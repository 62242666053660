import React, { useState, useEffect } from 'react';
import DefaultLogin from './DefaultLogin';
import PasswordReset from './PasswordReset';
import FooterLinks from '../links/Footers';
import useStyles from './LoginFormStyle';
import Logo from '../logo/Logo';
import ForgotPassword from './ForgotPassword';
import ResetForgottenPassword from './ResetForgottenPassword';

const LoginForm = (props) => {
  const classes = useStyles();
  const { context, setLoading } = props;
  const [mainComponent, setMainComponent] = useState(null);
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState('');
  const [component, setComponent] = useState('');
  const [authError, setAuthError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [verificationCodeError, setVerificationCodeError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [usernameErrorText, setUsernameErrorText] = useState('');
  const [verificationCodeErrorText, setVerificationCodeErrorText] = useState('');

  const onPasswordReset = async (firstName, lastName, password) => {
    setPasswordError(false);
    setLoading(true);
    try {
      await context.completeSignup(user, firstName, lastName, password);
      setLoading(false);
    } catch (error) {
      if (error.code === 'InvalidPasswordException') {
        setLoading(false);
        setPasswordErrorText(error.message);
        setPasswordError(true);
      }
    }
  };

  const onLogin = async (email, password) => {
    setAuthError(false);
    setLoading(true);
    try {
      const cognitoUser = await context.login(email, password);
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(cognitoUser);
        setComponent('PasswordReset');
        setLoading(false);
      }
    } catch (error) {
      setAuthError(true);
      setLoading(false);
    }
  };

  const onForgotPassword = () => {
    setComponent('ForgotPassword');
  };

  const onSubmitUsernameForVerification = async (userName) => {
    setAuthError(false);
    setLoading(true);

    try {
      await context.forgotPassword(userName);
      setUsername(userName);
      setLoading(false);
      setComponent('ResetForgottenPassword');
    } catch (error) {
      setLoading(false);
      setUsernameErrorText(error.message);
      setUsernameError(true);
    }
  };

  const onForgotPasswordCancel = () => {
    setComponent('default');
  };

  const onResetForgottenPasswordSubmit = async (userName, verificationCode, newPassword) => {
    setAuthError(false);
    setLoading(true);

    try {
      await context.forgotPasswordSubmit(userName, verificationCode, newPassword);
      setLoading(false);
      setComponent('default');
    } catch (error) {
      if (error.code === 'InvalidPasswordException') {
        setLoading(false);
        setPasswordErrorText(error.message);
        setPasswordError(true);
      } else {
        setLoading(false);
        setVerificationCodeErrorText(error.message);
        setVerificationCodeError(true);
      }
    }
  };

  useEffect(() => {
    switch (component) {
      case 'PasswordReset':
        setMainComponent(
          <PasswordReset
            onSubmit={onPasswordReset}
            passwordError={passwordError}
            passwordErrorText={passwordErrorText}
          />,
        );
        break;
      case 'ForgotPassword':
        setMainComponent(
          <ForgotPassword
            usernameError={usernameError}
            usernameErrorText={usernameErrorText}
            onCancel={onForgotPasswordCancel}
            onSubmit={onSubmitUsernameForVerification}
          />,
        );
        break;
      case 'ResetForgottenPassword':
        setMainComponent(
          <ResetForgottenPassword
            username={username}
            passwordError={passwordError}
            passwordErrorText={passwordErrorText}
            verificationCodeError={verificationCodeError}
            verificationCodeErrorText={verificationCodeErrorText}
            onSubmit={onResetForgottenPasswordSubmit}
          />,
        );
        break;
      default:
        setMainComponent(
          <DefaultLogin
            onSubmit={onLogin}
            onForgotPassword={onForgotPassword}
            authError={authError}
          />,
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authError, passwordError, component, usernameError, verificationCodeError]);

  return (
    <>
      <Logo />
      <div className={classes.login}>{mainComponent}</div>
      <FooterLinks />
    </>
  );
};

export default LoginForm;
