import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useApolloClient } from '@apollo/client';
import { UsersContext } from 'globalState/users';
import GET_GROUP_USERS from 'graphql/queries/getGroupUsers';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import SearchTableView from 'components/searchTable';
import searchbarPositions from 'components/searchTable/constants/searchbarPositions';
import columns from './constants/columns';
import { RootWrapper, ErrorWrapper } from './groupMembers-styled';
import { useSelectedLeftMenu } from '../../../../atoms';
import AvatarCell from './components/avatarCell';

const userPoolId = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;

const getRows = (data, getUser) => {
  const groupUsers = data?.getGroupUsers?.items;
  const rows = [];
  groupUsers.forEach((groupUser, index) => {
    const { mId } = groupUser;
    const user = getUser(mId);
    if (user) {
      const { mAvatarUrl, mProperties, mTitle } = user;
      const { jobTitle, email } = mProperties;
      rows.push({
        rowId: index,
        name: AvatarCell({ mTitle, mAvatarUrl }),
        type: jobTitle,
        email,
        mTitle,
      });
    }
  });
  return rows;
};

const GroupMembers = (props) => {
  const { groupPolicy } = props;
  const [defaultSort] = useState(false);
  const { getUser } = useContext(UsersContext);
  const [selectedLeftMenu] = useSelectedLeftMenu();
  const client = useApolloClient();

  const { data, error, loading } = useQuery(GET_GROUP_USERS, {
    variables: {
      input: {
        groupName: selectedLeftMenu,
        userPoolId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const userCount = groupPolicy?.mProperties?.userCount;
  const total = data?.getGroupUsers?.items?.length;

  useEffect(() => {
    if (!loading && userCount !== total) {
      const mProperties = { ...groupPolicy.mProperties, userCount: total };
      client.cache.modify({
        id: client.cache.identify({ mId: 'groupPolicy', mRefId: groupPolicy.mRefId }),
        fields: {
          mProperties: () => mProperties,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorWrapper>Error loading group members</ErrorWrapper>;

  const rows = getRows(data, getUser);

  return (
    <RootWrapper>
      <SearchTableView
        usageType="settings"
        columns={columns}
        rows={rows}
        defaultSelect={false}
        defaultSort={defaultSort}
        showTitle={false}
        showSearchIcon
        searchbarPosition={searchbarPositions.BOTTOM}
        selectable={false}
      />
    </RootWrapper>
  );
};

GroupMembers.propTypes = {
  groupPolicy: PropTypes.object,
};

GroupMembers.defaultProps = {
  groupPolicy: [],
};
export default GroupMembers;
