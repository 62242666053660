/**
 * Hook for handling behavior of input field on key press
 * i.e. Enter, Escape, Tab
 *
 * How to use:
 *
 * import useInputEvents from 'hooks/useInputEvents';
 *
 *
 * const [inpurRef, onKeyDown, onBlur]= useInputEvents(onUpdate, currentValue, initialValue);
 *
 * Now use the inputRef, onKeyDown and onBlur callback !
 *
 * ...
 * Possible Interactions:
 *
 * 1. On Enter press blur the input field and return current value
 * 2. On Escape press blur the input field and  return intial value
 * 3. On pressing tab or clicking outside of input return current value
 *    and blur
 * 4. tabExplicit(= true) should be used when user doesn't want blur to fire,
 *    but on tab key down wants to do some action
 * ...
 */

import { useRef, useCallback } from 'react';

const useInputEvents = (onUpdate, currentValue, initialValue, tabExplicit = false) => {
  const inputRef = useRef(null);

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        inputRef.current.blur();
        onUpdate(currentValue);
        event.stopPropagation();
      }
      if (event.key === 'Tab' && tabExplicit) {
        onUpdate(currentValue);
      }
      if (event.key === 'Escape') {
        inputRef.current.blur();
        onUpdate(initialValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentValue, initialValue],
  );

  const onBlur = useCallback(
    (event) => {
      event.preventDefault();
      if (event.relatedTarget) onUpdate(currentValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentValue],
  );

  const onClear = useCallback(
    (event) => {
      event.preventDefault();
      onUpdate('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentValue, onUpdate],
  );

  return [inputRef, onKeyDown, onBlur, onClear];
};

export default useInputEvents;
