import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0,
  },
  inputProp: {
    margin: '4px 0 4px',
    height: '22px',
  },
  inputRoot: {
    width: '100%',
    padding: ({ rootPadding }) => rootPadding,
    display: 'inline-flex',
    flexFlow: 'row wrap',
    minHeight: ({ minHeight }) => minHeight,
    '&:hover': {
      backgroundColor: theme.palette.dina.outlineButtonOnHover,
    },
  },
  input: {
    padding: 0,
    width: '128px',
    paddingLeft: '6px',
    color: theme.palette.dina.highEmphasis,

    '&::-webkit-input-placeholder': {
      ...theme.typography.dina.rightColumnHeaderText,
      color: theme.palette.dina.disabled,
    },
    '&::-moz-placeholder': {
      ...theme.typography.dina.rightColumnHeaderText,
      color: theme.palette.dina.disabled,
    }, // Firefox 19+
    '&:-ms-input-placeholder': {
      color: theme.palette.dina.disabled,
      ...theme.typography.dina.rightColumnHeaderText,
    }, // IE 11
    '&::-ms-input-placeholder': {
      color: theme.palette.dina.disabled,
      ...theme.typography.dina.rightColumnHeaderText,
    }, // Edge
  },
  underline: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.disabled}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
  },
  helperText: {
    ...theme.typography.dina.chipLabel,
    whiteSpace: 'pre-wrap',
  },
  chip: {
    margin: '2px',
  },
  disabled: {},
  focused: {},
  error: {},
}));

export default useStyles;
