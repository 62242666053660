import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 0,
  },
  calendarContainer: {
    borderRadius: '8px',
    boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 
      0px 8px 10px 1px rgba(0,0,0,0.14), 
      0px 3px 14px 2px rgba(0,0,0,0.12)`,
  },
}));

export default useStyles;
