import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import memberTypes from 'graphql/memberTypes';
import USER_UTILS from 'graphql/mutations/userUtils';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import useLogger from 'utils/useLogger';

const GET_USERS = gql`
  query GetUsers($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
    }
  }
`;

const useUserUtils = () => {
  const logger = useLogger('use user utils');
  const [userUtils] = useMutation(USER_UTILS);

  const disableUser = async (mId) => {
    const input = {};
    const users = [];
    const user = { mId, actions: ['disable'] };
    users.push(user);
    input.users = users;

    try {
      await userUtils({
        variables: { input },
        update: (proxy) => {
          const members = proxy.readQuery({
            query: GET_USERS,
            variables: getMembersOfTypeQuery(memberTypes.USER),
          });

          const updatedMembers = members.getMembersOftype.filter((member) => member.mId !== mId);

          proxy.writeQuery({
            query: GET_USERS,
            variables: getMembersOfTypeQuery(memberTypes.USER),
            data: { getMembersOftype: updatedMembers },
          });
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.log(e);
    }
  };
  return [disableUser];
};

export default useUserUtils;
