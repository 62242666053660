import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import URLS from 'utils/constants/urls';

const { ABOUT, TERMS_CONDITIONS } = URLS;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    left: '56px',
    bottom: '32px',
    '@media (max-width:830px)': {
      left: '25px',
      width: '310px',
      bottom: '24px',
    },
  },
  link: {
    color: theme.palette.dina.mediumEmphasis,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px',
  },
  signUpText: {
    color: theme.palette.dina.highEmphasis,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px',
    height: '24px',
    textAlign: 'bottom',
    bottom: '50px',
    '@media (min-width:768px)': {
      display: 'none',
    },
  },
}));

const Footers = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} data-testid="footer">
      <Typography className={classes.signUpText}>
        Don&apos;t have an Account? &nbsp;
        <Link href="" className={classes.signUpText} underline="hover">
          Sign up here...
        </Link>
      </Typography>

      <div>
        <Typography>
          <Link href={ABOUT} className={classes.link} target="_blank" underline="hover">
            About DiNA
          </Link>
          <span style={{ display: 'inline' }} className={classes.link}>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          </span>
          <Link href={TERMS_CONDITIONS} className={classes.link} target="_blank" underline="hover">
            Terms and Conditions
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default Footers;
