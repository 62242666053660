/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import useHover from 'hooks/useHover';
import { Collapse, List, ListItem, ListItemText, Fade, IconButton } from '@material-ui/core';
import MoreVertical from 'assets/icons/systemicons/more_vertical.svg';
import Tooltip from 'components/tooltip';
import Button from 'components/buttons/button';
import ArrowDown from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
import ArrowUp from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import PlusSmallCircle from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import useStyles from './group-styles';

const Group = (props) => {
  const {
    title,
    children,
    onEllipsis,
    open,
    toggleListOpen,
    hasMore,
    handleCreateClicked,
    type,
    onLoadMore,
    toolTipTitle,
    itemCount,
    hideCreateNewButton,
  } = props;
  const classes = useStyles();
  const moreItems = hasMore ? ' +' : '';
  const [hoverRef, isHovered] = useHover();

  const crateClicked = (e) => {
    e.stopPropagation();
    handleCreateClicked(e);
  };

  return (
    <div>
      <ListItem
        button
        onClick={toggleListOpen}
        className={classes.listHeader}
        ref={hoverRef}
        classes={{ button: classes.buttonOverride }}
      >
        <img
          src={open ? ArrowUp : ArrowDown}
          alt="expand/collapse icon"
          title="Expand / Collapse"
        />
        <ListItemText primary={title} classes={{ primary: classes.listHeaderText }} />
        <div className={classes.rightItems}>
          {onEllipsis ? <img src={MoreVertical} alt="more icon" /> : null}
          <span
            className={`${classes.numberOf} ${
              isHovered && type === 'left' && !hideCreateNewButton
                ? classes.numberOfHovered
                : classes.numberOfNotHovered
            }`}
          >
            {children && children.props ? children.props.children.length + moreItems : itemCount}
          </span>

          {type === 'left' && !hideCreateNewButton && (
            <Fade in={isHovered} timeout={{ enter: 250, exit: 250 }} mountOnEnter unmountOnExit>
              <div>
                {/** prevents ref error * */}
                <Tooltip title={toolTipTitle || 'Create Story'} noArrow>
                  <IconButton onClick={crateClicked} size="small" className={classes.addButton}>
                    <img src={PlusSmallCircle} alt="add" />
                  </IconButton>
                </Tooltip>
              </div>
            </Fade>
          )}
        </div>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
          {hasMore ? (
            <div className={classes.loadMoreButtonContainer}>
              <Button onClick={onLoadMore} variant="text" title="Load more elements">
                Load more
              </Button>
            </div>
          ) : null}
        </List>
      </Collapse>
    </div>
  );
};

Group.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** List items */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  /** should the header have options? */
  onEllipsis: PropTypes.bool,
  /** Should the list be open or not */
  open: PropTypes.bool,
  /** toggle open/close */
  toggleListOpen: PropTypes.func,
  /** add button clicked */
  handleCreateClicked: PropTypes.func,
  /** where should the group be */
  type: PropTypes.string,
  /** load more elements */
  onLoadMore: PropTypes.func,
  /** have more token */
  hasMore: PropTypes.bool,
  /**  when explicit itemCount specified */
  itemCount: PropTypes.number,
  /** Hides the create new button & stops the sliding of rundown count */
  hideCreateNewButton: PropTypes.bool,
};

Group.defaultProps = {
  title: 'Group',
  onEllipsis: false,
  children: null,
  open: false,
  toggleListOpen: () => {},
  handleCreateClicked: () => {},
  type: '',
  hasMore: false,
  onLoadMore: () => {},
  itemCount: 0,
  hideCreateNewButton: false,
};

export default Group;
