import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  splitPane: {
    '& .Resizer': {
      boxSizing: 'border-box',
      backgroundClip: 'padding-box',
      zIndex: 100,
      cursor: 'col-resize',
      '&:before': {
        backgroundColor: theme.palette.dina.dividerLight,
        content: '""',
        height: '100%',
        textAlign: 'center',
        display: 'block',
        width: '2px',
      },
      '&:hover': {
        backgroundColor: theme.palette.dina.onFocus,
        borderLeft: `1px solid ${theme.palette.dina.onFocus}`,
        borderRight: `1px solid ${theme.palette.dina.onFocus}`,
      },
      '&:active': {
        backgroundColor: theme.palette.dina.onFocus,
        borderLeft: `1px solid ${theme.palette.dina.onFocus}`,
        borderRight: `1px solid ${theme.palette.dina.onFocus}`,
      },
    },
  },
}));

export default useStyles;
