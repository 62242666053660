import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { elementTypes } from 'components/editor/constants/types';
import { removeBlock, updateBlock } from 'components/editor/utils';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import InputGroup from '../inputGroup';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';
import { FactBoxIcon, InputGroupWrapper } from './styled';

const FactBox = ({ attributes, children, element, readOnly }) => {
  const { data } = element;
  const editor = useSlate();
  const { update } = useEditorContext();

  const { stinger, content, collapsed } = data || {};

  const [onChangeCollapse] = useChangeCollapse(element);

  const handleStingerValueChange = useCallback(
    (newStingerValue) => {
      const updatedData = {
        ...data,
        stinger: newStingerValue,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const handleContentValueChange = useCallback(
    (newContentValue) => {
      const updatedData = {
        ...data,
        content: newContentValue,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderComponent = useMemo(() => {
    const stingerInputProps = {
      disableLabel: true,
      onChange: handleStingerValueChange,
      value: stinger || '',
      placeholder: 'Type Stinger here...',
    };

    const contentInputProps = {
      disableLabel: true,
      onChange: handleContentValueChange,
      value: content || '',
      placeholder: 'Type Content here...',
    };

    const inputProps = [stingerInputProps, contentInputProps];

    return (
      <Box
        iconComponent={<FactBoxIcon />}
        title="Fact Box"
        readOnly={readOnly}
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed}
        collapsedContent={stinger || ''}
        hideEllipsisButton
        onMenuSelect={onMenuSelect}
      >
        <InputGroupWrapper>
          <InputGroup {...{ readOnly, inputProps }} />
        </InputGroupWrapper>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    collapsed,
    content,
    handleContentValueChange,
    handleStingerValueChange,
    onMenuSelect,
    readOnly,
    stinger,
    onChangeCollapse,
  ]);

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderComponent}
      </DragAndDrop>
    </div>
  );
};

FactBox.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

FactBox.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.FACT,
  },
};

export default memo(FactBox);
