const saveMetaFormsOnContext = (metadataResult, config) => {
  // eslint-disable-next-line no-param-reassign
  config.metadataForms = mergeForms(metadataResult);
};

const mergeForms = (metadataResult) => {
  const { data } = metadataResult;
  if (!data) return [];
  const { getMemberFromId: forms } = data;
  if (!forms) return [];
  const ret = {
    mTitle: 'title',
    mRefId: 'refId',
    defaultViews: [],
    fields: [],
    views: [],
  };
  forms.forEach((form) => {
    const { mProperties, mTitle, mRefId } = form;
    ret.mTitle = mTitle;
    ret.mRefId = mRefId;
    ret.defaultView = (mProperties && mProperties.defaultView) || ret.defaultView;
    ret.defaultViews = [...ret.defaultViews, ...((mProperties && mProperties.defaultViews) || [])];
    ret.fields = [...ret.fields, ...((mProperties && mProperties.fields) || [])];
    ret.views = [...ret.views, ...((mProperties && mProperties.views) || [])];
  });

  const defaultForm = forms.find((f) => f.mRefId === 'form-00');
  if (defaultForm) {
    const { mProperties, mTitle, mRefId } = defaultForm;
    ret.mTitle = mTitle;
    ret.mRefId = mRefId;
    ret.defaultView = (mProperties && mProperties.defaultView) || ret.defaultView;
  }

  return [ret];
};

export default saveMetaFormsOnContext;
