import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import { getMembersOfQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';
import updateStoryListCache from 'graphql/utils/cache/notification/updateStoryListCache';

const getUpdatedList = (list, item) => {
  const memberUpdated = item.department;
  const updatedList = [...list];
  if (item.crudAction === 'INSERT') {
    memberUpdated.__typename = 'MemberType';
    memberUpdated.mDescription = item.department.mDescription || null;
    memberUpdated.mAvatarUrl = item.department.mAvatarUrl || null;
    const memberExist = updatedList.filter((u) => u.mId === item.department.mId);
    if (memberExist.length === 0) {
      updatedList.push(memberUpdated);
    }
    return updatedList;
  }
  // crudAction is REMOVE if it is not INSERT.
  return updatedList.filter((t) => t.mId !== item.department.mId);
};

const updateCahe = (proxy, item, query, variables) => {
  try {
    const list = proxy.readQuery({
      query,
      variables,
    });
    const updatedList = getUpdatedList(list.getMembersOf, item);
    // Write updated member list in the cache.
    proxy.writeQuery({
      query,
      variables,
      data: {
        getMembersOf: updatedList,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const shareStoryWithDepartment = (proxy, item) => {
  const variables = getMembersOfQuery(item.story.mId);
  updateCahe(proxy, item, GET_MEMBERS_OF, variables);
  const memberId = item.department.mId;
  updateStoryListCache(proxy, { ...item, memberId }, memberTypes.DEPARTMENT_STORY);
};

export default shareStoryWithDepartment;
