import Ready from './ready.svg';
import ReadySmall from './ready_small.svg';
import InReview from './inReview.svg';
import InReviewSmall from './inReview_small.svg';
import Published from './published.svg';
import PublishedSmall from './published_small.svg';
import InProgress from './inProgress.svg';
import InProgressSmall from './inProgress_small.svg';
import ToDo from './toDo.svg';
import ToDoSmall from './toDo_small.svg';
import WarningSmall from './warning_small.svg';
import ErrorSmall from './error_small.svg';
import Error from './error.svg';
import Expired from './expired.svg';
import ExpiredSmall from './expired_small.svg';
import Pending from './pending.svg';
import PendingSmall from './pending_small.svg';

const statusIcons = {
  todo: ToDo,
  inProgress: InProgress,
  inReview: InReview,
  ready: Ready,
  published: Published,
  todo_small: ToDoSmall,
  inProgress_small: InProgressSmall,
  inReview_small: InReviewSmall,
  ready_small: ReadySmall,
  published_small: PublishedSmall,
  warning_small: WarningSmall,
  error: Error,
  error_small: ErrorSmall,
  expired_small: ExpiredSmall,
  expired: Expired,
  pending: Pending,
  pending_small: PendingSmall,
};

export default statusIcons;
