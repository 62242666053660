import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

import theme from 'theme/theme';

import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Scrollbar from 'components/scrollbar';

export const StyledTabIconWrapper = styled('div')`
  margin-right: 10px;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0;
  display: none;

  svg {
    cursor: pointer;
    path {
      fill-opacity: 0.53 !important;
    }
  }

  &:hover {
    svg {
      path {
        fill-opacity: 1 !important;
      }
    }
  }
`;

const commonTabHoverAndActiveStyles = css`
  ${StyledTabIconWrapper} {
    svg {
      path {
        fill: ${theme.palette.dina.iconActive};
        fill-opacity: 1;
      }
    }
  }
`;

export const StyledDropdownTabsWrapper = styled(Scrollbar)`
  display: inherit;
  flex-direction: inherit;
  width: inherit;
  max-height: 350px;
  overflow-y: auto;

  > div:nth-child(1) {
    position: relative !important;
  }
`;

export const StyledDropdownListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
  width: 100%;
  color: ${theme.palette.dina.highEmphasis};
  padding: 0 1rem;

  &.Mui-disabled {
    opacity: 0.19;
  }

  &:hover {
    background-color: ${theme.palette.dina.outlineButtonOnHover};
    ${commonTabHoverAndActiveStyles}

    ${StyledIconButton} {
      display: block;
    }
  }

  &.Mui-selected {
    background-color: ${theme.palette.dina.onSelected};
    ${commonTabHoverAndActiveStyles}

    &:hover {
      background-color: ${theme.palette.dina.onSelected};
    }
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }
`;

export const StyledDropdownListItemLabel = styled(ListItemText)`
  span {
    ${theme.typography.dina.listItemLabel};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledDropdownListItemIcons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  gap: 8px;
  width: 56px;
  height: 100%;

  svg {
    &:first-of-type {
      path {
        fill-opacity: 1;
      }
    }
  }
`;
