import { useCallback } from 'react';
import { useSlate } from 'slate-react';

import { updateBlock } from 'components/editor/utils';
import useEditorContext from 'components/editor/hooks/useEditorContext';

import { boxTypes } from '../constants/types/voidTypes';
import { elementTypes } from '../constants';

const boxElementTypes = Object.values(boxTypes);
const boxTypesSet = new Set(boxElementTypes);

const useChangeCollapse = (element) => {
  const { update } = useEditorContext();

  const editor = useSlate();
  const { data } = element;
  const { collapsed, itemId } = data;

  const onChangeCollapse = useCallback(
    (event) => {
      let allAttributes;
      let updatedCollapseValue = !collapsed;

      event.preventDefault();
      event.stopPropagation();

      const { shiftKey, ctrlKey, metaKey, altKey } = event;

      const ctrlOrMetaKey = ctrlKey || metaKey;

      if (ctrlOrMetaKey) allAttributes = { value: !collapsed };

      if (shiftKey) {
        updatedCollapseValue = false;
        allAttributes = { value: true };
      }
      if (ctrlOrMetaKey && shiftKey) {
        updatedCollapseValue = true;
        allAttributes = { value: false };
      }

      if (altKey) allAttributes = null;

      const updatedData = { ...data, collapsed: updatedCollapseValue };

      updateBlock(editor, element, updatedData, update, undefined);

      const updateOtherBlocks = (children) => {
        children.forEach((elem) => {
          if (elem?.type === elementTypes.TWEET_THREAD) {
            updateOtherBlocks(elem.children);
          } else if (boxTypesSet.has(elem?.type) && itemId !== elem?.data?.itemId) {
            const updatedElementData = { ...elem.data, collapsed: allAttributes?.value };
            updateBlock(editor, elem, updatedElementData, update, undefined);
          }
        });
      };

      if (allAttributes) updateOtherBlocks(editor?.children);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return [onChangeCollapse];
};

export default useChangeCollapse;
