import { useApolloClient } from '@apollo/client';
import { auditFailure } from 'utils/sendErrorLogToBackend';

const useLogger = (moduleName) => {
  const isProduction = process.env.NODE_ENV === 'production';
  const client = useApolloClient();

  const logger = (level, message) => {
    // eslint-disable-next-line no-console
    console[level](`${moduleName}, ${message}`);
  };

  const log = (message) => {
    logger('log', message);
  };

  const info = (message) => {
    logger('info', message);
  };

  const warn = (message) => {
    logger('warn', message);
  };

  const createErrorMessage = (label, error) => {
    if (error) {
      const message = error instanceof Error ? error.message : JSON.stringify(error);
      return label ? `${label}: ${message}` : message;
    }
    return label;
  };

  /**
   * Outputs error message to console and if in production also submits the error to the backend
   * @param {string|Error} label - Either a label (string) or an Error instance.
   * @param {Error} err - Optional Error instance if label is defined as a string
   */
  const error = (label, err = undefined) => {
    // To handle the case where the Error object is passed as first argument
    if (label && label instanceof Error) {
      error('', label);
      return;
    }

    const message = createErrorMessage(label, err);
    logger('error', message);
    if (!isProduction) return;

    const eobj = err || {};
    auditFailure(
      client,
      {
        message: `${moduleName}, ${message}`,
        code: eobj.code || '400',
        stack: eobj.stack,
        origin: 'logger',
      },
      new Date(),
    );
  };

  const debug = (message) => {
    logger('debug', message);
  };

  return {
    log,
    info,
    warn,
    error,
    debug,
  };
};

export default useLogger;
