import conversationTypes from 'utils/constants/conversationTypes';

const updateAvatarUrl = (mAssignedMembers, members) => {
  const currentSelectedMember = { ...mAssignedMembers[0] };
  const member = members.find((t) => t.mId === currentSelectedMember.mId);
  const { mAvatarUrl } = member ?? {};
  currentSelectedMember.mAvatarUrl = mAvatarUrl;
  return [currentSelectedMember];
};
const getAssignedMemberInfo = (
  getUser,
  mAssignedMembers = [],
  userId,
  conversationType,
  teams = [],
  departments = [],
) => {
  if (conversationType === conversationTypes.TEAM) {
    return updateAvatarUrl(mAssignedMembers, teams);
  }
  if (conversationType === conversationTypes.DEPARTMENT) {
    return updateAvatarUrl(mAssignedMembers, departments);
  }

  if (
    conversationType === conversationTypes.DIRECT ||
    conversationType === conversationTypes.GROUP
  ) {
    const filteredMembers =
      conversationType === conversationTypes.DIRECT
        ? mAssignedMembers.filter(({ mId }) => mId !== userId)
        : mAssignedMembers;
    const returnData = filteredMembers.map(({ mId }) => getUser(mId) || {});
    return returnData;
  }

  return mAssignedMembers;
};

export default getAssignedMemberInfo;
