import { memo } from 'react';
import styled from '@emotion/styled/macro';
import { ListItem as MaterialListItem } from '@material-ui/core';
import theme from 'theme/theme';
import { listItemLabel } from 'theme/styledTypography';

const ListItemComponent = memo(MaterialListItem);

export const ListItem = styled(ListItemComponent, {
  shouldForwardProp: (prop) => prop !== 'borderRadius' && prop !== 'maxHeight',
})`
  &.MuiListItem-root {
    border-radius: ${(props) => `${props.borderRadius}px`};
    max-height: ${(props) => `${props.maxHeight}px`};
    :hover {
      background-color: ${theme.palette.dina.outlineButtonOnHover};
    }
    &.Mui-selected {
      background-color: ${theme.palette.dina.onSelected};
      :hover {
        background-color: ${theme.palette.dina.onSelected};
      }
    }
  }
`;

export const ListItemText = styled('span')`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  ${listItemLabel}
  color: ${theme.palette.dina.highEmphasis};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${(props) => props.whiteSpace};
`;
