import React from 'react';
import PropTypes from 'prop-types';

import Divider from 'components/divider';

import {
  StyledListItem,
  StyledTitle,
  StyledContent,
  StyledDividerWrapper,
} from './showItem-styled';

const ShowItem = ({ title, content, onClick }) => (
  <div>
    <StyledListItem onClick={onClick}>
      <StyledTitle>{title}</StyledTitle>
      <StyledContent>{content}</StyledContent>
    </StyledListItem>

    <StyledDividerWrapper>
      <Divider />
    </StyledDividerWrapper>
  </div>
);

ShowItem.propTypes = {
  /** Defines the title of the item  */
  title: PropTypes.string,
  /** Defines the content of the item  */
  content: PropTypes.node,
};

ShowItem.defaultProps = {
  title: 'title',
  content: 'content',
};

export default ShowItem;
