import styled from '@emotion/styled';
import colors from 'theme/colors';
import TabsComponent from 'components/instanceCard/components/tabs';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  border-right: 1px solid ${colors.dividerLight};
  overflow: hidden;
`;

export const TabsWrapper = styled('div')`
  height: 44px;
  background: ${colors.backgroundResting};
`;

export const Tabs = styled(TabsComponent)`
  padding-left: 8px;
  padding-right: 8px;
`;
