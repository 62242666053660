import { makeStyles } from '@material-ui/styles';

const listItem = (theme) => ({
  width: '100%',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 8px',
  ...theme.typography.dina.listItemLabel,
  letterSpacing: '0.25',
  cursor: 'pointer',
});

const useStyles = makeStyles((theme) => ({
  listbox: {
    width: ({ listWidthOffset }) => `calc(100% - ${listWidthOffset}px)`,
    margin: 0,
    zIndex: 1300,
    position: 'absolute',
    listStyle: 'none',
    maxHeight: 366,
    overflow: 'hidden',
    borderRadius: '4px',
    backgroundColor: theme.palette.dina.surfaceCardDark,
    '& div[data-focus="true"]:not([aria-selected="true"])': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
    '& > :first-child': {
      marginTop: '8px',
    },
    '& > :last-child': {
      marginBottom: '8px',
    },
  },
  listItem: { ...listItem(theme) },
  listItemDisabled: {
    ...listItem(theme),
    color: theme.palette.dina.disabled,
    pointerEvents: 'none',
  },
  optionSelectedIcon: {
    marginRight: 16,
    '& path': {
      fill: theme.palette.dina.disabled,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  valueStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarContainer: {
    marginRight: 8,
  },
}));

export default useStyles;
