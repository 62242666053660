import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledDropdownListItem as ListItem,
  StyledDropdownListItemLabel as ListItemLabel,
} from '../tabItems/tabItems-styled';

const FixedItemsView = ({
  currentTab,
  contentTabs,
  hasInactiveTabs,
  handleCloseActiveTab,
  handleCloseInactiveTabs,
  handleCloseAllTabs,
}) => (
  <>
    <ListItem button onClick={handleCloseActiveTab} disabled={currentTab.tabType === 'icon'}>
      <ListItemLabel>Close Active Tab</ListItemLabel>
      {/* <TabAction>{`Ctrl + W \n⌘ + W`}</TabAction> */}
    </ListItem>
    <ListItem button onClick={handleCloseInactiveTabs} disabled={!hasInactiveTabs}>
      <ListItemLabel>Close Inactive Tabs</ListItemLabel>
    </ListItem>
    <ListItem button onClick={handleCloseAllTabs} disabled={contentTabs.length === 0}>
      <ListItemLabel>Close All Tabs</ListItemLabel>
      {/* <TabAction>{`Ctrl + Shift + W\n⌘ + Shift + W`}</TabAction> */}
    </ListItem>
  </>
);

FixedItemsView.propTypes = {
  /** current tab */
  currentTab: PropTypes.object.isRequired,
  /** content tabs array */
  contentTabs: PropTypes.array.isRequired,
  /** has inactive tabs open */
  hasInactiveTabs: PropTypes.bool.isRequired,
  /** handle close inactive tabs callback fn */
  /** handle close active tab  */
  handleCloseActiveTab: PropTypes.func.isRequired,
  /** handle close inactive tabs callback fn */
  handleCloseInactiveTabs: PropTypes.func.isRequired,
  /** handle close all tabs callback fn */
  handleCloseAllTabs: PropTypes.func.isRequired,
};

export default FixedItemsView;
