/* eslint-disable import/no-extraneous-dependencies */
import React, { createContext, useReducer, useEffect, useContext } from 'react';
import has from 'lodash/has';
import { RUNDOWN_TOOLBAR_BUTTON_DEFAULT_STATE } from 'screens/rundown/components/toolbar/toolbarButtons';
import reducer from './reducers/rundownReducer';

export const RundownContext = createContext();

const getSavedState = () => JSON.parse(window.localStorage.getItem('rundown'));

const SAVED_STATE = getSavedState();
const DEFAULT_STATE = RUNDOWN_TOOLBAR_BUTTON_DEFAULT_STATE;

export const RundownProvider = ({ children }) => {
  SAVED_STATE &&
    SAVED_STATE.toolbarButtons &&
    SAVED_STATE.toolbarButtons.forEach((object) => {
      if (!has(object, 'id')) {
        window.localStorage.setItem('rundown', JSON.stringify(DEFAULT_STATE));
      }
    });
  const initialState = getSavedState() || DEFAULT_STATE;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.localStorage.setItem(
      'rundown',
      JSON.stringify({
        ...state,
      }),
    );
  }, [state]);

  return <RundownContext.Provider value={[state, dispatch]}>{children}</RundownContext.Provider>;
};

export const useRundownContext = () => useContext(RundownContext);
