import memberTypes from 'graphql/memberTypes';

const ADDED = 'Added';
const REMOVED = 'Removed';
const FROM = 'From';
const TO = 'to';
const SHARED = 'Shared';
const WITH = 'With';

const { STORY, TEAM, DEPARTMENT } = memberTypes;

const sharePitchWithUser = (userTitle, pitchTitle, actionName) =>
  `You were ${actionName === 'INSERT' ? 'assigned to' : 'removed from'} a pitch: `;

const shareStoryWithUser = (userTitle, storyTitle, actionName) =>
  `You were ${actionName === 'INSERT' ? 'assigned to' : 'removed from'} a story: `;

const shareStoryWithTeam = (teamName, storyName, actionName) =>
  `${actionName === 'INSERT' ? SHARED : REMOVED} ${STORY}:\r\n"${storyName}"\r\n${
    actionName === 'INSERT' ? WITH : FROM
  }  ${TEAM}:\r\n"${teamName}"`;

const shareStoryWithdepartment = (departmentName, storyName, actionName) =>
  `${actionName === 'INSERT' ? SHARED : REMOVED} ${STORY}:\r\n"${storyName}"\r\n${
    actionName === 'INSERT' ? WITH : FROM
  }  ${DEPARTMENT}:\r\n"${departmentName}"`;

const addOrRemoveDepartmentToTeam = (departmentName, teamName, actionName) =>
  `${actionName === 'INSERT' ? ADDED : REMOVED} ${DEPARTMENT}\r\n"${departmentName}"\r\n${
    actionName === 'INSERT' ? TO : FROM
  }  ${TEAM}:\r\n"${teamName}"`;

const instanceAddedToStory = (instanceName, storyName, actionName) =>
  `${ADDED} a "${instanceName.toUpperCase()}" instance to ${STORY}:\r\n${storyName}`;

const userAddOrRemoveFromInstance = (userTitle, instanceName, actionName) =>
  `You were ${actionName === 'INSERT' ? 'assigned to' : 'removed from'} an instance: `;

const addRemoveUserFromConversation = (userTitle, conversationId, actionName) =>
  `${userTitle !== 'You' ? `${userTitle} is` : 'You have been '}\r\n${
    actionName === 'INSERT' ? 'added to conversation' : 'removed from conversation'
  }\r\n"${conversationId}"`;

// message types
const sharePitchWithUserMessage = (pitch) => {
  const { user, loggedInUserId, crudAction } = pitch.message;
  const message = sharePitchWithUser(getUserTitle(user, loggedInUserId), pitch.mTitle, crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString: crudAction === 'INSERT' ? 'Shared pitch' : 'Removed user from pitch',
    mId: pitch.mId,
    mType: pitch.mType,
    itemTitle: pitch.mTitle,
  };
};

const addOrRemoveUserFromConversationMessage = (conversation) => {
  const { user, loggedInUserId, crudAction } = conversation.message;
  const message = addRemoveUserFromConversation(
    getUserTitle(user, loggedInUserId),
    conversation.mTitle || conversation.mId,
    crudAction,
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === 'INSERT' ? 'Added user to conversation' : 'Removed user from conversation',
    mId: conversation.mId,
    mType: conversation.mType,
    itemTitle: conversation.mTitle,
  };
};

const newMessageReceivedNotifictaion = (chatMessage) => {
  const message = `New message Received`;
  return {
    message,
    messageType: 'New Message',
    messageTypeString: '',
    mId: chatMessage.mId, // Id of conversation
    mType: chatMessage.mType,
    itemTitle: chatMessage.mTitle,
  };
};

const shareStoryWithUserMessage = (story) => {
  const { user, loggedInUserId, crudAction } = story.message;
  const message = shareStoryWithUser(getUserTitle(user, loggedInUserId), story.mTitle, crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString: crudAction === 'INSERT' ? 'Shared story' : 'Removed user from story',
    mId: story.mId,
    mType: story.mType,
    itemTitle: story.mTitle,
  };
};

const shareStoryWithTeamMessage = (item) => {
  const message = shareStoryWithTeam(item.team.mTitle, item.story.mTitle, item.crudAction);
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item.crudAction === 'INSERT' ? 'Shared story with team' : 'Removed story from team',
  };
};

const shareStoryWithDepartmentMessage = (item) => {
  const message = shareStoryWithdepartment(
    item.department.mTitle,
    item.story.mTitle,
    item.crudAction,
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item.crudAction === 'INSERT'
        ? 'Shared story with department'
        : 'Removed story from department',
  };
};

const addOrRemoveUserToTeamMessage = (item) => {
  const { mId, mType, mTitle: itemTitle, message: data } = item;
  return {
    mId,
    mType,
    itemTitle,
    message: `You have been ${data.crudAction === 'INSERT' ? 'added to' : 'removed from'} `,
    messageType: 'share',
    messageTypeString:
      data.crudAction === 'INSERT' ? 'Added user to team' : 'Removed user from team',
  };
};

const addOrRemoveUserToDepartmentMessage = (item) => {
  const { mId, mType, mTitle: itemTitle, message: data } = item;
  return {
    mId,
    mType,
    itemTitle,
    message: `You have been ${data.crudAction === 'INSERT' ? 'added to' : 'removed from'} `,
    messageType: 'share',
    messageTypeString:
      data.crudAction === 'INSERT' ? 'Added user to department' : 'Removed user from department',
  };
};

const addOrRemoveDepartmentToTeamMessage = (item) => {
  const message = addOrRemoveDepartmentToTeam(
    item.department.mTitle,
    item.team.mTitle,
    item.crudAction,
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item.crudAction === 'INSERT' ? 'Added department to team:' : 'Removed department from team:',
  };
};

const instanceAddedToStoryMessage = (item) => {
  const message = instanceAddedToStory(
    item.instance.mProperties.platform,
    item.story.mTitle,
    item.crudAction,
  );
  return {
    message,
    messageType: `${item.instance.mProperties.platform}`,
    messageTypeString:
      item.crudAction === 'INSERT' ? 'Added an instance to story:' : 'Removed instance from story:',
  };
};

const addOrRemoveUserFromInstance = (instance) => {
  const { user, loggedInUserId, crudAction } = instance.message;
  const message = userAddOrRemoveFromInstance(
    getUserTitle(user, loggedInUserId),
    instance.mTitle,
    crudAction,
  );

  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === 'INSERT' ? 'Assigned user to instance:' : 'Removed user from instance:',
    mId: instance.mStoryId,
    mType: instance.mType,
    itemTitle: instance.mTitle,
    mRefId: instance.mRefId,
  };
};

const getUserTitle = (user, currentUser) => (currentUser !== user.mId ? user.mTitle : 'You');

const messageFunctionsArray = [
  ['usr_str', shareStoryWithUserMessage],
  ['tea_str', shareStoryWithTeamMessage],
  ['dep_str', shareStoryWithDepartmentMessage],
  ['tea_usr', addOrRemoveUserToTeamMessage],
  ['dep_usr', addOrRemoveUserToDepartmentMessage],
  ['dep_tea', addOrRemoveDepartmentToTeamMessage],
  ['str_ins', instanceAddedToStoryMessage],
  ['usr_ins', addOrRemoveUserFromInstance],
  ['usr_pitch', sharePitchWithUserMessage],
  ['message', newMessageReceivedNotifictaion],
  ['con_usr', addOrRemoveUserFromConversationMessage],
];
const messageFunctionsMap = new Map(messageFunctionsArray);

const getMessage = (notifyUser) => {
  const mType = notifyUser?.message?.mType;
  const messageFunction = messageFunctionsMap.get(mType);
  if (messageFunction) return messageFunction(notifyUser);
  return undefined;
};

export default getMessage;
