import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: ({ tabsHeight }) => `${tabsHeight}px`,
  },
  indicator: {
    display: 'none',
  },

  flexContainer: {
    border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    borderRadius: 8,
    backgroundColor: theme.palette.dina.backgroundResting,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabRoot: {
    ...theme.typography.dina.listItemLabel,
    letterSpacing: '0.25px',
    fontWeight: 'normal',
    color: theme.palette.dina.mediumEmphasis,
    textTransform: 'none',
    minWidth: ({ tabLength }) => {
      const tabWidth = Math.floor(100 / tabLength);
      return `calc(${tabWidth}% - ${tabLength * 2}px)`;
    },
    minHeight: ({ tabsHeight }) => `${tabsHeight - 4}px`,
    maxHeight: ({ tabsHeight }) => `${tabsHeight - 4}px`,
    transition: '0.1s ease-out',
    margin: '1px',
    borderRadius: '6px',
    '&:hover': {
      transition: '0.1s ease-in',
      color: theme.palette.dina.highEmphasis,
      opacity: 1,
    },
  },
  tabSelected: {
    transition: '0.1s ease-in',
    backgroundColor: theme.palette.dina.onSelected,
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
