import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_small.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/editor/photo_small.svg';
import TextButton from '../textButton';
import Wrapper from '../wrapper';
import VerticalDivider from '../verticalDivider';
import BlockButton from '../blockButton';
// import SectionWrapper from '../sectionWrapper';
import useStyles from './styles';

const { IMAGE, VIDEO } = elementTypes;

const cmsVariants = {
  IFRAME: 'iframe',
  BLOCKS: 'blocks',
};

const CmsToolbar = ({ platformStructure }) => {
  const classes = useStyles();
  const { variant, sections } = platformStructure;

  return (
    <div className={classes.container}>
      {variant === cmsVariants.IFRAME ? (
        <Wrapper showRightMargin>
          <TextButton icon={<VideoIcon />} label="Add Video" type={VIDEO} />
          <TextButton icon={<PhotoIcon />} label="Add Photo" type={IMAGE} />
        </Wrapper>
      ) : (
        sections.map(section => (
          <React.Fragment key={section.name}>
            <Wrapper showRightMargin rightMarginWidth={10}>
              {section.blocks.map(block => (
                <BlockButton key={block} type={block} />
              ))}
            </Wrapper>
            <VerticalDivider />
          </React.Fragment>
        ))
      )}
    </div>
  );
};

CmsToolbar.propTypes = {
  /** platform structure for the block */
  platformStructure: PropTypes.shape({
    variant: PropTypes.string,
    section: PropTypes.arrayOf(PropTypes.object),
  }),
};

CmsToolbar.defaultProps = {
  platformStructure: {
    variant: 'iframe',
    sections: [],
  },
};

export default memo(CmsToolbar);
