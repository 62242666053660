import React, { useState, useRef, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TabsComponent from 'components/instanceCard/components/tabs';
import ChipInput from 'components/chipInput';
import LoadingIndicator from 'components/loadingIndicator';
import useStyles from './dialog-styles';
import MemberList from './memberList';

const Tabs = memo(TabsComponent);

const DialogView = (props) => {
  const {
    onOk,
    onCancel,
    variant,
    data,
    members,
    dialogTitle,
    showItems,
    height,
    disableEdit,
    tabs,
    tabIndex,
    onTabChange,
    usage,
    loading,
  } = props;
  const isUsageSearchBar = usage === 'searchBar';
  const classes = useStyles({ isUsageSearchBar, height });
  const [inputValues, setInputValues] = useState(members);
  const chipInputRef = useRef(null);
  const [input, setInput] = useState('');
  const [pressedKey, setPressedKey] = useState('');
  const inputHelperDefaultText = 'Tab or use arrow keys to navigate.\nHit Enter to select';
  const [inputHelperText, setInputHelperText] = useState(inputHelperDefaultText);

  useEffect(() => {
    chipInputRef.current.focus();
  }, [chipInputRef]);

  const handleOk = () => {
    onOk(inputValues);
  };

  const addChipMember = useCallback(
    (member) => {
      if (member) setInputValues([...inputValues, member]);
      setInputHelperText(inputHelperDefaultText);
      chipInputRef.current.focus();
      setInput('');
    },
    [inputValues],
  );

  const removeChipMember = useCallback(
    (member) => {
      if (member) setInputValues(inputValues.filter((value) => value.mId !== member.mId));
      chipInputRef.current.focus();
      setInput('');
    },
    [inputValues],
  );

  const onDelete = useCallback(
    (_, index) => {
      const newInputValues = inputValues.filter((__, currentIndex) => currentIndex !== index);

      if (newInputValues.length === 0) {
        // eslint-disable-next-line max-len
        setInputHelperText(
          'Saving with no assigned user will set the\ncreator of the story or pitch as assigned.',
        );
      }
      setInputValues(newInputValues);
      chipInputRef.current.focus();
    },
    [inputValues],
  );

  const clearKeyPress = useCallback(() => {
    setPressedKey('');
  }, []);

  const checkIsMemberSelected = useCallback(
    (id) => Boolean(inputValues.find((member) => member.mId === id)),
    [inputValues],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        setPressedKey(event.key);
      } else if (event.key.length === 1) {
        setInput(input + event.key);
        setPressedKey('Character');
      } else if (event.keyCode === 8) {
        const newInput = input.slice(0, -1);
        setInput(newInput);
      } else setPressedKey(event.key);
    },
    [input],
  );

  return (
    <form className={classes.formContainer}>
      <div>
        <div className={classes.textRoot}>{dialogTitle}</div>
        <Divider classes={{ root: classes.dividerRoot }} />
        {tabs && tabs.length > 0 && (
          <>
            <div className={classes.tabs}>
              <Tabs tabs={tabs} onTabChange={onTabChange} tabIndex={tabIndex} tabsHeight={36} />
            </div>
          </>
        )}

        <div className={classes.inputDiv}>
          <ChipInput
            placeholder={`Type to find ${variant}`}
            variant={variant === 'Host' ? 'People' : variant}
            disabled={variant === 'Host' && inputValues.length === 1}
            chipInputItems={inputValues}
            onDelete={onDelete}
            inputRef={chipInputRef}
            onKeyDown={handleKeyDown}
            helperText={inputHelperText}
            disableEdit={disableEdit}
          />
        </div>
      </div>
      <Divider classes={{ root: classes.dividerRoot }} />
      <div className={classes.memberListDiv}>
        {(input || showItems) &&
          (loading ? (
            <LoadingIndicator />
          ) : (
            <MemberList
              data={data}
              variant={variant === 'Host' ? 'People' : variant}
              input={input}
              pressedKey={pressedKey}
              onPressKey={clearKeyPress}
              addChipMember={addChipMember}
              removeChipMember={removeChipMember}
              checkIsMemberSelected={checkIsMemberSelected}
            />
          ))}
      </div>
      <div className={classes.footer}>
        <Divider classes={{ root: classes.dividerRoot }} />
        <div className={classes.buttonContainer}>
          <Button className={classes.cancelButton} onClick={onCancel} fullWidth>
            Cancel
          </Button>
          <Button
            disabled={!inputValues.length && !members.length}
            fullWidth
            className={classes.okButton}
            onClick={handleOk}
          >
            Ok
          </Button>
        </div>
      </div>
    </form>
  );
};

DialogView.propTypes = {
  /** List of existing members */
  members: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Cancel button */
  onCancel: PropTypes.func,
  /** Create button */
  onOk: PropTypes.func,
  /** variant: People, Team, Department */
  variant: PropTypes.string,
  /** Show the options of item */
  showItems: PropTypes.bool,
  /** Boolean that disables edit in chip input */
  disableEdit: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  /** a tab for choosing multiple types of member */
  tabIndex: PropTypes.number,
  /** callback to be invoked when the tab is changed */
  onTabChange: PropTypes.func,
  /** describes the style variant of the component */
  usage: PropTypes.string,
};

DialogView.defaultProps = {
  members: [],
  onCancel: () => {},
  onOk: () => {},
  variant: 'People',
  showItems: true,
  disableEdit: false,
  tabs: [],
  tabIndex: 0,
  onTabChange: () => {},
  usage: 'default',
};

export default DialogView;
