import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import useMenuAnchor from 'hooks/useMenuAnchor';
import postMessage from 'utils/postMessage';
import { useRightHidden, useRightSelection } from 'store/sidebar';

import IconButton from 'components/buttons/iconButton';
import ICONS from 'assets/icons/icons';
import { MenuWrapper, Menu, MenuItem } from './styled';

const graphicsOptions = [
  {
    title: 'Edit graphics',
    action: 'open-in-editor',
  },
];

const getDefaultOptions = (hasPlaceholder) => [
  {
    title: 'Details...',
    action: 'show-details',
  },
  ...[
    {
      ...(hasPlaceholder
        ? {
            title: 'Remove Placeholder',
            action: 'remove-placeholder',
          }
        : { title: 'Create Placeholder', action: 'create-placeholder' }),
    },
  ],
];

const EllipsisMenu = ({
  className,
  mosobj,
  isGraphic,
  hasPlaceholder,
  handleOpenDetails,
  openPlaceholderDialog,
  removePlaceholder,
}) => {
  const { anchorEl, closeMenu, toggleMenu } = useMenuAnchor();

  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();

  const onClick = useCallback(({ action }) => {
    closeMenu();

    if (action === 'show-details') handleOpenDetails();

    if (action === 'open-in-editor' && mosobj) {
      postMessage(mosobj, 'pilotedge');
      setRightHidden(false);
      setRightSelection('graphicsbox');
    }

    if (action === 'create-placeholder') openPlaceholderDialog();

    if (action === 'remove-placeholder') removePlaceholder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(
    () => (isGraphic ? graphicsOptions : getDefaultOptions(hasPlaceholder)),
    [hasPlaceholder, isGraphic],
  );

  const MenuList = useMemo(
    () =>
      options &&
      options.length > 0 &&
      options.map((item) => (
        <MenuItem key={item.title} onClick={() => onClick(item)}>
          {item.title}
        </MenuItem>
      )),
    [onClick, options],
  );

  return (
    <MenuWrapper className={className}>
      <IconButton icon={ICONS.MORE_VERTICAL} onClick={toggleMenu} />
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        PaperProps={{
          onMouseLeave: closeMenu,
        }}
      >
        {MenuList}
      </Menu>
    </MenuWrapper>
  );
};

EllipsisMenu.propTypes = {
  /** CSS class to override styling */
  className: PropTypes.string,
};

EllipsisMenu.defaultProps = {
  className: '',
};

export default memo(EllipsisMenu);
