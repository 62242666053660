import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Wrapper = ({ children, showRightMargin, rightMarginWidth }) => {
  const classes = useStyles({ showRightMargin, rightMarginWidth });

  return <div className={classes.root}>{children}</div>;
};

Wrapper.propTypes = {
  /** React children */
  children: PropTypes.node,
  /** Specifies if right margin should be added or not */
  showRightMargin: PropTypes.bool,
  /** width of the right margin */
  rightMarginWidth: PropTypes.number,
};

Wrapper.defaultProps = {
  children: null,
  showRightMargin: false,
  rightMarginWidth: undefined,
};

export default memo(Wrapper);
