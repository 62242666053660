import React from 'react';
import PropTypes from 'prop-types';
import Users from 'components/avatarGroup/users';
import Avatar from 'components/avatar';
import { avatarVariants } from 'components/avatar/Avatar';
import capitalize from 'utils/capitalize';
import { Typography } from '@material-ui/core';
import { distanceInWordsToNow } from 'date-fns';
import useStyles from './assignees-styles';

const Assignees = ({
  updatedAt,
  assignees,
  onClick,
  showEmptyPlaceholder,
  size,
  disableScaling,
  numOfUsers,
}) => {
  const classes = useStyles();
  const isEmpty = assignees.length === 0;

  return (
    <div className={classes.container}>
      {updatedAt && (
        <div className={classes.assignmentInfo}>
          <Typography classes={{ root: classes.updatedAt }}>
            {capitalize(
              distanceInWordsToNow(updatedAt, {
                addSuffix: true,
                includeSeconds: true,
              }),
            )}
          </Typography>

          {!isEmpty && (
            <Typography classes={{ root: classes.assigneeName }}>{assignees[0].mTitle}</Typography>
          )}
        </div>
      )}

      {isEmpty && showEmptyPlaceholder ? (
        <Avatar
          pointer
          variant={avatarVariants.placeholder}
          {...{ size, onClick, disableScaling }}
        />
      ) : (
        <Users pointer data={assignees} {...{ size, onClick, disableScaling, numOfUsers }} />
      )}
    </div>
  );
};

Assignees.propTypes = {
  /** List of People assigned.
   *  Only the username of first people of the list is displayed
   */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mTitle: PropTypes.string,
    }),
  ),
  /** Time of last update */
  updatedAt: PropTypes.string,
  /** Callback to be invoked when avatar overflow is clicked */
  onClick: PropTypes.func,
  /** If true, show an Avatar placeholder variant when assignee list is empty */
  showEmptyPlaceholder: PropTypes.bool,
  /** Size of the Avatar */
  size: PropTypes.number,
};

Assignees.defaultProps = {
  assignees: [],
  updatedAt: '',
  onClick: () => {},
  showEmptyPlaceholder: false,
  size: 24,
};

export default Assignees;
