import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Scrollbar from 'screens/main/components/scrollbar';
import useStyles from './leftMenu-styles';
import Timeline from './timeline';

const LeftMenu = ({ headerTitle, menuItems, onItemClick, selectedLeftMenu, loading }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>{headerTitle}</Typography>
      </div>
      <Divider />
      <div className={classes.menuContainer}>
        <Scrollbar>
          {loading ? (
            <LoadingIndicator />
          ) : (
            menuItems.map((menuItem, index) => (
              <Timeline
                items={menuItem.items}
                onClick={onItemClick}
                selectedItem={selectedLeftMenu}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              />
            ))
          )}
        </Scrollbar>
      </div>
    </div>
  );
};

LeftMenu.propTypes = {
  /** Title of the left menu */
  headerTitle: PropTypes.string,
  /** Menu items */
  menuItems: PropTypes.arrayOf(PropTypes.object),
  /** Function to run on menu item clicked */
  onItemClick: PropTypes.func,
  /** Selected item from the menu items */
  selectedLeftMenu: PropTypes.shape({}),
  /** If the left menu items are loading */
  loading: PropTypes.bool,
};

LeftMenu.defaultProps = {
  headerTitle: 'Version History',
  menuItems: [],
  onItemClick: () => {},
  loading: false,
  selectedLeftMenu: undefined,
};

export default LeftMenu;
