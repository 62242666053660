import variants from 'utils/instance/variants';
import uuidv1 from 'uuid/v1';
import elementTypes from './types/elementTypes';
import version from './version';

const initialValue = {
  document: [
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
  version,
};

const twitterInitialValue = {
  version,
  document: [
    {
      type: 'tweet-thread',
      data: {},
      children: [
        {
          type: 'description',
          data: {
            content: '',
          },
          children: [
            {
              text: '',
            },
          ],
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const facebookInitialValue = {
  version,
  document: [
    {
      type: 'description',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {
        content: [],
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const cmsBlockInitialValue = {
  version,
  document: [
    {
      type: 'title',
      data: {
        content: '',
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'abstract',
      data: {
        content: '',
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'section',
      data: {
        content: [],
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'source',
      data: {
        content: [],
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'author',
      data: {
        content: [],
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {
        enableAutocomplete: true,
        content: [],
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const instagramInitialValue = {
  version,
  document: [
    {
      type: 'description',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {
        content: [],
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const youtubeInitialValue = {
  version,
  document: [
    {
      type: 'title',
      data: {
        content: '',
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'description',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'video',
      data: {
        showThumbnail: true,
        itemId: uuidv1(),
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {
        content: [],
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'privacy',
      data: {
        content: 'public',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const noteInitialValue = {
  version,
  document: [
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const initialValues = (variant, isAllowed, isCmsBlock) => {
  if (variant === variants.TWITTER && isAllowed) return twitterInitialValue;
  if (variant === variants.FACEBOOK && isAllowed) return facebookInitialValue;
  if (variant === variants.INSTAGRAM && isAllowed) return instagramInitialValue;
  if (variant === variants.CMS && isCmsBlock && isAllowed) return cmsBlockInitialValue;
  if (variant === variants.CMS && isAllowed) return initialValue;
  if (variant === variants.YOUTUBE && isAllowed) return youtubeInitialValue;
  if (variant === variants.NOTES) return noteInitialValue;
  return initialValue;
};

export default initialValues;
