import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';

import UserCtx from 'contexts/UserContext';
import memberTypes from 'graphql/memberTypes';
import useLogger from 'utils/useLogger';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCreateStory from 'hooks/useCreateStory';
import { useSidebar } from 'store';
import { useAddContentTab } from 'store/tabs';

import Popover from 'components/shared/popover';
import CreateStory from 'components/createNewV2';
import Create from './create-view';

export const CreateWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
`;

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const CreateTab = (props) => {
  const [{ leftHidden, leftSelection }] = useSidebar();
  const addContentTab = useAddContentTab();
  const { mId } = useContext(UserCtx);
  const [anchorEl, setAnchorEl] = useState(null);
  const logger = useLogger('navbar/tab/create-container');
  const [createStoryForUser] = useCreateStory();
  const [checkUserRight] = useCheckUserRight();
  const canCreateStory = checkUserRight('story', 'create');
  const canCreatePitch = checkUserRight('pitch', 'create');
  const enableRestrictionOption = checkUserRight('story', 'access-restriction');

  const handleCreate = async (mTitle, isRestricted, mPublishingAt, isPitch) => {
    try {
      setAnchorEl(null);
      const createdId = await createStoryForUser(
        mId,
        mTitle,
        isPitch ? memberTypes.USER_PITCH : memberTypes.USER_STORY,
        mId,
        isRestricted,
        mPublishingAt,
      );
      addContentTab({
        type: isPitch ? memberTypes.PITCH : memberTypes.STORY,
        title: mTitle,
        id: createdId,
      });
      if (
        !leftHidden &&
        (leftSelection === memberTypes.STORY ||
          leftSelection === memberTypes.PITCH ||
          leftSelection === memberTypes.USER)
      ) {
        const elm = document.getElementById('scrollbar');

        if (elm && elm.firstChild) {
          setTimeout(() => {
            elm.firstChild.scrollTop = 0;
          }, 1000);
        }
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const onCreateStory = async (mTitle, isRestricted, mPublishingAt) => {
    await handleCreate(mTitle, isRestricted, mPublishingAt, false);
  };

  const onCreatePitch = async (mTitle, isRestricted, mPublishingAt) => {
    await handleCreate(mTitle, isRestricted, mPublishingAt, true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <CreateWrapper>
      <Create onClick={handleClick} {...props} />
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <CreateStory
          hideRestrictedOption={!enableRestrictionOption}
          canCreateStory={canCreateStory}
          canCreatePitch={canCreatePitch}
          onCreateStory={onCreateStory}
          onCreatePitch={onCreatePitch}
          onCancel={handleClose}
        />
      </Popover>
    </CreateWrapper>
  );
};

export default CreateTab;
