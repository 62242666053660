import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  messages: {
    '& > :first-child': {
      marginTop: '16px',
    },
  },
  loadMoreButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
