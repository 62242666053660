import React from 'react';
import PropTypes from 'prop-types';

import { useTabs, useMaxVisibleTabs } from 'store/tabs';

import TabItemsView from './tabItems-view';

import { StyledDropdownTabsWrapper } from './tabItems-styled';

const TabItemsContainer = (props) => {
  const { handleDropDownClose } = props;
  const [tabs] = useTabs();
  const [maxVisibleTabs] = useMaxVisibleTabs();

  return (
    <StyledDropdownTabsWrapper>
      {tabs.map(
        (tab, index) =>
          tab.tabType !== 'icon' && (
            <TabItemsView
              tab={tab}
              visible={index < maxVisibleTabs}
              index={index}
              handleDropDownClose={handleDropDownClose}
            />
          ),
      )}
    </StyledDropdownTabsWrapper>
  );
};

TabItemsContainer.propTypes = {
  /** callback for dropdown close */
  handleDropDownClose: PropTypes.func.isRequired,
};

export default TabItemsContainer;
