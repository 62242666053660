import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';
import memberTypes from 'graphql/memberTypes';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import { ReactComponent as ChatEnabled } from 'assets/icons/systemicons/HeaderNavbar/chat_enabled.svg';
import useStyles from './iconComponent-styles';

const IconComponent = ({ variant, mAvatarUrl }) => {
  const classes = useStyles();
  if (variant === memberTypes.MESSAGE || variant === memberTypes.CONVERSATION) {
    if (mAvatarUrl) return <Avatar src={mAvatarUrl} size={40} />;
    return <ChatEnabled className={classes.icon} />;
  }
  return <img src={mAvatarUrl || fallbackImage} className={classes.image} alt="" />;
};

IconComponent.propTypes = {
  /** variant of alert component */
  variant: PropTypes.string,
  /** url of avatar of alert */
  mAvatarUrl: PropTypes.string,
};

IconComponent.defaultProps = {
  variant: memberTypes.MESSAGE,
  mAvatarUrl: '',
};

export default IconComponent;
