import React, { useState, useCallback, useRef, memo } from 'react';
import { useSlate } from 'slate-react';
import { Transforms, Editor } from 'slate';
import { elementTypes } from 'components/editor/constants';
import Dialog from 'components/dialog';
import { ReactComponent as IconComponent } from 'assets/icons/systemicons/editor/linkSmall_off.svg';
import wrapLink from 'components/editor/components/link/utils/wrapLink';
import PropTypes from 'prop-types';
import Button from 'components/buttons/button';
import styled from '@emotion/styled';
import theme from 'theme/theme';
import ButtonBase from '../buttonBase';
import DialogContent from './components/dialogContent';
import actionTypes from './constants/actionTypes';

const StyledButton = styled(Button)`
  border: none;
  ${theme.typography.dina.label};
  color: ${theme.palette.dina.disabled};
  cursor: pointer;
  padding: 0px;
  :hover {
    color: ${theme.palette.dina.highEmphasis};
    background: none;
  }
`;

const LinkButton = ({ variant }) => {
  const editor = useSlate();
  const { selection, getFragment } = editor;
  const selectionCacheRef = useRef(selection);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [match] = Editor.nodes(editor, {
    match: ({ type }) => type === elementTypes.LINK,
  });

  const { data, children } = match?.[0] || {};

  const initialLink = data?.href;

  let initialLinkText = children?.[0]?.text;

  if (!initialLinkText && variant !== 'inline') {
    const fragments = getFragment();
    initialLinkText = initialLinkText || fragments?.[0]?.children?.[0]?.text;
  }

  const domSelection = window.getSelection();
  // This is to check if selection has a single link node or composite node
  const isCompositeSelection = domSelection?.baseNode?.data !== domSelection?.extentNode?.data;

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      if (Boolean(match) && isCompositeSelection) return;
      if (selection) {
        selectionCacheRef.current = selection;
        openDialog();
      }
    },
    [selection, openDialog, isCompositeSelection, match],
  );

  const update = useCallback(
    ({ type, payload }) => {
      closeDialog();

      Transforms.select(editor, selectionCacheRef.current);

      if (type === actionTypes.CONFIRM) {
        const { href, linkText } = payload;
        wrapLink(editor, href, linkText);
      }
    },
    [closeDialog, editor],
  );

  const getButtonComponent = () => {
    if (variant === 'inline')
      return (
        <StyledButton type="button" onClick={onMouseDown}>
          Edit Link
        </StyledButton>
      );

    return (
      <ButtonBase
        isActive={Boolean(match)}
        type={elementTypes.LINK}
        {...{ IconComponent, onMouseDown }}
      />
    );
  };

  return (
    <>
      {getButtonComponent()}
      <Dialog onClose={closeDialog} open={dialogOpen}>
        <DialogContent {...{ initialLink, initialLinkText, update }} />
      </Dialog>
    </>
  );
};

LinkButton.propTypes = {
  variant: PropTypes.string,
};

LinkButton.defaultProps = {
  variant: 'default',
};

export default memo(LinkButton);
