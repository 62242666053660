import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'components/chip';
import {
  RootWrapper,
  ContentWrapper,
  Header,
  TextArea,
  ChipContainer,
} from './profileDetails-styled';

const ProfileDetails = (props) => {
  const {
    headerValue,
    textValue,
    showDeleteButton,
    showAsChipComponent,
    members,
    variant,
    showAvatar,
  } = props;
  return (
    <RootWrapper>
      <ContentWrapper>
        <Header>{headerValue}</Header>
        {!showAsChipComponent && <TextArea>{textValue}</TextArea>}
        {showAsChipComponent && members?.length > 0 && (
          <ChipContainer>
            {members?.map((member) => (
              <Chip
                key={`${member?.mRefId}`}
                label={member?.mTitle}
                avatarUrl={member?.mAvatarUrl}
                showDeleteButton={showDeleteButton}
                variant={variant}
                showAvatar={showAvatar}
                width="auto"
                height={40}
                labelType="listItemLabel"
              />
            ))}
          </ChipContainer>
        )}
      </ContentWrapper>
    </RootWrapper>
  );
};

ProfileDetails.propTypes = {
  /** Value of header of the info content */
  headerValue: PropTypes.string,
  /** Value of content under header */
  textValue: PropTypes.string,
  /** Boolean to show list as chip component */
  showAsChipComponent: PropTypes.bool,
  /** Array of teams/depratments/groups user belongs to */
  members: PropTypes.arrayOf(PropTypes.any),
  /** boolean to show the delete button */
  showDeleteButton: PropTypes.bool,
  /** Avatar Variant  */
  variant: PropTypes.string,
  /** show avatar or not: values- true/false */
  showAvatar: PropTypes.bool,
};

ProfileDetails.defaultProps = {
  headerValue: '',
  textValue: '',
  showAsChipComponent: false,
  members: [],
  showDeleteButton: false,
  variant: 'People',
  showAvatar: false,
};

export default ProfileDetails;
