import { useAtom, atom, useSetAtom, useAtomValue } from 'jotai';
import { focusAtom } from 'jotai/optics';

import memberTypes from 'graphql/memberTypes';

import { policiesAtom } from 'store/config';
import { removeStoryLayoutByStoryId } from 'utils/storyHelper/storyHelper';
import { insertIntoArray, moveArrayItem } from 'utils/arrayUtils';

export const rundownTabType = {
  type: memberTypes.RUNDOWN,
  title: '',
  id: '',
  mid: '',
  selectedDate: new Date(),
  mType: '',
};

export const storyPitchTabType = {
  type: memberTypes.PITCH || memberTypes.STORY,
  title: '',
  id: '',
  image: '',
};

export const iconTabType = {
  title: '',
  type: '',
  tabType: 'icon',
};

const iconTabsArray = [
  {
    title: 'Home',
    type: 'home',
    tabType: 'icon',
  },
  {
    title: 'Feeds',
    type: 'feeds',
    tabType: 'icon',
  },
  {
    title: 'StoryHub',
    type: 'plans',
    tabType: 'icon',
  },
  {
    title: 'Maps',
    type: 'maps',
    tabType: 'icon',
  },
];

const initialValues = {
  tabs: [],
  tabIndex: 0,
  currentTab: { title: 'Home', type: 'home', tabType: 'icon' },
  maxVisibleTabs: 0,
};

const getResourcePermission = (resource, action, defaultValue) => {
  const { permissions = [] } = resource ?? {};
  const permission = permissions.find((p) => p.action === action);
  if (!permission) return defaultValue;
  if (permission.access && permission.access === 'allow') return true;
  return false;
};

const checkUserRight = (policies, resourceName, action) => {
  const defaultPermission = resourceName !== 'feature';
  const resource = policies.find((r) => r.resourceName === resourceName);
  if (!resource) return defaultPermission;
  return getResourcePermission(resource, action, defaultPermission);
};

const navStorageAtom = atom(JSON.parse(localStorage.getItem('navbar')) ?? initialValues);

const navAtom = atom(
  (get) => {
    const defaultValues = get(navStorageAtom);
    const policies = get(policiesAtom);

    if (defaultValues.tabs.length === 0) {
      const iTabs = iconTabsArray.filter((tab) => {
        const { type } = tab;
        switch (type) {
          case 'home':
            return true;
          case 'plans':
            return checkUserRight(policies, 'storyhub', 'access');
          case 'feeds':
            return checkUserRight(policies, 'feeds', 'access');
          case 'maps':
            return checkUserRight(policies, 'feature', 'maps');
          default:
            return false;
        }
      });
      defaultValues.tabs = [...iTabs];
    }

    return { ...defaultValues, tabs: [...defaultValues.tabs] };
  },
  (_get, set, nextValue) => {
    set(navStorageAtom, nextValue);
    localStorage.setItem('navbar', JSON.stringify(nextValue));
  },
);
export const useNavbar = () => useAtom(navAtom);

// tabs atom
const tabsAtom = focusAtom(navAtom, (optic) => optic.prop('tabs'));
export const useTabs = () => useAtom(tabsAtom);

// tab index
const tabIndexAtom = focusAtom(navAtom, (optic) => optic.prop('tabIndex'));
export const useTabIndexValue = () => useAtomValue(tabIndexAtom);

const setTabIndexAtom = atom(null, (get, set, nextValue) => {
  const currentTabIndex = get(tabIndexAtom);
  const tabs = get(tabsAtom);
  const maxVisibleTabs = get(maxVisibleTabsAtom);
  // if the current tab is already selected, do nothing and return
  if (currentTabIndex === nextValue) return;
  // if not, set the current tab values
  const nextTab = tabs[nextValue];
  set(currentTabAtom, nextTab);
  // check if next tab index is visible
  // if visible, set the tab index and return
  if (nextValue < maxVisibleTabs - 1) {
    set(tabIndexAtom, nextValue);
    return;
  }
  // if not visible, update the tabs array and set the tab index
  const updatedArray = moveArrayItem(tabs, nextValue, maxVisibleTabs - 1);
  set(tabsAtom, updatedArray);
  set(tabIndexAtom, maxVisibleTabs - 1);
});
export const useSetTabIndex = () => useSetAtom(setTabIndexAtom);

// max content tabs
const maxVisibleTabsAtom = focusAtom(navAtom, (optic) => optic.prop('maxVisibleTabs'));
export const useMaxVisibleTabs = () => useAtom(maxVisibleTabsAtom);

// current tab atom
const currentTabAtom = focusAtom(navAtom, (optic) => optic.prop('currentTab'));
// current tab value
export const useCurrentTabValue = () => useAtomValue(currentTabAtom);

/**
 * adds new content tab
 * @param  {Object} nextValue
 * @param  {string} nextValue.id - id of the content tab
 */
const addContentTabAtom = atom(null, (get, set, nextValue) => {
  const { id } = nextValue;
  const tabs = get(tabsAtom);
  const maxVisibleTabs = get(maxVisibleTabsAtom);
  const currentTabIndex = get(tabIndexAtom);

  // check if the tab already exists
  const existingTab = tabs.find((tab) => tab.id === String(id));
  let tabIndex = tabs.indexOf(existingTab);
  let updatedTabs = [];

  // if it's current tab, return
  if (tabIndex === currentTabIndex) return;

  // if it's not the current tab & it doesn't exist in the array, add it
  // update the tabs array,
  // set it to be the current tab &
  // set the tab index accordingly
  if (!existingTab) {
    // if the max content tabs is reached, insert the new tab at last visible tabs index
    if (tabs.length >= maxVisibleTabs) {
      // update the updated tabs array & tab index
      updatedTabs = insertIntoArray(tabs, maxVisibleTabs - 1, nextValue);
      tabIndex = maxVisibleTabs - 1;
    } else {
      updatedTabs = [...tabs, nextValue];
      tabIndex = updatedTabs.length - 1;
    }

    set(tabsAtom, updatedTabs);
    set(currentTabAtom, nextValue);
    set(tabIndexAtom, tabIndex);
    return;
  }

  // if it already exists, check if it's index is lower than max visible tabs index
  // if it is, set it to be the current tab & set the tab index accordingly
  if (tabIndex < maxVisibleTabs - 1) {
    set(currentTabAtom, existingTab);
    set(tabIndexAtom, tabIndex);
    return;
  }
  // if it's index is higher than max visible tabs index,
  // change it's position to be the last visible tab
  // set it to be the current tab & set the tab index accordingly
  updatedTabs = moveArrayItem(tabs, tabIndex, maxVisibleTabs - 1);
  set(tabsAtom, updatedTabs);
  set(currentTabAtom, existingTab);
  set(tabIndexAtom, maxVisibleTabs - 1);
});
export const useAddContentTab = () => useSetAtom(addContentTabAtom);

/**
 * closes content tab
 * @param {Object} nextValue
 * @param {string} [nextValue.id] - id of the content tab to close
 * @param {string} [nextValue.mid] - mid of the content tab to close
 */
const closeContentTabAtom = atom(null, (get, set, nextValue) => {
  const { id, mid } = nextValue;
  const tabs = get(tabsAtom);
  const currentTabIndex = get(tabIndexAtom);
  const closingTabIndex = tabs.findIndex((tab) => tab.id === String(id) || tab.mid === String(mid));
  const iconTabsLength = tabs.filter((tab) => tab.tabType === 'icon').length;
  let updatedTabs = [];

  if (id) {
    updatedTabs = tabs.filter((tab) => tab.id !== id);
    removeStoryLayoutByStoryId(id);
  }

  if (mid) {
    updatedTabs = tabs.filter((tab) => tab.mid !== mid);
  }

  // updating tabs array
  set(tabsAtom, updatedTabs);

  // if not closing the current tab
  if (closingTabIndex !== currentTabIndex) {
    // if tab index is higher than current tab, we don't need to do anything
    if (closingTabIndex > currentTabIndex) {
      return;
    }
    // if lower, decrease the tab index
    set(tabIndexAtom, currentTabIndex - 1);
    return;
  }

  // when closing the current tab
  // if closing the last tab, select the previous one
  if (closingTabIndex === updatedTabs.length) {
    if (updatedTabs.length > iconTabsLength) {
      set(currentTabAtom, updatedTabs[updatedTabs.length - 1]);
      set(tabIndexAtom, updatedTabs.length - 1);
    } else {
      // if there's not content tab left, set the current tab to the first icon tab
      set(currentTabAtom, initialValues.currentTab);
      set(tabIndexAtom, initialValues.tabIndex);
    }
    return;
  }
  // if not closing the last tab, select the next one
  set(currentTabAtom, updatedTabs[closingTabIndex]);
  set(tabIndexAtom, closingTabIndex);
});
export const useSetCloseContentTab = () => useSetAtom(closeContentTabAtom);

const closeInactiveTabsAtom = atom(null, (get, set) => {
  const tabs = get(tabsAtom);
  const currentTab = get(currentTabAtom);
  // close all tabs except the current tab
  const updatedTabs = tabs.filter((tab) => tab.id === currentTab.id || tab.tabType === 'icon');
  const newTabIndex = updatedTabs.indexOf(currentTab);
  set(tabsAtom, updatedTabs);
  set(tabIndexAtom, newTabIndex);
});
export const useSetCloseInactiveTabs = () => useSetAtom(closeInactiveTabsAtom);

const closeOtherTabsAtom = atom(null, (get, set, nextValue) => {
  const tabs = get(tabsAtom);
  const updatedTabs = tabs.filter((tab) => tab.id === nextValue.id || tab.tabType === 'icon');
  set(tabsAtom, updatedTabs);
  set(currentTabAtom, nextValue);
  set(tabIndexAtom, updatedTabs.indexOf(nextValue));
});
export const useSetCloseOtherTabs = () => useSetAtom(closeOtherTabsAtom);

const closeAllTabsAtom = atom(null, (get, set) => {
  const tabs = get(tabsAtom);
  // close all tabs
  const updatedTabs = tabs.filter((tab) => tab.tabType === 'icon');
  set(tabsAtom, updatedTabs);
  set(currentTabAtom, initialValues.currentTab);
  set(tabIndexAtom, initialValues.tabIndex);
});
export const useSetCloseAllTabs = () => useSetAtom(closeAllTabsAtom);

/**
 * @param  {Object} nextValue - tab
 */
const closeTabsToLeftAtom = atom(null, (get, set, nextValue) => {
  const tabs = get(tabsAtom);
  const tabIndex = tabs.indexOf(nextValue);
  const currentTab = get(currentTabAtom);
  const currentTabIndex = get(tabIndexAtom);
  // close all tabs to the left of the current tab
  const updatedTabs = tabs.filter((tab, index) => index >= tabIndex || tab.tabType === 'icon');
  set(tabsAtom, updatedTabs);
  // if currently selected tab is filtered out
  // set provided tab as current tab
  if (currentTabIndex < tabIndex) {
    set(currentTabAtom, nextValue);
    set(tabIndexAtom, updatedTabs.indexOf(nextValue));
    return;
  }
  // else just update the current tab index
  set(tabIndexAtom, updatedTabs.indexOf(currentTab));
});
export const useSetCloseTabsToLeft = () => useSetAtom(closeTabsToLeftAtom);

/**
 * @param  {Object} nextValue - tab
 */
const closeTabsToRightAtom = atom(null, (get, set, nextValue) => {
  const tabs = get(tabsAtom);
  const tabIndex = tabs.indexOf(nextValue);
  const currentTabIndex = get(tabIndexAtom);
  // close all tabs to the right of the current tab
  const updatedTabs = tabs.filter((tab, index) => index <= tabIndex || tab.tabType === 'icon');
  set(tabsAtom, updatedTabs);

  if (currentTabIndex > tabIndex) {
    set(currentTabAtom, nextValue);
    set(tabIndexAtom, updatedTabs.indexOf(nextValue));
  }
});
export const useSetCloseTabsToRight = () => useSetAtom(closeTabsToRightAtom);

/**
 * updates content tabs title
 * @param  {Object} nextValue
 * @param  {string} nextValue.id - id of the tab
 * @param  {string} nextValue.title - title of the tab
 */
const updateContentTabTitleAtom = atom(null, (get, set, nextValue) => {
  const { id, title } = nextValue;
  const tabs = get(tabsAtom);
  const index = tabs.findIndex((tab) => tab.id === String(id));
  const tabToBeUpdated = tabs[index];
  if (!tabToBeUpdated) return;
  tabToBeUpdated.title = title;
  set(tabsAtom, [...tabs]);
});
export const useSetUpdateContentTabTitle = () => useSetAtom(updateContentTabTitleAtom);

const onDropTabItemAtom = atom(null, (get, set, nextValue) => {
  const { itemIndex, dropIndex } = nextValue;
  const tabs = get(tabsAtom);
  const currentTabIndex = get(tabIndexAtom);
  // move tab items in the array based on index
  const updatedTabsArray = moveArrayItem(tabs, itemIndex, dropIndex);
  const updatedCurrentTabIndex = updatedTabsArray.findIndex(
    (tab) => tab.id === get(currentTabAtom).id,
  );
  // update the current tab index if changed
  currentTabIndex !== updatedCurrentTabIndex && set(tabIndexAtom, updatedCurrentTabIndex);
  // finally update tabs array
  set(tabsAtom, updatedTabsArray);
});
export const useSetOnDropTabItem = () => useSetAtom(onDropTabItemAtom);
