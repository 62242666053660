import React from 'react';
import PropTypes from 'prop-types';
import distanceInWord from 'utils/distanceInWords';
import { ButtonBase } from '@material-ui/core';
import Divider from 'components/divider';
import memberTypes from 'graphql/memberTypes';
import IconComponent from './iconComponent';
import DetailComponent from './detailComponent';
import useStyles from './alert-styles';

const Alert = ({
  mAvatarUrl,
  onOk,
  onCancel,
  mCreatedAt,
  variant,
  contentValue,
  contentHighlightedText,
  alertTitle,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.alertRoot}>
      <div className={classes.iconContainer}>
        <div className={classes.iconComponent}>
          <IconComponent variant={variant} mAvatarUrl={mAvatarUrl} />
        </div>
      </div>
      <div className={classes.detailContainer}>
        <div className={classes.timeInfo}>{distanceInWord(mCreatedAt)}</div>
        <div className={classes.alertTitle}>{alertTitle}</div>
        <DetailComponent
          contentValue={contentValue}
          variant={variant}
          contentHighlightedText={contentHighlightedText}
        />
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.buttonContainer}>
        <ButtonBase classes={{ root: classes.buttonRoot }} onClick={onOk}>
          {variant === memberTypes.CONVERSATION ||
          variant === memberTypes.MESSAGE ||
          variant === memberTypes.TEAM ||
          variant === memberTypes.DEPARTMENT
            ? 'Show'
            : 'Open'}
        </ButtonBase>
        <Divider className={classes.divider} />
        <ButtonBase classes={{ root: classes.buttonRoot }} onClick={onCancel}>
          Dismiss
        </ButtonBase>
      </div>
    </div>
  );
};

Alert.propTypes = {
  /** variant of alert component */
  variant: PropTypes.string,
  /** callback of on ok button */
  onOk: PropTypes.func,
  /** callback of on cancel button */
  onCancel: PropTypes.func,
  /** creation time of the alert */
  mCreatedAt: PropTypes.string,
  /** value of content */
  contentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  /** url of avatar of alert */
  mAvatarUrl: PropTypes.string,
  /** text to be highlighted in content */
  contentHighlightedText: PropTypes.string,
  /** title of the alert */
  alertTitle: PropTypes.string,
};

Alert.defaultProps = {
  variant: 'message',
  onOk: () => {},
  onCancel: () => {},
  mCreatedAt: new Date().toISOString(),
  contentValue: undefined,
  mAvatarUrl: '',
  contentHighlightedText: '',
  alertTitle: '',
};

export default Alert;
