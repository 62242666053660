import { makeStyles } from '@material-ui/styles';
import { avatarVariants } from 'components/avatar/Avatar';

const menuWidth = 400;

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: '16px',
  },
  avatarText: {
    ...theme.typography.dina.listItemLabel,
    fontSize: '10px',
    color: ({ avatarVariant }) => {
      if (avatarVariant === avatarVariants.team) return theme.palette.dina.blackHighEmphasis;
      return theme.palette.dina.highEmphasis;
    },
  },
  iconChild: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    ...theme.typography.dina.caption,
    marginRight: '4px',
  },
  menuIcon: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  menu: {
    width: menuWidth,
    padding: '24px 12px 24px 24px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    width: menuWidth - 24 - 12,
    backgroundColor: theme.palette.dina.surfaceDialogs,
    borderRadius: 4,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),' +
      '0px 8px 10px 1px rgba(0,0,0,0.14),' +
      '0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
}));

export default useStyles;
