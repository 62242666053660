import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  backdropRoot: {
    position: 'absolute',
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.dina.surfaceCard,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '8px',
  },
  iconEmphasis: {
    '& path': {
      fill: theme.palette.dina.highEmphasis,
      fillOpacity: 1,
      transition: '0.3s ease',
    },
  },
  tabs: {
    width: '100%',
    height: '48px',
  },
  iframeContainer: {
    width: '100%',
    height: 'calc(100% - 164px)',
    backgroundColor: theme.palette.dina.statusOnPress,
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  iFrame0: {
    width: '375px',
    height: '740px',
    padding: '20px',
  },
  iFrame1: {
    width: '723px',
    height: '375px',
  },
  iFrame2: {
    width: '1024px',
    height: '768px',
    padding: '25px',
  },
  iFrame3: {
    width: '100%',
    height: '100%',
  },
  iFrame: {
    width: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: ({ hasPreview }) =>
      theme.palette.dina[hasPreview ? 'highEmphasis' : 'statusOnPress'],
  },
}));

export default useStyles;
