import styled from '@emotion/styled';
import colors from 'theme/colors';
import { h6 } from 'theme/styledTypography';

export const RootWrapper = styled('div')`
  height: 50px;
  align-items: center;
  display: flex;
  user-select: none;
  background: ${colors.backgroundResting};
`;

export const GroupHeaderWrapper = styled('div')`
  display: flex;
  cursor: pointer;
  padding-left: 12px;
  :hover {
    path {
      fill: ${colors.highEmphasis};
      fill-opacity: 1;
    }
  }
  ${h6}
`;
