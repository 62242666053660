import AUDIT_FAILURE from 'graphql/mutations/auditFailure';
/**
 * Sends an Error object to the DiNA backend
 * @param {ApolloClient} client - The ApolloClient
 * @param {Error} eobj - The Error object to send
 */
const auditFailure = async (client, eobj, timeStamp) => {
  const source = process.env.REACT_APP_REPO_NAME || '7m-frontend';
  const branchName = process.env.REACT_APP_REPO_BRANCHNAME || process.env.NODE_ENV;
  const commitId = process.env.REPO_COMMIT_ID || '';

  const dinaVersion = process.env.REACT_APP_VERSION || 'v0.0.0';
  // const dinaVersionDate = process.env.REACT_APP_VERSION_DATE || new Date().toISOString();
  const { code, message, stack, origin } = eobj;

  try {
    const input = {
      mActionId: 'failure',
      mId: `${source}`,
      mType: source,
      mTimestamp: timeStamp.toISOString(),
      mPayload: {
        __typename: 'AuditFailureType',
        version: dinaVersion,
        origin,
        error: {
          message,
          code,
          stack,
        },
        source: {
          repository: source,
          branchName,
          commitId,
        },
      },
    };
    const resp = await client.mutate({
      mutation: AUDIT_FAILURE,
      variables: { input },
    });
    // eslint-disable-next-line no-console
    console.error('auditFailure:', JSON.stringify(resp, 0, 1));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('auditFailure:', err);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { auditFailure };
