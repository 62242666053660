import React from 'react';
import PropTypes from 'prop-types';
import MessageInput from 'components/messageInput';
import Scrollbar from 'components/scrollbar';
import Divider from 'components/divider';
import Header from './header';
import Messages from './messages';
import useStyles from './messageContents-styles';

const MessageContents = ({ createMessage, showInput }) => {
  const classes = useStyles();

  const handleSendMessage = async (newMessage, mRefId, crudAction) => {
    try {
      await createMessage(JSON.stringify(newMessage), mRefId, crudAction);
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <div className={classes.root}>
      {showInput && (
        <>
          <Header showDeleteButton={false} showAddUser={false} />
          <div className={classes.messageInput}>
            <MessageInput onSend={handleSendMessage} />
          </div>
          <Divider />
        </>
      )}
      <div className={classes.messageContainer}>
        <div className={classes.mask} />
        <Scrollbar top={5} bottom={5}>
          <Messages handleDeleteMessage={handleSendMessage} />
        </Scrollbar>
      </div>
    </div>
  );
};

MessageContents.propTypes = {
  /** message creating callback */
  createMessage: PropTypes.func,
  /** boolean to show messageInput */
  showInput: PropTypes.bool,
};

MessageContents.defaultProps = {
  createMessage: () => {},
  showInput: true,
};

export default MessageContents;
