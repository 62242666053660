import gql from 'graphql-tag';

export default gql`
  query GetRundowns($mId: String) {
    getRundowns(mId: $mId) {
      mId
      mRefId
      mOrder
      mTitle
      mCoverPhotoUrl @client(always: true)
      mCoverPhotoKey
      mThumbnailUrl @client(always: true)
      mThumbnailKey
      mType
      version
      recurrence {
        duration
        rRule
        startTime
        timeZone
        dailyExclusive
      }
    }
  }
`;
