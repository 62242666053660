import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  thumbVertical: {
    background: 'transparent',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      '& > :first-child': {
        background: theme.palette.dina.iconDisabled,
      },
    },
    '&:active': {
      '& > :first-child': {
        background: `${theme.palette.dina.iconInactive} !important`,
      },
    },
  },
  thumbHorizontal: {
    background: 'transparent',
    height: '100%',
    '&:hover': {
      '& > :first-child': {
        background: theme.palette.dina.iconDisabled,
      },
    },
    '&:active': {
      '& > :first-child': {
        background: `${theme.palette.dina.iconInactive} !important`,
      },
    },
  },
  thumbVerticalInner: {
    width: '4px',
    height: '100%',
    background: theme.palette.dina.iconInactiveUltraDiscreet,
    borderRadius: '2px',
  },
  thumbHorizontalInner: {
    height: '4px',
    width: '100%',
    background: theme.palette.dina.iconInactiveUltraDiscreet,
    borderRadius: '2px',
  },
  trackVertical: {
    transition: 'opacity 200ms ease',
    zIndex: 99,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    right: '0px',
  },
  trackHorizontal: {
    transition: 'opacity 200ms ease',
    zIndex: 99,
    position: 'absolute',
    display: 'flex',
    width: '100%',
    bottom: '0px',
    left: '0px',
  },
}));

const Scrollbar = ({ children, isDisabled }, ref) => {
  const classes = useStyles({ isDisabled });
  return (
    <Scrollbars
      ref={ref}
      hideTracksWhenNotNeeded
      id="scrollbar"
      style={{ height: '100%' }}
      renderThumbVertical={(scrollProps) => (
        <div
          {...scrollProps}
          className={classes.thumbVertical}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className={classes.thumbVerticalInner} />
        </div>
      )}
      renderTrackVertical={(scrollProps) => (
        <div {...scrollProps} className={classes.trackVertical} style={{ width: '16px' }} />
      )}
      renderThumbHorizontal={(scrollProps) => (
        <div
          {...scrollProps}
          className={classes.thumbHorizontal}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={classes.thumbHorizontalInner} />
        </div>
      )}
      renderTrackHorizontal={(scrollProps) => (
        <div {...scrollProps} className={classes.trackHorizontal} style={{ height: '16px' }} />
      )}
      renderView={(scrollProps) =>
        isDisabled ? (
          <div {...scrollProps} style={{ overflow: 'hidden', height: '100%', width: '100%' }} />
        ) : (
          <div {...scrollProps} />
        )
      }
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  valueChanged: PropTypes.string,
};

Scrollbar.defaultProps = {
  valueChanged: null,
};

const forwardedScrollbar = forwardRef(Scrollbar);
export default forwardedScrollbar;
