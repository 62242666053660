import gql from 'graphql-tag';

export default gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mThumbnailUrl
      mContentKey
      mCreatedAt
      mUpdatedAt
      mState
      mPublishingAt
      mType
      mStoryId
      mTemplateId
      isTemplateInstance
      items {
        itemId
        title
        templateType
        templateVariant
        subItems {
          itemId
          title
          templateType
          templateVariant
        }
      }
      mProperties {
        __typename
        ... on PlatformType {
          platform
          provider {
            requestId
            state
            message
            embeddedEndpoint
            previewEndpoint
            updateEndpoint
          }
          account {
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
      }
      mMetaData {
        key
        value
      }
      mAssignedMembers {
        mId
        mType
      }
      mRelatedMembers
    }
  }
`;
