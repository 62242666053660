import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '364px',
    background: theme.palette.dina.surfaceCard,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '8px',
  },
  topComponents: {
    margin: '0 16px',
    flexGrow: 1,
  },
  title: {
    ...theme.typography.dina.marketplaceHeader,
    margin: '16px',
  },
  subTitle: {
    ...theme.typography.dina.overline,
    margin: '10px 0',
  },
  radioText: {
    ...theme.typography.dina.captionRegular,
    color: theme.palette.dina.highEmphasis,
    marginLeft: '5px',
  },
  blank: {
    height: '16px',
  },
  dateTimePicker: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '56px',
    position: 'relative',
  },
  linearDateTimePicker: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '56px',
  },
  linearDatePicker: {
    width: '100%',
  },
  datePicker: {
    width: '194px',
  },
  timePicker: {
    width: '134px',
  },
  timePickerDisabled: {
    width: '150px',
    opacity: 0.35,
  },
  radioButtonContainer: {
    width: '192px',
    height: '76px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > :last-child': {
      marginTop: '4px',
    },
  },
  radioButton: {
    width: '192px',
    height: '24px',
    flex: 'none',
    alignItems: 'center',
    cursor: 'pointer',
  },
  reset: {
    ...theme.typography.dina.captionRegularUnderline,
    color: theme.palette.dina.disabled,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.dina.highEmphasis,
    },
    '&:disabled': {
      opacity: 0.35,
    },
  },
  resetDiv: {
    position: 'absolute',
    left: '202px',
    top: 'calc(100% - 14px)',
  },
  checkBox: {
    margin: 0,
    height: '40px',
  },
  checkBoxText: {
    ...theme.typography.dina.listItemLabel,
    marginLeft: 4,
  },
}));

export default useStyles;
