import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AdvancedDialog from 'components/advancedDialog';
import Popover from 'components/popover';
import Warning from 'components/instanceCard/components/warning';
import DialogBody from './components/dialogBody';
import DialogFooter from './components/dialogFooter';
import useStyles from './instanceVersionHistory-styles';

const InstanceVersionHistory = (props) => {
  const {
    open,
    title,
    id,
    onCancel,
    onOk,
    checkVersionRestorability,
    menuHeaderTitle,
    content,
    versions,
    getSpecificVersionContent,
    lockedByUser,
    isSavingContent,
    auditListLoading,
    versionContentLoading,
    versionElement,
    restricted,
  } = props;

  const menuItems = [
    {
      subtitle: 'subtitle',
      items: versions,
    },
  ];
  const [selectedVersion, setSelectedVersion] = useState(versions[0]);
  const [contentHeader, setContentHeader] = useState(versions[0] && versions[0].timestamp);
  const [showWarning, setShowWarning] = useState(false);
  const rootRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(isSavingContent);

  const onVersionSelect = async (item) => {
    setSelectedVersion(item);
    setContentHeader(item.timestamp);
    getSpecificVersionContent(item.contentKey);
  };

  const handleOnOk = async (newContent) => {
    setIsProcessingSubmit(true);
    const versionRestorable = await checkVersionRestorability();
    if (versionRestorable) {
      onOk(newContent);
    } else {
      setAnchorEl(rootRef.current);
      setShowWarning(true);
    }
    setIsProcessingSubmit(false);
  };

  const handleHideWarning = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div ref={rootRef}>
      <AdvancedDialog
        isOpen={open}
        onClose={onCancel}
        initialWidth="45%"
        initialHeight="60%"
        minWidth={840}
        minHeight={640}
        subtitle="version history"
        itemId={id}
        title={title}
        footerComponent={
          <DialogFooter
            content={content}
            selectedLeftMenu={selectedVersion}
            onCancel={onCancel}
            onOk={handleOnOk}
            isProcessingSubmit={isProcessingSubmit}
            restricted={restricted}
          />
        }
      >
        <DialogBody
          menuHeaderTitle={menuHeaderTitle}
          menus={menuItems}
          onLeftMenuItemSelect={onVersionSelect}
          selectedLeftMenu={selectedVersion}
          isMenuLoading={auditListLoading}
          contentHeaderTitle={contentHeader}
          content={content}
          isContentLoading={versionContentLoading}
        />
      </AdvancedDialog>
      {showWarning && (
        <Popover anchorEl={anchorEl} onClose={handleHideWarning} position="center">
          <div className={classes.warning}>
            <Warning
              title={lockedByUser}
              onClose={handleHideWarning}
              onConfirm={handleHideWarning}
              variant={versionElement === 'instance' ? 'instanceLocked' : 'storyLocked'}
            />
          </div>
        </Popover>
      )}
    </div>
  );
};

InstanceVersionHistory.propTypes = {
  /** Flag to set dialog open */
  open: PropTypes.bool,
  /** Title of the instance */
  title: PropTypes.string,
  /** ID of the instance */
  id: PropTypes.string,
  /** Title of the menu sidebar */
  menuHeaderTitle: PropTypes.string,
  /** on cancel button press from dialog */
  onCancel: PropTypes.func,
  versions: PropTypes.arrayOf(PropTypes.shape({})),
  /** version history element type for */
  versionElement: PropTypes.string,
};

InstanceVersionHistory.defaultProps = {
  open: false,
  title: 'Instance Version History',
  id: undefined,
  menuHeaderTitle: 'Versions',
  onCancel: () => {},
  versions: [],
  versionElement: 'instance',
};

export default InstanceVersionHistory;
