import { ReactEditor } from 'slate-react';
import { actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';

const { setNodes } = Transforms;

/**
 * Removes Block (video, image, gif, section etc. ) element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element element block object
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const updateBlock = (editor, oElement, oData, update, shouldFocus = false, allAttributes) => {
  const updates = [];
  if (allAttributes) {
    updates.push(...getAndSetAllMetaData(editor, allAttributes));
  }

  updates.push({ data: oData, element: oElement });
  if (shouldFocus) ReactEditor.focus(editor);
  updates.forEach((upd) => {
    const { data, element } = upd;
    const path = ReactEditor.findPath(editor, element);
    setNodes(editor, { data }, { at: path });
    update({
      type: actionTypes.BLOCK_UPDATE,
      payload: {
        document: editor.children,
        updatedElement: {
          ...element,
          data,
        },
      },
    });
  });

  return editor;
};

const getAndSetAllMetaData = (editor, attribute) => {
  const { children } = editor;
  const { key, value } = attribute;
  const ret = [];
  const allToSet = children.filter(
    (elm) => (elm?.data?.metadata && key in elm.data.metadata) || (elm?.data && key in elm.data),
  );
  allToSet.forEach((element) => {
    const uElement = JSON.parse(JSON.stringify(element));
    if (element.data?.metadata && key in element.data?.metadata) {
      if (element.data.metadata[key] === value) return;
      uElement.data.metadata[key] = value;
    }

    if (element.data && key in element.data) {
      if (element.data[key] === value) return;
      uElement.data[key] = value;
    }
    ret.push({ data: uElement.data, element });
  });
  return ret;
};

export default updateBlock;
