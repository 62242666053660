import { markTypes, actionTypes } from './types';

const { BOLD, ITALIC, UNDERLINE } = markTypes;
const { DONE } = actionTypes;

const hotkeys = {
  'mod+b': BOLD,
  'mod+i': ITALIC,
  'mod+u': UNDERLINE,
  'mod+enter': DONE,
};

export default hotkeys;
