import React, { useCallback, memo, useMemo } from 'react';
import { FormatQuote } from '@material-ui/icons';
import { useSlate } from 'slate-react';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { isElementActive, toggleElement } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants';
import useStyles from './styles';

const { QUOTE } = elementTypes;

const QuoteButton = () => {
  const editor = useSlate();
  const isActive = isElementActive(editor, QUOTE);
  const classes = useStyles({ isActive });

  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      toggleElement(editor, QUOTE, isActive);
    },
    [editor, isActive],
  );

  const renderContent = useMemo(
    () => (
      <div className={classes.root} role="presentation" {...{ onMouseDown }}>
        <FormatQuote className={classes.icon} title={tooltipEnum.QUOTE} />
      </div>
    ),
    [classes.icon, classes.root, onMouseDown],
  );

  return renderContent;
};

export default memo(QuoteButton);
