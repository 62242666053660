import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListSubheader, List } from '@material-ui/core';
import EllipsisButton from 'components/buttons/ellipsisButton';
import Divider from 'components/divider';
import Popover from 'components/popover';
import { Notification } from 'components/statusIndicators';
import RightMetadataOffSrc from 'assets/icons/systemicons/right_metadata_off.svg';
import MinusSrc from 'assets/icons/systemicons/minus.svg';
import PrintSrc from 'assets/icons/systemicons/print.svg';
import DownloadSrc from 'assets/icons/systemicons/download.svg';
import History from 'assets/icons/systemicons/time.svg';
import Duplicate from 'assets/icons/systemicons/duplicate.svg';
import Delete from 'assets/icons/systemicons/delete.svg';
import Notes from 'assets/icons/systemicons/notes.svg';
import PadlockSrc from 'assets/icons/systemicons/padlock_off.svg';
import InstanceVersionHistory from 'components/instanceVersionHistory';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import DeleteInstance from './components/deleteInstance';
import FoldersMenuList from '../foldersMenuList';
import MenuItem from '../menuItem';
import useStyles from './linear-ellipsis-menu-styles';

const topRight = {
  vertical: 'top',
  horizontal: 'right',
};

const centerLeft = {
  vertical: 'center',
  horizontal: 'left',
};

const LinearEllipsisMenuView = (props) => {
  const {
    metadataCount,
    onSaveTemplate,
    onSelectTemplate,
    onDeleteTemplate,
    onCreateFolder,
    onDeleteFolder,
    onMetadataSelect,
    onCreateDuplicate,
    onRemove,
    onDownload,
    onForceUnlock,
    notes,
    folders,
    showMetadata,
    hideTemplateOptions,
    writeLock,
    lockedByUser,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    onDeleteInstance,
    isDeleteEnabled,
    disableEdit,
    title,
    instanceId,
    versions,
    currentVersionContent,
    refetchVersionList,
    refetchVersionContent,
    onRestoreVersion,
    checkVersionRestorability,
    isSavingContent,
    auditListLoading,
    versionContentLoading,
    canCreateInstance,
    canDeleteInstance,
    canUpdateInstance,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [popoverComponent, setPopoverComponent] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [versionRestoreDisabled] = useState(false);
  const { selectTemplateSet, templateSets, templateSetIndex } = useGetAutomationTemplates();

  // TODO: AC3 MIGRATION
  // new items to tackle immutability issue while migration

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleMetadataClick = () => {
    closeMenu();
    onMetadataSelect();
  };

  const handlePrintInstance = () => {
    closeMenu();
    onDownload('application/pdf');
  };
  const handleDownloadInstance = () => {
    closeMenu();
    onDownload('application/xml');
  };

  const handleForceUnlock = () => {
    closeMenu();
    onForceUnlock();
  };

  const handleRemove = () => {
    closeMenu();
    onRemove();
  };

  const handleHistory = async () => {
    if (canUpdateInstance) {
      refetchVersionList();
      setHistoryDialogOpen(true);
    }
  };

  const handleRestoreVersion = async (content) => {
    await onRestoreVersion(content);
    setHistoryDialogOpen(false);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClosePopover();
    closeMenu();
    onDeleteInstance();
  };
  const OpenDelete = () => {
    setAnchor(anchorEl);
    setPopoverComponent(
      <DeleteInstance
        onCancel={handleClosePopover}
        onOk={handleDelete}
        isDeleteEnabled={isDeleteEnabled}
      />,
    );
  };
  const handleOpenNotes = () => {};
  const handleClosePopover = () => {
    setAnchor(null);
    setPopoverComponent(null);
  };

  const handleDuplicate = () => {
    onCreateDuplicate();
    closeMenu();
  };
  const handleOpenPopover = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    handleClosePopover();
  };

  const moreOptions = [
    {
      src: History,
      label: 'History',
      callback: handleHistory,
      disabled: !canUpdateInstance,
    },
    {
      src: Duplicate,
      label: 'Duplicate Instance',
      callback: handleDuplicate,
      disabled: disableEdit || !canCreateInstance,
    },
    {
      src: MinusSrc,
      label: 'Remove from Rundown',
      callback: handleRemove,
      disabled: true,
    },
    {
      src: Delete,
      label: 'Delete Instance',
      callback: OpenDelete,
      disabled: disableEdit || !canDeleteInstance,
    },
    {
      src: PrintSrc,
      label: 'Print Instance',
      callback: handlePrintInstance,
      disabled: false,
    },
    {
      src: DownloadSrc,
      label: 'Download Instance',
      callback: handleDownloadInstance,
      disabled: false,
    },
    {
      src: PadlockSrc,
      label: 'Force unlock',
      callback: handleForceUnlock,
      disabled: disableEdit,
    },
  ];

  const MoreOptionsMenuList = () => (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>MORE OPTIONS</ListSubheader>

      {moreOptions.map(({ src, label, callback, disabled }) => (
        <MenuItem
          key={label}
          image={src}
          label={label}
          onClick={callback}
          showSecondaryItem={false}
          disabled={disabled}
        />
      ))}
    </List>
  );

  const GeneralMenuList = () => (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>GENERAL</ListSubheader>

      <MenuItem
        label="Metadata"
        image={RightMetadataOffSrc}
        onClick={handleMetadataClick}
        disabled={showMetadata}
        secondaryItem={
          <Notification notificationCount={metadataCount > 0 ? metadataCount : null} />
        }
      />

      <MenuItem label="Notes" onClick={handleOpenNotes} image={Notes} data={notes} disabled />
    </List>
  );

  return (
    <div>
      {historyDialogOpen && (
        <InstanceVersionHistory
          versions={versions}
          getSpecificVersionContent={refetchVersionContent}
          content={currentVersionContent}
          title={title}
          id={instanceId}
          open={historyDialogOpen}
          onCancel={() => setHistoryDialogOpen(false)}
          onOk={handleRestoreVersion}
          checkVersionRestorability={checkVersionRestorability}
          versionRestoreDisabled={versionRestoreDisabled}
          lockedByUser={lockedByUser}
          isSavingContent={isSavingContent}
          auditListLoading={auditListLoading}
          versionContentLoading={versionContentLoading}
        />
      )}
      <div className={classes.container}>
        <EllipsisButton onClick={handleOpenPopover} />

        <Popover
          anchorEl={anchorEl}
          onClose={closeMenu}
          anchorOrigin={topRight}
          transformOrigin={topRight}
        >
          <div className={classes.menu}>
            <GeneralMenuList />

            {!hideTemplateOptions && (
              <>
                <Divider className={classes.divider} />
                <FoldersMenuList
                  {...{
                    anchorEl,
                    folders,
                    canCreateNewTemplate,
                    canDeleteTemplateFolder,
                    canDeleteTemplate,
                    writeLock,
                    onDeleteFolder,
                    closeMenu,
                    onSaveTemplate,
                    onSelectTemplate,
                    onDeleteTemplate,
                    onCreateFolder,
                  }}
                />
                <Popover
                  noMargin
                  anchorEl={anchor}
                  anchorOrigin={centerLeft}
                  transformOrigin={topRight}
                  onClose={handleClosePopover}
                >
                  {popoverComponent}
                </Popover>
              </>
            )}

            <Divider className={classes.divider} />
            <List disablePadding classes={{ root: classes.menuItem }}>
              <ListSubheader classes={{ root: classes.listSubheader }}>
                Automation template sets
              </ListSubheader>
              <MenuItem
                label={templateSets[templateSetIndex]?.title || 'Select template set'}
                image={null}
                onClick={(event) => {
                  setAnchor(event.currentTarget);
                  setPopoverComponent(
                    <List disablePadding className={classes.templateSetMenu}>
                      {templateSets.map(({ title: templateSetTitle, value }) => (
                        <MenuItem
                          key={value}
                          image={null}
                          label={templateSetTitle}
                          selected={value === templateSetIndex}
                          onClick={() => {
                            selectTemplateSet(value);
                            setAnchor(null);
                          }}
                          showSecondaryItem={false}
                        />
                      ))}
                    </List>,
                  );
                }}
              />
            </List>

            <Divider className={classes.divider} />
            <MoreOptionsMenuList />
          </div>
        </Popover>
      </div>
    </div>
  );
};

LinearEllipsisMenuView.propTypes = {
  /** Reference to the container element */
  containerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Metadata count to show on status notification indicator */
  metadataCount: PropTypes.number,
  /** Callback to be invoked when metadata option is clicked */
  onMetadataSelect: PropTypes.func,
  /** Callback to be invoked when remove from rundown is clicked */
  onRemove: PropTypes.func,
  /** List of notes  */
  notes: PropTypes.arrayOf(PropTypes.any),
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** Callback to be invoked download of an instance */
  onDownload: PropTypes.func,
  /** Whether to hide template options or not */
  hideTemplateOptions: PropTypes.bool,
  /** Boolean that indicates that user can change content */
  writeLock: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
};

LinearEllipsisMenuView.defaultProps = {
  containerRef: { current: null },
  onSelectTemplate: (_template) => {},
  onSaveTemplate: (_templateName) => {},
  onDeleteTemplate: (_template) => {},
  metadataCount: null,
  onMetadataSelect: () => {},
  onRemove: () => {},
  notes: [],
  folders: [],
  onCreateFolder: () => {},
  onDeleteFolder: () => {},
  onDownload: () => {},
  hideTemplateOptions: false,
  writeLock: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
};

export default LinearEllipsisMenuView;
