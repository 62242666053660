import { atom, useAtom, useSetAtom } from 'jotai';
import { focusAtom } from 'jotai/optics';

const defaultSidebarValues = {
  leftHidden: false,
  rightHidden: false,
  rightExpanded: false,
  leftSelection: 'user',
  rightSelection: 'contacts',
  contactTab: 0,
  openGroups: {},
  leftSidebarSavedSearches: [],
  rightSidebarSavedSearches: [],
};

const sidebarStorageAtom = atom(
  JSON.parse(localStorage.getItem('sidebars')) ?? defaultSidebarValues,
);

export const sidebarAtom = atom(
  (get) => get(sidebarStorageAtom),
  (_get, set, nextValue) => {
    set(sidebarStorageAtom, nextValue);
    localStorage.setItem('sidebars', JSON.stringify(nextValue));
  },
);
export const useSidebar = () => useAtom(sidebarAtom);

export const leftHiddenAtom = focusAtom(sidebarAtom, (optic) => optic.prop('leftHidden'));
export const useLeftHidden = () => useAtom(leftHiddenAtom);

export const rightHiddenAtom = focusAtom(sidebarAtom, (optic) => optic.prop('rightHidden'));
export const useRightHidden = () => useAtom(rightHiddenAtom);

export const leftSelectionAtom = focusAtom(sidebarAtom, (optic) => optic.prop('leftSelection'));
export const useLeftSelection = () => useAtom(leftSelectionAtom);

export const rightSelectionAtom = focusAtom(sidebarAtom, (optic) => optic.prop('rightSelection'));
export const useRightSelection = () => useAtom(rightSelectionAtom);

export const rightExpandedAtom = focusAtom(sidebarAtom, (optic) => optic.prop('rightExpanded'));
export const useRightExpanded = () => useAtom(rightExpandedAtom);

export const openGroupsAtom = focusAtom(sidebarAtom, (optic) => optic.prop('openGroups'));
export const useOpenGroups = () => useAtom(openGroupsAtom);

export const contactsTabAtom = focusAtom(sidebarAtom, (optic) => optic.prop('contactTab'));
export const useContactsTab = () => useAtom(contactsTabAtom);

export const leftSidebarSavedSearchesAtom = focusAtom(sidebarAtom, (optic) =>
  optic.prop('leftSidebarSavedSearches'),
);
export const useLeftSidebarSavedSearches = () => useAtom(leftSidebarSavedSearchesAtom);

export const rightSidebarSavedSearchesAtom = focusAtom(sidebarAtom, (optic) =>
  optic.prop('rightSidebarSavedSearches'),
);
export const useRightSidebarSavedSearches = () => useAtom(rightSidebarSavedSearchesAtom);

const setToggleBothSidebarsAtom = atom(null, (get, set) => {
  const leftHidden = get(leftHiddenAtom);
  const rightHidden = get(rightHiddenAtom);
  const leftSelection = get(leftSelectionAtom);
  const rightSelection = get(rightSelectionAtom);

  const bothHidden = leftHidden && rightHidden;

  if (!bothHidden) {
    !leftHidden && set(leftHiddenAtom, (prev) => !prev);
    !rightHidden && set(rightHiddenAtom, (prev) => !prev);
  }

  if (bothHidden) {
    set(leftSelectionAtom, leftSelection ?? 'user');
    set(rightSelectionAtom, rightSelection ?? 'contacts');
    set(leftHiddenAtom, (prev) => !prev);
    set(rightHiddenAtom, (prev) => !prev);
  }
});
export const useSetToggleBothSidebars = () => useSetAtom(setToggleBothSidebarsAtom);

export const sidebarBatchUpdateAtom = atom(null, (_get, set, nextValues) =>
  set(sidebarAtom, (prev) => ({ ...prev, ...nextValues })),
);
export const useSetSidebarBatchUpdate = () => useSetAtom(sidebarBatchUpdateAtom);

/* Sidebar Date Picker Atom */

const convertDate = (value) => (value ? new Date(value) : null);

const datePickerAtom = atom([new Date(Date.now())]);

const sidebarDatePickerAtom = atom(
  (get) => {
    const { 0: sDate, length: l, [l - 1]: eDate } = get(datePickerAtom);

    const startDate = convertDate(sDate);
    startDate?.setHours(0, 0, 0, 0);

    const endDate = convertDate(eDate);
    endDate?.setHours(23, 59, 59, 500);

    return { startDate, endDate };
  },
  (_get, set, updatedValue) => {
    set(datePickerAtom, updatedValue);
  },
);

export const useSidebarDatePickerAtom = () => useAtom(sidebarDatePickerAtom);
