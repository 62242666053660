import React from 'react';
import PropTypes from 'prop-types';
import Profile from './profile';
import Group from './group/group-view';
import { useSelectedLeftMenu, useSelectedGroup } from '../../atoms';
import RootWrapper from './settingsDetails-styled';

const SettingsDetails = (props) => {
  const { groupPolicies } = props;
  const [selectedLeftMenu] = useSelectedLeftMenu();
  const [selectedGroup] = useSelectedGroup();

  const viewHelper = () => {
    if (selectedGroup) return <Group groupPolicies={groupPolicies} />;
    if (selectedLeftMenu === 'profile') return <Profile groupPolicies={groupPolicies} />;
    return <div />;
  };

  return <RootWrapper>{viewHelper()}</RootWrapper>;
};

SettingsDetails.propTypes = {
  groupPolicies: PropTypes.array,
};

SettingsDetails.defaultProps = {
  groupPolicies: [],
};

export default SettingsDetails;
