import styled from '@emotion/styled';

import theme from 'theme/theme';

import Typography from '@material-ui/core/Typography';

export const StyledTooltipTitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: ${({ type }) => (type ? '252px' : 'max-content')};
  padding: 0 4px;
`;

export const StyledTooltipTitleHeader = styled(Typography)`
  ${theme.typography.dina.captionMedium};
  text-transform: capitalize;
`;

export const StyledTooltipDescription = styled(Typography)`
  ${theme.typography.dina.caption};
  color: ${theme.palette.dina.highEmphasis};
  text-transform: capitalize;
`;
