import React, { useRef, useEffect, useCallback, memo, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useSlate, useFocused } from 'slate-react';
import { ReactComponent as OpenIcon } from 'assets/icons/systemicons/editor/open_small.svg';
import { ReactComponent as UnLinkIcon } from 'assets/icons/systemicons/editor/unlink.svg';
import getCleanLink from 'components/editor/utils/getCleanLink';
import useCheckUserRight from 'hooks/useCheckUserRight';
import LinkedInstance from 'components/editor/components/link/components/linkedInstance';
import getSelectedLinkElement from 'components/editor/utils/getSelectedLinkElement';
import isLeftMouseClicked from 'utils/isLeftMouseClicked';
import movePortalToViewPort from 'components/editor/utils/movePortalToViewPort';
import unLink from './utils/unLink';
import LinkButton from '../toolbar/components/linkButton';
import getHrefFromSelection from './utils/getHrefFromSelection';
import ButtonBase from '../toolbar/components/buttonBase';
import Menu from './components/menu';
import urlPrefix from '../../constants/urlPrefix';

const target = '_blank';

const Tooltip = React.forwardRef((props, ref) => <div ref={ref} {...props} />);

const Portal = ({ children }) =>
  typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;

const HoveringToolbar = () => {
  const ref = useRef();
  const tooltipRef = useRef();
  const editor = useSlate();
  const inFocus = useFocused();
  const { selection } = editor;
  const href = getHrefFromSelection(editor);

  useEffect(() => {
    const el = ref.current;

    if (!el) {
      return;
    }

    const domSelection = window.getSelection();

    if (
      !selection ||
      !inFocus ||
      !getSelectedLinkElement(editor) ||
      // This is to check if selection has a single link node or composite node
      domSelection?.baseNode?.data !== domSelection?.extentNode?.data
    ) {
      el.removeAttribute('style');
      return;
    }

    movePortalToViewPort(el);
  });

  const openInNewTab = useCallback(
    (event) => {
      event.preventDefault();
      if (isLeftMouseClicked(event)) {
        window.open(getCleanLink(href), target);
      }
    },
    [href],
  );

  const removeLink = useCallback(
    (event) => {
      event.preventDefault();
      if (isLeftMouseClicked(event)) {
        unLink(editor);
      }
    },
    [editor],
  );

  const [checkUserRight] = useCheckUserRight();
  const showLinkedInstancePreview = checkUserRight('feature', 'instance-link');

  const memoizedLink = useMemo(() => {
    if (showLinkedInstancePreview && href?.indexOf(urlPrefix.LINKED_INSTANCE_HREF_PREFIX) === 0) {
      const splittedHref = href?.split(urlPrefix.LINKED_INSTANCE_HREF_PREFIX);
      const instanceId = splittedHref?.length === 2 ? splittedHref[1] : '';
      const selectedElement = getSelectedLinkElement(editor);
      return (
        <LinkedInstance
          instanceId={instanceId}
          editor={editor}
          element={selectedElement}
          shouldShowPreviewOnClick
        >
          <ButtonBase IconComponent={OpenIcon} />
        </LinkedInstance>
      );
    }

    return <ButtonBase IconComponent={OpenIcon} onMouseDown={openInNewTab} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href]);

  const memoizedPortal = useMemo(
    () => (
      <Portal>
        <Menu
          ref={ref}
          onMouseDown={(e) => {
            // prevent toolbar from taking focus away from editor
            e.preventDefault();
          }}
        >
          <Tooltip ref={tooltipRef} title="Edit Link">
            <LinkButton variant="inline" />
          </Tooltip>
          <Tooltip ref={tooltipRef} title="Visit Link">
            {memoizedLink}
          </Tooltip>
          <Tooltip ref={tooltipRef} title="Unlink">
            <ButtonBase IconComponent={UnLinkIcon} onMouseDown={removeLink} />
          </Tooltip>
        </Menu>
      </Portal>
    ),
    [removeLink, memoizedLink],
  );

  return memoizedPortal;
};

export default memo(HoveringToolbar);
