import styled from '@emotion/styled';
import { ReactComponent as VideoIconComponent } from 'assets/icons/systemicons/editor/video_off.svg';
import { overline } from 'theme/styledTypography';
import theme from 'theme/theme';

export const VideoIcon = styled(VideoIconComponent)`
  margin: 8px;
  path {
    fill: ${theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

export const ThumbnailWrapper = styled('div')`
  margin-bottom: 16px;
`;

export const Label = styled('p')`
  ${overline}
  font-weight: 500;
`;
