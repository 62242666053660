import React from 'react';
import PropTypes from 'prop-types';

import { useCurrentTabValue, useAddContentTab } from 'store/tabs';
import memberTypes from 'graphql/memberTypes';
import { onImageLoadingError } from 'utils/image/imageUtils';
import getTimeZone from 'utils/getTimeZone';
import getRundownPublishingTime from 'utils/getRundownPublishingTime';

import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import ICONS from 'assets/icons/icons';

import Tooltip from 'components/tooltip';
import {
  ListItem,
  ImageWrapper,
  Image,
  ContentWrapper,
  IconButton,
  RundownTitle,
  TimingInfoWrapper,
  TimingInfo,
  TimingLabel,
  TimingValue,
} from './listItem-styled';

const RundownContainer = ({
  title,
  plan,
  image,
  id,
  selectedDates,
  setPreviewRundown,
  timeZone,
  startTime,
  initializeRundown,
  state,
  rundowntype,
  startDate,
}) => {
  const currentTab = useCurrentTabValue();
  const addContentTab = useAddContentTab();

  const activeTab = id === currentTab.id;
  const publishingDate = new Date(selectedDates[0]);

  const selectedDate = getRundownPublishingTime(
    publishingDate,
    startTime,
    timeZone || getTimeZone(),
  );

  const openRundown = () => {
    if (initializeRundown) {
      initializeRundown({ mTitle: title, mId: id, selectedDate });
      return;
    }
    addContentTab({
      type: memberTypes.RUNDOWN,
      title,
      id,
      mid: id,
      selectedDate,
      mType: rundowntype,
    });
  };

  const handlePreviewRundown = (e) => {
    setPreviewRundown({
      id,
      date: selectedDate,
      image,
      title,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <ListItem button selected={activeTab} onClick={openRundown}>
      <ImageWrapper>
        <Image state={state} onError={onImageLoadingError} alt="complex" src={image} />
      </ImageWrapper>
      <ContentWrapper>
        <Tooltip title={title} noArrow>
          <RundownTitle>{title}</RundownTitle>
        </Tooltip>
        <TimingInfoWrapper>
          <TimingInfo>
            <TimingLabel>IN</TimingLabel>
            <TimingValue>{startTime}</TimingValue>
          </TimingInfo>
          <TimingInfo>
            <TimingLabel>PLANNED</TimingLabel>
            <TimingValue>{plan}</TimingValue>
          </TimingInfo>
        </TimingInfoWrapper>
      </ContentWrapper>
      {setPreviewRundown && (
        <IconButton
          title="Preview Rundown"
          icon={ICONS.DISCLOSURE_ARROW_RIGHT}
          onClick={handlePreviewRundown}
        />
      )}
    </ListItem>
  );
};

RundownContainer.propTypes = {
  /** Story id */
  id: PropTypes.string.isRequired,
  /** Story title */
  title: PropTypes.string.isRequired,
  /** The startTime in hh:mm:ss format  */
  startTime: PropTypes.string,
  /** plan time */
  plan: PropTypes.string,
  /** Url for thumbnail */
  image: PropTypes.string,
  /** the state for the rundown (nostate,ready, active) */
  state: PropTypes.string,
  /** the type of the rundown (rundown or rundowntemplate) */
  // rundownType: PropTypes.string,
  /** set the previewRundown */
  setPreviewRundown: PropTypes.func,
  /** the selected dates */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** Start date of the rundown */
  startDate: PropTypes.string,
};

RundownContainer.defaultProps = {
  startTime: '00:00:00',
  plan: '--:--:--',
  image: fallbackImage,
  setPreviewRundown: undefined,
  selectedDates: [],
  state: '',
  // rundownType: 'rundown',
  startDate: new Date().toISOString(),
};

export default RundownContainer;
