import React, { memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Button as MaterialButton } from '@material-ui/core';
import { button } from 'theme/styledTypography';

import colors from 'theme/colors';

export const StyledButton = styled(MaterialButton)`
  &.MuiButton-root {
    height: 40px;
    width: 220px;
    border-radius: 4px;
    border: 0.5px solid ${colors.buttonBorderOutlined};
    background-color: ${colors.blackHoverOverlay};
    :hover {
      background-color: ${colors.whiteHoverOverlay};
    }
    .MuiButton-label {
      ${button}
      justify-content: start;
      text-transform: none;
      color: ${colors.mediumEmphasis};
    }
    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 4px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const Button = ({ label, icon, onClick, disabled }) => (
  <StyledButton startIcon={icon} onClick={onClick} disabled={disabled}>
    {label}
  </StyledButton>
);

Button.propTypes = {
  /** Label of the button */
  label: PropTypes.string,
  /** Icon React component */
  icon: PropTypes.element,
  /** onClick callback function */
  onClick: PropTypes.func,
  /** disabled */
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  label: '',
  icon: null,
  onClick: () => {},
  disabled: false,
};

export default memo(Button);
