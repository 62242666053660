import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    // paddingBottom: ({ writeLock, readLock }) => (writeLock || readLock ? '0px' : '2px'),
  },
  paper: {
    border: ({ writeLock, readLock }) => {
      if (writeLock) return `1px solid ${theme.palette.dina.statusApproved}`;
      if (readLock) return `1px solid ${theme.palette.dina.statusWarning}`;
      return `1px solid transparent`;
    },
    borderRadius: 8,
    background: theme.palette.dina.surfaceCard,
    position: 'relative',
  },
  divider: {
    background: ({ dividerColor }) =>
      dividerColor ? theme.palette.dina[dividerColor] : theme.palette.dina.kanbanTodo,
  },
  backdropRoot: {
    position: 'absolute',
  },
  popover: {
    margin: '-8px 0',
  },
  description: {
    ...theme.typography.dina.listItemLabel,
    padding: '16px 16px 16px 24px',
  },
  editorDiv: {
    outline: 'none',
  },
  fadeIn: {
    animationName: '$fadeInOpacity',
    animationTimingFunction: 'ease-in',
    animationDuration: '2s',
    animationIterationCount: 2,
  },

  '@keyframes fadeInOpacity': {
    '0%': {
      boxShadow: 'none',
    },
    '50%': {
      boxShadow: `0 0 0 3px ${theme.palette.dina.onSelected}`,
    },
    '100%': {
      boxShadow: 'none',
    },
  },
}));

export default useStyles;
