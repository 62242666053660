import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    right: '24px',
    bottom: '24px',
  },
  linkText: {
    color: theme.palette.dina.mediumEmphasis,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px',
  },
}));

const ImageCredit = (props) => {
  const { image } = props;
  const classes = useStyles();
  const imageCredit = `Photo by ${image.credit}`;
  return (
    <div className={classes.container}>
      <Typography>
        <Link
          href={image.url}
          className={classes.linkText}
          target="_blank"
          data-testid="linkImageCredit"
          underline="hover"
        >
          {imageCredit}
        </Link>
      </Typography>
    </div>
  );
};

const imagePropType = PropTypes.shape({
  url: PropTypes.string,
  credit: PropTypes.string,
});

ImageCredit.propTypes = {
  /* To set error status for password. Values: true/false */
  image: imagePropType,
};

ImageCredit.defaultProps = {
  image: { url: '', credit: '' },
};

export default ImageCredit;
