import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Avatar from 'components/avatar';
import getInitials from 'utils/getInitials';
import { listItemLabel } from 'theme/styledTypography';
import colors from 'theme/colors';

const AvatarCellWrapper = styled('div')`
  display: flex;
  min-width: 325px;
  align-items: center;
  > :first-of-type {
    margin-right: 8px;
  }
`;

const AvatarText = styled('span')`
  ${listItemLabel}
  font-size: 10px;
  color: ${colors.highEmphasis};
`;

const AvatarCell = ({ mTitle, mAvatarUrl }) => (
  <AvatarCellWrapper>
    <Avatar src={mAvatarUrl} size={24}>
      <AvatarText>{getInitials(mTitle)}</AvatarText>
    </Avatar>
    {mTitle}
  </AvatarCellWrapper>
);

AvatarCell.propTypes = {
  /** text in the cell */
  mTitle: PropTypes.string,
  /** avatar image */
  mAvatarUrl: PropTypes.string,
};

AvatarCell.defaultProps = {
  mTitle: '',
  mAvatarUrl: '',
};

export default AvatarCell;
