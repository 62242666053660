import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import getTimeString from 'utils/getTimeString';
import getMilliseconds from 'utils/getMilliseconds';
import { outTimingTypes, actionTypes } from 'components/editor/constants/types';
import selectElement from 'components/editor/utils/selectElement';
import TimingSelect from '../timingSelect';

const { MANUAL_OUT, ITEM_OUT, INSTANCE_OUT, AUTO_OUT } = outTimingTypes;

const items = [
  { type: MANUAL_OUT, title: 'Manual Out' },
  { type: AUTO_OUT, title: 'Set Duration' },
  { type: ITEM_OUT, title: 'Item Out' },
  { type: INSTANCE_OUT, title: 'Instance Out' },
];

const OutTimingSelect = ({ element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { outTiming, duration } = element.data;
  const showInput = outTiming === AUTO_OUT;

  const updateData = useCallback(
    (updatedProperty) => {
      const updatedData = { ...element.data, ...updatedProperty };

      selectElement(editor, element);
      Transforms.setNodes(editor, { data: updatedData });
      ReactEditor.focus(editor);

      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleTypeChange = useCallback(
    (event) => {
      updateData({ outTiming: event.target.value });
      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateData],
  );

  const handleInputChange = useCallback(
    (newValue) => {
      updateData({ outTiming: AUTO_OUT, duration: getMilliseconds(newValue) });
    },
    [updateData],
  );

  const inputValue = useMemo(() => getTimeString(duration), [duration]);

  return (
    <TimingSelect
      items={items}
      showInput={showInput}
      selectValue={outTiming}
      inputValue={inputValue}
      handleInputChange={handleInputChange}
      handleTypeChange={handleTypeChange}
    />
  );
};

OutTimingSelect.propTypes = {
  /** SlateJS element */
  element: PropTypes.shape({}),
};

OutTimingSelect.defaultProps = {
  element: {},
};

export default memo(OutTimingSelect);
