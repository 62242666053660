import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/close.svg';
import Dialog from 'components/dialog';
import ConfirmDialog from 'components/confirmDialog';
import KanbanCard from 'components/kanbanCard';
import Divider from 'components/divider';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import { CustomFields } from 'utils/metadata';
import { IconButtonWrapper, CardWrapper } from './instance-styled';

export const InstanceItemVariant = {
  INSTANCE_LIST_ITEM: 'instance_list_item',
  CREATE_LINK_LIST_ITEM: 'create_link_list_item',
};

const getMetaDataValue = (mMetaData, key) => {
  const foundMetaData = mMetaData.find((metaData) => metaData.key === key);
  return foundMetaData?.value;
};

const InstanceItem = (props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { parentInstance, instance, onDeleteLink, loading, isLinkingDisabled } = props;
  const {
    mTitle: title,
    items,
    mProperties,
    mUpdatedAt,
    mState,
    mPublishingAt,
    mRelatedMembers,
    mMetaData,
    mAssignedMembers,
  } = instance;
  const { platform: instanceType, account } = mProperties;

  const blockTitle = getMetaDataValue(mMetaData, 'block_title');

  const storyTitle = getMetaDataValue(mMetaData, CustomFields.STORY_TITLE);

  const [getAssignedMembers] = useGetAssignedMembers(mAssignedMembers || []);
  const [assignedUsers] = getAssignedMembers();

  const onCloseConfirmation = () => setShowConfirmation(false);
  const onOpenConfirmation = () => setShowConfirmation(true);

  const onConfirm = () => {
    onDeleteLink({
      variables: {
        input: {
          mId: parentInstance.mId,
          mRelatedMembers: [
            {
              mId: instance.mId || '',
              crudAction: 'DELETE',
            },
          ],
        },
      },
    });
  };

  return (
    <>
      <CardWrapper>
        <KanbanCard
          destination={account?.accountTitle}
          mRelatedMembers={mRelatedMembers}
          updatedAt={mUpdatedAt}
          storyTitle={storyTitle}
          blockTitle={blockTitle}
          title={title}
          mPublishingAt={mPublishingAt}
          items={items}
          mState={mState}
          assignedUsers={assignedUsers}
          platform={instanceType}
        />
        {!isLinkingDisabled && (
          <IconButtonWrapper>
            <DeleteIcon onClick={onOpenConfirmation} />
          </IconButtonWrapper>
        )}
        <Dialog open={showConfirmation} onClose={!loading ? onCloseConfirmation : () => {}}>
          <ConfirmDialog
            onCancel={onCloseConfirmation}
            onConfirm={onConfirm}
            title="Delete link?"
            confirmButtonLabel="Delete"
            confirmButtonType="danger"
            info="Are you sure you want to delete this link?"
            loading={loading}
          />
        </Dialog>
      </CardWrapper>
      <Divider />
    </>
  );
};

export default InstanceItem;
