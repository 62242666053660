import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NotificationIndicator from 'components/statusIndicators/notification/Notification';

import { ReactComponent as HomeOn } from 'assets/icons/systemicons/HeaderNavbar/home_on.svg';
import { ReactComponent as HomeOff } from 'assets/icons/systemicons/HeaderNavbar/home_off.svg';
import { ReactComponent as FeedsOn } from 'assets/icons/systemicons/HeaderNavbar/feeds_on.svg';
import { ReactComponent as FeedsOff } from 'assets/icons/systemicons/HeaderNavbar/feeds_off.svg';
import { ReactComponent as NavigateOn } from 'assets/icons/systemicons/HeaderNavbar/navigate_on.svg';
import { ReactComponent as NavigateOff } from 'assets/icons/systemicons/HeaderNavbar/navigate_off.svg';
import { ReactComponent as LocationOn } from 'assets/icons/systemicons/location_on.svg';
import { ReactComponent as LocationOff } from 'assets/icons/systemicons/location_off.svg';

import Tooltip from 'components/tooltip';
import TabToolTip from '../tabTooltip';

import { StyledTab as Tab, NotificationWrapper } from './iconTab-styled';

/**
 * @param  {string} type
 * @return {[ReactComponent, ReactComponent]}
 */
const getIcons = (type) => {
  let IconOn;
  let IconOff;

  switch (type) {
    case 'home':
      IconOn = HomeOn;
      IconOff = HomeOff;
      break;
    case 'feeds':
      IconOn = FeedsOn;
      IconOff = FeedsOff;
      break;
    case 'plans':
      IconOn = NavigateOn;
      IconOff = NavigateOff;
      break;
    case 'maps':
      IconOn = LocationOn;
      IconOff = LocationOff;
      break;
    default:
      break;
  }
  return [IconOn, IconOff];
};

const IconTabView = (props) => {
  const { active, type, notificationCount, clearNotificationCount, title, ...rest } = props;
  const [IconOn, IconOff] = getIcons(type);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTabClick = () =>
    type === 'feeds' && notificationCount > 0 && clearNotificationCount();

  return (
    <>
      <Tab
        onClick={handleTabClick}
        icon={
          active ? (
            <Tooltip title={<TabToolTip title={title} />} noArrow maxWidth={80} open={showTooltip}>
              <IconOn />
            </Tooltip>
          ) : (
            <Tooltip title={<TabToolTip title={title} />} noArrow maxWidth={80} open={showTooltip}>
              <IconOff />
            </Tooltip>
          )
        }
        tabIndex={0}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        {...rest}
      />

      {type === 'feeds' && notificationCount > 0 && (
        <NotificationWrapper>
          <NotificationIndicator notificationCount={notificationCount} />
        </NotificationWrapper>
      )}
    </>
  );
};

IconTabView.propTypes = {
  /** if the tab is active */
  active: PropTypes.bool.isRequired,
  /** type of the tab */
  type: PropTypes.string.isRequired,
  /** title */
  title: PropTypes.string.isRequired,
  /** notification counter */
  notificationCount: PropTypes.number,
  /** clear notification function */
  clearNotificationCount: PropTypes.func,
};

IconTabView.defaultProps = {
  /** no of notification */
  notificationCount: 0,
  /** callback to clear notification */
  clearNotificationCount: () => {},
};

export default IconTabView;
