import React, { createContext, useState, useContext } from 'react';
/**
 * This context is used to keep scheduleResource data since initial load.
 */
const ScheduleResourceContext = createContext({
  project: [],
  agency: [],
  client: [],
  contact: [],
  request: [],
  category: [],
  status: [],
});

const ScheduleResourceProvider = ({ children }) => {
  const [scheduleResources, setScheduleResources] = useState({
    project: [],
    agency: [],
    client: [],
    contact: [],
    request: [],
    category: [],
    status: [],
  });

  return (
    <ScheduleResourceContext.Provider value={{ scheduleResources, setScheduleResources }}>
      {children}
    </ScheduleResourceContext.Provider>
  );
};

const useScheduleResourceContext = () => {
  const context = useContext(ScheduleResourceContext);
  if (!context) {
    throw new Error(
      'useScheduleResourceContext must be used within a ScheduleResourceContextProvider',
    );
  }
  return context;
};

export { ScheduleResourceProvider, useScheduleResourceContext };
