import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import styled from '@emotion/styled';
import DragAndDrop from '../dragAndDrop';

const QuoteElement = styled('p')`
  margin: 0;
  padding: 8px;
  font-size: 18px;

  ::before {
    content: open-quote;
  }
  ::after {
    content: close-quote;
  }
`;

const Quote = ({ attributes, children, element }) => (
  <DragAndDrop element={element} isDisabled>
    <QuoteElement {...attributes}>{children}</QuoteElement>
  </DragAndDrop>
);

Quote.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Quote.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.QUOTE,
    children: [],
  },
};

export default memo(Quote);
