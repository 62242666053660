import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { Button as MaterialButton } from '@material-ui/core';
import { elementTypes } from 'components/editor/constants/types';
import EditButton from 'components/instanceCard/components/cmsEditing/components/editButton';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { ReactComponent as InfoIcon } from 'assets/icons/systemicons/editor/info_on.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_small.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/editor/photo_small.svg';
import insertBlock from '../toolbar/components/iconButton/utils/insertBlock';
import insertWithNoSelction from '../toolbar/components/textButton/utils/insertWithNoSelection';
import useStyles from './styles';

const Button = memo(MaterialButton);
const CmsEditButton = memo(EditButton);
const { IMAGE, VIDEO } = elementTypes;

const Info = ({ attributes, children, element }) => {
  const classes = useStyles();
  const editor = useSlate();
  const { update, onCmsEditing, isPublished } = useEditorContext();

  const onAddBlock = useCallback(type => {
    if (editor.selection) insertBlock({ editor, update, type, data: {} });
    else insertWithNoSelction({ editor, update, type, data: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddVideo = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    onAddBlock(VIDEO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddPhoto = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    onAddBlock(IMAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...attributes}>
      {children}
      <div className={classes.article} contentEditable={false}>
        Article
      </div>
      <div contentEditable={false} className={classes.contentContainer}>
        <div className={classes.iconWrapper}>
          <InfoIcon className={classes.icon} />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.text}>
            To edit Content, please switch to “CMS Editing” in the toolbar, or the button below:
          </div>
          <div className={classes.editBtnWrapper}>
            <CmsEditButton disabled={!isPublished} height={40} width={160} onClick={onCmsEditing} />
          </div>
          <div className={classes.text}>
            To add assets to the CMS-article, click the Photo or Video buttons in the toolbar, or
            the buttons below.
          </div>
          <div className={classes.addButtons}>
            <Button
              classes={{ root: classes.addButtonRoot, label: classes.addButtonText }}
              onClick={onAddVideo}
            >
              <VideoIcon className={classes.addIcon} />
              Add Video
            </Button>
            <Button
              classes={{ root: classes.addButtonRoot, label: classes.addButtonText }}
              onClick={onAddPhoto}
            >
              <PhotoIcon className={classes.addIcon} />
              Add Photo
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Info.propTypes = {
  /**
   * Attributes of SlateJS children
   */
  attributes: PropTypes.shape({}),
  /**
   * SlateJS children
   */
  children: PropTypes.node,
  /**
   * SlateJS element
   */
  element: PropTypes.shape({}),
};

Info.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.INFO,
  },
};

export default memo(Info);
