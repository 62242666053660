import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 506,
    backgroundColor: theme.palette.dina.surfaceCard,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    height: 62,
  },
  headerLabel: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
    marginLeft: 16,
  },
  closeButton: {
    cursor: 'pointer',
    marginRight: 16,
    marginLeft: 8,
    '&:hover': {
      '& path': {
        fillOpacity: 1,
      },
    },
  },
  content: {
    margin: 16,
  },
  checkboxContainer: {
    marginTop: 10,
  },
  checkboxWithLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'max-content',
    userSelect: 'none',
  },
  checkboxLabel: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    marginLeft: 4,
  },
  restrictedInfo: {
    display: 'flex',
    height: 26,
    alignItems: 'center',
    marginTop: 12,
    marginLeft: 24,
  },
  warning: {
    '& path': {
      fill: theme.palette.dina.statusWarning,
      fillOpacity: 1,
    },
  },
  restrictedText: {
    ...theme.typography.dina.caption,
    marginLeft: 8,
  },
  footer: {
    height: 56,
    borderTop: `1px solid ${theme.palette.dina.dividerLight}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnContainer: {
    display: 'flex',
    width: '316px',
  },
  cancelButton: {
    height: 40,
    width: 76,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
    ...theme.typography.dina.button,
    fontWeight: 'normal',
    cursor: 'pointer',
    color: theme.palette.dina.mediumEmphasis,
    '&:hover': {
      color: theme.palette.dina.highEmphasis,
    },
  },
  storyLabel: {
    marginLeft: '8px',
    color: theme.palette.dina.highEmphasis,
    '&:hover': {
      color: theme.palette.dina.highEmphasis,
    },
  },
  storyIcon: {
    '& path': {
      fill: theme.palette.dina.highEmphasis,
      fillOpacity: 1,
    },
  },
  pitchLabel: {
    marginLeft: '8px',
    color: theme.palette.dina.blackHighEmphasis,
    '&:hover': {
      color: theme.palette.dina.blackHighEmphasis,
    },
  },
  pitchIcon: {
    '& path': {
      fill: theme.palette.dina.blackHighEmphasis,
      fillOpacity: 1,
    },
  },
  //   padlockOffIcon: {
  //     '& path': {
  //       fill: theme.palette.dina.blackHighEmphasis,
  //       fillOpacity: 1,
  //     },
  //   },
}));

export default useStyles;
