import { useState, useEffect } from 'react';

const useCalculateContainerDimension = (containerRef) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const init = () => {
    let resizeObserver;

    if (containerRef.current) {
      const element = containerRef.current;

      if (window.ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
          const { height: h, width: w } = element.getBoundingClientRect();
          setHeight(h);
          setWidth(w);
        });
        resizeObserver.observe(element);
      } else {
        const { height: h, width: w } = element.getBoundingClientRect();
        setHeight(h);
        setWidth(w);
      }
    }

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  };

  useEffect(init, [containerRef]);

  return { height, width };
};

export default useCalculateContainerDimension;
