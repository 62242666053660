/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import Calendar from 'components/calendar';
import dndTypes from 'utils/dndTypes';
import useLeftPanelNavigation from 'hooks/useLeftPanelNavigation';
import memberTypes from 'graphql/memberTypes';
import Popper from 'components/shared/popper';
import Dialog from 'components/dialog';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import ConfirmDialog from 'components/confirmDialog';
import StoryCard from 'components/storyCard';
import { ListItem, Slide, ClickAwayListener } from '@material-ui/core';
import capitalize from 'utils/capitalize';
import useStyles from './story-styles';
import SubMenu from './subMenu';

const getMType = (archived, isPitch) => {
  if (!archived) return undefined;
  return isPitch ? memberTypes.ARCHIVED_PITCH : memberTypes.ARCHIVED_STORY;
};

const Story = (props) => {
  const {
    title,
    updatedAt,
    publishingAt,
    image,
    onClick,
    onSameClick,
    onDoubleClick,
    selected,
    assignedUsers,
    setShowStorybox,
    groupType,
    archived,
    bookmarked,
    joined,
    subMenuActionHandler,
    onUpdatePublishDate,
    dragSourceEnabled,
    isPitch,
    isStorySelected,
    isStoryFocused,
    isRestricted,
    handleDrop,
    enableUpdate,
    showArchiveButton,
  } = props;

  const [hoverRef, isHovered] = useLeftPanelNavigation(isStoryFocused);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [handleClick, handleDoubleClick] = useMouseClickEvents(onClick, onDoubleClick);
  const [popAnchorEl, setPopAnchorEl] = useState(null);

  const [, dragRef] = useDrag({
    type: isPitch ? dndTypes.PITCH : dndTypes.STORY,
    item: () => props,
    canDrag: () => dragSourceEnabled && enableUpdate,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.MEMBER],
    drop: (item, monitor) => {
      if (enableUpdate) handleDrop(monitor.getItem().id, item.id, monitor.getItem().type);
    },
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const classes = useStyles({ hovered, isPitch });
  const closeDialog = () => setDialog(null);
  const openDialog = (btn) => setDialog(btn);

  const subMenuClickHandler = (btn) => {
    closeSubMenu();
    if (btn === 'archive' || btn === 'join') {
      openDialog(btn);
    } else {
      subMenuActionHandler(btn);
    }
  };

  const onMoreClick = () => {
    setSubMenuOpen(true);
  };

  const closeSubMenu = () => setSubMenuOpen(false);

  const confirmDialog = () => {
    closeDialog();
    const mType = getMType(archived, isPitch);
    subMenuActionHandler(dialog, mType);
  };

  const handleClose = () => {
    setPopAnchorEl(null);
  };

  const handleScheduleClick = (event) => {
    if (enableUpdate) setPopAnchorEl(event.currentTarget);
  };

  const handleChange = (newTime) => {
    onUpdatePublishDate(newTime.toISOString());
    handleClose();
  };

  const handleUnschedule = () => {
    onUpdatePublishDate();
    handleClose();
  };

  const DialogContent = () => {
    if (dialog === 'archive') {
      const itemType = groupType === memberTypes.PITCH ? 'pitch' : 'story';
      const capitalizedItemType = capitalize(itemType);
      return (
        <Dialog open onClose={closeDialog}>
          <ConfirmDialog
            onCancel={closeDialog}
            onConfirm={confirmDialog}
            title={`${archived ? 'Un-archive' : 'Archive'} ${capitalizedItemType}?`}
            confirmButtonLabel={archived ? 'Unarchive' : 'Archive'}
            confirmButtonType="danger"
            info={`${capitalizedItemType}: "${title}" will be ${
              archived ? 'un' : ''
            }archived. Are you sure you want to ${archived ? 'un' : ''}archive the ${itemType}?`}
          />
        </Dialog>
      );
    }
    if (dialog === 'join') {
      return (
        <Dialog open onClose={closeDialog}>
          <ConfirmDialog
            onCancel={closeDialog}
            onConfirm={confirmDialog}
            title={`${joined ? 'Leave' : 'Join'} Story?`}
            confirmButtonLabel={joined ? 'Leave' : 'Join'}
            confirmButtonType={joined ? 'danger' : 'significant'}
            info={`Are you sure you want to ${joined ? 'leave' : 'join'} the story?`}
            extraInfo={
              joined
                ? 'Saving with no assigned user will set the creator of the story or pitch as assigned.'
                : ''
            }
          />
        </Dialog>
      );
    }
    return <></>;
  };

  const attachRef = useCallback((elm) => {
    dragRef(elm);
    dropRef(elm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListItemClick = (e) => {
    if (!isStorySelected) {
      handleClick(e);
    } else {
      onSameClick(e);
    }
  };

  return (
    <div className={classes.focusDiv} ref={attachRef}>
      <ListItem
        button
        selected={selected}
        className={`${(popAnchorEl || isStoryFocused) && classes.popoverItem}`}
        classes={{
          root: classes.listItem,
          selected: classes.selected,
        }}
        onClick={handleListItemClick}
        onDoubleClick={handleDoubleClick}
        ref={hoverRef}
        disableGutters
      >
        <StoryCard
          title={title}
          image={image}
          selected={selected}
          isRestricted={isRestricted}
          isPitch={isPitch}
          isHovered={isHovered}
          archived={archived}
          bookmarked={bookmarked}
          timingInfo={updatedAt}
          publishingAt={publishingAt}
          assignedUsers={assignedUsers}
          onMoreClick={onMoreClick}
          onArrowClick={setShowStorybox}
          onBookmarkClick={subMenuClickHandler}
          onScheduleClick={handleScheduleClick}
          enableUpdate={enableUpdate}
        />
        <Slide
          in={isSubMenuOpen}
          timeout={{ enter: 250, exit: 250 }}
          mountOnEnter
          unmountOnExit
          direction="left"
        >
          <div className={classes.subMenu} onMouseLeave={closeSubMenu}>
            <SubMenu
              groupType={groupType}
              archived={archived}
              bookmarked={bookmarked}
              joined={joined}
              clickHandler={subMenuClickHandler}
              showArchiveButton={isPitch || showArchiveButton}
            />
          </div>
        </Slide>
      </ListItem>
      <Popper placement="right-end" anchorEl={popAnchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.calendarContainer}>
            <Calendar
              selectedDate={publishingAt}
              changeSelectedDate={handleChange}
              onUnschedule={handleUnschedule}
            />
          </div>
        </ClickAwayListener>
      </Popper>
      <DialogContent />
    </div>
  );
};

Story.propTypes = {
  /** Story title */
  title: PropTypes.string.isRequired,
  /** Url for thumbnail */
  image: PropTypes.string,
  /** bookmarked */
  bookmarked: PropTypes.bool,
};

Story.defaultProps = {
  image: '',
  bookmarked: false,
};

export default Story;
