import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/buttons/iconButton';
import Divider from 'components/divider';
import ICONS from 'assets/icons/icons';
import useMenuAnchor from 'hooks/useMenuAnchor';
import { StyledMenu, MenuItem, IconWrapper } from './styled';

const Menu = ({
  anchorOrigin,
  className,
  items,
  menuProps,
  menuItemProps,
  transformOrigin,
  onSelect,
}) => {
  const { anchorEl, closeMenu, toggleMenu } = useMenuAnchor();

  const menuItems = useMemo(
    () =>
      items.map(({ title, disabled, icon, divider, ...rest }) => (
        <div key={title}>
          <MenuItem
            key={title}
            onClick={() => {
              closeMenu();
              onSelect({ title, ...rest });
            }}
            disabled={disabled}
            {...menuItemProps}
          >
            {icon && <IconWrapper>{icon}</IconWrapper>}
            {title}
          </MenuItem>
          {divider && <Divider />}
        </div>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeMenu, items, menuItemProps, onSelect],
  );

  return (
    <div className={className}>
      <IconButton icon={ICONS.MORE_VERTICAL} onClick={toggleMenu} />

      {items && items.length > 0 && (
        <StyledMenu
          keepMounted
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          getContentAnchorEl={null}
          PaperProps={{
            onMouseLeave: closeMenu,
          }}
          {...menuProps}
        >
          {menuItems}
        </StyledMenu>
      )}
    </div>
  );
};

Menu.propTypes = {
  /** Anchor origin for Material UI Popover */
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** CSS class to override root styling */
  className: PropTypes.string,
  /** Items to be shown as menu options */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
    }),
  ),
  /** Props for Material UI Menu */
  menuProps: PropTypes.shape({}),
  /** Props for Material UI MenuItem */
  menuItemProps: PropTypes.shape({}),
  /** Transform origin for Material UI Popover */
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Callback to be invoked on menu item selection */
  onSelect: PropTypes.func,
};

Menu.defaultProps = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
  className: '',
  items: [],
  menuProps: {},
  menuItemProps: {},
  transformOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
  onSelect: () => {},
};

export default memo(Menu);
