import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Container from '../container/Container';
import MaterialAvatar from '../material-avatar';

const StyledCategoryAvatar = styled(MaterialAvatar)`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 25%;
`;

const Category = ({ children, src, ...rest }) => (
  <Container {...rest}>
    <StyledCategoryAvatar src={src}>{children}</StyledCategoryAvatar>
  </Container>
);

Category.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
};

Category.defaultProps = {
  children: null,
  src: '',
};

export default Category;
