import shareInstanceWithUser from 'graphql/utils/cache/notification/shareInstanceWithUser';
import shareStoryWithUser from 'graphql/utils/cache/notification/shareStoryWithUser';
import shareStoryWithTeam from 'graphql/utils/cache/notification/shareStoryWithTeam';
import shareStoryWithDepartment from 'graphql/utils/cache/notification/shareStoryWithDepartment';
import memberTypes from 'graphql/memberTypes';
import updateInstanceOfStory from 'graphql/utils/cache/notification/updateInstanceOfStory';
import updateMember from 'graphql/utils/cache/notification/updateMember';
import updateDepartmentInTeam from 'graphql/utils/cache/notification/updateDepartmentInTeam';
import updateMessageInConversation from 'graphql/utils/cache/notification/updateMessageInConversation';
import updateConversationsOfUser from 'graphql/utils/cache/notification/updateConversationsOfUser';

const dispatchMessage = (client, notifyUser, updateCache, leftSelection, setUnscheduledItem) => {
  const { mType } = notifyUser.message;
  switch (mType) {
    case memberTypes.USER_STORY:
      shareStoryWithUser(notifyUser, updateCache, leftSelection, setUnscheduledItem);
      break;
    case memberTypes.TEAM_STORY:
      shareStoryWithTeam(client, notifyUser);
      break;
    case memberTypes.DEPARTMENT_STORY:
      shareStoryWithDepartment(client, notifyUser);
      break;
    case memberTypes.TEAM_USER:
      updateMember(client, notifyUser);
      updateConversationsOfUser(client, notifyUser);
      break;
    case memberTypes.DEPARTMENT_USER:
      updateMember(client, notifyUser);
      updateConversationsOfUser(client, notifyUser);
      break;
    case memberTypes.DEPARTMENT_TEAM:
      updateDepartmentInTeam(client, notifyUser);
      break;
    case memberTypes.STORY_INSTANCE:
      updateInstanceOfStory(client, notifyUser);
      break;
    case memberTypes.USER_INSTANCE:
      // updateUserToInstance(client, notifyUser);
      shareInstanceWithUser(notifyUser, setUnscheduledItem);
      break;
    case memberTypes.USER_PITCH:
      shareStoryWithUser(notifyUser, updateCache, leftSelection, setUnscheduledItem);
      // TODO: sharePitchWithUser(client,notifyUser);
      break;
    case memberTypes.MESSAGE:
      updateMessageInConversation(client, notifyUser);
      break;
    case memberTypes.CONVERSATION_USER:
      updateConversationsOfUser(client, notifyUser);
      break;
    default:
      break;
  }
};
const updateCacheWithNotification = (
  client,
  notifyUser,
  updateCache,
  leftSelection,
  setUnscheduledItem,
) => {
  dispatchMessage(client, notifyUser, updateCache, leftSelection, setUnscheduledItem);
};

export default updateCacheWithNotification;
