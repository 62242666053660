import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as LeftArrow } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as MenuIcon } from 'assets/icons/systemicons/more_vertical.svg';
import { ReactComponent as BookmarkStar } from 'assets/icons/systemicons/bookmarks_on.svg';
import { ReactComponent as Alarm } from 'assets/icons/systemicons/set_alert_off.svg';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    ...theme.typography.dina.rightColumnHeaderText,
    textTransform: 'none',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {},
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
  },
  iconButton: {
    marginRight: '8px',
  },
}));

const TopMenuRow = (props) => {
  const { onMenuButtonClick, bookmarked, messagePending, onBackButtonClick } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Button
        variant="text"
        classes={{ root: classes.buttonRoot }}
        onClick={onBackButtonClick}
        disableRipple
      >
        <LeftArrow className={classes.icon} />
        Teams
      </Button>
      <div className={classes.iconButton}>
        {bookmarked && (
          <IconButton>
            <BookmarkStar />
          </IconButton>
        )}
        {messagePending && (
          <IconButton>
            <Alarm />
          </IconButton>
        )}
        {onMenuButtonClick && (
          <IconButton onClick={onMenuButtonClick}>
            <MenuIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

TopMenuRow.propTypes = {
  onMenuButtonClick: PropTypes.func,
  bookmarked: PropTypes.bool,
  messagePending: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
};

TopMenuRow.defaultProps = {
  onMenuButtonClick: () => {},
  bookmarked: false,
  messagePending: false,
  onBackButtonClick: () => {},
};

export default TopMenuRow;
