import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  useSetCloseContentTab,
  useSetCloseOtherTabs,
  useSetCloseAllTabs,
  useSetCloseTabsToLeft,
  useSetCloseTabsToRight,
  useTabs,
} from 'store/tabs';

import ContextMenuView from './contextMenu-view';

const ContextMenuContainer = ({ tab, onClose, contextMenuPos }) => {
  const [tabs] = useTabs();
  const closeContentTab = useSetCloseContentTab();
  const closeOtherTabs = useSetCloseOtherTabs();
  const closeAllTabs = useSetCloseAllTabs();
  const closeTabsToLeft = useSetCloseTabsToLeft();
  const closeTabsToRight = useSetCloseTabsToRight();

  const contentTabs = useMemo(() => tabs.filter((t) => t.tabType !== 'icon'), [tabs]);

  const hasOtherTabs = useMemo(() => {
    const otherTabs = contentTabs.filter((t) => t.id !== tab.id);
    return otherTabs.length > 0;
  }, [contentTabs, tab.id]);

  const hasTabsToLeft = useMemo(() => {
    const tabsToLeft = contentTabs.filter((_t, index) => index < contentTabs.indexOf(tab));
    return tabsToLeft.length > 0;
  }, [contentTabs, tab]);

  const hasTabsToRight = useMemo(() => {
    const tabsToRight = contentTabs.filter((_t, index) => index > contentTabs.indexOf(tab));
    return tabsToRight.length > 0;
  }, [contentTabs, tab]);

  const handleCloseActiveTab = () => {
    closeContentTab({ id: tab.id });
    onClose();
  };

  const handleCloseOtherTabs = () => {
    closeOtherTabs(tab);
    onClose();
  };

  const handleCloseAllTabs = () => {
    closeAllTabs();
    onClose();
  };

  const handleCloseTabsToLeft = () => {
    closeTabsToLeft(tab);
    onClose();
  };

  const handleCloseTabsToRight = () => {
    closeTabsToRight(tab);
    onClose();
  };

  return (
    <ContextMenuView
      contentTabsLength={contentTabs.length}
      contextMenuPos={contextMenuPos}
      hasOtherTabs={hasOtherTabs}
      hasTabsToLeft={hasTabsToLeft}
      hasTabsToRight={hasTabsToRight}
      onClose={onClose}
      handleCloseActiveTab={handleCloseActiveTab}
      handleCloseOtherTabs={handleCloseOtherTabs}
      handleCloseAllTabs={handleCloseAllTabs}
      handleCloseTabsToLeft={handleCloseTabsToLeft}
      handleCloseTabsToRight={handleCloseTabsToRight}
    />
  );
};

ContextMenuContainer.propTypes = {
  /** tab on which context menu was requested  */
  tab: PropTypes.object.isRequired,
  /** callback to close context menu */
  onClose: PropTypes.func.isRequired,
  /** position of context menu */
  contextMenuPos: PropTypes.shape({
    mouseX: PropTypes.number,
    mouseY: PropTypes.number,
  }).isRequired,
};

export default ContextMenuContainer;
