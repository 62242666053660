import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ThumbnailComponent from 'components/thumbnail';
import { ThumbnailWrapper, Button, AddIcon, ButtonLabel, ButtonChildWrapper } from './styled';

const Thumbnail = ({ src, onAdd, onRemove, readOnly }) => (
  <ThumbnailWrapper>
    {src ? (
      <ThumbnailComponent src={src} size={88} onClose={onRemove} type="image" />
    ) : (
      <Button type="dashedOutlined" onClick={onAdd} disabled={readOnly}>
        <ButtonChildWrapper>
          <AddIcon />
          <ButtonLabel>Add Thumbnail</ButtonLabel>
        </ButtonChildWrapper>
      </Button>
    )}
  </ThumbnailWrapper>
);

Thumbnail.propTypes = {
  /** thumbnail image source */
  src: PropTypes.string,
  /** callback to add thumbnail */
  onAdd: PropTypes.func,
  /** callback to remove thumbnail */
  onRemove: PropTypes.func,
  /** boolean to stop editing */
  readOnly: PropTypes.bool,
};

Thumbnail.defaultProps = {
  src: '',
  onAdd: () => {},
  onRemove: () => {},
  readOnly: false,
};

export default memo(Thumbnail);
