import React, { forwardRef } from 'react';

import selectionTypes from '../../selectionTypes';

import Contacts from '../contacts';
import Teams from '../teams';
import Departments from '../departments';

const ViewComponent = forwardRef((props, ref) => {
  const { selectionType } = props;

  switch (selectionType) {
    case selectionTypes.CONTACTS:
      return <Contacts {...props} containerRef={ref} />;
    case selectionTypes.TEAMS:
      return <Teams {...props} containerRef={ref} />;
    case selectionTypes.DEPARTMENT:
      return <Departments {...props} containerRef={ref} />;
    default:
      return null;
  }
});

export default ViewComponent;
