import styled from '@emotion/styled';
import { List as MuiList, MenuItem as MuiMenuItem, Paper } from '@material-ui/core';
import theme from 'theme/theme';

export const ListWrapper = styled(Paper)`
  position: absolute;
  ${(props) => ({ top: props.top, left: props.left })};
  z-index: 100;
  background-color: ${theme.palette.dina.surfaceDialogs};
`;

export const List = styled(MuiList)`
  border-radius: 4px;
`;

export const MenuItem = styled(MuiMenuItem)`
  color: ${theme.palette.dina.highEmphasis};
`;
