import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import useStyles from './dialogFooter-styles';

const DialogFooter = ({
  content,
  selectedLeftMenu,
  onCancel,
  onOk,
  isProcessingSubmit,
  restricted,
}) => {
  const classes = useStyles();
  const [submitConfirmed, setSubmitConfirmed] = useState(false);

  const handleSubmit = () => {
    if (!submitConfirmed) {
      setSubmitConfirmed(true);
    } else {
      onOk(content);
      setSubmitConfirmed(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <Button
          onClick={onCancel}
          classes={{
            root: `${classes.button} ${classes.cancelButton}`,
            label: classes.cancelLabel,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!selectedLeftMenu || restricted}
          classes={{
            root: `${classes.button} ${classes.okButton} ${submitConfirmed ? 'confirmed' : ''}`,
            label: `${classes.okLabel} ${submitConfirmed ? 'confirmed' : ''}`,
          }}
        >
          {isProcessingSubmit && <LoadingIndicator />}
          {!isProcessingSubmit && submitConfirmed
            ? 'Click again to Restore'
            : 'Restore this version'}
        </Button>
      </div>
    </div>
  );
};

DialogFooter.propTypes = {};

DialogFooter.defaultProps = {};

export default DialogFooter;
