/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';
import Group from 'components/listGroup';
import Divider from 'components/divider';
import { makeStyles } from '@material-ui/styles';
import groupBy from 'lodash/groupBy';
import useFuseSearch from 'hooks/useFuseSearch';
import Member from './listItem';

const useStyles = makeStyles((theme) => ({
  divider: {
    paddingLeft: '72px',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
  },
}));

const MembersList = (props) => {
  const { members, dropHandler, type, searchString, ...rest } = props;
  const classes = useStyles();
  const search = useFuseSearch();

  const filteredMembers = search(
    members,
    ['mTitle', 'mDescription', 'mProperties.email', 'mProperties.phone'],
    searchString,
  );

  const sortedMembers = filteredMembers
    .filter(({ mTitle }) => !!mTitle)
    .sort((a, b) => (a.mTitle.toLowerCase() > b.mTitle.toLowerCase() ? 1 : -1));

  const groupedMembers = groupBy(sortedMembers, ({ mTitle }) => mTitle.charAt(0).toUpperCase());

  return Object.entries(groupedMembers).map(([groupTitle, groupMembers]) => {
    const numberOfItems = groupMembers.length;

    return (
      <Group title={groupTitle} key={groupTitle} id={type + groupTitle}>
        <List disablePadding>
          {numberOfItems > 0 &&
            groupMembers.map((groupMember, index) => {
              const { mId, mTitle, mCreatedAt, mAvatarUrl, mDescription, mProperties, mAvatarKey } =
                groupMember;

              return (
                <div key={mId}>
                  <Member
                    id={mId}
                    name={mTitle}
                    image={mAvatarUrl}
                    imageKey={mAvatarKey}
                    title={mCreatedAt}
                    description={mDescription}
                    properties={mProperties}
                    type={type}
                    handleDrop={(memberId, storyId) => dropHandler(memberId, storyId)}
                    {...rest}
                  />

                  <div className={numberOfItems === index + 1 ? '' : classes.divider}>
                    <Divider />
                  </div>
                </div>
              );
            })}
        </List>
      </Group>
    );
  });
};

MembersList.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mCreatedAt: PropTypes.string,
      description: PropTypes.string,
      handleDrop: PropTypes.func,
    }),
  ),
  dropHandler: PropTypes.func,
  type: PropTypes.string,
  searchString: PropTypes.string,
};

MembersList.defaultProps = {
  members: [],
  dropHandler: () => {},
  type: 'contact',
  searchString: '',
};

export default MembersList;
