import React, { useEffect, useContext, useCallback, memo, useMemo } from 'react';

import { BreakingFeedContext } from 'contexts/BreakingFeedContext';
import useBoundingClientRect from 'hooks/useBoundingClientRect';
import { useTabs, useTabIndexValue, useSetTabIndex, useMaxVisibleTabs } from 'store/tabs';

import TabsView from './tabs-view';
import TabsDropdown from './components/dropdown';

const minContentTabWidth = 146;
const maxContentTabWidth = 240;
const iconTabWidth = 48;

const TabsContainer = () => {
  const [tabs] = useTabs();
  const tabIndex = useTabIndexValue();
  const setTabIndex = useSetTabIndex();
  const [maxVisibleTabs, setMaxVisibleTabs] = useMaxVisibleTabs();
  const [{ width }, ref] = useBoundingClientRect();
  const [breakingFeedNotifications, clearNotificationCount] = useContext(BreakingFeedContext);

  const contentTabs = useMemo(() => tabs.filter((tab) => tab.tabType !== 'icon'), [tabs]);
  const iconTabs = useMemo(() => tabs.filter((tab) => tab.tabType === 'icon'), [tabs]);

  const maxContentTabs = useMemo(() => {
    if (!width) return 0;
    return Math.floor((width - iconTabs.length * iconTabWidth) / minContentTabWidth);
  }, [iconTabs.length, width]);

  const contentTabWidth = useMemo(() => {
    let contentWidth = 0;
    const cTWidth = Math.floor(
      (width -
        iconTabs.length * iconTabWidth -
        ((width - iconTabs.length * iconTabWidth) % minContentTabWidth)) /
        contentTabs.length,
    );
    if (cTWidth < minContentTabWidth) {
      contentWidth = minContentTabWidth;
    }
    if (cTWidth > maxContentTabWidth) {
      contentWidth = maxContentTabWidth;
    }
    if (cTWidth >= minContentTabWidth && cTWidth <= maxContentTabWidth) {
      contentWidth = cTWidth;
    }
    return contentWidth;
  }, [contentTabs.length, iconTabs.length, width]);

  useEffect(() => {
    setMaxVisibleTabs(maxContentTabs + iconTabs.length);
  }, [maxContentTabs, iconTabs.length, setMaxVisibleTabs]);

  const handleChange = useCallback((_event, newValue) => setTabIndex(newValue), [setTabIndex]);

  return (
    <>
      <TabsView
        ref={ref}
        tabs={tabs}
        tabIndex={tabIndex}
        maxVisibleTabs={maxVisibleTabs}
        handleChange={handleChange}
        breakingFeedNotifications={breakingFeedNotifications}
        clearNotificationCount={clearNotificationCount}
        contentTabWidth={contentTabWidth}
      />
      {/* Dropdown ↓ */}
      <TabsDropdown />
    </>
  );
};

export default memo(TabsContainer);
