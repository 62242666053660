import returnStates from 'screens/planning/components/status/utils/statusBoards/returnStates';

export const planningViews = {
  STATUS: 'Status',
  TIMELINE: 'Hours',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
};

export const timeVariants = {
  DAY: 'date',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const getDefaultStateCollapsed = () => {
  const states = returnStates('status-normal');
  const defaultStateColumns = {};
  states.forEach(({ id }) => {
    defaultStateColumns[id] = false;
  });
  return defaultStateColumns;
};

export const planningStateTypes = {
  VIEW: 'view',
  TIME: 'time',
  TAB_INDEX: 'tabIndex',
  TIME_VARIANT: 'timeVariant',
  SAVED_FILTERS: 'savedFilters',
  STATE_COLLAPSED: 'stateCollapsed',
  SHOW_SCHEDULED: 'showScheduled',
  STATES_VIEW: 'statesView',
  CURRENT_FILTER: 'currentFilter',
  SHOW_STORY: 'showStory',
  SHOW_PITCH: 'showPitch',
};

export const DEFAULT_STATE = {
  [planningStateTypes.VIEW]: planningViews.STATUS,
  [planningStateTypes.TIME]: new Date().toISOString(),
  [planningStateTypes.TAB_INDEX]: 0,
  [planningStateTypes.TIME_VARIANT]: timeVariants.DAY,
  [planningStateTypes.SAVED_FILTERS]: [],
  [planningStateTypes.STATE_COLLAPSED]: getDefaultStateCollapsed(),
  [planningStateTypes.SHOW_SCHEDULED]: true,
  [planningStateTypes.STATES_VIEW]: 'status-normal',
  [planningStateTypes.CURRENT_FILTER]: {},
  [planningStateTypes.SHOW_STORY]: true,
  [planningStateTypes.SHOW_PITCH]: true,
};

export const defaultTabs = [
  { label: planningViews.STATUS, timeVariant: timeVariants.DAY },
  { label: planningViews.TIMELINE, timeVariant: timeVariants.DAY },
  { label: planningViews.DAY, timeVariant: timeVariants.DAY },
  { label: planningViews.WEEK, timeVariant: timeVariants.WEEK },
  { label: planningViews.MONTH, timeVariant: timeVariants.MONTH },
];
