import React from 'react';
import useStorageImage from 'hooks/useStorageImage';
import { useApolloClient } from '@apollo/client';
import { uploadToS3 } from 'utils/s3Utils';
import HeaderView from './header-view';

const HeaderContainer = (props) => {
  const client = useApolloClient();
  const { avatarUrl, imageKey: imageStoragePath, teamId } = props;
  const { data: imageSrc } = useStorageImage(imageStoragePath, !!avatarUrl);

  const updateAvatar = async (imageFile, imageUrl) => {
    uploadToS3(imageStoragePath, imageFile);

    client.cache.modify({
      id: client.cache.identify(teamId),
      fields: {
        mAvatarUrl: () => imageUrl,
      },
    });
  };

  return <HeaderView {...props} {...{ updateAvatar }} avatarUrl={imageSrc || avatarUrl} />;
};

export default HeaderContainer;
