import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    height: '32px',
    minWidth: '200px',
    borderRadius: '2px',
    border: `0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
    '&:hover': {
      border: `0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  inputOutline: {
    border: 'none',
  },
  inputFocused: {
    border: `1px solid ${theme.palette.dina.onFocus}`,
    '&:hover': {
      border: `1px solid ${theme.palette.dina.onFocus}`,
    },
  },
  adoredStart: {
    padding: '4px',
  },
  input: {
    ...theme.typography.dina.listItemLabel,
  },
  closeIcon: {
    cursor: 'pointer',
  },
}));

export default useStyles;
