import React, { memo } from 'react';
import useGetInstances from 'hooks/useGetInstances';
import LoadingIndicator from 'components/loadingIndicator';
import InstancePreview from 'screens/main/components/leftArea/sidebar/rundown/instancePreview';
import { distanceInWordsStrict } from 'date-fns';
import styled from '@emotion/styled';
import theme from 'theme/theme';

const LoaderWrapper = styled('div')`
  width: 100%;
  max-height: calc(100% - 64px);
  color: ${theme.palette.dina.highEmphasis};
  text-align: center;
  position: fixed;
  left: 0;
  top: 50%;
  display: flex;
  align-items: center;
`;

const ErrorWrapper = styled('div')`
  width: 527px;
  max-height: calc(100% - 64px);
  min-height: 100px;
  color: ${theme.palette.dina.highEmphasis};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.dina.surfaceCardDark};
`;

const LinkPreview = ({ instanceId, onClose }) => {
  const [data, error, loading] = useGetInstances(instanceId);

  if (loading)
    return (
      <LoaderWrapper>
        <LoadingIndicator />
      </LoaderWrapper>
    );

  if (error || !data?.getMember) return <ErrorWrapper>Error loading instance</ErrorWrapper>;

  const instance = data.getMember;

  const { mProperties, mUpdatedAt } = instance;
  const { account } = mProperties;

  const subtitle = `@${account.accountTitle}`;
  const lastUpdatedAt = distanceInWordsStrict(new Date(mUpdatedAt), new Date());

  return (
    <InstancePreview
      image={instance.mThumbnailUrl}
      timingInfo={lastUpdatedAt}
      onClose={onClose}
      onOpenStory={null}
      id={instance.mId}
      title={instance.mTitle}
      mContentKey={instance.mContentKey}
      assignees={instance?.mUsers || []}
      state={instance.mState}
      metaData={instance.mMetaData}
      destination={subtitle}
      platform={instance.mProperties?.platform || {}}
      publishingAt={instance.mPublishingAt}
      isOpenStoryDisabled
      instance={instance}
      isLinkingDisabled
    />
  );
};

export default memo(LinkPreview);
