import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import memberTypes from 'graphql/memberTypes';
import { useAddContentTab } from 'store/tabs';

const useOpenStory = () => {
  const addContentTab = useAddContentTab();
  const [, setCurrentClicked] = useScrollIntoView();
  const openStory = async (title, id, type = memberTypes.STORY, scrollId = null) => {
    addContentTab({
      type,
      title,
      id,
    });
    if (scrollId) await setCurrentClicked(scrollId);
  };
  return openStory;
};

export default useOpenStory;
