import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './timelineEntry-styles';

const TimelineEntry = ({ item, index }) => {
  const { labels, timestamp, updatedBy } = item;
  let state;
  let customName;
  if (labels && labels.length > 0) {
    [state, customName] = labels;
  }
  let itemHeight = 48;
  if (state) itemHeight += 20;
  if (customName) itemHeight += 22;
  const classes = useStyles({ itemHeight, index });

  return (
    <div className={classes.listItem}>
      {state && (
        <div className={classes.event}>
          <div className={classes.boxedStateLayout}>
            <Typography className={classes.stateLabel}>{state}</Typography>
          </div>
        </div>
      )}

      {customName && (
        <div className={classes.customNameEvent}>
          <div className={classes.boxedCustomNameLayout}>
            <Typography className={classes.customNameLabel}>{customName}</Typography>
          </div>
        </div>
      )}

      {timestamp && (
        <div className={classes.timestamp}>
          <Typography className={classes.timestampLabel}>{timestamp}</Typography>
        </div>
      )}

      {updatedBy && (
        <div className={classes.updatedBy}>
          <Typography className={classes.updatedByLabel}>{updatedBy}</Typography>
        </div>
      )}
    </div>
  );
};

export default TimelineEntry;
