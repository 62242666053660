import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  headerDiv: {
    position: 'relative',
    overflow: 'hidden',
    background: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    paddingBottom: '12px',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectPosition: '0% 25%',
  },
  avatar: {
    opacity: 0.5,
    filter: 'blur(15px)',
  },
}));

export default useStyles;
