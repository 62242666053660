import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/tabs';
import DialogWrapper from 'components/instanceCard/components/shared/dialogWrapper';
import { ReactComponent as PortraitIcon } from 'assets/icons/systemicons/mobile_portait.svg';
import { ReactComponent as LandscapeIcon } from 'assets/icons/systemicons/mobile_landscape.svg';
import { ReactComponent as TabletIcon } from 'assets/icons/systemicons/landscape_off.svg';
import { ReactComponent as DesktopIcon } from 'assets/icons/systemicons/desktop.svg';
import useStyles from './preview-styles';

const Preview = ({ id, title, closeDialog, src }) => {
  const classes = useStyles({ hasPreview: Boolean(src) });

  /** default tab selected: desktop */
  const [tabIndex, setTabIndex] = useState(3);

  const tabs = [
    {
      icon: <PortraitIcon className={classes[tabIndex === 0 ? 'iconEmphasis' : null]} />,
      title: 'Mobile, Portrait',
    },
    {
      icon: <LandscapeIcon className={classes[tabIndex === 1 ? 'iconEmphasis' : null]} />,
      title: 'Mobile, Landscape',
    },
    {
      icon: <TabletIcon className={classes[tabIndex === 2 ? 'iconEmphasis' : null]} />,
      title: 'Tablet',
    },
    {
      icon: <DesktopIcon className={classes[tabIndex === 3 ? 'iconEmphasis' : null]} />,
      title: 'Desktop  ',
    },
  ];

  const onTabChange = (value, index) => {
    setTabIndex(index);
  };

  return (
    <DialogWrapper closeDialog={closeDialog} label={title} mode="Previewing" isPreview>
      <>
        <div className={classes.tabs}>
          <Tabs variant="icon" {...{ tabs, tabIndex, onTabChange }} />
        </div>
        <div className={classes.iframeContainer}>
          <div className={classes[`iFrame${tabIndex}`]}>
            <iframe
              id={id}
              src={src}
              title={title}
              className={classes.iFrame}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        </div>
      </>
    </DialogWrapper>
  );
};

Preview.propTypes = {
  /** Title of the preview dialog */
  title: PropTypes.string,
  /** Callback to close preview dialog */
  closeDialog: PropTypes.func,
  /** Source url for preview component */
  src: PropTypes.string,
  /** Id of the instance */
  id: PropTypes.string,
};

Preview.defaultProps = {
  title: '',
  closeDialog: () => {},
  src: '',
  id: '',
};

export default Preview;
