import UPDATE_RELATED_MEMBERS from 'graphql/mutations/updateRelatedMembers';
import { useMutation } from '@apollo/client';

const useUpdateRelatedMembers = () => {
  const [updateRelatedMembersMutation, { loading }] = useMutation(UPDATE_RELATED_MEMBERS);

  return [updateRelatedMembersMutation, loading];
};

export default useUpdateRelatedMembers;
