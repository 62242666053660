import { useContext } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';

import memberTypes from 'graphql/memberTypes';
import differenceByProp from 'utils/differenceByProp';
import useLogger from 'utils/useLogger';
import SHARE_STORY_WITH from 'graphql/mutations/shareStoryWith';
import GET_STORY from 'graphql/queries/getStory';
import updateCache from 'graphql/utils/cache/share-story';
import UserCtx from 'contexts/UserContext';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import { getMembersOfQuery } from 'graphql/queryVariables';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { useSidebar } from 'store';

const storyMemberMap = {
  usr_str: memberTypes.USER,
  tea_str: memberTypes.TEAM,
  dep_str: memberTypes.DEPARTMENT,
  mar_str: memberTypes.MARKET,
  str_con: memberTypes.CONTACT,
};

const getDataFromCache = async (client, mId, mType) => {
  const { data } = await client.query({
    query: GET_MEMBERS_OF,
    variables: getMembersOfQuery(mId, mType),
    fetchPolicy: 'cache-only',
  });
  return data;
};

const useShareStory = () => {
  const user = useContext(UserCtx);
  const logger = useLogger('use share story');
  const [{ leftHidden, leftSelection }] = useSidebar();

  const [shareStoryMutation] = useMutation(SHARE_STORY_WITH);
  const client = useApolloClient();
  const [updateLeftSidebarCache] = useUpdateLeftSidebarCache();

  const [checkUserRight] = useCheckUserRight();
  const canUpdateStory = checkUserRight('story', 'update');
  const canUpdatePitch = checkUserRight('pitch', 'update');

  const teamData = getDataFromCache(client, user.mId, memberTypes.TEAM_USER);

  const departmentData = getDataFromCache(client, user.mId, memberTypes.DEPARTMENT_USER);

  const getStory = async (storyId) => {
    const result = await client.query({
      query: GET_STORY,
      variables: {
        input: {
          mId: storyId,
        },
      },
    });

    const { data } = result;

    return data && data.getMember;
  };

  const shareStory = (
    storyId,
    type,
    addemembersInput,
    removedMembersInput,
    updateAssigneesInput,
    removedIdsList = [],
  ) => {
    const newMembers = {
      members: addemembersInput,
    };

    const removedMembers = {
      members: removedMembersInput,
    };

    const variables = {
      newMembers,
      removedMembers,
      updateAssigneesInput,
    };

    const getIsAddAction = (filterType, base) =>
      updateAssigneesInput.mAssignedMembers
        .filter((i) => i.mType === filterType)
        .some((t) => base.includes(t.mId));

    shareStoryMutation({
      variables,
      update: async (proxy, mutationResult) => {
        try {
          if (!leftHidden) {
            const story = await getStory(storyId);
            if (leftSelection === memberTypes.STORY || leftSelection === memberTypes.PITCH) {
              updateLeftSidebarCache(story, type, true);
            } else {
              const teams = teamData.getMembersOf.map((t) => t.mId);
              const departments = departmentData.getMembersOf.map((d) => d.mId);

              const shouldUpdateLeftSidebar = [
                ...updateAssigneesInput.mAssignedMembers.map((i) => i.mId),
                ...removedIdsList,
              ].some((item) => [...teams, ...departments, user.mId].includes(item));

              if (shouldUpdateLeftSidebar) {
                let isAddAction = true;
                if (type === memberTypes.USER_STORY)
                  isAddAction = getIsAddAction(memberTypes.USER, [user.mId]);
                if (type === memberTypes.TEAM_STORY)
                  isAddAction = getIsAddAction(memberTypes.TEAM, teams);
                if (type === memberTypes.DEPARTMENT_STORY)
                  isAddAction = getIsAddAction(memberTypes.DEPARTMENT, departments);
                updateLeftSidebarCache(story, type, isAddAction);
              }
            }
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          logger.error(err);
        }
        updateCache(proxy, mutationResult, storyId, type, user.mId);
      },
    }).catch((err) => {
      // eslint-disable-next-line no-console
      logger.error(err);
    });
  };

  const canUpdate = (story) => {
    const { mType: storyType } = story;

    const isPitch = storyType === memberTypes.PITCH || storyType === memberTypes.RESTRICTED_PITCH;
    return isPitch ? canUpdatePitch : canUpdateStory;
  };

  const shareStoryWith = async (storyId, members, existingMembers, mType) => {
    if (!storyId) return;

    const story = await getStory(storyId);
    if (!story || !canUpdate(story)) return;

    const { mAssignedMembers } = story;

    let addMembersInput = [];
    let removedMembersInput = [];
    let removedIdsList = [];

    if (mType === memberTypes.STORY_CONTACT) {
      const [addedIds, removedIds] = differenceByProp(existingMembers, members, 'mRefId');

      addMembersInput = addedIds.map((mRefId) => ({
        mId: storyId,
        mRefId,
        mType,
      }));

      removedMembersInput = removedIds.map((mRefId) => ({
        mId: storyId,
        mRefId,
        mType,
      }));
    } else {
      const [addedIds, removedIds] = differenceByProp(existingMembers, members, 'mId');
      removedIdsList = removedIds;
      addMembersInput = addedIds.map((mId) => ({
        mId,
        mRefId: storyId,
        mType,
      }));

      removedMembersInput = removedIds.map((mId) => ({
        mId,
        mRefId: storyId,
        mType,
      }));
    }

    const memberType = storyMemberMap[mType];

    const assignedMembers = [];

    members.forEach((member) => {
      assignedMembers.push({
        mId: member.mId,
        mType: memberType,
      });
    });

    (mAssignedMembers || [])
      .filter((m) => m.mType !== memberType)
      .forEach((m) => {
        assignedMembers.push({
          mId: m.mId,
          mType: m.mType,
        });
      });

    const updateAssigneesInput = {
      mId: storyId,
      mRefId: storyId,
      mAssignedMembers: assignedMembers,
    };

    shareStory(
      storyId,
      mType,
      addMembersInput,
      removedMembersInput,
      updateAssigneesInput,
      removedIdsList,
    );
  };

  const assignMemberToStory = async (storyId, memberId, memberType) => {
    if (!storyId) return;

    const story = await getStory(storyId);
    if (!story || !canUpdate(story)) return;

    const { mAssignedMembers } = story;

    let memberInput = {
      mId: memberId,
      mRefId: storyId,
      mType: memberTypes.USER_STORY,
    };

    switch (memberType) {
      case memberTypes.TEAM:
        memberInput.mType = memberTypes.TEAM_STORY;
        break;
      case memberTypes.DEPARTMENT:
        memberInput.mType = memberTypes.DEPARTMENT_STORY;
        break;
      case memberTypes.CONTACT:
      case memberTypes.STORY_CONTACT:
        memberInput = {
          mId: storyId,
          mRefId: memberId,
          mType: memberTypes.STORY_CONTACT,
        };
        break;
      default:
        break;
    }

    const assignedMembers = [];

    (mAssignedMembers || []).forEach((m) => {
      assignedMembers.push({
        mId: m.mId,
        mType: m.mType,
      });
    });

    assignedMembers.push({
      mId: memberId,
      mType: storyMemberMap[memberInput.mType],
    });

    let updateAssigneesInput = null;

    updateAssigneesInput = {
      mId: storyId,
      mRefId: storyId,
      mAssignedMembers: assignedMembers,
    };

    shareStory(storyId, memberInput.mType, [memberInput], [], updateAssigneesInput);
  };

  return [assignMemberToStory, shareStoryWith];
};

export default useShareStory;
