import styled from '@emotion/styled';
import colors from 'theme/colors';
import { overline, subtitle1 } from 'theme/styledTypography';
import { ReactComponent as CloseIconComponent } from 'assets/icons/systemicons/close.svg';
import { Modal as MuiModal, IconButton as MuiIconButton } from '@material-ui/core';

export const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  :focus {
    outline: none;
  }
`;

export const AnimatedModalContent = styled('div')`
  :focus {
    outline: none;
  }
  transition: transform 0.2s;
  opacity: ${({ open }) => (open ? 1 : 0)};
`;

export const ContentWrapper = styled('div')`
  width: 70vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  background-color: ${colors.surfaceCardDark};
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(0, 0, 0, 0.25),
    0px 12px 24px rgba(0, 0, 0, 0.25);
  :focus {
    outline: none;
  }
`;

export const HeaderWrapper = styled('div')`
  height: 63px;
  border-bottom: 1px solid ${colors.dividerLight};
  background: ${colors.backgroundResting};
  display: flex;
  justify-content: space-between;
`;

export const HeaderContent = styled('span')`
  display: flex;
  flex-direction: column;
  padding: 15.5px 59px 0 16px;
`;

export const Title = styled('p')`
  ${overline}
  margin:0;
  margin-bottom: 2px;
`;

export const Filename = styled('p')`
  ${subtitle1}
  margin:0;
  color: ${colors.highEmphasis};
  overflow: hidden;
  width: 50vw;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const IconButton = styled(MuiIconButton)`
  margin-right: 16px;
  :hover {
    background-color: transparent;
  }
`;

export const CloseIcon = styled(CloseIconComponent)`
  :hover {
    path {
      fill: ${colors.highEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const BodyWrapper = styled('div')`
  flex-grow: 1;
  padding: 12px 24px;
  border-bottom: 1px solid ${colors.dividerLight};
  background-color: ${colors.highEmphasis};
  color: ${colors.blackHighEmphasis};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${colors.dividerDark};
  }
`;

export const FooterWrapper = styled('div')`
  background: ${colors.backgroundResting};
  height: 57px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2px;
`;
