import React from 'react';
import PropTypes from 'prop-types';
import { Popover as MaterialPopover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  createInstance: {
    backgroundColor: theme.palette.dina.surfaceCard,
    overflowY: 'visible',
    overflowX: 'visible',
    borderRadius: '8px',
  },
  createView: {
    backgroundColor: theme.palette.dina.surfaceCard,
    overflowY: 'visible',
    overflowX: 'visible',
    borderRadius: '8px',
    width: '310px',
  },
  surfaceCardDark: {
    backgroundColor: theme.palette.dina.surfaceDialogs,
    overflowY: 'visible',
    overflowX: 'visible',
    borderRadius: '8px',
    backdropFilter: 'blur(30px)',
  },
  div: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

const Popover = ({ children, onClose, noMargin, position, anchorEl, type, ...others }) => {
  const classes = useStyles();
  // eslint-disable-next-line one-var
  let verticalAnchor, horizontalAnchor, verticalTransform, horizontalTransform;

  if (position === 'top') {
    verticalAnchor = 'bottom';
    horizontalAnchor = 'right';
    verticalTransform = 'center';
    horizontalTransform = 'right';
  } else if (position === 'right') {
    verticalAnchor = 'top';
    horizontalAnchor = 'right';
    verticalTransform = 'top';
    horizontalTransform = 'left';
  } else if (position === 'right-top') {
    verticalAnchor = 'top';
    horizontalAnchor = 'right';
    verticalTransform = 'top';
    horizontalTransform = 'right';
  } else if (position === 'left-center') {
    verticalAnchor = 'center';
    horizontalAnchor = 'left';
    verticalTransform = 'top';
    horizontalTransform = 'left';
  } else if (position === 'left-top') {
    verticalAnchor = 'top';
    horizontalAnchor = 'left';
    verticalTransform = 'top';
    horizontalTransform = 'left';
  } else if (position === 'bottom-center') {
    verticalAnchor = 'bottom';
    horizontalAnchor = 'center';
    verticalTransform = 'top';
    horizontalTransform = 'right';
  } else if (position === 'bottom-right') {
    verticalAnchor = 'center';
    horizontalAnchor = 'right';
    verticalTransform = 'top';
    horizontalTransform = 'left';
  } else if (position === 'center-left') {
    verticalAnchor = 'center';
    horizontalAnchor = 'left';
    verticalTransform = 'top';
    horizontalTransform = 'right';
  } else if (position === 'right-center') {
    verticalAnchor = 'bottom';
    horizontalAnchor = 'center';
    verticalTransform = 'center';
    horizontalTransform = 'left';
  } else if (position === 'center') {
    verticalAnchor = 'center';
    horizontalAnchor = 'center';
    verticalTransform = 'center';
    horizontalTransform = 'center';
  } else if (position === 'publish-setting-custom') {
    verticalAnchor = 'center';
    horizontalAnchor = 'center';
    verticalTransform = 'top';
    horizontalTransform = 'center';
  } else if (position === 'top-center') {
    verticalAnchor = 'top';
    horizontalAnchor = 'center';
    verticalTransform = 'bottom';
    horizontalTransform = 'center';
  }

  return (
    <MaterialPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        // if top or bottom vertical will be changed horizontal will be centered
        vertical: verticalAnchor,
        // if left or right horizontal will be changed vertical will be centered
        horizontal: horizontalAnchor,
      }}
      transformOrigin={{
        vertical: verticalTransform,
        horizontal: horizontalTransform,
      }}
      classes={{ paper: classes[type] }}
      {...others}
    >
      <div className={noMargin ? '' : classes.div}>{children}</div>
    </MaterialPopover>
  );
};

Popover.propTypes = {
  /** Content that will be shown on the popover */
  children: PropTypes.node,
  /** Default DOM element (object or function) */
  anchorEl: PropTypes.objectOf(PropTypes.object),
  /** Position of the popover  */
  position: PropTypes.string,
  /** onClose function: enables the popover to close */
  onClose: PropTypes.func,
  /** values: createInstance or createView */
  type: PropTypes.string,
  /** whether there will be margin or not */
  noMargin: PropTypes.bool,
};

Popover.defaultProps = {
  children: '',
  anchorEl: null,
  position: 'top',
  onClose: () => {},
  type: 'createInstance',
  noMargin: false,
};

export default Popover;
