import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Fade } from '@material-ui/core';

import { useUploadingFiles } from 'store';
import useHover from 'hooks/useHover';
import ArrowDown from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
import PlusSmallCircle from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import ArrowUp from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import LoadingIndicator from 'components/loadingIndicator';

import {
  AddButton,
  ExpandIcon,
  GroupHeader,
  ItemCount,
  Label,
  LabelContainer,
  UploadProgressContainer,
} from './group-styled';

const GroupView = ({
  label,
  children,
  variant,
  collapsed,
  setCollapsed,
  itemCount,
  onAddClick,
  Input,
  showAddButton,
  type,
  storyId,
}) => {
  const [open, setOpen] = useState(collapsed);
  const [hoverRef, isHovered] = useHover();
  const [uploadingFiles] = useUploadingFiles();

  const showButton = typeof showAddButton === 'undefined' ? true : showAddButton;

  const showUploadProgress = uploadingFiles.find(
    (f) => f.mediaType?.includes(type) && f.storyId?.includes(storyId),
  );

  return (
    <>
      <GroupHeader
        button
        onClick={() => {
          setOpen(!open);
          setCollapsed(!collapsed);
        }}
        variant={variant}
        ref={hoverRef}
      >
        <LabelContainer>
          <ExpandIcon
            src={!open ? ArrowUp : ArrowDown}
            alt="expand/collapse icon"
            title="Expand / Collapse"
          />
          <Label>{label}</Label>
          {showUploadProgress && (
            <UploadProgressContainer $isHovered={isHovered} $onAddClick={onAddClick}>
              <LoadingIndicator />
            </UploadProgressContainer>
          )}
          {!showUploadProgress && itemCount >= 0 && showButton && (
            <ItemCount $isHovered={isHovered} $onAddClick={onAddClick}>
              {itemCount}
            </ItemCount>
          )}
          {showButton && onAddClick && (
            <Fade in={isHovered} timeout={{ enter: 250, exit: 250 }} mountOnEnter unmountOnExit>
              <AddButton
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onAddClick(event);
                }}
                size="small"
                tabIndex="0"
              >
                <img src={PlusSmallCircle} alt="add" />
              </AddButton>
            </Fade>
          )}
        </LabelContainer>
      </GroupHeader>
      {Input}
      <Collapse in={!open} timeout="auto" mountOnEnter>
        {children}
      </Collapse>
    </>
  );
};

GroupView.propTypes = {
  /** label to show on the input field */
  label: PropTypes.string,
  /** defines whether group header will be initially collapsed or not  */
  collapsed: PropTypes.bool,
  /** children to be rendered under group header */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  /** counting the number of children */
  itemCount: PropTypes.number,
  /** variant of the group header */
  variant: PropTypes.string,
  /** onAddButtonClick */
  onAddClick: PropTypes.func,
  /** callback that sets the collapsed boolean */
  setCollapsed: PropTypes.func,
  /** video / audio / image / application */
  type: PropTypes.string,
  /** story id */
  storyId: PropTypes.string,
};

GroupView.defaultProps = {
  label: '',
  collapsed: false,
  children: null,
  itemCount: undefined,
  variant: '',
  onAddClick: undefined,
  setCollapsed: () => {},
  type: '',
  storyId: '',
};

export default GroupView;
