import styled from '@emotion/styled';
import theme from 'theme/theme';

const { dividerLight } = theme.palette.dina;

export const Container = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  flex: 1;
  margin-left: -1px;
  border-bottom: 1px solid ${dividerLight};
`;

export const OuterWrapper = styled('div')`
  height: 40px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: -1px;
  border-top: 1px solid ${dividerLight};
`;

export const InnerWrapper = styled('div')`
  display: flex;
  gap: 8px;
  flex: 1;
  padding: 0 8px;
  border-left: 1px solid ${dividerLight};
`;

export const Empty = styled('div')`
  height: 40px;
  flex: 1;
  margin-top: -1px;
  border-top: 1px solid ${dividerLight};
`;
