/* eslint-disable import/no-extraneous-dependencies */
import getMosAsset from 'components/editor/utils/getMosAsset';
import { elementTypes } from 'components/editor/constants/types';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import findKey from 'lodash/findKey';

const { secondaryTypes, properties } = elementTypes;
/**
 * Replaces overlay graphics with new one
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const replaceGraphics = (editor, element, payload, update) => {
  const asset = getMosAsset(payload);

  if (asset) {
    const { data } = element;
    const { assets: previousAssets, ...rest } = data;

    const updatedData = {
      ...rest,
      templateVariant: asset.title,
      mosobj: payload,
      typeId: properties[element.type].id,
      templateType: findKey(secondaryTypes, (value) => value === element.type),
    };

    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { data: updatedData }, { at: path });

    ReactEditor.focus(editor);
  }
};

export default replaceGraphics;
