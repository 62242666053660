import React, { useState, useMemo, useCallback } from 'react';

import { useMaxVisibleTabs, useTabs } from 'store/tabs';

import { ReactComponent as DropdownIcon } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet.svg';

import Fade from '@material-ui/core/Fade';

import Popover from 'components/popover';
import TabsDropdownView from './dropdown-view';

import { StyledDropDownWrapper } from './dropdown-styled';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const TabsDropdownContainer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabs] = useTabs();
  const [maxVisibleTabs] = useMaxVisibleTabs();

  const contentTabs = useMemo(() => tabs.filter((tab) => tab.tabType !== 'icon'), [tabs]);
  const maxContentTabs = useMemo(
    () => maxVisibleTabs - tabs.filter((tab) => tab.tabType === 'icon').length,
    [maxVisibleTabs, tabs],
  );

  const handleDropDownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropDownClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const dropDownOpen = Boolean(anchorEl);
  const dropDownId = dropDownOpen ? 'tabs-dropdown' : undefined;

  return (
    <>
      <StyledDropDownWrapper onClick={handleDropDownClick} active={dropDownOpen}>
        {contentTabs.length > maxContentTabs && <>+{contentTabs.length - maxContentTabs}</>}
        <DropdownIcon />
      </StyledDropDownWrapper>
      <Popover
        id={dropDownId}
        open={dropDownOpen}
        onClose={handleDropDownClose}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        TransitionComponent={Fade}
      >
        <TabsDropdownView handleDropDownClose={handleDropDownClose} />
      </Popover>
    </>
  );
};

export default TabsDropdownContainer;
