import { ReactEditor } from 'slate-react';
import getMosAsset from 'components/editor/utils/getMosAsset';
import { elementTypes } from 'components/editor/constants/types';
import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';

const { OVERLAY_GRAPHICS } = elementTypes;

const onGraphicsDrop = ({ payload }, editor, element, update) => {
  const asset = getMosAsset(payload);

  if (asset) {
    insertSecondaryAutomationElement(
      editor,
      OVERLAY_GRAPHICS,
      {
        templateVariant: asset.title,
        mosobj: payload,
      },
      false,
    );

    ReactEditor.focus(editor);
  }
};

export default onGraphicsDrop;
