import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  editor: {
    borderRadius: '8px',
    border: `0.5px solid ${theme.palette.dina.inputBorderResting}`,
    overflow: 'hidden',
    ...theme.typography.dina.body2,
  },
}));

export default useStyles;
