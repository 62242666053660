/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState, useCallback, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import throttle from 'lodash/throttle';
import uniq from 'lodash/uniq';

import { useAddContentTab } from 'store/tabs';
import memberTypes from 'graphql/memberTypes';
import SEARCH_STORIES from 'graphql/queries/searchApi';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import UserCtx from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCreateStory from 'hooks/useCreateStory';
import useApolloSubscription from 'hooks/useApolloSubscription';
import useLogger from 'utils/useLogger';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import { List } from '@material-ui/core';
import Group from 'components/listGroup';
import CreateStory from 'components/createNewV2';
import Popover from 'components/shared/popover';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Story from '../listItem';

import getMType from './utils/getMType';
import getRelationType from './utils/getRelationType';
import getFilterMType from './utils/getFilterMType';
import getIsUnscheduled from './utils/getIsUnscheduled';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const GroupContainer = (
  {
    mId,
    groupIds,
    title,
    type,
    setShowStorybox,
    openStory,
    selectedDates,
    scheduleType,
    subTabs,
    selectedSubTab,
    onBeforeRefetch,
    onAfterRefetch,
    userItemsOnly,
    searchString,
    assignedMembers: searchAssignedMembers,
  },
  ref,
) => {
  const user = useContext(UserCtx);
  const { mId: userId } = user;
  const addContentTab = useAddContentTab();
  const [anchorEl, setAnchorEl] = useState(null);
  const [createStory] = useCreateStory();
  const [checkUserRight] = useCheckUserRight();
  const canCreateStory = checkUserRight('story', 'create');
  const canArchiveStory = checkUserRight('story', 'archive');
  const canCreatePitch = checkUserRight('pitch', 'create');
  const canUpdateStory = checkUserRight('story', 'update');
  const canUpdatePitch = checkUserRight('pitch', 'update');
  const enableRestrictionOption = checkUserRight('story', 'access-restriction');
  const logger = useLogger('left area/sidebar/stories/list group/group container');
  const [previewItemId, setPreviewItemId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [, updateOnSubscription, setCurrentFilter] = useUpdateLeftSidebarCache();

  const handleKeyPress = useCallback((key, currentId, items, listLength, keyType) => {
    if (keyType === 'released') {
      setPreviewItemId(currentId);
      return;
    }

    const currentIndex = currentId ? items.findIndex((i) => i.mId === currentId) : null;

    if (key === 'ArrowUp') {
      if (currentIndex !== null && currentIndex > 0) {
        const selected = items[currentIndex - 1];
        setSelectedId(selected?.mId);
      }
    }

    if (key === 'ArrowDown') {
      if (currentIndex !== null && currentIndex < listLength - 1) {
        const selected = items[currentIndex + 1];
        setSelectedId(selected?.mId);
      }
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledHandleKeyPress = useCallback(
    throttle(
      (eventData) => {
        handleKeyPress(
          eventData.key,
          eventData.currentId,
          eventData.items,
          eventData.listLength,
          eventData.keyType,
        );
      },
      500,
      { leading: true },
    ),
    [],
  );

  const handleKeyUp = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      throttledHandleKeyPress({
        key: event.key,
        currentId: selectedId,
        listLength: members.length,
        items: members,
        keyType: 'released',
      });
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      throttledHandleKeyPress({
        key: event.key,
        currentId: selectedId,
        items: members,
        listLength: members.length,
        keyType: 'pressed',
      });
    }
  };

  const { startDate, endDate } = selectedDates;

  const mType = getMType(type);

  const groupType = type === memberTypes.STORY ? type : mType;

  const handleCreateNew = async (mTitle, isRestricted, mPublishingAt, isPitch = false) => {
    try {
      setAnchorEl(null);
      const relationType = getRelationType(type, isPitch);
      const createdItemId = await createStory(
        mId,
        mTitle,
        relationType,
        userId,
        isRestricted,
        mPublishingAt,
      );

      addContentTab({
        type: isPitch ? memberTypes.PITCH : memberTypes.STORY,
        title: mTitle,
        id: createdItemId,
      });
      const elm = document.getElementById('scrollbar');
      if (elm && elm.firstChild) {
        setTimeout(() => {
          elm.firstChild.scrollTop = 0;
        }, 1000);
      }
    } catch (e) {
      logger.error(e);
    }
  };

  const onCreateStory = async (mTitle, isRestricted, mPublishingAt) => {
    await handleCreateNew(mTitle, isRestricted, mPublishingAt, false);
  };

  const onCreatePitch = async (mTitle, isRestricted, mPublishingAt) => {
    await handleCreateNew(mTitle, isRestricted, mPublishingAt, true);
  };

  const bookmarkedIds = Object.keys(user.bookmarks);
  const skip = mType === memberTypes.USER_BOOKMARK && bookmarkedIds.length === 0;

  const mIds = [];

  const isUnscheduled = getIsUnscheduled(scheduleType, mType);
  const assignedMembers = [];

  const mTypes = [getFilterMType(mType, subTabs[selectedSubTab] === 'archived')];

  const searchBefore = endDate ? endDate.toISOString() : undefined;
  const searchAfter = startDate ? startDate.toISOString() : undefined;

  const filter = {
    searchString,
    mTypes,
    assignedMembers,
    userItemsOnly: mType === memberTypes.USER_STORY || userItemsOnly,
  };

  if (isUnscheduled !== undefined) filter.isUnscheduled = isUnscheduled;

  if (searchBefore) filter.searchBefore = searchBefore;
  if (searchAfter) filter.searchAfter = searchAfter;

  const variables = {
    limit: 25,
    filter,
  };

  if (mType === memberTypes.USER_STORY) {
    mTypes.push('pitch');
    assignedMembers.push(...searchAssignedMembers);
  } else if (mType === memberTypes.TEAM_STORY || mType === memberTypes.DEPARTMENT_STORY) {
    const assignedMembersSet = uniq([...groupIds, ...searchAssignedMembers]);
    assignedMembers.push(...assignedMembersSet);
    mTypes.push('pitch');
  } else if (mType === memberTypes.USER_BOOKMARK) {
    mTypes.push('pitch');
    mIds.push(...bookmarkedIds);
    filter.mIds = mIds;
    assignedMembers.push(...searchAssignedMembers);
  } else {
    assignedMembers.push(...searchAssignedMembers);
  }

  setCurrentFilter(filter);

  const { data, error, loading, fetchMore } = useQuery(SEARCH_STORIES, {
    variables,
    fetchPolicy: 'cache-and-network',
    skip,
  });

  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: 'stories',
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const item = subscriptionData.data.notifyMemberUpdateSubscription;
      updateOnSubscription(item, userId);
    },
  });

  useEffect(() => {
    subscribe();

    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingIndicator />;

  if (error) {
    const errorMessage = `Error! ${error.message}`;
    return <span style={{ color: 'white' }}>{errorMessage}</span>;
  }

  const hasMore = !!data?.searchApi?.nextToken;

  const fetchMoreStories = async () => {
    const scrollTopBefore = onBeforeRefetch();
    if (hasMore) {
      await fetchMore({
        variables: {
          ...variables,
          nextToken: data.searchApi.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          // Add the new  items to the end of the old  items.
          searchApi: {
            items: [...previousResult.searchApi.items, ...fetchMoreResult.searchApi.items],
            nextToken: fetchMoreResult.searchApi.nextToken,
            __typename: 'MemberType',
          },
        }),
      });
    }
    onAfterRefetch(scrollTopBefore);
  };

  const getCreateTitle = (createType) => {
    switch (createType) {
      case 'story':
        return 'Create Story';
      case 'pitch':
        return 'Create Pitch';
      default:
        return 'Create';
    }
  };

  const sortMembers = (unsortedMembers) => {
    let ret = [];
    if (scheduleType === 'scheduled') {
      const sortedByPublishingAt =
        unsortedMembers
          .filter(Boolean)
          .sort((a, b) => (a.mPublishingAt < b.mPublishingAt ? 1 : -1)) || [];
      const groupedByPublishingAt = sortedByPublishingAt.reduce((r, a) => {
        // eslint-disable-next-line no-param-reassign
        r[a.mPublishingAt] = [...(r[a.mPublishingAt] || []), a];
        return r;
      }, {});
      ret = Object.values(groupedByPublishingAt)
        .map((arr) => arr.sort((a, b) => (a.mUpdatedAt < b.mUpdatedAt ? 1 : -1)) || [])
        .flat();
    }
    ret =
      unsortedMembers.filter(Boolean).sort((a, b) => (a.mUpdatedAt < b.mUpdatedAt ? 1 : -1)) || [];
    if (mType === memberTypes.TEAM_STORY || mType === memberTypes.DEPARTMENT_STORY) {
      return ret.filter((str) => (str.mAssignedMembers || []).find((m) => m.mId === mId));
    }
    return ret;
  };

  const members = data?.searchApi ? sortMembers(data.searchApi.items) : [];

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Group
        title={title}
        id={mId}
        hasMore={hasMore}
        handleCreateClicked={(e) => setAnchorEl(e.currentTarget.parentNode)}
        type={mType === memberTypes.USER_BOOKMARK ? null : 'left'}
        onLoadMore={fetchMoreStories}
        toolTipTitle={getCreateTitle(type)}
        hideCreateNewButton={!canCreateStory && !canCreatePitch}
      >
        <List disablePadding onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
          {members.filter(Boolean).map((story, index) => (
            <Story
              ref={ref}
              key={story.mId}
              id={story.mId}
              refId={story.mRefId}
              index={index}
              title={story.mTitle}
              description={story.mDescription}
              updatedAt={story.mUpdatedAt}
              publishingAt={story.mPublishingAt}
              assignedMembers={story.mAssignedMembers || []}
              mContentKey={story.mContentKey}
              imgUrl={story.mThumbnailUrl}
              coverPhotoUrl={story.mAvatarUrl}
              groupType={groupType}
              storyType={story.mType}
              isRestricted={story.mType.includes('res_') || story.isRestricted}
              isStorySelected={previewItemId !== null && previewItemId === story.mId}
              isStoryFocused={selectedId !== null && selectedId === story.mId}
              showArchiveButton={canArchiveStory}
              openStory={openStory}
              setShowStorybox={setShowStorybox}
              setPreviewItemId={setPreviewItemId}
              setSelectedId={setSelectedId}
              canUpdatePitch={canUpdatePitch}
              canUpdateStory={canUpdateStory}
            />
          ))}
        </List>
      </Group>
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <CreateStory
          hideRestrictedOption={type !== memberTypes.PITCH || !enableRestrictionOption}
          canCreateStory={canCreateStory}
          canCreatePitch={canCreatePitch}
          onCancel={handleClose}
          onCreateStory={canCreateStory ? onCreateStory : undefined}
          onCreatePitch={canCreatePitch ? onCreatePitch : undefined}
        />
      </Popover>
    </>
  );
};

GroupContainer.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** group id */
  mId: PropTypes.string.isRequired,
  /** group type */
  type: PropTypes.string.isRequired,
};

GroupContainer.defaultProps = {
  title: 'Group',
};

const forwardedGroupContainer = forwardRef(GroupContainer);
export default forwardedGroupContainer;
