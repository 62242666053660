import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  listWrapper: {
    height: '100%',
  },
  backdropRoot: {
    position: 'absolute',
    width: '100%',
  },
}));

export default useStyles;
