import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { EditorContext } from 'globalState';
import UNLOCK_MEMBER from 'graphql/mutations/unlockMember';
import useLogger from 'utils/useLogger';

const useLockMember = () => {
  const logger = useLogger('use unlock member');
  const [unlockMemberMutation] = useMutation(UNLOCK_MEMBER);
  const [, , , setEditorData] = useContext(EditorContext);

  const unlock = async (mId, content) => {
    const input = {
      mId,
    };

    if (content) input.content = JSON.stringify(content);

    try {
      await unlockMemberMutation({
        variables: { input },
      }).then(() => {
        setEditorData({
          mId: null,
          mType: null,
          locked: null,
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.log(e);
    }
  };

  return [unlock];
};

export default useLockMember;
