/* eslint-disable import/no-extraneous-dependencies */
/**
 *  Hook for getting owners data
 */

import { useCallback, useContext, useMemo } from 'react';
import intersectionBy from 'lodash/intersectionBy';
import memberTypes from 'graphql/memberTypes';
import { MembersContext } from 'globalState/members';

const useGetAssignedMembers = (mAssignedMembers) => {
  const { getMembers = () => {} } = useContext(MembersContext);
  const allMembers = getMembers();
  const assignedMembers = useMemo(
    () => (Array.isArray(mAssignedMembers) ? mAssignedMembers : []),
    [mAssignedMembers],
  );

  const getAssignedMembers = useCallback(() => {
    const usersData = assignedMembers.filter((m) => m.mType === memberTypes.USER);
    const teamsData = assignedMembers.filter((m) => m.mType === memberTypes.TEAM);
    const departmentsData = assignedMembers.filter((m) => m.mType === memberTypes.DEPARTMENT);
    const marketsData = assignedMembers.filter((m) => m.mType === memberTypes.MARKET);
    const contactsData = assignedMembers.filter((m) => m.mType === memberTypes.CONTACT);

    const assignedUsers = intersectionBy(allMembers, usersData, 'mId');
    const assignedTeams = intersectionBy(allMembers, teamsData, 'mId');
    const assignedDepartments = intersectionBy(allMembers, departmentsData, 'mId');
    const assignedMarkets = intersectionBy(allMembers, marketsData, 'mId');
    const assignedContacts = intersectionBy(allMembers, contactsData, 'mId');

    return [assignedUsers, assignedTeams, assignedDepartments, assignedMarkets, assignedContacts];
  }, [allMembers, assignedMembers]);

  return [getAssignedMembers];
};

export default useGetAssignedMembers;
