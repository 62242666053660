/* eslint-disable prefer-promise-reject-errors */
import { useCallback } from 'react';
import usePromiseApi from './usePromiseApi';

const cancellablePromise = (promise) => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
};

const delay = (n) => new Promise((resolve) => setTimeout(resolve, n));

const useMouseClickEvents = (onClick, onDoubleClick) => {
  const api = usePromiseApi();

  const handleClick = useCallback(
    (event) => {
      const target = event.currentTarget;
      api.clearPendingPromises();
      const waitForClick = cancellablePromise(delay(300));
      api.appendPendingPromise(waitForClick);

      event.persist();
      return waitForClick.promise
        .then(() => {
          api.removePendingPromise(waitForClick);
          onClick(target);
        })
        .catch((errorInfo) => {
          api.removePendingPromise(waitForClick);
          if (!errorInfo.isCanceled) {
            throw errorInfo.error;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClick],
  );

  const handleDoubleClick = useCallback(
    (event) => {
      api.clearPendingPromises();
      onDoubleClick(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDoubleClick],
  );

  return [handleClick, handleDoubleClick];
};

export default useMouseClickEvents;
