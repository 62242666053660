const normalizeAssetData = (data) => ({
  id: data.id,
  title: data.title,
  src: data.thumbnailUrl,
  thumbnailUrl: data.thumbnailUrl || data.thumbnail,
  itemType: data.itemType,
  duration: data.itemDuration,
  size: data.size,
  extension: data.extension,
  provider: data.provider,
  mediaType: data.mediaType || data.itemType,
  proxy: data.proxy,
});

export default normalizeAssetData;
