import React from 'react';
import { Chip as MaterialChip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as UpArrow } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_up.svg';
import chipData from './chipData';
import useStyles from './chip-styles';

const Chip = ({ type, membersCount, openPopover, fullWidth }) => {
  const isPopulated = membersCount > 0;
  const classes = useStyles({ isPopulated, fullWidth });

  const { title, SelectedIcon, UnselectedIcon } = chipData[type];

  const handleChipClick = (event) => {
    openPopover(event.currentTarget, type);
  };

  return (
    <MaterialChip
      label={title}
      variant="outlined"
      clickable
      onClick={handleChipClick}
      onDelete={handleChipClick}
      icon={isPopulated ? <SelectedIcon className={classes.avatarPopulated} /> : <UnselectedIcon />}
      classes={{
        root: classes.root,
        outlined: classes.outlined,
        label: classes.label,
        icon: classes.icon,
      }}
      deleteIcon={
        <>
          <div className={classes.memberCount}>{membersCount || 'All'}</div>
          <UpArrow className={classes.arrowIcon} />
        </>
      }
    />
  );
};

Chip.propTypes = {
  type: PropTypes.string,
  membersCount: PropTypes.number,
  openPopover: PropTypes.func,
  fullWidth: PropTypes.bool,
};

Chip.defaultProps = {
  type: '',
  membersCount: 0,
  openPopover: () => {},
  fullWidth: false,
};

export default Chip;
