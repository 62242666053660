/* eslint-disable no-param-reassign */

const useMovePortalToViewPort = (el) => {
  if (!el) return;

  const domSelection = window.getSelection();
  const domRange = domSelection.getRangeAt(0);
  const rect = domRange.getBoundingClientRect();

  el.style.opacity = 1;
  el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
  el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
};

export default useMovePortalToViewPort;
