import gql from 'graphql-tag';

export default gql`
  query GetMembersOf($input: GetMemberInput) {
    getMembersOf(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mDescription
      mAvatarUrl @client(always: true)
    }
  }
`;
