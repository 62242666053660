import styled from '@emotion/styled/macro';
import { Select as MaterialSelect, MenuItem as MaterialMenuItem } from '@material-ui/core';
import { captionRegular, body2 } from 'theme/styledTypography';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme/theme';

const menuWidth = 232;

export const RootWrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

export const ArrowIconWrapper = styled('div')`
  width: 16px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectWrapper = styled('div')`
  width: 100%;
  position: relative;
`;

export const MenuItemIcon = styled('div')`
  height: 24px;
  padding-right: 16px;
`;

export const Select = styled(MaterialSelect, { shouldForwardProp: (prop) => prop !== 'showInput' })`
  .MuiSelect-root {
    height: 24px;
    display: ${(props) => (props.showInput ? 'none' : 'flex')};
    align-items: center;
    width: 100%;
    ${captionRegular};
    font-size: 14px;
    color: ${theme.palette.dina.mediumEmphasis};
    line-height: 16px;
  }
  ${MenuItemIcon} {
    display: none;
  }

  .MuiSelect-select {
    padding: 0px;
  }
`;

export const InputWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const MenuItem = styled(MaterialMenuItem)`
  ${body2}
  ${MenuItemIcon} {
    opacity: 0;
  }
  :hover {
    background-color: ${theme.palette.dina.whiteHoverOverlay};
  }
  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: ${theme.palette.dina.onSelected};
    ${MenuItemIcon} {
      opacity: 1;
      svg {
        path {
          fill-opacity: 1;
        }
      }
    }
  }
`;

export const useStyles = makeStyles(({ palette }) => ({
  menu: {
    width: menuWidth,
    padding: 24,
    paddingLeft: 12,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    width: menuWidth - 12 - 24,
    backgroundColor: palette.dina.surfaceDialogs,
    borderRadius: 4,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),' +
      '0px 8px 10px 1px rgba(0,0,0,0.14),' +
      '0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
}));
