/* eslint-disable import/no-extraneous-dependencies */
import _get from 'lodash/get';
import _has from 'lodash/has';
import contentStat from 'components/editor/utils/contentStat';
import convertValue from './valueConverter';

/**
 * Returns true if the given value is undefined (and not null or empty)
 */
const isUndefined = (v) => typeof v === 'undefined';

/**
 * Splits the given path in four parts [selector,path,vtype,kvstore] by ':' and ',' separators
 *  -    path               > [ null, path, null,  null    ]
 *  -    path,      key=val > [ null, path, null,  kvstore ]
 *  -    path,vtype         > [ null, path, vtype, null    ]
 *  -    path,vtype,key=val > [ null, path, vtype, kvstore ]
 *  - md:path               > [ md,   path, null,  null    ]
 *  - md:path,      key=val > [ md,   path, null,  kvstore ]
 *  - md:path,vtype         > [ md,   path, vtype, null    ]
 *  - md:path,vtype,key=val > [ md,   path, vtype, kvstore ]
 * @param {*} path - The path to parse
 * @param {*} sep  - Optional separator, default = ':'
 */

const parseFieldPath = (path) => {
  const [fieldPaths, ...rest] = path.split(',');

  const fieldExpressions = fieldPaths.split('|');

  const fields = fieldExpressions.map((expression) => {
    const [selector, key] = expression.split(':');
    return key ? { selector, key } : { selector: null, key: selector };
  });

  const { vtype, kvstore } = rest.reduce(
    (kvs, item) => {
      const vtypeUndefined = isUndefined(kvs.vtype);

      if (item.includes('=') || !vtypeUndefined) {
        // Support kv without value as boolean true
        const [key, value] = item.split('=');

        /* eslint-disable no-param-reassign */
        kvs.kvstore[key.trim()] = value ? value.trim() : true;
        if (vtypeUndefined) kvs.vtype = null; // I.e. defines vtype as default (null)
        return kvs;
      }
      kvs.vtype = item.trim();
      return kvs;
    },
    { vtype: undefined, kvstore: {} },
  );

  return {
    fields,
    vtype: isUndefined(vtype) ? null : vtype,
    kvstore,
  };
};

const regexMetadataPath = /(.*)\[(.*)\]/;
/**
 * Splits the given metadata path in two parts [mdPath, mdKey]
 *  - mMetaData[myKey] > [mMetaData,myKey]
 * @param {*} path
 */
const parseMetadataPath = (path) => {
  const mres = regexMetadataPath.exec(path);
  return mres ? [mres[1], mres[2]] : [path, null];
};

/**
 * Return true if the given value is a valid value for a key-value store
 * Any undefined or null values are returned as false
 */
const isValidValue = (value) => {
  if (value === null) return false;
  if (typeof value === 'undefined') return false;
  return true;
};

/**
 * Returns a single value from a metadata (key/value) store
 * @param {*} object - The object to retreive the value from
 * @param {*} mdpath - The metadata path: metadata[key]
 * @param {*} defaultValue - Optional default value if property is not found
 */
const getMetadataValue = (object, mdpath, defaultValue = null) => {
  const [path, key] = parseMetadataPath(mdpath);

  const mdata = path ? _get(object, path, null) : object;
  if (!mdata) return defaultValue;

  if (!key) return mdata;

  const field = mdata.find((md) => md.key === key);
  return field && isValidValue(field.value) ? field.value : defaultValue;
};

/**
 * Returns a random generated string
 * @param {*} len - The length of the returned string.
 */
const getRandomValue = (len) => {
  const dec2hex = (dec) => dec.toString(16).padStart(2, '0');
  const arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

const getSystemValue = (key) => {
  let systemValue = null;
  switch (key) {
    case 'now':
      systemValue = new Date().toISOString();
      break;
    case 'rnd4':
      systemValue = getRandomValue(4);
      break;
    default:
      systemValue = '';
      break;
  }
  return systemValue;
};

const getFieldValue = (object, mdmap, fields, vtype, kvstore) => {
  let pvalue = null;
  for (const field of fields) {
    const { selector, key } = field;

    switch (selector) {
      case 'md':
        pvalue = getMetadataValue(object, key, null);
        break;
      case 'system':
        pvalue = getSystemValue(key);
        break;
      default:
        pvalue = _get(object, key, null);
        // this check is to bypass literals
        pvalue = pvalue === null && !_has(object, key) ? key : pvalue;
        break;
    }

    /* eslint-disable no-continue */
    if (pvalue === null || pvalue === '') continue;

    pvalue = convertValue(pvalue, vtype, kvstore, mdmap);

    if (!pvalue) continue;

    if (kvstore) {
      // Assign all properties from the kvstore to the returned object
      if (Array.isArray(pvalue)) {
        pvalue.forEach((item) => Object.assign(item, kvstore));
      } else if (typeof pvalue === 'object') {
        Object.assign(pvalue, kvstore);
      }
    }

    if (pvalue) break;
  }
  return pvalue;
};

/**
 * Returns a single value from the given payload
 * @param {*} object - The object to retreive the value from
 * @param {*} path - The path of the propery to get
 * @param {*} mdmap - How to map DiNA payload to Mimir metadata
 * @param {*} defaultValue - Optional default value if property is not found
 */
const getPayloadValue = (object, path, mdmap, defaultValue = '') => {
  const { fields, vtype, kvstore } = parseFieldPath(path);

  return getFieldValue(object, mdmap, fields, vtype, kvstore);
};

const transform = (templateString = '', templateVariables = {}) =>
  templateString.replace(/\${(.*?)}/g, (_, key) => getPayloadValue(templateVariables, key));

/**
 * Creates default placeholder title
 *
 * @param {String} storyTitle Title of the story instance
 * @param {Object} variables An multi level object to replace string literals
 * @returns {String[]} Colored words
 */
const generatePlaceholderDefaultTitle = (placeholderTemplate = '', variables = {}) => {
  if (!placeholderTemplate) return '';

  // To support passing placeholderTemplate both direct or via the template
  const defaultFormat =
    typeof placeholderTemplate === 'string'
      ? placeholderTemplate
      : placeholderTemplate.defaultFormat;
  if (!defaultFormat) return '';

  return transform(defaultFormat, variables);
};

/**
 * Returns an object with all placeholder properties including the following functions:
 *  - hasPlaceholder(name) - returns true if it already exist an placeholder with the same name.
 * @param {*} prarms
 *  - template: Placeholder properties from settings
 *  - s3Content: Corresponding slate content object, as stored in S3
 * @returns {*} - properties used by the CreateNew:variant=placeholder dialog
 */
const generatePlaceholderProps = ({ template, s3Content, variables }) => {
  if (!template) return { defaultTitle: '', hasPlaceholder: () => {} };
  const { defaultFormat } = template;
  return {
    ...template,
    defaultTitle: generatePlaceholderDefaultTitle(defaultFormat, variables),
    hasPlaceholder: contentStat(s3Content).hasPlaceholder,
  };
};

export { generatePlaceholderProps as default, generatePlaceholderDefaultTitle };
