import { Editor } from 'slate';

const getSelectedElement = (editor) => {
  if (!editor) return null;

  const { selection } = editor;

  if (!selection || !selection.anchor) return null;

  const [selectedElement] = Editor.node(editor, selection, { depth: 1 });

  return selectedElement;
};

export default getSelectedElement;
