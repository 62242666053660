import GET_INSTANCE from 'graphql/queries/getInstance';
import { getStoryInstancesQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';

const getContentKey = (instance) => {
  const { mStoryId, mId } = instance;
  if (mStoryId) return `${mStoryId}/instances/${mId}/content.data`;
  return `${mId}/content.data`;
};

const getUpdatedList = (list, item) => {
  const memberUpdated = {
    ...item.instance,
  };
  const updatedList = [...list];
  if (item.crudAction === 'INSERT') {
    memberUpdated.__typename = 'MemberType';
    // this field should be sent from backend
    memberUpdated.mContentKey = getContentKey(item.instance);
    memberUpdated.mDescription = item.instance.mDescription || 'no-description';
    memberUpdated.locked = item.instance.mDescription || null;
    memberUpdated.mDefaultContentKey = `${item.instance.mId}/content.data` || null;
    memberUpdated.mPublishingAt = item.instance.mPublishingAt || null;
    memberUpdated.mProperties.account.__typename = 'AccountType';
    memberUpdated.mThumbnailUrl = item.instance.mThumbnailUrl || null;
    memberUpdated.mProperties.account.accountRefId =
      item.instance.mProperties.account.accountRefId || null;
    memberUpdated.mMetaData = item.instance.mMetaData || null;
    if (memberUpdated.mMetaData.length > 0) {
      memberUpdated.mMetaData = memberUpdated.mMetaData.map((metadata) => ({
        ...metadata,
        __typename: 'mMetaDataField',
      }));
    }
    const memberExist = updatedList.filter((m) => m.mId === item.instance.mId);
    if (memberExist.length === 0) {
      updatedList.push(memberUpdated);
    }
    return updatedList;
  }
  // crudAction is REMOVE if it is not INSERT.
  return updatedList.filter((m) => m.mId !== item.instance.mId);
};

const updateCache = (proxy, item, query, variables) => {
  try {
    const rootQuery = proxy.cache.data.data.ROOT_QUERY;
    // Query to read from cache.
    const queryInCache = `getMembers({"input":{"mId":"${item.mId}",
      "mType":"${memberTypes.STORY_INSTANCE}"}})`;
    // If query doesn't exists in cache return.
    if (rootQuery[queryInCache] === undefined) {
      return;
    }
    const list = proxy.readQuery({
      query,
      variables,
    });
    // Write updated member list in the cache.
    proxy.writeQuery({
      query,
      variables,
      data: {
        getMembers: getUpdatedList(list.getMembers, item),
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const updateInstanceOfStory = (proxy, item) => {
  try {
    const variables = getStoryInstancesQuery(item.story.mId);
    updateCache(proxy, item, GET_INSTANCE, variables);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default updateInstanceOfStory;
