import React, { createContext, useEffect, useReducer } from 'react';
import { DEFAULT_STATE, planningStateTypes } from 'utils/planningViews';

export const PlanningContext = createContext();

const SAVED_STATE = JSON.parse(window.localStorage.getItem('plans'));
const initialState = { ...DEFAULT_STATE, ...SAVED_STATE };

const reducerFunction = (state, action) => {
  switch (action.type) {
    case planningStateTypes.VIEW:
      return { ...state, [planningStateTypes.VIEW]: action.value };
    case planningStateTypes.TIME:
      return { ...state, [planningStateTypes.TIME]: action.value };
    case planningStateTypes.TAB_INDEX:
      return { ...state, [planningStateTypes.TAB_INDEX]: action.value };
    case planningStateTypes.TIME_VARIANT:
      return { ...state, [planningStateTypes.TIME_VARIANT]: action.value };
    case planningStateTypes.SAVED_FILTERS:
      return { ...state, [planningStateTypes.SAVED_FILTERS]: action.value };
    case planningStateTypes.STATE_COLLAPSED:
      return { ...state, [planningStateTypes.STATE_COLLAPSED]: action.value };
    case planningStateTypes.SHOW_SCHEDULED:
      return { ...state, [planningStateTypes.SHOW_SCHEDULED]: action.value };
    case planningStateTypes.STATES_VIEW:
      return { ...state, [planningStateTypes.STATES_VIEW]: action.value };
    case planningStateTypes.CURRENT_FILTER:
      return { ...state, [planningStateTypes.CURRENT_FILTER]: action.value };
    case planningStateTypes.SHOW_STORY:
      return { ...state, [planningStateTypes.SHOW_STORY]: action.value };
    case planningStateTypes.SHOW_PITCH:
      return { ...state, [planningStateTypes.SHOW_PITCH]: action.value };
    default:
      return state;
  }
};

const PlanningProvider = ({ children }) => {
  const [planningStates, updatePlanningStates] = useReducer(reducerFunction, initialState);

  useEffect(() => {
    window.localStorage.setItem('plans', JSON.stringify(planningStates));
  }, [planningStates]);

  return (
    <PlanningContext.Provider
      value={{
        planningStates,
        updatePlanningStates,
      }}
    >
      {children}
    </PlanningContext.Provider>
  );
};

export { PlanningProvider };
