import React from 'react';
import SplitPane from 'react-split-pane';
import useStyles from './splitView-styles';

const SplitView = ({ pane1, pane2 }) => {
  const classes = useStyles();
  return (
    <SplitPane
      className={classes.splitPane}
      style={{
        position: 'relative',
      }}
      split="vertical"
      minSize={48}
      maxSize={-56}
      pane1Style={{
        minWidth: '21%',
      }}
      pane2Style={{
        minWidth: '45%',
        maxWidth: '79%',
        display: 'flex',
      }}
      defaultSize="70%"
      primary="second"
    >
      {pane1}
      {pane2}
    </SplitPane>
  );
};

SplitView.propTypes = {};

SplitView.defaultProps = {};

export default SplitView;
