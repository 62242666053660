import React from 'react';
import PropTypes from 'prop-types';
import { Button, ListItem, Divider, IconButton } from '@material-ui/core';
import Avatar from 'components/avatar';
import getInitials from 'utils/getInitials';
import AddSmall from 'assets/icons/systemicons/add_small.svg';
import useStyles from './addMember-style';

const AddMember = (props) => {
  const { name, title, image, avatarVariant, selected, onAdd, onRemove, focused } = props;
  const isCategoryVariant = avatarVariant === 'Category';
  const classes = useStyles();

  return (
    <div>
      <ListItem
        button
        className={focused ? 'scrollActive' : ''}
        classes={{
          root: isCategoryVariant ? classes.categoryListItem : classes.listItem,
          button: focused ? classes.activeButton : classes.button,
        }}
        onClick={selected ? onRemove : onAdd}
      >
        <div className={classes.avatarDiv}>
          {image ? (
            <Avatar
              disableScaling
              variant={avatarVariant}
              src={image}
              checked={selected}
              size={isCategoryVariant ? 32 : 48}
            />
          ) : (
            <Avatar disableScaling variant={avatarVariant} checked={selected}>
              {getInitials(name)}
            </Avatar>
          )}
        </div>
        <div className={classes.nameDiv}>
          <div className={classes.nameLine}>{name}</div>
          <div className={classes.titleLine}>{title}</div>
        </div>
        {!selected ? (
          <IconButton tabIndex="-1" classes={{ root: classes.iconButton }} onClick={onAdd}>
            <img alt="add" src={AddSmall} />
          </IconButton>
        ) : (
          <Button
            tabIndex="-1"
            variant="outlined"
            classes={{
              root: classes.buttonRoot,
              outlined: classes.buttonOutlined,
              label: classes.buttonLabel,
            }}
            onClick={onRemove}
          >
            Remove
          </Button>
        )}
      </ListItem>
      <Divider
        classes={{
          root: isCategoryVariant ? classes.categoryDivider : classes.divider,
        }}
      />
    </div>
  );
};

AddMember.propTypes = {
  /** Member name */
  name: PropTypes.string,
  /** Member designation */
  title: PropTypes.string,
  /** image Url for the avatar */
  image: PropTypes.string,
  /** variant of avatar */
  avatarVariant: PropTypes.string,
  /** Item is selected or not */
  selected: PropTypes.bool,
  /** function for adding members */
  onAdd: PropTypes.func,
  /** function for removing members */
  onRemove: PropTypes.func,
};

AddMember.defaultProps = {
  name: '',
  title: '',
  image: '',
  avatarVariant: 'People',
  selected: false,
  onAdd: () => {},
  onRemove: () => {},
};

export default AddMember;
