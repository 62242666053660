import { makeStyles } from '@material-ui/styles';

const buttonStyle = (theme) => ({
  ...theme.typography.dina.button,
  width: '100%',
  borderRadius: '4px',
  height: '40px',
  textTransform: 'none',
  '&:focus': {
    boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
  },
});

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
  },
  cancelButton: {
    ...buttonStyle(theme),
    color: theme.palette.dina.mediumEmphasis,
    margin: '4px 2px 4px 4px',
    border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    backgroundColor: theme.palette.dina.backgroundResting,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  okButton: {
    ...buttonStyle(theme),
    margin: '4px 4px 4px 2px',
    backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    color: theme.palette.dina.highEmphasis,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
      backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    },
    '&:disabled': {
      color: theme.palette.dina.highEmphasis,
      opacity: 0.35,
    },
  },
}));

export default useStyles;
