import { memo } from 'react';
import styled from '@emotion/styled';
import ButtonComponent from 'components/buttons/button';

export const ThumbnailWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

export const Button = styled(memo(ButtonComponent))`
  height: 58px;
  width: 58px;
  border-radius: 2px;
  outline: none;
`;
