import styled from '@emotion/styled';

import theme from 'theme/theme';

import Divider from 'components/divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export const StyledAppbar = styled(AppBar)`
  position: relative;
  height: 48px;
  z-index: ${theme.zIndex.drawer + 1};
  background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
`;

export const StyledToolbar = styled(Toolbar)`
  min-height: 48px;
  padding: 0;
  display: flex;
  gap: 0.5rem;
`;

export const StyledTabDivider = styled(Divider)`
  margin: 12px 0;
`;
