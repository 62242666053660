import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { RootWrapper, Title, CloseButton } from './header-styled';

const Header = ({ onClose }) => (
  <RootWrapper>
    <Title>Dina Settings</Title>
    <CloseButton onClick={onClose}>
      <Close />
    </CloseButton>
  </RootWrapper>
);

Header.propTypes = {
  /** callback to close the message hub */
  onClose: PropTypes.func,
};

Header.defaultProps = {
  onClose: () => {},
};

export default Header;
