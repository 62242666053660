import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  const buttonStyle = {
    ...theme.typography.dina.button,
    height: '40px',
    borderRadius: '4px',
    textTransform: 'none',
    fontWeight: 500,
  };
  return {
    formContainer: {
      width: '309px',
      display: 'flex',
      flexDirection: 'column',
      height: ({ height }) => height || '85vh',
    },
    textRoot: {
      ...theme.typography.dina.formTitle,
      lineHeight: '28px',
      margin: '16px',
    },
    tabs: {
      height: '48px',
      borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
      padding: '0 8px',
    },
    inputDiv: {
      margin: '8px 8px 18px 8px',
    },
    memberListDiv: {
      overflow: 'auto',
      flex: '1',
    },
    footer: {
      marginTop: 'auto',
    },
    buttonContainer: {
      display: 'flex',
    },
    dividerRoot: {
      background: theme.palette.dina.dividerLight,
    },
    cancelButton: ({ isUsageSearchBar }) => ({
      ...buttonStyle,
      margin: '8px 4px 8px 8px',
      border: isUsageSearchBar
        ? `0.5px solid ${theme.palette.dina.buttonBackgroundOutlined}`
        : undefined,
      color: theme.palette.dina.mediumEmphasis,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        color: theme.palette.dina.highEmphasis,
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
        color: theme.palette.dina.highEmphasis,
      },
    }),
    okButton: ({ isUsageSearchBar }) => ({
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor:
        theme.palette.dina[isUsageSearchBar ? 'buttonBackgroundHighEmphasis' : 'statusApproved'],
      '&:hover': {
        backgroundColor:
          theme.palette.dina[isUsageSearchBar ? 'buttonBackgroundHighEmphasis' : 'statusApproved'],
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
      },
      '&:disabled': {
        opacity: '0.35',
        color: theme.palette.dina.disabled,
      },
    }),
  };
});

export default useStyles;
