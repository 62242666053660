import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import UserCtx from 'contexts/UserContext';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import memberTypes from 'graphql/memberTypes';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import ProfileView from './profile-view';
import { ErrorWrapper } from './profile-styled';

const ProfileContainer = (props) => {
  const { groupPolicies } = props;
  const { mId, groups: userGroups } = useContext(UserCtx);
  const groups = groupPolicies.filter((groupPolicy) => userGroups.includes(groupPolicy.mRefId));

  const {
    data: teams,
    error: teamError,
    loading: teamLoading,
  } = useQuery(GET_MEMBERS_OF, {
    variables: {
      input: {
        membersOfType: memberTypes.TEAM_USER,
        mId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: departments,
    error: departmentError,
    loading: departmentLoading,
  } = useQuery(GET_MEMBERS_OF, {
    variables: {
      input: {
        membersOfType: memberTypes.DEPARTMENT_USER,
        mId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  if (teamLoading || departmentLoading) return <LoadingIndicator />;
  if (teamError) return <ErrorWrapper>Error loading teams in profile</ErrorWrapper>;
  if (departmentError) return <ErrorWrapper>Error loading departartments in profile</ErrorWrapper>;

  return (
    <ProfileView
      mId={mId}
      teams={teams?.getMembersOf}
      departments={departments?.getMembersOf}
      groups={groups}
    />
  );
};

ProfileContainer.propTypes = {
  groupPolicies: PropTypes.array,
};

ProfileContainer.defaultProps = {
  groupPolicies: [],
};

export default ProfileContainer;
