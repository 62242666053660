import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: ({ isSelfMessage }) => (isSelfMessage ? '88px' : '16px'),
    paddingRight: ({ isSelfMessage }) => (isSelfMessage ? '24px' : '64px'),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
