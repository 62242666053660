import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Button from 'components/buttons/button';
import ResizableDialog from 'components/resizableDialog';
import Divider from 'components/divider';
import { ReactComponent as Close } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as CopyApproved } from 'assets/icons/systemicons/copyApproved.svg';
import useStyles from './advancedDialog-styles';

const Header = ({ classes, title, itemId, subtitle, onClose }) => {
  const [copy, setCopy] = useState(false);
  const onCopy = () => {
    navigator.clipboard.writeText(itemId);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, '2000');
  };

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.titleContainer}>
        {subtitle ? (
          <>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
            <Typography className={classes.title}>{title}</Typography>
          </>
        ) : (
          <Typography className={classes.title}>{title}</Typography>
        )}
        <div className={classes.idContainer}>
          {itemId ? (
            <>
              <span>
                <Typography className={classes.subtitle}>ID: {itemId}</Typography>
              </span>
              {copy ? (
                <CopyApproved className={classes.copyButton} />
              ) : (
                <Copy className={classes.copyButton} onClick={onCopy} />
              )}
            </>
          ) : null}
        </div>
      </div>
      <button className={classes.closeButton} onClick={onClose} type="button">
        <Close />
      </button>
    </div>
  );
};

const Footer = ({ classes, onCancel, handleSubmit }) => (
  <div className={classes.footerWrapper}>
    <div className={classes.buttonContainer}>
      <Button
        onClick={onCancel}
        shape="full-width"
        classes={{
          root: `${classes.button} ${classes.cancelButton}`,
        }}
      >
        Cancel
      </Button>
      <Button
        shape="full-width"
        onClick={handleSubmit}
        classes={{
          root: `${classes.button} ${classes.okButton}`,
        }}
      >
        Ok
      </Button>
    </div>
  </div>
);

const AdvancedDialog = ({
  children,
  isOpen,
  onClose,
  onSubmit,
  initialWidth,
  initialHeight,
  minWidth,
  minHeight,
  title,
  itemId,
  subtitle,
  headerComponent,
  footerComponent,
}) => {
  const classes = useStyles({});
  return (
    <ResizableDialog
      open={isOpen}
      initialWidth={initialWidth}
      initialHeight={initialHeight}
      minWidth={minWidth}
      minHeight={minHeight}
    >
      <div className={classes.root}>
        <div className={`${classes.headerContainer} dragHandler`}>
          {headerComponent || (
            <Header
              classes={classes}
              title={title}
              subtitle={subtitle}
              itemId={itemId}
              onClose={onClose}
            />
          )}
          <Divider className={classes.divider} />
        </div>
        <div className={classes.contentContainer}>{children}</div>
        <div className={classes.footerContainer}>
          <Divider className={classes.divider} />
          {footerComponent || (
            <Footer classes={classes} onCancel={onClose} handleSubmit={onSubmit} />
          )}
        </div>
      </div>
    </ResizableDialog>
  );
};

AdvancedDialog.propTypes = {
  /** Determines if the dialog is open */
  isOpen: PropTypes.bool,
  /** Runs on dialog close or Cancel button press */
  onClose: PropTypes.func,
  /** Runs on Ok button press */
  onSubmit: PropTypes.func,

  /** Initial width of the dialog in percentage */
  initialWidth: PropTypes.string,
  /** Initial height of the dialog in percentage */
  initialHeight: PropTypes.string,
  /** Minimum width of the dialog */
  minWidth: PropTypes.number,
  /** Minimum height of the dialog */
  minHeight: PropTypes.number,

  /** Title of the dialog */
  title: PropTypes.string,
  /** Title of the dialog */
  subtitle: PropTypes.string,
  /** The element id */
  itemId: PropTypes.string,

  /** Replace default header of the dialog */
  headerComponent: PropTypes.element,
  /** Replace default footer of the dialog */
  footerComponent: PropTypes.element,
};

AdvancedDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onSubmit: () => {},
  initialWidth: '60%',
  initialHeight: '75%',
  minWidth: 840,
  minHeight: 640,
  title: 'Title',
  itemId: undefined,
  subtitle: undefined,
  headerComponent: undefined,
  footerComponent: undefined,
};

export default AdvancedDialog;
