import React from 'react';
import BATCH_GETMEMBERS from 'graphql/queries/batchGetRundownInstances';
import { useQuery, NetworkStatus } from '@apollo/client';
import Scrollbar from 'components/scrollbar';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { Grid, Typography, IconButton, Button } from '@material-ui/core';
import useUpdateRelatedMembers from 'hooks/useUpdateRelatedMembers';
import InstanceListItem from './instance';
import useStyles from './linkedInstances-styles';

const SKIP = false;
const FETCH_POLICY = 'cache-and-network';

const ExistingListContainer = ({
  linkedInstances,
  onOpenAllInstances,
  parentInstance,
  onClose,
  isLinkingDisabled,
}) => {
  const classes = useStyles();

  const [updateRelatedMembersMutation, updateRelatedMembersLoading] = useUpdateRelatedMembers();

  const { data, error, loading, networkStatus } = useQuery(BATCH_GETMEMBERS, {
    variables: {
      input: {
        mIds: linkedInstances && linkedInstances.length ? [...linkedInstances] : [],
      },
    },
    skip: SKIP || linkedInstances.length === 0,
    fetchPolicy: FETCH_POLICY,
  });

  if (
    loading &&
    (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables)
  ) {
    return (
      <div className={classes.instancesContainer}>
        <LoadingIndicator />
      </div>
    );
  }

  if (error) {
    const errorMessage = `Error! ${error.message}`;
    return <div className={classes.buttonLabel}>{errorMessage}</div>;
  }

  return (
    <div className={classes.instancesContainer}>
      <Grid
        container
        nowrap
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerContainer}
      >
        <Grid>
          <Typography className={classes.headerText}>Linked Instances</Typography>
        </Grid>
        <Grid>
          <IconButton onClick={onClose} component="span" aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid className={classes.instanceListContainer}>
        <Scrollbar top={5} bottom={5}>
          <div className={classes.instanceList}>
            {data?.batchGetMembers?.length > 0 ? (
              [...data.batchGetMembers]
                .filter((item) => item)
                .map((item) => (
                  <InstanceListItem
                    key={item.mId}
                    parentInstance={parentInstance}
                    instance={item}
                    onDeleteLink={updateRelatedMembersMutation}
                    loading={updateRelatedMembersLoading}
                    isLinkingDisabled={isLinkingDisabled}
                  />
                ))
            ) : (
              <div className={classes.emptyList}>
                No Instances linked. <br />
                <br /> Please click on the <br />
                <div className={classes.addNewWrapper}>
                  “
                  <span className={classes.addNew} onClick={onOpenAllInstances} role="presentation">
                    Add new link
                  </span>
                  ”-button
                </div>
                to link Instances.
              </div>
            )}
            {loading && networkStatus === NetworkStatus.fetchMore && <LoadingIndicator />}
          </div>
        </Scrollbar>
      </Grid>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        className={classes.buttonContainer}
      >
        <Button
          disabled={updateRelatedMembersLoading}
          classes={{
            root: classes.cancelButton,
            label: classes.buttonLabel,
            disabled: classes.disabled,
          }}
          aria-label="Close button at the bottom"
          onClick={onClose}
        >
          Close
        </Button>

        <Button
          disabled={updateRelatedMembersLoading || isLinkingDisabled}
          onClick={onOpenAllInstances}
          classes={{
            root: classes.createButton,
            label: classes.buttonLabel,
            disabled: classes.disabled,
          }}
          aria-label="add-new-link"
        >
          Add new link
        </Button>
      </Grid>
    </div>
  );
};

export default ExistingListContainer;
