import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    '& > *': {
      marginBottom: '8px',
    },
  },
}));

export default useStyles;
