import React from 'react';
import PropTypes from 'prop-types';

import FixedItems from './components/fixedItems';
import TabItems from './components/tabItems';

import {
  StyledWrapper as Wrapper,
  StyledDropDownContentList as List,
  StyledTabsOverline,
  StyledDivider as Divider,
} from './dropdown-styled';

const TabsDropdownView = ({ handleDropDownClose }) => (
  <Wrapper>
    <List disablePadding>
      {/* Fixed dropdown items */}
      <FixedItems handleDropDownClose={handleDropDownClose} />
      <Divider />
      <StyledTabsOverline>Open Tabs</StyledTabsOverline>
      {/* Dynamic Tab items */}
      <TabItems handleDropDownClose={handleDropDownClose} />
    </List>
  </Wrapper>
);

TabsDropdownView.propTypes = {
  /** callback for dropdown close */
  handleDropDownClose: PropTypes.func.isRequired,
};

export default TabsDropdownView;
