import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'theme/theme';

const Severity = styled('div')`
  width: 4px;
  height: '100%';
  background-color: ${(props) =>
    props.severity === 'mandatory' ? theme.palette.dina.statusWarning : 'transparent'};
`;

const SeverityIndicator = ({ severity, ...rest }) => <Severity severity={severity} {...rest} />;

SeverityIndicator.propTypes = {
  /** defined the color of indicator depending on severity */
  severity: PropTypes.string,
};

SeverityIndicator.defaultProps = {
  severity: 'regular',
};

export default SeverityIndicator;
