/* eslint-disable no-param-reassign */
import gql from 'graphql-tag';

const GET_MESSAGES_OF_CONVERSATION_SHORT = gql`
  query getMessagesOfConversation(
    $input: GetMessagesOfConversationInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagesOfConversation(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
        mContent
      }
      nextToken
    }
  }
`;

const updateCache = (proxy, convoMessage, variables) => {
  try {
    const { getMessagesOfConversation } = proxy.readQuery({
      query: GET_MESSAGES_OF_CONVERSATION_SHORT,
      variables,
    });

    const newMessageList = [];
    const { items } = getMessagesOfConversation;
    const { mContent, crudAction } = convoMessage.message;

    switch (crudAction) {
      case 'REMOVE':
        newMessageList.push(...items.filter((message) => message.mRefId !== convoMessage.mRefId));
        break;
      case 'INSERT':
        convoMessage.__typename = 'MessageType';
        convoMessage.mContent = mContent;
        newMessageList.push(convoMessage, ...items);
        break;
      case 'UPDATE':
        // eslint-disable-next-line no-case-declarations
        const itemIndex = items.findIndex((message) => message.mRefId === convoMessage.mRefId);
        if (itemIndex !== -1) items[itemIndex].mContent = mContent;
        newMessageList.push(...items);
        break;
      default:
        // unknown operation, just copy the current items;
        newMessageList.push(...items);
        break;
    }
    proxy.writeQuery({
      query: GET_MESSAGES_OF_CONVERSATION_SHORT,
      variables,
      data: {
        getMessagesOfConversation: { ...getMessagesOfConversation, items: newMessageList },
      },
    });
  } catch (error) {
    // logger.log(error);
  }
};

const updateMessageInConversation = (proxy, item) => {
  try {
    const variables = {
      input: {
        mId: item.mId,
      },
      limit: 25,
    };
    updateCache(proxy, item, variables);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default updateMessageInConversation;
