/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';

import Tabs from '@material-ui/core/Tabs';

export const StyledTabs = styled(Tabs)`
  flex-grow: 1;

  .MuiTabs-indicator {
    display: none;
  }
`;
