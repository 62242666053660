/* eslint-disable react/react-in-jsx-scope */
/** @jsxImportSource @emotion/react */
import { memo, useRef, useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import { removeBlock, updateBlock, checkIfDragDisabled } from 'components/editor/utils';
import SelectedElement from 'components/editor/components/selectedElement';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useFileUpload from 'hooks/useFileUpload';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import { menuOptions } from 'components/editor/constants';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { ReactComponent as HourglassIcon } from 'assets/icons/systemicons/hourglass.svg';
import fieldEnums from 'utils/constants/fieldEnums';
import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/photo.svg';
import { ReactComponent as DocIcon } from 'assets/icons/systemicons/text_off.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/systemicons/audio.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_off.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/systemicons/editor/photo_off.svg';
import { ReactComponent as AtomOff } from 'assets/icons/systemicons/editor/atom_off.svg';
import variants from 'utils/instance/variants';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import UploadCaptions from 'components/editor/components/videoBase/components/uploadCaptions';
import { getThumbnailKey } from 'utils/mediaKey';
import notifyChange from 'components/editor/utils/notifyChange';
import Metadata from '../metadata';
import AddMedia from '../addMedia';
import AddThumbnails from '../addThumbnails';
import MediaDropZone from './mediaDropzone';
import Box from '../box';
import { getInputProps, getTextAreaProps, getTitleProps } from './utils';
import UploadProgress from '../uploadProgress';
import DragAndDrop from '../dragAndDrop';
import {
  boxIconStyle,
  IconWrapper,
  Label,
  Title,
  RootWrapper,
  PlaceholderWrapper,
  TitleWrapper,
} from './styled';

const DefaultMediaType = 'video/placeholder';

const icons = {
  video: VideoIcon,
  image: ImageIcon,
  audio: AudioIcon,
  application: DocIcon,
};

const thumbnailCount = 1;

const { setNodes } = Transforms;

const Placeholder = ({ attributes, children, element }) => {
  const [thumbBlob, setThumbBlob] = useState(null);
  const fileRef = useRef();
  const cacheRef = useRef();
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const editor = useSlate();
  const { isAllowed, update, variant, withSignedUrl } = useEditorContext();

  const field = getFieldsForBlock(fieldEnums.CUSTOM_SOURCES, { options: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sourceOptions = useMemo(() => field?.options || [], []);

  const [onChangeCollapse] = useChangeCollapse(element);

  const { data } = element;

  const {
    showThumbnail,
    mTitle,
    mediaType = DefaultMediaType,
    thumbnails,
    collapsed,
    metadata,
    subtitles,
    mId,
    mRefId,
    proxy,
  } = data;

  const [type, subType] = mediaType.split('/');
  const itemType = data?.itemType ?? type;

  const onFileLoad = useCallback(
    (file, fileUri) => {
      setThumbBlob(fileUri);
      fileRef.current = file;
    },
    [setThumbBlob],
  );

  const fileMimeTypes = {
    image: ['.jpg', '.jpeg', '.png'],
    video: ['.mp4'],
    audio: ['.mp3'],
    file: ['.pdf'],
  };

  const getFile = useFileUpload(fileMimeTypes[itemType], onFileLoad, false);
  const memoizedKey = useMemo(() => mId && mRefId && getThumbnailKey(mId, mRefId), [mId, mRefId]);
  const { url: thumbSrc } = useGetSignedUrl(memoizedKey, false);
  const thumbUrl = thumbBlob || thumbSrc;
  const videoSrc = thumbUrl || cacheRef.current;

  useEffect(() => {
    if (!proxy) return;

    cacheRef.current = proxy;
  }, [proxy]);

  const {
    showMetadata,
    title,
    caption,
    photographer,
    isCoverphoto,
    description,
    editor: editors,
    source,
  } = metadata || {};

  const isCmsVariant = variant === variants.CMS;

  const [localThumbnails, setLocalThumbnails] = useState(thumbnails || []);

  const [boxType, setBoxType] = useState('media');

  useEffect(() => {
    if (isCoverphoto) setBoxType('iscoverphoto');
    else setBoxType('media');
  }, [isCoverphoto]);

  const menuItems = useMemo(
    () => [
      {
        title: `${showMetadata ? 'Hide' : 'Show'} Metadata`,
        action: 'show-metadata',
        icon: <AtomOff />,
        divider: true,
      },
      ...menuOptions,
    ],
    [showMetadata],
  );

  const removePlaceholder = useCallback(
    async (event) => {
      event.preventDefault();
      if (withSignedUrl) fileRef.current = null;

      const { showThumbnail: shwThumb, ...placeholder } = element.data;

      await update({
        type: actionTypes.REMOVE_PLACEHOLDER,
        payload: { document: editor.children, placeholder },
      });

      const updatedData = {
        showThumbnail: shwThumb,
        thumbnails: thumbnails || [],
        itemId: uuidv1(),
        metadata: placeholder.metadata,
        subtitles: placeholder.subtitles,
      };

      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData, type }, { at: path });
      notifyChange(editor, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, withSignedUrl],
  );

  const addThumbnails = useCallback(
    async (response, updatedLocalThumbnails) => {
      if (Array.isArray(response)) {
        const updatedData = {
          ...data,
          thumbnails: response,
        };
        if (updatedLocalThumbnails) setLocalThumbnails(updatedLocalThumbnails);

        const path = ReactEditor.findPath(editor, element);
        setNodes(editor, { data: updatedData }, { at: path });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, element],
  );

  const removeThumbnail = useCallback(
    (newThumbnails) => {
      const updatedData = {
        ...data,
        thumbnails: newThumbnails,
      };
      setLocalThumbnails(newThumbnails);

      updateBlock(editor, element, updatedData, update);
    },
    [data, editor, element, update],
  );

  const updateMetadata = useCallback(
    (newValue, metaPropName) => {
      const currentMetadata = metadata || {};
      const updatedData = {
        ...data,
        metadata: {
          ...currentMetadata,
          [metaPropName]: newValue,
        },
      };
      const allAttributes =
        metaPropName === 'isCoverphoto' && newValue === true
          ? { key: 'isCoverphoto', value: false }
          : null;
      updateBlock(editor, element, updatedData, update, undefined, allAttributes);
    },
    [data, editor, element, metadata, update],
  );

  const uploadSubtitle = useCallback(
    async (file) => {
      const result = await update({
        type: actionTypes.ASSET_INSERT,
        payload: { document: editor.children, file },
      });

      const existingSubtitles = subtitles ? [...subtitles] : [];
      const updatedData = {
        ...element.data,
        subtitles: [...existingSubtitles, { ...result, title: file.name }],
      };

      updateBlock(editor, element, updatedData, update);
    },
    [editor, element, subtitles, update],
  );

  const updateSubtitles = useCallback(
    (updatedSubtitles) => {
      const updatedData = {
        ...element.data,
        subtitles: updatedSubtitles,
      };
      updateBlock(editor, element, updatedData, update);
    },
    [editor, element, update],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
      if (action === 'show-metadata') updateMetadata(!showMetadata, 'showMetadata');
    },
    [editor, element, showMetadata, update, updateMetadata],
  );

  const mergeNewThumbnail = useCallback((newThumbnail) => {
    setLocalThumbnails([newThumbnail]);
  }, []);

  const renderContent = useMemo(() => {
    const Icon = icons[type];
    const sourceProps = {
      value: source || [],
      placeholder: 'Start typing to find Source',
      options: sourceOptions,
      propName: 'source',
    };

    const titleProps = getTitleProps(type, title || '');
    const inputProps = getInputProps(type, photographer || '', editors || '');
    const textareaProps = getTextAreaProps(type, caption || '', description || '');

    const isSubtitleAllowed =
      type === 'video' && variant !== variants.INSTAGRAM && variant !== variants.TWITTER;

    return (
      <MediaDropZone
        element={element}
        variant={variant}
        placeholderType={type}
        canAddThumbnail={type === 'video'}
        mergeNewThumbnail={mergeNewThumbnail}
      >
        <Box
          iconComponent={<Icon css={boxIconStyle} />}
          title={`${type} placeholder`}
          subtitle={mTitle}
          menuItems={menuItems}
          onMenuSelect={onMenuSelect}
          type={boxType}
          collapsed={collapsed}
          collapsedContent={mTitle}
          updateCollapsed={onChangeCollapse}
        >
          <AddMedia
            addMedia={getFile}
            removePlaceholder={removePlaceholder}
            mediaWidth={156}
            hasPlaceholder={!thumbUrl}
            mediaThumbnail={thumbUrl}
            mediaSrc={videoSrc}
            mediaType={type}
            mId={mId}
            mRefId={mRefId}
            thumbSrc={thumbUrl}
            withSignedUrl={withSignedUrl}
          />

          {showThumbnail && (
            <div>
              <Label>Add video thumbnail</Label>
              <AddThumbnails
                maxThumbnails={thumbnailCount}
                thumbnails={localThumbnails}
                removeThumbnail={removeThumbnail}
                addThumbnails={addThumbnails}
              />
            </div>
          )}
          {showMetadata && (
            <Metadata
              sourceProps={sourceProps}
              inputProps={inputProps}
              titleProps={titleProps}
              isCmsVariant={isCmsVariant}
              updateMetadata={updateMetadata}
              textareaProps={textareaProps}
              isCoverphoto={isCoverphoto}
            />
          )}
          {isSubtitleAllowed && (
            <UploadCaptions
              updateSubtitles={updateSubtitles}
              subtitles={subtitles}
              uploadSubtitle={uploadSubtitle}
            />
          )}
          {withSignedUrl && thumbBlob && fileRef.current !== null && (
            <UploadProgress fileRef={fileRef} data={data} />
          )}
        </Box>
      </MediaDropZone>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addThumbnails,
    boxType,
    caption,
    collapsed,
    description,
    editors,
    element,
    isCmsVariant,
    isCoverphoto,
    localThumbnails,
    mId,
    mRefId,
    mTitle,
    menuItems,
    mergeNewThumbnail,
    onMenuSelect,
    photographer,
    removePlaceholder,
    removeThumbnail,
    showMetadata,
    showThumbnail,
    source,
    sourceOptions,
    subtitles,
    thumbSrc,
    thumbBlob,
    title,
    type,
    onChangeCollapse,
    updateMetadata,
    updateSubtitles,
    uploadSubtitle,
    variant,
    withSignedUrl,
  ]);

  if (!isAllowed)
    return (
      <SelectedElement element={element}>
        <div {...attributes}>
          {children}

          <RootWrapper contentEditable={false}>
            <PlaceholderWrapper>
              <IconWrapper>
                {subType === 'placeholder' && <HourglassIcon alt="Placeholder" />}
                {subType !== 'placeholder' && type === 'image' && <PhotoIcon alt="image" />}
                {subType !== 'placeholder' && type === 'video' && <VideoIcon alt="video" />}
                {subType !== 'placeholder' && type === 'audio' && <AudioIcon alt="audio" />}
                {subType !== 'placeholder' && type === 'application' && <DocIcon alt="document" />}
              </IconWrapper>
              <TitleWrapper>
                <Title>{mTitle}</Title>
              </TitleWrapper>
            </PlaceholderWrapper>
          </RootWrapper>
        </div>
      </SelectedElement>
    );

  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDisabled={checkIfDragDisabled(variant)}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
};

Placeholder.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Placeholder.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.PLACEHOLDER,
    children: [],
  },
};

export default memo(Placeholder);
