import styled from '@emotion/styled';
import theme from 'theme/theme';
import { listItemLabel, overline } from 'theme/styledTypography';

const transientOptions = {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
};

export const Table = styled('table')`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 0 0 1px ${theme.palette.dina.dividerLight};
  border-radius: 2px;
`;

export const TableHeader = styled('th', transientOptions)`
  ${overline};
  width: ${(props) => props.$width};
  height: 24px;
  text-align: left;
  padding-left: 8px;
  border: 1px solid ${theme.palette.dina.dividerLight};
  background-color: ${theme.palette.dina.blackHoverOverlay};
`;

export const TableData = styled('td', transientOptions)`
  ${listItemLabel};
  width: ${(props) => props.$width};
  font-weight: normal;
  border: 1px solid ${theme.palette.dina.dividerLight};
  height: 40px;
  padding-left: 4px;
  padding-right: 4px;
`;
