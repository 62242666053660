import React, { useContext, useState, useEffect, forwardRef, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { CoverPhotoContext } from 'contexts/CoverPhotoContext';
import UserContext from 'contexts/UserContext';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import memberTypes from 'graphql/memberTypes';
import distanceInWord from 'utils/distanceInWords';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import { UNTITLED_STORY } from 'utils/constants';
import useShareStory from 'hooks/useShareStory';
import useBookmarkStory from 'hooks/useBookmarkStory';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useArchiveStory from 'hooks/useArchiveStory';
import useUnarchiveMember from 'hooks/useUnarchiveMember';
import useLogger from 'utils/useLogger';
import { useSidebar } from 'store';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Popper from 'components/shared/popper';
import { useSetCloseContentTab, useCurrentTabValue } from 'store/tabs';

import Story from './story-view';

const StoryBox = lazy(() => import('screens/storybox'));

export const StoryBoxWrapper = styled('div')`
  width: 327px;
  height: 830px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(0, 0, 0, 0.25),
    0px 12px 24px rgba(0, 0, 0, 0.25);
`;

const {
  PITCH,
  ARCHIVED_STORY,
  ARCHIVED_PITCH,
  RESTRICTED_PITCH,
  ARCHIVED_RESTRICTED_STORY,
  ARCHIVED_RESTRICTED_PITCH,
} = memberTypes;

const archivedTypesSet = new Set([
  ARCHIVED_STORY,
  ARCHIVED_PITCH,
  ARCHIVED_RESTRICTED_STORY,
  ARCHIVED_RESTRICTED_PITCH,
]);

const pitchTypesSet = new Set([PITCH, ARCHIVED_PITCH, RESTRICTED_PITCH, ARCHIVED_RESTRICTED_PITCH]);

const StoryContainer = (
  {
    title,
    updatedAt,
    publishingAt,
    assignedMembers,
    id,
    refId,
    index,
    imgUrl,
    setShowStorybox,
    groupType,
    storyType,
    openStory,
    setSelectedId,
    setPreviewItemId,
    isStorySelected,
    isStoryFocused,
    isRestricted,
    canUpdatePitch,
    canUpdateStory,
    showArchiveButton,
  },
  ref,
) => {
  const isPitch = pitchTypesSet.has(storyType);
  const timeString = distanceInWord(updatedAt);
  const currentTab = useCurrentTabValue();
  const { page: currentPage } = currentTab;

  const [{ rightHidden, rightSelection }] = useSidebar();
  const dragSourceEnabled =
    currentPage === 'rundown' || (!rightHidden && rightSelection === 'contacts');
  const closeContentTab = useSetCloseContentTab();
  const [getAssignedUsers] = useGetAssignedMembers(assignedMembers || []);
  const [assignedUsers] = getAssignedUsers();
  const [assignMemberToStory, shareStoryWith] = useShareStory();
  const [bookmark] = useBookmarkStory();
  const [archive] = useArchiveStory();
  const [unarchive] = useUnarchiveMember();
  const [updatePublishDate] = usePublishDateUpdate();
  const user = useContext(UserContext);
  const { bookmarks, mId: userId } = user;
  const [archived, setArchived] = useState(false);
  const [bookmarked, setBookmarked] = useState(!!bookmarks[id]);
  const [joined, setJoined] = useState(assignedUsers.map((usr) => usr.mId).includes(userId));
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { coverPhoto } = useContext(CoverPhotoContext);
  const [, setCurrentClicked] = useScrollIntoView();
  const logger = useLogger('left area/sidebar/stories/list item/story container');

  useEffect(() => {
    setImageUrl(imgUrl);
  }, [imgUrl]);

  useEffect(() => {
    setArchived(archivedTypesSet.has(storyType));
  }, [storyType]);

  const selected = id === currentTab.id;

  useEffect(() => {
    if (coverPhoto && coverPhoto.mId === id && coverPhoto.url) {
      setImageUrl(coverPhoto.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverPhoto]);

  const addMemberToStory = async (memberId, storyId, memberType) => {
    assignMemberToStory(storyId, memberId, memberType);
  };

  const joinStory = async () => {
    if (joined) {
      try {
        await shareStoryWith(
          id,
          assignedUsers.filter((usr) => usr.mId !== userId),
          assignedUsers,
          memberTypes.USER_STORY,
        );
        setJoined(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        logger.error(error);
      }
    } else {
      try {
        await assignMemberToStory(id, userId, memberTypes.USER_STORY);
        setJoined(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        logger.error(error);
      }
    }
  };

  const archiveStory = () => {
    const story = {
      mId: id,
      mRefId: refId,
      mType: storyType,
      mPublishingAt: publishingAt,
    };
    archive(id, groupType, story);
    closeContentTab({ id });
  };

  const bookmarkStory = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (bookmarks.hasOwnProperty(id)) {
      setBookmarked(false);
      delete bookmarks[id];
    } else {
      setBookmarked(true);
      bookmarks[id] = {
        bookmarkedId: id,
        bookmarkedType: memberTypes.STORY,
      };
    }
    bookmark(userId, bookmarks);
  };

  const onUpdatePublishDate = (newTime) => {
    const publishingDate = new Date(newTime);
    updatePublishDate(id, publishingDate);
  };

  const unarchiveStory = (mType) => {
    unarchive(id, mType);
  };

  const subMenuActionHandler = (label, mType) => {
    switch (label) {
      case 'bookmark':
        bookmarkStory();
        break;
      case 'archive':
        archived ? unarchiveStory(mType) : archiveStory();
        break;
      case 'join':
        joinStory();
        break;
      default:
        break;
    }
  };

  const page = isPitch ? memberTypes.PITCH : memberTypes.STORY;

  const enableUpdate = isPitch ? canUpdatePitch : canUpdateStory;

  const handleOpenStory = () => openStory(title, id, page);
  const handleClose = (e) => {
    setAnchorEl(null);
    setPreviewItemId(null);
    setSelectedId(null);
    setPreviewItemId(null);
    setSelectedId(null);
  };

  const image = imageUrl || fallbackImage;

  const onInstanceClick = async (sId, instanceId) => {
    handleClose();
    await openStory(title, id, page).then(() => {
      setCurrentClicked(instanceId);
    });
  };

  const onSetShowStorybox = () =>
    setShowStorybox({
      id,
      image: imageUrl,
      title,
      page: storyType,
    });

  const handleClick = (target) => {
    const { current } = ref;
    setAnchorEl(current);
    setSelectedId(id);
    setPreviewItemId(id);
  };

  useEffect(() => {
    if (isStorySelected) {
      const { current } = ref;
      setAnchorEl(current);
      setSelectedId(id);
      setPreviewItemId(id);
    } else {
      setAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStorySelected]);

  const handleSameClick = () => {
    setAnchorEl(null);
    setPreviewItemId(null);
    setSelectedId(null);
  };

  const handleDrop = (memberId, storyId, memberType) => {
    addMemberToStory(memberId, storyId, memberType);
  };

  const handleOpenStoryFromStoryBox = () => {
    handleClose();
    handleOpenStory();
  };

  return (
    <>
      <Story
        title={title || UNTITLED_STORY}
        updatedAt={timeString}
        publishingAt={publishingAt}
        image={image}
        id={id}
        anchorEl={anchorEl}
        selected={selected}
        dragSourceEnabled={dragSourceEnabled}
        onDoubleClick={handleOpenStory}
        onClick={handleClick}
        onSameClick={handleSameClick}
        isPitch={isPitch}
        isRestricted={isRestricted}
        handleDrop={handleDrop}
        setShowStorybox={onSetShowStorybox}
        onUpdatePublishDate={onUpdatePublishDate}
        assignedUsers={assignedUsers}
        subMenuActionHandler={subMenuActionHandler}
        groupType={groupType}
        archived={archived}
        bookmarked={bookmarked}
        joined={joined}
        dropTargetType={page}
        showArchiveButton={showArchiveButton}
        isStorySelected={isStorySelected}
        isStoryFocused={isStoryFocused}
        storyType={storyType}
        enableUpdate={enableUpdate}
      />
      <Popper anchorEl={anchorEl} position="right-start">
        <StoryBoxWrapper>
          <Suspense fallback={LoadingIndicator}>
            <StoryBox
              storySpec={{ id, image }}
              dialogHeight="810px"
              onClose={handleClose}
              onOpenStory={handleOpenStoryFromStoryBox}
              onInstanceClick={onInstanceClick}
            />
          </Suspense>
        </StoryBoxWrapper>
      </Popper>
    </>
  );
};

StoryContainer.propTypes = {
  /** Story id */
  id: PropTypes.string.isRequired,
  /** Story title */
  title: PropTypes.string,
  /** Thumbnail Url */
  imgUrl: PropTypes.string,
};

StoryContainer.defaultProps = {
  title: UNTITLED_STORY,
  imgUrl: null,
};
const forwardedStoryContainer = forwardRef(StoryContainer);
export default forwardedStoryContainer;
