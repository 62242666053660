import React from 'react';
import UploadSrc from 'assets/icons/systemicons/upload.svg';
import menuActionTypes from './menuActionTypes';

const menuItems = [
  {
    label: 'Upload...',
    action: menuActionTypes.UPLOAD,
    icon: <img src={UploadSrc} alt="Upload from Computer" />,
  },
];

export default menuItems;
