import { createTheme } from '@material-ui/core/styles';
import colors from './colors';
import typography from './typography';

export default createTheme({
  palette: {
    dina: {
      ...colors,
    },
    background: { default: '#222a35', paper: '#222a35' },
  },
  typography: {
    useNextVariants: true, // Migration, old typ. will be removed in v4.0 Q1 2019.
    dina: {
      ...typography,
    },
  },
});
