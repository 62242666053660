import React, { useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import dndTypes from 'utils/dndTypes';
import useStyles from './member-styles';
import Member from './member-view';

const DraggableMember = (props) => {
  const { handleDrop, id, ...rest } = props;
  const [, dragRef] = useDrag({
    type: dndTypes.MEMBER,
    item: () => props,
    end: (_, monitor) => {
      if (!monitor.didDrop()) return;
      if (monitor.getDropResult().id) handleDrop(id, monitor.getDropResult().id);
    },
  });

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.PITCH],
    drop: (_, monitor) => handleDrop(id, monitor.getItem().id),
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const classes = useStyles({ hovered });

  const attachRef = useCallback(
    (el) => {
      dragRef(el);
      dropRef(el);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className={classes.focusDiv} ref={attachRef}>
      <Member id={id} {...rest} />
    </div>
  );
};

export default DraggableMember;
