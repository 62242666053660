import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar/Avatar';
import getInitials from 'utils/getInitials';
import {
  AvatarText,
  AvatarWrapper,
  ChipLabel,
  ChipWrapper,
  DeleteButton,
  Icon,
} from './chip-styled';

const Chip = (props) => {
  const {
    avatarUrl,
    variant,
    onDelete,
    height,
    width,
    label,
    showAvatar,
    showDeleteButton,
    onChipClick,
    CustomAvatar,
    disabled,
    labelType,
    ...rest
  } = props;

  const initial = getInitials(label);
  const avatar = (
    <Avatar disableScaling src={avatarUrl} size={24} variant={variant}>
      <AvatarText variant={variant}>{initial}</AvatarText>
    </Avatar>
  );

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(event);
  };

  const deleteButton = (
    <DeleteButton onClick={handleDelete} className="deleteButton">
      <Icon />
    </DeleteButton>
  );

  return (
    <ChipWrapper height={height} width={width} onClick={onChipClick} {...rest}>
      {showAvatar && <AvatarWrapper>{CustomAvatar || avatar}</AvatarWrapper>}
      <ChipLabel $showAvatar={showAvatar} $labelType={labelType} $width={width}>
        {label}
      </ChipLabel>
      {showDeleteButton && !disabled && deleteButton}
    </ChipWrapper>
  );
};

Chip.propTypes = {
  /** Avatar image url */
  avatarUrl: PropTypes.string,
  /** Label of the chip */
  label: PropTypes.string,
  /** onDelete click function */
  onDelete: PropTypes.func,
  /** show avatar or not: values- true/false */
  showAvatar: PropTypes.bool,
  /** width of the chip */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** height of the chip */
  height: PropTypes.number,
  /** boolean to show the delete button */
  showDeleteButton: PropTypes.bool,
  /** callback of chip click */
  onChipClick: PropTypes.func,
  /** customAvatar in place of avatar */
  CustomAvatar: PropTypes.element,
  /** Type of label text */
  labelType: PropTypes.string,
};

Chip.defaultProps = {
  avatarUrl: '',
  label: '',
  onDelete: () => {},
  showAvatar: true,
  width: 128,
  height: 32,
  showDeleteButton: true,
  onChipClick: () => {},
  CustomAvatar: null,
  labelType: 'chipLabel',
};

export default Chip;
