const organizationMemberTypes = {
  ORGANIZATION: 'organization',
  ORGANIZATION_DEPARTMENT: 'org_dep',
  ORGANIZATION_USER: 'org_usr',
};

const departmentMemberTypes = {
  DEPARTMENT: 'department',
  DEPARTMENT_STORY: 'dep_str',
  DEPARTMENT_TEAM: 'dep_tea',
  DEPARTMENT_USER: 'dep_usr',
  DEPARTMENT_PITCH: 'dep_pitch',
};

const teamMemberTypes = {
  TEAM: 'team',
  TEAM_STORY: 'tea_str',
  TEAM_USER: 'tea_usr',
  TEAM_PITCH: 'tea_pitch',
};
const userMemberTypes = {
  USER: 'user',
  USER_INSTANCE: 'usr_ins',
  USER_JOB: 'usr_job',
  USER_STORY: 'usr_str',
  USER_BOOKMARK: 'usr_bkm',
  USER_PITCH: 'usr_pitch',
};

const contactMemberTypes = {
  CONTACT: 'contact',
};

const storyMemberTypes = {
  STORY: 'story',
  STORY_JOB: 'str_job',
  STORY_INSTANCE: 'str_ins',
  STORY_CONTACT: 'str_con',
  STORY_DEPARTMENT: 'str_dep',
  STORY_PEOPLE: 'str_peo',
  STORY_TEAM: 'str_tea',
  STORY_ASSET: 'asset',
  ARCHIVED_STORY: 'archived_story',
  RESTRICTED_STORY: 'res_story',
  ARCHIVED_RESTRICTED_STORY: 'archived_res_story',
};

const instanceMemberTypes = {
  INSTANCE: 'instance',
};

const rundownMemberTypes = {
  RUNDOWN_TEMPLATE: 'rundowntemplate',
  RUNDOWN: 'rundown',
  RUNDOWN_USER: 'rnd_usr',
};

const clusterMemberTypes = {
  CLUSTER: 'cluster',
};

const marketMemberTypes = {
  MARKET: 'market',
  MARKET_STORY: 'mar_str',
};

const pitchesMemberTypes = {
  PITCH: 'pitch',
  ARCHIVED_PITCH: 'archived_pitch',
  RESTRICTED_PITCH: 'res_pitch',
  ARCHIVED_RESTRICTED_PITCH: 'archived_res_pitch',
};

const jobMemberTypes = {
  JOB: 'job',
};
const platformMemberTypes = {
  PLATFORM: 'platform',
};
const categoryMemberTypes = {
  CATEGORY: 'category',
};

const editorMemberTypes = {
  CONTENT_TEMPLATE: 'contenttemplate',
};

const archiveTypes = {
  ARCHIVED_INSTANCE: 'archived_instance',
};

const conversationTypes = {
  CONVERSATION: 'convo',
  CONVERSATION_USER: 'con_usr',
  MESSAGE: 'message',
};

const noteMemberTypes = {
  NOTE: 'note',
};

const mapMemberTypes = {
  MARKER: 'marker',
};

const memberTypes = {
  ...organizationMemberTypes,
  ...departmentMemberTypes,
  ...teamMemberTypes,
  ...userMemberTypes,
  ...storyMemberTypes,
  ...instanceMemberTypes,
  ...rundownMemberTypes,
  ...clusterMemberTypes,
  ...marketMemberTypes,
  ...pitchesMemberTypes,
  ...jobMemberTypes,
  ...categoryMemberTypes,
  ...platformMemberTypes,
  ...contactMemberTypes,
  ...editorMemberTypes,
  ...archiveTypes,
  ...conversationTypes,
  ...noteMemberTypes,
  ...mapMemberTypes,
};

export default memberTypes;
