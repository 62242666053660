/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useLeftHidden, useLeftSelection } from 'store/sidebar';

import { StyledDrawer as Drawer } from './leftArea-styled';
import VerticalNavbar, { navbarPosition } from '../verticalNavbar';
import Sidebar from './sidebar';

const LeftArea = ({ hasTitle, isTickerVisible }) => {
  const [leftHidden, setLeftHidden] = useLeftHidden();
  const [leftSelection, setLeftSelection] = useLeftSelection();

  const handleSelectionChanged = useCallback((val) => {
    setLeftSelection(val);
  }, []);

  const toggleLeftHidden = () => {
    setLeftHidden(!leftHidden);
  };

  return (
    <>
      <VerticalNavbar
        toggleSidebar={toggleLeftHidden}
        selectionChanged={handleSelectionChanged}
        hidden={leftHidden}
        selectionType={leftSelection}
        hasTitle={hasTitle}
        position={navbarPosition.LEFT}
      />
      <Drawer
        transitionDuration={{ enter: 200, exit: 200 }}
        variant="persistent"
        anchor="left"
        open={!leftHidden}
        hasTitle={hasTitle}
        $isTickerVisible={isTickerVisible}
      >
        <Sidebar selectionType={leftSelection} />
      </Drawer>
    </>
  );
};

LeftArea.propTypes = {
  /** if the sidebar has a title */
  hasTitle: PropTypes.bool.isRequired,
};

export default LeftArea;
