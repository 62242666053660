import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import isToday from 'date-fns/is_today';

import theme from 'theme/theme';
import memberTypes from 'graphql/memberTypes';

import PitchImageSrc from 'assets/images/PitchBackgroundOverlay.png';
import TileImage from 'assets/images/Tile_DiagonalPattern.png';

import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import Divider from 'components/divider';

const RootTabStyles = css`
  ${theme.typography.dina.storyTab};
  max-width: 192px;
  text-align: left;
  padding: 6px 24px 6px 16px;
`;

const commonDividerStyles = css`
  content: '';
  position: absolute;

  margin: auto 0;
  height: 24px;
  width: 1px;
  background-color: ${theme.palette.dina.dividerLight};
`;

export const StyledTabContentWrapper = styled('div')`
  align-self: flex-start;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}px;
  position: relative;
`;

export const StyledTabIconWrapper = styled('div')`
  margin-right: 10px;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const commonTabHoverAndActiveStyles = css`
  ${StyledTabIconWrapper} {
    svg {
      path {
        fill: ${theme.palette.dina.iconActive};
        fill-opacity: 1;
      }
    }
  }
`;

export const StyledTabWrapper = styled('div')`
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  transform: translate3d(0, 0, 0);
  cursor: ${({ dragging }) => (dragging ? 'grab' : 'pointer')};

  &:last-of-type::after {
    ${commonDividerStyles}
    right: 0;
  }

  &::before {
    ${commonDividerStyles}
    left: 0;
  }

  &:hover {
    ::before {
      display: none;
    }

    ::after {
      display: none;
    }
  }

  &:hover + & {
    ::before {
      display: none;
    }
  }
`;

export const StyledLabel = styled('div')`
  display: flex;
  align-items: center;
  width: calc(100% - 48px);
`;

export const StyledLabelTextWrap = styled('div')`
  &:first-of-type {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
  }

  &:hover {
    color: ${theme.palette.dina.highEmphasis};
  }
`;

export const StyledTab = styled(Tab)`
  ${RootTabStyles}
  margin-top: 4px;
  border-radius: 8px 8px 0 0;
  min-width: 146px;
  width: ${({ width }) => width || 240}px;
  max-width: 240px;
  min-height: 44px;
  height: 44px;
  border: ${({ hovered }) => hovered && `1px solid ${theme.palette.dina.statusWarning}`};

  &:hover {
    background-color: ${theme.palette.dina.whiteHoverOverlay};
    ${commonTabHoverAndActiveStyles};
  }

  &.Mui-selected {
    color: ${theme.palette.dina.highEmphasis};
    ${commonTabHoverAndActiveStyles};

    ${({ type, mType, selectedDate }) => {
      if (type === 'pitch') {
        return `
          background-color: ${theme.palette.dina.primary700};
          background-image: url(${PitchImageSrc});
          background-position-x: 24%;
        `;
      }
      if (type !== 'rundown') {
        return `
          background-color: ${theme.palette.dina.onSelected};
        `;
      }
      if (mType === memberTypes.RUNDOWN_TEMPLATE) {
        return `
          background-color: ${theme.palette.dina.paletteAccentOrange};
        `;
      }
      return isToday(selectedDate)
        ? `
        background-color: ${theme.palette.dina.paletteAccentPurple};
      `
        : `
        background-color: ${theme.palette.dina.paletteAccentPurple};
        background-image: url(${TileImage});
      `;
    }}
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: relative;
  height: 10px;
  width: 10px;
  margin-left: auto;

  .MuiIconButton-label {
    position: absolute;

    svg {
      width: 10px;
      height: 10px;
      path {
        fill: ${theme.palette.dina.iconInactive};
      }
    }
  }

  &:hover {
    svg {
      path {
        fill: ${theme.palette.dina.iconActive};
        fill-opacity: 1;
      }
    }
  }
`;

export const StyledTabDivider = styled(Divider)`
  height: 100%;
`;
