import React from 'react';
import useCreateMessage from 'hooks/useCreateMessage';
import checkIsNotificationConversation from 'utils/messageHub/checkIsNotificationConversation';
import useConversationContext from '../../hooks/useConversationContext';
import MessageContents from './messageContents-view';

const MessageContentsContainer = () => {
  const { currentConversation } = useConversationContext();
  const { mId, convoType } = currentConversation;
  const showInput = !checkIsNotificationConversation(convoType);

  const [createMessage] = useCreateMessage();

  if (!mId) return <div />;

  const handleCreateMessage = async (newMessageContent, mRefId, crudAction) => {
    try {
      await createMessage(mId, newMessageContent, convoType, mRefId, crudAction);
    } catch (e) {
      // console.log(e);
    }
  };

  return <MessageContents createMessage={handleCreateMessage} showInput={showInput} />;
};

MessageContentsContainer.propTypes = {};

MessageContentsContainer.defaultProps = {};

export default MessageContentsContainer;
