import styled from '@emotion/styled/macro';
import MuiListItem from '@material-ui/core/ListItem';
import theme from 'theme/theme';
import { teamInfoType, caption } from 'theme/styledTypography';
import IconButtonComponent from 'components/buttons/iconButton';

export const ListItem = styled(MuiListItem)`
  padding: 0px;
  left: 0;
  background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel2};
  max-height: 72px;
  box-shadow: 72px -1px 0px ${theme.palette.dina.dividerLight};
  :hover {
    background-color: ${theme.palette.dina.storyCardHover};
  }
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${theme.palette.dina.onFocus};
  }
`;

export const ImageWrapper = styled('div')`
  width: 48px;
  height: 48px;
  margin: 12px;
`;

export const Image = styled('img')`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 14px;
  border: ${(props) => {
    if (props.state === 'active') return '2px solid orange';
    if (props.state === 'ready') return '2px solid red';
    return '';
  }};
`;

export const ContentWrapper = styled('div')`
  flex: 1;
`;

export const IconButton = styled(IconButtonComponent)`
  padding: 8px 16px 8px 8px;
`;

export const RundownTitle = styled('p')`
  ${teamInfoType}
  margin:0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const TimingInfoWrapper = styled('div')`
  display: flex;
  margin-top: 3px;
`;

export const TimingInfo = styled('div')`
  max-width: 56px;
  margin-right: 16px;
`;

export const TimingLabel = styled('p')`
  ${caption}
  margin:0;
`;

export const TimingValue = styled('p')`
  ${caption}
  margin:0;
  margin-top: 1px;
  color: ${theme.palette.dina.highEmphasis};
`;
