import React, { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { elementTypes } from 'components/editor/constants';
import { mediaTypes } from 'utils/rundownItemTypes';
import dndTypes from 'utils/dndTypes';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import DropZone from 'components/editor/components/dropZone';
import addGraphics from './utils/addGraphics';
import addClip from './utils/addClip';

const { GRAPHICS, CLIP } = mediaTypes;
const { ASSET } = dndTypes;

const MediaDropZone = ({ children, element, canDropVideo }) => {
  const editor = useSlate();
  const { update } = useEditorContext();

  const onDrop = useCallback(
    ({ type, payload }) => {
      if (type === GRAPHICS) addGraphics(editor, element, payload, update);
      if (type === CLIP || type === ASSET) addClip(editor, element, payload, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const mediaDropZoneAcceptedTypes = useMemo(() => {
    const accept = [ASSET, mediaTypes.GRAPHICS];

    if (
      element.type === elementTypes.PACKAGE ||
      element.type === elementTypes.VOICE_OVER ||
      canDropVideo
    ) {
      accept.push(mediaTypes.CLIP);
    }

    return accept;
  }, [element.type, canDropVideo]);

  return (
    <DropZone accept={mediaDropZoneAcceptedTypes} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

MediaDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

MediaDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(MediaDropZone);
