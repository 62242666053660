import React from 'react';
import { elementTypes } from 'components/editor/constants/types';
import { ReactComponent as CameraIcon } from 'assets/icons/systemicons/editor/Primary_Inline_Camera.svg';
import { ReactComponent as PackageIcon } from 'assets/icons/systemicons/editor/Primary_Inline_VideoClip.svg';
import VoiceOverIcon from 'assets/icons/systemicons/editor/Primary_Inline_VoiceOver.svg';
import { ReactComponent as FullscreenGraphicIcon } from 'assets/icons/systemicons/editor/Primary_Inline_Graphic.svg';
import { ReactComponent as LiveIcon } from 'assets/icons/systemicons/editor/Primary_Inline_Live.svg';
import DveIcon from 'assets/icons/systemicons/editor/Primary_Inline_DVE.svg';
import { ReactComponent as JingleIcon } from 'assets/icons/systemicons/editor/Primary_Inline_Jingle.svg';
import { ReactComponent as BreakIcon } from 'assets/icons/systemicons/editor/Primary_Inline_Break.svg';
import { ReactComponent as TelephoneIcon } from 'assets/icons/systemicons/editor/Primary_Inline_Phone.svg';
import { ReactComponent as AdlibIcon } from 'assets/icons/systemicons/editor/Primary_Inline_AdLib.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/systemicons/editor/Secondary_Audio_Small.svg';
import { ReactComponent as AccessoriesIcon } from 'assets/icons/systemicons/editor/secondary_code_small.svg';

const {
  ACCESSORY,
  ADLIB,
  AUDIO,
  BREAK,
  CAMERA,
  DVE,
  FULLSCREEN_GRAPHICS,
  JINGLE,
  LIVE,
  PACKAGE,
  TELEPHONE,
  VOICE_OVER,
} = elementTypes;

const iconComponents = {
  [ACCESSORY]: AccessoriesIcon,
  [ADLIB]: AdlibIcon,
  [AUDIO]: AudioIcon,
  [BREAK]: BreakIcon,
  [CAMERA]: CameraIcon,
  [DVE]: () => <img src={DveIcon} alt="DveIcon" />,
  [FULLSCREEN_GRAPHICS]: FullscreenGraphicIcon,
  [JINGLE]: JingleIcon,
  [LIVE]: LiveIcon,
  [PACKAGE]: PackageIcon,
  [TELEPHONE]: TelephoneIcon,
  [VOICE_OVER]: () => <img src={VoiceOverIcon} alt="VoiceOverIcon" />,
};

export default iconComponents;
