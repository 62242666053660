/**
 *  Hook for fetching list of platforms
 */
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { startOfDay, endOfDay } from 'date-fns';
import GET_PLATFORMS from 'graphql/queries/getPlatforms';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';

const useGetPlatforms = (date, instanceType, skip) => {
  const [destinations, setDestinations] = useState([]);
  const [platforms, setPlatforms] = useState([]);

  const { data, error, loading } = useQuery(GET_PLATFORMS, {
    skip,
  });
  // logger.log(`date: ${date} instanceType: ${instanceType} Skip: ${skip}`);
  // const sDate = format(date, 'YYYY-MM-DD');
  const startDate = date && startOfDay(date).toISOString();
  const endDate = date && endOfDay(date).toISOString();

  const inp = {
    input: {
      mType: 'rundown',
      startDate,
      endDate,
    },
  };

  const {
    data: rundownsByDateData,
    error: errorrundownsByDate,
    loading: loadingrundownsByDate,
  } = useQuery(GET_RUNDOWNS_BY_PUBLISHING_DATE, {
    variables: inp,
    skip: !data || !date || skip,
  });

  useEffect(() => {
    if (loading || loadingrundownsByDate) {
      return;
    }

    const emptyAccount = {
      accountId: null,
      accountTitle: 'Unassigned',
      accountUrl: 'Unassigned',
      accountLogo: 'url@tolog',
      isUnassigned: true,
      recurrence: {},
    };

    const linear = {
      id: 'linear',
      mTitle: 'current rundowns',
      mProperties: {
        platform: 'linear',
        platformIcon: 'linear',
        accounts: [emptyAccount],
      },
    };

    const allPlatforms = data?.getPlatforms ? [...data.getPlatforms, linear] : [linear];

    if (rundownsByDateData?.getRundownsByPublishingDate) {
      let existAccounts = [];
      const { getRundownsByPublishingDate } = rundownsByDateData;
      existAccounts = getRundownsByPublishingDate.map((rundown) => ({
        accountId: rundown.mId,
        accountTitle: rundown.mTitle,
        accountUrl: rundown.mTitle,
        accountLogo: 'url@tolog',
        recurrence: rundown.recurrence,
      }));

      linear.mProperties.accounts.push(...existAccounts);

      if (instanceType) {
        const platformsByType = allPlatforms.find((p) => p?.mProperties?.platform === instanceType);
        const targets =
          platformsByType?.mProperties?.accounts.map(
            ({ accountId, accountUrl, accountTitle, recurrence }) => {
              const rundown = getRundownsByPublishingDate.find((r) => r.mId === accountId);
              const mPublishingAt = rundown && rundown.mPublishingAt;
              return {
                id: accountId,
                value: instanceType === 'linear' && accountId ? accountId : accountTitle,
                title: accountTitle,
                publishingTime: instanceType === 'linear' && mPublishingAt ? mPublishingAt : null,
                startTime: recurrence ? recurrence.startTime : null,
                timeZone: recurrence ? recurrence.timeZone : null,
              };
            },
          ) || [];
        setDestinations(targets);
      }

      setPlatforms(allPlatforms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rundownsByDateData]);

  return [platforms, error || errorrundownsByDate, loading || loadingrundownsByDate, destinations];
};

export default useGetPlatforms;
