import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: ({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer'),
    opacity: ({ isDisabled }) => (isDisabled ? 0.5 : 1),
    width: 'fit-content',
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: ({ isFailedState }) =>
      theme.palette.dina[isFailedState ? 'kanbanError' : 'statusWarning'],
    marginLeft: '4px',
  },
  icon: {
    marginLeft: '8px',
    marginRight: '4px',
  },
  content: {
    ...theme.typography.dina.button,
    fontWeight: 'normal',
    marginRight: '16px',
  },
  buttonDiv: {
    border: `0.5px solid ${theme.palette.dina.buttonBackgroundOutlined}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    height: 24,
    width: 'fit-content',
    cursor: ({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer'),
    opacity: ({ isDisabled }) => (isDisabled ? 0.5 : 1),
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      '& > :last-child': {
        '& path': {
          transition: '0.2s ease-in',
          fillOpacity: 1,
        },
      },
    },
  },
  checkIcon: {
    marginLeft: '8px',
    marginRight: '4px',
    '& path': {
      fill: theme.palette.dina.kanbanPublished,
      fillOpacity: 1,
    },
  },
  published: {
    ...theme.typography.dina.button,
    fontWeight: 'normal',
    marginRight: '4px',
    color: theme.palette.dina.kanbanPublished,
    '&:hover': {
      color: theme.palette.dina.kanbanPublished,
    },
  },
  arrowIcon: {
    marginTop: '-2px',
  },
}));

export default useStyles;
