import { useCallback, useRef } from 'react';

/**
 * Sets up file upload mechanism from user device
 *
 * @param {String[]} mimeTypes - Array of one or more unique file type specifiers describing file
 * types to allow for upload
 * @param {Function} onLoad - Callback to be invoked on file upload with the file and filePath
 * passed in
 * @returns {Function} - Initiates file upload
 */
const useFileUpload = (mimeTypes, onLoad, multiple = false) => {
  const inputRef = useRef(null);

  const captureFile = useCallback(() => {
    if (inputRef.current) document.body.removeChild(inputRef.current);

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = mimeTypes.join(',');
    input.style.display = 'none';
    if (multiple) input.multiple = true;

    input.onchange = async (event) => {
      const files = [...event.target.files];
      if (multiple) {
        const output = [];
        files.forEach((file) => {
          const filePath = URL.createObjectURL(file);
          output.push({ file, filePath });
        });
        onLoad(output);
      } else if (files[0]) {
        const filePath = URL.createObjectURL(files[0]);
        await onLoad(files[0], filePath);
        // URL.revokeObjectURL(filePath);
      }

      document.body.removeChild(inputRef.current);
      inputRef.current = null;
    };

    inputRef.current = input;
    document.body.appendChild(input);
    inputRef.current.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad]);

  return captureFile;
};

export default useFileUpload;
