import styled from '@emotion/styled';

import theme from 'theme/theme';

export const StyledNotificationIcon = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const StyledChatEnabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: ${theme.palette.dina.iconActive};
        fill-opacity: 1;
      }
    }
  }
`;

export const NotificationIndicatorWrapper = styled.div`
  position: absolute;
  left: 14px;
  bottom: 13px;
  pointer-events: none;
`;

export const MessagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.dina.surfaceCardDark};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
`;

export const StyledPopper = styled.div`
  margin-top: 56px;
  margin-right: 8px;
`;

export const MessageHubInnerRootWrapper = styled('div')`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const StyledBody = styled('div')`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

export const StyledNewMessageContainer = styled('div')`
  height: ${({ height }) => height};
  width: ${({ width }) => `calc(${width}px - 64px)`};
`;
