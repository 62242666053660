/* eslint-disable import/no-extraneous-dependencies */
import uuidv1 from 'uuid/v1';
import findKey from 'lodash/findKey';
import { Transforms, Editor } from 'slate';
import { elementTypes, actionTypes } from 'components/editor/constants/types';

const { primaryTypes, properties } = elementTypes;

/**
 * Inserts primary automation element
 *
 * @param {Object} options
 * @param {Object} options.editor SlateJS editor instance
 * @param {String} options.type Type of the element
 * @param {Function} options.update Callback to be invoked on update
 * @param {Boolean} options.isNewline Should the element be inserted on a newline
 * @param {Object} options.data Data properties for the element
 * @returns {Object} SlateJS editor instance
 */

const defaultData = {
  automationType: 'PRIMARY',
  metaData: [],
  secondaryItems: [],
  templateVariant: 'VARIANT',
};

const { insertNodes, setNodes } = Transforms;

const insertPrimaryAutomationElement = ({ editor, type, update, isNewline = true, data = {} }) => {
  const insertedElement = {
    type,
    data: {
      ...defaultData,
      ...data,
      itemId: uuidv1(),
      typeId: properties[type].id,
      templateType: findKey(primaryTypes, (value) => value === type),
    },
    children: [{ text: '' }],
  };

  if (isNewline) {
    insertNodes(editor, insertedElement);
  } else {
    const [{ text }] = Editor.node(editor, editor.selection);
    if (text !== '') {
      // Transforms.delete(editor, { unit: 'block' });
      insertNodes(editor, insertedElement);
    }
    setNodes(editor, insertedElement);
  }

  update({
    type: actionTypes.AUTOMATION_INSERT,
    payload: { document: editor.children, insertedElement },
  });

  return editor;
};

export default insertPrimaryAutomationElement;
