import styled from '@emotion/styled';
import theme from 'theme/theme';
import typography from 'theme/typography';
import { Typography } from '@material-ui/core';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled('div')`
  display: flex;
  margin-bottom: 8px;
`;

export const AvatarWrapper = styled('div')`
  padding: 16px 16px 8px;
`;

export const TypographyDiv = styled('div')`
  margin-top: 42px;
  height: 52px;
`;

export const Title = styled(Typography)`
  ${typography.avatarHeaderTitle};
`;

export const Subtitle = styled('div')`
  ${typography.avatarHeaderSubtitle};
  margin-top: 8px;
`;

export const ErrorWrapper = styled('div')`
  height: 100px;
  color: ${theme.palette.dina.highEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
