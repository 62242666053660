import gql from 'graphql-tag';

export default gql`
  subscription notifyMemberUpdate($mIdSubscribed: String!) {
    notifyMemberUpdateSubscription(mIdSubscribed: $mIdSubscribed) {
      mId
      mTitle
      mRefId
      mDescription
      mState
      mPublishingAt
      mPublishingEnd
      mType
      crudAction
      mUpdatedAt
      mUpdatedById
      mCreatedAt
      mContentKey
      mPriority
      mContent
      mContentUrl
      mDefaultContentKey
      mThumbnailUrl
      message
      mAvatarUrl
      mAvatarKey
      mStoryId
      mThumbnailKey
      mCategories {
        label
        score
        categoryId
      }
      mSyncProviders {
        provider
        mMetaData {
          key
          value
        }
      }
      mMetaData {
        key
        value
      }
      locked
      items {
        title
        itemId
        templateType
        templateVariant
        subItems {
          itemId
          title
          templateType
          templateVariant
        }
      }
      mRelatedMembers
      mProperties {
        __typename
        ... on PlatformType {
          platform
          id
          provider {
            id
            requestId
            state
            message
            embeddedEndpoint
            previewEndpoint
            updateEndpoint
          }
          account {
            id
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
        ... on NoteProperties {
          pinned
          labelId
        }
      }
      mAssignedMembers {
        mId
        mType
      }
      mTemplateId
      isTemplateInstance
      isRestricted
      mUsers {
        mId
        mTitle
        mAvatarUrl
      }
      command {
        name
        arguments {
          key
          value
        }
      }
    }
  }
`;
