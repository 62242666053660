import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    overflow: 'hidden',
  },
  divider: {
    position: 'relative',
    top: -1,
  },
  headerTextWrapper: {
    height: '40px',
  },
  sidebarHeader: {
    height: '40px',
    minHeight: '40px',
    padding: '0 12px 0 12px',
    paddingBottom: '4px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  sidebarHeaderText: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
    color: theme.palette.dina.mediumEmphasis,
  },
  label: {
    ...theme.typography.dina.caption,
  },
  toggle: {
    color: theme.palette.dina.highEmphasis,
  },
  toggleThumb: {
    color: theme.palette.dina.highEmphasis,
  },
  checked: {},
  track: {},
  primary: {
    '&$checked + $track': {
      backgroundColor: theme.palette.dina.iconSelected,
    },
  },
  datepickerContainer: {
    '& hr': {
      display: 'none',
    },
  },
}));

export default useStyles;
