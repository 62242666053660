import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  bookmarkSchedule: {
    width: 32,
    height: 48,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
  },
  button: {
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.25)',
    },
  },
  hovered: {
    '& path': {
      fill: 'white',
      fillOpacity: 0.54,
    },
  },
  initialOn: {
    '& path': {
      fill: 'white',
      fillOpacity: 0.54,
    },
  },
  initial: {
    '& path': {
      fill: 'white',
      fillOpacity: 0.15,
    },
  },
  selected: {
    '& path': {
      fill: 'white',
      fillOpacity: 1,
    },
  },
}));

export default useStyles;
