import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@material-ui/lab';
import useStyles from './timeline-styles';
import TimelineEntry from '../timelineEntry';

const renderSeparator = (length, index, classes, selected) => (
  <TimelineSeparator className={classes.separator}>
    {index !== 0 && (
      <TimelineConnector
        classes={{ root: `${classes.connectorGap} ${selected === index ? 'selected' : ''}` }}
      />
    )}
    {index === 0 && <span style={{ marginTop: '12px' }} />}
    <TimelineDot classes={{ root: `${classes.dot} ${selected === index ? 'selected' : ''}` }} />
    <TimelineConnector
      classes={{
        root: `${classes.connector} ${selected === index ? 'selected' : ''} ${
          index === length - 1 ? 'lastElement' : ''
        }`,
      }}
    />
  </TimelineSeparator>
);

const TimelineView = ({ items, onClick, selectedItem }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(
    items.findIndex((item) => selectedItem && item.timestamp === selectedItem.timestamp),
  );
  const handleOnClick = (item, index) => {
    setSelected(index);
    onClick(item);
  };
  return (
    <Timeline className={classes.root}>
      {items.map((item, index) => (
        <TimelineItem
          onClick={() => handleOnClick(item, index)}
          classes={{ root: `${classes.timeline} ${selected === index ? 'selected' : ''}` }}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          {renderSeparator(items.length, index, classes, selected)}
          <TimelineContent classes={{ root: classes.timelineContent }}>
            <TimelineEntry item={item} index={index} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

TimelineView.propTypes = {
  /** Timeline items */
  items: PropTypes.arrayOf(PropTypes.object),
  /** Function to run on timeline item clicked */
  onClick: PropTypes.func,
  /** Selected item from the timeline items */
  selectedItem: PropTypes.shape({}),
};

TimelineView.defaultProps = {
  items: [],
  onClick: () => {},
  selectedItem: undefined,
};

export default TimelineView;
