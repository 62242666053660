import React from 'react';
import { Typography } from '@material-ui/core';
import URLS from 'utils/constants/urls';
import useStyles from './footer-styles';

const Footer = (props) => {
  const classes = useStyles();
  const versionNumber = process.env.REACT_APP_VERSION || 'N.NN.N';
  const versionDateUtc = process.env.REACT_APP_VERSION_DATE || new Date().toString();
  const versionDate = new Date(versionDateUtc).toLocaleDateString();
  const { CHANGELOG_URL } = URLS;

  return (
    <div className={classes.root}>
      <div className={classes.footerLines}>
        <Typography className={classes.text}>DiNA by 7Mountains</Typography>
        <a
          className={classes.anchor}
          href={CHANGELOG_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography className={classes.text}>
            Version {versionNumber}, {versionDate}
          </Typography>
        </a>
        <Typography className={classes.text}>
          Copyright © 2018-{new Date().getFullYear()}, 7Mountains AS
        </Typography>
        <Typography className={classes.text}>All rights reserved</Typography>
      </div>
    </div>
  );
};

export default Footer;
