import { makeStyles } from '@material-ui/styles';

const commonContainerStyles = theme => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.dina.surfaceCard,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const useStyles = makeStyles(theme => ({
  container: {
    ...commonContainerStyles(theme),
    borderRadius: '8px',
  },
  iframeContainer: {
    ...commonContainerStyles(theme),
  },
  iframe: {
    border: 'none',
    height: '100%',
    width: '100%',
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

export default useStyles;
