import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  contentValue: {
    ...theme.typography.dina.caption,
    margin: '4px 8px 8px 8px',
  },
  contentHighlightedText: {
    ...theme.typography.dina.caption,
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
