import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  infoRow: {
    marginLeft: ({ isSelfMessage }) => (isSelfMessage ? 0 : '35px'),
    marginBottom: '2px',
    display: 'flex',
    flexDirection: ({ isSelfMessage }) => (isSelfMessage ? 'row-reverse' : 'row'),
    ...theme.typography.dina.caption,
    justifyContent: 'space-between',
  },
  messageInfo: {
    display: 'flex',
    flexDirection: ({ isSelfMessage }) => (isSelfMessage ? 'row' : 'row-reverse'),
    alignItems: 'center',
  },
  actionEventContainer: {
    display: 'flex',
    '& > *': {
      marginLeft: '16px',
    },
  },
  divider: {
    height: '16px',
    margin: '0 8px',
  },
}));

export default useStyles;
