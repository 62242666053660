import styled from '@emotion/styled';

import theme from 'theme/theme';
import colors from 'theme/colors';

export const RootWrapper = styled('div')`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BodyWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

export const SettingsMenuWrapper = styled('div')`
  width: 25%;
  border-right: 1px solid ${colors.dividerLight};
`;

export const SettingsDetailsWrapper = styled('div')`
  width: 75%;
`;

export const ProfileWrapper = styled.div`
  width: 360px;
  border-radius: 8px;
  overflow: hidden;
  margin: -8px 0 -8px;
`;

export const SettingsWrapper = styled.div`
  width: 60vw;
  height: 80vh;
  background-color: ${theme.palette.dina.surfaceCardDark};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
`;
