import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderLeft: `1px solid ${theme.palette.dina.dividerLight}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  years: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 20px',
  },
  year: {
    maxHeight: '24px',
    minHeight: '24px',
    minWidth: '56px',
    maxWidth: '56px',
    margin: '8px 0',
    borderRadius: '12px',
  },
  yearSelected: {
    maxHeight: '24px',
    minHeight: '24px',
    minWidth: '56px',
    maxWidth: '56px',
    margin: '8px 0',
    display: 'flex',
    backgroundColor: theme.palette.dina.onSelected,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: theme.palette.dina.onSelected,
    },
  },
  yearText: {
    ...theme.typography.dina.instanceFooterLabel,
    letterSpacing: '0.25px',
    color: theme.palette.dina.mediumEmphasis,
  },
  yearTextSelected: {
    ...theme.typography.dina.instanceFooterLabel,
    letterSpacing: '0.25px',
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
