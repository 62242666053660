/* eslint-disable no-console */
import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { ReactEditor } from 'slate-react';
import PropTypes from 'prop-types';
import { Paper, Collapse, Fade, Input } from '@material-ui/core';
import Metadata from 'components/metadata';
import ResizableDialog from 'components/resizableDialog';
import getFormBySpec from 'utils/rundown/getFormBySpec';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import variants from 'utils/instance/variants';
import useDidMount from 'hooks/useDidMount';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import { entityTypes, specTypes } from 'utils/metadata/defaultViewTypes';
import returnFieldPairs from 'utils/rundown/returnFieldPairs';
import Editor from 'components/editor';
import { publishingPoints } from 'assets/icons/publishingPoints';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Download from 'components/download/download';
import useSettingsValue from 'hooks/useSettingsValue';
import { useFeedTickerVisible } from 'store';

import Header from './components/header';
import Footer from './components/footer';
import CmsEditing from './components/cmsEditing';
import scrollAfterTimeout from './utils/scrollAfterTimeout';
import toggleViews from './utils/toggleViews';
import useStyles from './instance-card-styles';
import EmbedView from './components/embedView';

const toggleViewConstraint = [
  variants.FACEBOOK,
  variants.YOUTUBE,
  variants.TWITTER,
  variants.INSTAGRAM,
];

const getForcePublishMetadata = (metadata, field) =>
  metadata.find((meta) => meta.key === field.id) || {
    key: field.id,
    value: field.value,
  };

const InstanceCardView = ({
  assignees,
  backgroundImage,
  canShowNewDesign,
  canCreateNewTemplate,
  canDeleteTemplate,
  canDeleteTemplateFolder,
  clipDuration,
  currentDestination,
  placeholderConfigs,
  description,
  disableEdit,
  editorValue,
  folders,
  form,
  handleUpdateMetadata,
  hostReadSpeed,
  hasChanges,
  initiallyCollapsed,
  instance,
  isSavingContent,
  isTimelineHidden,
  loading,
  lockedByUser,
  mId,
  mMetaData,
  publishingDate,
  onAssigneeUpdate,
  onClipDurationChange,
  onCreateDuplicate,
  onCreateFolder,
  onDeleteFolder,
  onDeleteInstance,
  onDeleteTemplate,
  onDescriptionChange,
  onDone,
  onEditorUpdate,
  onForceUnlock,
  onLockInstance,
  onOpenStory,
  onPublishSettingsChange,
  onSaveTemplate,
  onSelectTemplate,
  onStatusChange,
  onTitleUpdate,
  onUnlockInstance,
  publishingPoint,
  publishingPointIcon,
  readLock,
  schedule,
  expiryTime,
  scriptDuration,
  setShowMetadata,
  setSkipDownload,
  shouldResetEditorSelection,
  showMetadata,
  statusId,
  storyId,
  summarize,
  title,
  totalDuration,
  users,
  variant,
  writeLock,
  hideToolbar,
  disableCollapse,
  twitterThreadCount,
  provider,
  versions,
  currentVersionContent,
  refetchVersionList,
  refetchVersionContent,
  onRestoreVersion,
  checkVersionRestorability,
  auditListLoading,
  versionContentLoading,
  platform,
  canShowCmsIframe,
  disableOpenStory,
  editorFontSize,
  showMasterEditView,
  canUpdateInstance,
  updatingRelatedMembers,
  canCreateInstance,
  canUploadMediaBySignedURL,
  relatedMembers,
  showCloseButton,
  onClose,
  showOpenStoryButton,
  hideTemplateOptions,
}) => {
  const containerRef = useRef();
  const [collapsed, setCollapsed] = useState(initiallyCollapsed);
  const [openCmsEditDialog, setOpenCmsEditDialog] = useState(false);
  const [currentClicked, setCurrentClicked] = useScrollIntoView();
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const [toggleView, setToggleView] = useState(toggleViews.EDIT);
  const classes = useStyles({ writeLock, readLock });
  const didMount = useDidMount();
  const [isShowingDownload, setIsShowingDownload] = useState(false);
  const [download, setDownload] = useState({});
  const [descriptionValue, setDescriptionValue] = useState(description);
  const isCMSInstance = useMemo(() => publishingPoint === variants.CMS, [publishingPoint]);
  const isYoutubeInstance = publishingPoint === variants.YOUTUBE;
  const isTwitterInstance = publishingPoint === variants.TWITTER;
  const isFacebookInstance = publishingPoint === variants.FACEBOOK;
  const isInstagramInstance = publishingPoint === variants.INSTAGRAM;
  const [editor, setEditor] = useState();
  const [readOnly, setReadOnly] = useState(!writeLock);
  const [getSettingsValue] = useSettingsValue();
  const applicationTitleFormat = getSettingsValue('app.title');
  const placeholderConfigsRef = useRef(placeholderConfigs);
  const [feedTickerVisible] = useFeedTickerVisible();

  const [fields, parameterFields] = useMemo(
    () =>
      getFormBySpec(form, {
        target: publishingPoint,
        entity: entityTypes.INSTANCE,
        type: specTypes.METADATA,
      }),
    [form, publishingPoint],
  );

  const metadata = useMemo(() => returnFieldPairs(mMetaData, fields), [fields, mMetaData]);

  const forcePublishField = getFieldsForBlock('force-publish', {
    id: 'force-publish',
    value: 'false',
  });

  const forcePublishMetadata = useMemo(
    () => getForcePublishMetadata(mMetaData, forcePublishField),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mMetaData],
  );

  const onSetEditor = useCallback(
    (value) => {
      setEditor(value);
    },
    [setEditor],
  );

  const getPlaceholderConfigs = useCallback(() => placeholderConfigsRef.current, []);

  useEffect(() => {
    placeholderConfigsRef.current = placeholderConfigs;
  }, [placeholderConfigs]);

  useEffect(() => {
    if (didMount) {
      const el = document.getElementById(`${storyId}-${mId}`);
      if (el) {
        if (!collapsed) scrollAfterTimeout(el);

        if (setSkipDownload) setSkipDownload(collapsed);

        if (currentClicked) setCurrentClicked(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  useEffect(() => {
    if (didMount && currentClicked === mId) {
      const target = document.getElementById(`${storyId}-${mId}`);
      if (target) scrollAfterTimeout(target);

      setCollapsed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount, currentClicked]);

  useEffect(() => {
    setDescriptionValue(description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  const editorRef = useRef();
  const timeOutRef = useRef();

  // NOTE: This is not ideal solution. Need to reset
  // editor before changing readonly property. Otherwise
  // the app will crash in case of force unlock.
  const resetSelection = () => {
    if (editor) {
      const { deselect, blur } = ReactEditor;
      deselect(editor);
      blur(editor);
    }
  };

  useEffect(() => {
    const resetTimeOut = () => {
      timeOutRef.current = true;
    };

    resetSelection();
    setReadOnly(!writeLock);

    if (editorRef.current) {
      editorRef.current.addEventListener('keydown', resetTimeOut, false);
      editorRef.current.addEventListener('mousedown', resetTimeOut, false);
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('keydown', resetTimeOut, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        editorRef.current.removeEventListener('mousedown', resetTimeOut, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  useEffect(() => {
    if (writeLock) {
      window.onbeforeunload = async (e) => {
        e.preventDefault();
        await onUnlockInstance();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  const onMetadataSelect = useCallback(
    () => setShowMetadata(!showMetadata),
    [setShowMetadata, showMetadata],
  );

  const updateInstanceMetadata = useCallback(
    (newMetadata) => handleUpdateMetadata(newMetadata, instance), // TODO: fix it
    [handleUpdateMetadata, instance],
  );

  const toggleCollapse = useCallback(() => {
    if (!disableCollapse) setCollapsed((previousState) => !previousState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = useCallback(
    (contentType) => {
      if (!mId || mId.startsWith('-')) return;
      const reqData = { mId, mRefId: mId, contentType, rundownSection: '-' };
      setDownload(reqData);
      setIsShowingDownload(true);
    },
    [mId],
  );

  const handleOnEditorClick = useCallback(() => {
    !readLock && !writeLock && !disableEdit && onLockInstance();
  }, [onLockInstance, readLock, writeLock, disableEdit]);

  const handleDone = useCallback(
    (e) => {
      onDone(e);
      isCMSInstance && onDescriptionChange(descriptionValue);
    },
    [descriptionValue, isCMSInstance, onDescriptionChange, onDone],
  );

  const handleSelectTemplate = useCallback(
    (templateObj) => {
      writeLock && onSelectTemplate(templateObj);
    },
    [onSelectTemplate, writeLock],
  );

  const handleDescriptionChange = useCallback((e) => setDescriptionValue(e.target.value), []);

  const handleBackClick = useCallback(
    () => setShowMetadata(!showMetadata),
    [setShowMetadata, showMetadata],
  );

  const platformStructure =
    platform && platform.mProperties && platform.mProperties.platformStructure;

  const editInExternalTab =
    platformStructure &&
    variant === variants.CMS &&
    platformStructure.variant === 'editInExternalTab';

  const {
    previewEndpoint,
    embeddedEndpoint,
    state: providerState,
    message: providerMessage,
  } = provider || {};

  const onCmsEditingClick = useCallback(() => {
    if (editInExternalTab) {
      window.open(embeddedEndpoint, 235711131719);
    } else {
      setOpenCmsEditDialog(!openCmsEditDialog);
    }
  }, [embeddedEndpoint, editInExternalTab, openCmsEditDialog]);

  const showTwitterEmbed = useMemo(
    () => canShowNewDesign && isTwitterInstance && !!previewEndpoint,
    [canShowNewDesign, isTwitterInstance, previewEndpoint],
  );

  const showYoutubeEmbed = useMemo(
    () => canShowNewDesign && isYoutubeInstance && !!embeddedEndpoint,
    [canShowNewDesign, embeddedEndpoint, isYoutubeInstance],
  );

  const showFacebookEmbed = useMemo(
    () => canShowNewDesign && isFacebookInstance && !!embeddedEndpoint,
    [canShowNewDesign, embeddedEndpoint, isFacebookInstance],
  );

  const showInstagramEmbed = useMemo(
    () => canShowNewDesign && isInstagramInstance && !!embeddedEndpoint,
    [canShowNewDesign, embeddedEndpoint, isInstagramInstance],
  );

  // const shouldShowEditor =
  //   !showTwitterEmbed && !showYoutubeEmbed && !showFacebookEmbed && !showInstagramEmbed;
  const isEmbedPresent = useMemo(
    () => showTwitterEmbed || showYoutubeEmbed || showFacebookEmbed || showInstagramEmbed,
    [showFacebookEmbed, showInstagramEmbed, showTwitterEmbed, showYoutubeEmbed],
  );

  const shouldShowLoading = loading && !showTwitterEmbed && !showYoutubeEmbed;

  const showToggleButton = useMemo(
    () => canShowNewDesign && toggleViewConstraint.includes(variant),
    [canShowNewDesign, variant],
  );

  const isPublished = !!publishingDate;

  const isCmsBlock =
    platformStructure && variant === variants.CMS && platformStructure.variant === 'blocks';

  const masterEditOffset = showMasterEditView ? 80 : 0;
  const applicationTitleHeight = applicationTitleFormat ? 20 : 0;
  const writeReadLockOffset = writeLock || readLock ? 40 : 0;
  const timelineHeight = isTimelineHidden ? 125 : 0;
  const feedTickerHeight = feedTickerVisible ? 40 : 0;
  const heightOffset =
    360 +
    writeReadLockOffset -
    (timelineHeight - applicationTitleHeight - masterEditOffset - feedTickerHeight);
  const height = window.innerHeight - heightOffset;

  const renderedEditor = useMemo(
    () => (
      <Editor
        height={height}
        readOnly={readOnly}
        shouldResetSelection={shouldResetEditorSelection}
        update={onEditorUpdate}
        thumbnail={backgroundImage}
        platformStructure={platformStructure}
        value={editorValue}
        isAllowed={canShowNewDesign || (isCMSInstance && canShowCmsIframe)}
        renderToolbar={hideToolbar ? () => null : undefined}
        onCmsEditing={onCmsEditingClick}
        setEditor={onSetEditor}
        placeholder={variant === variants.CMS ? '' : undefined}
        withSignedUrl={canUploadMediaBySignedURL}
        {...{
          users,
          variant,
          hostReadSpeed,
          isPublished,
          isCmsBlock,
          editorFontSize,
          getPlaceholderConfigs,
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      height,
      readOnly,
      shouldResetEditorSelection,
      backgroundImage,
      editorValue,
      canShowNewDesign,
      canShowCmsIframe,
      hideToolbar,
      onCmsEditingClick,
      onSetEditor,
      users,
      variant,
      hostReadSpeed,
      isPublished,
      platformStructure,
      isCmsBlock,
      editorFontSize,
      getPlaceholderConfigs,
      canUploadMediaBySignedURL,
    ],
  );

  const content =
    toggleView === toggleViews.EDIT ? (
      renderedEditor
    ) : (
      <EmbedView
        {...{
          showTwitterEmbed,
          showYoutubeEmbed,
          showFacebookEmbed,
          showInstagramEmbed,
          provider,
          readLock,
          writeLock,
          height,
        }}
      />
    );

  const showCmsDescriptionInput = useMemo(
    () => isCMSInstance && !canShowNewDesign && !canShowCmsIframe,
    [canShowCmsIframe, canShowNewDesign, isCMSInstance],
  );

  const header = useMemo(
    () => (
      <Header
        instanceId={mId}
        onBackgroundClick={toggleCollapse}
        onDownload={handleDownload}
        onSelectTemplate={handleSelectTemplate}
        {...{
          toggleView,
          setToggleView,
          showToggleButton,
          backgroundImage,
          canCreateNewTemplate,
          canDeleteTemplate,
          canDeleteTemplateFolder,
          containerRef,
          currentDestination,
          embeddedEndpoint,
          folders,
          onCreateDuplicate,
          onCreateFolder,
          onDeleteFolder,
          onDeleteInstance,
          onDeleteTemplate,
          onForceUnlock,
          onMetadataSelect,
          onOpenStory,
          onPublishSettingsChange,
          onSaveTemplate,
          onTitleUpdate,
          previewEndpoint,
          publishingPoint,
          publishingPointIcon,
          schedule,
          expiryTime,
          showMetadata,
          statusId,
          summarize,
          title,
          variant,
          writeLock,
          readLock,
          lockedByUser,
          disableEdit,
          canShowNewDesign,
          canShowCmsIframe,
          versions,
          currentVersionContent,
          refetchVersionList,
          refetchVersionContent,
          onRestoreVersion,
          checkVersionRestorability,
          isSavingContent,
          auditListLoading,
          versionContentLoading,
          onCmsEditingClick,
          isCmsBlock,
          disableOpenStory,
          providerState,
          isEmbedPresent,
          forcePublishMetadata,
          updateInstanceMetadata,
          canCreateInstance,
          canUpdateInstance,
        }}
        showCloseButton={showCloseButton}
        onClose={onClose}
        showOpenStoryButton={showOpenStoryButton}
        hideTemplateOptions={hideTemplateOptions}
      />
    ),
    [
      auditListLoading,
      backgroundImage,
      canCreateNewTemplate,
      canDeleteTemplate,
      canDeleteTemplateFolder,
      canShowCmsIframe,
      canShowNewDesign,
      checkVersionRestorability,
      currentDestination,
      currentVersionContent,
      disableEdit,
      disableOpenStory,
      embeddedEndpoint,
      expiryTime,
      folders,
      forcePublishMetadata,
      handleDownload,
      handleSelectTemplate,
      isCmsBlock,
      isEmbedPresent,
      isSavingContent,
      lockedByUser,
      mId,
      onCmsEditingClick,
      onCreateDuplicate,
      onCreateFolder,
      onDeleteFolder,
      onDeleteInstance,
      onDeleteTemplate,
      onForceUnlock,
      onMetadataSelect,
      onOpenStory,
      onPublishSettingsChange,
      onRestoreVersion,
      onSaveTemplate,
      onTitleUpdate,
      previewEndpoint,
      providerState,
      publishingPoint,
      publishingPointIcon,
      readLock,
      refetchVersionContent,
      refetchVersionList,
      schedule,
      showMetadata,
      showToggleButton,
      statusId,
      summarize,
      title,
      toggleCollapse,
      toggleView,
      updateInstanceMetadata,
      variant,
      versionContentLoading,
      versions,
      writeLock,
      canCreateInstance,
      canUpdateInstance,
      showCloseButton,
      onClose,
      showOpenStoryButton,
      hideTemplateOptions,
    ],
  );

  const footer = useMemo(
    () => (
      <Footer
        {...{
          assignees,
          onAssigneeUpdate,
          collapsed,
          variant,
          statusId,
          onStatusChange,
          onClipDurationChange,
          clipDuration,
          scriptDuration,
          totalDuration,
          isSavingContent,
          onUnlockInstance,
          readLock,
          writeLock,
          lockedByUser,
          publishingPoint,
          disableEdit,
          hasChanges,
          twitterThreadCount,
          canShowNewDesign,
          providerState,
          providerMessage,
          instance,
          canUpdateInstance,
        }}
        relatedMembers={relatedMembers}
        onDone={handleDone}
      />
    ),
    [
      assignees,
      canShowNewDesign,
      clipDuration,
      collapsed,
      disableEdit,
      handleDone,
      hasChanges,
      isSavingContent,
      lockedByUser,
      onAssigneeUpdate,
      onClipDurationChange,
      onStatusChange,
      onUnlockInstance,
      providerMessage,
      providerState,
      publishingPoint,
      readLock,
      scriptDuration,
      statusId,
      totalDuration,
      twitterThreadCount,
      variant,
      writeLock,
      instance,
      canUpdateInstance,
      relatedMembers,
    ],
  );

  return (
    <div className={classes.root} ref={containerRef}>
      {/* {(fixedHeight || !!calculatedHeight) && ( */}
      <Paper elevation={24} classes={{ root: classes.paper }} id={`${storyId}-${mId}`}>
        {(shouldShowLoading || updatingRelatedMembers) && <LoadingIndicator />}
        {header}
        <div ref={editorRef}>
          <Collapse
            in={!collapsed}
            classes={{ container: classes.collapseContainer }}
            timeout={0}
            mountOnEnter
          >
            {showMetadata ? (
              <Fade in={showMetadata} {...(showMetadata ? { timeout: 500 } : {})}>
                <div style={{ height }}>
                  <Metadata
                    fields={fields}
                    metadata={metadata}
                    parameterFields={parameterFields}
                    onUpdateMeta={updateInstanceMetadata}
                    onBackClick={handleBackClick}
                    disableEdit={disableEdit}
                  />
                </div>
              </Fade>
            ) : (
              <div onClick={handleOnEditorClick} role="presentation" className={classes.editorDiv}>
                {showCmsDescriptionInput && (
                  <Input
                    className={classes.description}
                    placeholder="Write a short description..."
                    fullWidth
                    multiline
                    disableUnderline
                    onChange={handleDescriptionChange}
                    value={descriptionValue}
                    rows={3}
                    readOnly={!writeLock || readLock}
                  />
                )}
                {content}
              </div>
            )}
          </Collapse>
        </div>
        {footer}
      </Paper>
      <ResizableDialog open={openCmsEditDialog}>
        <CmsEditing id={mId} title={title} closeDialog={onCmsEditingClick} />
      </ResizableDialog>
      {isShowingDownload ? (
        <Download download={download} setIsShowingDownload={setIsShowingDownload} />
      ) : null}
    </div>
  );
};

InstanceCardView.propTypes = {
  /** mId of the instance */
  mId: PropTypes.string,
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Callback to be invoked on status change */
  onStatusChange: PropTypes.func,
  /** Publishing platform of the instance */
  publishingPoint: PropTypes.oneOf(Object.keys(publishingPoints)),
  /** Currently selected destination of this instance */
  currentDestination: PropTypes.shape({
    title: PropTypes.string,
    publishingTime: PropTypes.string,
  }),
  /** provider information from mProperties */
  provider: PropTypes.shape({
    embeddedEndpoint: PropTypes.string,
    message: PropTypes.string,
    previewEndpoint: PropTypes.string,
    requestId: PropTypes.string,
    state: PropTypes.string,
    updateEndpoint: PropTypes.string,
  }),
  /** Callback to be invoked on publish settings change */
  onPublishSettingsChange: PropTypes.func,
  /** Title of the instance */
  title: PropTypes.string,
  /** Callback to be invoked on title update */
  onTitleUpdate: PropTypes.func,
  /** Publishing time for general instance variant */
  schedule: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Expire time for cms instance variant */
  expiryTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Clip duration for the instance in milliseconds */
  clipDuration: PropTypes.string,
  /** Callback to be invoked on clip duration change */
  onClipDurationChange: PropTypes.func,
  /** Script duration for the instance in milliseconds */
  scriptDuration: PropTypes.string,
  /** Total duration for the instance in milliseconds */
  totalDuration: PropTypes.string,
  /** Initial of the editor */
  editorValue: PropTypes.shape({}),
  /** List of users that can be mentioned on the editor */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mTitle: PropTypes.string,
    }),
  ),
  /** Callback to be invoked when text editor's value changes,
   * with the updated value passed in as JSON. It is invoked
   * after certain duration of actual value changes in the
   * editor.
   */
  onEditorValueChange: PropTypes.func,
  /** Callback to be invoked when text editor's value changes,
   * with the updated value passed in as JSON
   */
  onEditorValueChangeSync: PropTypes.func,
  /** Callback to be invoked when keypress occurs on the editor */
  onEditorKeyDown: PropTypes.func,
  /** Callback to invoked when user clicks away from focused editor */
  onClickAway: PropTypes.func,
  /** Callback to invoked when mos automation item is inserted */
  onEditorAutomationInsert: PropTypes.func,
  /** Callback to invoked when mos automation item is deleted */
  onEditorAutomationRemove: PropTypes.func,
  /** Defines if the card should start collapsed */
  initiallyCollapsed: PropTypes.bool,
  /** Defines if the card should have a predetermined height */
  fixedHeight: PropTypes.number,
  /** Call to be invoked when an asset in inserted in general variants */
  onAssetInsert: PropTypes.func,
  /** Call to be invoked when an asset in removed in general variants */
  onAssetRemove: PropTypes.func,
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked when opening a story */
  onOpenStory: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** boolean to indicate tat user cab only read the value */
  readLock: PropTypes.bool,
  /** Callback to be invoked to lock the instance */
  onLockInstance: PropTypes.func,
  /** Callback to be invoked to unlock the instance */
  onUnlockInstance: PropTypes.func,
  /** Indicated that current instance is locked by others */
  accessLostBy: PropTypes.string,
  /** Boolean that indicates that editor value is saving */
  isSavingContent: PropTypes.bool,
  /** Name of the instance's locking user */
  lockedByUser: PropTypes.string,
  /** Boolean to indicates that user can edit the value */
  writeLock: PropTypes.bool,
  /** callback function to be invoked wen done button is clicked */
  onDone: PropTypes.func,
  /** description of the cms instance */
  description: PropTypes.string,
  /** Callback to invoke when description changes */
  onDescriptionChange: PropTypes.func,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  /** Hides the toolbar of editor */
  hideToolbar: PropTypes.bool,
  /** function to open metadata view */
  setShowMetadata: PropTypes.func,
  /** editorFontSize defined for paragraph: small, medium, large, xLarge */
  editorFontSize: PropTypes.string,
  /** Hides template select options from ellipsis menu */
  hideTemplateOptions: false,
};

InstanceCardView.defaultProps = {
  accessLostBy: null,
  assignees: [],
  clipDuration: undefined,
  currentDestination: {},
  provider: {},
  description: '',
  editorValue: undefined,
  fixedHeight: null,
  folders: [],
  initiallyCollapsed: false,
  isSavingContent: false,
  lockedByUser: 'Someone',
  mId: undefined,
  onAssetInsert: (file, fileName) => {},
  onAssetRemove: (storageKey) => {},
  onAssigneeUpdate: (updatedAssignees) => {},
  onClickAway: (value) => {},
  onClipDurationChange: (newDuration) => {},
  onCreateFolder: () => {},
  onDeleteFolder: () => {},
  onDeleteTemplate: (template) => {},
  onDescriptionChange: () => {},
  onDone: () => {},
  onEditorAutomationInsert: (value) => {},
  onEditorAutomationRemove: (value) => {},
  onEditorKeyDown: (event, value) => {},
  onEditorValueChange: (value) => {},
  onEditorValueChangeSync: (content) => {},
  onLockInstance: () => {},
  onOpenStory: () => {},
  onPublishSettingsChange: (newPublishingSettings) => {},
  onSaveTemplate: (templateName) => {},
  onSelectTemplate: (template) => {},
  onStatusChange: (newStatusId) => {},
  onTitleUpdate: (newTitle) => {},
  onUnlockInstance: () => {},
  publishingPoint: 'linear',
  readLock: false,
  schedule: null,
  expiryTime: undefined,
  scriptDuration: undefined,
  statusId: 'todo',
  title: '',
  totalDuration: undefined,
  users: [],
  variant: variants.LINEAR,
  writeLock: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
  hideToolbar: false,
  setShowMetadata: () => {},
  editorFontSize: '',
  hideTemplateOptions: false,
};

export default InstanceCardView;
