import { mediaTypes } from 'utils/rundownItemTypes';

/**
 * Extracts asset properties from mos xml object
 *
 * @param {Object} xmlDoc Passed mos xml object
 * @returns {Object} mos asset object
 */

const QuerySelector = (xmlDoc) => (selector) => {
  const mosObject = xmlDoc.querySelector(selector);
  return mosObject ? mosObject.textContent : null;
};

const convertIntoMosAsset = (xmlDoc) => {
  const newQuerySelector = QuerySelector(xmlDoc);
  return {
    provider: newQuerySelector('mosID'),
    assetType: mediaTypes.GRAPHICS,
    id: newQuerySelector('objID'),
    self: newQuerySelector('mosExternalMetadata mosPayload element_uri'),
    title: newQuerySelector('objSlug'),
    description: newQuerySelector('description'),
    mediaRecordedOn: newQuerySelector('changed'),
    thumbnailUrl: newQuerySelector('mosExternalMetadata mosPayload payload_uri'),
    proxy: newQuerySelector('mosExternalMetadata mosPayload payload_uri'),
  };
};

export default convertIntoMosAsset;
