import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { checkIfDragDisabled } from 'components/editor/utils';
import { ReactComponent as AbstractIcon } from 'assets/icons/systemicons/editor/description.svg';
import TextArea from 'components/textArea';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import { elementTypes } from 'components/editor/constants/types';
import updateBlock from 'components/editor/utils/updateBlock';
import styled from '@emotion/styled';
import stopEventPropagation from 'utils/stopEventPropagation';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';

const IconComponent = styled(AbstractIcon)`
  margin: 8px;
`;

const TextAreaWrapper = styled('div')`
  margin-bottom: 16px;
`;

const Abstract = ({ attributes, children, element }) => {
  const { data } = element;
  const { collapsed = false } = data;
  const initialValue = (data && data.content) || '';

  const editor = useSlate();

  const [onChangeCollapse] = useChangeCollapse(element);

  const { update, variant } = useEditorContext();

  const [localValue, setLocalValue] = useState(initialValue);

  const updateAbstract = useCallback(
    (newData) => {
      const updatedData = {
        ...data,
        content: newData,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, data],
  );

  const onKeyDown = useCallback(
    (event) => {
      const { key } = event;

      if (key === 'Enter') {
        event.preventDefault();
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          const el = document.activeElement;
          const { selectionStart, selectionEnd, value } = el;
          const preSubString = value.substring(0, selectionStart);
          const postSubString = value.substring(selectionEnd, value.length);
          const newValue = `${preSubString}\n${postSubString}`;
          el.value = newValue;
          el.selectionStart = selectionStart + 1;
          el.selectionEnd = selectionStart + 1;
          setLocalValue(newValue);
        } else {
          updateAbstract(localValue);
        }
      }
      if (key === 'Tab') {
        updateAbstract(localValue);
      }
      if (key === 'Escape') setLocalValue(initialValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValue, localValue],
  );

  const onChange = useCallback((val) => {
    setLocalValue(val);
  }, []);

  const onBlur = useCallback(
    (event) => {
      event.preventDefault();
      updateAbstract(localValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localValue],
  );

  const renderContent = useMemo(
    () => (
      <Box
        title="Description"
        iconComponent={<IconComponent />}
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed}
        collapsedContent={localValue}
        hideEllipsisButton
      >
        <TextAreaWrapper>
          <TextArea
            severity="regular"
            type="SoMe"
            rows={4}
            onMouseDown={stopEventPropagation}
            description={`${localValue.length} characters`}
            value={localValue}
            {...{ onChange, onBlur, onKeyDown }}
          />
        </TextAreaWrapper>
      </Box>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed, localValue, onBlur, onChange, onKeyDown, onChangeCollapse],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDisabled={checkIfDragDisabled(variant)}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
};

Abstract.propTypes = {
  /**
   * Attributes of SlateJS children
   */
  attributes: PropTypes.shape({}),
  /**
   * SlateJS children
   */
  children: PropTypes.node,
  /**
   * SlateJS element
   */
  element: PropTypes.shape({}),
};

Abstract.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { content: '' },
    type: elementTypes.ABSTRACT,
  },
};

export default memo(Abstract);
