/**
 *  Hook for getting members of
 */

import { useQuery } from '@apollo/client';
import { getMembersOfQuery } from 'graphql/queryVariables';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';

const useGetMembersOf = (mId, mType) => {
  const { data, error, loading } = useQuery(GET_MEMBERS_OF, {
    variables: getMembersOfQuery(mId, mType),
    fetchPolicy: 'cache-only',
  });

  return [data, error, loading];
};

export default useGetMembersOf;
