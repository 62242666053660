import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import { Button as StyledButton } from './styled';

const Button = ({ label, icon, onClick, type, onClickAway }) => {
  const renderedLabel = type === 'confirm' ? 'Click again to remove' : label;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <StyledButton type={type} startIcon={icon} onClick={onClick}>
        {renderedLabel}
      </StyledButton>
    </ClickAwayListener>
  );
};

Button.propTypes = {
  /** Label of the button */
  label: PropTypes.string,
  /** Icon React component */
  icon: PropTypes.element,
  /** onClick callback function */
  onClick: PropTypes.func,
  /** type of the button */
  type: PropTypes.string,
  /** click away from button callback */
  onClickAway: PropTypes.func,
};

Button.defaultProps = {
  label: '',
  icon: null,
  onClick: () => {},
  type: 'default',
  onClickAway: () => {},
};

export default memo(Button);
