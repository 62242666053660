import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Tooltip from 'components/tooltip';
import { ListItem, ListItemText } from './listItem-styled';

const ListItemView = ({
  text,
  firstChild,
  iconChild,
  excludeDivider,
  onClick,
  disabled,
  maxHeight,
  borderRadius,
  whiteSpace,
  tooltipTitle,
  ...rest
}) => (
  <Tooltip title={tooltipTitle}>
    <div>
      <ListItem
        disabled={disabled}
        component="label"
        button
        onClick={onClick}
        borderRadius={borderRadius}
        maxHeight={maxHeight}
        {...rest}
      >
        {firstChild}
        <ListItemText whiteSpace={whiteSpace}>{text}</ListItemText>
        {iconChild}
      </ListItem>
      {!excludeDivider && <Divider />}
    </div>
  </Tooltip>
);

ListItemView.propTypes = {
  /** Primary text of the list item */
  text: PropTypes.string.isRequired,
  /** Component that appears on the left side of text */
  firstChild: PropTypes.node,
  /** Icon component that appears on the right side of the text */
  iconChild: PropTypes.node,
  /** Determines if the divider should be excluded */
  excludeDivider: PropTypes.bool,
  /** Returns a callback on Click */
  onClick: PropTypes.func,
  /** maximum height of the list item */
  maxHeight: PropTypes.number,
  /** border radius of the list item */
  borderRadius: PropTypes.number,
  /** determines the whiteSpace property of the listItem text */
  whiteSpace: PropTypes.string,
  /** string to show while hovering mouse on listItem */
  tooltipTitle: PropTypes.string,
};

ListItemView.defaultProps = {
  firstChild: null,
  iconChild: null,
  excludeDivider: false,
  onClick: () => {},
  maxHeight: 48,
  borderRadius: 0,
  whiteSpace: 'normal',
  tooltipTitle: '',
};

export default memo(ListItemView);
