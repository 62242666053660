import React, { memo, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { menuOptions } from 'components/editor/constants';
import { ReactComponent as AtomOff } from 'assets/icons/systemicons/editor/atom_off.svg';
import AddMedia from '../addMedia';
import AddThumbnails from '../addThumbnails';
import Box from '../box';
import Metadata from '../metadata';
import UploadCaptions from './components/uploadCaptions';
import UploadProgress from '../uploadProgress';
import { VideoIcon, ThumbnailWrapper, Label } from './styled';

const MIN_THUMB_COUNT = 1;

const VideoBase = ({
  readOnly,
  uploadVideo,
  removeVideo,
  videoSrc,
  thumbnails,
  addThumbnails,
  removeThumbnail,
  showThumbnail,
  onMenuSelect,
  hideEllipsisButton,
  mediaThumbnail,
  showMetadata,
  sourceProps,
  textareaProps,
  titleProps,
  inputProps,
  element,
  isCmsVariant,
  updateMetadata,
  uploadSubtitle,
  updateSubtitles,
  subtitles,
  isCoverphoto,
  collapsed,
  collapsedContent,
  updateCollapsed,
  isSubtitleAllowed,
  mId,
  mRefId,
  createPlaceholder,
  fileRef,
  cacheRef,
  updateEditorOnUpload,
  withSignedUrl,
}) => {
  const [boxType, setBoxType] = useState('media');
  useEffect(() => {
    if (isCoverphoto) setBoxType('iscoverphoto');
    else setBoxType('media');
  }, [isCoverphoto]);

  const menuItems = useMemo(() => {
    const extendedOptions = [
      {
        title: `${showMetadata ? 'Hide' : 'Show'} Metadata`,
        action: 'show-metadata',
        icon: <AtomOff />,
        divider: true,
      },
    ];
    return [...extendedOptions, ...menuOptions];
  }, [showMetadata]);

  const thumbnailCount = MIN_THUMB_COUNT;
  const thumbnailLabel = 'Add video thumbnail';

  const renderContent = useMemo(
    () => (
      <Box
        type={boxType}
        title="Video"
        menuItems={menuItems}
        hideEllipsisButton={hideEllipsisButton}
        iconComponent={<VideoIcon />}
        onMenuSelect={onMenuSelect}
        readOnly={readOnly}
        element={element}
        collapsed={collapsed}
        collapsedContent={collapsedContent}
        updateCollapsed={updateCollapsed}
      >
        <AddMedia
          element={element}
          addMedia={uploadVideo}
          removeMedia={removeVideo}
          mediaSrc={videoSrc}
          mediaType="video"
          mediaWidth={156}
          mediaThumbnail={mediaThumbnail}
          hasPlaceholder={false}
          mId={mId}
          mRefId={mRefId}
          createPlaceholder={createPlaceholder}
          withSignedUrl={withSignedUrl}
        />
        {showThumbnail && (
          <ThumbnailWrapper>
            <Label>{thumbnailLabel}</Label>
            <AddThumbnails
              maxThumbnails={thumbnailCount}
              thumbnails={thumbnails}
              addThumbnails={addThumbnails}
              removeThumbnail={removeThumbnail}
            />
          </ThumbnailWrapper>
        )}
        {showMetadata && (
          <Metadata
            sourceProps={sourceProps}
            textareaProps={textareaProps}
            isCmsVariant={isCmsVariant}
            titleProps={titleProps}
            inputProps={inputProps}
            updateMetadata={updateMetadata}
            isCoverphoto={isCoverphoto}
          />
        )}
        {isSubtitleAllowed && (
          <UploadCaptions
            uploadSubtitle={uploadSubtitle}
            subtitles={subtitles}
            updateSubtitles={updateSubtitles}
          />
        )}

        {withSignedUrl && fileRef.current && cacheRef.current !== null && (
          <UploadProgress fileRef={fileRef} updateEditorOnUpload={updateEditorOnUpload} />
        )}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addThumbnails,
      boxType,
      collapsed,
      collapsedContent,
      element,
      hideEllipsisButton,
      inputProps,
      isCmsVariant,
      isCoverphoto,
      isSubtitleAllowed,
      mediaThumbnail,
      onMenuSelect,
      mId,
      mRefId,
      createPlaceholder,
      readOnly,
      removeThumbnail,
      removeVideo,
      showMetadata,
      showThumbnail,
      sourceProps,
      subtitles,
      textareaProps,
      thumbnails,
      titleProps,
      updateCollapsed,
      updateMetadata,
      updateSubtitles,
      uploadSubtitle,
      // uploadVideo, //need to use callback
      videoSrc,
      fileRef,
      cacheRef,
      withSignedUrl,
    ],
  );

  return renderContent;
};

VideoBase.propTypes = {
  /** Boolean that indicates a read only component */
  readOnly: PropTypes.bool,
  /** Callback to upload a video */
  uploadVideo: PropTypes.func,
  /** Callback to remove a video */
  removeVideo: PropTypes.func,
  /** Source of the video */
  videoSrc: PropTypes.string,
  /** A list of thumbnails */
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      /** Id of the image as asset */
      mId: PropTypes.string,
      /** unique Id of the image as asset */
      mRefId: PropTypes.string,
      /** mContentKey of the thumbnail image, is used to get the link of the image */
      src: PropTypes.string,
    }),
  ),
  /** Callback to add thumbnail */
  addThumbnails: PropTypes.func,
  /** Callback to remove thumbnail */
  removeThumbnail: PropTypes.func,
  /** Boolean to show thumbnail */
  showThumbnail: PropTypes.bool,
  /** Callback to be invoked when a menu is selected */
  onMenuSelect: PropTypes.func,
  /** Maximum number of allowed thumbnails */
  maxThumbnails: PropTypes.number,
  /** whether the video will be a lead image or not */
  isCoverphoto: PropTypes.bool,
};

VideoBase.defaultProps = {
  readOnly: false,
  uploadVideo: () => {},
  removeVideo: () => {},
  videoSrc: '',
  thumbnails: [],
  addThumbnails: () => {},
  removeThumbnail: () => {},
  showThumbnail: false,
  onMenuSelect: () => {},
  maxThumbnails: 4,
  isCoverphoto: false,
};

export default memo(VideoBase);
