import { makeStyles } from '@material-ui/styles';

const scheduleButton = (theme) => ({
  border: `0.5px solid ${theme.palette.dina.buttonBackgroundOutlined}`,
  backgroundColor: theme.palette.dina.blackHoverOverlay,
  borderRadius: '4px',
  height: 32,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  marginLeft: '4px',
  '&:hover': {
    '& > :first-child': {
      '& path': {
        transition: '0.2s ease-in',
        fillOpacity: 1,
      },
    },
    '& > :last-child': {
      transition: '0.2s ease-in',
      color: theme.palette.dina.highEmphasis,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  scheduleButton: { ...scheduleButton(theme) },
  disabled: {
    pointerEvents: ({ disableScheduleButton }) => (disableScheduleButton ? 'none' : 'auto'),
    opacity: ({ disableScheduleButton }) => (disableScheduleButton ? 0.5 : 1),
  },
  calendarIcon: {
    marginLeft: '8px',
    marginRight: '4px',
  },
  buttonWrapper: {
    display: 'flex',
  },
  schedule: {
    ...theme.typography.dina.button,
    fontWeight: 'normal',
    marginRight: '16px',
    color: theme.palette.dina.mediumEmphasis,
    '&:hover': {
      color: theme.palette.dina.mediumEmphasis,
    },
  },
  backdropRoot: {
    position: 'absolute',
  },
  scheduleButtonDateTime: {
    ...scheduleButton(theme),
    cursor: ({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer'),
    opacity: ({ isDisabled }) => (isDisabled ? 0.5 : 1),
  },
}));

export default useStyles;
