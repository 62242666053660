import React from 'react';
import PropTypes from 'prop-types';
import { RootWrapper, ProfileInfoWrapper } from './profileInfo-styled';
import ProfileDetails from '../profileDetails';

const ProfileInfo = (props) => {
  const {
    firstName,
    surname,
    mDescription,
    email,
    phone,
    readSpeed,
    groups,
    mId,
    teams,
    departments,
  } = props;
  return (
    <RootWrapper>
      <ProfileInfoWrapper>
        <ProfileDetails headerValue="Firstname" textValue={firstName} />
        <ProfileDetails headerValue="Surname" textValue={surname} />
      </ProfileInfoWrapper>
      <ProfileDetails headerValue="Description" textValue={mDescription} />
      <ProfileInfoWrapper>
        <ProfileDetails headerValue="E-mail" textValue={email} />
        <ProfileDetails headerValue="Phone" textValue={phone} />
      </ProfileInfoWrapper>
      <ProfileDetails headerValue="ReadSpeed" textValue={`${readSpeed} wpm`} />
      <ProfileDetails headerValue="Groups" members={groups} showAsChipComponent />
      <ProfileDetails
        headerValue="Teams"
        members={teams}
        variant="Team"
        showAsChipComponent
        showAvatar
      />
      <ProfileDetails
        headerValue="Departments"
        members={departments}
        variant="Department"
        showAsChipComponent
        showAvatar
      />
      <ProfileDetails headerValue="Id" textValue={mId} />
    </RootWrapper>
  );
};

ProfileInfo.propTypes = {
  /** Value of firstName of the user */
  firstName: PropTypes.string,
  /** Value of surname of the user */
  surname: PropTypes.string,
  /** Value of email of the user */
  email: PropTypes.string,
  /** Value of phone number of the user */
  phone: PropTypes.string,
  /** Value of readSpeed of the user */
  readSpeed: PropTypes.string,
  /** Value of description of the user */
  mDescription: PropTypes.string,
  /** Teams assigned to the user */
  teams: PropTypes.arrayOf(PropTypes.any),
  /** Departments assigned to user */
  departments: PropTypes.arrayOf(PropTypes.any),
  /** Value of groups of the user */
  groups: PropTypes.array,
  /** Value of id of the user */
  mId: PropTypes.string,
};

ProfileInfo.defaultProps = {
  firstName: '',
  surname: '',
  email: '',
  phone: '',
  readSpeed: '',
  mDescription: '',
  groups: '',
  mId: '',
  teams: [],
  departments: [],
};

export default ProfileInfo;
