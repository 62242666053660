import styled from '@emotion/styled/macro';
import theme from 'theme/theme';
import ButtonComponent from 'components/buttons/button';
import { ReactComponent as AddIconComponent } from 'assets/icons/systemicons/add.svg';
import { caption } from 'theme/styledTypography';

export const ThumbnailWrapper = styled('div')`
  margin-right: 8px;
`;

export const AddIcon = styled(AddIconComponent)``;

export const ButtonLabel = styled('div')`
  ${caption}
  text-transform: none;
  margin-top: 4px;
`;

export const Button = styled(ButtonComponent)`
  height: 88px;
  width: 88px;
  border-radius: 2px;
  :hover {
    ${AddIcon} {
      path {
        fill: ${theme.palette.dina.highEmphasis};
        fill-opacity: 1;
      }
    }
    ${ButtonLabel} {
      color: ${theme.palette.dina.highEmphasis};
    }
  }
`;

export const ButtonChildWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
