import React, { createContext, useState } from 'react';

export const MembersContext = createContext({});

export function MembersProvider({ children }) {
  const [members, setMembers] = useState({});

  const updateMembersHandler = (data, type) => {
    members[type] = data;
    setMembers({ ...members });
  };

  const getMembers = () => {
    let allMembers = [];
    Object.keys(members).forEach((key) => {
      allMembers = [...allMembers, ...members[key]];
    });

    return allMembers;
  };

  return (
    <MembersContext.Provider value={{ members, updateMembers: updateMembersHandler, getMembers }}>
      {children}
    </MembersContext.Provider>
  );
}
