import React, { useEffect, lazy, Suspense } from 'react';

import useReleaseEditorLock from 'hooks/useReleaseEditorLock';
import { useToggleSidebar } from 'hooks/useToggleSidebar';
import memberTypes from 'graphql/memberTypes';
import { useSidebar } from 'store/sidebar';
import { useCurrentTabValue } from 'store/tabs';
import { useFeedTickerVisible } from 'store';

import LoadingIndicator from 'components/loadingIndicator';
import Thumbnail from './components/header/navbar/scratchPad/components/thumbnail';
import Header from './components/header';
import LeftArea from './components/leftArea';
import RightArea from './components/rightArea';

import { useMainSettings, useExternalData } from './hooks';

import {
  CenterWrapper,
  SidebarWrapper,
  ThumbWrapper,
  Body,
  ContentWrapper,
  TickerWrapper,
} from './main-styled';

const Home = lazy(() => import('screens/home'));
const Feeds = lazy(() => import('screens/feeds'));
const Maps = lazy(() => import('screens/maps'));
const Rundown = lazy(() => import('screens/rundown'));
const Story = lazy(() => import('screens/story'));
const StoryHub = lazy(() => import('screens/planning'));
const FeedTicker = lazy(() => import('features/feedsTicker'));

const Main = () => {
  const currentTab = useCurrentTabValue();
  const [{ leftHidden, rightHidden, rightExpanded }] = useSidebar();
  const [isTickerVisible, setTickerVisible] = useFeedTickerVisible();
  const [externalData] = useExternalData();
  const [appTitle, rightPanelWidth] = useMainSettings();
  const [releaseEditorLock] = useReleaseEditorLock();
  useToggleSidebar();

  const { type, id, mid, image, title, selectedDate } = currentTab;

  useEffect(() => {
    releaseEditorLock(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id, selectedDate]);

  const routes = {
    home: <Home />,
    feeds: <Feeds />,
    maps: <Maps />,
    plans: <StoryHub />,
    create: id && <Story storyId={id} imgUrl={image} />,
    story: id && <Story storyId={id} imgUrl={image} />,
    pitch: id && <Story variant={memberTypes.PITCH} storyId={id} imgUrl={image} />,
    rundown: (
      <Rundown
        rundownId={id}
        mid={mid}
        title={title}
        selectedDate={selectedDate}
        hasTitle={appTitle}
      />
    ),
  };

  return (
    <ContentWrapper>
      <Header appTitle={appTitle} />
      <Body $hasTitle={appTitle} $isTickerVisible={isTickerVisible}>
        <SidebarWrapper>
          <LeftArea hasTitle={appTitle} isTickerVisible={isTickerVisible} />
        </SidebarWrapper>

        <CenterWrapper
          $isRightExpanded={rightExpanded}
          $isLeftHidden={leftHidden}
          $isRightHidden={rightHidden}
          $rightPanelWidth={rightPanelWidth}
        >
          <ThumbWrapper>
            {externalData.length > 0 && <Thumbnail data={externalData} />}
          </ThumbWrapper>
          <Suspense fallback={LoadingIndicator}>{routes[type] ?? routes.home}</Suspense>
        </CenterWrapper>

        <SidebarWrapper>
          <RightArea
            hasTitle={appTitle}
            rightPanelWidth={rightPanelWidth}
            isTickerVisible={isTickerVisible}
          />
        </SidebarWrapper>
      </Body>
      <TickerWrapper>
        {isTickerVisible && (
          <Suspense fallback={LoadingIndicator}>
            <FeedTicker setVisible={setTickerVisible} />
          </Suspense>
        )}
      </TickerWrapper>
    </ContentWrapper>
  );
};

export default Main;
