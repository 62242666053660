/* eslint-disable import/no-extraneous-dependencies */
import differenceBy from 'lodash/differenceBy';
import unionBy from 'lodash/unionBy';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import GET_STORY_CONTACTS from 'graphql/queries/getStoryContacts';
import GET_MEMBERS from 'graphql/queries/getUsers';
import GET_TEAMS from 'graphql/queries/getTeams';
import GET_DEPARTMENTS from 'graphql/queries/getDepartments';
import memberTypes from 'graphql/memberTypes';

import { getMembersOfQuery, getMembersQuery, getMembersOfTypeQuery } from 'graphql/queryVariables';

const getMembersOfType = (proxy, type) => {
  let query = GET_MEMBERS;

  switch (type) {
    case memberTypes.DEPARTMENT:
      query = GET_DEPARTMENTS;
      break;
    case memberTypes.TEAM:
      query = GET_TEAMS;
      break;
    default:
      query = GET_MEMBERS;
      break;
  }

  const members = proxy.readQuery({
    query,
    variables: getMembersOfTypeQuery(type),
  });

  return members.getMembersOftype;
};

const updateParentsOfType = (proxy, storyId, addedMembers, removedMembers, parentType) => {
  const addedMembersIds = addedMembers.map(({ mId }) => mId);

  try {
    const rootQuery = proxy.data.data.ROOT_QUERY;
    // Query to read from cache.
    const queryInCache = `getMembersOf({"input":{"mId":"${storyId}"}})`;
    // If query doesn't exists in cache return.
    if (rootQuery[queryInCache] === undefined) {
      return;
    }
    const list = proxy.readQuery({
      query: GET_MEMBERS_OF,
      variables: getMembersOfQuery(storyId),
    });

    const newMembers = [];
    addedMembersIds.forEach((id) => {
      const member = getMembersOfType(proxy, parentType).find((m) => m.mId === id);
      if (member) {
        member.mType = parentType;
        newMembers.push(member);
      }
    });

    const updatedList = differenceBy(list.getMembersOf, removedMembers, 'mId');

    // Write updated member list in the cache.
    proxy.writeQuery({
      query: GET_MEMBERS_OF,
      variables: getMembersOfQuery(storyId),
      data: {
        getMembersOf: unionBy(updatedList, newMembers, 'mId'),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

const updateContacts = (proxy, storyId, addedContacts, removedContacts) => {
  const contactIds = addedContacts.map(({ mRefId }) => mRefId);

  try {
    const storyContactList = proxy.readQuery({
      query: GET_STORY_CONTACTS,
      variables: getMembersQuery(storyId, memberTypes.STORY_CONTACT),
    });

    const newContacts = [];

    const contacts = getMembersOfType(proxy, memberTypes.CONTACT);

    contactIds.forEach((contactId) => {
      const contact = contacts.find((c) => c.mId === contactId);
      if (contact) {
        contact.mRefId = contact.mId;
        contact.mType = memberTypes.CONTACT;
        newContacts.push(contact);
      }
    });

    const updatedList = differenceBy(storyContactList.getMembers, removedContacts, 'mRefId');

    // Write updated contacts list in the cache.
    proxy.writeQuery({
      query: GET_STORY_CONTACTS,
      variables: getMembersQuery(storyId, memberTypes.STORY_CONTACT),
      data: {
        getMembers: unionBy(updatedList, newContacts, 'mId'),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

const updateStoryMembers = (proxy, storyId, addedMembers, removedMembers, type, parentType) => {
  if (type === memberTypes.STORY_CONTACT) {
    updateContacts(proxy, storyId, addedMembers, removedMembers);
    return;
  }

  updateParentsOfType(proxy, storyId, addedMembers, removedMembers, parentType);
};

export default updateStoryMembers;
