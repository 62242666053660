import React, { useState } from 'react';
import useCheckUserRight from 'hooks/useCheckUserRight';
import Master from './master';
import Detail from './details';
import viewTypes from './viewTypes';

const TeamsView = ({
  containerRef,
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  searchString,
}) => {
  const [viewType, setViewType] = useState(viewTypes.MASTER);
  const [selectedItem, setSelectedItem] = useState({});

  const [checkUserRight] = useCheckUserRight();
  const canCreateTeam = checkUserRight('folder', 'create-teams');
  const canDeleteTeam = checkUserRight('folder', 'delete-teams');

  const showDetail = (item) => {
    setSelectedItem(item);
    setViewType(viewTypes.DETAIL);
  };

  const showMaster = () => {
    setViewType(viewTypes.MASTER);
    setSelectedItem(null);
  };

  return viewType === viewTypes.MASTER ? (
    <Master
      {...{
        showDetail,
        containerRef,
        filterModel,
        updateFilterModel,
        history,
        updateHistory,
        currentSearch,
        updateCurrentSearch,
        searchString,
        canCreateTeam,
      }}
    />
  ) : (
    <Detail onBackButtonClick={showMaster} {...selectedItem} {...{ containerRef, canDeleteTeam }} />
  );
};

export default TeamsView;
