const branchName = process.env.REACT_APP_REPO_BRANCHNAME;

const urls = {
  ABOUT: 'https://7mountains.com/',
  TERMS_CONDITIONS: 'https://fonngroup.com/fonn-group-general-terms-and-conditions/',
  CHANGELOG_URL: branchName?.startsWith('prod')
    ? 'https://sites.google.com/7mountains.com/dina-changelog'
    : 'https://sites.google.com/7mountains.com/dina-changelog-stage',
};

export default urls;
