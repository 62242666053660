import styled from '@emotion/styled';
import theme from 'theme/theme';
import { css } from '@emotion/react';

const CommonButtonStyle = css`
  border: none;
  background: transparent;
  color: ${theme.palette.dina.mediumEmphasis};
  cursor: pointer;

  :hover {
    color: ${theme.palette.dina.highEmphasis};
  }

  :disabled {
    pointer-events: none;
    cursor: auto;
    color: ${theme.palette.dina.mediumEmphasis};
  }
`;

export const GalleryItemContainer = styled('div')`
  padding-top: 8px;
  padding-bottom: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const MetaContainer = styled('div')`
  flex-grow: 1;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const InputWrapper = styled('div')`
  width: 100%;
`;

export const CommonButton = styled('button')`
  ${CommonButtonStyle}
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MoveButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MoveDownButton = styled('button')`
  ${CommonButtonStyle}
  margin-left: 4px;
`;
