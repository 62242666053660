import gql from 'graphql-tag';

/*  Query to fetch stories with pagination. 
   limit: Number of items to fetch.
 nextToken: Token from where next items has to fetch. 
*/
export default gql`
  query GetStories($input: GetMemberInput, $limit: Int, $nextToken: String) {
    getMembersPagination(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
        mTitle
        mDescription
        mContentKey
        mAvatarUrl @client(always: true)
        mThumbnailUrl @client(always: true)
        mCreatedAt
        mUpdatedAt
        mState
        mPublishingAt
        mType
        mMetaData {
          key
          value
        }
        mCategories {
          label
          score
          categoryId
        }
        mAssignedMembers {
          mId
          mType
        }
      }
      nextToken
    }
  }
`;
