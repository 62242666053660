import React from 'react';
import PropTypes from 'prop-types';
import { format as formatDate, isToday, isTomorrow } from 'date-fns';
import CalendarSrc from 'assets/icons/systemicons/calendar_small_on.svg';
import FailedSrc from 'assets/icons/systemicons/status/error.svg';
import useStyles from './datetime-indicator-styles';

// logic for showing published is not there yet, so commented out

/*
const PublishedButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div role="presentation" onClick={onClick} className={classes.buttonDiv}>
      <CheckIcon className={classes.checkIcon} />
      <div className={classes.published}>Published</div>
      <ArrowDownIcon className={classes.arrowIcon} />
    </div>
  );
};
  // const isPublished = isBefore(dateObject, new Date());

  // if (isPublished) return <PublishedButton onClick={onClick} />;

*/

const DatetimeIndicator = ({ datetime, onClick, format, isFailedState, isDisabled }) => {
  const classes = useStyles({ isFailedState, isDisabled });
  const dateObject = new Date(datetime);

  let datetimeToShow = formatDate(new Date(dateObject), format);

  if (isToday(dateObject)) datetimeToShow = `Today / ${formatDate(new Date(dateObject), 'HH:mm')}`;
  if (isTomorrow(dateObject))
    datetimeToShow = `Tomorrow / ${formatDate(new Date(dateObject), 'HH:mm')}`;

  return (
    <div className={classes.root} {...{ onClick }} role="presentation">
      <img src={isFailedState ? FailedSrc : CalendarSrc} alt="Calendar" className={classes.icon} />

      <div className={classes.content}>{datetimeToShow}</div>
    </div>
  );
};

DatetimeIndicator.propTypes = {
  /** Currently selected datetime */
  datetime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Date-fns format string */
  format: PropTypes.string,
  /** Callback to be invoked when indicator is clicked */
  onClick: PropTypes.func,
  /** boolean that indicated a failed state */
  isFailedState: PropTypes.bool,
  /** boolean to indicate that the button is disabled */
  isDisabled: PropTypes.bool,
};

DatetimeIndicator.defaultProps = {
  datetime: new Date(),
  format: 'ddd. D MMM. YYYY',
  onClick: () => {},
  isFailedState: false,
  isDisabled: false,
};

export default DatetimeIndicator;
