import { useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfQuery, getMembersOfTypeQuery } from 'graphql/queryVariables';
import configCtx from 'contexts/configContext';
import { UsersContext } from 'globalState/users';
import { MembersContext } from 'globalState/members';
import { useScheduleResourceContext } from 'contexts/ScheduleResourceContext';
import {
  getPreProcessedScheduleResourceData,
  addTitlesForResources,
  SCHEDULE_RESOURCE_TYPE,
} from 'utils/scheduleResourceHelper';
import { useFeedSources, usePolicies } from 'store';
import GET_MEMBERSOF from '../queries/getMembersOf';
import GET_MEMBERS_FROM_MID from '../queries/getMembersFromId';
import GET_CONFIGS from '../queries/getConfigs';
import GET_METADATA_FORMS from '../queries/getMetadataForms';
import GET_USERS from '../queries/getUsers';
import GET_TEAMS from '../queries/getTeams';
import GET_DEPARTMENTS from '../queries/getDepartments';
import GET_POLICY from '../queries/getPolicy';
import GET_SETTINGS from '../queries/getSettings';
import GET_SCHEDULE_RESOURCES from '../queries/getScheduleResources';
import GET_UNREAD_CONVERSATIONS from '../queries/getUnreadConversations';

import {
  saveAutoTempOnContext,
  savePolicyOnContext,
  saveExtUrlOnContext,
  saveFeedOnContext,
  saveIptcMediaOnContext,
  saveMetaFormOnContext,
  saveSettingsOnContext,
} from './utils';

async function InitialQueries(mId, groups = [], setInitialLoading) {
  const [skip, setSkip] = useState(false);

  const config = useContext(configCtx);
  const { updateMembers } = useContext(MembersContext);
  const { setUsers, setUnreadConversations } = useContext(UsersContext);
  const { setScheduleResources } = useScheduleResourceContext();
  const [, setFeedSources] = useFeedSources();
  const [, setPolicies] = usePolicies();

  useEffect(() => {
    setSkip(true);
  }, []);

  const client = useApolloClient();

  if (skip) return;

  const fetchPolicy = !skip ? 'network-only' : 'cache-only';

  const policyPromise = client.query({
    query: GET_POLICY,
    variables: { input: { mId: 'groupPolicy' } },
    fetchPolicy,
    skip,
  });

  const extUrlPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'externalurl' } },
    fetchPolicy,
    skip,
  });

  const automationPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'automation' } },
    fetchPolicy,
    skip,
  });

  const feedPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'feed' } },
    fetchPolicy,
    skip,
  });

  const metadataPromise = client.query({
    query: GET_METADATA_FORMS,
    variables: { input: { mId: 'form' } },
    fetchPolicy,
    skip,
  });

  const getUserPromise = client.query({
    query: GET_USERS,
    variables: getMembersOfTypeQuery(memberTypes.USER),
    fetchPolicy,
    skip,
  });

  const teamPromise = client.query({
    query: GET_TEAMS,
    variables: getMembersOfTypeQuery(memberTypes.TEAM),
    fetchPolicy,
    skip,
  });

  const deptPromise = client.query({
    query: GET_DEPARTMENTS,
    variables: getMembersOfTypeQuery(memberTypes.DEPARTMENT),
    fetchPolicy,
    skip,
  });

  const settingsPromise = client.query({
    query: GET_SETTINGS,
    variables: {
      generalSettingsInput: { mId: 'settings', mRefId: 'general' },
      userSettingsInput: { mId: 'settings', mRefId: mId },
    },
    fetchPolicy,
    skip,
  });

  const medtopPromise = client.query({
    query: GET_MEMBERS_FROM_MID,
    variables: { input: { mId: 'medtop' } },
    fetchPolicy,
    skip,
  });

  const scheduleResourcesPromise = client.query({
    query: GET_SCHEDULE_RESOURCES,
    variables: { input: { mId: '' } },
    fetchPolicy,
    skip,
  });

  const userPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.USER),
    fetchPolicy,
    skip,
  });

  const teamUserPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.TEAM_USER),
    fetchPolicy,
    skip,
  });

  const deptUserPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.DEPARTMENT_USER),
    fetchPolicy,
    skip,
  });

  const contactPromise = client.query({
    query: GET_USERS,
    variables: getMembersOfTypeQuery(memberTypes.CONTACT),
    fetchPolicy,
    skip,
  });

  const unreadConverationsPromise = client.query({
    query: GET_UNREAD_CONVERSATIONS,
    variables: { input: { mId } },
    fetchPolicy,
    skip,
  });

  const promises = [
    policyPromise,
    extUrlPromise,
    automationPromise,
    feedPromise,
    metadataPromise,
    getUserPromise,
    teamPromise,
    deptPromise,
    contactPromise,
    settingsPromise,
    medtopPromise,
    scheduleResourcesPromise,
    unreadConverationsPromise,
    userPromise,
    teamUserPromise,
    deptUserPromise,
  ];

  const responses = await Promise.allSettled(promises);

  const [
    policyResult,
    extUrlResult,
    automationResult,
    feedResult,
    metadataResult,
    getUserResult,
    teamResult,
    deptResult,
    contactResult,
    settingsResult,
    medtopResult,
    scheduleResourcesResult,
    unreadConverationsResult,
  ] = responses.map((response) => response.value);

  if (
    !policyResult?.loading &&
    !extUrlResult?.loading &&
    !automationResult?.loading &&
    !feedResult?.loading &&
    !metadataResult?.loading &&
    !getUserResult?.loading &&
    !teamResult?.loading &&
    !deptResult?.loading &&
    !settingsResult?.loading &&
    !medtopResult?.loading &&
    !scheduleResourcesResult?.loading &&
    !contactResult?.loading &&
    !unreadConverationsResult?.loading
  ) {
    // Save policies on context

    savePolicyOnContext(groups, policyResult, config);
    const { userRights } = config;
    setPolicies(userRights);

    // save externalUrl config on context

    saveExtUrlOnContext(extUrlResult, config);

    // save automation config on context

    saveAutoTempOnContext(automationResult, config);

    // save feed config on context
    saveFeedOnContext(feedResult, config);
    setFeedSources(feedResult);

    // save metadta config on context
    saveMetaFormOnContext(metadataResult, config);

    // save settings on context

    saveSettingsOnContext(settingsResult, config);

    // save getUser results on context
    const { getMembersOftype } = getUserResult.data;
    const { getUnreadConversations } = unreadConverationsResult.data;
    updateMembers(getMembersOftype, memberTypes.USER);
    setUsers(getMembersOftype);
    setUnreadConversations(getUnreadConversations);
    // save team on context

    updateMembers(teamResult.data.getMembersOftype, memberTypes.TEAM);

    // save department on context

    updateMembers(deptResult.data.getMembersOftype, memberTypes.DEPARTMENT);

    // save external contacts in context
    updateMembers(contactResult.data.getMembersOftype, memberTypes.CONTACT);

    // save iptcMediaTopic config on context
    saveIptcMediaOnContext(medtopResult, config);

    const reducerFn = (prev, curr) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!prev.hasOwnProperty(curr.mId)) {
        // eslint-disable-next-line no-param-reassign
        prev[curr.mId] = [];
      }

      prev[curr.mId].push(curr);
      return prev;
    };

    const reducedScheduleResources = scheduleResourcesResult.data.getScheduleResources.reduce(
      reducerFn,
      {},
    );

    const preprocessedScheduleResources = {};

    Object.keys(reducedScheduleResources).forEach((key) => {
      preprocessedScheduleResources[key] = getPreProcessedScheduleResourceData(
        reducedScheduleResources[key],
      );

      preprocessedScheduleResources[key].sort((itemA, itemB) => {
        if (itemA.mTitle.toLowerCase() > itemB.mTitle.toLowerCase()) {
          return 1;
        }
        return -1;
      });

      if (key === SCHEDULE_RESOURCE_TYPE.category) {
        preprocessedScheduleResources[key].forEach((category) => {
          category.items.sort((itemA, itemB) => {
            if (itemA.title.toLowerCase() > itemB.title.toLowerCase()) {
              return 1;
            }
            return -1;
          });
        });
      }
    });

    const scheduleResources = addTitlesForResources(preprocessedScheduleResources);
    // saving scheduleResources data for the initial load.
    setScheduleResources(scheduleResources);
    setInitialLoading(false);
  }
}

export default InitialQueries;
