import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  savedSearchRoot: {
    width: '320px',
  },
  scrollDiv: {
    minHeight: '0px',
    maxHeight: '700px',
    overflowX: 'hidden',
  },
  header: {
    height: '32px',
    marginLeft: '16px',
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.dina.overline,
  },
  leadingIcon: {
    marginRight: '8px',
  },
  link: {
    ...theme.typography.dina.captionSmallLink,
    letterSpacing: '0.25px',
    marginLeft: '8px',
    textTransform: 'none',
  },
  savedListItem: {
    '&:hover': {
      '& $closeIcon': {
        opacity: 1,
      },
    },
  },
  closeIcon: {
    minWidth: '24px',
    minHeight: '24px',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  pinOnOff: {
    width: '24px',
    height: '24px',
  },
  iconChild: {
    minWidth: '24px',
    minHeight: '24px',
    '& path': {
      fill: theme.palette.dina.highEmphasis,
      fillOpacity: 0.54,
    },
    '&:hover': {
      '& path': {
        fill: theme.palette.dina.highEmphasis,
        fillOpacity: 1,
      },
    },
  },
  deleteIcon: {
    marginRight: '8px',
    '&:hover': {
      '& path': {
        fill: theme.palette.dina.highEmphasis,
        fillOpacity: 1,
      },
    },
  },
}));

export default useStyles;
