import { memo } from 'react';
import styled from '@emotion/styled';
import theme from 'theme/theme';
import ReusableButton from 'components/buttons/button';
import { ReactComponent as PlaceholderIconComponent } from 'assets/icons/systemicons/hourglass.svg';

const ButtonComponent = memo(ReusableButton);

export const MediaWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 96px;
  width: 100%;
  margin-bottom: 10px;
`;

export const ButtonGroup = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const IconWrapper = styled('div')`
  width: ${(props) => `${props.mediaWidth}px`};
  height: 88px;
  margin-right: 8px;
  border: 1px dashed ${theme.palette.dina.statusApproved};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlaceholderIcon = styled(PlaceholderIconComponent)`
  path {
    fill: ${(props) => props.active && theme.palette.dina.statusApproved};
    fill-opacity: ${(props) => props.active && 1};
  }
`;

export const Button = styled(ButtonComponent)`
  height: 88px;
  width: ${(props) => `${props.mediaWidth}px`};
  border-radius: 2px;
  outline: none;
  margin-right: 8px;
  :hover {
    .addIcon {
      path {
        fill: ${theme.palette.dina.highEmphasis};
        fill-opacity: 1;
      }
    }
  }
`;

export const ThumbnailWrapper = styled('div')`
  position: relative;
  border-radius: 4px;
  margin-right: 8px;
  overflow: hidden;
`;
