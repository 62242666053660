import gql from 'graphql-tag';

export default gql`
  mutation UserUtils($input: UserUtilsInput) {
    userUtils(input: $input) {
      mId
      mRefId
    }
  }
`;
