import React, { createContext, useState, useContext, useEffect } from 'react';

import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import NOTIFY_CONVERSATION_SUBSCRIPTION from 'graphql/subscriptions/notifyConversation';
import UserCtx from 'contexts/UserContext';
import createMessage from 'graphql/utils/cache/notification/createMesasge';
import updateCacheWithNotification from 'graphql/utils/cache/notification/updateCacheWithNotification';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import useApolloSubscription from 'hooks/useApolloSubscription';
import conversationTypes from 'utils/constants/conversationTypes';
import { UsersContext } from 'globalState/users';
import shouldIgnoreSetNotification from 'utils/notificationHelper/setNotificationLogic';
import extractNotification from 'utils/notificationHelper/notificationUtils';
import processNotificationPayload from 'utils/notificationHelper/payloadUtils';
import { AuthContext } from 'contexts/AuthContext';
import { useLeftSelection, useSidebarDatePickerAtom } from 'store';

export const NotificationContext = createContext();

const NotificationStore = ({ children }) => {
  const { mId } = useContext(UserCtx);
  const { unreadConversations } = useContext(UsersContext);
  const [selectedDates] = useSidebarDatePickerAtom();
  const authCtx = useContext(AuthContext);
  const [updateCache] = useUpdateLeftSidebarCache();
  const [leftSelection] = useLeftSelection();

  const [notification, setNotification] = useState([{ message: '' }]);
  const [notificationMessage, setNotificationMessage] = useState([...unreadConversations]);

  const [unscheduledItem, setUnscheduledItem] = useState(null);

  const executeCommand = (command, data) => {
    const { name: commandName } = command;

    if (commandName === 'logout') {
      authCtx.logout();
    }
  };

  const notificationData = ({ client, subscriptionData }) => {
    const notifyUser = subscriptionData.data.notifyMemberUpdateSubscription;
    const { command } = notifyUser;
    if (command) {
      executeCommand(command, notifyUser);
      return;
    }

    getMessageAndUpdateCache(client, notifyUser);
  };

  const chatNotificationData = ({ client, subscriptionData }) => {
    const notifyUser = subscriptionData.data.notifyConversationSubscription;
    getMessageAndUpdateCache(client, notifyUser);
  };

  const getMessageAndUpdateCache = (client, payload) => {
    const { mUpdatedById } = payload;
    if (payload.mId === 'messageAll' && mUpdatedById === mId) return;
    const updatedBy = client.cache.data.data[mUpdatedById];
    // eslint-disable-next-line no-param-reassign
    payload.message = processNotificationPayload(payload, mId, selectedDates);
    // Update cache before sending notification message.
    updateCacheWithNotification(client, payload, updateCache, leftSelection, setUnscheduledItem);
    if (shouldIgnoreSetNotification(payload)) return;
    const newNotificationMessage = createMessage(payload);
    // eslint-disable-next-line max-len
    if (newNotificationMessage)
      setNotification(
        extractNotification(newNotificationMessage, mUpdatedById, updatedBy, payload),
      );
  };

  const [subscribe, unsubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: { mIdSubscribed: mId },
    onSubscriptionData: notificationData,
  });

  const [subscribeConvo, unsubscribeConvo] = useApolloSubscription(
    NOTIFY_CONVERSATION_SUBSCRIPTION,
    {
      variables: { mIdSubscribed: mId },
      onSubscriptionData: chatNotificationData,
    },
  );

  const [subscribeAllConvo, unsubscribeAllConvo] = useApolloSubscription(
    NOTIFY_CONVERSATION_SUBSCRIPTION,
    {
      variables: { mIdSubscribed: conversationTypes.ALL },
      onSubscriptionData: chatNotificationData,
    },
  );

  useEffect(() => {
    subscribe();
    subscribeConvo();
    subscribeAllConvo();
    return () => {
      unsubscribe();
      unsubscribeConvo();
      unsubscribeAllConvo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!notification.message) return;
    if (!notification.message.length || notification.message.length === 0) return;

    if (notification.mType !== 'convo' && notification.mType !== 'message') {
      notification.mId = `${mId}_notifications`;
    }
    setNotificationMessage(notificationMessage.concat(notification));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);
  // eslint-disable-next-line no-console
  return (
    <NotificationContext.Provider
      value={{ notificationMessage, unscheduledItem, setUnscheduledItem }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
export default NotificationStore;
