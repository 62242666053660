import React from 'react';
import { useQuery } from '@apollo/client';
import GET_USERS from 'graphql/queries/getUsers';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import useShareStory from 'hooks/useShareStory';
import Scrollbar from 'screens/main/components/scrollbar';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import List from '../../list-view';

const ContactListContainer = ({ type, ...rest }) => {
  const { data, error, loading } = useQuery(GET_USERS, {
    variables: getMembersOfTypeQuery(type),
  });

  const [assignMemberToStory] = useShareStory();

  if (error) {
    return `Error! ${error.message}`;
  }

  if (data) {
    const addUserToStory = async (userId, storyId) => {
      assignMemberToStory(storyId, userId, type);
    };

    const contacts = data.getMembersOftype;

    return (
      <Scrollbar>
        <List
          type={type}
          members={contacts}
          dropHandler={(userId, storyId) => addUserToStory(userId, storyId)}
          {...rest}
        />
      </Scrollbar>
    );
  }
  if (loading) {
    return (
      // it will be 600px down from the top
      <div style={{ marginTop: '600px' }}>
        <LoadingIndicator />
      </div>
    );
  }

  return null;
};

export default ContactListContainer;
