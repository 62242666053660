import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListSubheader, List } from '@material-ui/core';
import EllipsisButton from 'components/buttons/ellipsisButton';
import Popover from 'components/popover';
import RightMetadataOffSrc from 'assets/icons/systemicons/right_metadata_off.svg';
import PadlockSrc from 'assets/icons/systemicons/padlock_off.svg';
// import ShortTextSrc from 'assets/icons/systemic ons/short_text.svg';
import Delete from 'assets/icons/systemicons/delete.svg';
import History from 'assets/icons/systemicons/time.svg';
import Divider from 'components/divider';
import InstanceVersionHistory from 'components/instanceVersionHistory';
import FoldersMenuList from '../foldersMenuList';
import MenuItem from '../menuItem';
import DeleteInstance from '../linearEllipsisMenu/components/deleteInstance';
import useStyles from './general-ellipsis-menu-styles';

const topRight = {
  vertical: 'top',
  horizontal: 'right',
};

const centerLeft = {
  vertical: 'center',
  horizontal: 'left',
};

const GeneralEllipsisMenu = ({
  isDeleteEnabled,
  onDeleteInstance,
  onForceUnlock,
  onMetadataSelect,
  showMetadata,
  summarize,
  writeLock,
  lockedByUser,
  disableEdit,
  title,
  instanceId,
  versions,
  currentVersionContent,
  refetchVersionList,
  refetchVersionContent,
  onRestoreVersion,
  checkVersionRestorability,
  isSavingContent,
  auditListLoading,
  versionContentLoading,
  canDeleteInstance,
  canUpdateInstance,
  folders,
  canCreateNewTemplate,
  canDeleteTemplateFolder,
  canDeleteTemplate,
  onDeleteFolder,
  onSaveTemplate,
  onSelectTemplate,
  onDeleteTemplate,
  onCreateFolder,
  hideTemplateOptions,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [anchor, setAnchor] = useState(null);
  const [popoverComponent, setPopoverComponent] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

  const handleOpenPopover = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchor(null);
    setPopoverComponent(null);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const handleMetadataClick = () => {
    closeMenu();
    onMetadataSelect();
  };
  const handleForceUnlock = () => {
    closeMenu();
    onForceUnlock();
  };

  const handleDelete = () => {
    handleClosePopover();
    closeMenu();
    onDeleteInstance();
  };

  const handleHistory = async () => {
    refetchVersionList();
    setHistoryDialogOpen(true);
  };

  const handleRestoreVersion = async (content) => {
    await onRestoreVersion(content);
    setHistoryDialogOpen(false);
    setAnchorEl(null);
  };

  const OpenDelete = () => {
    setAnchor(anchorEl);
    setPopoverComponent(
      <DeleteInstance
        onCancel={handleClosePopover}
        onOk={handleDelete}
        isDeleteEnabled={isDeleteEnabled}
      />,
    );
  };

  // const handleSummarize = () => {
  //   closeMenu();
  //   summarize();
  // };

  const disableEditOrFalse = disableEdit || false;

  const moreOptions = [
    {
      src: History,
      label: 'History',
      callback: handleHistory,
      disabled: !canUpdateInstance,
    },
    {
      src: PadlockSrc,
      label: 'Force unlock',
      callback: handleForceUnlock,
      disabled: disableEditOrFalse,
    },
    {
      src: Delete,
      label: 'Delete Instance',
      callback: OpenDelete,
      disabled: disableEditOrFalse || !canDeleteInstance,
    },
    // {
    //   src: ShortTextSrc,
    //   label: 'Summarize',
    //   callback: handleSummarize,
    //   disabled: !writeLock,
    // },
  ];

  const MoreOptionsMenuList = () => (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>MORE OPTIONS</ListSubheader>

      {moreOptions.map(({ src, label, callback, disabled }) => (
        <MenuItem
          key={label}
          image={src}
          label={label}
          onClick={callback}
          showSecondaryItem={false}
          disabled={disabled}
        />
      ))}
      <Popover
        anchorEl={anchor}
        anchorOrigin={centerLeft}
        transformOrigin={topRight}
        onClose={handleClosePopover}
      >
        <div className={classes.popover}>{popoverComponent}</div>
      </Popover>
    </List>
  );

  const GeneralMenuList = () => (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>GENERAL</ListSubheader>
      <MenuItem
        label="Metadata"
        image={RightMetadataOffSrc}
        onClick={handleMetadataClick}
        disabled={showMetadata}
      />
    </List>
  );

  return (
    <div>
      {historyDialogOpen && (
        <InstanceVersionHistory
          versions={versions}
          getSpecificVersionContent={refetchVersionContent}
          content={currentVersionContent}
          title={title}
          id={instanceId}
          open={historyDialogOpen}
          onCancel={() => setHistoryDialogOpen(false)}
          onOk={handleRestoreVersion}
          checkVersionRestorability={checkVersionRestorability}
          lockedByUser={lockedByUser}
          isSavingContent={isSavingContent}
          auditListLoading={auditListLoading}
          versionContentLoading={versionContentLoading}
        />
      )}
      <div className={classes.container}>
        <EllipsisButton onClick={handleOpenPopover} />
        <Popover
          anchorEl={anchorEl}
          onClose={closeMenu}
          anchorOrigin={topRight}
          transformOrigin={topRight}
        >
          <div className={classes.menu}>
            <GeneralMenuList />
            {!hideTemplateOptions && (
              <>
                <Divider className={classes.divider} />
                <FoldersMenuList
                  {...{
                    anchorEl,
                    folders,
                    canCreateNewTemplate,
                    canDeleteTemplateFolder,
                    canDeleteTemplate,
                    writeLock,
                    onDeleteFolder,
                    closeMenu,
                    onSaveTemplate,
                    onSelectTemplate,
                    onDeleteTemplate,
                    onCreateFolder,
                  }}
                />
              </>
            )}
            <>
              <Divider className={classes.divider} />
              <MoreOptionsMenuList />
            </>
          </div>
        </Popover>
      </div>
    </div>
  );
};

GeneralEllipsisMenu.propTypes = {
  /** Reference to the container element */
  containerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  /** Callback to be invoked when metadata option is clicked */
  onMetadataSelect: PropTypes.func,
  /** callback to forcefully unlock the instance */
  onForceUnlock: PropTypes.func,
  /** Boolean that disables edit */
  disableEdit: PropTypes.bool,
};

GeneralEllipsisMenu.defaultProps = {
  containerRef: { current: null },
  onMetadataSelect: () => {},
  onForceUnlock: () => {},
  disableEdit: false,
};

export default GeneralEllipsisMenu;
