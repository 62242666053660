import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useStorageImage from 'hooks/useStorageImage';
import close from 'assets/icons/systemicons/close_small.svg';
import { ThumbnailWrapper, Image, IconButton, CloseIcon } from './styled';

const Thumbnail = ({ onThumbnailRemoveClick, thumbnail, index }) => {
  const { src, filePath, thumbnailUrl, proxy } = thumbnail;
  const { data } = useStorageImage(src);
  return (
    <ThumbnailWrapper>
      <Image src={filePath || thumbnailUrl || data || proxy} alt="thumbnail" />
      <IconButton onClick={() => onThumbnailRemoveClick(index)}>
        <CloseIcon src={close} alt="close" />
      </IconButton>
    </ThumbnailWrapper>
  );
};

Thumbnail.propTypes = {
  /** Callback to be invoked when remove button is clicked */
  onThumbnailRemoveClick: PropTypes.func,
  /** Information about the thumbnail */
  thumbnail: PropTypes.shape({
    /** Id of the image as asset */
    mId: PropTypes.string,
    /** unique Id of the image as asset */
    mRefId: PropTypes.string,
    /** url for recently uploaded thumbnail */
    filePath: PropTypes.string,
    /** mContentKey of the thumbnail image, is used to get the link of the image */
    src: PropTypes.string,
  }),
  /** index of the thumbnail on array */
  index: PropTypes.number.isRequired,
};

Thumbnail.defaultProps = {
  onThumbnailRemoveClick: () => {},
  thumbnail: {},
};

export default memo(Thumbnail);
