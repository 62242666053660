import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextAreaComponent from 'components/textArea';
import useInputEvents from 'hooks/useInputEvents';

const TextArea = memo(TextAreaComponent);

const Content = ({ content, updateContent }) => {
  const initialContentValue = content;
  const [contentValue, setContentValue] = useState(initialContentValue);

  const handleContentChange = useCallback((newValue) => {
    setContentValue(newValue);
  }, []);

  const onUpdateContent = useCallback(
    (newValue) => {
      const trimmedValue = newValue.trim();
      if (trimmedValue === initialContentValue) setContentValue(initialContentValue);
      else updateContent(trimmedValue);
    },
    [initialContentValue, updateContent],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(
    onUpdateContent,
    contentValue,
    initialContentValue,
  );

  return (
    <TextArea
      disableLabel
      severity="regular"
      type="SoMe"
      rows={3}
      inputRef={inputRef}
      placeholder="Article title, will be used by default as notification/push message"
      value={contentValue}
      onChange={handleContentChange}
      description="Message to send to selected destinations on publish time."
      onBlur={handleBlur}
      onKeyDown={handleKeydown}
    />
  );
};

Content.propTypes = {
  /** content of message */
  content: PropTypes.string,
  /** callback to update content */
  updateContent: PropTypes.func,
};

Content.defaultProps = {
  content: '',
  updateContent: () => {},
};

export default memo(Content);
