import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '55px',
    background: theme.palette.dina.surfaceCard,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  buttonContainer: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '1px',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    height: '40px',
    width: '192px',
    margin: '8px 8px 8px 0',
    borderRadius: '4px',
  },
  okButton: {
    backgroundColor: theme.palette.dina.statusWarning,
    '&.confirmed': {
      backgroundColor: theme.palette.dina.statusError,
    },
    '&:disabled': {
      opacity: '0.35',
      color: theme.palette.dina.disabled,
    },
    '&:hover': {
      backgroundColor: theme.palette.dina.statusWarning,
    },
    '&:hover.confirmed': {
      backgroundColor: theme.palette.dina.statusError,
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
  },
  okLabel: {
    ...theme.typography.dina.buttonMedium,
    color: theme.palette.dina.blackHighEmphasis,
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.dina.blackHighEmphasis,
    },
    '&.confirmed': {
      color: theme.palette.dina.highEmphasis,
    },
    '&:hover.confirmed': {
      color: theme.palette.dina.highEmphasis,
    },
  },
  cancelLabel: {
    ...theme.typography.dina.button,
    color: theme.palette.dina.mediumEmphasis,
    textTransform: 'none',
    fontWeight: 'normal',
  },
}));

export default useStyles;
