import React from 'react';
import PropTypes from 'prop-types';
import Editor from 'components/editor';
import editorVariants from 'components/editor/constants/types/editorVariants';
import useStyles from './detailComponent-styles';

const DetailComponent = ({ contentValue, contentHighlightedText, variant }) => {
  const classes = useStyles();
  if (variant === 'message') {
    return (
      <Editor
        placeholder=""
        renderToolbar={() => null}
        variant={editorVariants.MESSAGE}
        isFixedHeightEditor={false}
        background="transparent"
        height="100%"
        readOnly
        padding={0}
        value={JSON.parse(contentValue)}
      />
    );
  }
  return (
    <div className={classes.contentValue}>
      {contentValue}
      <span className={classes.contentHighlightedText}>{contentHighlightedText}</span>
    </div>
  );
};

DetailComponent.propTypes = {
  /** value of content */
  contentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  /** text to be highlighted in content */
  contentHighlightedText: PropTypes.string,
  /** variant of alert component */
  variant: PropTypes.string,
};

DetailComponent.defaultProps = {
  contentValue: undefined,
  contentHighlightedText: '',
  variant: 'message',
};

export default DetailComponent;
