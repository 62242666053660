import styled from '@emotion/styled';
import theme from 'theme/theme';

const BoxWrapper = styled.div`
  border: ${(props) =>
    `1px solid ${
      props.isSelected ? theme.palette.dina.onFocus : theme.palette.dina.buttonBackgroundOutlined
    }`};
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 42px;
  display: flex;
  &:hover {
    background-color: ${theme.palette.dina.whiteHoverOverlay};
    .primaryAutomationTitleWrapper {
      width: calc(100% - 176px);
    }
    .primaryAutomationMenu {
      opacity: 1;
      width: 32px;
    }
  }
`;

const ContentWrapper = styled.div`
  padding-left: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  .primaryAutomationMenu {
    width: 0px;
    opacity: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    will-change: width, opacity;
  }
`;

const Icon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const PlaceholdersWrapper = styled.div`
  width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RootWrapper = styled.div`
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
  padding: 8px;
  width: 100%;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
`;

const TitleWrapper = styled.div`
  width: calc(100% - 144px);
  transition: width 0.3s ease;
  will-change: width;
`;

export {
  BoxWrapper,
  ContentWrapper,
  Icon,
  PlaceholdersWrapper,
  RootWrapper,
  Thumbnail,
  ThumbnailWrapper,
  TitleWrapper,
};
