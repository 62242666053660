import React from 'react';
import Navbar from './navbar';
import Toolbar from './toolbar';
import AppTitle from './appTitle';

const Header = ({ appTitle, thumbnailData, setThumbnailData }) => (
  <>
    <AppTitle appTitle={appTitle} />
    <Navbar thumbnailData={thumbnailData} setThumbnailData={setThumbnailData} />
    <Toolbar />
  </>
);

export default Header;
