import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import { Rnd } from 'react-rnd';
import useStyles from './resizableDialog-styles';

const getSize = (parentSize, childSize) =>
  Math.floor((100 * parentSize - parentSize * parseInt(childSize, 10)) / 200);

const ResizableDialog = ({
  open,
  children,
  initialWidth,
  initialHeight,
  minWidth,
  minHeight,
  onClose,
  initialPosition,
}) => {
  const [currentDimension, setCurrentDimension] = useState({
    width: initialWidth,
    height: initialHeight,
  });
  const { x: initialX, y: initialY } = initialPosition || { x: 0, y: 0 };
  const [currentPosition, setCurrentPosition] = useState({ x: initialX, y: initialY });

  const classes = useStyles();
  const { width, height } = currentDimension;
  const { x, y } = currentPosition;

  useLayoutEffect(() => {
    const bodyWidth = document.body.scrollWidth;
    const bodyHeight = document.body.scrollHeight;

    if (!initialPosition)
      setCurrentPosition({
        x: getSize(bodyWidth, initialWidth),
        y: getSize(bodyHeight, initialHeight),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Rnd
        className={classes.rndRoot}
        size={{ width, height }}
        position={{ x, y }}
        onResize={(_1, _2, ref, _3, position) => {
          setCurrentDimension({
            width: ref.style.width,
            height: ref.style.height,
          });
          setCurrentPosition({
            x: position.x,
            y: position.y,
          });
        }}
        onDrag={(_1, position) => {
          setCurrentPosition({
            x: position.x,
            y: position.y,
          });
        }}
        minWidth={minWidth}
        minHeight={minHeight}
        dragHandleClassName="dragHandler"
        bounds="window"
      >
        <div className={classes.childrenContainer}>{children}</div>
      </Rnd>
    </Modal>
  );
};

ResizableDialog.propTypes = {
  /** whether the dialog is open or not */
  open: PropTypes.bool,
  /** children to render inside */
  children: PropTypes.element,
  /** initial width of the dialog: must be specified in percentage i.e. '50%' */
  initialWidth: PropTypes.string,
  /** initial height of the dialog: must be specified in percentage i.e. '60%' */
  initialHeight: PropTypes.string,
  /** Minimum width of the resizable dialog */
  minWidth: PropTypes.number,
  /** Minimum height of the resizable dialog */
  minHeight: PropTypes.number,
  /** callback to close the modal */
  onClose: PropTypes.func,
  /** initial position of the dialog, initialWidth & initialHeight must be a number in this case */
  initialPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

ResizableDialog.defaultProps = {
  open: false,
  children: null,
  initialHeight: '100%',
  initialWidth: '100%',
  minHeight: 640,
  minWidth: 840,
  onClose: () => {},
  initialPosition: undefined,
};

export default ResizableDialog;
