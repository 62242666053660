import styled from '@emotion/styled';

import theme from 'theme/theme';

import List from '@material-ui/core/List';

import Divider from 'components/divider';

export const StyledDropDownWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1px;
  cursor: pointer;
  color: ${theme.palette.dina.statusWarning};
  ${theme.typography.caption}

  svg {
    fill: ${({ active }) =>
      active ? theme.palette.dina.iconActive : theme.palette.dina.iconInactive};

    &:hover {
      fill: ${theme.palette.dina.iconActive};

      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const StyledWrapper = styled('div')`
  width: 368px;
  max-height: 508px;
  background: ${theme.palette.dina.surfaceCardDark};
`;

export const StyledDropDownContentList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
`;

export const StyledDropdownTabAction = styled('div')`
  text-align: right;
  white-space: pre-line;
  color: ${theme.palette.dina.mediumEmphasis};
  ${theme.typography.caption}
`;

export const StyledTabsOverline = styled('div')`
  ${theme.typography.dina.overline}
  padding: 0 0 0 8px;
  margin: 8px 0;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 8px 0;
`;
