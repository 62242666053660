/**
 * Scrolls into view a target element with delay provided
 *
 * @param {DOMNode} target that needs to be scrolled into view
 * @param {Number} delay after which the scrolling will perform
 * @returns  Undefined
 */

const scrollAfterTimeout = (target) => {
  setTimeout(() => {
    target.scrollIntoView({
      block: 'center',
      behavior: 'instant',
    });
  }, 50);
};

export default scrollAfterTimeout;
