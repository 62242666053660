/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Dialog from 'components/dialog';
import ConfirmDialog from 'components/confirmDialog';
import UserContext from 'contexts/UserContext';
import GET_CONVERSATIONS_OF_USER from 'graphql/queries/getConversationsOfUser';
import LEAVE_CONVERSATION from 'graphql/mutations/leaveConversation';
import memberTypes from 'graphql/memberTypes';
import conversationTypes from 'utils/constants/conversationTypes';
import notificationImage from 'assets/icons/systemicons/notifications_on.svg';
import useLogger from 'utils/useLogger';
import useConversationContext from 'screens/main/components/header/navbar/messageHub/hooks/useConversationContext';
import LeftColumn from './leftColumn-view';
import assembleTeamData from '../newMessage/utils/assembleTeamData';
import assembleDepartmentData from '../newMessage/utils/assembleDepartmentData';

const LeftColumnContainer = ({ onAddNewConversation, ...rest }) => {
  const logger = useLogger('Conversation list: leftcolumn');
  const user = useContext(UserContext);
  const { mId } = user;

  const { selectedConversationIdRef, onConversationSelect, currentConversation } =
    useConversationContext();

  const [dialog, setDialog] = useState(null);
  const [dialogId, setDialogId] = useState(null);

  const closeDialog = () => setDialog(null);

  const [leaveConversation] = useMutation(LEAVE_CONVERSATION);

  const { data, loading, error } = useQuery(GET_CONVERSATIONS_OF_USER, {
    variables: {
      input: {
        mId,
      },
    },
  });

  const handleLeaveConversation = useCallback(
    (id) => {
      if (currentConversation.mId === id) {
        selectedConversationIdRef.current = null;
        onConversationSelect({});
      }

      leaveConversation({
        variables: {
          input: {
            mId: id,
            mRefId: mId,
          },
        },
        update: (proxy) => {
          const conversations = proxy.readQuery({
            query: GET_CONVERSATIONS_OF_USER,
            variables: {
              input: {
                mId,
              },
            },
          });
          const newConversations = conversations.getConversationsOfUser.filter(
            ({ mId: _mId }) => _mId !== id,
          );

          proxy.writeQuery({
            query: GET_CONVERSATIONS_OF_USER,
            variables: {
              input: {
                mId,
              },
            },
            data: {
              getConversationsOfUser: newConversations,
            },
          });
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentConversation.mId, selectedConversationIdRef.current],
  );

  const confirmDialog = () => {
    closeDialog();
    handleLeaveConversation(dialogId);
  };

  const DialogContent = () => {
    if (dialog === 'leave-conversation') {
      return (
        <Dialog open={Boolean(dialog)} onClose={closeDialog}>
          <ConfirmDialog
            onCancel={closeDialog}
            onConfirm={confirmDialog}
            title="Leave the Conversation?"
            confirmButtonLabel="Yes, leave Conversation"
            confirmButtonType="danger"
            // eslint-disable-next-line max-len
            info="When you leave a Conversation, you will no longer receive any notifications from it. Messages you have posted in this Conversation will remain visible for remaining participants."
          />
        </Dialog>
      );
    }
    return <></>;
  };

  const onMenuSelect = (action, id) => {
    if (action === 'leave-conversation') {
      setDialog(action);
      setDialogId(id);
    }
  };

  if (loading) return <LoadingIndicator />;
  if (error) {
    logger.error(error);
    return <div>{error.message}</div>;
  }

  const getConversationsOfUser = data?.getConversationsOfUser || [];

  const teamData = getConversationsOfUser
    .filter((d) => d.mType === memberTypes.TEAM)
    .filter(Boolean);
  const departmentData = getConversationsOfUser
    .filter((d) => d.mType === memberTypes.DEPARTMENT)
    .filter(Boolean);

  const groupedData = groupBy(data.getConversationsOfUser, 'convoType');
  const people = groupedData[conversationTypes.DIRECT];

  const groups = groupedData[conversationTypes.GROUP];
  const teams = assembleTeamData(teamData, true);
  const departments = assembleDepartmentData(departmentData, true);

  return (
    <>
      <LeftColumn
        {...rest}
        people={people}
        groups={groups}
        teams={teams}
        departments={departments}
        onAddButtonClicked={onAddNewConversation}
        notificationsConvoList={[
          {
            mId: `${mId}_notifications`,
            mTitle: 'DINA Notifications',
            mAvatarUrl: notificationImage,
            convoType: conversationTypes.NOTIFICATION,
          },
        ]}
        onMenuSelect={onMenuSelect}
      />
      <DialogContent />
    </>
  );
};

LeftColumnContainer.propTypes = {
  /** callback to open new conversation list */
  onAddNewConversation: PropTypes.func,
};

LeftColumnContainer.defaultProps = {
  onAddNewConversation: () => {},
};

export default LeftColumnContainer;
