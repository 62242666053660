import statusBoard from './statusBoard';

export const defaultState = {
  id: 'undefined',
  name: 'Undefined',
  description: 'Undefined',
  backgroundColor: 'kanbanArchived',
  icon: 'todo',
};

const returnState = (stateId) => {
  const { states } = statusBoard;
  const foundState = states.find((s) => s.id === stateId);
  if (foundState) return foundState;
  return defaultState;
};

export default returnState;
