import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    maxHeight: '72px',
  },
  avatar: {
    width: '48px',
    height: '48px',
    margin: '12px',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  nameLine: {
    ...theme.typography.dina.listItemLabel,
    display: '-webkit-box',
    lineClamp: 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  ellipsis: {
    marginTop: '-5px',
  },

  titleLine: {
    ...theme.typography.dina.caption,
  },
  icon: {
    color: theme.palette.dina.iconInactive,
    margin: '0 20px 0 10px',
  },
  button: {
    '&:hover': {
      background: theme.palette.dina.surfaceAppBackgroundMain,
    },
    '&:focus': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  focusDiv: {
    transform: 'translateZ(0)',
    border: ({ hovered }) => (hovered ? `1px solid ${theme.palette.dina.statusWarning}` : null),
  },

  divider: {
    paddingLeft: '72px',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
  },
}));

export default useStyles;
