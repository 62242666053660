import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import AutoCompleteBase from 'components/autoCompleteBase';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import ChipGroup from 'components/editor/components/tags/components/chipGroup';
import InputField from 'components/inputField';
import useInputEvents from 'hooks/useInputEvents';
import { InputWrapper } from './styled';

const Input = memo(InputField);

const Metadata = ({ captionProps, sourceProps, updateMetadata }) => {
  /** combobox value setters */
  const [value, setValue] = useState(null);
  const [caption, setCaption] = useState(captionProps.value);
  const [inputValue, setInputValue] = useState('');

  const onCaptionUpdate = useCallback(
    (newValue) => {
      if (newValue === captionProps.value) setCaption(captionProps.value);
      else updateMetadata(newValue, captionProps.propName);
    },
    [captionProps, updateMetadata],
  );

  const [captionInputRef, handleKeydownOnCaption, handleBlurOnCaption, handleClearCaption] =
    useInputEvents(onCaptionUpdate, caption, captionProps.value);

  const handleChange = (event, newObj) => {
    event.preventDefault();
    if (newObj) {
      const isIncluded = sourceProps.value.find((opt) => opt.value === newObj.value);

      if (!isIncluded) {
        const newData = [...sourceProps.value, { id: newObj.id, value: newObj.value }];
        updateMetadata(newData, sourceProps.propName);
      }
      setInputValue('');
      setValue(null);
    }
  };

  const removeChip = (event, index) => {
    event.preventDefault();
    const newData = sourceProps.value.filter((_, pos) => pos !== index);
    updateMetadata(newData, sourceProps.propName);
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'source-box',
    options: sourceProps.options,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
  });

  const onCaptionChange = useCallback((val) => {
    setCaption(val);
  }, []);

  return (
    <>
      <InputWrapper>
        <Input
          inputRef={captionInputRef}
          value={caption}
          types="text"
          usage="editor"
          severity="regular"
          label="Add caption..."
          onKeyDown={handleKeydownOnCaption}
          onBlur={handleBlurOnCaption}
          onChange={onCaptionChange}
          onClear={handleClearCaption}
        />
      </InputWrapper>
      <InputWrapper>
        <AutoCompleteBase
          content={sourceProps.value}
          value={inputValue}
          inputUsage="metadata"
          placeholder={sourceProps.placeholder}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          getListboxProps={getListboxProps}
          getOptionProps={getOptionProps}
          getClearProps={getClearProps}
          groupedOptions={groupedOptions}
          getPopupIndicatorProps={getPopupIndicatorProps}
          popupOpen={popupOpen}
        />
        <ChipGroup list={sourceProps.value} removeChip={removeChip} />
      </InputWrapper>
    </>
  );
};

Metadata.propTypes = {
  /** sourceProps for input field */
  sourceProps: PropTypes.shape({}),
  /**  caption props */
  captionProps: PropTypes.shape({}),
  /** updatemetadata callback function */
  updateMetadata: PropTypes.func,
};

Metadata.defaultProps = {
  sourceProps: {},
  captionProps: {},
  updateMetadata: () => {},
};

export default memo(Metadata);
