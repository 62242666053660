import mergeSettings from 'utils/settings/mergeSettings';

const saveSettingsOnContext = (settingsResult, config) => {
  const { generalSettings, userSettings } = settingsResult.data;
  const mergedSettings = mergeSettings(
    generalSettings && generalSettings.mMetaData,
    userSettings && userSettings.mMetaData,
  );
  // eslint-disable-next-line no-param-reassign
  config.settings = [mergedSettings];
};

export default saveSettingsOnContext;
