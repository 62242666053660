import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '172px',
    backgroundColor: theme.palette.dina.backgroundResting,
    height: '100%',
    position: 'relative',
    flexGrow: 1,
  },
  header: {
    height: '40px',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.dina.overline,
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  organization: {
    height: ({ organizationListHeight }) => organizationListHeight,
  },
  divider: {
    margin: '8px 0 8px 56px',
    background: theme.palette.dina.dividerLight,
  },
  notificationList: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `4px solid ${theme.palette.dina.dividerLight}`,
    padding: '8px 0',
  },
}));

export default useStyles;
