import isHotkey from 'is-hotkey';
import { hotkeys } from 'components/editor/constants';
import { toggleMark, isMarkActive } from 'components/editor/utils';
import actionTypes from 'components/editor/constants/types/actionTypes';

const onLeafKeyDown = (editor, event, onDone) => {
  Object.keys(hotkeys).forEach(hotkey => {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();

      const mark = hotkeys[hotkey];

      if (mark === actionTypes.DONE) onDone();
      else {
        const isActive = isMarkActive(editor, mark);

        toggleMark(editor, mark, isActive);
      }
    }
  });

  return editor;
};

export default onLeafKeyDown;
