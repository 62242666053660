import React from 'react';
import LeftMenu from './leftMenu';
import Content from './content';
import useStyles from './dialogBody-styles';

const DialogBody = ({
  menuHeaderTitle,
  menus,
  onLeftMenuItemSelect,
  selectedLeftMenu,
  isMenuLoading,
  contentHeaderTitle,
  content,
  isContentLoading,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.leftMenu}>
        <LeftMenu
          headerTitle={menuHeaderTitle}
          menuItems={menus}
          onItemClick={onLeftMenuItemSelect}
          selectedLeftMenu={selectedLeftMenu}
          loading={isMenuLoading}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.content}>
        <Content headerTitle={contentHeaderTitle} content={content} loading={isContentLoading} />
      </div>
    </div>
  );
};

DialogBody.propTypes = {};

DialogBody.defaultProps = {};

export default DialogBody;
