import styled from '@emotion/styled/macro';
import colors from 'theme/colors';

export const ButtonText = styled('span')`
  display: block;
  padding-top: 2px;
  color: ${colors.mediumEmphasis};
`;

export const ButtonWrapper = styled('div')`
  z-index: 50;
  margin: 0;
  :hover {
    path {
      fill-opacity: 1;
    }
    ${ButtonText} {
      color: ${colors.highEmphasis};
    }
  }
  margin: 0;
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  height: 56px;
`;
