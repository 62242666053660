import styled from '@emotion/styled';

import theme from 'theme/theme';

import MaterialAvatar from '../material-avatar';

export const StyledRundownAvatar = styled(MaterialAvatar)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 8px;

  ${({ src }) =>
    !src &&
    `
    background-color: ${theme.palette.dina.blackHoverOverlay};
    border: 1px dashed ${theme.palette.dina.inputBorderResting};
  `}
`;

export const StyledTemplateWrapper = styled('div')`
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 0px 0px 0px 8px;
  overflow: hidden;
  bottom: 0;
  left: 0;
`;

export const StyledTemplate = styled('div')`
  border-bottom: 42px solid ${theme.palette.dina.statusWarning};
  border-right: 42px solid transparent;
`;

export const StyledTemplateImage = styled('img')`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const StyledPublishingPoint = styled('div')`
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 60%;
  right: 60%;
`;
