import { durationTypes, getTime, getDuration, getSeconds } from '../components/editor/utils';

const updateDurationField = (fields, key, value) => {
  const field = fields.find((f) => f.key.includes(key));
  const fieldKey = field ? field.key : key;

  const fieldList = fields.filter((f) => f.key !== fieldKey);
  fieldList.push({ key: fieldKey, value });

  return fieldList;
};

const respectHostReadSpeed = (mMetaData, hostReadSpeed) => {
  let metaData = mMetaData ? [...mMetaData] : [];
  const wordCount = getDuration(metaData, durationTypes.WORD_COUNT);
  if (!wordCount) return metaData;

  const readRate = hostReadSpeed; // words per minute
  const wordsPerSecond = readRate / 60;
  const newSpeakDuration = Math.ceil(wordCount / wordsPerSecond);

  metaData = updateDurationField(metaData, durationTypes.HOST_READ_SPEED, hostReadSpeed);

  metaData = updateDurationField(metaData, durationTypes.SPEAK_DURATION, getTime(newSpeakDuration));
  const clipDuration = getDuration(metaData, durationTypes.CLIP_DURATION);
  const newTotalDuration = getSeconds(clipDuration) + newSpeakDuration;

  metaData = updateDurationField(metaData, durationTypes.TOTAL_DURATION, getTime(newTotalDuration));

  return metaData;
};

export default respectHostReadSpeed;
