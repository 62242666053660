/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import getTimeString from 'utils/getTimeString';
import ArrowDropDownUnboxedSrc from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet_unboxed.svg';
import Dropdown from './components/lowerthirdDropdown';
import SelectView from './components/select';
import {
  Arrow,
  DropdownWrapper,
  SubTitle,
  Title,
  TitlesWrapper,
  Wrapper,
  SelectWrapper,
} from './styled';

/** Returns concatenated meta values */
const concatenateMetaValues = (metaData) => {
  if (metaData.length === 0) return '';
  return metaData.reduce((mstr, mdata) => {
    if (!mdata.isVisible) return mstr;
    if (mstr) mstr += ', ';
    mstr += mdata.value;
    return mstr;
  }, '');
};

const getSubtitleString = (metaData, asset) => {
  let subTitle = concatenateMetaValues(metaData);
  if (asset) {
    if (subTitle) subTitle += ' | ';
    if (asset.mTitle) subTitle += asset.mTitle;
    else if (asset.title) subTitle += asset.title;
    if (asset.itemDuration) subTitle += ` | ${getTimeString(asset.itemDuration)}`;
  }
  return subTitle;
};

const splitTitleBySlash = (title) =>
  title
    .split('/')
    .slice(0, 3)
    .map((line) => line.trim());

const TitleBox = ({
  title,
  meta,
  asset,
  graphicsDestination,
  updateVariant,
  updateGraphicsDestination,
  spec = [],
  resources,
  isGraphic,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const titles = useMemo(() => title && splitTitleBySlash(title), [title]);
  const subTitle = useMemo(() => getSubtitleString(meta, asset), [meta, asset]);
  const [isLowerthirdSelectOpen, setIsLowerthirdSelectOpen] = useState(false);

  const openSelectMenu = useCallback(() => setIsSelectOpen(true), []);
  const closeSelectMenu = useCallback(() => setIsSelectOpen(false), []);
  const openLowerthirdSelectMenu = useCallback(() => setIsLowerthirdSelectOpen(true), []);
  const closeLowerthirdSelectMenu = useCallback(() => setIsLowerthirdSelectOpen(false), []);

  const handleSelection = useCallback(
    (selectedValue) => {
      const template = spec.find((temp) => temp.variant === selectedValue);
      if (template) {
        const { variant, fields, name, description } = template;
        updateVariant(variant, fields, name, description);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateVariant],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLowerthirdSelection = useCallback(
    (selectedValue) => {
      const lowerThird = resources.graphicsDestinations.find((d) => d.value === selectedValue);
      if (lowerThird) {
        const { value } = lowerThird;
        updateGraphicsDestination(value);
      }
    },
    [resources, updateGraphicsDestination],
  );

  return (
    <Wrapper>
      <SelectWrapper onClick={openSelectMenu} role="presentation">
        {!isGraphic && <Arrow src={ArrowDropDownUnboxedSrc} alt="expand" />}
        <TitlesWrapper>
          {spec?.length > 0 && (
            <SelectView
              isOpen={isSelectOpen}
              items={spec}
              onChange={handleSelection}
              onClose={closeSelectMenu}
              onOpen={openSelectMenu}
              selectedValue={title}
            />
          )}

          {spec?.length === 0 &&
            titles?.length > 0 &&
            titles.map((line) => (
              <Title title={line} key={line}>
                {line}
              </Title>
            ))}
          <SubTitle title={subTitle}>{subTitle}</SubTitle>
        </TitlesWrapper>
      </SelectWrapper>
      {isGraphic && resources?.graphicsDestinations?.length > 0 && (
        <DropdownWrapper onClick={openLowerthirdSelectMenu}>
          <Dropdown
            isOpen={isLowerthirdSelectOpen}
            items={resources.graphicsDestinations}
            onChange={handleLowerthirdSelection}
            onClose={closeLowerthirdSelectMenu}
            selectedValue={
              !graphicsDestination
                ? resources.graphicsDestinations.find((r) => r.type === 'default').value
                : graphicsDestination
            }
          />
        </DropdownWrapper>
      )}
    </Wrapper>
  );
};

TitleBox.propTypes = {
  /** templateVariant to show as title */
  title: PropTypes.string,
  /** metaData to show as subtitle */
  meta: PropTypes.arrayOf(PropTypes.object),
  /** on Title box click callback function */
  updateVariant: PropTypes.func,
};

TitleBox.defaultProps = {
  title: 'VARIANT',
  meta: [],
  updateVariant: () => {},
};

export default memo(TitleBox);
