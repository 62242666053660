import styled from '@emotion/styled/macro';
import theme from 'theme/theme';
import { borderColor } from './utils/styleUtils';

export const MainWrapper = styled('div')`
  width: 100%;
  min-height: 56px;
  border-radius: 4px;
  display: flex;
  position: relative;
  border: ${(props) => props.isSelected && `1px solid ${theme.palette.dina.onFocus}`};
  :hover {
    background-color: ${theme.palette.dina.whiteHoverOverlay};
  }
`;

export const ChildWrapper = styled('div')`
  width: calc(100% - 16px);
  margin: 0 8px;
  border-top: ${borderColor};
  border-bottom: ${borderColor};
  box-sizing: border-box;
`;
