import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'components/scrollbar/scrollbar';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { Collapse } from '@material-ui/core/';
import { useSelectedLeftMenu, useSelectedGroup, useSelectedGroupName } from '../../atoms';
import { RootWrapper, BlankDiv, ArrowWrapper, CountText, GroupList } from './settingsMenu-styled';

const SettingsMenu = (props) => {
  const { groupPolicies } = props;
  const [openGroup, setOpenGroup] = useState(false);
  const [selectedLeftMenu, setSelectedLeftMenu] = useSelectedLeftMenu();
  const [, setSelectedGroup] = useSelectedGroup();
  const [, setSelectedGroupName] = useSelectedGroupName();

  const toggleLeftMenu = (leftMenuItem, isGroupOpen) => {
    setSelectedLeftMenu(leftMenuItem);
    setSelectedGroup(isGroupOpen);
  };
  const toggleLeftMenuProfile = () => {
    toggleLeftMenu('profile', false);
  };

  const toggleGroupOpen = () => setOpenGroup(!openGroup);

  return (
    <RootWrapper>
      <Scrollbar>
        <ListItem
          text="Profile"
          selected={selectedLeftMenu === 'profile'}
          onClick={toggleLeftMenuProfile}
          excludeDivider
          firstChild={<BlankDiv margin="26px" />}
        />
        <GroupList
          text="Groups"
          excludeDivider
          onClick={toggleGroupOpen}
          firstChild={<ArrowWrapper>{openGroup ? <ArrowDown /> : <ArrowRight />}</ArrowWrapper>}
          iconChild={<CountText>{groupPolicies.length}</CountText>}
        />
        <Collapse in={openGroup} mountOnEnter unmountOnExit>
          {groupPolicies.map((item) => (
            <ListItem
              key={item.mRefId}
              text={item.mTitle}
              selected={selectedLeftMenu === item.mRefId}
              onClick={() => {
                setSelectedLeftMenu(item.mRefId);
                setSelectedGroup(true);
                setSelectedGroupName(item.mTitle);
              }}
              excludeDivider
              firstChild={<BlankDiv margin="45px" />}
              whiteSpace="nowrap"
              iconChild={<CountText>{item?.mProperties?.userCount}</CountText>}
            />
          ))}
        </Collapse>
      </Scrollbar>
    </RootWrapper>
  );
};

SettingsMenu.propTypes = {
  groupPolicies: PropTypes.array,
};

SettingsMenu.defaultProps = {
  groupPolicies: [],
};

export default SettingsMenu;
