import React from 'react';
import { Popover as MaterialPopover, Fade } from '@material-ui/core';
import useStyles from './popover-styles';

const Popover = ({ anchorEl, onClose, children, ...rest }) => {
  const classes = useStyles();
  return (
    <MaterialPopover
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      PaperProps={{ elevation: 2 }}
      classes={{ paper: classes.paper }}
      TransitionComponent={Fade}
      {...rest}
    >
      {children}
    </MaterialPopover>
  );
};

export default Popover;
