import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  leftMenu: {
    position: 'absolute',
    width: '256px',
    left: '0px',
    top: '0px',
    bottom: '0px',
  },

  divider: {
    position: 'absolute',
    width: '1px',
    left: '256px',
    right: '0px',
    top: '0px',
    bottom: '0px',
    background: theme.palette.dina.dividerLight,
  },

  content: {
    position: 'absolute',
    left: '257px',
    right: '0px',
    top: '0px',
    bottom: '0px',
  },
}));

export default useStyles;
