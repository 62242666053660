/**
 * Extracts abstract value given mos xml object
 *
 * @param {Object} xmlDoc Pased mos xml object
 * @returns {String} Abstract value
 */

const getMosAbstract = (xmlDoc) => {
  const mosAbstractObject = xmlDoc.querySelector('mosAbstract');
  const abstract = mosAbstractObject ? mosAbstractObject.textContent : null;

  return abstract;
};

export default getMosAbstract;
