import styled from '@emotion/styled';
import DividerComponent from 'components/divider';
import { Typography } from '@material-ui/core';
import { captionMedium, caption } from 'theme/styledTypography';
import theme from 'theme/theme';
import transientOptions from 'theme/helpers';

export const Wrapper = styled('div', transientOptions)`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: ${({ $selected, $showFocus }) => {
    if ($selected) return theme.palette.dina.onSelected;
    if ($showFocus) return theme.palette.dina.statusOnFocusedDiscreetFill;
    return 'transparent';
  }};
  :hover {
    background-color: ${({ $selected, $showFocus }) => {
      if ($selected) return theme.palette.dina.onSelected;
      if ($showFocus) return theme.palette.dina.statusOnFocusedDiscreetFill;
      return theme.palette.dina.statusOnHover;
    }};
  }
  opacity: ${({ $showFocus }) => ($showFocus ? 0.8 : 1)};
`;

export const Avatar = styled('div')`
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 12px;
  margin-right: 8px;
`;

export const ThumbnailImage = styled('img')`
  width: 26px;
  height: 26px;
  border-radius: 2px;
  object-fit: cover;
`;

export const PublishingPointImage = styled('img')`
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
  bottom: 0;
`;
export const CenterContent = styled('div')`
  flex: 1;
  height: 40px;
  min-width: 0;
`;
export const Title = styled(Typography)`
  ${captionMedium};
  color: ${theme.palette.dina.highEmphasis};
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Icons = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-top: 3px;
  > :not(:first-of-type) {
    margin-left: 2px;
  }
`;

export const TimingDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none;
  margin-top: -22px;
  margin-right: 12px;
  margin-left: 5px;
  ${caption};
  line-height: 14px;
  letter-spacing: 0.25px;
  font-weight: normal;
  text-align: right;
`;

export const Divider = styled(DividerComponent)`
  margin-left: 52px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
