import styled from '@emotion/styled';
import colors from 'theme/colors';

export const RootWrapper = styled('div')`
  display: flex;
  height: 57px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  border-top: 1px solid ${colors.dividerLight};
  background: ${colors.backgroundResting};
`;

export const ButtonWrapper = styled('div')`
  width: 160px;
  height: 40px;
  padding-right: 8px;
`;

export const TextHighEmphasis = styled('div')`
  color: ${colors.highEmphasis};
`;

export const TextMediumEmphasis = styled('div')`
  color: ${colors.mediumEmphasis};
`;
