import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '40px',
    height: '40px',
    '& path': {
      fillOpacity: 1,
    },
  },
  image: {
    width: '40px',
    height: '40px',
    borderRadius: '2px',
    objectFit: 'cover',
  },
}));

export default useStyles;
