import styled from '@emotion/styled/macro';
import theme from 'theme/theme';
import Menu from './components/ellipsisMenu';

export const ContentWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const SelectWrapper = styled('div')`
  min-width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
`;

export const VerticalBorder = styled('div')`
  height: 100%;
  border-right: 1px solid ${theme.palette.dina.dividerLight};
  margin-left: 8px;
`;

export const TitleBoxWrapper = styled('div')`
  width: calc(100% - 116px);
  height: 100%;
  display: flex;
  align-items: center;
`;

export const EllipsisMenuWrapper = styled('div')`
  width: 24px;
  height: 24px;
  padding-right: 4px;
  position: relative;
  margin-right: 4px;
`;

export const EllipsisMenu = styled(Menu)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
`;

export const BoxWrapper = styled('div')`
  width: 100%;
  min-width: 328px;
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'all')};
  padding: 8px;

  .automationIcon {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
  }

  :hover {
    ${EllipsisMenu} {
      opacity: 1;
    }
    .automationIcon {
      opacity: 0;
    }
  }
`;
