import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AddMember from 'components/addMemberDialog';
import AddUnit from 'components/addUnitDialog';
import memberTypes from 'graphql/memberTypes';
import { searchbarActions, searchbarFields } from 'utils/constants/searchbar';
import checkIsEmptyModel from '../../utils/isEmptyModel';
import SavedSearches from '../savedSearches';
import Platforms from '../platforms';

const updateMembers = (members) =>
  members.map(({ mId, mTitle, mType }) => ({ mId, mTitle, mType }));

const PopoverContents = ({
  popoverType,
  filterModel,
  savedFilters,
  onCreateFilter,
  onUpdateFilters,
  history,
  currentSearch,
  updateCurrentSearch,
  updateFilterModel,
  updateHistory,
  onClose,
  hideSavedFilters,
  platforms,
}) => {
  const isEmptyModel = checkIsEmptyModel(filterModel);

  const updatePlatforms = useCallback(
    (updatedPlatforms) => {
      updateFilterModel({ type: searchbarActions.UPDATE_PLATFORM, value: updatedPlatforms });
      updateHistory(searchbarFields.platform, updatedPlatforms);
    },
    [updateHistory, updateFilterModel],
  );

  const updateTeams = useCallback(
    (newMembers) => {
      onClose();
      const updatedTeams = updateMembers(newMembers);
      updateFilterModel({ type: searchbarActions.UPDATE_TEAM, value: updatedTeams });
      updateHistory(searchbarFields.TEAM, updatedTeams);
    },
    [updateFilterModel, updateHistory, onClose],
  );

  const updateDepartments = useCallback(
    (newMembers) => {
      onClose();
      const updatedDepartments = updateMembers(newMembers);
      updateFilterModel({ type: searchbarActions.UPDATE_DEPARTMENT, value: updatedDepartments });
      updateHistory(searchbarFields.DEPARTMENT, updatedDepartments);
    },
    [updateFilterModel, updateHistory, onClose],
  );

  const updateUnits = useCallback(
    (newMembers) => {
      onClose();
      const updatedMembers = updateMembers(newMembers);
      const team = updatedMembers.filter((member) => member.mType === memberTypes.TEAM);
      const department = updatedMembers.filter((member) => member.mType === memberTypes.DEPARTMENT);
      const groupedData = { team, department };
      updateFilterModel({ type: searchbarActions.UPDATE_UNIT, value: groupedData });
      updateHistory(searchbarFields.UNIT, groupedData);
    },
    [updateFilterModel, updateHistory, onClose],
  );

  const updateUsers = useCallback(
    (newMembers) => {
      onClose();
      const updatedUsers = updateMembers(newMembers);
      updateFilterModel({ type: searchbarActions.UPDATE_USER, value: updatedUsers });
      updateHistory(searchbarFields.USER, updatedUsers);
    },
    [updateFilterModel, updateHistory, onClose],
  );

  const handleSelectSearch = useCallback(
    (newModel) => {
      onClose();
      newModel && updateFilterModel({ type: searchbarActions.SELECT, value: newModel });
    },
    [onClose, updateFilterModel],
  );

  const handleCreateNewFilter = useCallback(
    (label) => {
      onCreateFilter({ label, model: filterModel, isPinned: false });
    },
    [filterModel, onCreateFilter],
  );

  switch (popoverType) {
    case searchbarFields.PLATFORM:
      return (
        <Platforms
          platforms={platforms}
          onClose={onClose}
          filters={filterModel?.platform || []}
          updateFilters={updatePlatforms}
        />
      );
    case searchbarFields.USER:
      return (
        <AddMember
          variant="People"
          dialogTitle="Assigned to..."
          onCancel={onClose}
          members={filterModel?.user || []}
          onOk={updateUsers}
          usage="searchBar"
          showItems={false}
        />
      );
    case searchbarFields.TEAM:
      return (
        <AddMember
          variant="Team"
          dialogTitle="Assigned to..."
          onCancel={onClose}
          members={filterModel?.team || []}
          onOk={updateTeams}
          usage="searchBar"
          showItems={false}
        />
      );
    case searchbarFields.DEPARTMENT:
      return (
        <AddMember
          variant="Department"
          dialogTitle="Assigned to..."
          onCancel={onClose}
          members={filterModel?.department || []}
          onOk={updateDepartments}
          usage="searchBar"
          showItems={false}
        />
      );
    case searchbarFields.UNIT: {
      const unitMembers = [...(filterModel?.department || []), ...(filterModel?.team || [])];
      return (
        <AddUnit
          dialogTitle="Assigned to..."
          onCancel={onClose}
          members={unitMembers}
          onOk={updateUnits}
          usage="searchBar"
          showItems={false}
        />
      );
    }
    case searchbarFields.SAVED_SEARCH:
      return (
        <SavedSearches
          onCreateFilter={handleCreateNewFilter}
          savedFilters={savedFilters}
          onUpdateFilters={onUpdateFilters}
          isEmptyModel={isEmptyModel}
          history={history}
          onSelectSearch={handleSelectSearch}
          currentSearch={currentSearch}
          updateCurrentSearch={updateCurrentSearch}
          hideSavedFilters={hideSavedFilters}
        />
      );
    default:
      return <div />;
  }
};

const model = PropTypes.shape({
  label: PropTypes.string,
  model: PropTypes.shape({
    freeText: PropTypes.string,
    user: PropTypes.arrayOf(PropTypes.shape({})),
    team: PropTypes.arrayOf(PropTypes.shape({})),
    department: PropTypes.arrayOf(PropTypes.shape({})),
    platform: PropTypes.arrayOf(PropTypes.shape({})),
  }),
});

PopoverContents.propTypes = {
  popoverType: PropTypes.string,
  onCreateFilter: PropTypes.func,
  onUpdateFilters: PropTypes.func,
  updateCurrentSearch: PropTypes.func,
  updateFilterModel: PropTypes.func,
  updateHistory: PropTypes.func,
  onClose: PropTypes.func,
  savedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      model,
    }),
  ),
  currentSearch: PropTypes.shape({
    type: PropTypes.string,
    identification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  history: PropTypes.arrayOf(model),
  filterModel: model,
  hideSavedFilters: PropTypes.bool,
};

PopoverContents.defaultProps = {
  popoverType: '',
  onCreateFilter: () => {},
  onUpdateFilters: () => {},
  updateCurrentSearch: () => {},
  updateFilterModel: () => {},
  updateHistory: () => {},
  onClose: () => {},
  savedFilters: [],
  currentSearch: undefined,
  history: [],
  filterModel: {},
  hideSavedFilters: false,
};

export default PopoverContents;
