import React, { useContext } from 'react';
import { DatePickerContext } from 'globalState';
import CalendarView from './calendar-view';

const Calendar = props => {
  try {
    const [isExpanded, setIsExpanded] = useContext(DatePickerContext);
    return <CalendarView {...{ ...props, isExpanded, setIsExpanded }} />;
  } catch (e) {
    // logger.log(e);
  }
  return <CalendarView {...{ ...props }} />;
};

export default Calendar;
