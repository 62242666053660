/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import Input from 'components/shared/input';
import AutoComplete from '../autocomplete';
import inputTypes from '../utils/inputTypes';
import { TableHeader, TableData, Table } from './table-view-styled';

const TableRow = ({ field, onFieldUpdate, resources }) => {
  const { name, value, type } = field;

  const handleSelection = useCallback((selectedValue) => {
    onFieldUpdate({ name, value: selectedValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = useCallback((inputValue) => {
    if (inputValue !== value) onFieldUpdate({ name, value: inputValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resource = resources[field.options];
  const items = [...sortBy(resource, 'name')];

  const fieldInput = useMemo(() => {
    switch (type) {
      case inputTypes.SELECT:
        return <AutoComplete initialValue={value} options={items} onChange={handleSelection} />;
      default:
        return <Input value={value || ''} onUpdate={handleUpdate} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <tr>
      <TableData $width="20%">{name}</TableData>
      <TableData>{fieldInput}</TableData>
    </tr>
  );
};

const TableView = ({ fields, resources, onFieldUpdate, ...rest }) => (
  <Table {...rest}>
    <tbody>
      <tr>
        <TableHeader $width="30%">key</TableHeader>
        <TableHeader>value</TableHeader>
      </tr>
      {fields.map((field, index) => (
        <TableRow
          key={`table-${index}`}
          field={field}
          onFieldUpdate={onFieldUpdate}
          resources={resources}
        />
      ))}
    </tbody>
  </Table>
);

TableView.propTypes = {
  /** data that will be mapped on to the table */
  fields: PropTypes.arrayOf(PropTypes.object),
  /** onFieldUpdate callback function */
  onFieldUpdate: PropTypes.func,
  /** mixerInputs options */
  mixerInputs: PropTypes.arrayOf(PropTypes.object),
};

TableView.defaultProps = {
  fields: [],
  onFieldUpdate: () => {},
  mixerInputs: [],
};
export default memo(TableView);
