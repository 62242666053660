import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, useReadOnly, useSelected } from 'slate-react';

import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import { elementTypes } from 'components/editor/constants/types';
import SelectedElement from 'components/editor/components/selectedElement';
import AddSrc from 'assets/icons/systemicons/placeholders_add.svg';
import PlaySrc from 'assets/icons/systemicons/Play_WithCircleBackground_small.svg';
import HourglassSrc from 'assets/icons/systemicons/hourglass.svg';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { useRightHidden, useRightSelection } from 'store/sidebar';
import PlaceholderDialog from 'components/editor/components/placeholderDialog';
import removePlaceholder from 'components/editor/components/placeholderDialog/utils/removePlaceholder';
import postMessage from 'utils/postMessage';
import { onImageLoadingError } from 'utils/image/imageUtils';
import { mediaTypes } from 'utils/rundownItemTypes';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useGetAsset from 'hooks/useGetAsset';
import { getThumbnailKey } from 'utils/mediaKey';
import openAssetInMimir, { isMimirAssetItem } from 'utils/openAssetInMimir';
import DragAndDrop from '../dragAndDrop';
import PrimaryDropZone from './components/primaryDropZone';
import MediaDropZone from './components/mediaDropZone';
import AutomationIcon from './components/automationIcon';
import Select from './components/select';
import Menu from '../menu';
import Title from './components/title';
import MediaDialog from './components/mediaDialog';
import DetailsDialog from './components/detailsDialog';
import getInitialData from './utils/getInitialData';
import updateElement from './utils/updateElement';
import syncAssetWithSlate from './utils/syncAssetWithSlate';

import {
  BoxWrapper,
  ContentWrapper,
  Icon,
  PlaceholdersWrapper,
  RootWrapper,
  Thumbnail,
  ThumbnailWrapper,
  TitleWrapper,
} from './styled';

const { PACKAGE, ADLIB, VOICE_OVER } = elementTypes;
const videoAutomationTypes = [PACKAGE, ADLIB, VOICE_OVER];

const PrimaryAutomation = ({ attributes, children, element }) => {
  const { update, withSignedUrl } = useEditorContext();
  const readOnly = useReadOnly();
  const { data, type } = element;

  const isVideoAutomation = videoAutomationTypes.some((automation) => automation === type);

  const isSelected = useSelected(element);
  const editor = useSlate();
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [placeholderDialogOpen, setPlaceholderDialogOpen] = useState(false);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const initialData = useMemo(() => getInitialData(data), [data]);
  const { assets, templateVariant } = initialData;
  const [assetItem] = assets;
  const { templates } = useGetAutomationTemplates();

  const { mId, mRefId, mAssetId } = assetItem || {};
  const isAssetInMimir = Boolean(mAssetId);
  const [newAssetData] = useGetAsset(mId, mRefId, isAssetInMimir);

  useEffect(() => {
    if (newAssetData) syncAssetWithSlate(editor, element, initialData, newAssetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssetInMimir, newAssetData]);

  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();

  const placeholder = useMemo(
    () => assets.find(({ mediaType }) => mediaType === 'video/placeholder'),
    [assets],
  );

  const hasPlaceholder = Boolean(placeholder);

  const memoizedKey = useMemo(() => mId && mRefId && getThumbnailKey(mId, mRefId), [mId, mRefId]);
  const { url: thumbSrc } = useGetSignedUrl(memoizedKey, false);

  const template = useMemo(
    () =>
      templates && templates.find((item) => item.type === type && item.variant === templateVariant),
    [templateVariant, templates, type],
  );

  const { video = false, graphics = false } = template?.requires || {};
  const showCreatePlaceholderOption = Boolean(video) || isVideoAutomation;
  const showEditGraphicsOption = Boolean(graphics);
  const showOpenInMimir = isMimirAssetItem(element);

  // following - 'isMImirProvider' this will be handled in another ticket.
  // ignore, it doesn't have any impact
  const isMimirProvider = true;

  const menuItems = useMemo(
    () => [
      {
        title: 'Details...',
        action: 'show-details',
        divider:
          (!showOpenInMimir && showCreatePlaceholderOption && !thumbSrc && !hasPlaceholder) ||
          showEditGraphicsOption,
      },
      ...(showOpenInMimir || thumbSrc
        ? [
            {
              title: 'Open asset',
              action: 'open-in-mimir',
              divider: showCreatePlaceholderOption || showEditGraphicsOption,
            },
          ]
        : []),
      ...(hasPlaceholder && isMimirProvider && withSignedUrl
        ? [
            {
              title: 'Upload in Mimir',
              action: 'upload-in-mimir',
              divider: hasPlaceholder,
            },
          ]
        : []),

      ...(showCreatePlaceholderOption
        ? [
            {
              ...(hasPlaceholder
                ? {
                    title: 'Remove Placeholder',
                    action: 'remove-placeholder',
                  }
                : { title: 'Create Placeholder', action: 'create-placeholder' }),
              divider: showEditGraphicsOption,
            },
          ]
        : []),
      ...(showEditGraphicsOption
        ? [
            {
              title: 'Edit graphics',
              action: 'edit-graphics',
            },
          ]
        : []),
    ],
    [
      hasPlaceholder,
      showCreatePlaceholderOption,
      showEditGraphicsOption,
      showOpenInMimir,
      thumbSrc,
      isMimirProvider,
      withSignedUrl,
    ],
  );

  /* Determine when to show add button, functionality to be implemented */
  const showAdd = false;

  const openDetailsDialog = useCallback(() => setDetailsDialogOpen(true), []);

  const closeDetailsDialog = useCallback(() => setDetailsDialogOpen(false), []);

  const openPlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(true), []);

  const closePlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(false), []);

  const openMediaDialog = useCallback(() => setMediaDialogOpen(true), []);

  const closeMediaDialog = useCallback(() => setMediaDialogOpen(false), []);
  const getValidProvider = (provider) => {
    switch (provider) {
      case 'PILOT':
      case 'XPRESSION':
      case 'xpression.mos':
        return true;
      default:
        return false;
    }
  };
  const handleEditGraphics = () => {
    if (!assetItem) return;
    if (!getValidProvider(assetItem.provider)) return;
    const message = assetItem.mosobj;
    setRightHidden(false);
    setRightSelection('graphicsbox');
    if (message) {
      postMessage(message, 'pilotedge');
    }
  };
  const handleOpenInMimir = (asset) => {
    const assetData = { ...asset };
    if (!assetData) return;
    if (assetData.mProvider !== 'Mimir') return;
    setRightHidden(false);
    setRightSelection('storybox');
    openAssetInMimir({ data: { assets: [{ ...assetData }] } });
  };

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'show-details') openDetailsDialog();

      if (action === 'edit-graphics') handleEditGraphics();

      if (action === 'create-placeholder') openPlaceholderDialog();

      if (action === 'remove-placeholder')
        removePlaceholder(editor, initialData, placeholder, update);

      if (action === 'open-in-mimir') {
        if (!isAssetInMimir) {
          updateElement(editor, initialData, newAssetData);
          handleOpenInMimir({
            ...newAssetData,
            mProvider: newAssetData.mMetaData.reduce(
              (accumulator, currentValue) =>
                currentValue.key === 'provider' ? currentValue.value : accumulator,
              '',
            ),
            mediaType: 'video/placeholder',
          });
        } else handleOpenInMimir(assetItem);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      initialData,
      placeholder,
      openDetailsDialog,
      openPlaceholderDialog,
      newAssetData,
      editor,
      assetItem,
    ],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element} hideHighlight>
        <SelectedElement element={element}>
          <PrimaryDropZone element={element}>
            <MediaDropZone element={element} canDropVideo={video}>
              {children}
              <RootWrapper contentEditable={false} readOnly={readOnly}>
                <BoxWrapper isSelected={isSelected}>
                  <AutomationIcon type={type} />
                  <ContentWrapper>
                    <Select initialData={initialData} element={element} />
                    <TitleWrapper className="primaryAutomationTitleWrapper">
                      <Title {...{ initialData, type, templates }} />
                    </TitleWrapper>
                    <PlaceholdersWrapper>
                      {(showAdd || assetItem) && (
                        <>
                          {showAdd && <img alt="add" src={AddSrc} />}
                          {hasPlaceholder && (
                            <ThumbnailWrapper onClick={thumbSrc ? openMediaDialog : null}>
                              {thumbSrc ? (
                                <>
                                  <Thumbnail
                                    alt="placeholder"
                                    src={thumbSrc}
                                    onError={onImageLoadingError}
                                  />
                                  <Icon alt="play" src={PlaySrc} />
                                </>
                              ) : (
                                <Icon alt="placeholder" src={HourglassSrc} />
                              )}
                            </ThumbnailWrapper>
                          )}
                          {!hasPlaceholder && assetItem?.assetType !== mediaTypes.GRAPHICS && (
                            <ThumbnailWrapper onClick={openMediaDialog}>
                              <Thumbnail
                                src={assetItem.thumbnailUrl}
                                alt="asset"
                                onError={onImageLoadingError}
                              />
                              <Icon alt="play" src={PlaySrc} />
                            </ThumbnailWrapper>
                          )}
                        </>
                      )}
                    </PlaceholdersWrapper>
                    <Menu
                      className="primaryAutomationMenu"
                      items={menuItems}
                      onSelect={onMenuSelect}
                    />
                  </ContentWrapper>
                </BoxWrapper>
              </RootWrapper>
            </MediaDropZone>
          </PrimaryDropZone>
        </SelectedElement>
      </DragAndDrop>

      {detailsDialogOpen && (
        <DetailsDialog
          open={detailsDialogOpen}
          onClose={closeDetailsDialog}
          {...{ initialData, type, element }}
        />
      )}

      {placeholderDialogOpen && (
        <PlaceholderDialog
          open={placeholderDialogOpen}
          onClose={closePlaceholderDialog}
          {...{ element }}
        />
      )}

      {mediaDialogOpen && (
        <MediaDialog asset={assetItem} open={mediaDialogOpen} onClose={closeMediaDialog} />
      )}
    </div>
  );
};

PrimaryAutomation.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

PrimaryAutomation.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.CAMERA,
    children: [],
  },
};

export default memo(PrimaryAutomation);
