/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Back } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import SurfaceOverlay from 'assets/images/SurfaceOverlay.png';
import { h5, h7 } from 'theme/styledTypography';
import colors from 'theme/colors';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled('div')`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
  cursor: pointer;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
  :focus {
    path {
      fill-opacity: 1;
    }
  }
`;

export const QRDialogHeader = styled('div')`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
`;

export const BackIcon = styled(Back)`
  height: 40px;
  width: 40px;
  padding: 8px;
  cursor: pointer;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
  :focus {
    path {
      fill-opacity: 1;
    }
  }
`;

export const QRDialogHeaderText = styled(Typography)`
  ${h7};
`;

export const LogoutButtonWrapper = styled('div')`
  height: 40px;
  width: 100%;
  padding: 0px 11px 0px 5px;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.05);
  }
  :active {
    transform: scale(0.9);
  }
`;

export const HeaderContainer = styled('div')`
  padding: 8px;
`;

export const AvatarBackgroundContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 140px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const AvatarBackground = styled('img')`
  width: 100%;
  height: 100%;
  object-position: 0% 25%;
  filter: blur(30px);
  opacity: 0.5;
`;

export const AvatarFallback = styled('div')`
  width: 100%;
  height: 100%;
  filter: blur(15px);
  background-image: url(${SurfaceOverlay});
`;

export const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 56px;
  margin: 8px;
`;

export const Title = styled(Typography)`
  ${h5};
  color: ${colors.highEmphasis};
  letter-spacing: 0;
  margin-left: 12px;
  z-index: 1;
`;
