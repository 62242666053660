import styled from '@emotion/styled';
import theme from 'theme/theme';
import { InputAdornment as MuiInputAdornment } from '@material-ui/core';
import { ReactComponent as ArrowDownComponent } from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
import { ReactComponent as ArrowUpComponent } from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import { css } from '@emotion/react';
import { listItemLabel } from 'theme/styledTypography';

const arrowIconStyle = css`
  width: 16px;
  height: 24px;
  margin-right: 8px;
`;

export const TextAreaWrapper = styled('div')`
  flex-grow: 1;
`;

export const InputAdornment = styled(MuiInputAdornment)`
  cursor: pointer;
  :hover {
    > * {
      path {
        fill: ${theme.palette.dina.highEmphasis};
        fill-opacity: 1;
      }
    }
  }
`;

export const ArrowUp = styled(ArrowUpComponent)`
  ${arrowIconStyle}
`;

export const ArrowDown = styled(ArrowDownComponent)`
  ${arrowIconStyle}
`;

export const ListBox = styled('div')`
  position: absolute;
  left: 56px;
  right: 16px;
  top: 134px;
  background-color: ${theme.palette.dina.surfaceCard};
  z-index: 1300;
  list-style: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.25),
    0px 12px 24px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  div[data-focus='true']:not([aria-selected='true']) {
    background-color: ${theme.palette.dina.statusOnHover};
  }
  div[aria-selected='true'] {
    background-color: ${theme.palette.dina.onSelected};
  }
  max-height: 50vh;
  > :first-child {
    margin-top: 8px;
  }
  > :last-child {
    margin-bottom: 8px;
  }
  margin: 0;
  overflow: auto;
`;

export const ListItem = styled('div')`
  ${listItemLabel}
  letter-spacing:0.25px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  color: ${(props) =>
    props.disabled ? theme.palette.dina.disabled : theme.palette.dina.highEmphasis};
`;
