import memberTypes from 'graphql/memberTypes';

const getRelationType = (type, isPitch = false) => {
  switch (type) {
    case memberTypes.TEAM:
      return isPitch ? memberTypes.TEAM_PITCH : memberTypes.TEAM_STORY;
    case memberTypes.DEPARTMENT:
      return isPitch ? memberTypes.DEPARTMENT_PITCH : memberTypes.DEPARTMENT_STORY;
    default:
      return isPitch ? memberTypes.USER_PITCH : memberTypes.USER_STORY;
  }
};
export default getRelationType;
