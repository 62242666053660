import React from 'react';
import Button from 'components/buttons/button';
import { Typography, Grid } from '@material-ui/core';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const ButtonWrapper = styled(Grid)`
  margin-left: -8px;
  margin-bottom: 16px;
`;

const Footer = ({ onClickHandler, shouldDisable }) => (
  <ButtonWrapper container>
    <Button variant="outlined" onClick={onClickHandler} disabled={shouldDisable}>
      <AddIcon />
      <Typography>Add</Typography>
    </Button>
  </ButtonWrapper>
);

Footer.propTypes = {
  /** Callable for the footer button */
  onClickHandler: PropTypes.func.isRequired,
  shouldDisable: PropTypes.bool,
};

Footer.defaultProps = {
  shouldDisable: false,
};

export default Footer;
