/** search with fuse, for frontend filtering of object arrays,
 * fuse.js documentation can be found on https://fusejs.io/
 */
import Fuse from 'fuse.js';

const useFuseSearch = (options = {}, formateResult = true) => {
  const search = (list, keys, expressions) => {
    const fuseOptions = {
      useExtendedSearch: true,
      shouldSort: false,
      threshold: 0.2,
      distance: 8000,
      ...options,
      keys,
    };

    const fuse = new Fuse(list, fuseOptions);

    if (expressions.length > 0) {
      const results = fuse.search(expressions);
      // With the use of map the output will be formatted the same way as the input.
      return formateResult ? results.map(res => res.item) : results;
    }
    return list;
  };

  return search;
};

export default useFuseSearch;
