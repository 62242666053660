import React, { useState, useEffect, useMemo } from 'react';
import { List } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import orderBy from 'lodash/orderBy';
import Member from 'screens/main/components/rightArea/sidebar/teams/listItem/addMember/addMember-view';

const MemberList = (props) => {
  const {
    variant,
    addChipMember,
    removeChipMember,
    data,
    input,
    pressedKey,
    onPressKey,
    checkIsMemberSelected,
  } = props;
  const [cursor, setCursor] = useState(0);

  const orderedMembers = useMemo(() => {
    const filteredData = data.filter((member) =>
      member.mTitle.toLowerCase().includes(input.toLowerCase()),
    );
    return orderBy(filteredData, (member) =>
      typeof member?.mTitle === 'string' ? member.mTitle.toLowerCase() : '',
    );
  }, [data, input]);

  useEffect(() => {
    if (pressedKey === 'ArrowDown') {
      onPressKey();
      if (cursor < orderedMembers.length - 1) {
        setCursor(cursor + 1);
      } else setCursor(orderedMembers.length - 1);
    }
    if (pressedKey === 'ArrowUp') {
      onPressKey();
      if (cursor > 0) {
        setCursor(cursor - 1);
      } else setCursor(0);
    }
    if (pressedKey === 'Enter') {
      const currentMember = orderedMembers[cursor];
      const selected = checkIsMemberSelected(currentMember?.mId);
      if (selected) removeChipMember(currentMember);
      else addChipMember(currentMember);

      onPressKey();
      setCursor(0);
    }
    if (pressedKey === 'Character') setCursor(0);
  }, [
    pressedKey,
    cursor,
    onPressKey,
    addChipMember,
    removeChipMember,
    checkIsMemberSelected,
    orderedMembers,
  ]);

  useEffect(() => {
    const target = document.querySelector('.scrollActive');
    if (target) target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [cursor]);

  return (
    <List disablePadding>
      {orderedMembers.map((member, index) => {
        const focused = cursor === index;
        const selected = checkIsMemberSelected(member.mId);
        return (
          <Member
            key={member.mId}
            id={member.mId}
            name={member.mTitle}
            title={member.mCreatedAt} // TODO: fix this
            image={member.mAvatarUrl}
            avatarVariant={variant}
            onAdd={() => {
              addChipMember(member);
            }}
            onRemove={() => {
              removeChipMember(member);
            }}
            selected={selected}
            focused={focused}
            pressedKey={pressedKey}
          />
        );
      })}
    </List>
  );
};

export default MemberList;
