import styled from '@emotion/styled/macro';
import theme from 'theme/theme';

export const IconButtonWrapper = styled('div')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  right: -48px;
  transition: right 0.2s ease-in-out;
  cursor: pointer;
  background-color: ${theme.palette.dina.surfaceCard};
  display: flex;
  align-items: center;
  padding: 4px;
  :hover {
    transform: translateY(-50%) scale(1.03);
    filter: ${theme.palette.dina.onHover};
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const CardWrapper = styled('div')`
  width: 100%;
  position: relative;
  cursor: default;
  :hover {
    ${IconButtonWrapper} {
      right: 16px;
    }
  }
`;
