import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { EditorContext } from 'globalState';
import UNLOCK_INSTANCE from 'graphql/mutations/unlockInstance';
import useLogger from 'utils/useLogger';

const useLockInstance = () => {
  const logger = useLogger('use unlock instance');
  const [unlockInstanceMutation] = useMutation(UNLOCK_INSTANCE);
  const [, , , setEditorData] = useContext(EditorContext);

  const unlock = async (instanceId, content) => {
    const input = {
      mId: instanceId,
    };

    if (content) input.content = JSON.stringify(content);

    try {
      await unlockInstanceMutation({
        variables: { input },
      }).then(() => {
        setEditorData({
          mId: null,
          mType: null,
          locked: null,
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      logger.log(e);
    }
  };

  return [unlock];
};

export default useLockInstance;
