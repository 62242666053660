import React, { createContext, useState } from 'react';

export const UsersContext = createContext({});

export function UsersProvider({ children }) {
  const [users, setUsers] = useState([]);
  const [unreadConversations, setUnreadConversations] = useState([]);

  const setUsersHandler = (data) => {
    setUsers(data);
  };

  const setUnreadConversationsHandler = (data) => {
    setUnreadConversations(data);
  };
  const getUserTitle = (mId) => {
    const selectedUser = getUser(mId);
    if (selectedUser && selectedUser.mTitle) return selectedUser.mTitle;
    return undefined;
  };

  const getUser = (mId) => {
    const foundUser = users.find((usr) => usr.mId === mId);
    if (foundUser) return foundUser;
    return undefined;
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        setUsers: setUsersHandler,
        getUserTitle,
        getUser,
        unreadConversations,
        setUnreadConversations: setUnreadConversationsHandler,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
