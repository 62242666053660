/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { Button as MaterialButton } from '@material-ui/core';
import { button } from 'theme/styledTypography';
import theme from 'theme/theme';

const buttonType = {
  CONFIRM: 'confirm',
  DEFAULT: 'default',
};

export const Button = styled(MaterialButton)`
  &.MuiButton-root {
    height: 40px;
    width: 220px;
    border-radius: 4px;
    border: ${(props) =>
      props.type !== buttonType.CONFIRM &&
      `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`};
    background-color: ${(props) =>
      props.type === buttonType.CONFIRM
        ? theme.palette.dina.statusError
        : theme.palette.dina.blackHoverOverlay};
    :hover {
      background-color: ${(props) =>
        props.type === buttonType.CONFIRM
          ? theme.palette.dina.statusError
          : theme.palette.dina.whiteHoverOverlay};
    }
    .MuiButton-label {
      ${button}
      justify-content: start;
      text-transform: none;
      color: ${(props) =>
        props.type === buttonType.CONFIRM
          ? theme.palette.dina.highEmphasis
          : theme.palette.dina.mediumEmphasis};
    }
    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 4px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
