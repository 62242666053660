const statusBoard = {
  id: 'status-board-1',
  name: 'Status',
  description: 'DiNA status board',
  defaultView: 'status-normal',
  states: [
    {
      id: 'none',
      name: 'none',
      description: 'No status selected',
      backgroundColor: 'kanbanTodo',
      icon: 'todo',
    },
    {
      id: 'todo',
      name: 'To do',
      description: 'Tasks that are selected for work',
      backgroundColor: 'kanbanTodo',
      icon: 'todo',
    },
    {
      id: '02-in-progress',
      name: 'In Progress',
      description: 'Tasks that are currently being worked upon',
      backgroundColor: 'kanbanInProgress',
      icon: 'inProgress',
    },
    {
      id: '03-in-review',
      name: 'In Review',
      description: 'Tasks that are complete but requires a review',
      backgroundColor: 'kanbanInReview',
      icon: 'inReview',
    },
    {
      id: '04-ready',
      name: 'Ready',
      description: 'Tasks that are ready for publishing',
      backgroundColor: 'kanbanReady',
      icon: 'ready',
    },
    {
      id: '05-published',
      name: 'Published',
      description: 'Tasks that are published',
      backgroundColor: 'kanbanPublished',
      icon: 'published',
    },
    {
      id: 'pending',
      name: 'Pending',
      description: 'Tasks that are pending',
      backgroundColor: 'kanbanPending',
      icon: 'pending',
    },
    {
      id: 'failed',
      name: 'Failed',
      description: 'Tasks that are failed to be published',
      backgroundColor: 'kanbanError',
      icon: 'error',
      opacity: 0.45,
    },
    {
      id: '06-archived',
      name: 'Archived',
      description: 'Tasks that are published',
      backgroundColor: 'surfaceCard',
      icon: 'published',
    },
    {
      id: 'expired',
      name: 'expired',
      description: 'Tasks that are expired',
      backgroundColor: 'surfaceCard',
      icon: 'expired',
    },
  ],
  views: [
    {
      id: 'status-normal',
      name: 'Default status view',
      description: 'Default column view',
      states: [
        { id: 'todo' },
        { id: '02-in-progress' },
        { id: '03-in-review' },
        { id: '04-ready' },
        { id: '05-published' },
        { id: '06-archived' },
        { id: 'failed' },
      ],
    },
    {
      id: 'status-linear',
      name: 'Linear status view',
      description: 'Linear status view',
      states: [
        { id: 'none' },
        { id: 'todo' },
        { id: '02-in-progress' },
        { id: '03-in-review' },
        { id: '04-ready' },
        { id: '05-published' },
        { id: '06-archived' },
      ],
    },
    {
      id: 'status-SoMe',
      name: 'SoMe status view',
      description: 'Social Media Status view',
      states: [
        { id: 'todo' },
        { id: '02-in-progress' },
        { id: '03-in-review' },
        { id: '04-ready' },
        { id: 'pending' },
        { id: '05-published' },
        { id: '06-archived' },
        { id: 'failed' },
        { id: 'expired' },
      ],
    },
  ],
};

export default statusBoard;
