/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/tooltip';
import capitalize from 'utils/capitalize';
import getRelativeDate from 'utils/getRelativeDate';
import { ReactComponent as ScheduleOff } from 'assets/icons/systemicons/storyCard/calendar_ultrasmall_off.svg';
import { ReactComponent as ScheduleOn } from 'assets/icons/systemicons/storyCard/calendar_ultrasmall_on.svg';
import { ReactComponent as BookmarkOff } from 'assets/icons/systemicons/storyCard/bookmark_ultrasmall_off.svg';
import { ReactComponent as BookmarkOn } from 'assets/icons/systemicons/storyCard/bookmark_ultrasmall_on.svg';
import useStyles from './iconButton-styles';

const IconButtonView = ({
  bookmarked,
  publishingAt,
  isHovered,
  selected,
  onBookmarkClick,
  onScheduleClick,
  disableScheduleUpdate,
}) => {
  const classes = useStyles();
  const isSelected = selected && isHovered;

  const iconOnClass = `${classes.button} ${isHovered ? '' : classes.initialOn} ${
    isSelected ? classes.selected : ''
  }`;

  const iconOffClass = `${classes.button} ${isHovered ? classes.hovered : classes.initial} ${
    isSelected ? classes.selected : ''
  }`;

  const handleBookmarkClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onBookmarkClick('bookmark');
  };

  const handleScheduleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    !disableScheduleUpdate && onScheduleClick(event);
  };

  const publish = capitalize(getRelativeDate(publishingAt, 'ddd. D MMM. YYYY'));

  const BookmarkSrc = bookmarked ? (
    <BookmarkOn className={iconOnClass} onClick={handleBookmarkClick} />
  ) : (
    <BookmarkOff className={iconOffClass} onClick={handleBookmarkClick} />
  );
  const ScheduleSrc = publishingAt ? (
    <Tooltip title={publish}>
      <ScheduleOn
        className={iconOnClass}
        style={{ marginTop: '-4px' }}
        onClick={handleScheduleClick}
      />
    </Tooltip>
  ) : (
    <ScheduleOff
      className={iconOffClass}
      style={{ marginTop: '-4px' }}
      onClick={handleScheduleClick}
    />
  );
  return (
    <div className={classes.bookmarkSchedule}>
      {BookmarkSrc}
      {ScheduleSrc}
    </div>
  );
};

IconButtonView.propTypes = {
  /** Story bookmarked or not: Boolean */
  bookmarked: PropTypes.bool,
  /** publishingAt time applied */
  publishingAt: PropTypes.string,
  /** story card hovered or not */
  isHovered: PropTypes.bool,
  /** story currently selected or not */
  selected: PropTypes.bool,
  /** callback function for bookmark */
  onBookmarkClick: PropTypes.func,
  /** callback function for scheduling */
  onScheduleClick: PropTypes.func,
};

IconButtonView.defaultProps = {
  bookmarked: false,
  publishingAt: null,
  isHovered: false,
  selected: false,
  onBookmarkClick: () => {},
  onScheduleClick: () => {},
};

export default IconButtonView;
