import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useSearchBar from 'hooks/useSearchBar';
import memberTypes from 'graphql/memberTypes';
import InstanceContainer from './instances';
import Rundown from './rundown';
import Stories from './stories';

const Sidebar = ({ selectionType }) => {
  const {
    filterModel,
    updateFilterModel,
    history,
    updateHistory,
    currentSearch,
    updateCurrentSearch,
    assignedMembers,
    searchString,
    platforms,
    instanceAssignedMembers,
  } = useSearchBar({ historyLimit: 5 });
  if (selectionType === memberTypes.RUNDOWN_USER) {
    return <Rundown />;
  }
  if (selectionType === memberTypes.INSTANCE) {
    return (
      <InstanceContainer
        filterModel={filterModel}
        updateFilterModel={updateFilterModel}
        history={history}
        updateHistory={updateHistory}
        currentSearch={currentSearch}
        updateCurrentSearch={updateCurrentSearch}
        searchString={searchString}
        platforms={platforms}
        assignedMembers={instanceAssignedMembers}
      />
    );
  }
  return (
    <Stories
      selectionType={selectionType}
      filterModel={filterModel}
      updateFilterModel={updateFilterModel}
      history={history}
      updateHistory={updateHistory}
      currentSearch={currentSearch}
      updateCurrentSearch={updateCurrentSearch}
      assignedMembers={assignedMembers}
      searchString={searchString}
    />
  );
};

Sidebar.propTypes = {
  /** type of data that should be shown in the sidebar list */
  selectionType: PropTypes.string,
};

Sidebar.defaultProps = {
  selectionType: '',
};

export default memo(Sidebar);
