import conversationTypes from 'utils/constants/conversationTypes';
import getUsersConversationData from './getUsersConversationData';
import AvatarCell from '../components/avatarCell';

const assemblePeopleData = (users, userId, conversations = []) =>
  users
    .filter(({ mId }) => userId !== mId)
    .map(({ mId, mTitle, mAvatarUrl }) => {
      const userData = {
        rowId: mId,
        mId,
        name: AvatarCell({ mTitle, mAvatarUrl }),
        mTitle,
        mAvatarUrl,
        convoType: conversationTypes.DIRECT,
        type: 'Person',
      };
      const conversationData = getUsersConversationData(conversations, mId);
      return { ...userData, ...conversationData };
    });
// .sort((a, b) => a.mTitle.toLowerCase() - b.mTitle.toLowerCase());

export default assemblePeopleData;
