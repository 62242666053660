import React, { useState, useEffect, useCallback, useRef } from 'react';

import useCheckUserRight from 'hooks/useCheckUserRight';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import memberTypes from 'graphql/memberTypes';

import Popper from '@material-ui/core/Popper';

import { ReactComponent as ChatResting } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import { ReactComponent as ChatSelected } from 'assets/icons/systemicons/HeaderNavbar/chat_selected.svg';

import NotificationIndicator from 'components/statusIndicators/notification/Notification';
import ResizableDialog from 'components/resizableDialog';
import Alert from '../alert';
import MessageHub from './messageHub-view';

import {
  StyledNotificationIcon,
  StyledChatEnabled,
  NotificationIndicatorWrapper,
  MessagesWrapper,
  StyledPopper,
} from './messageHub-styled';

const MessageHubContainer = (props) => {
  const { messages, anchorEl } = props;
  const [isMessageHubOpen, setIsMessageHubOpen] = useState(false);
  const [chatNotified, setChatNotified] = useState(0);
  const [newChatNotifications, setNewChatNotifications] = useState([]);
  const selectedConversationIdRef = useRef(null);
  const chatRef = useRef(null);
  const [checkUserRight] = useCheckUserRight();
  const [updateConvoReadAt] = useUpdateConvoReadAt();
  const canUseChat = checkUserRight('chat', 'access');

  const removeConversationNotification = useCallback(() => {
    const filteredNotifications = newChatNotifications.filter(
      ({ mType }) => mType !== memberTypes.CONVERSATION,
    );
    setNewChatNotifications([...filteredNotifications]);
  }, [newChatNotifications]);

  const removeMessageNotification = useCallback(
    (conversationId, storyId) => {
      const filteredNotifications = storyId
        ? newChatNotifications.filter(({ mStoryId }) => mStoryId !== storyId)
        : newChatNotifications.filter(({ mId }) => mId !== conversationId);
      setNewChatNotifications([...filteredNotifications]);
    },
    [newChatNotifications],
  );

  const onMessagePopoverClose = () => {
    if (selectedConversationIdRef.current) {
      updateConvoReadAt(selectedConversationIdRef.current);
    }
    removeConversationNotification();
    setIsMessageHubOpen(false);
    selectedConversationIdRef.current = null;
  };

  const onMessagePopoverOpen = () => {
    removeConversationNotification();
    setIsMessageHubOpen(true);
  };

  useEffect(() => {
    if (messages.length > chatNotified) {
      const numOfMessagesToBeNotified = messages.length - chatNotified;
      const latestMessages = messages.slice(-numOfMessagesToBeNotified);
      setChatNotified(latestMessages.length + chatNotified);
      setNewChatNotifications([...latestMessages, ...newChatNotifications]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    window.onbeforeunload = async (e) => {
      e.preventDefault();
      if (selectedConversationIdRef.current) {
        updateConvoReadAt(selectedConversationIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationIdRef.current]);

  return (
    <>
      {canUseChat && (
        <>
          <StyledNotificationIcon>
            <StyledChatEnabled ref={chatRef} title="Message Hub" onClick={onMessagePopoverOpen}>
              {isMessageHubOpen ? <ChatSelected /> : <ChatResting />}
            </StyledChatEnabled>
            {newChatNotifications.length > 0 ? (
              <NotificationIndicatorWrapper>
                <NotificationIndicator notificationCount={newChatNotifications.length} />
              </NotificationIndicatorWrapper>
            ) : (
              <></>
            )}
          </StyledNotificationIcon>
          {isMessageHubOpen ? (
            <ResizableDialog
              open
              onClose={onMessagePopoverClose}
              initialWidth={944}
              initialHeight={800}
              minWidth={840}
              minHeight={640}
              initialPosition={{ x: document.body.scrollWidth - 960, y: 56 }}
            >
              <MessagesWrapper>
                <MessageHub
                  onClose={onMessagePopoverClose}
                  removeMessageNotification={removeMessageNotification}
                  notifications={newChatNotifications}
                  selectedConversationIdRef={selectedConversationIdRef}
                />
              </MessagesWrapper>
            </ResizableDialog>
          ) : (
            <Popper anchorEl={anchorEl} open disablePortal placement="bottom-end">
              <StyledPopper>
                <Alert
                  notifications={newChatNotifications}
                  onOpenMessageHub={onMessagePopoverOpen}
                  removeNotificationMessageCount={removeMessageNotification}
                  selectedConversationIdRef={selectedConversationIdRef}
                />
              </StyledPopper>
            </Popper>
          )}
        </>
      )}
    </>
  );
};

export default MessageHubContainer;
