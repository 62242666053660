import { actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import normalizeAssetData from 'utils/normalizeAssetData';
import normalizeIntoBlockData from 'components/editor/utils/normalizeIntoBlockData';
import { ReactEditor } from 'slate-react';
import notifyChange from 'components/editor/utils/notifyChange';

const assetTypes = new Set(['image', 'video', 'placeholder']);

const getElementType = (element, type, addThumbnail) => {
  const { type: elemType } = element;
  if (addThumbnail || !assetTypes.has(elemType) || !type) return elemType;
  return type;
};

/**
 * Adds clip media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

// eslint-disable-next-line consistent-return
const addMedia = async (editor, element, payload, update, addThumbnail = false) => {
  const { data } = element;
  const assetPayload = normalizeAssetData(payload);

  let returnData;

  try {
    const result = await update({
      type: actionTypes.CREATE_ASSET,
      payload: { document: editor.children, asset: assetPayload },
    });

    const asset = result.data.createAssets[0];
    if (asset) {
      const isAssetPlaceholder = !!asset.mediaType?.includes('placeholder');
      const type = isAssetPlaceholder ? 'placeholder' : asset.itemType;

      const blockData = normalizeIntoBlockData({ ...payload, ...asset });

      if (addThumbnail) returnData = blockData;

      const updatedData = addThumbnail
        ? { ...data, thumbnails: [blockData] }
        : { ...data, ...blockData };

      const path = ReactEditor.findPath(editor, element);
      const elementType = getElementType(element, type, addThumbnail);
      Transforms.setNodes(editor, { type: elementType, data: updatedData }, { at: path });
      notifyChange(editor, update);
      ReactEditor.focus(editor);
    }
  } catch (error) {
    // console.log(error)
  }
  if (addThumbnail) return returnData;
};

export default addMedia;
