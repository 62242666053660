import React from 'react';
import { create } from 'jss';
import JssProvider from 'react-jss/lib/JssProvider';
// import { createAppSyncLink } from 'aws-appsync';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createGenerateClassName, jssPreset } from '@material-ui/styles';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
// import { createHttpLink } from 'apollo-link-http';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  HttpLink,
  defaultDataIdFromObject,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import ErrorBoundary from 'components/errorBoundary';
import resolveInstanceCache from 'graphql/localResolvers/resolveInstanceCache';
import resolveRundownInstancesCache from 'graphql/localResolvers/resolveRundownInstancesCache';
import { ContextProvider } from 'globalState/state';

import theme from 'theme/theme';
import AppScreen from 'screens/screens-container';
import { getAuthConfiguration } from 'utils/Auth';
import { getCoverPhotoUrl, getThumbnailUrl } from 'graphql/localResolvers/urlResolvers';
import { AuthProvider, AuthConsumer } from './contexts/AuthContext';

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const amplifyConfig = {
  ...getAuthConfiguration(),
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_AWS_APPSYNC_REGION,
    },
  },
};

Amplify.configure(amplifyConfig);

const endpoint = process.env.REACT_APP_API_ENDPOINT;
const region = process.env.REACT_APP_AWS_APPSYNC_REGION;

const getUserInfo = async () => {
  const user = await Auth.currentAuthenticatedUser();
  let sub = null;
  let claims = [];
  if (user) {
    const token = user.getSignInUserSession().getIdToken();
    claims = token.payload['cognito:groups'] || [];
    sub = token.payload['custom:subalias'] || token.payload.sub;
  }

  return {
    sub,
    claims,
  };
};

const resolvers = {
  Query: {
    instance: resolveInstanceCache,
    rundownInstances: resolveRundownInstancesCache,
  },
  MemberType: {
    mThumbnailUrl: getThumbnailUrl,
    mAvatarUrl: getCoverPhotoUrl,
    mContent: (parent, args, { client: cl }) => null,
  },
  Rundown: {
    mThumbnailUrl: getThumbnailUrl,
    mCoverPhotoUrl: getCoverPhotoUrl,
  },
};

const auth = {
  type: 'AWS_IAM', // TODO: Read it from config
  credentials: () => Auth.currentCredentials(),
};

const httpLink = new HttpLink({
  uri: endpoint,
});

const apolloLink = ApolloLink.from([
  createAuthLink({ url: endpoint, region, auth }),
  setContext(async (request, previousContext) => {
    const { sub, claims } = await getUserInfo();
    return {
      headers: {
        ...previousContext.headers,
        claims,
        sub,
      },
    };
  }),
  split(
    (op) => {
      const { operation } = op.query.definitions[0];

      if (operation === 'subscription') {
        return false;
      }

      return true;
    },
    httpLink,
    createSubscriptionHandshakeLink(
      {
        auth,
        region,
        url: endpoint,
      },
      httpLink,
    ),
  ),
]);

const possibleTypes = {
  mProperties: [
    'AutomationTemplateConfigType',
    'ContactType',
    'ScheduleEntryProperties',
    'GroupPolicy',
    'NoteProperties',
    'MetadataFormType',
    'PlatformType',
    'InstanceConfigType',
    'InstanceStateConfigType',
    'RundownStateType',
  ],
};

const typePolicies = {
  MemberType: {
    fields: {
      mProperties: {
        merge: true,
      },
    },
  },
  Query: {
    fields: {
      searchApi: {
        keyArgs: false,
        merge: (existing, incoming) => {
          const items = existing ? [...existing.items] : [];
          const incomingItems = incoming ? [...incoming.items] : [];
          const merged = [...items, ...incomingItems];
          return {
            nextToken: incoming?.nextToken,
            items: merged,
          };
        },
      },
    },
  },
};

const client = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache({
    dataIdFromObject: (obj) => {
      const { mId, mRefId, __typename, provider } = obj;
      if (!mId) return defaultDataIdFromObject(obj);
      if (__typename === 'FeedItem') return `${provider}:${mId}`;
      return mId !== mRefId ? `${mId}:${mRefId}` : mId;
    },
    possibleTypes,
    // typePolicies,
  }),
  typePolicies,
  resolvers,
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(lastFetchPolicy) {
        if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
          return 'cache-first';
        }
        return lastFetchPolicy;
      },
    },
  },
});

const App = () => (
  <ErrorBoundary apolloClient={client}>
    <ContextProvider>
      <AuthProvider>
        <ApolloProvider client={client}>
          <JssProvider jss={jss} generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AuthConsumer>{(context) => <AppScreen context={context} />}</AuthConsumer>
            </ThemeProvider>
          </JssProvider>
        </ApolloProvider>
      </AuthProvider>
    </ContextProvider>
  </ErrorBoundary>
);

export default App;
