/* eslint-disable react/react-in-jsx-scope */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import listComponents from './constants/listComponents';
import DragAndDrop from '../dragAndDrop';

const listStyle = css`
  margin: 0;
`;

const List = ({ attributes, children, element }) => {
  const ListComponent = useMemo(() => listComponents[element.type], [element.type]);

  return (
    <DragAndDrop element={element} isDisabled>
      <ListComponent css={listStyle} {...attributes}>
        {children}
      </ListComponent>
    </DragAndDrop>
  );
};

List.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

List.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.LIST_ITEM,
    children: [],
  },
};

export default memo(List);
