/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useRightHidden, useRightExpanded, useRightSelection, useContactsTab } from 'store/sidebar';

import { StyledDrawer as Drawer } from './rightArea-styled';
import Sidebar from './sidebar';
import VerticalNavbar, { navbarPosition } from '../verticalNavbar/index';

const RightArea = ({ hasTitle, rightPanelWidth, isTickerVisible }) => {
  const [rightHidden, setRightHidden] = useRightHidden();
  const [rightExpanded, setRightExpanded] = useRightExpanded();
  const [rightSelection, setRightSelection] = useRightSelection();
  const [contactsTab, setContactsTab] = useContactsTab();

  const toggleRightSidebar = () => {
    setRightHidden(!rightHidden);
  };

  const drawerWidth = useMemo(
    () => (rightExpanded ? window.innerWidth / 2 : rightPanelWidth),
    [rightExpanded, rightPanelWidth],
  );

  const handleSelectionChanged = useCallback((val) => {
    setRightSelection(val);
    if (val !== 'storybox' || val !== 'graphicsbox') {
      setRightExpanded(false);
    }
  }, []);

  const handleContactTabChange = useCallback((val) => {
    if (val === undefined) return;
    setContactsTab(val);
  }, []);

  const setRightSidebarExpand = useCallback((val) => {
    setRightExpanded(val);
  }, []);

  return (
    <>
      <Drawer
        transitionDuration={{ enter: 300, exit: 300 }}
        variant="persistent"
        anchor="right"
        open={!rightHidden}
        width={drawerWidth}
        hasTitle={hasTitle}
        $isTickerVisible={isTickerVisible}
      >
        <Sidebar
          selectionType={rightSelection}
          setExpand={setRightSidebarExpand}
          contactTab={contactsTab}
          isRightExpanded={rightExpanded}
          handleContactTabChange={handleContactTabChange}
        />
      </Drawer>
      <VerticalNavbar
        selectionType={rightSelection}
        toggleSidebar={toggleRightSidebar}
        selectionChanged={handleSelectionChanged}
        hidden={rightHidden}
        hasTitle={hasTitle}
        position={navbarPosition.RIGHT}
      />
    </>
  );
};

RightArea.propTypes = {
  /** if the sidebar has a title  */
  hasTitle: PropTypes.bool.isRequired,
  /** width of right panel */
  rightPanelWidth: PropTypes.number.isRequired,
};

export default RightArea;
