import React from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import DropZone from 'components/editor/components/dropZone';
import { mediaTypes } from 'utils/rundownItemTypes';
import addMedia from 'components/editor/components/video/utils/addMedia';

const { CLIP } = mediaTypes;

const MediaDropZone = ({
  children,
  element,
  placeholderType,
  canAddThumbnail,
  mergeNewThumbnail = () => {},
}) => {
  const editor = useSlate();
  const { update } = useEditorContext();

  const onDrop = async ({ type, payload }) => {
    if (type === CLIP && payload && payload.itemType) {
      if (canAddThumbnail && placeholderType === 'video' && payload.itemType === 'image') {
        await addMedia(editor, element, payload, update, true).then((r) => {
          if (!r.loading) mergeNewThumbnail(r);
        });
      }
    }
  };

  return (
    <DropZone accept={[CLIP]} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

MediaDropZone.propTypes = {
  canAddThumbnail: PropTypes.bool,
  mergeNewThumbnail: PropTypes.func,
};

MediaDropZone.defaultProps = {
  canAddThumbnail: true,
  mergeNewThumbnail: () => {},
};

export default MediaDropZone;
