import { memo } from 'react';
import styled from '@emotion/styled/macro';
import { Button as MaterialButton } from '@material-ui/core';
import theme from 'theme/theme';

const MemoizedButton = memo(MaterialButton);

export const Button = styled(MemoizedButton)`
  &.MuiButton-root {
    ${({ height, width }) => ({
      minHeight: `${height}px`,
      maxHeight: `${height}px`,
      minWidth: `${width}px`,
      maxWidth: `${width}px`,
    })}
    padding: 0px;
    :hover {
      & * {
        fill: ${theme.palette.dina.iconActive};
        fill-opacity: 1;
      }
    }
  }
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;
