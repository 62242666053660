import React, { useState, memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { PreviewWrapper } from './styled';

const Preview = <PreviewWrapper>Preview</PreviewWrapper>;

const Tweet = ({ tweetId }) => {
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setShowPreview(false);
  }, [tweetId]);

  const onLoad = useCallback((element) => {
    setShowPreview(!element);
  }, []);

  if (!tweetId || showPreview) return Preview;

  return (
    <TwitterTweetEmbed
      tweetId={tweetId}
      key={tweetId}
      options={{ align: 'center' }}
      onLoad={onLoad}
      placeholder={Preview}
    />
  );
};

Tweet.propTypes = {
  /** id of the tweet */
  tweetId: PropTypes.string,
};
Tweet.defaultProps = {
  tweetId: null,
};

export default memo(Tweet);
