import React, { useMemo, useContext, useState, useCallback, useRef, useEffect } from 'react';

import { SidebarSubTabContext } from 'globalState';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { useLeftSidebarSavedSearches, useSidebarDatePickerAtom } from 'store/sidebar';

import Divider from 'components/divider';
import Searchbar from 'components/searchbarV2';
import Tabs from 'components/tabs';
import Scrollbar from 'components/scrollbar';
import { Typography, Switch, Grid } from '@material-ui/core';
import DatePickerButton from '../stories/datePickerButton';
import InstanceListContainer from './list';
import useStyles from './instances-styles';

const InstanceContainer = ({
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  searchString,
  assignedMembers,
  platforms,
}) => {
  const classes = useStyles();
  const previewAnchorRef = useRef(null);

  const [selectedDates, setSelectedDates] = useSidebarDatePickerAtom();

  const [{ subTabs, selectedSubTab, setSelectedSubTab, scheduleType }] =
    useContext(SidebarSubTabContext);
  const [leftSidebarSavedSearches, setLeftSidebarSavedSearches] = useLeftSidebarSavedSearches();

  const [showMyItemsOnly, setShowMyItemsOnly] = useState(false);

  const [platformsData, setPlatformsData] = useState();
  const [data, error, loading] = useGetPlatforms(new Date());

  useEffect(() => {
    if (!loading && !error) setPlatformsData(data);
  }, [data, loading, error]);

  const handleCreateNewFilter = (newFilter) => {
    setLeftSidebarSavedSearches([...leftSidebarSavedSearches, newFilter]);
  };

  const handleUpdateFilters = (newSavedFilters) => {
    setLeftSidebarSavedSearches(newSavedFilters);
  };

  const onDateChanged = useCallback(
    (e) => {
      setSelectedDates(e);
    },
    [setSelectedDates],
  );

  const memoizedDateTimePicker = useMemo(
    () => <DatePickerButton onSelectedDateChange={onDateChanged} />,
    [onDateChanged],
  );

  const tabIndex = selectedSubTab;

  const onTabChange = (value, index) => {
    setSelectedSubTab(index);
  };

  const tabs = subTabs.map((subTab) => ({
    label: subTab,
  }));

  return (
    <div className={classes.container}>
      <Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          classes={{ root: classes.sidebarHeader }}
          ref={previewAnchorRef}
        >
          <Grid item>
            <Grid
              classes={{ root: classes.headerTextWrapper }}
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography className={classes.sidebarHeaderText}>Instances</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Typography classes={{ root: classes.label }}>My items only</Typography>
              <Switch
                checked={showMyItemsOnly}
                onChange={(event) => setShowMyItemsOnly(event.target.checked)}
                classes={{
                  thumb: classes.toggleThumb,
                  colorPrimary: classes.primary,
                  checked: classes.checked,
                  track: classes.track,
                }}
                color="primary"
                name="showMyItemsOnly"
                inputProps={{ 'aria-label': 'showMyItemsOnly' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <>
        <Tabs variant="default" tabWidth="scrollable" {...{ tabs, tabIndex, onTabChange }} />
        <div className={classes.divider}>
          <Divider />
        </div>
      </>
      <div className={classes.datepickerContainer}>{memoizedDateTimePicker}</div>
      <Scrollbar top={5} bottom={5}>
        <InstanceListContainer
          selectedDates={selectedDates}
          scheduleType={scheduleType}
          showMyItemsOnly={showMyItemsOnly}
          searchString={searchString}
          assignedMembers={assignedMembers}
          platforms={platforms}
          ref={previewAnchorRef}
          isArchivedTabSelected={subTabs[selectedSubTab] === 'archived'}
        />
      </Scrollbar>
      <Searchbar
        chipTypes={['user', 'platform']}
        savedFilters={leftSidebarSavedSearches}
        onCreateFilter={handleCreateNewFilter}
        onUpdateFilters={handleUpdateFilters}
        platforms={platformsData}
        {...{
          filterModel,
          updateFilterModel,
          history,
          updateHistory,
          currentSearch,
          updateCurrentSearch,
        }}
      />
    </div>
  );
};

export default InstanceContainer;
