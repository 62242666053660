/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Group from 'components/listGroup';
import UserCtx from 'contexts/UserContext';
import image from 'assets/images/default/defaultThumbnail.png';
import { useQuery } from '@apollo/client';
import useLogger from 'utils/useLogger';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { useAddContentTab } from 'store/tabs';
import memberTypes from 'graphql/memberTypes';
import GET_RUNDOWNS from 'graphql/queries/getRundowns';
import Popover from 'components/popover/Popover';
import CreateRundown from 'components/createNew';
import useCreateMasterRundown from 'hooks/useCreateMasterRundown';
import { sortRundownsByStartTime } from 'utils/rundown/sortRundowns';
import RundownListItem from '../listItem';

const GroupContainer = (props) => {
  const { title, selectedDates, setPreviewRundown } = props;
  const user = useContext(UserCtx);
  const { mId: userId } = user;
  const logger = useLogger('left area/sidebar/rundown/list/listgroup/group container');
  const [anchorEl, setAnchorEl] = useState(null);
  const addContentTab = useAddContentTab();

  const query = GET_RUNDOWNS;
  const variables = { mId: '' };
  const [createMasterRundown] = useCreateMasterRundown();

  const { data, error, loading } = useQuery(query, {
    variables,
  });

  if (error) return <div>{`Error: ${error.message}`}</div>;

  if (data) {
    const { getRundowns } = data;

    const onCreateMasterRundown = async (rundownTitle) => {
      try {
        setAnchorEl(null);
        const newRundown = await createMasterRundown(rundownTitle, userId);

        if (!newRundown) return;

        const { mId: id, mTitle, mType, mPublishingAt } = newRundown;
        addContentTab({
          type: memberTypes.RUNDOWN,
          title: mTitle,
          id,
          mid: id,
          selectedDate: mPublishingAt,
          mType,
        });
      } catch (e) {
        logger.log(e);
      }
    };

    const sortedRundowns = sortRundownsByStartTime([...getRundowns]);

    return (
      <>
        <Group
          title={title}
          type="left"
          handleCreateClicked={(e) => setAnchorEl(e.currentTarget.parentNode)}
          toolTipTitle="Create Master Rundown"
          id="masterrundown"
        >
          <List disablePadding>
            {sortedRundowns.map(
              (rundown) =>
                rundown && (
                  <RundownListItem
                    key={rundown.mId}
                    title={rundown.mTitle}
                    image={rundown.mThumbnailUrl || image}
                    startTime={(rundown.recurrence && rundown.recurrence.startTime) || '00:00:00'}
                    timeZone={rundown.recurrence && rundown.recurrence.timeZone}
                    plan={rundown.recurrence.duration}
                    difference={rundown.difference}
                    id={rundown.mId}
                    rundowntype={rundown.mType}
                    selectedDates={selectedDates}
                    setPreviewRundown={setPreviewRundown}
                  />
                ),
            )}
          </List>
        </Group>
        <Popover onClose={() => setAnchorEl(null)} anchorEl={anchorEl} position="right">
          <CreateRundown
            variant="masterRundown"
            onCancel={() => setAnchorEl(null)}
            onCreate={onCreateMasterRundown}
          />
        </Popover>
      </>
    );
  }

  if (loading) return <LoadingIndicator />;

  return null;
};

GroupContainer.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** selected dates */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** set the preview rundown */
  setPreviewRundown: PropTypes.func,
};

GroupContainer.defaultProps = {
  title: 'Group',
  selectedDates: [],
  setPreviewRundown: () => {},
};

export default GroupContainer;
