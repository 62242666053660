/* eslint-disable import/prefer-default-export */
import { atom, useAtom } from 'jotai';

/** Uploading files array, temp storage of file under upload so we can show a spinner */
const uploadingFilesAtom = atom([]);

const uploadingItemsAtom = atom(
  (get) => get(uploadingFilesAtom),
  (get, set, { storyId, mediaType, fileName }) => {
    const uploadingArray = get(uploadingFilesAtom);

    const filtered = uploadingArray.filter((i) => i.fileName !== fileName);
    if (filtered.length === uploadingArray.length) filtered.push({ storyId, mediaType, fileName });

    set(uploadingFilesAtom, filtered);
  },
);

export const useUploadingFiles = () => useAtom(uploadingItemsAtom);
