import { distanceInWordsStrict } from 'date-fns';

const dateStringAbbreviation = {
  year: 'yr',
  month: 'mo',
  hour: 'hr',
  minute: 'min',
  second: 'sec',
};

const dateStringRegex = /year|month|hour|minute|second/gi;

const distanceInAbbreviatedWords = (oldDate) =>
  distanceInWordsStrict(oldDate, new Date()).replace(
    dateStringRegex,
    (matched) => dateStringAbbreviation[matched],
  );

export default distanceInAbbreviatedWords;
