import gql from 'graphql-tag';

export default gql`
  query GetRundown($input: GetRundownInput) {
    getRundown(input: $input) {
      mId
      mRefId
      mOrder
      mPreorder
      mRefId
      mTitle
      mType
      mState
      mPreparingState
      startTime
      duration
      mPlannedDuration
      mPrePlannedDuration
      mPublishingAt
      mCoverPhotoUrl
      mCoverPhotoKey
      mThumbnailUrl
      mThumbnailKey
      mRundownTemplateId
      mProperties {
        __typename
        ... on RundownStateType {
          ready {
            state
            startTime
            stopTime
            currentInstance {
              mId
              startTime
            }
          }
          preparing {
            state
            startTime
            stopTime
            currentInstance {
              mId
              startTime
            }
          }
        }
      }
      version
      recurrence {
        duration
        startTime
        timeZone
        dailyExclusive
      }
      mMetaData {
        key
        value
      }
      mSyncProviders {
        provider
        mMetaData {
          key
          value
        }
      }
    }
  }
`;
