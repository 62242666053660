import React, { useState, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import useStorageImage from 'hooks/useStorageImage';
import DeleteIcon from '@material-ui/icons/Delete';
import { uploadToS3 } from 'utils/s3Utils';
import UserCtx from 'contexts/UserContext';
import MenuCreator from 'screens/main/components/rightArea/menuCreator/MenuCreator';
import Dialog from 'components/dialog';
import ConfirmDialog from 'components/confirmDialog';
import useDeleteContact from 'hooks/useDeleteContact';
import useUserUtils from 'hooks/useUserUtils';
import useCheckUserRight from 'hooks/useCheckUserRight';
import DetailView from './detail-view';

const DetailContainer = ({ showMaster, selectedItem, contactTab, ...rest }) => {
  const [showTopMenu, setShowTopMenu] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const client = useApolloClient();
  const [checkUserRight] = useCheckUserRight();
  const allowDeleteUser = checkUserRight('feature', 'disable-users');
  const user = useContext(UserCtx);
  const currentUserId = user.mId;

  const getDisabled = () => {
    if (!allowDeleteUser && contactTab === 0) return true;
    if (currentUserId === selectedItem.id) return true;
    return false;
  };

  const getTopRowOptions = () => {
    const ret = [];

    switch (contactTab) {
      case 0:
        ret.push({
          label: `Disable User...`,
          action: 'disableUser',
          disabled: getDisabled(),
          Icon: DeleteIcon,
        });
        break;
      case 1:
        ret.push({
          label: `Delete Contact...`,
          action: 'deleteContact',
          disabled: getDisabled(),
          Icon: DeleteIcon,
        });
        break;
      default:
    }

    return ret;
  };

  const { image, imageKey: imageStoragePath } = selectedItem;
  const { data: imageSrc } = useStorageImage(imageStoragePath, !!image);

  const updateAvatar = async (imageFile, imageUrl) => {
    try {
      uploadToS3(imageStoragePath, imageFile);

      client.cache.modify({
        id: client.cache.identify(selectedItem.id),
        fields: {
          mAvatarUrl: () => imageUrl,
        },
      });
    } catch (e) {
      // console.log(e);
    }
  };

  const [deleteContact] = useDeleteContact();
  const [disableUser] = useUserUtils();

  const onDeleteContact = async (id) => {
    switch (contactTab) {
      case 0:
        disableUser(id);
        break;
      default:
        await deleteContact(id);
    }

    showMaster();
  };

  const getDialogTekst = (type) => {
    switch (type) {
      case 'title':
        if (contactTab === 0) return 'User';
        return 'Contact';
      case 'confirmButtonLabel':
        if (contactTab === 0) return 'Disable';
        return 'Delete';
      case 'info':
        if (contactTab === 0) return `"${selectedItem.name}" will be disabled.`;
        return `"${selectedItem.name}" will be deleted.`;
      default:
        return '';
    }
  };

  return (
    <>
      <DetailView
        {...rest}
        {...{ showMaster, contactTab, updateAvatar, selectedItem, user, showMenu: true }}
        avatarUrl={imageSrc || image}
        onMenuButtonClick={(event) => {
          setShowTopMenu(event.currentTarget);
        }}
      />
      <>
        <MenuCreator
          onClose={({ action }) => {
            if (action) {
              if (action === 'deleteContact' || action === 'disableUser') setDeleteDialogOpen(true);
            }
            setShowTopMenu(null);
          }}
          anchorEl={showTopMenu}
          menuOptions={getTopRowOptions()}
          position="right-top"
        />
        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
          <ConfirmDialog
            onCancel={() => setDeleteDialogOpen(false)}
            onConfirm={() => {
              onDeleteContact(selectedItem.id);
            }}
            title={getDialogTekst('title')}
            confirmButtonLabel={getDialogTekst('confirmButtonLabel')}
            confirmButtonType="danger"
            info={getDialogTekst('info')}
          />
        </Dialog>
      </>
    </>
  );
};

export default DetailContainer;
