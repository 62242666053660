const props = {
  input: {
    image: {
      placeholder: 'Add Photographer...',
      description: 'Who is the photographer?',
      propName: 'photographer',
    },
    video: {
      placeholder: 'Add Editor...',
      description: 'Who is the editor?',
      propName: 'editor',
    },
  },
  textArea: {
    image: {
      placeholder: 'Add Caption...',
      description: 'Describe the photo',
      propName: 'caption',
    },
    video: {
      placeholder: 'Add Caption...',
      description: 'Describe the video',
      propName: 'description',
    },
  },
  title: {
    image: {
      placeholder: 'Add Title',
      description: `Add a Title to the photo`,
      propName: 'title',
    },
    video: {
      placeholder: 'Add Title',
      description: `Add a Title to the video`,
      propName: 'title',
    },
  },
};

const getInputProps = (type, photographer = '', editors = '') => {
  const getValue = () => {
    if (type === 'image') return photographer;
    if (type === 'video') return editors;
    return '';
  };

  return { value: getValue(), ...props.input[type] };
};

const getTextAreaProps = (type, caption = '', description = '') => {
  const getValue = () => {
    if (type === 'image') return caption;
    if (type === 'video') return description;
    return '';
  };

  return { value: getValue(), ...props.textArea[type] };
};

const getTitleProps = (type, title = '') => ({ value: title, ...props.title[type] });

export { getInputProps, getTextAreaProps, getTitleProps };
