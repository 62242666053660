import React, { memo } from 'react';
import styled from '@emotion/styled';
import theme from 'theme/theme';
import { onImageLoadingError } from 'utils/image/imageUtils';
import { getThumbnailKey } from 'utils/mediaKey';
import useGetSignedUrl from 'hooks/useGetSignedUrl';

const Image = styled('img')`
  border: 1px solid ${theme.palette.dina.buttonBorderOutlined};
  border-radius: 4px;
  min-height: 66px;
  min-width: 66px;
  object-fit: cover;
`;

const GalleryItemImage = ({ mId, mRefId, proxy = '' }) => {
  const key = getThumbnailKey(mId, mRefId);
  const { url: imageData, loading } = useGetSignedUrl(key, !key);

  const imageSrc = key ? imageData : proxy;

  return (
    <Image
      height="66px"
      width="66px"
      src={loading ? '' : imageSrc}
      alt="alternative"
      onError={onImageLoadingError}
    />
  );
};

export default memo(GalleryItemImage);
