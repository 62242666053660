import groupPoliciesConfig from 'config/permissions.json';

const { policies: policiesConfig } = groupPoliciesConfig;

const getGroupPermissions = (groupPolicy, selectedLeftMenu) => {
  const permissionRows = [];
  policiesConfig.forEach((policy, indexFirst) => {
    const { permissions, resourceName } = policy;
    permissions.forEach((permission, indexSecond) => {
      const { description, action } = permission;
      const access = getResourcePermission(groupPolicy, action, resourceName);
      permissionRows.push({
        permission: description,
        resourceName,
        id: `${selectedLeftMenu}-${indexFirst}${indexSecond}`,
        action,
        access,
      });
    });
  });
  return permissionRows;
};

const getResourcePermission = (groupPolicy, action, resourceName) => {
  const { policies } = groupPolicy?.mProperties;
  const resource = policies?.find((policy) => policy.resourceName === resourceName);
  if (!resource) return true;
  const { permissions } = resource;
  const matchedPermission = permissions?.find((permission) => permission.action === action);
  if (!matchedPermission) return true;
  if (matchedPermission?.access === 'deny') return false;
  return true;
};

export default getGroupPermissions;
