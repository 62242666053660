import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useCurrentTabValue } from 'store/tabs';

import IconTabView from './iconTab-view';

const IconTabContainer = (props) => {
  const {
    breakingFeedNotifications,
    clearNotificationCount,
    tab: { type, title },
    ...rest
  } = props;

  const currentTab = useCurrentTabValue();
  const active = useMemo(() => type === currentTab.type, [type, currentTab.type]);

  return (
    <IconTabView
      key={type}
      active={active}
      type={type}
      title={title}
      notificationCount={breakingFeedNotifications}
      clearNotificationCount={clearNotificationCount}
      {...rest}
    />
  );
};

IconTabContainer.propTypes = {
  /** tab */
  tab: PropTypes.object.isRequired,
  /** number ofr breaking feed notifications  */
  breakingFeedNotifications: PropTypes.number,
  /** clear notification function */
  clearNotificationCount: PropTypes.func,
};

IconTabContainer.defaultProps = {
  breakingFeedNotifications: undefined,
  clearNotificationCount: undefined,
};

export default IconTabContainer;
