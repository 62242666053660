import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useCheckUserRight from 'hooks/useCheckUserRight';
import RundownList from './list-view';

const SidebarContainer = (props) => {
  const { setPreviewRundown } = props;
  const [selectedDates, setSelectedDates] = useState([new Date()]);

  const [checkUserRight] = useCheckUserRight();
  const hideMasterRundowns = !checkUserRight('rundown', 'view-master');
  const canCreateNewRundown = checkUserRight('rundown', 'create');

  const onDateChanged = (e) => {
    setSelectedDates(e);
  };

  return (
    <RundownList
      onDateChanged={onDateChanged}
      selectedDates={selectedDates}
      hideMasterRundowns={hideMasterRundowns}
      canCreateNewRundown={canCreateNewRundown}
      setPreviewRundown={setPreviewRundown}
    />
  );
};

SidebarContainer.propTypes = {
  /** function set the rundown that should be previewed   */
  setPreviewRundown: PropTypes.func,
};

SidebarContainer.defaultProps = {
  setPreviewRundown: () => {},
};

export default SidebarContainer;
