const params = 'utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels';

const imageData = [
  {
    title: 'JacobMorch01.webp',
    credit: 'Jacob Morch',
    url: `https://www.pexels.com/@jacobmorch?${params}`,
  },
  {
    title: 'LukasKloeppel.webp',
    credit: 'Lukas Kloeppel',
    url: `https://www.pexels.com/@lkloeppel?${params}`,
  },
  {
    title: 'TobiasBjorkli01.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli02.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli03.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli04.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli05.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli06.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli07.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli08.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli09.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli10.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli11.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli12.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli13.webp',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'ValdemarasD01.webp',
    credit: 'Valdemaras D.',
    url: `https://www.pexels.com/@valdemaras-d-784301?${params}`,
  },
];

export default imageData;
