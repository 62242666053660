import gql from 'graphql-tag';

export default gql`
  query GetGroupUsers($input: GetGroupUserInput) {
    getGroupUsers(input: $input) {
      items {
        mId
        mProperties {
          __typename
          ... on ContactType {
            firstName
            surname
            email
            jobTitle
          }
        }
      }
    }
  }
`;
