import styled from '@emotion/styled';
import theme from 'theme/theme';
import { subtitle1 } from 'theme/styledTypography';

export const RootWrapper = styled('div')`
  display: flex;
  height: 423px;
`;
export const LeftWrapper = styled('div')`
  width: 216px;
`;

export const ContentWrapper = styled('div')`
  height: calc(100% - 64px);
`;

export const RightWrapper = styled('div')`
  width: 578px;
`;

export const CenterContent = styled('div')`
  margin: 16px;
`;

export const FieldLabel = styled('p')`
  ${subtitle1};
  color: ${theme.palette.dina.highEmphasis};
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const TransitionSelectWrapper = styled('div')`
  width: 176px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid ${theme.palette.dina.buttonBorderOutlined};
`;
