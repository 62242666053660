import styled from '@emotion/styled';
import theme from 'theme/theme';
import colors from 'theme/colors';
import { h5 } from 'theme/styledTypography';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  flex-shrink: 0;
  user-select: none;
  background: ${colors.backgroundResting};
`;

export const Title = styled('div')`
  display: flex;
  align-items: center;
  ${h5};
  line-height: 28px;
`;

export const CloseButton = styled('div')`
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    path {
      fill: ${colors.highEmphasis};
      fill-opacity: 1;
    }
  }
`;
