import getMessage from 'utils/notificationHelper/messageTextUtils';
import doNotify from './notificationConfig';

const createMessage = (notifyUser) => {
  const messages = [];
  const messageObject = {
    messages,
    messageType: '',
    messageTypeString: '',
    mId: '',
    mType: '',
    itemTitle: '',
    mRefId: '',
  };

  try {
    const { loggedInUserId, mUpdatedById } = notifyUser.message;
    const selfUpdate = mUpdatedById === loggedInUserId;
    const notify = !selfUpdate && doNotify(notifyUser.message, loggedInUserId);
    const message = notify ? getMessage(notifyUser) : undefined;
    if (message !== undefined) {
      messages.push(message.message);
      const { messageType, messageTypeString, mId, mRefId, mType, itemTitle } = message;
      messageObject.messageType = messageType;
      messageObject.messageTypeString = messageTypeString;
      messageObject.mId = mId;
      messageObject.mType = mType;
      messageObject.itemTitle = itemTitle;
      messageObject.mRefId = mRefId;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error in getTitle:', error);
  }
  return messageObject;
};

export default createMessage;
