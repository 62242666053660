import styled from '@emotion/styled/macro';
import theme from 'theme/theme';
import Button from 'components/buttons/button';
import { ReactComponent as AddIconComponent } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as GifIconComponent } from 'assets/icons/systemicons/editor/gif_off.svg';

export const GifIcon = styled(GifIconComponent)`
  width: 24px;
  height: 24px;
  margin: 8px;
  path {
    fill: ${theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

export const ContentWrapper = styled('div')`
  width: 100%;
  margin-bottom: 16px;
`;
export const GifWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const Image = styled('img')`
  height: 80px;
  width: 80px;
  border-radius: 2px;
  margin-right: 84px;
`;

export const AddIcon = styled(AddIconComponent)``;
export const AddButton = styled(Button)`
  height: 80px;
  width: 80px;
  border-radius: 2px;
  :hover {
    ${AddIcon} {
      path {
        fill: ${theme.palette.dina.highEmphasis};
        fill-opacity: 1;
      }
    }
  }
`;
