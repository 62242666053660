import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: '6px 0px',
  },
  separator: {
    flex: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '-18px',
  },
  connector: {
    backgroundColor: theme.palette.dina.onSelected,
    position: 'relative',
    width: '1px',
    '&.selected': {
      backgroundColor: theme.palette.dina.iconInactive,
    },
    '&.lastElement': {
      visibility: 'hidden',
    },
    height: '95%',
  },
  connectorGap: {
    backgroundColor: theme.palette.dina.onSelected,
    position: 'relative',
    width: '1px',
    '&.selected': {
      backgroundColor: theme.palette.dina.iconInactive,
    },
    height: '20px',
  },
  dot: {
    backgroundColor: theme.palette.dina.onSelected,
    borderWidth: '0px',
    '&.selected': {
      backgroundColor: theme.palette.dina.iconInactive,
    },
    marginTop: '0px',
    marginBottom: '0px',
  },
  timeline: {
    position: 'relative',
    left: '0px',
    minHeight: '48px',
    cursor: 'pointer',
    width: '256px',

    '&.selected': {
      cursor: 'grab',
      backgroundColor: theme.palette.dina.onSelected,
    },
  },
  timelineContent: {
    padding: '0',
    position: 'relative',
    left: '0%',
    right: '0%',
    top: '2.56%',
    bottom: '76.92%',
  },
}));

export default useStyles;
