import { makeStyles } from '@material-ui/styles';

const assetButtonsMargin = 4;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      cursor: 'pointer',
    },
    '& > *:not(:last-child)': {
      marginRight: ({ showRightMargin, rightMarginWidth }) =>
        showRightMargin && (rightMarginWidth || assetButtonsMargin),
    },
  },
}));

export default useStyles;
