import styled from '@emotion/styled';
import colors from 'theme/colors';

export const RootWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.dividerLight};
`;

export const ProfileInfoWrapper = styled('div')`
  display: flex;
  gap: 8px;
`;
