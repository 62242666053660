import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './footer-styles';

const Footer = ({
  handleCancel,
  isLinear,
  selectedRadioButton,
  handleOK,
  publishNow,
  confirmPublish,
  shouldDisablePublish,
}) => {
  const classes = useStyles();

  const [className, buttonText] = (() => {
    if (publishNow && !isLinear) {
      return confirmPublish
        ? ['confirmPublishButton', 'Click again to Publish']
        : ['publishButton', 'Publish now'];
    }
    return ['scheduleButton', 'Schedule'];
  })();

  return (
    <div className={classes.buttonContainer}>
      <Button className={classes.cancelButton} onClick={handleCancel}>
        Cancel
      </Button>
      {!isLinear && selectedRadioButton === 'unschedule' ? (
        <Button className={classes.okButton} onClick={handleOK}>
          Ok
        </Button>
      ) : (
        <Button className={classes[className]} onClick={handleOK} disabled={!shouldDisablePublish}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

Footer.propTypes = {
  handleCancel: PropTypes.func,
  isLinear: PropTypes.bool,
  selectedRadioButton: PropTypes.string,
  handleOK: PropTypes.func,
  publishNow: PropTypes.bool,
  confirmPublish: PropTypes.bool,
  shouldDisablePublish: PropTypes.bool,
};

Footer.defaultProps = {
  handleCancel: () => {},
  isLinear: false,
  selectedRadioButton: 'unschedule',
  handleOK: () => {},
  publishNow: false,
  confirmPublish: false,
  shouldDisablePublish: false,
};

export default Footer;
