import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants';
import { useSlate, ReactEditor } from 'slate-react';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import iconComponents from './constants/iconComponents';
import useStyles from './styles';
import insertBlock from '../iconButton/utils/insertBlock';

const BlockButton = ({ type, data }) => {
  const classes = useStyles();
  const editor = useSlate();
  const { update } = useEditorContext();
  const IconButton = iconComponents[type];

  const insertItems = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    switch (type) {
      case elementTypes.CONTENT:
        insertBlock({ editor, update, type: elementTypes.PARAGRAPH });
        break;
      case elementTypes.TAGS:
        insertBlock({ editor, update, type, data: { content: [], enableAutocomplete: true } });
        break;
      case elementTypes.AUTHOR:
      case elementTypes.SOURCE:
      case elementTypes.SECTION:
        insertBlock({ editor, update, type, data: { content: [] } });
        break;
      case elementTypes.LIVE_STREAM:
        insertBlock({ editor, update, type, data: { dvrHours: 48 } });
        break;
      default:
        insertBlock({ editor, update, type, data });
        break;
    }

    // ensures new block gets focus after insert
    ReactEditor.focus(editor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderIcon = useMemo(
    () => (
      <div className={classes.root}>
        <IconButton onClick={insertItems} title={tooltipEnum[type]} />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [insertItems],
  );

  return renderIcon;
};

BlockButton.propTypes = {
  /**
   * type of the block that the button will insert
   */
  type: PropTypes.string,
  /** default data passed from toolbar */
  data: PropTypes.shape({}),
};

BlockButton.defaultProps = {
  type: elementTypes.IMAGE,
  data: {},
};

export default memo(BlockButton);
