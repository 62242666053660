import styled from '@emotion/styled/macro';
import {
  Divider as MaterialDivider,
  List as MaterialList,
  ListItem as MaterialListItem,
  ListItemText as MaterialListItemText,
} from '@material-ui/core';
import { listItemLabel } from 'theme/styledTypography';
import colors from 'theme/colors';
import { ReactComponent as Add } from 'assets/icons/systemicons/add_small.svg';

export const AddIcon = styled(Add)`
  margin: 0px 5px;
`;

export const List = styled(MaterialList)`
  background-color: ${colors.surfaceCard};
  border-radius: 8px;
  backdrop-filter: blur(30px);
  width: 400px;
  padding: 8px 0;
  max-height: 85vh;
  overflow: auto;
`;

export const DeleteWrapper = styled('div')`
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate3d(0, -12px, 0);
`;

export const Divider = styled(MaterialDivider)`
  background: ${colors.dividerLight};
  margin: 4px 8px;
`;

export const ListItemWrapper = styled('div')`
  position: relative;
  :hover {
    ${DeleteWrapper} {
      opacity: 1;
    }
  }
`;

export const ListItem = styled(MaterialListItem)`
  &.MuiListItem-root {
    :hover {
      background-color: ${colors.whiteHoverOverlay};
    }
  }
`;

export const ListItemText = styled(MaterialListItemText)`
  .MuiListItemText-primary {
    ${listItemLabel}
    margin: 0px 16px 0px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
