import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import InputField from 'components/inputField/InputField';
import useStyles from './LoginFormStyle';

const ForgotPassword = (props) => {
  const defaultUsernameDescription = 'Type your Username here...';
  const severityMandatory = 'mandatory';
  const severityError = 'error';
  const classes = useStyles();
  const { usernameError, usernameErrorText, onSubmit, onCancel } = props;
  const [username, setusername] = useState('');

  const [usernameDescription, setusernameDescription] = useState(defaultUsernameDescription);
  const [usernameSeverity, setusernameSeverity] = useState(severityMandatory);

  const setUsernameSeverityWithDescription = (severity, description) => {
    setusernameSeverity(severity);
    setusernameDescription(description);
  };

  useEffect(() => {
    if (usernameError) {
      setUsernameSeverityWithDescription(severityError, usernameErrorText);
    } else {
      setUsernameSeverityWithDescription(severityMandatory, defaultUsernameDescription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usernameError]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(username);
    }
  };

  const handleOnCancel = (event) => {
    event.preventDefault();
    if (onCancel) {
      onCancel();
    }
  };

  const onUsernameChange = (value) => {
    if (usernameSeverity === severityError) {
      setUsernameSeverityWithDescription(severityMandatory, defaultUsernameDescription);
    }
    setusername(value);
  };

  return (
    <>
      <div>
        <Typography variant="h6" className={classes.resetText}>
          Please Enter Your Username below
        </Typography>
        <form onSubmit={handleSubmit} data-testid="forgotPassword">
          <InputField
            label="Username"
            type="text"
            description={usernameDescription}
            severity={usernameSeverity}
            value={username}
            onChange={(value) => onUsernameChange(value)}
          />
          <Button
            type="submit"
            size="large"
            data-testid="btnSubmit"
            fullWidth
            disabled={!username}
            classes={{
              root: classes.buttonRoot,
              disabled: classes.buttonDisabled,
            }}
          >
            SUBMIT
          </Button>
          <Typography>
            <Link href="" className={classes.link} onClick={handleOnCancel} underline="hover">
              Go Back
            </Link>
          </Typography>
        </form>
      </div>
    </>
  );
};

ForgotPassword.propTypes = {
  /* To set error status for password. Values: true/false */
  usernameError: PropTypes.bool,

  /* Error message for password */
  usernameErrorText: PropTypes.string,

  /*  onSubmit function */
  onSubmit: PropTypes.func,

  /*  onCancel function */
  onCancel: PropTypes.func,
};

ForgotPassword.defaultProps = {
  usernameError: false,
  usernameErrorText: '',
  onSubmit: () => {},
  onCancel: () => {},
};

export default ForgotPassword;
