import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  imageContainer: {
    position: 'relative',
    height: 40,
    width: 40,
  },
  image: {
    height: '40px',
    width: '40px',
    borderRadius: '2px',
    objectFit: 'cover',
  },
  archivedOverlay: {
    position: 'absolute',
    width: 20,
    height: 20,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.dina.statusError,
    borderTopLeftRadius: 25,
    borderBottomRightRadius: 2,
  },
  archivedPitchOverlay: {
    position: 'absolute',
    width: 15,
    height: 15,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.dina.statusError,
    borderTopLeftRadius: 25,
    borderBottomRightRadius: 2,
  },
  titleDiv: {
    flex: 1,
    marginLeft: '8px',
    height: 40,
    maxWidth: 'calc(100% - 160px)',
  },
  title: {
    ...theme.typography.dina.listItemLabel,
    letterSpacing: '0.4px',
    fontWeight: 500,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'break-word',
  },
  pitchDiv: {
    height: 40,
    width: 40,
    position: 'relative',
  },
  pitchIcon: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.dina.statusStoryMarket,
    borderRadius: '2px',
  },
  pitchThumbnail: {
    height: 24,
    width: 24,
    objectFit: 'cover',
    borderRadius: '2px',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  secondary: {
    width: 88,
    height: 48,
    marginRight: '8px',
  },
  timingDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    outline: 'none',
    '&:hover': {
      '& > :first-child': {
        color: theme.palette.dina.highEmphasis,
      },
      '& > :last-child': {
        '& path': {
          fillOpacity: 1,
        },
      },
    },
  },
  timingText: {
    ...theme.typography.dina.caption,
    lineHeight: '14px',
    letterSpacing: '0.25px',
    fontWeight: 'normal',
    textAlign: 'right',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    marginRight: '8px',
    position: 'relative',
  },
  hoveredDiv: {
    display: 'flex',
    position: 'absolute',
    right: 20,
    transition: 'all 0.18s',
  },
  initialDiv: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    transition: 'all 0.18s',
  },
  iconButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
  },
  iconButton: {
    padding: '0px',
    paddingLeft: '8px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  padlockIcon: {
    '& path': {
      fill: theme.palette.dina.statusWarning,
      fillOpacity: 1,
    },
  },
}));

export default useStyles;
