import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '48px',
    height: '28px',
    borderRadius: '6px',
    backgroundColor: theme.palette.dina.statusApproved,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
      '& *': {
        fill: theme.palette.dina.iconActive,
        fillOpacity: 1,
      },
    },
  },
}));

export default useStyles;
