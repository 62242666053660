const tabs = [
  {
    label: 'Contacts',
  },
  {
    label: 'Teams',
  },
  {
    label: 'Departments',
  },
];

export default tabs;
