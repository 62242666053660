import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/buttons/button';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { RootWrapper, ButtonWrapper, TextHighEmphasis, TextMediumEmphasis } from './footer-styled';
import { useChangedRows } from '../../atoms';
import useUpdateGroup from '../../hooks/useUpdateGroup';

const Footer = (props) => {
  const { groupPolicies, onClose } = props;
  const [changedRows, setChangedRows] = useChangedRows();
  const [updatePermissions, loading] = useUpdateGroup();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (changedRows.length !== 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [changedRows]);

  const onCancel = () => {
    setChangedRows([]);
  };

  const onSave = async () => {
    const result = await updatePermissions(groupPolicies);
    if (result?.data?.updateGroupPermissions) {
      setChangedRows([]);
    }
  };

  const onSaveAndClose = () => {
    onSave();
    onClose();
  };

  return (
    <RootWrapper>
      <ButtonWrapper>
        <Button shape="full-width" onClick={onCancel} disabled={disabled}>
          <TextMediumEmphasis>Cancel</TextMediumEmphasis>
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button type="cta" shape="full-width" onClick={onSave} disabled={disabled}>
          <TextHighEmphasis>Save</TextHighEmphasis>
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button type="significant" shape="full-width" onClick={onSaveAndClose} disabled={disabled}>
          <TextHighEmphasis>Save & Close</TextHighEmphasis>
        </Button>
      </ButtonWrapper>
      {loading && <LoadingIndicator />}
    </RootWrapper>
  );
};

Footer.propTypes = {
  /** callback to close the message hub dialog */
  onClose: PropTypes.func,
  groupPolicies: PropTypes.array,
};

Footer.defaultProps = {
  onClose: () => {},
  groupPolicies: [],
};

export default Footer;
