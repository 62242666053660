import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import styled from '@emotion/styled';
import { elementTypes } from 'components/editor/constants/types';
import { iconComponents } from '../../constants';

const IconWrapper = styled.div`
  position: absolute;
  height: 40px;
  user-select: none;
`;

const AutomationIcon = ({ type }) => {
  const editor = useSlate();
  const IconComponent = useMemo(() => iconComponents[type], [type]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const forceEditorFocus = useCallback(() => ReactEditor.focus(editor), []);

  return (
    <IconWrapper onClick={forceEditorFocus}>
      <IconComponent />
    </IconWrapper>
  );
};

AutomationIcon.propTypes = {
  /** Automation type */
  type: PropTypes.string,
};

AutomationIcon.defaultProps = {
  type: elementTypes.CAMERA,
};

export default memo(AutomationIcon);
