import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import theme from 'theme/theme';
import { caption } from 'theme/styledTypography';

const StyledTooltip = styled((props) => (
  <MaterialTooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    ${caption}
    max-width: ${(props) => props.maxWidth}px;
    color: ${(props) =>
      props.severity === 'regular'
        ? `${theme.palette.dina.highEmphasis}`
        : `${theme.palette.dina.blackHighEmphasis}`};
    background-color: ${(props) =>
      props.severity === 'regular'
        ? `${theme.palette.dina.tooltip}`
        : `${theme.palette.dina.tooltipWarningBackground}`};
  }
  & .MuiTooltip-arrow {
    color: ${theme.palette.dina.tooltip};
  }
`;

const Tooltip = ({ children, title, noArrow, severity, maxWidth, ...rest }) => (
  <StyledTooltip arrow={!noArrow} severity={severity} maxWidth={maxWidth} title={title} {...rest}>
    {children}
  </StyledTooltip>
);

Tooltip.propTypes = {
  /** children must be React component/div/button etc, react functional
   * component won't work, in this case wrap it around a div
   */
  children: PropTypes.node,
  /** value to show on tooltip, if empty string, nothing will be shown */
  title: PropTypes.string || PropTypes.node,
  /** severity of the tooltip */
  severity: PropTypes.string,
  /** maxWidth of the tooltip */
  maxWidth: PropTypes.number,
};

Tooltip.defaultProps = {
  children: null,
  title: '',
  severity: 'regular',
  maxWidth: 300,
};

export default Tooltip;
