import { makeStyles } from '@material-ui/styles';

const width = 24;
const height = 24;
const fontSize = 16;
const borderRadius = '4px';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width,
    height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: ({ isActive }) => isActive && palette.dina.iconDisabled,
    borderRadius,
    '&:hover': {
      '& *': {
        fontSize,
        color: palette.dina.iconActive,
      },
    },
  },
  icon: {
    fontSize,
    color: ({ isActive }) => (isActive ? palette.dina.highEmphasis : palette.dina.iconInactive),
  },
}));

export default useStyles;
