import React, { memo } from 'react';

import { useToolbar } from 'store';
import { useCurrentTabValue } from 'store/tabs';

import Toolbar from './toolbar-view';

const ToolbarContainer = () => {
  const currentTab = useCurrentTabValue();
  const [toolbar] = useToolbar();
  const { type, selectedDate, mType } = currentTab;

  return (
    <Toolbar mType={mType || type} type={type} selectedDate={selectedDate}>
      {toolbar}
    </Toolbar>
  );
};

export default memo(ToolbarContainer);
