import React from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import RundownIcon, { iconUsage } from 'components/rundownIcons';
import returnState from 'screens/planning/components/status/utils/statusBoards/returnState';
import AvatarGroup from 'components/avatarGroup/avatarGroup';
import { ReactComponent as LinkIcon } from 'assets/icons/systemicons/link.svg';
import { ReactComponent as ScheduledIcon } from 'assets/icons/systemicons/scheduled_ultrasmall_on.svg';
import { ReactComponent as UnscheduledIcon } from 'assets/icons/systemicons/scheduled_ultrasmall_off.svg';
import statusIcons from 'assets/icons/systemicons/status/statusIcon';
import getFormattedPublishedDate from 'utils/getFormattedPublishedDate';
import distanceInAbbreviatedWords from 'utils/distanceInAbbreviatedWords';
import {
  PublishingInfoWrapper,
  TopRowWrapper,
  Title,
  TitleRowWrapper,
  BottomRowWrapper,
  ItemsWrapper,
  Divider,
  StoryTitle,
  KanbanCardWrapper,
} from './kanbanCard-styled';

const getState = (state) => {
  const { icon, name } = returnState(state);
  return { icon: statusIcons[`${icon}_small`], name };
};

const KanbanCard = (props) => {
  const {
    destination,
    mRelatedMembers,
    updatedAt,
    storyTitle,
    blockTitle,
    title,
    mPublishingAt,
    items,
    mState,
    assignedUsers,
    autoHeight,
    platform,
    hideStatusInfo,
  } = props;

  const { icon, name } = getState(mState);

  const PlatformIcon = PlatformIcons[platform]
    ? PlatformIcons[platform]
    : PlatformIcons.defaultIcon;

  const lastUpdatedAt = distanceInAbbreviatedWords(updatedAt);

  return (
    <KanbanCardWrapper $storyTitle={storyTitle} $autoHeight={autoHeight}>
      <TopRowWrapper>
        <div>
          <PlatformIcon />
          <span className="destination">{destination}</span>
        </div>
        <div>
          {mRelatedMembers?.length > 0 && (
            <>
              <span className="relationNumber">{mRelatedMembers?.length}x</span>
              <LinkIcon />
              <Divider orientation="vertical" flexItem />
            </>
          )}

          {lastUpdatedAt}
        </div>
      </TopRowWrapper>
      <TitleRowWrapper>
        {Boolean(storyTitle) && <StoryTitle title={storyTitle}>{storyTitle}</StoryTitle>}
        <Title $autoHeight={autoHeight} title={blockTitle || title}>
          {blockTitle || title}
        </Title>
      </TitleRowWrapper>
      <BottomRowWrapper>
        <div>
          <PublishingInfoWrapper $hasPublishingDate={Boolean(mPublishingAt)}>
            {mPublishingAt ? (
              <>
                <ScheduledIcon />
                <span className="publishedText">{getFormattedPublishedDate(mPublishingAt)}</span>
              </>
            ) : (
              <>
                <UnscheduledIcon />
                <span>Unscheduled</span>
              </>
            )}
          </PublishingInfoWrapper>
          {items?.length > 0 && (
            <ItemsWrapper>
              {items.slice(0, 3).map((item) => (
                <RundownIcon
                  key={uuidv1()}
                  variant={item.title}
                  width={16}
                  height={16}
                  usage={iconUsage.META}
                />
              ))}
            </ItemsWrapper>
          )}
          {!hideStatusInfo && (
            <>
              <img src={icon} alt="" />
              <span>{name}</span>
            </>
          )}
        </div>
        <AvatarGroup size={20} members={assignedUsers} max={2} />
      </BottomRowWrapper>
    </KanbanCardWrapper>
  );
};

KanbanCard.propTypes = {
  /** Account of platform */
  destination: PropTypes.string,
  /** List of related instances */
  mRelatedMembers: PropTypes.array,
  /** Last updated at */
  updatedAt: PropTypes.string,
  /** Title of the story that the instance is part of */
  storyTitle: PropTypes.string,
  /** Block title within the instance */
  blockTitle: PropTypes.string,
  /** title of instance itself */
  title: PropTypes.string,
  /** publishing time */
  mPublishingAt: PropTypes.string,
  /** List of items within the instance */
  items: PropTypes.array,
  /** state of the instance */
  mState: PropTypes.string,
  /** List of users assigned to the instance */
  assignedUsers: PropTypes.array,
  /** sets the height of the Kanban card depending on contents */
  autoHeight: PropTypes.bool,
  /** Platform of instance */
  platform: PropTypes.string,
  /** hides mState info */
  hideStatusInfo: PropTypes.bool,
};

KanbanCard.defaultProps = {
  destination: '',
  mRelatedMembers: [],
  updatedAt: '',
  storyTitle: '',
  blockTitle: '',
  title: '',
  mPublishingAt: '',
  items: [],
  mState: '',
  assignedUsers: [],
  autoHeight: true,
  platform: '',
  hideStatusInfo: false,
};

export default KanbanCard;
