import styled from '@emotion/styled';
import { css } from '@emotion/react';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import transientOptions from 'theme/helpers';
import colors from 'theme/colors';
import { listItemGroupHeadersLabel, captionRegular } from 'theme/styledTypography';

export const GroupHovered = css`
  right: 48px;
  transition: all 0.15s;
`;

export const GroupInitial = css`
  right: 24px;
  transition: all 0.15s;
`;

export const GroupHeader = styled(ListItem)`
  &.MuiListItem-root {
    padding: 0px;
    padding-right: ${(props) => `${props.variant ? '0px' : '14px'}`};
    border-bottom: ${(props) =>
      `1px solid ${props.variant ? colors.dividerLight : colors.dividerDark}`};
    background-color: ${(props) => `${props.variant ? 'transparent' : colors.blackHoverOverlay}`};
    border-top: ${(props) => `${props.variant ? '0px' : '1px'}} solid ${colors.dividerDark}`};
    &:hover {
      background-color: ${colors.whiteHoverOverlay};
    }
  }
`;

export const LabelContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
`;

export const ExpandIcon = styled('img')`
  margin-right: 6px;
`;

export const Label = styled(Typography)`
  ${listItemGroupHeadersLabel};
  font-weight: normal;
  line-height: 15px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const UploadProgressContainer = styled('div', transientOptions)`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  & > :first-of-type {
    height: 18px;
    width: 18px;
    position: static;
  }
  ${(props) => (props.$isHovered && props.$onAddClick ? GroupHovered : GroupInitial)};
`;

export const ItemCount = styled(Typography, transientOptions)`
  ${captionRegular};
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  ${(props) => (props.$isHovered && props.$onAddClick ? GroupHovered : GroupInitial)};
`;

export const AddButton = styled(IconButton)`
  height: 30px;
  width: 30px;
`;
