import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { publishingPoints } from 'assets/icons/publishingPoints';

import Container from '../container/Container';
import MaterialAvatar from '../material-avatar';

const StyledMaterialAvatar = styled(MaterialAvatar)`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 4px;
`;

const Publish = ({ children, src, publishingPoint, width, height, ...rest }) => {
  const publishingPointImage = publishingPoints[publishingPoint]
    ? publishingPoints[publishingPoint]
    : publishingPoints.default;

  return (
    <Container width={width} height={height} {...rest}>
      <StyledMaterialAvatar width={width} height={height} src={publishingPointImage}>
        {children}
      </StyledMaterialAvatar>
    </Container>
  );
};

Publish.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  publishingPoint: PropTypes.string,
};

Publish.defaultProps = {
  children: null,
  src: '',
  publishingPoint: '',
};

export default Publish;
