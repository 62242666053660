import React, { useState, useCallback, useEffect, useMemo, memo } from 'react';
import { Editor, Transforms, Range } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { ClickAwayListener } from '@material-ui/core';
import { elementTypes } from 'components/editor/constants/types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import Portal from '../portal';
import { ListWrapper, List, MenuItem } from './styled';

const handleEvent = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const Suggestions = () => {
  const editor = useSlate();
  const { users } = useEditorContext();
  const { selection } = editor;
  const [top, setTop] = useState('200vh');
  const [left, setLeft] = useState('200vw');
  const [target, setTarget] = useState();
  const [search, setSearch] = useState('');

  useEffect(() => {
    const { isCollapsed, edges } = Range;

    if (selection && isCollapsed(selection)) {
      const [start] = edges(selection);
      const { before, range, string, after } = Editor;
      const wordBefore = before(editor, start, { unit: 'word' });
      const beforePoint = wordBefore && before(editor, wordBefore);
      const beforeRange = beforePoint && range(editor, beforePoint, start);
      const beforeText = beforeRange && string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
      const afterPoint = after(editor, start);
      const afterRange = range(editor, start, afterPoint);
      const afterText = string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
      } else {
        setTarget(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const filteredUsers = useMemo(
    () =>
      users
        .filter(({ mTitle }) => mTitle.toLowerCase().startsWith(search.toLowerCase()))
        .slice(0, 10)
        .sort((a, b) => a.mTitle.localeCompare(b.mTitle)),
    [search, users],
  );

  useEffect(() => {
    if (target && filteredUsers.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();

      setTop(`${rect.top + window.pageYOffset + 24}px`);
      setLeft(`${rect.left + window.pageXOffset}px`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredUsers.length, target]);

  const [open, setOpen] = useState(true);
  const [cursor, setCursor] = useState(0);
  const userCount = filteredUsers.length;
  const showSuggestions = open && target && filteredUsers.length > 0;

  const goForward = useCallback(
    () =>
      setCursor((previousCursor) => (previousCursor + 1 === userCount ? 0 : previousCursor + 1)),
    [userCount],
  );

  const goBackward = useCallback(
    () =>
      setCursor((previousCursor) => (previousCursor - 1 < 0 ? userCount - 1 : previousCursor - 1)),
    [userCount],
  );

  const openList = useCallback(() => setOpen(true), []);
  const closeList = useCallback(() => setOpen(false), []);

  const onArrowDown = useCallback(
    (event) => {
      handleEvent(event);
      goForward();
    },
    [goForward],
  );

  const onArrowUp = useCallback(
    (event) => {
      handleEvent(event);
      goBackward();
    },
    [goBackward],
  );

  const insertMention = useCallback(
    (user) => {
      const mention = {
        type: elementTypes.MENTION,
        data: user,
        children: [{ text: '' }],
      };

      const { select, insertNodes, move } = Transforms;

      ReactEditor.focus(editor);

      select(editor, target);
      insertNodes(editor, mention);
      move(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [target],
  );

  const onEnter = useCallback(
    (event) => {
      handleEvent(event);
      insertMention(filteredUsers[cursor]);
    },
    [insertMention, filteredUsers, cursor],
  );

  const onEscape = useCallback(
    (event) => {
      handleEvent(event);
      closeList();
    },
    [closeList],
  );

  const onKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowDown':
          onArrowDown(event);
          break;

        case 'ArrowUp':
          onArrowUp(event);
          break;

        case 'Enter':
          onEnter(event);
          break;

        case 'Escape':
          onEscape(event);
          break;

        default:
          openList();
          break;
      }
    },
    [onArrowDown, onArrowUp, onEnter, onEscape, openList],
  );

  useEffect(() => {
    if (showSuggestions) window.addEventListener('keydown', onKeyDown, true);

    return () => {
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown, showSuggestions]);

  return showSuggestions ? (
    <Portal>
      <div>
        <ClickAwayListener onClickAway={closeList}>
          <ListWrapper top={top} left={left}>
            <List>
              {filteredUsers.map(({ mTitle, mId }, index) => (
                <MenuItem
                  dense
                  selected={index === cursor}
                  key={mId}
                  onClick={() => insertMention({ mTitle, mId })}
                >
                  {mTitle}
                </MenuItem>
              ))}
            </List>
          </ListWrapper>
        </ClickAwayListener>
      </div>
    </Portal>
  ) : null;
};

export default memo(Suggestions);
