import RundownItemTypes from 'screens/rundown/utils/RundownItemTypes';

/**
 * Finds meta items given editor nodes
 *
 * @param {Object[]} nodes Editor nodes
 * @returns {Object[]} Meta items
 */

const filterEditorMeta = (nodes) => {
  const primaryMeta = Object.values(RundownItemTypes).slice(0, 9);

  const primaryNodes = nodes.filter(({ data }) => data && data.automationType === 'PRIMARY');

  const editorNodes = primaryNodes.map(({ type, data }) => {
    const { itemId, templateType, templateVariant } = data;

    return {
      title: type,
      itemId,
      templateType,
      templateVariant,
    };
  });
  const editorMeta = editorNodes.filter(({ title }) => primaryMeta.includes(title));

  return editorMeta;
};

export default filterEditorMeta;
