import React, { useState, useContext, createContext, useEffect } from 'react';

const RundownToolbarCtx = createContext();

const SAVED_STATE = JSON.parse(window.localStorage.getItem('screens'));

const RundownToolbarCtxProvider = (props) => {
  const { rundown } = SAVED_STATE || {};

  const [editorSize, setEditorSize] = useState(rundown && rundown.editorSize);
  const [editorFontSize, setEditorFontSize] = useState(rundown && rundown.editorFontSize);

  useEffect(() => {
    window.localStorage.setItem(
      'screens',
      JSON.stringify({
        rundown: {
          editorSize,
          editorFontSize,
        },
      }),
    );
  }, [editorFontSize, editorSize]);

  return (
    <RundownToolbarCtx.Provider
      value={{ editorSize, setEditorSize, editorFontSize, setEditorFontSize }}
      {...props}
    />
  );
};

export const useRundownToolbarCtx = () => useContext(RundownToolbarCtx);

export default RundownToolbarCtxProvider;
