import Jimp from 'jimp';

const resizeImage = async ({ file, filePath, width, height, quality, isThumbnail, fileName }) => {
  const image = await Jimp.read(filePath);
  const { bitmap } = image;
  const { width: sourceWidth, height: sourceHeight } = bitmap;
  const outputWidth = Math.min(width, sourceWidth);
  const outputHeight = Math.min(height, sourceHeight);

  if (isThumbnail) {
    const outputSize = Math.min(outputWidth, outputHeight);
    image.resize(outputSize, outputSize).quality(quality);
  } else {
    image.resize(outputWidth, outputHeight).quality(quality);
  }

  const imageBuffer = await image.getBufferAsync(Jimp.MIME_JPEG);

  const [defaultFileName] = file.name.split('.');

  const imageFile = new File([imageBuffer], `${fileName || defaultFileName}.jpg`, {
    type: 'image/jpeg',
  });

  const imageUrl = URL.createObjectURL(imageFile);

  return [imageFile, imageUrl];
};

export default resizeImage;
