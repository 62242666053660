import React from 'react';
import PropTypes from 'prop-types';

import Container from '../container/Container';

import {
  StyledDepartmentAvatar,
  StyledAvatarChild,
  StyledMidAddIcon,
  StyledHorizontalTopIcon,
  StyledHorizontalBottomIcon,
  StyledVerticalLeftIcon,
  StyledVerticalRightIcon,
} from './department-styled';

const Department = ({ children, width, height, type, src, uploadEnabled, title, ...rest }) => (
  <Container width={width} height={height} {...rest}>
    <StyledDepartmentAvatar type={type} src={src} width={width} height={height} title={title}>
      <StyledAvatarChild type={type}>{src || uploadEnabled ? null : children}</StyledAvatarChild>
    </StyledDepartmentAvatar>
    {uploadEnabled && !src ? <StyledMidAddIcon /> : null}
    <StyledHorizontalTopIcon />
    <StyledVerticalRightIcon />
    <StyledHorizontalBottomIcon />
    <StyledVerticalLeftIcon />
  </Container>
);

Department.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  type: PropTypes.oneOf(['root', 'topic']),
  uploadEnabled: PropTypes.bool,
};

Department.defaultProps = {
  children: null,
  src: '',
  type: 'root',
  uploadEnabled: false,
};

export default Department;
