import { distanceInWordsToNow } from 'date-fns';
import capitalize from 'utils/capitalize';
import getConversationInformation from 'screens/main/components/header/navbar/messageHub/components/leftColumn/list/utils/getConversationInformation';
import conversationTypes from 'utils/constants/conversationTypes';

const assembleGroupData = (conversations = [], getUser) =>
  conversations.map((conversation) => {
    const { name } = getConversationInformation(conversation, '', getUser);
    return {
      ...conversation,
      conversationMId: conversation.mId,
      conversationMRefId: conversation.mRefId,
      rowId: conversation.mId,
      type: 'Group',
      name,
      mTitle: name,
      chatHistory: capitalize(distanceInWordsToNow(conversation.mUpdatedAt, { addSuffix: true })),
      convoType: conversationTypes.GROUP,
    };
  });

export default assembleGroupData;
