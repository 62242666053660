import React, { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import Dialog from 'components/dialog';
import CreateNew from 'components/createNew';
import generatePlaceholderProps from 'utils/generatePlaceholderProps';
import createPlaceholder from './utils/createPlaceholder';
import getInitialData from '../primaryAutomation/utils/getInitialData';

const MemoizedDialog = memo(Dialog);
const MemoizedCreateNew = memo(CreateNew);

const PlaceholderDialog = ({ element, onClose, open }) => {
  const editor = useSlate();
  const { data, type } = element;
  const initialData = useMemo(() => getInitialData(data), [data]);
  const { templateVariant } = initialData;

  const { templates } = useGetAutomationTemplates();

  const template = useMemo(
    () =>
      templates && templates.find((item) => item.type === type && item.variant === templateVariant),
    [templateVariant, templates, type],
  );

  const editorContext = useEditorContext();
  const { containerRef, update, getPlaceholderConfig } = editorContext;

  const placeholderConfigs = getPlaceholderConfig();
  const { variables } = placeholderConfigs;
  variables.item = {
    ...template,
    ...initialData,
  };

  const handleCreatePlaceholder = useCallback(
    (title) => {
      onClose();
      createPlaceholder(editor, element, update, title);
      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <MemoizedDialog
      container={containerRef.current}
      {...{ open, onClose }}
      disableRestoreFocus
      disableEnforceFocus
    >
      <MemoizedCreateNew
        variant="placeholder"
        onCreate={handleCreatePlaceholder}
        onCancel={onClose}
        placeholderNameConfigs={generatePlaceholderProps(placeholderConfigs)}
      />
    </MemoizedDialog>
  );
};

PlaceholderDialog.propTypes = {
  /** SlateJS element */
  element: PropTypes.shape({
    data: PropTypes.shape({
      assets: PropTypes.arrayOf({}),
    }),
  }),
  /** Callback to be invoked when the dialog closes */
  onClose: PropTypes.func,
  /** Specifies if the dialog is open or not */
  open: PropTypes.bool,
};

PlaceholderDialog.defaultProps = {
  element: {},
  onClose: () => {},
  open: false,
};

export default memo(PlaceholderDialog);
