import React, { memo, useState, useCallback } from 'react';
import { useSlate } from 'slate-react';
import { Dialog } from '@material-ui/core';
import styled from '@emotion/styled';
import theme from 'theme/theme';
import { selectElement } from 'components/editor/utils';
import InstancePreview from '../instancePreview';

const StyledAnchor = styled('a')`
  color: ${theme.palette.dina.onSelected};
  text-decoration: underline;
  cursor: ${(props) => (props.shouldShowPreviewOnClick ? 'pointer' : 'text')};
`;

const InstancePreviewWrapper = styled('div')`
  width: 527px;
`;

const LinkedInstance = ({
  instanceId,
  attributes,
  children,
  element,
  shouldShowPreviewOnClick = false,
}) => {
  const editor = useSlate();

  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
    selectElement(editor, element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOpen]);

  const onClick = useCallback(() => {
    if (!shouldShowPreviewOnClick) return;
    // if the editor is in open mode we will not open the preview
    // instead we will show the hovering toolbar.
    setOpen(true);
  }, [setOpen, shouldShowPreviewOnClick]);

  return (
    <>
      <StyledAnchor
        role="presentation"
        onClick={onClick}
        {...attributes}
        shouldShowPreviewOnClick={shouldShowPreviewOnClick}
      >
        {children}
      </StyledAnchor>
      {open && (
        <Dialog open={open} onClose={onClose}>
          <InstancePreviewWrapper>
            <InstancePreview instanceId={instanceId} onClose={onClose} />
          </InstancePreviewWrapper>
        </Dialog>
      )}
    </>
  );
};

export default memo(LinkedInstance);
