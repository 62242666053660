import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UsersContext } from 'globalState/users';
import Avatar from 'components/avatar';
import Scrollbar from 'components/scrollbar/scrollbar';
import getInitials from 'utils/getInitials';
import ProfileInfo from './components/profileInfo';
import {
  RootWrapper,
  Header,
  AvatarWrapper,
  Subtitle,
  Title,
  TypographyDiv,
} from './profile-styled';

const Profile = (props) => {
  const { mId, teams, departments, groups } = props;
  const { getUser } = useContext(UsersContext);
  const user = getUser(mId);
  const { mTitle, mAvatarUrl, mProperties, mDescription } = user;
  const { jobTitle, firstName, surname, email, phone, readSpeed } = mProperties;

  return (
    <RootWrapper>
      <Header>
        <AvatarWrapper>
          <Avatar disableScaling size={104} src={mAvatarUrl}>
            <Title>{getInitials(mTitle)}</Title>
          </Avatar>
        </AvatarWrapper>
        <TypographyDiv>
          <Title>{mTitle}</Title>
          <Subtitle>{jobTitle}</Subtitle>
        </TypographyDiv>
      </Header>
      <Scrollbar>
        <ProfileInfo
          firstName={firstName}
          surname={surname}
          mDescription={mDescription}
          email={email}
          phone={phone}
          readSpeed={readSpeed}
          groups={groups}
          mId={mId}
          teams={teams}
          departments={departments}
        />
      </Scrollbar>
    </RootWrapper>
  );
};

Profile.propTypes = {
  /** Value of id of the user */
  mId: PropTypes.string,
  /** Teams assigned to the user */
  teams: PropTypes.arrayOf(PropTypes.any),
  /** Departments assigned to user */
  departments: PropTypes.arrayOf(PropTypes.any),
  /** Groups assigned to user */
  groups: PropTypes.arrayOf(PropTypes.any),
};

Profile.defaultProps = {
  mId: '',
  teams: [],
  departments: [],
  groups: [],
};

export default Profile;
