import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { getSelectedElement } from 'components/editor/utils';
import variants from '../../constants/types/editorVariants';
import GeneralToolbar from './components/generalToolbar';
import TwitterToolbar from './components/twitterToolbar';
import LinearToolbar from './components/linearToolbar';
import NotesToolbar from './components/notesToolbar';
import MessageToolbar from './components/messageToolbar';
import InstagramToolbar from './components/instagramToolbar';
import FacebookToolbar from './components/facebookToolbar';
import CmsToolbar from './components/cmsToolbar';

import { Container, CMSBlockContainer, CMSToolbarWrapper, GeneralToolbarWrapper } from './styled';

const { GENERAL, LINEAR, TWITTER, CMS, YOUTUBE, FACEBOOK, MESSAGE, NOTES, INSTAGRAM } = variants;

const preventDefault = (event) => event.preventDefault();

const variantConditions = [GENERAL, TWITTER, CMS, YOUTUBE, FACEBOOK, INSTAGRAM];

const ToolbarVariant = ({ variant, isAllowed }) => {
  switch (variant) {
    case CMS:
      return <CmsToolbar />;
    case NOTES:
      return <NotesToolbar />;
    case LINEAR:
      return <LinearToolbar />;
    case MESSAGE:
      return <MessageToolbar />;
    case TWITTER:
      return <TwitterToolbar />;
    case FACEBOOK:
      return <FacebookToolbar />;
    case INSTAGRAM:
      return <InstagramToolbar />;
    case GENERAL:
      return <GeneralToolbar isAllowed={isAllowed} />;
    default: {
      return !isAllowed && variantConditions.includes(variant) && <GeneralToolbar />;
    }
  }
};

const Toolbar = ({
  variant,
  readOnly,
  isAllowed,
  platformStructure,
  isCmsBlock,
  toolbarPosition,
}) => {
  const editor = useSlate();
  const selectedElement = getSelectedElement(editor);
  const isVoidSelected = selectedElement && editor.isVoid(selectedElement);

  // disable general toolbar when void element is selected if
  // editor variant is GENERAL, NOTES or CMS with block enabled.
  const disableGeneralToolbar =
    isVoidSelected && ((isCmsBlock && variant === CMS) || variant === GENERAL);

  if (variant === YOUTUBE) return null;
  if (variant === CMS && isCmsBlock) {
    return (
      <CMSBlockContainer readOnly={readOnly} onMouseDown={preventDefault}>
        <GeneralToolbarWrapper readOnly={readOnly} disableGeneralToolbar={disableGeneralToolbar}>
          <GeneralToolbar isAllowed={isAllowed} hideRightButtons />
        </GeneralToolbarWrapper>
        <CMSToolbarWrapper readOnly={readOnly}>
          <CmsToolbar platformStructure={platformStructure} />
        </CMSToolbarWrapper>
      </CMSBlockContainer>
    );
  }

  return (
    <Container
      onMouseDown={preventDefault}
      role="presentation"
      readOnly={readOnly}
      isNotes={variant === NOTES}
      disableGeneralToolbar={disableGeneralToolbar}
      toolbarPosition={toolbarPosition}
    >
      <ToolbarVariant variant={variant} isAllowed={isAllowed} />
    </Container>
  );
};

Toolbar.propTypes = {
  /** Variant of the toolbar */
  variant: PropTypes.oneOf(Object.values(variants)),
};

Toolbar.defaultProps = {
  variant: variants.GENERAL,
};

export default memo(Toolbar);
