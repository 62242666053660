import mergeUserRights from 'utils/mergeUserRights';

const savePolicyOnContext = (groups, policyResult, config) => {
  let userRights = [];
  if (groups.length > 0) {
    groups.forEach((group) => {
      const matchedPolicy = policyResult.data.getMemberFromId.find(
        (policy) => policy.mRefId === group,
      );
      if (matchedPolicy) {
        const mergedUserRights = mergeUserRights(userRights, matchedPolicy.mProperties.policies);
        userRights = mergedUserRights;
      }
    });
  }
  // eslint-disable-next-line no-param-reassign
  config.userRights = userRights;
};

export default savePolicyOnContext;
