import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

import getTimeString from 'utils/getTimeString';
import openAssetInMimir, { isMimirAssetItem } from 'utils/openAssetInMimir';
import { useRightHidden, useRightSelection } from 'store/sidebar';

import { IconButton as MaterialIconButton } from '@material-ui/core';
import play from 'assets/icons/systemicons/Play_WithCircleBackground.svg';
import MediaDialog from 'components/editor/components/primaryAutomation/components/mediaDialog';
import useStyles from './videoComponents-styles';

const IconButton = memo(MaterialIconButton);

const VideoComponents = ({
  videoThumbnailImage,
  size,
  width,
  height,
  src,
  hidePlayButton,
  durationDataType,
  duration,
  canPlayVideo,
  usage,
  showPlayer,
  hideDuration,
  mId,
  mRefId,
  element,
}) => {
  const classes = useStyles({ isScratchPad: usage === 'scratchPad' });

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();

  const closeMediaDialog = useCallback(() => {
    setMediaDialogOpen(false);
  }, []);
  const openMediaDialog = useCallback(() => {
    setMediaDialogOpen(true);
  }, []);

  const openInMimir = () => {
    setRightHidden(false);
    setRightSelection('storybox');
    openAssetInMimir(element);
  };
  const showOpenInMimir = isMimirAssetItem(element);
  const showVideo = !!src || !!(!videoThumbnailImage && !showPlayer);

  return (
    <>
      {showVideo && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video width={size || width} height={size || height} className={classes.video}>
          <source src={src} type="video/mp4" />
        </video>
      )}
      {!hidePlayButton && (
        <IconButton className={classes.playButton} onClick={openMediaDialog}>
          <img src={play} alt="play" className={classes.playImage} />
        </IconButton>
      )}
      {!hideDuration && duration !== 0 && (
        <div className={classes.duration}>
          {durationDataType === 'milliSeconds' ? getTimeString(duration) : duration}
        </div>
      )}
      {showOpenInMimir && (
        <img
          role="presentation"
          src="https://mimir.mjoll.no/assets/mimir-logo.svg"
          alt="Open"
          className={classes.mimirIcon}
          onClick={openInMimir}
        />
      )}

      {canPlayVideo && mediaDialogOpen && src && (
        <MediaDialog
          asset={{ mediaType: 'video', mContentUrl: src, mId, mRefId }}
          open={mediaDialogOpen}
          onClose={closeMediaDialog}
          showPlayer={showPlayer}
        />
      )}
    </>
  );
};

VideoComponents.propTypes = {
  /** size of the thumbnail, overwrites both width & height props */
  size: PropTypes.number,
  /** Width of the component */
  width: PropTypes.number,
  /** Height of the component */
  height: PropTypes.number,
  /** source of the media */
  src: PropTypes.string,
  /** Duration of video for video component */
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Type of duration data */
  durationDataType: PropTypes.oneOf(['milliSeconds', 'string']),
  /** Boolean to play video of component */
  canPlayVideo: PropTypes.bool,
  /** Boolean to hide play button of video thumbnail */
  hidePlayButton: PropTypes.bool,
  /** Thumbnail image for video */
  videoThumbnailImage: PropTypes.string,
  /** Type of usage of thumbnail component */
  usage: PropTypes.string,
  /** Hides duration information */
  hideDuration: PropTypes.bool,
};

VideoComponents.defaultProps = {
  size: 0,
  width: 96,
  height: 64,
  src: '',
  duration: 0,
  durationDataType: 'milliSeconds',
  canPlayVideo: true,
  hidePlayButton: false,
  videoThumbnailImage: '',
  usage: 'default',
  hideDuration: false,
};

export default memo(VideoComponents);
