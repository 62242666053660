import React, { useState } from 'react';

import memberTypes from 'graphql/memberTypes';
import { useRightSidebarSavedSearches } from 'store/sidebar';

import Dialog from 'components/dialog';
import Searchbar from 'components/searchbarV2';
import RelevantHeader from '../../relevantHeader';
import Tabs from '../../tabs';
import MasterWrapper from '../../masterWrapper';
import useStyles from './list-styles';
import ListContainer from './list-container';
import menuItems from './menu-items';
import menuActions from './menu-actions';
import CreateContact from '../createContact';

const ListView = ({
  containerRef,
  showDetail,
  contactTab,
  handleContactTabChange,
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  searchString,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(<div />);
  const [rightSidebarSavedSearches, setRightSidebarSavedSearches] = useRightSidebarSavedSearches();

  const handleCreateNewFilter = (newFilter) => {
    setRightSidebarSavedSearches([...rightSidebarSavedSearches, newFilter]);
  };

  const handleUpdateFilters = (newSavedFilters) => {
    setRightSidebarSavedSearches(newSavedFilters);
  };

  const tabItems = [
    {
      label: 'INTERNAL',
      content: (
        <div className={classes.listWrapper}>
          <ListContainer searchString={searchString} onClick={showDetail} type={memberTypes.USER} />
        </div>
      ),
    },
    {
      label: 'EXTERNAL',
      content: (
        <div className={classes.listWrapper}>
          <ListContainer
            searchString={searchString}
            onClick={showDetail}
            type={memberTypes.CONTACT}
          />
        </div>
      ),
    },
  ];

  const onMenuItemClick = (action) => {
    switch (action) {
      case menuActions.CREATE_CONTACT:
        setDialogContent(
          <CreateContact
            onCancel={() => setDialogOpen(false)}
            onSubmit={(contact) => {
              // TODO: if null display error message.
              setDialogOpen(false);
            }}
          />,
        );
        setDialogOpen(true);
        break;
      default:
        setDialogContent(<div />);
        setDialogOpen(false);
        break;
    }
  };

  const onTabChange = (_, newValue) => {
    handleContactTabChange(newValue);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        container={containerRef.current}
        BackdropProps={{ classes: { root: classes.backdropRoot } }}
        onClose={() => setDialogOpen(false)}
      >
        {dialogContent}
      </Dialog>

      <MasterWrapper
        header={<RelevantHeader headerTitle="Contacts" {...{ menuItems, onMenuItemClick }} />}
        body={<Tabs disableElevation {...{ tabItems, onTabChange, tabIndex: contactTab }} />}
      />
      <Searchbar
        savedFilters={rightSidebarSavedSearches}
        onCreateFilter={handleCreateNewFilter}
        onUpdateFilters={handleUpdateFilters}
        {...{
          filterModel,
          updateFilterModel,
          history,
          updateHistory,
          currentSearch,
          updateCurrentSearch,
        }}
      />
    </>
  );
};

export default ListView;
