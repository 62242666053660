import memberTypes from 'graphql/memberTypes';

/**
 * All the funcations return variables object for graphql queries.
 *
 * Using apollo hook a typical query looks like
 * const {data, error, loading} = useQuery(GET_MEMBERS_OF, {
 *          variables : { input: {mId: "1", mType: "usr_str"} },
 * });
 *
 * Now the code for accessing cache that was generated for the above query looks like
 * proxy.readQuery({query:GET_MEMBERS_OF,
 *                 variables: { input: {mId: "1", mType: "usr_str"} });
 *
 * Since for a query the variables object for querying and accessing cache is similar and
 * the order of input values is crucial instead of constructing the variables object  in different
 * places we can use this function to return identical variable object for query and cache access.
 *
 * Query:
 * const {data, error, loading} = useQuery(GET_MEMBERS_OF, {
 *          variables : getMembersOfQuery("1", "usr_str"),
 * });
 *
 * Cache access:
 * proxy.readQuery({query:GET_MEMBERS_OF,
 *                 variables : getMembersOfQuery("1", "usr_str") });
 *
 * In this way we can make sure query and cache access are using same set of values
 * for a graphql query.
 */

/**
 * Returns variables object for getMembersOf query.
 *
 * @param {string} mId
 * @param {string} mType
 */
const getMembersByUpdatedAtDateQuery = (mType, startDate, endDate) => ({
  input: {
    mType,
    startDate,
    endDate,
  },
});

/**
 * Returns variables object for getMembersOf query.
 *
 * @param {string} mId
 * @param {string} mType
 */
const getMembersOfQuery = (mId, mType) => ({
  input: {
    mId,
    mType,
    membersOfType: mType,
  },
});

/**
 * Returns variables object for getMembers query.
 *
 * @param {string} mId
 * @param {string} mType
 */
const getMembersQuery = (mId, mType) => ({
  input: {
    mId,
    mType,
  },
});

/**
 * Returns variables object for the  queries using getMembersOfType graphql query
 *
 * @param {string} mType
 */
const getMembersOfTypeQuery = (mType) => ({
  input: { mType },
});

/**
 * Returns variables object for the  queries using getMembersType graphql query
 *
 * @param {string} mType
 */
const getMembersTypeQuery = (mType) => ({
  input: { mType },
});

/**
 * Returns variables object for the queries using getMember graphql query
 *
 * @param {string} mId
 */
const getMemberQuery = (mId) => ({
  input: { mId },
});

/**
 * Returns variables object including page limit for getStories query for the given parameter.
 *
 * @param {string} mType
 */
const getStoriesQuery = (mId, mType) => ({
  input: { mId, mType },
  limit: 50,
});

/**
 * Returns variables object getPitches query for the given parameters
 *
 * @param { string } mId
 * @param { string } mType
 */
const getPitchesQuery = (mId, mType) => ({
  input: { mId, mType },
  limit: 30,
});

/**
 *  Returns variables object for getStoryInstances query
 *
 * @param {string} mId
 */
const getStoryInstancesQuery = (mId) => ({ input: { mId, mType: memberTypes.STORY_INSTANCE } });

/**
 *  Returns variables object for get a single rundown query
 *
 * @param {string} mId
 * @param {string} mRefId
 */
const getRundownQuery = (mId, mRefId) => ({ input: { mId, mRefId } });

/**
 *  Returns variables object for getRundows query
 *
 * @param {string} mId
 */
const getRundownsQuery = (mPublishingAt) => ({
  input: { mPublishingAt, mType: memberTypes.RUNDOWN },
});

export {
  getMembersByUpdatedAtDateQuery,
  getMembersOfQuery,
  getMembersOfTypeQuery,
  getMembersTypeQuery,
  getMembersQuery,
  getMemberQuery,
  getStoriesQuery,
  getStoryInstancesQuery,
  getPitchesQuery,
  getRundownsQuery,
  getRundownQuery,
};
