import React from 'react';
import PropTypes from 'prop-types';

import { publishingPoints } from 'assets/icons/publishingPoints';
import RepeatIcon from 'assets/icons/systemicons/rundown/Repeat_small.svg';

import Container from '../container/Container';

import {
  StyledRundownAvatar,
  StyledTemplate,
  StyledTemplateWrapper,
  StyledTemplateImage,
  StyledPublishingPoint,
} from './rundown-styled';

const Rundown = ({ children, src, width, height, isRecurring, defaultSrc, ...rest }) => (
  <Container width={width} height={height} {...rest}>
    <StyledRundownAvatar src={src} defaultSrc={defaultSrc} width={width} height={height}>
      {children}
    </StyledRundownAvatar>
    {isRecurring && (
      <StyledTemplateWrapper>
        <StyledTemplate>
          <StyledTemplateImage src={RepeatIcon} alt="union" />
        </StyledTemplate>
      </StyledTemplateWrapper>
    )}
    <StyledPublishingPoint>
      <img src={publishingPoints.linear} alt="linear" />
    </StyledPublishingPoint>
  </Container>
);

Rundown.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
  isRecurring: PropTypes.bool,
};

Rundown.defaultProps = {
  children: null,
  src: '',
  isRecurring: false,
  defaultSrc: null,
};

export default Rundown;
