import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '49px',
    width: '100%',
    minWidth: '245px',
  },
  component: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '48px',
    flexGrow: 1,
    padding: '0 24px 0 16px',
  },
  chipContainer: {
    display: 'flex',
    '& > *': {
      marginRight: '4px',
    },
  },
  overflowComponent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrow: {
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overflowText: {
    ...theme.typography.dina.caption,
    textAlign: 'right',
    width: '24px',
  },
  popover: {
    padding: '8px 0',
    backgroundColor: theme.palette.dina.surfaceCardDark,
    borderRadius: '8px',
    width: '280px',
  },
  avatar: {
    marginRight: '16px',
  },
  avatarText: {
    ...theme.typography.dina.listItemLabel,
    fontSize: '10px',
  },
  addUser: {
    cursor: 'pointer',
  },
}));

export default useStyles;
