import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const buttonStyle = (theme) => ({
  cursor: 'pointer',
  ...theme.typography.dina.captionLink,
  '&:hover': {
    '-webkit-filter': theme.palette.dina.onHover,
    color: theme.palette.dina.highEmphasis,
  },
});

const useStyles = makeStyles((theme) => ({
  actionEvent: {
    ...buttonStyle(theme),
    color: theme.palette.dina.mediumEmphasis,
  },
  actionEventEmphasis: {
    ...buttonStyle(theme),
  },
  actionEventDanger: {
    ...buttonStyle(theme),
    color: theme.palette.dina.danger,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
      color: theme.palette.dina.danger,
    },
  },
}));

const Button = ({ text, onClick, type }) => {
  const classes = useStyles();
  const buttonClassName = (() => {
    if (type === 'danger') return classes.actionEventDanger;
    if (type === 'emphasis') return classes.actionEventEmphasis;
    return classes.actionEvent;
  })();
  return (
    <div
      className={buttonClassName}
      role="button"
      tabIndex="0"
      onKeyPress={onClick}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  text: '',
  type: '',
  onClick: () => {},
};

export default Button;
