import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    flexShrink: 0,
  },
  leftComponents: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.dina.h5,
    lineHeight: '28px',
  },
  icon: {
    '& path': {
      fillOpacity: 1,
    },
    marginRight: '8px',
  },
  buttonRoot: {
    width: '126px',
    height: '32px',
    padding: 0,
    marginRight: '8px',
    borderRadius: '6px',
    backgroundColor: theme.palette.dina.backgroundResting,
  },
  buttonOutlined: {
    border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    ...theme.typography.dina.button,
    color: theme.palette.dina.mediumEmphasis,
  },
  closeButton: {
    width: '24px',
    height: '24px',
    backdropFilter: 'blur(15px)',
  },
  closeImage: {
    width: '13px',
    height: '13px',
  },
}));

export default useStyles;
