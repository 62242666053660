import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchTableView from 'components/searchTable';
import columns from './constants/columns';
import tabs from './constants/tabs';

const MAX_SELECTED_ITEMS = Infinity;

const NewMessage = ({ onClose, onOk, rows }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const handleItemSelect = (newSelectedItem) => {
    if (MAX_SELECTED_ITEMS === 1 || tabIndex !== 0) setSelectedItems([newSelectedItem]);
    else if (
      selectedItems.length < MAX_SELECTED_ITEMS &&
      !selectedItems.find((item) => item.rowId === newSelectedItem.rowId)
    )
      setSelectedItems([...selectedItems, newSelectedItem]);
  };

  const handleOk = () => {
    onOk(selectedItems);
  };

  const handleDeleteSelectedItem = (newItem) => {
    setSelectedItems(selectedItems.filter((item) => item.rowId !== newItem.rowId));
  };

  const handleTabChange = (_, index) => {
    setTabIndex(index);
    setSelectedItems([]);
  };

  return (
    <SearchTableView
      onItemSelect={handleItemSelect}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      onClose={onClose}
      columns={columns}
      rows={rows[tabIndex]}
      closeOnSelect={false}
      inputType="chip"
      onOk={handleOk}
      showFooter
      title="New Message"
      onDeleteSelectedItem={handleDeleteSelectedItem}
      tabs={tabs}
      tabIndex={tabIndex}
      onTabChange={handleTabChange}
    />
  );
};

NewMessage.propTypes = {
  /** callback to close new message dialog */
  onClose: PropTypes.func,
  /** callback of ok button press */
  onOk: PropTypes.func,
  /** list of people & other conversation options */
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
};

NewMessage.defaultProps = {
  onClose: () => {},
  onOk: () => {},
  rows: [],
};

export default NewMessage;
