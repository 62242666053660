import React, { Component } from 'react';
import ErrorBoundaryDetails from './components/errorBoundaryDetails-view';

// const clipString = (str, to, include) =>
//   str.substr(0, str.indexOf(to) + (include ? to.length : 0));

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, timeStamp: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      timeStamp: new Date(),
    });
  }

  render() {
    const { errorInfo, error, timeStamp } = this.state;
    const { children, apolloClient } = this.props;
    const isDevEnv = process.env.NODE_ENV === 'development';

    // const tStamp = new Intl.DateTimeFormat('en-US', {
    //   // year: 'numeric',
    //   // month: '2-digit',
    //   // day: '2-digit',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    // }).format(timeStamp);

    if (errorInfo) {
      return (
        <ErrorBoundaryDetails
          client={apolloClient}
          {...{ timeStamp, isDevEnv, error, errorInfo }}
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
