import React from 'react';
import Icon from 'components/icon/icon-view';
import ICONS from 'assets/icons/icons';
import colors from 'theme/colors';

const SavedFilterIcon = () => (
  <>
    <Icon icon={ICONS.BOOKMARK_SEARCH_OFF} height={24} width={24} color={colors.iconInactive} />
    <Icon icon={ICONS.ARROWS_DOUBLE} height={24} width={24} />
  </>
);

const BookmarkIcon = () => (
  <Icon icon={ICONS.BOOKMARK_SEARCH_OFF} height={24} width={24} color={colors.iconInactive} />
);

const ClearIcon = () => <Icon icon={ICONS.CLEAR} height={24} width={24} />;

export { SavedFilterIcon, BookmarkIcon, ClearIcon };
