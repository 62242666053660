import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  shown: {
    height: '100%',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  hidden: {
    height: '100%',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    display: 'none',
  },
}));

export default useStyles;
