/**
 * Extracts id value given mos xml object
 *
 * @param {Object} xmlDoc Parsed mos xml object
 * @returns {String} id value
 */

const getMosId = (xmlDoc) => {
  const mosIdObject = xmlDoc.querySelector('objID');
  const id = mosIdObject ? mosIdObject.textContent : null;

  return id;
};

export default getMosId;
