import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Slide } from '@material-ui/core';
import Popover from 'components/popover';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import GET_TEAMS from 'graphql/queries/getTeams';
import GET_DEPARTMENTS from 'graphql/queries/getDepartments';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import ConversationContext from './conversationContext';
import Header from './components/header';
import LeftColumn from './components/leftColumn';
import MessageContents from './components/messageContents';
import NewMessage from './components/newMessage';
import SplitView from './components/splitView';

import {
  MessageHubInnerRootWrapper,
  StyledBody,
  StyledNewMessageContainer,
} from './messageHub-styled';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

const MessageHub = ({
  onClose,
  selectedConversationIdRef,
  removeMessageNotification,
  notifications,
}) => {
  const [currentConversation, setCurrentConversation] = useState({});
  const [newMessageDialogAnchor, setNewMessageDialogAnchor] = useState(null);
  const newMessageAnchorRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [updateConvoReadAt] = useUpdateConvoReadAt();

  const {
    data: teamData,
    loading: teamLoading,
    error: teamError,
  } = useQuery(GET_TEAMS, {
    variables: getMembersOfTypeQuery(memberTypes.TEAM),
  });

  const {
    data: departmentData,
    loading: departmentLoading,
    error: departmentError,
  } = useQuery(GET_DEPARTMENTS, {
    variables: getMembersOfTypeQuery(memberTypes.DEPARTMENT),
  });

  useEffect(() => {
    if (newMessageDialogAnchor) {
      const { height, width } = newMessageDialogAnchor.getBoundingClientRect();
      if (height !== containerHeight) setContainerHeight(height);
      if (width !== containerWidth) setContainerWidth(width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageDialogAnchor]);

  if (teamLoading || departmentLoading) return <LoadingIndicator />;
  if (teamError) return <div> No Team Data. </div>;
  if (departmentError) return <div> No department data </div>;

  const { getMembersOftype: teams } = teamData;
  const { getMembersOftype: departments } = departmentData;

  const handleConversationSelect = (newConversation) => {
    if (currentConversation?.mId !== newConversation.mId) {
      if (currentConversation.mId && newConversation.mId) {
        updateConvoReadAt(currentConversation.mId);
      }
      // eslint-disable-next-line no-param-reassign
      selectedConversationIdRef.current = newConversation.mId;
      setCurrentConversation(newConversation);
    }
  };

  const handlePopoverClose = () => {
    setNewMessageDialogAnchor(null);
  };

  const handlePopoverOpen = () => {
    if (newMessageAnchorRef && newMessageAnchorRef.current)
      setNewMessageDialogAnchor(newMessageAnchorRef.current);
  };

  const notificationsObject = {};
  notifications.forEach(({ mId }) => {
    if (mId in notificationsObject) notificationsObject[mId] += 1;
    else notificationsObject[mId] = 1;
  });

  return (
    <MessageHubInnerRootWrapper ref={newMessageAnchorRef}>
      <ConversationContext.Provider
        value={{
          currentConversation,
          onConversationSelect: handleConversationSelect,
          removeMessageNotification,
          notifications: notificationsObject,
          selectedConversationIdRef,
          teams,
          departments,
          onClose,
        }}
      >
        <Header onClose={onClose} onNewMessageClick={handlePopoverOpen} />
        <StyledBody>
          <SplitView
            pane1={<LeftColumn onAddNewConversation={handlePopoverOpen} />}
            pane2={currentConversation.mId ? <MessageContents /> : <div />}
          />
        </StyledBody>
        <Popover
          anchorEl={newMessageDialogAnchor}
          onClose={handlePopoverClose}
          noMargin
          TransitionComponent={Transition}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          BackdropProps={{ invisible: false }}
        >
          <StyledNewMessageContainer height={containerHeight} width={containerWidth}>
            <NewMessage onClose={handlePopoverClose} onOk={handleConversationSelect} />
          </StyledNewMessageContainer>
        </Popover>
      </ConversationContext.Provider>
    </MessageHubInnerRootWrapper>
  );
};

MessageHub.propTypes = {
  /** callback to close the message hub dialog */
  onClose: PropTypes.func,
  /** callback to remove new message notifications for the conversations */
  removeMessageNotification: PropTypes.func,
  /** list of notifications */
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mType: PropTypes.string,
    }),
  ),
};

MessageHub.defaultProps = {
  onClose: () => {},
  removeMessageNotification: () => {},
  notifications: [],
};

export default MessageHub;
