/**
 *  Hook for creating a new story and attach it to a user.
 *  It takes the mId of the user and the title of the story .
 *  Returns the story id of the created story.
 *
 */

import { useMutation } from '@apollo/client';
import CREATE_STORY from 'graphql/mutations/createStory';
import memberTypes from 'graphql/memberTypes';
import useSettingsValue from 'hooks/useSettingsValue';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';

const useCreateStory = () => {
  const [createStoryForUser] = useMutation(CREATE_STORY);
  const [updateCache] = useUpdateLeftSidebarCache();
  const [getSettingsValue] = useSettingsValue();

  let createdStoryId;

  const create = async (mId, mTitle, mType, userId, isRestricted = false, mPublishingAt) => {
    const isSyncActive = !(getSettingsValue('story.disableAutoSync') === 'true');
    const isPitch = mType.includes(memberTypes.PITCH);

    const variables = {
      input: {
        mId,
        mTitle,
        mType,
        isRestricted,
        mCreatedById: userId,
      },
    };
    if (mPublishingAt) {
      variables.input.mPublishingAt = mPublishingAt;
    }
    if (!isPitch && isSyncActive) {
      variables.input.isSyncActive = isSyncActive;
    }
    await createStoryForUser({
      variables,
      update: (_, mutationResult) => {
        const { createStoryForMember } = mutationResult.data;
        createdStoryId = createStoryForMember.mId;
        updateCache(createStoryForMember, mType);
      },
    });

    return createdStoryId;
  };

  return [create];
};

export default useCreateStory;
