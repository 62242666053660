import gql from 'graphql-tag';

export default gql`
  query GetDepartments($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mAvatarKey
      mAvatarUrl
      mType
    }
  }
`;
