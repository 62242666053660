import styled from '@emotion/styled';

import colors from 'theme/colors';
import transientOptions from 'theme/helpers';
import { calcContentHeight } from 'screens/main/utils';

/** Main-container */

export const ErrorWrapper = styled('div')`
  color: white;
  position: fixed;
  top: 30%;
  left: 25%;
  font-size: 25px;
`;

/** Main-view */

export const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Body = styled('div', transientOptions)`
  display: flex;
  height: ${({ $hasTitle, $isTickerVisible }) => calcContentHeight($hasTitle, $isTickerVisible)};
  background: ${colors.surfaceAppBackgroundMain};
  margin-right: 48px;
`;

export const ThumbWrapper = styled('div')`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1101;
`;

export const SidebarWrapper = styled('div')`
  max-width: 424px;
  z-index: 100;
  display: flex;
`;

export const CenterWrapper = styled('div', transientOptions)`
  background: ${colors.surfaceAppBackgroundMain};
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-left: ${({ $isLeftHidden }) => ($isLeftHidden ? '0' : '327px')};
  margin-right: ${({ $isRightHidden, $rightPanelWidth, $isRightExpanded }) => {
    if ($isRightHidden) return '0';
    return `${$isRightExpanded ? window.innerWidth / 2 : $rightPanelWidth}px`;
  }};

  transition: all 0.1s linear;
`;

export const TickerWrapper = styled('div', transientOptions)`
  height: ${({ $isTickerVisible }) => ($isTickerVisible ? '40px' : '0px')};
  background-color: ${colors.surfaceAppBackgroundNavLevel1};
  width: 100%;
  z-index: 1000;
  transition: 0.3s;
`;
