import styled from '@emotion/styled/macro';
import { Menu as MaterialMenu, MenuItem as MaterialMenuItem } from '@material-ui/core';
import theme from 'theme/theme';
import { body2 } from 'theme/styledTypography';

export const MenuWrapper = styled('div')``;

export const Menu = styled(MaterialMenu)`
  .MuiMenu-paper {
    width: 232px;
    padding: 24px;
    padding-right: 12px;
    background-color: transparent;
    box-shadow: none;
  }
  .MuiMenu-list {
    width: 196px;
    background-color: ${theme.palette.dina.surfaceDialogs};
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const MenuItem = styled(MaterialMenuItem)`
  &.MuiMenuItem-root {
    ${body2}
    :hover {
      background-color: ${theme.palette.dina.whiteHoverOverlay};
    }
  }
`;
