import memberTypes from 'graphql/memberTypes';

const getFilterMType = (mType, isArchived) => {
  switch (mType) {
    case memberTypes.PITCH:
      return isArchived ? memberTypes.ARCHIVED_PITCH : memberTypes.PITCH;
    case memberTypes.STORY:
      return isArchived ? memberTypes.ARCHIVED_STORY : memberTypes.STORY;
    default:
      return memberTypes.STORY;
  }
};

export default getFilterMType;
