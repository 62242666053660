import React, { useState, useEffect, forwardRef } from 'react';
import { useDrag } from 'react-dnd';
import DndTypes from 'utils/dndTypes';
import InstancePreview from 'screens/main/components/leftArea/sidebar/rundown/instancePreview';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useOpenStory from 'hooks/useOpenStory';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import memberTypes from 'graphql/memberTypes';
import { CustomFields } from 'utils/metadata';
import KanbanCard from 'components/kanbanCard';
import { InstancePreviewWrapper, ListItem, Popper } from './listItem-styled';

export const InstanceItemVariant = {
  INSTANCE_LIST_ITEM: 'instance_list_item',
  CREATE_LINK_LIST_ITEM: 'create_link_list_item',
};

const getMetaDataValue = (mMetaData, key) => {
  const foundMetaData = mMetaData.find((metaData) => metaData.key === key);
  return foundMetaData?.value;
};

const InstanceItem = (props, ref) => {
  const {
    instance,
    isInstanceItemSelected,
    setOpenPreviewIndex,
    setCurrentFocusedIndex,
    index,
    onSelectInstance,
    variant,
    disabled,
  } = props;
  const {
    mId,
    mTitle: title,
    items,
    mProperties,
    mUpdatedAt,
    mState,
    mPublishingAt,
    mRelatedMembers,
    mAssignedMembers,
    mStoryId,
    isTemplateInstance,
    mTemplateId,
    mMetaData,
    mThumbnailUrl,
    mContentKey,
  } = instance;

  const blockTitle = getMetaDataValue(mMetaData, 'block_title');

  const storyTitle = getMetaDataValue(mMetaData, CustomFields.STORY_TITLE);

  const { platform: instanceType, account } = mProperties;

  const isInstanceItemVariant = variant === InstanceItemVariant.INSTANCE_LIST_ITEM;

  const [getAssignedMembers] = useGetAssignedMembers(mAssignedMembers || []);

  const openStory = useOpenStory();

  const isOpenStoryDisabled = Boolean(isTemplateInstance || mTemplateId);

  const handleOpenStory = (event) => {
    if (isOpenStoryDisabled) return;
    handleClose(event);
    openStory(title, mStoryId, memberTypes.STORY, mId);
  };

  const [assignedUsers] = getAssignedMembers();

  const destination = account?.accountTitle;

  const payload = {
    id: mId,
    platform: instanceType,
    publishingAt: mPublishingAt,
    statusId: mState,
    accountId: account.accountId,
    accountRefId: account.accountRefId,
    ...instance,
  };

  const [, dragRef] = useDrag({
    type: DndTypes.INSTANCE_LIST_ITEM,
    item: { type: DndTypes.INSTANCE_LIST_ITEM, payload },
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setCurrentFocusedIndex(null);
    setOpenPreviewIndex(null);
  };

  const handleClick = () => {
    const { current } = ref;
    setAnchorEl(current);
    setCurrentFocusedIndex(index);
    setOpenPreviewIndex(index);
  };

  useEffect(() => {
    if (isInstanceItemSelected && isInstanceItemVariant) {
      const { current } = ref;
      setAnchorEl(current);
      setCurrentFocusedIndex(index);
      setOpenPreviewIndex(index);
    } else {
      setAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstanceItemSelected]);

  const handleClickInstance = (event) => {
    if (isInstanceItemVariant) handleClick();
    else onSelectInstance(event, index, mId);
  };

  const [onClick, onDoubleClick] = useMouseClickEvents(handleClickInstance, handleOpenStory);

  return (
    <ListItem
      button
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      ref={isInstanceItemVariant ? dragRef : null}
      disabled={disabled}
      selected={isInstanceItemSelected && !disabled}
      $isInstanceItemVariant={isInstanceItemVariant}
    >
      <KanbanCard
        destination={destination}
        mRelatedMembers={mRelatedMembers}
        updatedAt={mUpdatedAt}
        storyTitle={storyTitle}
        blockTitle={blockTitle}
        title={title}
        mPublishingAt={mPublishingAt}
        items={items}
        mState={mState}
        assignedUsers={assignedUsers}
        platform={instanceType}
      />
      <Popper anchorEl={anchorEl} position="right-start">
        <InstancePreviewWrapper>
          <InstancePreview
            image={mThumbnailUrl}
            onClose={handleClose}
            onOpenStory={handleOpenStory}
            id={mId}
            title={title}
            mContentKey={mContentKey}
            assignees={assignedUsers}
            state={mState}
            metaData={mMetaData}
            destination={destination}
            platform={instanceType}
            publishingAt={mPublishingAt}
            isOpenStoryDisabled={isOpenStoryDisabled}
            instance={instance}
            isLinkingDisabled
          />
        </InstancePreviewWrapper>
      </Popper>
    </ListItem>
  );
};

const forwardedInstanceItem = forwardRef(InstanceItem);
export default forwardedInstanceItem;
