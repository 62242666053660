import { useContext, useCallback } from 'react';
import configCtx from 'contexts/configContext';

/**
 *  Hook to expose a function that returns the field for given field-id
 *
 * e.g: const [getFieldsForBlock]=useGetFieldsForBlock();
 *
 * const field=getFieldsForBlock('custom-tags');
 */

const useGetFieldsForBlock = () => {
  const { metadataForms } = useContext(configCtx);
  const form = metadataForms[0];

  const getFieldsForBlock = useCallback(
    (id, defaultField = {}) => {
      if (form?.fields) {
        const searchedField = form.fields.find((field) => field.id === id);
        if (searchedField) return searchedField;
      }

      return defaultField;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [getFieldsForBlock];
};

export default useGetFieldsForBlock;
