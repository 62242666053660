/**
 * Extracts tweet id from the last part of the embedded tweet url separated by '/'
 *
 * @param {String} url Embedded tweet url
 * @returns {String} extracted tweet id
 */

const TWITTER_URL_PREFIX = 'https://twitter.com/';

const getTweetIdFromURL = (url) => {
  if (!url) return null;
  if (typeof url === 'string' && url.startsWith(TWITTER_URL_PREFIX)) {
    const tokens = url.split('/');
    const id = tokens.slice(-1);
    return id[0] === '' ? null : id[0];
  }
  return null;
};

export default getTweetIdFromURL;
