/**
 * Check if the user is logged in and return either Login or Main screen.
 */
import React from 'react';
// import React from 'react';
import {
  AuthStates,
  getAuthState,
  getSignInUser,
  openFederatedSignIn,
  setAuthState,
  UseAuthAudit,
  UseHostedUI,
} from 'utils/Auth';
import InitialLoadingScreen from 'screens/loading';
import Login from './login/Login';
import Main from './main';
import AuthAudit from './authAudit';

/**
 * Returns true if the windows href indicates that it is redirected from HostedUI
 * Using presence of code search params to detect this.
 */
const isRedirectedFromHostedUI = () => {
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  return !!code;
};

const invokeSignIn = (authState) => {
  if (!authState || authState === AuthStates.NOT_VERIFIED) return true;
  return authState === AuthStates.VERIFIED ? false : !isRedirectedFromHostedUI();
};

const ScreensHandler = ({ context }) => {
  if (context.user) {
    const user = getSignInUser(context);
    return UseAuthAudit ? <AuthAudit props={context} /> : <Main user={user} />;
  }

  if (UseHostedUI) {
    const authState = getAuthState();
    if (!context.verified && invokeSignIn(authState)) {
      setAuthState(AuthStates.VERIFYING);
      openFederatedSignIn(context);
      return <div />;
    }
    if (authState === AuthStates.VERIFYING) {
      return <InitialLoadingScreen />;
    }
    return <div />;
  }

  return UseAuthAudit ? <AuthAudit props={context} /> : <Login />;
};

export default ScreensHandler;
