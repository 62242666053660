import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledTooltipTitleWrapper,
  StyledTooltipTitleHeader,
  StyledTooltipDescription,
} from './tabTooltip-styled';

const TabToolTip = ({ title, type }) =>
  title && (
    <StyledTooltipTitleWrapper type={type}>
      {type && <StyledTooltipTitleHeader>{type} Title</StyledTooltipTitleHeader>}
      <StyledTooltipDescription>{title}</StyledTooltipDescription>
    </StyledTooltipTitleWrapper>
  );

TabToolTip.propTypes = {
  /** title of the tab that should be shown in the tooltip */
  title: PropTypes.string.isRequired,
  /** type of content Tab */
  type: PropTypes.string,
};

TabToolTip.defaultProps = {
  type: '',
};

export default TabToolTip;
