import { makeStyles } from '@material-ui/styles';

const commonLabelStyle = (theme) => ({
  ...theme.typography.dina.button,
  fontWeight: 'normal',
  lineHeight: '14px',
});

const useStyles = makeStyles((theme) => ({
  button: {
    height: '32px',
    width: '168px',
    border: `0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
    borderRadius: '4px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    transition: '0.3s ease-in',
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  icon: {
    marginRight: '4px',
    marginLeft: '8px',
  },
  textOff: {
    ...commonLabelStyle(theme),
    color: theme.palette.dina.mediumEmphasis,
    '&:hover': {
      color: theme.palette.mediumEmphasis,
    },
  },
  textOn: {
    ...commonLabelStyle(theme),
  },
  calendarContainer: {
    borderRadius: '8px',
    boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2),
      0px 8px 10px 1px rgba(0,0,0,0.14),
      0px 3px 14px 2px rgba(0,0,0,0.12)`,
  },
}));

export default useStyles;
