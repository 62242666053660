/* eslint-disable import/no-extraneous-dependencies */
import cloneDeep from 'lodash/cloneDeep';

const merge = (policy1, policy2) => {
  const mergedPolicy = cloneDeep(policy1);

  policy2.forEach((resource) => {
    const mergedResourceObj = mergedPolicy.find(
      (policy) => policy.resourceName === resource.resourceName,
    );

    if (!mergedResourceObj) {
      mergedPolicy.push(resource);
    } else {
      const { permissions } = resource;

      permissions.forEach((permission) => {
        const mergedPermission = mergedResourceObj.permissions.find(
          (permit) => permit.action === permission.action,
        );

        if (!mergedPermission) {
          mergedResourceObj.permissions.push(permission);
        } else if (mergedPermission.access !== permission.access) {
          if (mergedPermission.access === 'allow' || permission.access === 'allow')
            mergedPermission.access = 'allow';
          else mergedPermission.access = 'deny';
        }
      });
    }
  });
  return mergedPolicy;
};

export default merge;
