import styled from '@emotion/styled';

import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as FeedsOff } from 'assets/icons/systemicons/HeaderNavbar/feeds_off.svg';
import Divider from 'components/divider';
import theme from 'theme/theme';
import transientOptions from 'theme/helpers';
import { calcContentHeight } from 'screens/main/utils';

import { navbarPosition } from './utils/navbarPosition';

export const StyledPaper = styled(Paper, transientOptions)`
  position: ${({ position }) => position === navbarPosition.RIGHT && 'absolute'};
  right: ${({ position }) => position === navbarPosition.RIGHT && 0};
  background: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
  display: flex;
  min-height: ${({ hasTitle, $isTickerVisible }) => calcContentHeight(hasTitle, $isTickerVisible)};
  border-right: ${({ position }) =>
    position === navbarPosition.RIGHT ? 'none' : '1px solid rgba(255, 255, 255, 0.12)'};
  border-left: ${({ position }) =>
    position === navbarPosition.RIGHT ? '1px solid rgba(255, 255, 255, 0.12)' : 'none'};
  width: 48px;
  z-index: 999;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledMenuItem = styled(MenuItem)`
  height: 48px;
  padding: 0 12px 0 11px;
  display: flex;
  justify-content: center;

  &:focus {
    icon {
      color: ${theme.palette.dina.iconSelectedWhite};
    }
  }

  &:hover {
    background-color: ${theme.palette.dina.whiteHoverOverlay};

    svg {
      path {
        fill: ${theme.palette.dina.iconSelectedWhite};
        fill-opacity: 1;
      }
    }
  }

  &.Mui-selected {
    background-color: ${theme.palette.dina.onFocus};

    &:hover {
      background-color: ${theme.palette.dina.onFocus};
    }
  }
`;

export const NavbarDivider = styled(Divider)`
  width: calc(100% - 16px);
  margin: 8px 0;
`;

export const TickerButton = styled(MenuItem)`
  position: absolute;
  bottom: 0px;
  height: 40px;
  width: 48px;
  padding: 0 12px 0 11px;
  display: flex;
  justify-content: center;

  &:focus {
    icon {
      color: ${theme.palette.dina.iconSelectedWhite};
    }
  }

  &:hover {
    background-color: ${theme.palette.dina.whiteHoverOverlay};

    svg {
      path {
        fill: ${theme.palette.dina.iconSelectedWhite};
        fill-opacity: 1;
      }
    }
  }
`;

export const TickerIcon = styled(FeedsOff)`
  width: 20px;
  height: 20px;
`;
