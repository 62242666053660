import React from 'react';
import PropTypes from 'prop-types';
import ChipInput from 'components/chipInput';
import searchbarPositions from 'components/searchTable/constants/searchbarPositions';
import { ReactComponent as CloseSmall } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/systemicons/search.svg';
import { InputAdornment } from '@material-ui/core';
import { Search, InputSearch, ClearButton } from './searchInput-styled';

const SearchInput = ({
  inputType,
  selectedItems,
  onDeleteSelectedItem,
  onValueChange,
  clearInput,
  setItems,
  showSearchIcon,
  usageType,
  inputRef,
  searchbarPosition,
}) => (
  <Search $searchbarPosition={searchbarPosition}>
    {inputType === 'default' && (
      <InputSearch
        $usageType={usageType}
        placeholder="Type to find..."
        disableUnderline
        autoFocus
        inputRef={inputRef}
        onChange={onValueChange}
        startAdornment={
          showSearchIcon && (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }
        endAdornment={
          inputType === 'default' &&
          inputRef?.current?.value !== '' && (
            <InputAdornment position="end">
              <ClearButton onClick={clearInput} role="presentation">
                <CloseSmall />
              </ClearButton>
            </InputAdornment>
          )
        }
      />
    )}

    {inputType === 'chip' && (
      <ChipInput
        chipInputItems={selectedItems}
        setItems={setItems}
        onDelete={onDeleteSelectedItem}
        onValueChange={onValueChange}
        inputRef={inputRef}
        placeholder="Type to find..."
        minHeight="40px"
        rootPadding="4px 6px"
        onUpdateInput={onValueChange}
        clearInputValueOnChange={false}
      />
    )}
  </Search>
);

SearchInput.propTypes = {
  /** type of input for search table */
  inputType: PropTypes.oneOf(['default', 'chip']),
  /** a list of selected items to be shown in 'chip' input  */
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** callback of the delete button of the chip in 'chip' input */
  onDeleteSelectedItem: PropTypes.func,
  /* Function to run on input value change */
  onValueChange: PropTypes.func,
  /* Function to run on clear input value */
  clearInput: PropTypes.func,
  /* Function to run on clear input value */
  setItems: PropTypes.func,
  /** Boolean to show search icon */
  showSearchIcon: PropTypes.bool,
  /** Type to use styles in settings search bar */
  usageType: PropTypes.string,
  /** Ref to input element  */
  inputRef: PropTypes.object,
  /** Postion of search bar Top or bottom */
  searchbarPosition: PropTypes.oneOf(Object.values(searchbarPositions)),
};

SearchInput.defaultProps = {
  inputType: 'default',
  selectedItems: [],
  onDeleteSelectedItem: () => {},
  onValueChange: () => {},
  clearInput: () => {},
  setItems: () => {},
  showSearchIcon: false,
  inputRef: null,
  usageType: '',
  searchbarPosition: searchbarPositions.TOP,
};

export default SearchInput;
