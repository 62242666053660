import React, { memo } from 'react';
import { markTypes, elementTypes } from 'components/editor/constants';
import { ReactComponent as DoneButtonIcon } from 'assets/icons/systemicons/editor/send.svg';
import MarkButton from '../markButton';
import ListButton from '../listButton';
import LinkButton from '../linkButton';
import DoneButton from '../doneButton';
import VerticalDivider from '../verticalDivider';
import Wrapper from '../wrapper';
import useStyles from './styles';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

const MessageToolbar = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Wrapper showRightMargin rightMarginWidth={8}>
          <MarkButton type={BOLD} />
          <MarkButton type={ITALIC} />
          <MarkButton type={UNDERLINE} />
          <MarkButton type={STRIKE_THROUGH} />
        </Wrapper>
        <VerticalDivider />
        <LinkButton />
        <VerticalDivider />
        <Wrapper showRightMargin rightMarginWidth={8}>
          <ListButton type={ORDERED_LIST} />
          <ListButton type={UNORDERED_LIST} />
        </Wrapper>
        <VerticalDivider />
      </div>

      <Wrapper>
        <DoneButton title="Send Message" iconComponent={<DoneButtonIcon />} />
      </Wrapper>
    </>
  );
};

export default memo(MessageToolbar);
