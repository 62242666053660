import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { overline, listItemLabel } from 'theme/styledTypography';
import theme from 'theme/theme';

export const boxIconStyle = css`
  margin: 8px;
  path {
    fill: ${theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

export const IconWrapper = styled('div')`
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 4px 0px 0px 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: ${theme.palette.dina.onSelected};
  svg {
    height: 16px;
  }
  path {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: ${theme.palette.dina.placeholderIcon};
    fill-opacity: 1;
  }
`;

export const Label = styled('div')`
  ${overline}
  font-weight: 500;
`;

export const Title = styled('p')`
  ${listItemLabel};
  padding-left: 44px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const RootWrapper = styled('div')`
  width: 100%;
  padding: 8px;
`;

export const TitleWrapper = styled('div')`
  flex: 1;
`;

export const PlaceholderWrapper = styled('div')`
  position: relative;
  border: 1px dashed ${theme.palette.dina.onSelected};
  border-radius: 4px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  :hover {
    background-color: ${theme.palette.dina.whiteHoverOverlay};
  }
`;
