import React from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from 'components/buttons/button';
import { ReactComponent as ArchiveOffSrc } from 'assets/icons/systemicons/ellipsis_menu/archive_off.svg';
import { ReactComponent as UnarchiveOffSrc } from 'assets/icons/systemicons/ellipsis_menu/archive_unarchive_off.svg';
import { ReactComponent as BookmarkOffSrc } from 'assets/icons/systemicons/ellipsis_menu/favorite_off.svg';
import { ReactComponent as BookmarkOnSrc } from 'assets/icons/systemicons/ellipsis_menu/favorite_on.svg';
import { ReactComponent as JoinSrc } from 'assets/icons/systemicons/ellipsis_menu/participate_join.svg';
import { ReactComponent as LeaveSrc } from 'assets/icons/systemicons/ellipsis_menu/participate_leave.svg';
// import { ReactComponent as Share } from 'assets/icons/systemicons/ellipsis_menu/share_off.svg';
import { ButtonText, ButtonWrapper, ButtonsWrapper } from './submenu-styled';

const Button = ({ type, onClick, Icon, text }) => (
  <ButtonWrapper>
    <ButtonComponent shape="action-button" type={type} variant="text" size={60} onClick={onClick}>
      <p>
        {Icon}
        <ButtonText>{text}</ButtonText>
      </p>
    </ButtonComponent>
  </ButtonWrapper>
);

const SubMenu = ({ groupType, archived, bookmarked, joined, clickHandler, showArchiveButton }) => {
  const handleArchiveButtonClick = (event) => {
    event.stopPropagation();
    clickHandler('archive');
  };

  const handleBookmarkButtonClick = (event) => {
    event.stopPropagation();
    clickHandler('bookmark');
  };

  const handleJoinButtonClick = (event) => {
    event.stopPropagation();
    clickHandler('join');
  };

  // const handleShareButtonClick = (event) => {
  //   event.stopPropagation();
  //   clickHandler('share');
  // };

  return (
    <ButtonsWrapper>
      {(showArchiveButton || groupType === 'pitch') && (
        <Button
          type="danger"
          onClick={handleArchiveButtonClick}
          Icon={archived ? <UnarchiveOffSrc /> : <ArchiveOffSrc />}
          text={archived ? 'Unarchive' : 'Archive'}
        />
      )}

      <Button
        type="warning"
        onClick={handleBookmarkButtonClick}
        Icon={bookmarked ? <BookmarkOnSrc /> : <BookmarkOffSrc />}
        text={bookmarked ? 'Unmark' : 'Bookmark'}
      />
      {!archived && (
        <Button
          type="cta"
          onClick={handleJoinButtonClick}
          Icon={joined ? <LeaveSrc /> : <JoinSrc />}
          text={joined ? 'Leave' : 'Join'}
        />
      )}
      {/* <Button // This button is disabled for now. Share functionality will be added leter
        type="significant"
        onClick={handleShareButtonClick}
        Icon={<Share />}
        text="Share"
      /> */}
    </ButtonsWrapper>
  );
};

SubMenu.propTypes = {
  clickHandler: PropTypes.func,
  bookmarked: PropTypes.bool,
};

SubMenu.defaultProps = {
  clickHandler: () => {},
  bookmarked: false,
};

export default SubMenu;
