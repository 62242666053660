const saveExtUrlOnContext = (extUrlResult, config) => {
  // eslint-disable-next-line no-param-reassign
  config.externalUrls = [
    ...extUrlResult.data.getMemberFromId.map(({ configs }) => ({
      externalUrl: configs[0].value,
      mTitle: configs[0].name,
    })),
  ];
};

export default saveExtUrlOnContext;
