import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import GET_TEAM_MEMBERS from 'graphql/queries/getTeamMembers';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import memberTypes from 'graphql/memberTypes';
import { getMembersQuery, getMembersOfQuery } from 'graphql/queryVariables';
import UPDATE_TEAM from 'graphql/mutations/updateTeam';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Details from './details-view';

const TeamDetailsContainer = (props) => {
  const { onBackButtonClick, teamId, ...rest } = props;

  const {
    data: getMembersData,
    error: membersError,
    loading: membersLoading,
  } = useQuery(GET_TEAM_MEMBERS, {
    variables: getMembersQuery(teamId, memberTypes.TEAM_USER),
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: getMembersOfData,
    error: membersOfError,
    loading: membersOfLoading,
  } = useQuery(GET_MEMBERS_OF, {
    variables: getMembersOfQuery(teamId, memberTypes.DEPARTMENT_TEAM),
    fetchPolicy: 'cache-and-network',
  });

  const [updateTeam] = useMutation(UPDATE_TEAM);
  const updateTeamTitle = (id, newTitle) => {
    const input = {
      mId: id,
      mTitle: newTitle,
    };
    updateTeam({
      variables: {
        input,
      },
    });
  };

  if (membersLoading || membersOfLoading) return <LoadingIndicator />;
  if (membersError || membersOfError) return `Error!`;

  return (
    <Details
      onBackButtonClick={onBackButtonClick}
      teamId={teamId}
      teamMembers={getMembersData.getMembers}
      teamDepartments={getMembersOfData.getMembersOf}
      updateTeamTitle={updateTeamTitle}
      {...rest}
    />
  );
};

TeamDetailsContainer.propTypes = {
  onBackButtonClick: PropTypes.func,
};

TeamDetailsContainer.defaultProps = {
  onBackButtonClick: () => {},
};

export default TeamDetailsContainer;
