import { elementTypes } from './types';
import Abstract from '../components/abstract';
import Author from '../components/author';
import CheckIn from '../components/checkIn';
import Content from '../components/content';
import Description from '../components/description';
import EmbeddedTweet from '../components/embeddedTweet';
import EmbeddedVideo from '../components/embeddedVideo';
import Fact from '../components/factBox';
import Feed from '../components/feed';
import Gif from '../components/gif';
import Heading from '../components/heading';
import HorizontalRule from '../components/horizontalRule';
import Html from '../components/html';
import Image from '../components/image';
import Info from '../components/info';
import Link from '../components/link';
import List from '../components/list';
import ListItem from '../components/listItem';
import LiveStream from '../components/liveStream';
import Location from '../components/location';
import Media from '../components/media';
import Mention from '../components/mention';
import Notification from '../components/notification';
import Overline from '../components/overline';
import Paragraph from '../components/paragraph';
import PhotoGallery from '../components/photoGallery';
import Placeholder from '../components/placeholder';
import PrimaryAutomation from '../components/primaryAutomation';
import Privacy from '../components/privacy';
import Quote from '../components/quote';
import QuoteBox from '../components/quoteBox';
import SecondaryAutomation from '../components/secondaryAutomation';
import Section from '../components/section';
import SimpleParagraph from '../components/simpleParagraph';
import Source from '../components/source';
import Tags from '../components/tags';
import Title from '../components/title';
import TweetThread from '../components/tweetThread';
import Video from '../components/video';

const getParagraph = (element, viewType) => {
  if (viewType !== 'linear') {
    const { children } = element;
    if (children.length && !children[0].text) return Paragraph;
    return SimpleParagraph;
  }
  return Paragraph;
};

const elementComponents = (props, viewType) => {
  const { element } = props;
  const { type } = element;
  const { primaryTypes, secondaryTypes } = elementTypes;

  switch (type) {
    /** paragraph component */
    case elementTypes.PARAGRAPH:
      return getParagraph(element, viewType);
    /** Heading component */
    case elementTypes.HEADING_ONE:
    case elementTypes.HEADING_TWO:
    case elementTypes.HEADING_THREE:
    case elementTypes.HEADING_FOUR:
      return Heading;
    /** Horizontal component */
    case elementTypes.HORIZONTAL_RULE:
      return HorizontalRule;
    /** Link component */
    case elementTypes.LINK:
      return Link;
    /** List Item component */
    case elementTypes.LIST_ITEM:
      return ListItem;
    /** Mention component */
    case elementTypes.MENTION:
      return Mention;
    /** Ordered and Unordered list component */
    case elementTypes.ORDERED_LIST:
    case elementTypes.UNORDERED_LIST:
      return List;
    /** Image component */
    case elementTypes.IMAGE:
      return Image;
    /** Video component */
    case elementTypes.VIDEO:
      return Video;
    /** Content component */
    case elementTypes.CONTENT:
      return Content;
    /** Info component */
    case elementTypes.INFO:
      return Info;
    /** Placeholder component */
    case elementTypes.PLACEHOLDER:
      return Placeholder;
    /** Quote component */
    case elementTypes.QUOTE:
      return Quote;
    /** Tweet thread component */
    case elementTypes.TWEET_THREAD:
      return TweetThread;
    /** Description component */
    case elementTypes.DESCRIPTION:
      return Description;
    /** Gif component */
    case elementTypes.GIF:
      return Gif;
    case elementTypes.PHOTO_GALLERY:
      return PhotoGallery;
    /** Tags component */
    case elementTypes.TAGS:
      return Tags;
    /** Author component */
    case elementTypes.AUTHOR:
      return Author;
    /** Source component */
    case elementTypes.SOURCE:
      return Source;
    /** Title component */
    case elementTypes.TITLE:
      return Title;
    /** Privacy component */
    case elementTypes.PRIVACY:
      return Privacy;
    /** Section component */
    case elementTypes.SECTION:
      return Section;
    /** Overline component */
    case elementTypes.OVERLINE:
      return Overline;
    /** Abstract component */
    case elementTypes.ABSTRACT:
      return Abstract;
    /** quotebox component */
    case elementTypes.QUOTE_BOX:
      return QuoteBox;
    /** Fact component */
    case elementTypes.FACT:
      return Fact;
    /** Media component */
    case elementTypes.MEDIA:
      return Media;
    /** CheckIn component */
    case elementTypes.CHECK_IN:
      return CheckIn;
    /** Location component */
    case elementTypes.LOCATION:
      return Location;
    /** Feed component */
    case elementTypes.FEED:
      return Feed;
    /** HTML component */
    case elementTypes.HTML:
      return Html;
    /** Embedded Tweet component */
    case elementTypes.EMBEDDED_TWEET:
      return EmbeddedTweet;
    /** Embedded video component */
    case elementTypes.EMBEDDED_VIDEO:
      return EmbeddedVideo;
    /** Livestream component */
    case elementTypes.LIVE_STREAM:
      return LiveStream;
    /** Notification component */
    case elementTypes.NOTIFICATION:
      return Notification;
    /** primary automation component */
    case primaryTypes.CAMERA:
    case primaryTypes.PACKAGE:
    case primaryTypes.VOICE_OVER:
    case primaryTypes.LIVE:
    case primaryTypes.FULLSCREEN_GRAPHICS:
    case primaryTypes.DVE:
    case primaryTypes.JINGLE:
    case primaryTypes.TELEPHONE:
    case primaryTypes.ADLIB:
    case primaryTypes.BREAK:
      return PrimaryAutomation;
    /** secondary automataion component */
    case secondaryTypes.OVERLAY_GRAPHICS:
    case secondaryTypes.AUDIO:
    case secondaryTypes.TEXT_TELE_PROMPTER:
    case secondaryTypes.ACCESSORY:
      return SecondaryAutomation;

    default:
      return Paragraph;
  }
};

export default elementComponents;
