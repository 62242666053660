import { Editor } from 'slate';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import insertParagraph from '../components/paragraph/utils/insertParagraph';

const { VIDEO, IMAGE, GIF } = elementTypes;
const assetTypes = [VIDEO, IMAGE, GIF];

/**
 * Handles onKeyDown event on video elements
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @returns {Object} SlateJS editor instance
 */

const onImageKeyDown = (editor, event, update) => {
  const [match] = Editor.nodes(editor, {
    match: ({ type }) => assetTypes.includes(type),
  });

  if (match) {
    const { key } = event;
    const isEnter = key === 'Enter';
    const isBackspace = key === 'Backspace';

    if (isEnter) {
      event.preventDefault();
      insertParagraph(editor);
    }

    if (isBackspace && update) {
      const [element] = match;
      const { src } = element.data;

      update({
        type: actionTypes.ASSET_REMOVE,
        payload: { document: editor.children, src },
      });
    }
  }

  return editor;
};

export default onImageKeyDown;
