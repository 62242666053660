import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useSettingsValue from 'hooks/useSettingsValue';
import Dialog from 'components/dialog';
import Scrollbar from 'screens/main/components/scrollbar';
import AddMemberDialog from 'components/addMemberDialog';
import DetailList from '../../../detailList';
import AddContent from '../../../addContent';
import AddDate from '../../../addDate';
import AddItem from '../../../addItem';
import AddDescription from '../../../addDescription';
import ShowItem from '../../../showItem';

const StyledInfoWrapper = styled('div')`
  height: 100%;
`;

const InfoView = ({
  description,
  updateDescription,
  updateEmail,
  updatePhone,
  updateDateOfBirth,
  updateReadSpeed,
  teams,
  addTeamsToContact,
  departments,
  addDepartmentsToContact,
  containerRef,
  properties,
  editDisabled,
}) => {
  const [openTeamsDialog, setOpenTeamsDialog] = useState(false);
  const [openDepartmentsDialog, setOpenDepartmentsDialog] = useState(false);
  const { email, firstName, phone, dateOfBirth, readSpeed } = properties;
  const [getSettingsValue] = useSettingsValue();
  const rundownOnly = getSettingsValue('rundown.rundownOnly') === 'true';
  return (
    <StyledInfoWrapper>
      <Scrollbar>
        {editDisabled ? (
          <ShowItem title="Description" content={description} />
        ) : (
          <AddDescription
            addItemTitle="Add Description..."
            onUpdate={updateDescription}
            helperText="Type a short Bio"
            {...{ description }}
          />
        )}

        {editDisabled ? (
          <ShowItem title="E-MAIL" content={email} />
        ) : (
          <AddContent
            addItemTitle="Add an Email"
            content={email}
            helperText="Email address"
            showItemTitle="E-MAIL"
            onUpdate={updateEmail}
            type="email"
            inputLabel="E-MAIL"
          />
        )}
        {editDisabled ? (
          <ShowItem title="MOBILE PHONE" content={phone} />
        ) : (
          <AddContent
            addItemTitle="Add a Mobile Phone number"
            content={phone}
            showItemTitle="MOBILE PHONE"
            helperText="Type a mobile phone number here"
            onUpdate={updatePhone}
            type="tel"
            inputLabel="MOBILE PHONE"
          />
        )}

        <AddContent
          addItemTitle="Add Reading Speed"
          content={readSpeed}
          showItemTitle="READING SPEED (WORDS PER MINUTE)"
          helperText="Words per minute"
          onUpdate={updateReadSpeed}
          inputLabel="READING SPEED"
        />

        {editDisabled ? (
          <ShowItem title="Birthday" content={dateOfBirth} />
        ) : (
          <AddDate
            addItemTitle="Add Birthday"
            content={dateOfBirth}
            showItemTitle="Birthday"
            onUpdate={(newValue) => {
              updateDateOfBirth(newValue);
            }}
            inputLabel="BIRTHDAY"
          />
        )}

        {!rundownOnly && <DetailList listTitle="Teams" members={teams} avatarVariant="Team" />}
        {!rundownOnly && !editDisabled && (
          <>
            <AddItem
              title={`Add ${firstName} to Team(s)...`}
              onClick={() => setOpenTeamsDialog(true)}
            />
            <Dialog
              open={openTeamsDialog}
              container={containerRef.current}
              onClose={() => setOpenTeamsDialog(false)}
            >
              <AddMemberDialog
                members={teams}
                variant="Team"
                dialogTitle="Teams"
                onCancel={() => setOpenTeamsDialog(false)}
                onOk={(members) => {
                  addTeamsToContact(members);
                  setOpenTeamsDialog(false);
                }}
              />
            </Dialog>
          </>
        )}

        {!rundownOnly && (
          <DetailList listTitle="Departments" members={departments} avatarVariant="People" />
        )}
        {!rundownOnly && !editDisabled && (
          <>
            <AddItem
              title={`Add ${firstName} to Department(s)...`}
              onClick={() => setOpenDepartmentsDialog(true)}
            />
            <Dialog
              open={openDepartmentsDialog}
              container={containerRef.current}
              onClose={() => setOpenDepartmentsDialog(false)}
            >
              <AddMemberDialog
                members={departments}
                variant="Department"
                dialogTitle="Department"
                onCancel={() => setOpenDepartmentsDialog(false)}
                onOk={(members) => {
                  addDepartmentsToContact(members);
                  setOpenDepartmentsDialog(false);
                }}
              />
            </Dialog>
          </>
        )}
      </Scrollbar>
    </StyledInfoWrapper>
  );
};

InfoView.propTypes = {
  /** Defines the bio of the user  */
  description: PropTypes.string,
  /** Callback to be invoked on description change  */
  updateDescription: PropTypes.func,
  /** Callback to be invoked on email change  */
  updateEmail: PropTypes.func,
  /** Callback to be invoked on phone number change  */
  updatePhone: PropTypes.func,
  /** Callback to be invoked on birthday(date) change  */
  updateDateOfBirth: PropTypes.func,
  /** Defines the list of teams that this contact belongs to  */
  teams: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked on addContactToDepartment dialog confirmation  */
  addContactToTeam: PropTypes.func,
  /** Defines the list of departments teams that this contact belongs to  */
  departments: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked on addContactToDepartment dialog confirmation  */
  addContactToDepartment: PropTypes.func,
  /** Defined if the contact is readonly or not  */
  editDisabled: PropTypes.bool,
};

InfoView.defaultProps = {
  description: '',
  updateDescription: () => {},
  updateEmail: () => {},
  updatePhone: () => {},
  updateDateOfBirth: () => {},
  teams: [],
  addContactToTeam: () => {},
  departments: [],
  addContactToDepartment: () => {},
  editDisabled: false,
};

export default InfoView;
