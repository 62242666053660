import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import DatePicker from 'components/datePicker/DatePicker';
import Scrollbar from 'screens/main/components/scrollbar';
import useStyles from './list-styles';
import RundownGroupMaster from './listGroup-master';
import RundownGroup from './listGroup';

const RundownList = ({
  onDateChanged,
  selectedDates,
  setPreviewRundown,
  hideMasterRundowns,
  canCreateNewRundown,
}) => {
  const classes = useStyles();

  const memoizedDatePicker = useMemo(
    () => <DatePicker onDateChange={onDateChanged} />,
    [onDateChanged],
  );

  return (
    <>
      <div className={classes.sidebarHeader}>
        <Typography className={classes.sidebarHeaderText}>Rundowns</Typography>
      </div>
      {memoizedDatePicker}
      <Scrollbar>
        <RundownGroup
          id={1}
          key={1}
          title="Rundowns"
          selectedDates={selectedDates}
          setPreviewRundown={setPreviewRundown}
          canCreateNewRundown={canCreateNewRundown}
        />
        {!hideMasterRundowns && (
          <RundownGroupMaster
            id={2}
            key={2}
            title="Master Rundowns"
            selectedDates={selectedDates}
            setPreviewRundown={setPreviewRundown}
          />
        )}
      </Scrollbar>
    </>
  );
};

RundownList.propTypes = {
  /** change date function */
  onDateChanged: PropTypes.func,
  /** function set the rundown that should be previewed */
  setPreviewRundown: PropTypes.func,
  /** selected dates */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** boolean that indicates hides the master rundowns group */
  hideMasterRundowns: PropTypes.bool,
  /** Boolean that allows creation of new rundown */
  canCreateNewRundown: PropTypes.bool,
};

RundownList.defaultProps = {
  onDateChanged: () => {},
  setPreviewRundown: () => {},
  selectedDates: [],
  hideMasterRundowns: false,
  canCreateNewRundown: false,
};

export default RundownList;
