import React from 'react';
import PropTypes from 'prop-types';
import Publish from './publish/Publish';
import Placeholder from './placeHolder';
import People from './people/People';
import Team from './team/team';
import Department from './department/Department';
import Rundown from './rundown';
import Category from './category/category';

export const avatarVariants = {
  people: 'People',
  team: 'Team',
  department: 'Department',
  publish: 'Publish',
  placeholder: 'Placeholder',
  category: 'Category',
  rundown: 'Rundown',
};

const Avatar = ({ variant, children, size, width, height, ...rest }) => {
  const sizeHelper = (avatarSize) => {
    if (avatarSize > 0) return { width: avatarSize, height: avatarSize };
    return { width, height };
  };
  switch (variant) {
    case avatarVariants.publish:
      return (
        <Publish {...sizeHelper(size)} {...rest}>
          {children}
        </Publish>
      );
    case avatarVariants.placeholder:
      return <Placeholder {...sizeHelper(size)} {...rest} />;
    case avatarVariants.team:
      return (
        <Team {...sizeHelper(size)} {...rest}>
          {children}
        </Team>
      );
    case avatarVariants.department:
      return (
        <Department {...sizeHelper(size)} {...rest}>
          {children}
        </Department>
      );
    case avatarVariants.category:
      return (
        <Category {...sizeHelper(size)} {...rest}>
          {children}
        </Category>
      );
    case avatarVariants.rundown:
      return (
        <Rundown {...sizeHelper(size)} {...rest}>
          {children}
        </Rundown>
      );
    default:
      return (
        <People {...sizeHelper(size)} {...rest}>
          {children}
        </People>
      );
  }
};

Avatar.propTypes = {
  /** Elements that should be rendered on the avatar (text or icon). */
  children: PropTypes.node,
  /** Defines type of the avatar.
   * Values: "People" or "Publish" or "Placeholder"
   */
  variant: PropTypes.oneOf(Object.values(avatarVariants)),
  /** URL of the image that should be rendered on avatar. */
  src: PropTypes.string,

  /** Default image in case of source is broken */
  defaultSrc: PropTypes.string,

  /** Defines the publishing point.
   * Values: "Twitter"
   */
  publishingPoint: PropTypes.string,
  /** Defines type of the status indicator.
   * Values: "Available" or "Away" or "Idle"
   */
  activityStatus: PropTypes.oneOf(['', 'Available', 'Away', 'Idle']),
  /** Defines the width and height of the avatar.
   * Default value: 48
   */
  size: PropTypes.number,
  /** Defines the width of the avatar. */
  width: PropTypes.number,
  /** Defines the height of the avatar. */
  height: PropTypes.number,
  /** Defines whether the avatar is selected or not */
  checked: PropTypes.bool,
  /** Callback to be invoked in avatar click */
  onClick: PropTypes.func,
  /** shows an plus symbol on top of avatar variant department */
  uploadEnabled: PropTypes.bool,
  /** whether to show pointer or default cursor */
  pointer: PropTypes.bool,
};

Avatar.defaultProps = {
  children: null,
  variant: 'People',
  src: '',
  defaultSrc: null,
  publishingPoint: '',
  activityStatus: '',
  size: 0,
  width: 48,
  height: 48,
  checked: false,
  onClick: () => {},
  uploadEnabled: false,
  pointer: false,
};

export default Avatar;
