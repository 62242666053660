import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import GET_RUNDOWN from 'graphql/queries/getRundown';
import DataLoader from 'screens/rundown/dataLoader-container';
import DetailsView from './details-view';
import { Wrapper } from './details-styled';

const DetailsContainer = ({ previewRundown, setPreviewRundown }) => {
  const { id, image, title } = previewRundown;
  const [instancesLoading, setInstancesLoading] = useState(false);

  const { data, error, loading } = useQuery(GET_RUNDOWN, {
    variables: {
      input: {
        mId: id,
        mRefId: id,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <div>{error.message}</div>;

  return (
    <Wrapper>
      {data && (
        <DataLoader mId={id} refId={id} data={data} setInstanceDataLoading={setInstancesLoading} />
      )}
      <DetailsView
        image={image}
        setPreviewRundown={setPreviewRundown}
        loading={loading || instancesLoading}
        title={title}
        data={data}
        publishingtime={data?.getRundown?.mPublishingAt}
      />
    </Wrapper>
  );
};

DetailsContainer.propTypes = {
  previewRundown: PropTypes.objectOf(PropTypes.string),
  setPreviewRundown: PropTypes.func,
};

DetailsContainer.defaultProps = {
  previewRundown: null,
  setPreviewRundown: () => {},
};

export default DetailsContainer;
