import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    height: '100%',
    display: 'flex',
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  vertDiv: {
    width: 1,
    height: 24,
    backgroundColor: palette.dina.dividerLight,
  },
}));

const VerticalDivider = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.vertDiv} />
    </div>
  );
};

export default memo(VerticalDivider);
