/**
 * Hook for handling hover events
 *
 * How to use:
 *
 * import useHover from 'hooks/useHover';
 *
 * const [hoverRef, isHovered] = useHover();
 * ...
 * <div ref={hoverRef}>
 *  {isHovered ? '😁' : '☹️'}
 * </div>
 */
import { useEffect, useRef, useState } from 'react';

const useHover = () => {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseleave', handleMouseOut);
        };
      }
      return undefined;
    },
    [], // Recall only if ref changes
  );

  return [ref, value];
};

export default useHover;
