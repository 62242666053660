import styled from '@emotion/styled';
import { itemLabelMedium } from 'theme/styledTypography';
import colors from 'theme/colors';

export const GroupPermissionTableRow = styled('div')`
  width: 100%;
  display: flex;
  height: 40px;
  border-bottom: 1px solid ${colors.dividerLight};
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: center;
  ${itemLabelMedium}
`;

export const CheckboxCell = styled('div')`
  margin-left: 8px;
`;

export const PermissionCell = styled('div')`
  flex: 1;
  margin-left: 8px;
`;

export const ResourceTypesCell = styled('div')`
  width: 160px;
  flex-shrink: 0;
`;
