import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.dina.surfaceCardDark,
    boxShadow: `0px 0px 1px ${theme.palette.dina.boxShadowDark},
     -8px 0px 8px ${theme.palette.dina.boxShadowDark}`,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '48px',
    padding: '0px 12px 0px 16px',
    background: theme.palette.dina.blackHoverOverlay,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.dina.subtitle1,
    color: theme.palette.dina.highEmphasis,
    cursor: 'default',
  },
  closeButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    '&:hover': {
      '& path': {
        'fill-opacity': 1,
      },
    },
    cursor: 'pointer',
  },

  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0px 8px',
    background: theme.palette.dina.backgroundResting,
  },

  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '56px',
    padding: '6px 8px',
    background: theme.palette.dina.blackHoverOverlay,
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    borderTop: `1px solid ${theme.palette.dina.dividerLight}`,
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '40px',
    background: theme.palette.dina.blackHoverOverlay,
    borderRadius: '2px',
  },
  searchInput: {
    ...theme.typography.dina.body2,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '4px 32px 4px 8px',
    color: theme.palette.dina.highEmphasis,
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    borderRadius: '1px',
    '&:hover': {
      borderColor: theme.palette.dina.inputBorderFocused,
    },
  },
  focused: {
    background: 'rgba(0, 30, 85, 0.16)',
    borderBottom: `1px solid ${theme.palette.dina.inputBorderFocused}`,
  },
  disabled: {},
  clearButton: {
    position: 'absolute',
    right: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    '&:hover': {
      '& path': {
        'fill-opacity': 1,
      },
    },
  },

  tableContainer: {
    width: '100%',
    flex: 1,
  },

  timelineContainer: {
    width: '100%',
    minHeight: `max(calc(100% - 192px), 200px)`,
  },

  highlightMatch: {
    color: theme.palette.dina.blackHighEmphasis,
    backgroundColor: theme.palette.dina.statusSearchMatchBG,
  },
  footer: {
    height: '56px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 10px',
  },
  button: {
    width: '192px',
    marginLeft: '3px',
  },
  buttonText: {
    color: theme.palette.dina.mediumEmphasis,
  },
  resourceTimelineHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px',
  },
  typeFilter: {},
  toggle: {
    color: theme.palette.dina.highEmphasis,
  },
  toggleThumb: {
    color: theme.palette.dina.highEmphasis,
  },
  checked: {},
  track: {},
  primary: {
    '&$checked + $track': {
      backgroundColor: theme.palette.dina.iconSelected,
    },
  },
}));

export default useStyles;
