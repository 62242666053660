const shouldIgnoreSetNotification = (payload) => {
  if (!payload) return true;
  // in the following case, notification was set during story/instance or team/department assignment
  // this is a related payload, which is only for updating list of messages in 'DiNA Notifications'
  if (payload.convoType === 'assignment') return true;
  if (payload.mType === 'message' && payload.message?.crudAction !== 'INSERT') return true;
  return false;
};

export default shouldIgnoreSetNotification;
