import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    ...theme.typography.dina.h7,
  },
  formControlRoot: {
    marginLeft: '16px',
    height: '40px',
  },
  formControlLabel: {
    ...theme.typography.dina.listItemLabel,
    marginLeft: '6px',
  },
}));

export default useStyles;
