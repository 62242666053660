import styled from '@emotion/styled';
import { captionMedium } from 'theme/styledTypography';
import colors from 'theme/colors';

export const HtmlContent = styled('div')`
  white-space: normal;
  user-select: text !important;
  background-color: ${colors.highEmphasis};
  color: ${colors.blackHighEmphasis};
  padding: 12px;
  border-radius: 8px;
  width: 576px;
  height: 324px;
  transform: scale(0.5);
  transform-origin: 0 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${colors.dividerDark};
  }
`;

export const PreviewWrapper = styled('div')`
  width: 288px;
  height: 162px;
  border: 1px dashed ${colors.iconInactive};
  border-radius: 8px;
  background-color: ${colors.blackHoverOverlay};
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  color: ${colors.mediumEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;
  ${captionMedium}
  font-weight: normal;
`;
