/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';

import Drawer from '@material-ui/core/Drawer';

import transientOptions from 'theme/helpers';
import { calcContentHeight } from 'screens/main/utils';

export const StyledDrawer = styled(Drawer, transientOptions)`
  max-width: 327px;
  z-index: 50;

  & .MuiDrawer-paper {
    min-width: 327px;
    max-width: 327px;
    top: ${({ hasTitle }) => (hasTitle ? '120px' : '96px')};
    height: ${({ hasTitle, $isTickerVisible }) => calcContentHeight(hasTitle, $isTickerVisible)};
  }

  & .MuiDrawer-paperAnchorLeft {
    left: 48px;
    box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25);
  }
`;
