import styled from '@emotion/styled';
import theme from 'theme/theme';
import transientOptions from 'theme/helpers';
import { css } from '@emotion/react';
import { Input } from '@material-ui/core';
import searchbarPositions from 'components/searchTable/constants/searchbarPositions';

const {
  dividerLight,
  highEmphasis,
  inputBorderFocused,
  blackHoverOverlay,
  body2,
  inputBorderResting,
  backgroundSelected,
} = theme.palette.dina;

const settingsStyle = css`
  border: 1px solid ${inputBorderResting};
  border-radius: 6px;
`;

const defaultStyle = css`
  border-bottom: 1px solid ${dividerLight};
  border-radius: 1px;
`;

const borderBottom = css`
  border-bottom: 1px solid ${dividerLight};
`;
const borderTop = css`
  border-top: 1px solid ${dividerLight};
`;

export const Search = styled('div', transientOptions)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 8px;
  background: ${blackHoverOverlay};
  ${({ $searchbarPosition }) => {
    if ($searchbarPosition === searchbarPositions.TOP) return borderBottom;
    return borderTop;
  }}
`;

export const InputSearch = styled(Input, transientOptions)`
  ${body2};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 8px 0px 8px;
  background: ${blackHoverOverlay};
  color: ${highEmphasis};
  ${({ $usageType }) => {
    if ($usageType === 'settings') return settingsStyle;
    return defaultStyle;
  }}
  :hover {
    border-color: ${inputBorderFocused};
  }
  :focus {
    background-color: ${backgroundSelected};
    border-color: ${inputBorderFocused};
  }
`;

export const ClearButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;
