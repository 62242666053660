import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { ReactComponent as SendIcon } from 'assets/icons/systemicons/editor/send.svg';
import useStyles from './styles';

const DoneButton = ({ title, iconComponent }) => {
  const classes = useStyles();

  const { onDone } = useEditorContext();

  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      onDone();
    },
    [onDone],
  );

  return (
    <div className={classes.root} title={title} role="presentation" onMouseDown={onMouseDown}>
      {iconComponent}
    </div>
  );
};

DoneButton.propTypes = {
  /** title of the button, showed in tooltip */
  title: PropTypes.string,
  /** icon of done button */
  iconComponent: PropTypes.node,
};

DoneButton.defaultProps = {
  title: '',
  iconComponent: <SendIcon />,
};

export default memo(DoneButton);
