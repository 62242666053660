/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ConfigCtx from 'contexts/configContext';
import { IconButton } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import useStyles from './mediaViewer-styles';

export const Wrapper = ({ children, onClose }) => {
  const classes = useStyles();
  return (
    <div className={classes.imageDiv}>
      <IconButton title="Close" classes={{ root: classes.button }} onClick={onClose}>
        <CloseIcon className={classes.icon} />
      </IconButton>
      {children}
    </div>
  );
};

const MediaViewer = ({ asset, getMetadataValue, onClose }) => {
  const classes = useStyles();
  const { feeds } = useContext(ConfigCtx);

  const { itemType, mMetaData, mContentUrl } = asset;
  const getValue = getMetadataValue(mMetaData);

  const fromFeed = feeds.find(
    (f) => f?.mRefId?.toLowerCase() === getValue('provider')?.toLowerCase(),
  );
  const accessToken = fromFeed && fromFeed.accessToken ? fromFeed.accessToken : '';
  const srcUrl = `
    ${
      mContentUrl ||
      getValue('proxy') ||
      getValue('src') ||
      getValue('videoPreviewUri') ||
      getValue('uri') ||
      getValue('previewUri')
    }${accessToken}`;

  switch (itemType) {
    case 'image':
      return (
        <Wrapper onClose={onClose}>
          <img src={srcUrl} className={classes.image} alt="imageUrl" />
        </Wrapper>
      );
    case 'video':
      return (
        <video controls width="800" height="500" className={classes.video}>
          <source src={srcUrl} type="video/mp4" />
        </video>
      );
    case 'audio':
      return (
        <audio controls className={classes.audio}>
          <source src={srcUrl} type="audio/mpeg" />
        </audio>
      );
    default:
      return <div />;
  }
};

MediaViewer.propTypes = {
  /** asset info for media view */
  asset: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  ),
  /** getMetadataValue callback function */
  getMetadataValue: PropTypes.func,
};

MediaViewer.defaultProps = {
  asset: {},
  getMetadataValue: () => {},
};

export default MediaViewer;
