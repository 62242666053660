import React, { useCallback, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import theme from 'theme/theme';
import styled from '@emotion/styled';
import { useReadOnly, useSelected, useSlate, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import variants from 'components/editor/constants/types/editorVariants';

const Wrapper = styled('div')`
  border-radius: 4px;
  background: ${(props) => props.showHighlightedBackground && theme.palette.dina.blackHoverOverlay};
`;

const SelectedElement = ({ children, element }) => {
  const isReadOnly = useReadOnly();
  const isSelected = useSelected(element);
  const { variant } = useEditorContext();
  const showHighlight = !isReadOnly && isSelected;
  const showHighlightedBackground = showHighlight && variant !== variants.MESSAGE;
  const editor = useSlate();
  const rootRef = useRef();

  const ensureSelection = useCallback(
    (event) => {
      try {
        if (!isReadOnly && !isSelected && ReactEditor.hasDOMNode(editor, event.target)) {
          const target = ReactEditor.findEventRange(editor, event);
          // console.log(target);

          if (target) Transforms.select(editor, target);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReadOnly, isSelected, editor],
  );

  useEffect(() => {
    showHighlight &&
      rootRef.current &&
      rootRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
  }, [showHighlight]);

  return (
    <Wrapper
      ref={rootRef}
      role="presentation"
      onClick={ensureSelection}
      showHighlightedBackground={showHighlightedBackground}
    >
      {children}
    </Wrapper>
  );
};

SelectedElement.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

SelectedElement.defaultProps = {
  children: null,
  element: {},
};

export default memo(SelectedElement);
