import { useState, useRef, useEffect } from 'react';

const useRect = () => {
  const ref = useRef();
  const [rect, setRect] = useState({});

  const set = () => setRect(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  const useEffectInEvent = (event, useCapture) => {
    useEffect(() => {
      set();
      window.addEventListener(event, set, useCapture);
      return () => window.removeEventListener(event, set, useCapture);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  };

  useEffectInEvent('resize');
  // useEffectInEvent('scroll', true);

  return [rect, ref];
};

export default useRect;
