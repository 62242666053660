import React, { useRef, useEffect, useState, memo } from 'react';
import ReactDOM from 'react-dom';
import getTime from 'screens/rundown/components/editor/utils/getTime';
import { ReactEditor, useSlate } from 'slate-react';
import styled from '@emotion/styled';
import theme from 'theme/theme';
import movePortalToViewPort from 'components/editor/utils/movePortalToViewPort';
import { Editor, Range } from 'slate';
import { getWords } from 'components/editor/utils';

const StyledDiv = styled('div')`
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: ${theme.palette.dina.hoveringTooltip};
  color: ${theme.palette.dina.highEmphasis};
  border-radius: 4px;
  transition: opacity 0.75s;

  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
`;

const Portal = ({ children }) => ReactDOM.createPortal(children, document.body);

const Tooltip = React.forwardRef((props, ref) => <StyledDiv ref={ref} {...props} />);

const getWordCount = (nodes) => getWords(nodes, true).length;

const HoveringTooltip = (props) => {
  const { hostReadSpeed } = props;
  const ref = useRef();
  const editor = useSlate();

  const [textReadSpeed, setTextReadSpeed] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const nodes = Editor.fragment(editor, selection);

    const wordCount = getWordCount(nodes);
    const wordsPerSecond = hostReadSpeed / 60;
    const speakDuration = Math.ceil(wordCount / wordsPerSecond);
    setTextReadSpeed(getTime(speakDuration));

    movePortalToViewPort(el);
  });

  return (
    <Portal>
      <Tooltip ref={ref}>{textReadSpeed}</Tooltip>
    </Portal>
  );
};

export default memo(HoveringTooltip);
