import React, { useEffect, memo } from 'react';
import { useSlate } from 'slate-react';
import useLogger from 'utils/useLogger';
import useUploadBySignedUrl from 'hooks/useUploadBySignedUrl';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import actionTypes from 'components/editor/constants/types/actionTypes';
import ProgressBar from '../../../progressBar';

const UploadProgress = ({ fileRef, data = {}, updateEditorOnUpload }) => {
  const [UploadBySignedUrl, progress] = useUploadBySignedUrl();
  const logger = useLogger(' $$ In UploadProgress View ::');
  const { update } = useEditorContext();
  const editor = useSlate();

  useEffect(() => {
    const upload = async () => {
      try {
        const file = fileRef?.current || {};

        const { result, signedUrl } = await update({
          type: actionTypes.ASSET_INSERT,
          payload: { document: editor.children, file, data },
        });

        signedUrl && (await UploadBySignedUrl(signedUrl, fileRef));

        result && updateEditorOnUpload?.(result);
      } catch (e) {
        logger.log(e);
      }
    };

    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ProgressBar percentage={progress} />;
};

export default memo(UploadProgress);
