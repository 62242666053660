import styled from '@emotion/styled';

import theme from 'theme/theme';

import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import { ReactComponent as Vertical } from 'assets/icons/systemicons/RectangleUnionVertical.svg';
import { ReactComponent as Horizontal } from 'assets/icons/systemicons/RectangleUnionHorizontal.svg';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';

import MaterialAvatar from '../material-avatar';

export const StyledDepartmentAvatar = styled(MaterialAvatar)`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: relative;
  box-sizing: border-box;
  background-image: ${({ src }) => (src ? null : `url(${fallbackImage})`)};
  background-size: cover;

  ${({ type }) => {
    if (type === 'root') {
      return `
        display: flex;
        border: 0.5px solid ${theme.palette.dina.iconInactive};
        border-radius: 30%;
        background-color: ${theme.palette.dina.outlineButtonOnHover};
      `;
    }
    if (type === 'topic') {
      return `
        border-radius: 4px;
        border: 0.5px solid ${theme.palette.dina.inputBorderResting};
        backdrop-filter: blur(15px);
        background-color: ${theme.palette.dina.blackHoverOverlay};
      `;
    }
    return ``;
  }};
`;

export const StyledAvatarChild = styled('div')`
  ${({ type }) =>
    type === 'root'
      ? `
        ${theme.typography.dina.instanceFooterLabel}
        font-weight: 500;
        display: flex;
        margin: auto;
        color: ${theme.palette.dina.highEmphasis};
      `
      : `
        ${theme.typography.dina.storyCardAvatarText}
        color: ${theme.palette.dina.highEmphasis};
      `}
`;

export const StyledMidAddIcon = styled(Add)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const StyledHorizontalTopIcon = styled(Horizontal)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -100%, 0);
`;

export const StyledHorizontalBottomIcon = styled(Horizontal)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 100%, 0);
`;

export const StyledVerticalLeftIcon = styled(Vertical)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(-100%, -50%, 0);
`;

export const StyledVerticalRightIcon = styled(Vertical)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(100%, -50%, 0);
`;
