/* eslint-disable import/no-extraneous-dependencies */
import pipe from 'lodash/fp/pipe';

/**
 * Converts hh:mm:ss formatted time string to seconds
 *
 * @param {String} time String to be converted
 * @returns {Number} Converted seconds
 */

const getSeconds = (time) => {
  if (!time) return 0;

  const getSign = pipe((string) => string.replaceAll(':', ''), Number, Math.sign);

  const summedSeconds = time
    .split(':')
    .reduce((accumulator, currentValue) => 60 * accumulator + Number(currentValue));

  return getSign(time) * Number(summedSeconds);
};

export default getSeconds;
