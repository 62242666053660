import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const commonStyles = (props) => css`
  width: ${props.width}px;
  height: ${props.height}px;
  position: relative;
  ${props.pointer && `cursor: pointer;`}
`;

const StyledDiv = styled('div')`
  ${commonStyles};
`;

const AnimatedDiv = styled('div')`
  ${commonStyles};
  transition: transform 0.2s;
  :hover {
    transform: scale(1.15);
  }
  :active {
    transform: scale(0.9);
  }
`;

const Container = ({ children, width, height, onClick, disableScaling, pointer }) =>
  disableScaling ? (
    <StyledDiv
      pointer={pointer}
      height={height}
      width={width}
      role="presentation"
      onClick={onClick}
    >
      {children}
    </StyledDiv>
  ) : (
    <AnimatedDiv pointer={pointer} height={height} width={width} onClick={onClick}>
      {children}
    </AnimatedDiv>
  );

Container.propTypes = {
  children: PropTypes.node,
  disableScaling: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  pointer: PropTypes.bool,
};

Container.defaultProps = {
  children: null,
  disableScaling: false,
  onClick: () => {},
  width: 48,
  height: 48,
  pointer: false,
};

export default Container;
