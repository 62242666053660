import styled from '@emotion/styled/macro';
import theme from 'theme/theme';
import { css } from '@emotion/react';
import { captionRegular } from 'theme/styledTypography';

const commonStyles = css`
  ${captionRegular}
  background:transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`;

export const Input = styled('input')`
  ${commonStyles}
  color: ${theme.palette.dina.mediumEmphasis};
  :not(:focus)::selection {
    background: transparent;
  }
  :not(:focus) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :focus {
    font-style: italic;
    color: ${theme.palette.dina.highEmphasis};
  }
`;

export const TextArea = styled('textarea')`
  ${commonStyles}
  padding: 4px 0px;
  resize: none;
  color: ${theme.palette.dina.highEmphasis};
`;
