/* eslint-disable import/no-extraneous-dependencies */
import { Text } from 'slate';
import words from 'lodash/words';
import textColors from 'components/editor/constants/textColors';

/**
 * Finds colored words given SlateJS document
 *
 * @param {Object} document SlateJS document instance
 * @returns {String[]} Colored words
 */

const getColoredWords = (document) => {
  const coloredNodes = filterColoredNodes(document, []);
  const coloredString = coloredNodes.join();

  return words(coloredString);
};

const [white] = textColors;

const filterColoredNodes = (nodes, initialValue = []) =>
  nodes.reduce((accumulator, node) => {
    const { color, text, children } = node;

    if (Text.isText(node) && color && color !== white) return [...accumulator, text.trim()];

    if (!children) return accumulator;

    return filterColoredNodes(children, accumulator);
  }, initialValue);

export default getColoredWords;
