import React from 'react';
import PropTypes from 'prop-types';

import Fade from '@material-ui/core/Fade';

import {
  StyledMenu as Menu,
  StyledMenuItem as MenuItem,
  StyledMenuItemText as MenuText,
  StyledDivider as Divider,
} from './contextMenu-styled';

const ContextMenuView = (props) => {
  const {
    contentTabsLength,
    contextMenuPos,
    hasOtherTabs,
    hasTabsToLeft,
    hasTabsToRight,
    onClose,
    handleCloseActiveTab,
    handleCloseOtherTabs,
    handleCloseAllTabs,
    handleCloseTabsToLeft,
    handleCloseTabsToRight,
  } = props;

  return (
    <Menu
      keepMounted
      open={contextMenuPos.mouseX !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuPos.mouseY !== null && contextMenuPos.mouseX !== null
          ? { top: contextMenuPos.mouseY, left: contextMenuPos.mouseX }
          : undefined
      }
      TransitionComponent={Fade}
    >
      <MenuItem button onClick={handleCloseActiveTab}>
        <MenuText>Close Tab</MenuText>
        {/* <TabAction>{`Ctrl + W \n⌘ + W`}</TabAction> */}
      </MenuItem>
      <MenuItem button onClick={handleCloseOtherTabs} disabled={!hasOtherTabs}>
        <MenuText>Close Other Tabs</MenuText>
      </MenuItem>
      <MenuItem button onClick={handleCloseAllTabs} disabled={contentTabsLength === 0}>
        <MenuText>Close All Tabs</MenuText>
        {/* <TabAction>{`Ctrl + Shift + W\n⌘ + Shift + W`}</TabAction> */}
      </MenuItem>

      <Divider />

      <MenuItem button onClick={handleCloseTabsToLeft} disabled={!hasTabsToLeft}>
        <MenuText>Close Tabs to the Left</MenuText>
      </MenuItem>
      <MenuItem button onClick={handleCloseTabsToRight} disabled={!hasTabsToRight}>
        <MenuText>Close Tabs to the Right</MenuText>
      </MenuItem>
    </Menu>
  );
};

ContextMenuView.propTypes = {
  /** content tabs length */
  contentTabsLength: PropTypes.number.isRequired,
  /** position of context menu */
  contextMenuPos: PropTypes.shape({
    mouseX: PropTypes.number,
    mouseY: PropTypes.number,
  }).isRequired,
  /** has other tabs */
  hasOtherTabs: PropTypes.bool.isRequired,
  /** has tabs to left */
  hasTabsToLeft: PropTypes.bool.isRequired,
  /** has tabs to right */
  hasTabsToRight: PropTypes.bool.isRequired,
  /** callback to close context menu */
  onClose: PropTypes.func.isRequired,
  /** callback to close active tab */
  handleCloseActiveTab: PropTypes.func.isRequired,
  /** callback to close other tabs */
  handleCloseOtherTabs: PropTypes.func.isRequired,
  /** callback to close all tabs */
  handleCloseAllTabs: PropTypes.func.isRequired,
  /** callback to close tabs to left */
  handleCloseTabsToLeft: PropTypes.func.isRequired,
  /** callback to close tabs to right */
  handleCloseTabsToRight: PropTypes.func.isRequired,
};

export default ContextMenuView;
