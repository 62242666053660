import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover';
import MenuItem from '../../../menuItem';
import TemplateSubMenu from '../templatesSubMenu';
import { List } from './foldersSubMenu-styled';

const SubMenu = (props) => {
  const {
    folders,
    anchorEl,
    onDeleteFolder,
    disabled,
    disableChildren,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    ...rest
  } = props;
  const [templatesData, setTemplatesData] = useState([]);
  const [anchor, setAnchor] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const handleClosePopover = () => {
    setAnchor(null);
    setTemplatesData([]);
  };

  return (
    <>
      <List $disableChildren={disableChildren}>
        {folders.map(({ mId, mRefId, mTitle, items }) => (
          <MenuItem
            key={mRefId}
            label={mTitle}
            data={items}
            showDeleteButton={canDeleteTemplateFolder}
            anchorEl={anchorEl}
            disabled={disabled}
            onClick={() => {
              setAnchor(anchorEl);
              setTemplatesData(items);
              setSelectedFolder(mRefId);
            }}
            onDeleteButtonClick={() => {
              onDeleteFolder(mId, mRefId);
            }}
          />
        ))}
      </List>

      <Popover
        anchorEl={templatesData.length > 0 || canCreateNewTemplate ? anchor : null}
        onClose={handleClosePopover}
        noMargin
      >
        <TemplateSubMenu
          folderId={selectedFolder}
          templates={templatesData}
          anchorEl={anchor}
          disabled={disableChildren}
          canCreateNewTemplate={canCreateNewTemplate}
          canDeleteTemplate={canDeleteTemplate}
          {...rest}
        />
      </Popover>
    </>
  );
};

SubMenu.propTypes = {
  /** List of folders to be shown */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** boolean to disable the item */
  disabled: PropTypes.bool,
  /** boolean to disable the children */
  disableChildren: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
};

SubMenu.defaultProps = {
  folders: [],
  anchorEl: null,
  onDeleteFolder: () => {},
  disabled: false,
  disableChildren: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
};

export default SubMenu;
