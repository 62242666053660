import { makeStyles } from '@material-ui/styles';

const commonButtonStyles = (theme) => ({
  ...theme.typography.dina.listItemLabel,
  color: theme.palette.dina.mediumEmphasis,
  textTransform: 'none',
  height: 28,
});

const useStyles = makeStyles((theme) => ({
  label: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    ...theme.typography.dina.overline,
    marginLeft: 8,
  },
  groupRoot: {
    border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    borderRadius: 8,
    backgroundColor: theme.palette.dina.backgroundResting,
  },
  grouped: {
    border: 'none',
    margin: 1,
    '&:not(:first-child)': {
      borderRadius: 6,
      margin: 1,
    },
    '&:first-child': {
      borderRadius: 6,
    },
  },
  toggleButtonRoot: {
    ...commonButtonStyles(theme),
    '&:hover': {
      backgroundColor: theme.palette.dina.outlineButtonOnHover,
    },
  },
  toggleButtonSelected: {
    ...commonButtonStyles(theme),
    color: theme.palette.dina.highEmphasis,
    backgroundColor: theme.palette.dina.onFocus,
    '&:hover': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  disabled: {
    opacity: 0.35,
    border: 'none',
  },
}));

export default useStyles;
