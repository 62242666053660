import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Avatar from 'components/avatar';
import getInitials from 'utils/getInitials';
import { avatarVariants } from 'components/avatar/Avatar';
import { listItemLabel } from 'theme/styledTypography';
import theme from 'theme/theme';

const AvatarCellWrapper = styled('div')`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 8px;
  }
`;

const AvatarText = styled('span')`
  ${listItemLabel}
  font-size: 10px;
  color: ${(props) => {
    if (props.variant === avatarVariants.team) return theme.palette.dina.blackHighEmphasis;
    return theme.palette.dina.highEmphasis;
  }};
`;

const AvatarCell = ({ mTitle, mAvatarUrl, avatarVariant }) => (
  <AvatarCellWrapper>
    <Avatar src={mAvatarUrl} size={24} variant={avatarVariant}>
      <AvatarText variant={avatarVariant}>{getInitials(mTitle)}</AvatarText>
    </Avatar>
    {mTitle}
  </AvatarCellWrapper>
);

AvatarCell.propTypes = {
  /** text in the cell */
  mTitle: PropTypes.string,
  /** avatar image */
  mAvatarUrl: PropTypes.string,
  /** variant of avatar */
  avatarVariant: PropTypes.string,
};

AvatarCell.defaultProps = {
  mTitle: '',
  mAvatarUrl: '',
  avatarVariant: avatarVariants.people,
};

export default AvatarCell;
