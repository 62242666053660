import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import GET_MEMBERS from 'graphql/queries/getUsers';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import configCtx from 'contexts/configContext';
import capitalize from 'utils/capitalize';
import CategoryIconLoader from 'utils/categoryIconLoader';
import DialogView from './dialog-view';

const DialogContainer = ({ variant, ...rest }) => {
  const { iptcMediaTopics } = useContext(configCtx);
  const type = {
    People: memberTypes.USER,
    Host: memberTypes.USER,
    Department: memberTypes.DEPARTMENT,
    Team: memberTypes.TEAM,
    Contact: memberTypes.CONTACT,
  };

  const { data, error, loading } = useQuery(GET_MEMBERS, {
    variables: getMembersOfTypeQuery(type[variant]),
    fetchPolicy: 'cache-and-network',
  });

  if (variant === 'Category') {
    // memberTypes.CATEGORY
    return (
      <DialogView
        data={iptcMediaTopics.map((topic) => ({
          mId: topic.categoryId,
          mTitle: capitalize(topic.label),
          mAvatarUrl: CategoryIconLoader(topic.categoryId),
        }))}
        variant="Category"
        {...rest}
      />
    );
  }

  if (error) {
    return `Error! ${error.message}`;
  }
  return (
    <DialogView
      data={loading ? [] : data?.getMembersOftype}
      variant={variant}
      loading={loading}
      {...rest}
    />
  );
};

export default DialogContainer;
