import React from 'react';
import styled from '@emotion/styled';
import loadingSpinner from 'assets/images/loadingSpinner/dina-loader-anpng-60frames.png';

const Image = styled('img')`
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  height: 64px;
  width: 64px;

  &.dark {
    filter: invert(100%);
  }
`;

const LoadingIndicator = ({ className }) => (
  <Image src={loadingSpinner} alt="spinner" className={className} />
);

export default LoadingIndicator;
