/**
 * Converts milliseconds to hh:mm:ss formatted time string
 *
 * @param {Number} milliseconds Milliseconds to be converted
 * @param {Boolean} preserveSign If true, adds +/- before the formatted time
 * @param {Boolean} preserveHours If true, persists hh in return string regardless of hour value
 * @returns {String} Formatted time string hh:mm:ss
 */

const addLeadingZeroes = (number) => number.toString().padStart(2, '0');

const getHours = (hours, preserveHours) => {
  if (hours) return `${addLeadingZeroes(hours)}:`;
  return preserveHours ? '00:' : '';
};

const getTimeString = (milliseconds = 0, preserveSign = false, preserveHours = false) => {
  const parsedMilliseconds = Number(milliseconds);

  if (Number.isNaN(parsedMilliseconds) || parsedMilliseconds === 0)
    return preserveHours ? '00:00:00' : '00:00';

  const sign = Math.sign(parsedMilliseconds) === -1 ? '-' : '+';
  const parsedSeconds = Math.floor(Math.abs(parsedMilliseconds / 1000));
  const hours = Math.floor(parsedSeconds / 3600);
  const minutes = Math.floor((parsedSeconds - hours * 3600) / 60);
  const seconds = parsedSeconds - hours * 3600 - minutes * 60;
  const hh = getHours(hours, preserveHours);
  const mm = `${addLeadingZeroes(minutes)}:`;
  const ss = `${addLeadingZeroes(seconds)}`;
  const formattedTime = hh + mm + ss;

  return (preserveSign ? sign : '') + formattedTime;
};

export default getTimeString;
