/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { captionMedium } from 'theme/styledTypography';
import theme from 'theme/theme';

export const PreviewWrapper = styled('div')`
  width: 88px;
  height: 88px;
  border: 1px dashed ${theme.palette.dina.iconInactive};
  border-radius: 8px;
  background-color: ${theme.palette.dina.blackHoverOverlay};
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  color: ${theme.palette.dina.mediumEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;
  ${captionMedium}
  font-weight: normal;
`;
