import { distanceInWordsToNow } from 'date-fns';
import capitalize from 'utils/capitalize';
import conversationTypes from 'utils/constants/conversationTypes';
import { avatarVariants } from 'components/avatar/Avatar';
import AvatarCell from '../components/avatarCell';

const assembleTeamData = (conversations = [], skipAvatarComponent = false) =>
  conversations.map((conversation) => {
    const { mId, mRefId, mTitle, mUpdatedAt, mAvatarUrl } = conversation;
    const avatarVariant = avatarVariants.team;
    return {
      ...conversation,
      conversationMId: mId,
      conversationMRefId: mRefId,
      rowId: mId,
      type: 'Team',
      name: skipAvatarComponent ? mTitle : AvatarCell({ mTitle, mAvatarUrl, avatarVariant }),
      chatHistory: mUpdatedAt
        ? capitalize(distanceInWordsToNow(mUpdatedAt, { addSuffix: true }))
        : '',
      convoType: conversationTypes.TEAM,
      mAssignedMembers: [conversation],
    };
  });
export default assembleTeamData;
