import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import dndTypes from 'utils/dndTypes';
import { useOpenGroups } from 'store/sidebar';

import Group from './group-view';

const ListGroup = ({
  title,
  children,
  onEllipsis,
  id,
  hasMore,
  handleCreateClicked,
  type,
  onLoadMore,
  toolTipTitle,
  ...rest
}) => {
  const [openGroups, setOpenGroups] = useOpenGroups();

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.CONTACT],
    drop: (item) => item,
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const isGroupOpen = () => !!Object.prototype.hasOwnProperty.call(openGroups, id);

  const toggleGroupOpen = () => {
    const groups = openGroups;
    if (isGroupOpen()) {
      delete groups[id];
    } else {
      groups[id] = { open: true };
    }

    setOpenGroups(groups);
  };

  const [listOpen, setListOpen] = useState(isGroupOpen(id));

  const toggleListOpen = () => {
    toggleGroupOpen(id);
    setListOpen(!listOpen);
  };

  useEffect(() => {
    if (hovered && !listOpen) toggleListOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return (
    <div ref={dropRef}>
      <Group
        open={listOpen}
        title={title}
        onEllipsis={onEllipsis}
        toggleListOpen={() => toggleListOpen()}
        toolTipTitle={toolTipTitle}
        handleCreateClicked={handleCreateClicked}
        type={type}
        onLoadMore={onLoadMore}
        hasMore={hasMore}
        {...rest}
      >
        {children}
      </Group>
    </div>
  );
};

ListGroup.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** List items */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  /** should the header have options? */
  onEllipsis: PropTypes.bool,
  /** group id */
  id: PropTypes.string,
  /** has more token */
  hasMore: PropTypes.bool,
  /** load more function */
  onLoadMore: PropTypes.func,
};

ListGroup.defaultProps = {
  title: 'Group',
  children: null,
  onEllipsis: false,
  id: '',
  hasMore: false,
  onLoadMore: () => {},
};

export default ListGroup;
