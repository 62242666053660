import React, { useState } from 'react';
import ResizableDialog from 'components/resizableDialog';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_small_off.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/systemicons/editor/visibility.svg';
import Preview from '../../../preview';
import EditButton from '../../../cmsEditing/components/editButton';
import DatetimeIndicator from '../datetimeIndicator';
import useStyles from './schedulingButtons-styles';

const SchedulingButtons = ({
  datetime,
  canShowNewDesign,
  canShowCmsIframe,
  embeddedEndpoint,
  isCMS,
  openPublishingSettings,
  previewEndpoint,
  id,
  title,
  onCmsEditingClick,
  isCmsBlock,
  isFailedState,
  isDisabled,
}) => {
  const disableScheduleButton = (canShowNewDesign || canShowCmsIframe) && isCMS && !isCmsBlock;

  const classes = useStyles({ disableScheduleButton, isDisabled });

  const [openPreview, setOpenPreview] = useState(false);

  const openPreviewView = () => setOpenPreview(true);
  const closePreviewView = () => setOpenPreview(false);

  const displayPreview = !!previewEndpoint;
  const displayEdit = !!embeddedEndpoint;

  const handleOpenPublishingSettings = (event) => {
    !isDisabled && openPublishingSettings(event);
  };

  return (
    <>
      {disableScheduleButton && (
        <div className={classes.buttonWrapper}>
          {displayEdit && <EditButton onClick={onCmsEditingClick} />}
          {displayPreview && (
            <div
              role="presentation"
              onClick={openPreviewView}
              className={classes.scheduleButton}
              style={{ display: displayPreview }}
            >
              <VisibilityIcon className={classes.calendarIcon} />
              <div className={classes.schedule}>Preview</div>
            </div>
          )}
          <ResizableDialog open={openPreview}>
            <Preview id={id} title={title} closeDialog={closePreviewView} />
          </ResizableDialog>
        </div>
      )}
      {datetime ? (
        <DatetimeIndicator
          {...{ datetime, isFailedState, isDisabled }}
          format="MMM D / HH:mm"
          onClick={handleOpenPublishingSettings}
        />
      ) : (
        <div
          role="presentation"
          onClick={handleOpenPublishingSettings}
          className={`${classes.scheduleButtonDateTime}`}
        >
          <CalendarOff className={classes.calendarIcon} />
          <div className={classes.schedule}>Schedule...</div>
        </div>
      )}
    </>
  );
};

export default SchedulingButtons;
