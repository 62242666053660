/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
const schema = {
  id: 'main-settings',
  name: 'Settings',
  categories: [
    {
      title: 'MAM',
      description: 'Media Asset Management',
      properties: {
        'mam.placeholderName': {
          type: 'string',
          default: '${mTitle}',
          description:
            'Specifies the naming convention to be used as default for creating asset placeholders',
        },
        'mam.placeholderName.maxLength': {
          type: 'string',
          default: 0,
          description:
            'Specifies maximum number of characters in a placeholder name. Set to 0 for no limit',
        },
        'mam.placeholderName.characters': {
          type: 'string',
          default: '',
          description:
            'Specifies a regexp containing the valid charcters for a placeholder name. Ignored when empty',
        },
        'mam.placeholderName.conjunctiveCharacter': {
          type: 'string',
          default: '_',
          description:
            'Specifies the character used to replace invalid characters in a placeholder',
        },
        'mam.placeholderName.maxLengthMessage': {
          type: 'string',
          default: 'The placeholder name cannot exceed 31 characters',
        },
        'mam.placeholderName.charactersMessage': {
          type: 'string',
          default: 'The placeholder name contains invalid characters',
        },
        'mam.placeholderName.hasDuplicateMessage': {
          type: 'string',
          default: 'The placeholder name already exists',
        },
        'mam.placeholderName.defaultHint': {
          type: 'string',
          default: '',
        },
      },
    },
    {
      title: 'Application',
      description: 'General application properties',
      properties: {
        'app.title': {
          type: 'string',
          default: null,
          description: 'Optional title used to identify the DiNA application',
        },
        'app.rightPanel.width': {
          type: 'number',
          default: 327,
          description: 'Width of the right panel in pixels',
        },
      },
    },
    {
      title: 'Rundown',
      description: 'General rundown properties',
      properties: {
        'rundown.defaultReadSpeed': {
          type: 'number',
          default: '150',
          description: 'Default read speed',
        },
        'rundown.confirmOnNotSaved': {
          type: 'boolean',
          default: false,
          description:
            'If set, a confirmation dialog will be shown when exiting the editor with pressing Done',
        },
        'rundown.instance.totalDurationField': {
          type: 'string',
          default: 'total-duration',
          description: 'Setting is used to calculate cume or backtiming.',
        },
        'rundown.autoSync': {
          type: 'boolean',
          default: false,
          description:
            'Setting is used to determine whether Mimir folder should be auto synchronized.',
        },
        'rundown.manualPlaybackEnabled': {
          type: 'boolean',
          default: false,
          description: 'If set, manual playback option will be available from frontend.',
        },
      },
    },
    {
      title: 'Scheduler',
      description: 'General scheduler properties',
      properties: {
        'scheduler.enable': {
          type: 'boolean',
          default: false,
          description: 'Whether scheduler should be enabled or not.',
        },
      },
    },
  ],
};

export default schema;
