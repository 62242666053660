import memberTypes from 'graphql/memberTypes';

import { ReactComponent as BookMarksOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/bookmarks_on.svg';
import { ReactComponent as BookMarksOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/bookmarks_off.svg';
import { ReactComponent as MyStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/mystories_on.svg';
import { ReactComponent as MyStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/mystories_off.svg';
import { ReactComponent as TeamStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/teamStories_on.svg';
import { ReactComponent as TeamStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/teamStories_off.svg';
import { ReactComponent as RundownsOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_on.svg';
import { ReactComponent as RundownsOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_off.svg';
import { ReactComponent as LeftDepartmentsOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/departments_on.svg';
import { ReactComponent as LeftDepartmentsOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/departments_off.svg';
import { ReactComponent as AllStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_on.svg';
import { ReactComponent as AllStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_off.svg';
import { ReactComponent as AllPitchesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_on.svg';
import { ReactComponent as AllPitchesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';
import { ReactComponent as AllInstancesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_on.svg';
import { ReactComponent as AllInstancesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_off.svg';

import { ReactComponent as StoryBoxOn } from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_on.svg';
import { ReactComponent as StoryBoxOff } from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_off.svg';
import { ReactComponent as MyContactsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_on.svg';
import { ReactComponent as MyContactsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_off.svg';
import { ReactComponent as TeamsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/teams_on.svg';
import { ReactComponent as TeamsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/teams_off.svg';
import { ReactComponent as RightDepartmentsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/departments_on.svg';
import { ReactComponent as RightDepartmentsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/departments_off.svg';
import { ReactComponent as GraphicsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/graphics_on.svg';
import { ReactComponent as GraphicsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/graphics_off.svg';

import selectionTypes from '../../rightArea/selectionTypes';

export const leftMenuItems = [
  {
    title: 'Bookmarks',
    selectionType: memberTypes.USER_BOOKMARK,
    SelectedComponent: BookMarksOn,
    UnselectedComponent: BookMarksOff,
    divider: true,
  },

  {
    title: 'My stories',
    selectionType: memberTypes.USER,
    SelectedComponent: MyStoriesOn,
    UnselectedComponent: MyStoriesOff,
  },
  {
    title: 'My Teams',
    selectionType: memberTypes.TEAM_USER,
    SelectedComponent: TeamStoriesOn,
    UnselectedComponent: TeamStoriesOff,
  },
  {
    title: 'My Departments',
    selectionType: memberTypes.DEPARTMENT_USER,
    SelectedComponent: LeftDepartmentsOn,
    UnselectedComponent: LeftDepartmentsOff,
    divider: true,
  },
  {
    title: 'All Stories',
    selectionType: memberTypes.STORY,
    SelectedComponent: AllStoriesOn,
    UnselectedComponent: AllStoriesOff,
  },
  {
    title: 'All Pitches',
    selectionType: memberTypes.PITCH,
    SelectedComponent: AllPitchesOn,
    UnselectedComponent: AllPitchesOff,
  },
  {
    title: 'All Instances',
    selectionType: memberTypes.INSTANCE,
    SelectedComponent: AllInstancesOn,
    UnselectedComponent: AllInstancesOff,
    divider: true,
  },
  {
    title: 'My Rundowns',
    selectionType: memberTypes.RUNDOWN_USER,
    SelectedComponent: RundownsOn,
    UnselectedComponent: RundownsOff,
  },
];

export const rightMenuItems = [
  {
    title: 'Graphics',
    selectionType: selectionTypes.GRAPHICSBOX,
    SelectedComponent: GraphicsOn,
    UnselectedComponent: GraphicsOff,
  },
  {
    title: 'Assets',
    selectionType: selectionTypes.STORYBOX,
    SelectedComponent: StoryBoxOn,
    UnselectedComponent: StoryBoxOff,
    divider: true,
  },
  {
    title: 'Contacts',
    selectionType: selectionTypes.CONTACTS,
    SelectedComponent: MyContactsOn,
    UnselectedComponent: MyContactsOff,
  },
  {
    title: 'Teams',
    selectionType: selectionTypes.TEAMS,
    SelectedComponent: TeamsOn,
    UnselectedComponent: TeamsOff,
  },
  {
    title: 'Departments',
    selectionType: selectionTypes.DEPARTMENT,
    SelectedComponent: RightDepartmentsOn,
    UnselectedComponent: RightDepartmentsOff,
  },
];
