const getUpdatedStoryList = (list, story) => {
  const updatedList = list;
  const { crudAction } = story.message;
  if (crudAction === 'INSERT') {
    const memberExist = updatedList.filter((s) => s.mId === story.mId);
    if (memberExist.length === 0) {
      updatedList.unshift(story);
    }
    return updatedList;
  }
  if (crudAction === 'REMOVE') {
    return updatedList.filter((s) => s.mId !== story.mId);
  }
  return list;
};

const updateStoryList = (proxy, story, query, variables) => {
  try {
    let hasQueryInCache = true;
    let list;
    try {
      list = proxy.readQuery({
        query,
        variables,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      hasQueryInCache = false;
    }
    if (hasQueryInCache) {
      const { items, nextToken } = list.searchApi;
      let storyItems = [...items];
      storyItems = getUpdatedStoryList(storyItems, story);
      // Write updated member list in the cache.
      proxy.writeQuery({
        query,
        variables,
        data: {
          searchApi: { items: storyItems, __typename: 'PaginatedMemberType', nextToken },
        },
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default updateStoryList;
