const extractNotification = (notificationMessage, mUpdatedById, updatedBy, payload) => ({
  message: notificationMessage.messages,
  messageType: notificationMessage.messageType,
  messageTypeText: notificationMessage.messageTypeString,
  mUpdatedById,
  updatedBy: updatedBy ? updatedBy.mTitle : 'some user',
  avatar: payload.mThumbnailUrl || updatedBy?.mAvatarUrl,
  time: payload.mUpdatedAt,
  mId: notificationMessage.mId,
  mStoryId: payload.mStoryId || payload.mId || '',
  mRefId: notificationMessage.mRefId,
  mType: notificationMessage.mType,
  itemTitle: notificationMessage.itemTitle,
  mContent: payload.message.mContent || notificationMessage.messages[0] || '',
});

export default extractNotification;
