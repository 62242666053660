import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  useCurrentTabValue,
  useSetCloseContentTab,
  useSetCloseInactiveTabs,
  useSetCloseAllTabs,
  useTabs,
} from 'store/tabs';

import FixedItemsView from './fixedItems-view';

const FixedItemsContainer = ({ handleDropDownClose }) => {
  const [tabs] = useTabs();
  const closeContentTab = useSetCloseContentTab();
  const closeInactiveTabs = useSetCloseInactiveTabs();
  const closeAllTabs = useSetCloseAllTabs();
  const currentTab = useCurrentTabValue();
  const contentTabs = useMemo(() => tabs.filter((t) => t.tabType !== 'icon'), [tabs]);

  const hasInactiveTabs = useMemo(() => {
    const inactiveTabs = contentTabs.filter((t) => t.id !== currentTab.id);
    return inactiveTabs.length > 0;
  }, [currentTab.id, contentTabs]);

  const handleCloseActiveTab = useCallback(() => {
    closeContentTab({ id: currentTab.id });
    handleDropDownClose();
  }, [closeContentTab, currentTab.id, handleDropDownClose]);

  const handleCloseInactiveTabs = useCallback(() => {
    closeInactiveTabs();
    handleDropDownClose();
  }, [closeInactiveTabs, handleDropDownClose]);

  const handleCloseAllTabs = useCallback(() => {
    closeAllTabs();
    handleDropDownClose();
  }, [closeAllTabs, handleDropDownClose]);

  return (
    <FixedItemsView
      handleCloseActiveTab={handleCloseActiveTab}
      handleCloseInactiveTabs={handleCloseInactiveTabs}
      handleCloseAllTabs={handleCloseAllTabs}
      hasInactiveTabs={hasInactiveTabs}
      currentTab={currentTab}
      contentTabs={contentTabs}
    />
  );
};

FixedItemsContainer.propTypes = {
  /** callback to close dropdown */
  handleDropDownClose: PropTypes.func.isRequired,
};

export default FixedItemsContainer;
