const columns = [
  {
    field: 'name',
    headerName: 'NAME',
    sortable: true,
    sortField: 'mTitle',
    searchField: 'mTitle',
  },
  {
    field: 'type',
    headerName: 'Type',
    sortable: true,
  },
  {
    field: 'chatHistory',
    headerName: 'CHAT HISTORY',
    sortable: true,
    sortField: 'mUpdatedAt',
    searchField: 'chatHistory',
  },
];

export default columns;
