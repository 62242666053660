import { Transforms } from 'slate';

/**
 * Updates editor -> element's data attribute.
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} data Data properties of the SlateJS element
 * @param {Object} newAssetData Queried assetData for Placeholder
 * if asset is uploaded for that placeholder in mimir
 *
 * @returns {Object} SlateJS editor instance
 *
 */

const updateElement = (editor, data, newAssetData) => {
  const updatedData = {
    ...data,
    assets: [
      {
        ...newAssetData,
        mProvider: newAssetData.mMetaData.reduce(
          (accumulator, currentValue) =>
            currentValue.key === 'provider' ? currentValue.value : accumulator,
          '',
        ),
        mediaType: 'video/placeholder',
      },
    ],
  };
  Transforms.setNodes(editor, { data: updatedData });

  return editor;
};

export default updateElement;
