import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  buttonRoot: {
    backgroundColor: 'transparent',
    padding: ({ padding }) => padding,
    '&:hover': {
      background: 'transparent',
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fill: 'white',
            fillOpacity: 1,
          },
        },
      },
    },
  },
});

export default useStyles;
