import styled from '@emotion/styled/macro';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiFilledInput from '@material-ui/core/FilledInput';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import theme from 'theme/theme';
import { inputLabel, body2, caption } from 'theme/styledTypography';

export const InputLabel = styled(MuiInputLabel, {
  shouldForwardProp: (prop) => prop !== 'isEditorOrSoMe' && prop !== 'type',
})`
  padding-left: ${(props) => (props.type === 'editor' ? null : '6px')};
  ${inputLabel}
  color: ${theme.palette.dina.disabled};
  mix-blend-mode: normal;

  &.Mui-focused,
  &.Mui-disabled,
  &.Mui-error {
    color: ${theme.palette.dina.disabled};
  }
  &.MuiInputLabel-shrink {
    ${(props) => props.isEditorOrSoMe && { transform: 'translate(10px, 4px) scale(0.7)' }}
  }
`;

export const FilledInput = styled(MuiFilledInput, {
  shouldForwardProp: (prop) =>
    prop !== 'isEditorOrSoMe' && prop !== 'underlineStyle' && prop !== 'lineHeight',
})`
  min-height: 40px;
  :hover:not(.Mui-disabled) {
    background-color: ${theme.palette.dina.outlineButtonOnHover};
  }
  &.Mui-error {
    border: 1px solid ${theme.palette.dina.outlineButtonOnHover};
  }
  &.MuiFilledInput-multiline {
    ${(props) => props.isEditorOrSoMe && { padding: '0px' }}
  }
  .MuiFilledInput-input {
    ${body2}
    width: 100%;
    padding: 0px 5px 5px;
    line-height: ${(props) => props.lineHeight || '23px'};
    &.MuiFilledInput-inputMultiline {
      ${(props) => props.isEditorOrSoMe && { padding: '10px 8px 0px' }}
    }
  }

  &.MuiFilledInput-underline {
    ::before {
      border-bottom: ${(props) =>
        `1px solid ${
          theme.palette.dina[
            props.underlineStyle === 'mandatory-touched' ? 'statusWarning' : 'disabled'
          ]
        }`};
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    ::after {
      border-bottom: 2px solid ${theme.palette.dina.onFocus};
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    :hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before {
      border-bottom: 1px solid ${theme.palette.dina.inputBorderResting};
    }
  }
`;

export const IconButton = styled(MuiIconButton)`
  color: ${theme.palette.dina.mediumEmphasis};
  position: absolute;
  top: 0px;
  right: 5px;
`;

export const FormHelperText = styled(MuiFormHelperText, {
  shouldForwardProp: (prop) => prop !== 'type',
})`
  ${caption}
  margin: 0;
  padding: ${(props) => (props.type === 'SoMe' ? '2px 0px 0px 8px' : '4px 0px 4px 16px')};
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  &.Mui-error {
    color: ${theme.palette.dina.mediumEmphasis};
    background: ${theme.palette.dina.statusError};
  }
  &.Mui-disabled {
    color: ${theme.palette.dina.disabled};
  }
`;
