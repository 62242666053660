import styled from '@emotion/styled/macro';
import theme from 'theme/theme';
import { ReactComponent as EmbeddedIconComponent } from 'assets/icons/systemicons/editor/blocks_EmbeddedVideo_off.svg';

export const EmbeddedVideoIcon = styled(EmbeddedIconComponent)`
    margin: 8px;
    path {
      fill: ${theme.palette.dina.blackMediumEmphasis};
      fill-opacity: 1;
    },
`;

export const ContentWrapper = styled('div')`
  display: flex;
  margin-bottom: 16px;
`;

export const InputGroupWrapper = styled('div')`
  width: calc(100% - 168px);
`;
