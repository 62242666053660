import gql from 'graphql-tag';

export default gql`
  mutation CreateMasterRundown($input: CreateMasterRundownInput) {
    createMasterRundown(input: $input) {
      mId
      mRefId
      mOrder
      mTitle
      mOrder
      mType
      mCoverPhotoUrl
      mCoverPhotoKey
      mThumbnailUrl
      mThumbnailKey
      recurrence {
        duration
        rRule
        startTime
        timeZone
      }
    }
  }
`;
