import styled from '@emotion/styled';

import theme from 'theme/theme';

import Tab from '@material-ui/core/Tab';

export const NotificationWrapper = styled.div`
  position: relative;
  left: -10px;
  width: 0;
  z-index: 10;
`;

export const StyledTab = styled(Tab)`
  min-width: 0;
  min-height: 44px;
  height: 44px;
  margin-top: 4px;
  border-radius: 8px 8px 0 0;

  &:hover {
    background-color: ${theme.palette.dina.whiteHoverOverlay};

    svg {
      path {
        fill: ${theme.palette.dina.iconActive};
        fill-opacity: 1;
      }
    }
  }

  &.Mui-selected {
    background-color: ${theme.palette.dina.primary700};
    color: ${theme.palette.dina.highEmphasis};
  }
`;
