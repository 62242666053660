import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import defaultStateValues from 'screens/planning/components/status/utils/statusBoards/defaultStateValues';
import Divider from 'components/divider';
import returnState from 'screens/planning/components/status/utils/statusBoards/returnState';
import variants from 'utils/instance/variants';
import providerStates from 'utils/constants/providerStates';
import InstanceLink from '../instanceLink';
import Assignees from './components/assignees';
import InfoSummary from './components/infoSummary';
import StatusSelector from './components/statusSelector';
import LockedIndicator from './components/lockedIndicator';
import useStyles from './footer-styles';

const getMessage = (variant, providerState, providerMessage) => {
  if (variant === variants.LINEAR || variant === variants.GENERAL) return '';
  if (
    providerState === providerStates.FAILED ||
    providerState === providerStates.PENDING ||
    providerState === providerStates.EXPIRED ||
    providerState === providerStates.ASSIGNED ||
    providerState === providerStates.DELETED ||
    providerState === providerStates.PUBLISHED
  )
    return providerMessage;
  if (providerState === providerStates.SCHEDULED)
    return `The instance will be published at scheduled time.`;
  return '';
};

const FooterView = ({
  variant,
  clipDuration,
  collapsed,
  onClipDurationChange,
  scriptDuration,
  totalDuration,
  statusId,
  onStatusChange,
  onBackgroundClick,
  isSavingContent,
  readLock,
  lockedByUser,
  writeLock,
  onDone,
  assignees,
  onAssigneeUpdate,
  disableEdit,
  hasChanges,
  twitterThreadCount,
  canShowNewDesign,
  providerState,
  providerMessage,
  instance,
  canUpdateInstance,
  relatedMembers,
}) => {
  const statusState = returnState(statusId);
  const backgroundColor = statusState?.backgroundColor || defaultStateValues.backgroundColor;
  const opacity = statusState?.opacity || defaultStateValues.opacity;
  const classes = useStyles({ backgroundColor, opacity });

  return (
    <div className={classes.root}>
      <div className={classes.footerContent}>
        <div role="presentation" className={classes.backgroundColor} onClick={onBackgroundClick} />
        <div className={classes.content}>
          <div className={classes.info}>
            {variant === variants.LINEAR && (
              <>
                <InfoSummary
                  disableEdit={disableEdit}
                  duration={clipDuration}
                  title="Dur"
                  onUpdate={onClipDurationChange}
                />

                <InfoSummary disableEdit duration={scriptDuration} title="script" />

                <InfoSummary disableEdit duration={totalDuration} title="total" />
              </>
            )}
            {canShowNewDesign && variant === variants.TWITTER && (
              <InfoSummary disableEdit size="large" duration={twitterThreadCount} title="Threads" />
            )}
            <div className={classes.messageText}>
              {getMessage(variant, providerState, providerMessage)}
            </div>
          </div>
          <div className={classes.footerRightArea}>
            <div className={classes.footerRightAreaItem}>
              <Assignees {...{ assignees, disableEdit }} onAssigneeUpdate={onAssigneeUpdate} />
            </div>
            <Divider orientation="vertical" className={classes.divider} />
            <div className={classes.footerRightAreaItem}>
              <InstanceLink
                linkedInstances={relatedMembers || []}
                instance={instance}
                isLinkingDisabled={!canUpdateInstance}
              />
            </div>
            <Divider orientation="vertical" className={classes.divider} />
            <StatusSelector {...{ variant, statusId, disableEdit }} onChange={onStatusChange} />
          </div>
        </div>
      </div>
      {(readLock || writeLock) && (
        <Fade in timeout={{ enter: 250, exit: 250 }}>
          <div>
            {/** prevents ref error * */}
            <LockedIndicator
              {...{
                readLock,
                writeLock,
                onDone,
                onBackgroundClick,
                lockedByUser,
                isSavingContent,
                hasChanges,
                collapsed,
              }}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};

FooterView.propTypes = {
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Callback to be invoked on clip duration change */
  onClipDurationChange: PropTypes.func,
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Callback to be invoked on status change */
  onStatusChange: PropTypes.func,
  /** Callback to be invoked on click for non-editable elements */
  onBackgroundClick: PropTypes.func,
  /** Boolean that indicates that editor content is saving */
  isSavingContent: PropTypes.bool,
  /** Name of the instance's locking user */
  lockedByUser: PropTypes.string,
  /** Boolean to indicate that the user has write access of the instance */
  writeLock: PropTypes.bool,
  /** Callback to be invoked when done button is pressed */
  onDone: PropTypes.func,
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  twitterThreadCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  providerState: PropTypes.string,
  providerMessage: PropTypes.string,
};

FooterView.defaultProps = {
  variant: variants.LINEAR,
  onClipDurationChange: (newDuration) => {},
  statusId: 'todo',
  onStatusChange: (newStatusId) => {},
  onBackgroundClick: () => {},
  isSavingContent: false,
  lockedByUser: 'Someone',
  writeLock: false,
  onDone: () => {},
  assignees: [],
  onAssigneeUpdate: (updatedAssignees) => {},
  disableEdit: false,
  twitterThreadCount: '-',
  providerState: '',
  providerMessage: '',
};

export default FooterView;
