/* eslint-disable import/prefer-default-export */
import useSettingsValue from 'hooks/useSettingsValue';

import { formatAppTitle } from '../utils';

const DEFAULT_RIGHTPANEL_WIDTH = 327;

const getRightPanelWidth = (width) => {
  if (isNaN(width) || width < DEFAULT_RIGHTPANEL_WIDTH) {
    return DEFAULT_RIGHTPANEL_WIDTH;
  }
  return width;
};

/**
 * This hook abstracts the usage of the settings values used in main.
 *
 * @returns {Array} Returns an array containing the app title and the right panel width in pixels.
 */
export const useMainSettings = () => {
  const [getSettingsValue] = useSettingsValue();

  const appTitle = formatAppTitle(getSettingsValue('app.title'));
  const width = parseInt(getSettingsValue('app.rightPanel.width'), 10);
  const rightPanelWidth = getRightPanelWidth(width);

  return [appTitle, rightPanelWidth];
};
