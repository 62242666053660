/**
 * Determines given text is mos or not
 *
 * @param {String} text Text to be tested for mos
 * @returns {Boolean} True if the text is mos, False otherwise
 */

const isMos = (text) => text.substring(0, 5) === '<mos>' && text !== '<mos><ncsReqAppInfo/></mos>';

export default isMos;
