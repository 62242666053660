import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import DropZone from 'components/editor/components/dropZone';
import { elementTypes } from 'components/editor/constants/types';
import { mediaTypes } from 'utils/rundownItemTypes';
import addMedia from 'components/editor/components/video/utils/addMedia';

const { CLIP } = mediaTypes;

const MediaDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();

  const onDrop = useCallback(
    async ({ type, payload }) => {
      if (type === CLIP && payload?.itemType === 'image')
        await addMedia(editor, element, payload, update, true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, update],
  );

  return (
    <DropZone accept={[CLIP]} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

MediaDropZone.propTypes = {
  /** SlateJS element */
  element: PropTypes.shape({}),
};

MediaDropZone.defaultProps = {
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.LIVE_STREAM,
  },
};

export default memo(MediaDropZone);
