/* eslint-disable import/no-extraneous-dependencies */
import curry from 'lodash/fp/curry';
import { Transforms } from 'slate';

/**
 * Sets the indent level on paragraph element data
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Number[]} path SlateJS location path
 * @returns {Object} SlateJS editor instance
 */

const setIndentLevel = (editor, path, indentLevel) => {
  Transforms.setNodes(
    editor,
    {
      data: { indentLevel },
    },
    { at: path },
  );

  return editor;
};

export default curry(setIndentLevel);
