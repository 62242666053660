import styled from '@emotion/styled';
import theme from 'theme/theme';
import { ReactComponent as CheckIconComponent } from 'assets/icons/systemicons/check.svg';
import { ReactComponent as DeleteIconComponent } from 'assets/icons/systemicons/delete.svg';
import { button, caption, listItemLabel } from 'theme/styledTypography';

export const ChipWrapper = styled('div')`
  height: 40px;
  border-radius: 6px;
  background-color: ${theme.palette.dina.buttonBackgroundHighEmphasis};
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const CheckIcon = styled(CheckIconComponent)`
  margin-left: 8px;
  margin-right: 4px;
  path {
    fill-opacity: 1;
  }
`;

export const DeleteIcon = styled(DeleteIconComponent)`
  margin-right: 8px;
  margin-left: 4px;
  cursor: pointer;
`;

export const ChipLabel = styled('p')`
  ${button}
  margin:0;
  font-weight: normal;
  flex: 1;
`;

export const RootWrapper = styled('div')`
  margin-bottom: 16px;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  height: 48px;
  align-items: center;
`;

export const TitleWrapper = styled('div')`
  height: 34px;
`;

export const Title = styled('p')`
  ${listItemLabel}
  margin:0;
  font-weight: normal;
`;

export const Subtitle = styled('p')`
  ${caption}
  font-weight: normal;
  margin: 0;
`;
