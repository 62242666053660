import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  headerWrapper: {
    height: 40 + 48,
  },
  bodyWrapper: {
    position: 'relative',
    top: -48,
    height: 'calc(100% - 48px)',
  },
}));

export default useStyles;
