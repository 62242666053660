import { actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import normalizeAssetData from 'utils/normalizeAssetData';
import normalizeIntoBlockData from 'components/editor/utils/normalizeIntoBlockData';
import { ReactEditor } from 'slate-react';
import findAndReplace from 'utils/findAndReplace';
import notifyChange from 'components/editor/utils/notifyChange';

/**
 * Adds clip media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

// eslint-disable-next-line consistent-return
const addClip = async (editor, element, payload, update) => {
  const { data } = element;
  const { assets: previousAssets, ...rest } = data;
  const assetPayload = normalizeAssetData(payload);

  try {
    if (payload.itemType === 'video') {
      const result = await update({
        type: actionTypes.CREATE_ASSET,
        payload: { document: editor.children, asset: assetPayload },
      });

      const asset = result.data.createAssets[0];

      if (asset) {
        const blockData = normalizeIntoBlockData({ ...payload, ...asset });
        const updatedData = {
          ...rest,
          assets: findAndReplace(previousAssets, blockData, 'assetType'),
        };

        const path = ReactEditor.findPath(editor, element);

        Transforms.setNodes(editor, { data: updatedData }, { at: path });
        notifyChange(editor, update);
      }
      ReactEditor.focus(editor);
    }
  } catch (error) {
    // console.log(error)
  }
};

export default addClip;
