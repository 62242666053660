import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import InputField from 'components/inputField/InputField';
import TextArea from 'components/textArea/TextArea';
import Divider from 'components/divider/divider-view';
import Button from 'components/buttons/button/button-view';

const useStyles = makeStyles((theme) => ({
  textRoot: {
    ...theme.typography.dina.formTitle,
    marginBottom: '19px',
    paddingTop: '24px',
  },
  inputContainer: {
    width: '279px',
    margin: 'auto',
  },
  inputDiv: {
    marginBottom: '18px',
  },
  textAreaDiv: {
    marginBottom: '17px',
  },
  formContainer: {
    width: '309px',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'space-around',
  },
}));

const CreateTeam = (props) => {
  const classes = useStyles();
  const { onSubmit, onCancel } = props;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [preventSubmission, setPreventSubmission] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    if (title && !preventSubmission) {
      setPreventSubmission(true);
      onSubmit(title, description);
    }
  };

  return (
    <form className={classes.formContainer} onSubmit={submitForm}>
      <div className={classes.inputContainer}>
        <Typography classes={{ root: classes.textRoot }}>Create New Team</Typography>
        <div className={classes.inputDiv}>
          <InputField
            label="Team Name"
            description="The name of the team"
            value={title}
            severity="mandatory"
            onChange={(val) => setTitle(val)}
            autoFocus
          />
        </div>
        <div className={classes.textAreaDiv}>
          <TextArea
            label="Description"
            type="descriptionField"
            value={description}
            optionalLabel="Description"
            onChange={(val) => setDescription(val)}
            description="Short description of what the Team does"
            rows="4"
          />
        </div>
      </div>
      <Divider />
      <div className={classes.buttonContainer}>
        <Button fullWidth variant="text" onClick={onCancel} shape="full-width">
          Cancel
        </Button>
        <Button
          shape="full-width"
          variant="contained"
          type="significant"
          disabled={preventSubmission || !title}
          onClick={submitForm}
        >
          Create Team
        </Button>
      </div>
    </form>
  );
};

CreateTeam.propTypes = {
  /** Cancel button */
  onCancel: PropTypes.func,
  /** Create button */
  onSubmit: PropTypes.func,
};

CreateTeam.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
};

export default CreateTeam;
