import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkIsNotificationConversation from 'utils/messageHub/checkIsNotificationConversation';
import checkIsSystemMessage from 'utils/messageHub/checkIsSystemMessage';
import infoImage from 'assets/icons/systemicons/info_off.svg';
import getInfoFromContent from 'utils/messageHub/getInfoFromContent';
import MessageRow from './components/messageRow';
import InfoRow from './components/infoRow';
import useStyles from './messageBubble-styles';

const MessageBubble = ({
  userId,
  message,
  onEdit,
  onDelete,
  getUser,
  messageSeen,
  showEditButton,
  showDeleteButton,
  openStory,
}) => {
  const {
    mId,
    mRefId,
    mCreatedById,
    mUpdatedById,
    mCreatedAt,
    mContent: content,
    convoType,
    mTitle,
  } = message;

  const isNotificationMessage = checkIsNotificationConversation(convoType);

  const { mContent, mStoryId, page, instanceId } = isNotificationMessage
    ? getInfoFromContent(content, convoType, mTitle)
    : { mContent: content };

  const isSystemMessage = checkIsSystemMessage(message);
  const isSelfMessage = !isNotificationMessage ? userId === (mCreatedById || mUpdatedById) : false;
  const classes = useStyles({ isSelfMessage });
  const { mTitle: senderName, mAvatarUrl: senderAvatarUrl } =
    isNotificationMessage || isSystemMessage
      ? { mTitle: 'DiNA', mAvatarUrl: infoImage }
      : getUser(mCreatedById || mUpdatedById) || {};

  const handleDelete = async () => {
    try {
      await onDelete(mId, mRefId);
    } catch (e) {
      // console.log(e);
    }
  };

  const handleEdit = () => {
    onEdit(mId, mRefId);
  };

  const handleOpenStory = () => {
    mStoryId && openStory(mTitle, mStoryId, page, instanceId);
  };

  return (
    <div className={classes.root} id={mRefId}>
      <MessageRow
        mContent={mContent}
        mAvatarUrl={senderAvatarUrl}
        mTitle={senderName}
        isSelfMessage={isSelfMessage}
      />
      <InfoRow
        onDelete={handleDelete}
        onEdit={handleEdit}
        mCreatedAt={mCreatedAt}
        isSelfMessage={isSelfMessage}
        senderName={senderName}
        messageSeen={messageSeen}
        userId={userId}
        showDeleteButton={showDeleteButton}
        showEditButton={showEditButton}
        canOpenStory={isNotificationMessage && mStoryId}
        onOpen={handleOpenStory}
      />
    </div>
  );
};

MessageBubble.propTypes = {
  /** Object containing message data */
  message: PropTypes.shape({
    mId: PropTypes.string,
    mRefId: PropTypes.string,
    mCreatedAt: PropTypes.string,
    mContent: PropTypes.string,
    mCreatedById: PropTypes.string,
    mUpdatedById: PropTypes.string,
  }),
  /** Id of the user */
  userId: PropTypes.string,
  /** callback function to edit message */
  onEdit: PropTypes.func,
  /** callback function to delete message */
  onDelete: PropTypes.func,
  /** callback to get the user data */
  getUser: PropTypes.func,
  /** boolean to show/hide edit button */
  showEditButton: PropTypes.bool,
  /** boolean to show/hide delete button */
  showDeleteButton: PropTypes.bool,
  /** list of seen info for the message */
  messageSeen: PropTypes.arrayOf(PropTypes.shape({})),
  /** callback to open story */
  openStory: PropTypes.func,
};

MessageBubble.defaultProps = {
  message: {},
  userId: '',
  onEdit: () => {},
  onDelete: () => {},
  getUser: () => {},
  showEditButton: true,
  showDeleteButton: true,
  messageSeen: [],
  openStory: () => {},
};

export default memo(MessageBubble);
