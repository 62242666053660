import { Editor, Transforms, Element as SlateElement } from 'slate';
import { elementTypes } from '../constants';
import isList from '../components/list/utils/isList';

const { unwrapNodes, setNodes, wrapNodes } = Transforms;

/**
 * Toggles the specified element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type for the node to toggle
 * @param {Boolean} isActive Specifies if the given element type is active or not
 * @returns {Object} SlateJS editor instance
 */

const toggleElement = (editor, type, isActive = false) => {
  unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && isList(n),
    split: true,
  });

  const newProperties = {
    // eslint-disable-next-line no-nested-ternary
    type: isActive ? elementTypes.PARAGRAPH : isList({ type }) ? elementTypes.LIST_ITEM : type,
  };

  setNodes(editor, newProperties, { mode: 'highest' });

  if (!isActive && isList({ type })) {
    const block = { type, children: [] };

    wrapNodes(editor, block);
  }
};

export default toggleElement;
