import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { useFeedTickerVisible } from 'store';
import memberTypes from 'graphql/memberTypes';
import useCheckUserRight from 'hooks/useCheckUserRight';

import useSettingsValue from 'hooks/useSettingsValue';
import { navbarPosition } from './utils/navbarPosition';
import { leftMenuItems, rightMenuItems } from './utils/menuItems';

import {
  StyledPaper as Paper,
  StyledMenuList as MenuList,
  StyledMenuItem as MenuItem,
  NavbarDivider as Divider,
  TickerIcon,
  TickerButton,
} from './verticalNavbar-styled';

const VerticalNavbar = (props) => {
  const { toggleSidebar, selectionChanged, selectionType, hidden, hasTitle, position } = props;
  const [checkUserRight] = useCheckUserRight();
  const [isTickerVisible, setTickerVisible] = useFeedTickerVisible();
  const tickerUserRight = checkUserRight('feature', 'ticker');
  const [getSettingsValue] = useSettingsValue();
  const rundownOnly = getSettingsValue('rundown.rundownOnly') === 'true';

  const enabledItems = useMemo(() => {
    if (position === navbarPosition.LEFT) {
      if (rundownOnly) {
        return leftMenuItems.filter(
          (item) =>
            item.selectionType === memberTypes.RUNDOWN_USER ||
            item.selectionType === memberTypes.INSTANCE,
        );
      }
      return leftMenuItems.filter((item) => {
        switch (item.selectionType) {
          case memberTypes.INSTANCE:
            return checkUserRight('search', 'all-instances');
          case memberTypes.STORY:
            return checkUserRight('search', 'all-stories');
          case memberTypes.PITCH:
            return checkUserRight('search', 'all-pitches');
          default:
            return true;
        }
      });
    }
    if (position === navbarPosition.RIGHT) {
      if (rundownOnly) {
        return rightMenuItems.filter(
          (item) =>
            item.title === 'Graphics' || item.title === 'Assets' || item.title === 'Contacts',
        );
      }
      return rightMenuItems.filter((item) =>
        checkUserRight('folder', `show-${item.title.toLowerCase()}`),
      );
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  const onSelected = useCallback(
    (val) => {
      if (selectionType === val) {
        toggleSidebar();
      } else if (hidden) {
        selectionChanged(val);
        toggleSidebar();
      } else {
        selectionChanged(val);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectionType, hidden],
  );

  const activateTicker = () => {
    setTickerVisible(true);
  };

  return (
    <Paper square hasTitle={hasTitle} position={position} $isTickerVisible={isTickerVisible}>
      <MenuList>
        {enabledItems.map((viewItem) => {
          const {
            title,
            SelectedComponent: SelectIcon,
            UnselectedComponent: UnselectIcon,
          } = viewItem;
          const isSelected = !hidden && selectionType === viewItem.selectionType;

          return (
            <>
              <MenuItem
                title={title}
                key={title}
                selected={isSelected}
                onClick={() => onSelected(viewItem.selectionType)}
              >
                {isSelected ? <SelectIcon /> : <UnselectIcon />}
              </MenuItem>
              {viewItem.divider && <Divider />}
            </>
          );
        })}
      </MenuList>
      {tickerUserRight && position === navbarPosition.LEFT && !isTickerVisible && (
        <TickerButton title="Ticker" onClick={activateTicker}>
          <TickerIcon />
        </TickerButton>
      )}
    </Paper>
  );
};

VerticalNavbar.propTypes = {
  /**  call parent component func to hide sidebar */
  toggleSidebar: PropTypes.func.isRequired,
  /**  call parent component func to change selection */
  selectionChanged: PropTypes.func.isRequired,
  /** selection type */
  selectionType: PropTypes.string,
  /** is the sidebar hidden */
  hidden: PropTypes.bool.isRequired,
  /** position of the sidebar */
  position: PropTypes.oneOf(navbarPosition).isRequired,
  /** if the sidebar has a title */
  hasTitle: PropTypes.bool.isRequired,
};

VerticalNavbar.defaultProps = {
  selectionType: null,
};

export default memo(VerticalNavbar);
