/* eslint-disable import/no-extraneous-dependencies */
import get from 'lodash/get';

import memberTypes from 'graphql/memberTypes';

const getVersionDate = (sdate = process.env.REACT_APP_VERSION_DATE) => {
  if (!sdate) return undefined;
  const vdate = new Date(sdate);
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return `${vdate.toLocaleDateString(undefined, dateOptions)} ${vdate.toLocaleTimeString(
    undefined,
  )}`;
};

const transform = (templateString = '', templateVariables = {}) =>
  templateString.replace(/\${(.*?)}/g, (_, key) => get(templateVariables, key));

export const formatAppTitle = (applicationTitleFormat) => {
  if (!applicationTitleFormat) return '';

  return transform(applicationTitleFormat, {
    version: process.env.REACT_APP_VERSION || '',
    versionDate: getVersionDate() || '',
    branchName: process.env.REACT_APP_REPO_BRANCHNAME || '',
    region: process.env.REACT_APP_AWS_APPSYNC_REGION || '',
  });
};

export const transformBookmarks = (user, data) => {
  const tempUser = user;
  tempUser.attributes = data.getMember ?? {};
  let bookmarks = tempUser.bookmarks ?? {};

  if (tempUser.attributes?.mBookmarks) {
    bookmarks = tempUser.attributes.mBookmarks.reduce((marksMap, bm) => {
      // eslint-disable-next-line no-param-reassign
      marksMap[bm.bookmarkedId] = {
        bookmarkedId: bm.bookmarkedId,
        bookmarkedType: memberTypes.STORY,
      };
      return marksMap;
    }, {});
  }

  return bookmarks;
};

export const calcContentHeight = (hasTitle, isTickerVisible) => {
  const tickerHeight = isTickerVisible ? 40 : 0;
  const titleHeight = hasTitle ? 24 : 0;
  const height = 96 + titleHeight + tickerHeight;

  return `calc(100vh - ${height}px)`;
};
