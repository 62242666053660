import { actionTypes } from 'components/editor/constants/types';

const notifyChange = (editor, update) => {
  update({
    type: actionTypes.COMMIT_UPDATE,
    payload: { document: editor.children },
  });
};

export default notifyChange;
