import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import GET_DOWNLOAD from 'graphql/queries/getDownload';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';

const Download = (props) => {
  const { download: input, setIsShowingDownload } = props;
  const winName = '_targets';
  const { data, error, loading } = useQuery(GET_DOWNLOAD, {
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) return <LoadingIndicator />;
  if (error) {
    setIsShowingDownload(false);
    return <div style={{ color: 'red' }}> {error.message} </div>;
  }
  const { exportContent } = data;

  if (input.contentType === 'application/pdf') {
    const width = window.innerWidth ? window.innerWidth : document.body.clientWidth;
    const height = window.innerHeight ? window.innerHeight : document.body.clientHeight;
    const winOptions = `fullscreen=yes,menubar=no,titlebar=no,copyhistory=no,
      location=no,resizable=yes,height=${height},width=${width}`;

    const win = window.open('', winName, winOptions);

    if (win) {
      win.document.write(`Loading pdf.... `);
      if (window.focus) {
        win.focus();
      }
    }
  }

  const buff = Buffer.from(exportContent.data);

  const downloadTxtFile = () => {
    const bstring = buff.toString('utf8');
    const element = document.createElement('a');
    const file = new Blob([bstring], {
      type: 'application/xml',
    });
    element.href = URL.createObjectURL(file);
    element.download = `${exportContent.mTitle}.xml`;
    element.target = '_blank';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
    setIsShowingDownload(false);
  };

  if (input.contentType === 'application/xml') {
    downloadTxtFile();
    return null;
  }

  const showPdf = () => {
    const width = window.innerWidth ? window.innerWidth : document.body.clientWidth;
    const height = window.innerHeight ? window.innerHeight : document.body.clientHeight;
    const winOptions = `fullscreen=yes,menubar=no,titlebar=no,copyhistory=no,
      location=no,resizable=yes,height=${height},width=${width}`;
    const file = new Blob([buff], {
      type: 'application/pdf',
    });
    const nUrl = URL.createObjectURL(file);
    const win = window.open(nUrl, winName, winOptions);
    if (window.focus) {
      if (win && !win.focus) win.focus();
    }

    setIsShowingDownload(false);
  };

  switch (input.contentType) {
    case 'application/pdf':
      showPdf();
      break;
    case 'application/xml':
      downloadTxtFile();
      break;
    default:
      return null;
  }
  return null;
};

Download.propTypes = {
  download: {
    mId: PropTypes.string.isRequired,
    mRefId: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
  },
};

Download.defaultProps = {
  download: { mId: '', mRefId: '', contentType: 'application/pdf' },
};

export default Download;
