import React from 'react';
import { useQuery } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import ScrollIntoViewContext from 'contexts/ScrollIntoViewContext';
import StoryToolbarCtx from 'contexts/StoryToolbarContext';
import RundownToolbarCtx from 'contexts/RundownToolbarContext';
import CoverPhotoCtx from 'contexts/CoverPhotoContext';
import NotificationCtx from 'contexts/NotificationContext';
import BreakingFeedCtx from 'contexts/BreakingFeedContext';
import GET_USER from 'graphql/queries/getUser';
import UserCtx from 'contexts/UserContext';
import LoadingIndicator from 'components/loadingIndicator';
import { getMemberQuery } from 'graphql/queryVariables';

import Main from './main-view';
import { ErrorWrapper } from './main-styled';
import { transformBookmarks } from './utils';

const MainContainer = (props) => {
  const { user } = props;

  const { data, error, loading } = useQuery(GET_USER, {
    variables: getMemberQuery(user.mId),
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <div> {error} </div>;
  }

  if (!data?.getMember) {
    Auth.signOut();
    return (
      <ErrorWrapper>
        Something went wrong! Please reload your browser and sign in again.
      </ErrorWrapper>
    );
  }

  user.bookmarks = transformBookmarks(user, data);

  return (
    <UserCtx.Provider value={user}>
      <NotificationCtx>
        <BreakingFeedCtx>
          <ScrollIntoViewContext>
            <CoverPhotoCtx>
              <StoryToolbarCtx>
                <RundownToolbarCtx>
                  <DndProvider backend={HTML5Backend}>
                    <Main />
                  </DndProvider>
                </RundownToolbarCtx>
              </StoryToolbarCtx>
            </CoverPhotoCtx>
          </ScrollIntoViewContext>
        </BreakingFeedCtx>
      </NotificationCtx>
    </UserCtx.Provider>
  );
};
export default MainContainer;
