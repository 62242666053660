import React, { useRef, useContext } from 'react';

import UserCtx from 'contexts/UserContext';
import { NotificationContext } from 'contexts/NotificationContext';

import Scratchpad from '../scratchPad';
import MessageHub from '../messageHub';
import Settings from '../settings';

import { UserContentWrapper, StyledTabDivider as Divider } from './userContent-styled';

const UserContent = () => {
  const user = useContext(UserCtx);
  const { attributes } = user;
  const { mTitle, mAvatarUrl, mId } = attributes;
  const { notificationMessage } = useContext(NotificationContext);
  const userContentRef = useRef(null);

  return (
    <UserContentWrapper ref={userContentRef}>
      <Scratchpad />
      <MessageHub messages={notificationMessage} anchorEl={userContentRef.current || null} />
      <Divider orientation="vertical" flexItem />
      <Settings avatar={mAvatarUrl} username={mTitle} userId={mId} />
    </UserContentWrapper>
  );
};

export default UserContent;
