import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  lockedIndicator: {
    position: 'relative',
    height: ({ collapsed }) => (collapsed ? '0px' : '40px'),
    width: '100%',
    overflow: 'hidden',
    backgroundColor: ({ readLock, writeLock }) => {
      if (writeLock) return theme.palette.dina.statusApproved;
      if (readLock) return theme.palette.dina.statusWarning;
      return 'transparent';
    },
  },
  components: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  leftComponents: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  icon: {
    width: '24px',
    height: '24px',
    margin: '0 8px 0 16px',
  },
  readLock: {
    '& path': {
      fillOpacity: 1,
      fill: theme.palette.dina.blackHighEmphasis,
    },
  },
  writeLock: {
    '& path': {
      fillOpacity: 1,
      fill: theme.palette.dina.highEmphasis,
    },
  },
  footerText: {
    ...theme.typography.dina.button,
    fontWeight: 500,
  },
  button: {
    width: '120px',
    height: '24px',
    backgroundColor: theme.palette.dina.highEmphasis,
    borderRadius: '4px',
    marginRight: '8px',
    ...theme.typography.dina.button,
    fontWeight: 500,
    color: theme.palette.dina.blackHighEmphasis,
    '&:hover': {
      color: theme.palette.dina.blackHighEmphasis,
    },
  },
  buttonContainer: {
    width: '140px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  messageComponent: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.dina.blackInactive,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.typography.dina.button,
  },
  progress: {
    color: theme.palette.dina.iconInactive,
    animationDuration: '1000ms',
    marginRight: '8px',
  },
}));

export default useStyles;
