import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleButtons from 'components/toggleButtons';
import returnState from 'screens/planning/components/status/utils/statusBoards/returnState';
import { publishingPoints as publishingPointIcons } from 'assets/icons/publishingPoints';
import { ReactComponent as EditOff } from 'assets/icons/systemicons/edit_off.svg';
import { ReactComponent as EditOn } from 'assets/icons/systemicons/edit_on.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/systemicons/editor/visibility.svg';
import { ReactComponent as VisibilityOn } from 'assets/icons/systemicons/editor/visibility_on.svg';
import Divider from 'components/divider';
import toggleViews from 'components/instanceCard/utils/toggleViews';
import PublishSettings from 'components/publishSettings';
import providerStates from 'utils/constants/providerStates';
import FallbackImage from 'assets/images/default/defaultThumbnail.png';
import variants from 'utils/instance/variants';
import useCheckUserRight from 'hooks/useCheckUserRight';
import defaultStateValues from 'screens/planning/components/status/utils/statusBoards/defaultStateValues';
import { onImageLoadingError } from 'utils/image/imageUtils';
import useSettingsValue from 'hooks/useSettingsValue';
import publishingPoints from '../../utils/publishingPoints';
import DestinationIndicator from './components/destinationIndicator';
import LinearEllipsisMenu from './components/linearEllipsisMenu';
import GeneralEllipsisMenu from './components/generalEllipsisMenu';
import SchedulingButtons from './components/schedulingButtons';
import TitleBox from './components/titleBox';
import useStyles from './header-styles';

const HeaderView = ({
  backgroundImage,
  canCreateNewTemplate,
  canDeleteTemplate,
  canDeleteTemplateFolder,
  containerRef,
  currentDestination,
  embeddedEndpoint,
  folders,
  instanceId,
  onBackgroundClick,
  onCreateDuplicate,
  onCreateFolder,
  onDeleteFolder,
  onDeleteInstance,
  onDeleteTemplate,
  onDownload,
  onForceUnlock,
  onMetadataSelect,
  onOpenStory,
  onPublishSettingsChange,
  onSaveTemplate,
  onSelectTemplate,
  onTitleUpdate,
  previewEndpoint,
  publishingPoint,
  publishingPointIcon,
  schedule,
  expiryTime,
  showMetadata,
  statusId,
  summarize,
  title,
  variant,
  writeLock,
  readLock,
  lockedByUser,
  onDock,
  onClose,
  disableEdit,
  editorValue,
  canShowNewDesign,
  canShowCmsIframe,
  versions,
  currentVersionContent,
  refetchVersionList,
  refetchVersionContent,
  onRestoreVersion,
  checkVersionRestorability,
  isSavingContent,
  auditListLoading,
  versionContentLoading,
  onCmsEditingClick,
  isCmsBlock,
  disableOpenStory,
  toggleView,
  setToggleView,
  showToggleButton,
  providerState,
  isEmbedPresent,
  forcePublishMetadata,
  updateInstanceMetadata,
  canCreateInstance,
  canUpdateInstance,
  showCloseButton,
  showOpenStoryButton,
  hideTemplateOptions,
}) => {
  const state = returnState(statusId);
  const isFailedState = statusId === 'failed';
  const backgroundColor = state?.backgroundColor || defaultStateValues.backgroundColor;
  const opacity = state?.opacity || defaultStateValues.opacity;
  const classes = useStyles({ backgroundColor, opacity });
  const [getSettingsValue] = useSettingsValue();
  const rundownOnly = getSettingsValue('rundown.rundownOnly') === 'true';

  const [checkUserRight] = useCheckUserRight();
  const canDisableSchedule = checkUserRight('feature', 'disable-schedule-on-edit');
  const canDeleteInstance = checkUserRight('instance', 'delete');

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (canShowNewDesign && showToggleButton) {
      if (providerState === providerStates.PUBLISHED) setToggleView(toggleViews.PREVIEW);
      if (providerState === providerStates.ASSIGNED) setToggleView(toggleViews.EDIT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerState]);

  const openPublishingSettings = (event) => !disableEdit && setAnchorEl(event.currentTarget);

  const onOK = (newPublishingSettings) => {
    setAnchorEl(null);
    onPublishSettingsChange(newPublishingSettings);
  };

  const handleCancel = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onBackgroundClick();
  };

  const { publishingTime } = currentDestination;
  const datetime = schedule;
  const isDeleteEnabled =
    variant === variants.LINEAR ? currentDestination.id !== null : schedule !== null;

  const memoizedLinearMenu = useMemo(
    () => (
      <LinearEllipsisMenu
        {...{
          folders,
          onSelectTemplate,
          onSaveTemplate,
          onDeleteTemplate,
          onCreateFolder,
          onDeleteFolder,
          onDownload,
          onMetadataSelect,
          onCreateDuplicate,
          onForceUnlock,
          showMetadata,
          writeLock,
          lockedByUser,
          canCreateNewTemplate,
          canDeleteTemplate,
          canDeleteTemplateFolder,
          onDeleteInstance,
          isDeleteEnabled,
          disableEdit,
          title,
          instanceId,
          editorValue,
          versions,
          currentVersionContent,
          refetchVersionList,
          refetchVersionContent,
          onRestoreVersion,
          checkVersionRestorability,
          isSavingContent,
          auditListLoading,
          versionContentLoading,
          canCreateInstance,
          canDeleteInstance,
          canUpdateInstance,
          hideTemplateOptions,
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      canCreateNewTemplate,
      canDeleteTemplate,
      canDeleteTemplateFolder,
      disableEdit,
      folders,
      isDeleteEnabled,
      showMetadata,
      writeLock,
      lockedByUser,
      versions,
      currentVersionContent,
      refetchVersionList,
      refetchVersionContent,
      onRestoreVersion,
      checkVersionRestorability,
      isSavingContent,
      auditListLoading,
      versionContentLoading,
      canCreateInstance,
      canDeleteInstance,
      canUpdateInstance,
      hideTemplateOptions,
    ],
  );

  const updateButtonList = [
    { icon: toggleView === toggleViews.EDIT ? <EditOn /> : <EditOff />, value: toggleViews.EDIT },
    {
      icon: toggleView === toggleViews.PREVIEW ? <VisibilityOn /> : <VisibilityOff />,
      value: toggleViews.PREVIEW,
      disabled: !isEmbedPresent,
    },
  ];

  return (
    <div className={classes.root}>
      <div role="presentation" className={classes.backgroundColor} />

      <div className={classes.container}>
        <div className={classes.titleBox}>
          <TitleBox
            instanceId={instanceId}
            initialTitle={title || '-'}
            onTitleUpdate={onTitleUpdate}
            onDock={onDock}
            onClose={onClose}
            disableEdit={disableEdit}
            showCloseButton={showCloseButton}
            showOpenStoryButton={!disableOpenStory && showOpenStoryButton && !rundownOnly}
            onOpenStory={onOpenStory}
          />
        </div>
        <div className={classes.scheduleDestination}>
          <div className={classes.avatar} role="presentation" onClick={handleClick}>
            <img
              src={backgroundImage || FallbackImage}
              onError={onImageLoadingError}
              alt="rundown-thumbnail"
              className={classes.thumbnail}
            />
            <img
              className={classes.publishingPoint}
              src={
                publishingPointIcons[publishingPointIcon]
                  ? publishingPointIcons[publishingPointIcon]
                  : publishingPointIcons.default
              }
              alt="publishing-point"
            />
          </div>
          <DestinationIndicator destination={currentDestination.title} onClick={handleClick} />
          {showToggleButton && (
            <div className={classes.toggleContainer}>
              <ToggleButtons
                selectedValue={toggleView}
                list={updateButtonList}
                onChange={setToggleView}
              />
            </div>
          )}

          <SchedulingButtons
            datetime={datetime}
            isCMS={publishingPoint === publishingPoints.CMS}
            canShowNewDesign={canShowNewDesign}
            canShowCmsIframe={canShowCmsIframe}
            openPublishingSettings={openPublishingSettings}
            onCmsEditingClick={onCmsEditingClick}
            id={instanceId}
            title={title}
            isCmsBlock={isCmsBlock}
            embeddedEndpoint={embeddedEndpoint}
            previewEndpoint={previewEndpoint}
            isFailedState={isFailedState}
            isDisabled={
              canDisableSchedule && variant !== variants.LINEAR ? writeLock || readLock : false
            }
          />

          {publishingPoints.LINEAR === publishingPoint ? (
            memoizedLinearMenu
          ) : (
            <GeneralEllipsisMenu
              {...{
                isDeleteEnabled,
                onDeleteInstance,
                onForceUnlock,
                onMetadataSelect,
                showMetadata,
                summarize,
                writeLock,
                lockedByUser,
                disableEdit,
                title,
                instanceId,
                versions,
                currentVersionContent,
                refetchVersionList,
                refetchVersionContent,
                onRestoreVersion,
                checkVersionRestorability,
                isSavingContent,
                auditListLoading,
                versionContentLoading,
                canDeleteInstance,
                canUpdateInstance,
                folders,
                canCreateNewTemplate,
                canDeleteTemplateFolder,
                canDeleteTemplate,
                onDeleteFolder,
                onSaveTemplate,
                onSelectTemplate,
                onDeleteTemplate,
                onCreateFolder,
                hideTemplateOptions,
              }}
            />
          )}
        </div>
        <Divider className={classes.divider} />
      </div>
      <PublishSettings
        instanceType={publishingPoint}
        publishingTime={variant === variants.LINEAR ? publishingTime : schedule}
        onCancel={handleCancel}
        variant={variant}
        selectedDestination={currentDestination}
        {...{
          anchorEl,
          onOK,
          expiryTime,
          isCmsBlock,
          forcePublishMetadata,
          updateInstanceMetadata,
        }}
      />
    </div>
  );
};

HeaderView.propTypes = {
  /** Reference to the container element */
  containerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Publishing platform of the instance */
  publishingPoint: PropTypes.oneOf(Object.keys(publishingPointIcons)),
  /** Currently selected destination of this instance */
  currentDestination: PropTypes.shape({
    title: PropTypes.string,
    publishingTime: PropTypes.string,
  }),
  /** Callback to be invoked on publish settings change */
  onPublishSettingsChange: PropTypes.func,
  /** Title of the instance */
  title: PropTypes.string,
  /** Callback to be invoked on title update */
  onTitleUpdate: PropTypes.func,
  /** Publishing time for general instance variant */
  schedule: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Expire time for cms instance variant */
  expiryTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Callback to be invoked on click for non-editable elements */
  onBackgroundClick: PropTypes.func,
  /** onCmsEditing callback */
  onCmsEditingClick: PropTypes.func,
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked when opening a story */
  onOpenStory: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** Callback to be invoked when downloading an instance */
  onDownload: PropTypes.func,
  /** Boolean that indicates that user can change content */
  writeLock: PropTypes.bool,
  /** Boolean that indicates other user currently has write lock on this content */
  readLock: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  /** toggle bewtween edit and embed view */
  toggleView: PropTypes.string,
  /** set whether edit or preview state */
  setToggleView: PropTypes.func,
  /** either to show toggle buttons */
  showToggleButton: PropTypes.bool,
};

HeaderView.defaultProps = {
  containerRef: { current: null },
  variant: variants.LINEAR,
  statusId: 'todo',
  publishingPoint: 'linear',
  currentDestination: {},
  onPublishSettingsChange: (newPublishingSettings) => {},
  title: '',
  onTitleUpdate: (newTitle) => {},
  schedule: null,
  expiryTime: undefined,
  assignees: [],
  onAssigneeUpdate: (updatedAssignees) => {},
  onSelectTemplate: (template) => {},
  onSaveTemplate: (templateName) => {},
  onDeleteTemplate: (template) => {},
  onBackgroundClick: () => {},
  onCmsEditingClick: () => {},
  folders: [],
  onCreateFolder: () => {},
  onOpenStory: () => {},
  onDeleteFolder: () => {},
  onDownload: () => {},
  writeLock: false,
  readLock: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
  toggleView: 'edit',
  setToggleView: () => {},
  showToggleButton: false,
};

export default HeaderView;
