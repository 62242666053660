import { usePolicies } from 'store';

const useCheckUserRight = () => {
  const [policies] = usePolicies();

  const check = (resourceName, action) => {
    const defaultPermission = resourceName !== 'feature';
    const resource = policies.find((r) => r.resourceName === resourceName);
    if (!resource) return defaultPermission;
    return getResourcePermission(resource, action, defaultPermission);
  };

  return [check];
};

const getResourcePermission = (resource, action, defaultValue) => {
  const { permissions = [] } = resource ?? {};
  const permission = permissions.find((p) => p.action === action);
  if (!permission) return defaultValue;
  if (permission.access && permission.access === 'allow') return true;
  return false;
};

export default useCheckUserRight;
