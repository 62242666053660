import styled from '@emotion/styled';
import { List as MuiList, MenuItem as MuiMenuItem, Paper as MuiPaper } from '@material-ui/core';
import { captionRegular } from 'theme/styledTypography';
import theme from 'theme/theme';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Input = styled('input')`
  ${captionRegular}
  color:${theme.palette.dina.mediumEmphasis};
  background: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  :focus {
    background-color: ${theme.palette.dina.statusOnFocusedDiscreetFill};
    border: 1px solid ${theme.palette.dina.onSelected};
  }
  :not(:focus)::selection {
    background: transparent;
    border: 1px solid transparent;
  }
  :not(:focus) {
    white-space: nowrap;
    border: 1px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Paper = styled(MuiPaper)`
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: ${(props) => props.background || theme.palette.dina.surfaceDialogs};
`;

export const List = styled(MuiList)`
  border-radius: 4px;
`;

export const MenuItem = styled(MuiMenuItem)`
  color: ${theme.palette.dina.highEmphasis};
`;
