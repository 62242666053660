import React from 'react';
import { isToday } from 'date-fns';

import { StyledAppbar as AppBar, StyledToolbar as ToolBar } from './toolbar-styled';

const ToolbarView = (props) => {
  const { children, selectedDate, mType, type } = props;

  return (
    <AppBar
      position="relative"
      mType={mType}
      isToday={isToday(selectedDate)}
      isStory={type === 'story'}
    >
      <ToolBar>{children}</ToolBar>
    </AppBar>
  );
};

export default ToolbarView;
