import { useDrop } from 'react-dnd';
import selectElement from 'components/editor/utils/selectElement';
import { useSlate, useReadOnly } from 'slate-react';
import dndTypes from 'utils/dndTypes';

/**
 * Hook to declare dropzone for a node
 * rerturns a ref for the dropzone
 */

const useDropZone = (accept, onDrop, element) => {
  const editor = useSlate();
  const readOnly = useReadOnly();

  const [collected, dropRef] = useDrop({
    accept,
    canDrop: () => !readOnly,
    drop: (item) => {
      if (item.type === dndTypes.EDITOR_BLOCK) onDrop(item);
      else if (!collected.didDrop) {
        onDrop(item);
      }
    },
    collect: (monitor) => {
      if (monitor.getItemType() === dndTypes.EDITOR_BLOCK) return { hovered: monitor.isOver() };
      const isActive = monitor.canDrop() && monitor.isOver();

      if (isActive) {
        selectElement(editor, element);
      }

      return { didDrop: monitor.didDrop() };
    },
  });

  return [collected, dropRef];
};

export default useDropZone;
