import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeftSrc } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as TodayIcon } from 'assets/icons/systemicons/today.svg';
import { ReactComponent as ArrowRightSrc } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';

import { Button, ButtonsWrapper } from './styled';

const ArrowNavigator = (props) => {
  const { buttonHeight, buttonWidth, resetTitle, handleDecrement, handleIncrement, handleReset } =
    props;

  return (
    <ButtonsWrapper>
      <Button
        width={buttonWidth}
        height={buttonHeight}
        onClick={handleDecrement}
        data-testid="decrement"
      >
        <ArrowLeftSrc />
      </Button>
      <Button
        width={buttonWidth}
        height={buttonHeight}
        onClick={handleReset}
        data-testid="reset"
        title={resetTitle}
      >
        <TodayIcon />
      </Button>
      <Button
        width={buttonWidth}
        height={buttonHeight}
        onClick={handleIncrement}
        data-testid="increment"
      >
        <ArrowRightSrc />
      </Button>
    </ButtonsWrapper>
  );
};

ArrowNavigator.propTypes = {
  /** Height of button */
  buttonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Width of button */
  buttonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Title of reset button */
  resetTitle: PropTypes.string,
  /** handle increment */
  handleIncrement: PropTypes.func,
  /** handle decrement */
  handleDecrement: PropTypes.func,
  /** handle reset */
  handleReset: PropTypes.func,
};

ArrowNavigator.defaultProps = {
  buttonHeight: 32,
  buttonWidth: 32,
  resetTitle: 'Today',
  handleIncrement: () => {},
  handleDecrement: () => {},
  handleReset: () => {},
};

export default ArrowNavigator;
