import React from 'react';
import { useMutation } from '@apollo/client';
import { uploadToS3 } from 'utils/s3Utils';
import UPDATE_DEPARTMENT from 'graphql/mutations/updateDepartment';
import DetailView from './detail-view';

const DetailContainer = (props) => {
  const { selectedItem } = props;
  const { imageKey: imageStoragePath } = selectedItem;

  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT);

  const updateAvatar = (imageFile) => uploadToS3(imageStoragePath, imageFile);

  const updateDepartmentTitle = (id, newTitle) => {
    const input = {
      mId: id,
      mTitle: newTitle,
    };
    updateDepartment({
      variables: {
        input,
      },
    });
  };

  return (
    <DetailView
      {...props}
      updateDepartmentTitle={updateDepartmentTitle}
      updateAvatar={updateAvatar}
    />
  );
};

export default DetailContainer;
