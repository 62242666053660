import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import { useReadOnly, useSelected, useSlateStatic, ReactEditor } from 'slate-react';
import {
  ArrowIconWrapper,
  ArrowDown,
  ArrowUp,
  BoxWrapper,
  BoxIconWrapper,
  ContentWrapper,
  Menu,
  MainContentWrapper,
  EllipsisWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  CollapsedContent,
  TopRightContentWrapper,
  TopRightContent,
} from './styled';

const Box = ({
  children,
  height,
  title,
  subtitle,
  type,
  menuItems,
  onMenuSelect,
  hideEllipsisButton,
  element,
  collapsed,
  updateCollapsed,
  collapsedContent,
  iconComponent,
  boxTopRightContent,
  allowContentDragDrop,
}) => {
  const isReadOnly = useReadOnly();
  const isSelected = useSelected(element);
  const editor = useSlateStatic();
  const showHighlight = !isReadOnly && isSelected;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const forceEditorFocus = useCallback(() => ReactEditor.focus(editor), []);

  const onDragStart = useCallback((e) => {
    if (!allowContentDragDrop) {
      e.preventDefault();
      e.stopPropagation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [handleClick, handleDoubleClick] = useMouseClickEvents(forceEditorFocus, updateCollapsed);

  const Arrow = useMemo(() => (collapsed ? ArrowDown : ArrowUp), [collapsed]);

  return (
    <BoxWrapper readOnly={isReadOnly}>
      <ContentWrapper
        showHighlight={showHighlight}
        contentEditable={false}
        collapsed={collapsed}
        height={height}
      >
        <BoxIconWrapper
          type={type}
          role="presentation"
          onDoubleClick={handleDoubleClick}
          onClick={handleClick}
        >
          {iconComponent}
          <ArrowIconWrapper type={type}>
            <Arrow type={type} onClick={handleDoubleClick} />
          </ArrowIconWrapper>
        </BoxIconWrapper>
        <MainContentWrapper>
          <EllipsisWrapper collapsed={collapsed} subtitle={subtitle}>
            <TitleWrapper>
              <Title>{title}</Title>
              {subtitle && !collapsed && <Subtitle>{subtitle}</Subtitle>}
            </TitleWrapper>
            <TopRightContentWrapper collapsed={collapsed}>
              {boxTopRightContent && <TopRightContent>{boxTopRightContent}</TopRightContent>}
              {!hideEllipsisButton && (
                <Menu collapsed={collapsed} items={menuItems} onSelect={onMenuSelect} />
              )}
            </TopRightContentWrapper>
          </EllipsisWrapper>
          {collapsed ? (
            <CollapsedContent collapsed={collapsedContent}>
              {collapsedContent || 'No content...'}
            </CollapsedContent>
          ) : (
            <div draggable onDragStart={onDragStart}>
              {children}
            </div>
          )}
        </MainContentWrapper>
      </ContentWrapper>
    </BoxWrapper>
  );
};

Box.propTypes = {
  type: PropTypes.oneOf(['default', 'media', 'error', 'iscoverphoto', 'feed']),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
  onMenuSelect: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  hideEllipsisButton: PropTypes.bool,
  collapsed: PropTypes.bool,
  updateCollapsed: PropTypes.func,
  collapsedContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Box.defaultProps = {
  type: 'default',
  menuItems: [],
  onMenuSelect: () => {},
  height: null,
  title: '',
  hideEllipsisButton: false,
  collapsed: false,
  updateCollapsed: () => {},
  collapsedContent: '',
};

export default memo(Box);
