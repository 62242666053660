import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Typography from '@material-ui/core/Typography';
import Button from 'components/buttons/button';
import useStyles from './confirmDialog-styles';

const ConfirmDialog = (props) => {
  const {
    onConfirm,
    onCancel,
    title,
    confirmButtonLabel,
    confirmButtonType,
    info,
    extraInfo,
    loading,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.dialogRoot}>
      <div className={classes.inner}>
        <div className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          <Divider />
        </div>
        <div className={classes.info}>
          <Typography className={classes.infoText}>{info}</Typography>
        </div>
        <div className={classes.info}>
          <Typography className={classes.infoText}>{extraInfo}</Typography>
        </div>
        <div>
          <Divider />
          <div className={classes.buttonLayout}>
            <div className={classes.button}>
              <Button disabled={loading} variant="text" onClick={onCancel} shape="full-width">
                Cancel
              </Button>
            </div>
            <div className={classes.button}>
              <Button
                disabled={loading}
                variant="contained"
                type={confirmButtonType}
                shape="full-width"
                onClick={onConfirm}
              >
                {confirmButtonLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmDialog.propTypes = {
  /** Cancel button */
  onCancel: PropTypes.func,
  /** Create button */
  onConfirm: PropTypes.func,
  /** type of confirm button */
  confirmButtonType: PropTypes.string,
  /** label for confirmButton */
  confirmButtonLabel: PropTypes.string,
  /** dialog title  */
  title: PropTypes.string,
  /** info text */
  info: PropTypes.string,
  /** additional info text */
  extraInfo: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  confirmButtonType: 'significant',
  confirmButtonLabel: 'Confirm',
  title: 'Confirm?',
  info: '',
  extraInfo: '',
};

export default ConfirmDialog;
