/* eslint-disable guard-for-in */
import React from 'react';
import { useQuery } from '@apollo/client';
import { avatarVariants } from 'components/avatar/Avatar';
import GET_TEAMS from 'graphql/queries/getTeams';
import useShareStory from 'hooks/useShareStory';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import List from '../../list-view';

const TeamListContainer = (props) => {
  const [assignMemberToStory] = useShareStory();

  const { data, error, loading } = useQuery(GET_TEAMS, {
    variables: getMembersOfTypeQuery(memberTypes.TEAM),
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return `Error! ${error.message}`;
  }

  if (data) {
    const teams = data.getMembersOftype;

    const addTeamToStory = async (teamId, storyId) => {
      assignMemberToStory(storyId, teamId, memberTypes.TEAM);
    };

    return (
      // type is required to differentiate between different types of drag source
      // in story list i.e  contact, teams
      <List
        members={teams}
        type={memberTypes.TEAM}
        dropHandler={(teamId, storyId) => addTeamToStory(teamId, storyId)}
        avatarVariant={avatarVariants.team}
        {...props}
      />
    );
  }

  if (loading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  return null;
};

export default TeamListContainer;
