import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogWrapper from 'components/instanceCard/components/shared/dialogWrapper';
import useLogger from 'utils/useLogger';
import useStyles from './cmsEditing-styles';

const CmsEditingView = ({ id, title, closeDialog, src }) => {
  const logger = useLogger('Cms Editing View');
  const classes = useStyles();

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    window.addEventListener('message', setEditState);

    return () => {
      window.removeEventListener('message', setEditState);
    };
  });

  const setEditState = (event) => {
    if (event.data.action) {
      if (event.data.action === 'content:state') {
        const state = event.data.payload;
        logger.log(`State:${state}`);
        setIsDirty(event.data.payload === 'dirty');
      }
    }
  };

  return (
    <DialogWrapper closeDialog={closeDialog} label={title} isDirty={isDirty}>
      <div className={classes.iframeContainer}>
        <iframe
          id={id}
          src={src}
          title="cms"
          className={classes.iframe}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </div>
    </DialogWrapper>
  );
};

CmsEditingView.propTypes = {
  /** Title of the preview dialog */
  title: PropTypes.string,
  /** Callback to close preview dialog */
  closeDialog: PropTypes.func,
  /** Source url for preview component */
  src: PropTypes.string,
  /** Id of the instance */
  id: PropTypes.string,
};

CmsEditingView.defaultProps = {
  title: '',
  closeDialog: () => {},
  src: '',
  id: '',
};

export default CmsEditingView;
