import React from 'react';
import styled from '@emotion/styled';
import { Popper as MuiPopper } from '@material-ui/core';
import colors from 'theme/colors';

export const StyledPopper = styled(MuiPopper)`
  z-index: 1300;
  background-color: ${colors.surfaceDialogs};
`;

const Popper = ({ children, anchorEl, position, ...rest }) => {
  const open = Boolean(anchorEl);

  return (
    <StyledPopper open={open} placement={position || 'top'} anchorEl={anchorEl} {...rest}>
      <div>{children}</div>
    </StyledPopper>
  );
};

export default Popper;
