import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

import { UNTITLED_STORY } from 'utils/constants';
import { formatDateToLocaleString } from 'utils/formatDate';
import dndTypes from 'utils/dndTypes';
import { useSetCloseContentTab, useSetOnDropTabItem } from 'store/tabs';

import { ReactComponent as CloseSmall } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as RundownIcon } from 'assets/icons/systemicons/HeaderNavbar/rundown_off.svg';
import { ReactComponent as StoryIcon } from 'assets/icons/systemicons/HeaderNavbar/stories_off.svg';
import { ReactComponent as PitchIcon } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';

import Tooltip from 'components/tooltip';
import TabToolTip from '../tabTooltip';
import ContextMenu from '../contextMenu';

import {
  StyledTab as Tab,
  StyledIconButton as IconButton,
  StyledTabContentWrapper,
  StyledTabIconWrapper,
  StyledLabel,
  StyledLabelTextWrap,
  StyledTabWrapper,
} from './contentTab-styled';

export const tabIcons = {
  rundown: RundownIcon,
  story: StoryIcon,
  pitch: PitchIcon,
  create: StoryIcon,
};

const initialContextMenuPos = {
  mouseX: null,
  mouseY: null,
};

const ContentTabView = ({ width, tab, index, ...rest }) => {
  const { id, type, selectedDate, mType, title } = tab;
  const onDropTabItem = useSetOnDropTabItem();
  const tabRef = useRef(null);

  const [, dragRef] = useDrag({
    type: dndTypes.CONTENT_TAB,
    item: () => ({ index }),
  });
  const [{ hovered, handlerId }, dropRef] = useDrop({
    accept: dndTypes.CONTENT_TAB,
    drop: (item) => onDropTabItem({ itemIndex: item.index, dropIndex: index }),
    collect: (monitor) => ({ hovered: monitor.isOver(), handlerId: monitor.getHandlerId() }),
  });

  const [contextMenuPos, setContextMenuPos] = useState(initialContextMenuPos);
  const [showTooltip, setShowTooltip] = useState(false);
  const closeContentTab = useSetCloseContentTab();

  const TabIcon = tabIcons[type];
  const tabTitle = title || UNTITLED_STORY;
  const shortDate = mType === 'rundown' ? formatDateToLocaleString(selectedDate) : '';

  const handleCloseIconClick = (event) => {
    event.stopPropagation();
    closeContentTab({ id });
  };

  const handleContextMenuClick = useCallback((event) => {
    event.preventDefault();
    setContextMenuPos({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }, []);

  const handleContextMenuClose = useCallback(() => {
    setContextMenuPos(initialContextMenuPos);
    setShowTooltip(false);
  }, []);

  dragRef(dropRef(tabRef));

  return (
    <Tooltip
      title={<TabToolTip title={title} type={type} />}
      noArrow
      maxWidth={256}
      open={contextMenuPos.mouseX === null && showTooltip}
    >
      <StyledTabWrapper
        ref={tabRef}
        width={width}
        onContextMenu={handleContextMenuClick}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        data-handler-id={handlerId}
      >
        <Tab
          key={id}
          hovered={hovered}
          type={type}
          selectedDate={selectedDate}
          mType={mType}
          width={width}
          onContextMenu={handleContextMenuClick}
          tabIndex={0}
          disableRipple
          {...rest}
          label={
            <StyledTabContentWrapper width={width - 48}>
              <StyledTabIconWrapper>
                <TabIcon />
              </StyledTabIconWrapper>
              <StyledLabel>
                <StyledLabelTextWrap> {tabTitle} </StyledLabelTextWrap>
                <StyledLabelTextWrap> {shortDate} </StyledLabelTextWrap>
              </StyledLabel>
              <IconButton onClick={handleCloseIconClick} tabIndex={-1}>
                <CloseSmall title="Close Tab" />
              </IconButton>
            </StyledTabContentWrapper>
          }
        />
        {contextMenuPos.mouseX !== null && (
          <ContextMenu contextMenuPos={contextMenuPos} tab={tab} onClose={handleContextMenuClose} />
        )}
      </StyledTabWrapper>
    </Tooltip>
  );
};

ContentTabView.propTypes = {
  /** index */
  index: PropTypes.number.isRequired,
  /** tab */
  tab: PropTypes.object.isRequired,
  /** width of the tab */
  width: PropTypes.number.isRequired,
};

export default ContentTabView;
