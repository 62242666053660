import isHotkey from 'is-hotkey';
import { Editor, Transforms } from 'slate';
import { elementTypes } from 'components/editor/constants';
import { matchRestriction } from 'components/editor/utils';
import voidTypes from 'components/editor/constants/types/voidTypes';
import getIndentLevel from './getIndentLevel';
import setIndentLevel from './setIndentLevel';

const { nodes, isEmpty, previous } = Editor;
const { insertText, removeNodes } = Transforms;
const voidTypeValues = Object.values(voidTypes);

/**
 * Handles onKeyDown event on paragraph element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @param {Number} maxIndentLevel Maximum allowed indentation level, default 3
 * @returns {Object} SlateJS editor instance
 */

const onParagraphKeyDown = (editor, event, variant, isAllowed, isCmsBlock, maxIndentLevel = 3) => {
  const [match] = nodes(editor, {
    match: ({ type }) => type === elementTypes.PARAGRAPH,
  });

  const shouldPrevent = !isCmsBlock && isAllowed && matchRestriction(variant);

  if (match) {
    if (shouldPrevent) event.preventDefault();

    const { key } = event;
    const isShiftTab = isHotkey('shift+tab')(event);
    const isShiftEnter = isHotkey('shift+enter')(event);
    const isTab = key === 'Tab';
    const isBackspace = key === 'Backspace';

    if (isShiftTab || isShiftEnter || isTab) event.preventDefault();

    if (isShiftTab) {
      const [element, path] = match;
      const indentLevel = getIndentLevel(element);

      if (indentLevel > 0) setIndentLevel(editor, path, indentLevel - 1);
    }

    if (!isShiftTab && isTab) {
      const [element, path] = match;
      const indentLevel = getIndentLevel(element);

      if (indentLevel < maxIndentLevel) setIndentLevel(editor, path, indentLevel + 1);
    }

    if (isShiftEnter) insertText(editor, '\n');

    if (isBackspace) {
      const [element, path] = match;

      if (isEmpty(editor, element)) {
        const previousMatch = previous(editor, { at: path });
        const [previousElement] = previousMatch || [];

        if (!shouldPrevent && previousElement && voidTypeValues.includes(previousElement.type)) {
          event.preventDefault();
          removeNodes(editor);
        }
      }
    }
  }

  return editor;
};

export default onParagraphKeyDown;
