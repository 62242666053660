import React, { useState } from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromLocalResolver';
import memberTypes from 'graphql/memberTypes';
import dndTypes from 'utils/dndTypes';
import useOpenStory from 'hooks/useOpenStory';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import Popover from 'components/popover';
import distanceInWord from 'utils/distanceInWords';
import DragSingle from 'assets/images/rundown/DragSingle.png';
import DragMultiple from 'assets/images/rundown/DragMultiple.png';
import InstancePreview from '../../instancePreview';
import InstanceItem from './instanceItem-view';

const PreviewWrapper = styled('div')`
  width: 560px;
`;

const EmptyDiv = styled('div')`
  display: none;
`;

const anchorOrigin = {
  vertical: 'center',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'center',
  horizontal: 'left',
};

const DraggableGridItem = ({
  mId,
  defaultThumb,
  publishingtime,
  currentOrder,
  currentAnchor,
  selectedIds,
  toggleCurrentItemFromSelectedIds,
  shiftItemsToSelectedIds,
  selectSingleItem,
  index,
  order,
  changeCurrentOrder,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const selected = selectedIds[index] === mId;
  const openStory = useOpenStory();

  const { data } = useQuery(GET_INSTANCE_FROM_CACHE, {
    variables: {
      input: {
        mId,
        mRefId: mId,
      },
    },
  });

  const handleClick = (event) => {
    const dataObject = { ignoreSelectedIds: false };
    if (order !== currentOrder) {
      changeCurrentOrder(order);
      dataObject.ignoreSelectedIds = true;
    }

    if (!(event.altKey || event.ctrlKey || event.metaKey || event.shiftKey)) {
      setAnchorEl(event.currentTarget);
      selectSingleItem(mId, index);
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      dataObject.ignoreSelectedIds
        ? selectSingleItem(mId, index)
        : toggleCurrentItemFromSelectedIds(mId, index);
      return;
    }
    if (event.shiftKey) {
      shiftItemsToSelectedIds(index, order, dataObject.ignoreSelectedIds);
    }
  };

  const payload = {
    id: mId,
    ids: Object.keys(selectedIds)
      .sort((a, b) => a - b)
      .map((key) => selectedIds[key]),
    platform: 'linear',
  };
  const [, dragRef, preview] = useDrag({
    type: dndTypes.INSTANCE,
    item: { type: dndTypes.INSTANCE, payload },
    canDrag: () => {
      if (!selected) handleClick({});
      return true;
    },
  });

  const {
    mTitle,
    mThumbnailUrl,
    items,
    mUpdatedAt,
    mStoryId,
    mContentKey,
    mProperties,
    mState,
    mMetaData,
    isTemplateInstance,
    mTemplateId,
    mAssignedMembers,
    mRelatedMembers,
  } = data?.instance || {};

  const [getAssignedMembers] = useGetAssignedMembers(mAssignedMembers || []);

  if (!data) return <EmptyDiv />;

  const timeString = distanceInWord(mUpdatedAt);
  const isOpenStoryDisabled = isTemplateInstance || mTemplateId;

  const [assignedUsers] = getAssignedMembers();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenStory = () => {
    mStoryId && openStory(mTitle, mStoryId, memberTypes.STORY, mId);
  };

  const onOpenStory = () => {
    handleClose();
    handleOpenStory();
  };

  const { account, platform } = mProperties;

  return (
    <>
      <div ref={dragRef} onClick={handleClick} role="presentation">
        <DragPreviewImage
          connect={preview}
          src={Object.entries(selectedIds).length > 1 ? DragMultiple : DragSingle}
        />
        <InstanceItem
          title={mTitle}
          image={mThumbnailUrl || defaultThumb}
          timingInfo={timeString}
          showFocus={Boolean(anchorEl)}
          selected={selected}
          items={items}
          {...rest}
        />
      </div>
      <Popover
        anchorEl={anchorEl}
        noMargin
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        type="surfaceCardDark"
      >
        <PreviewWrapper>
          <InstancePreview
            id={mId}
            title={mTitle}
            mContentKey={mContentKey}
            image={mThumbnailUrl || defaultThumb}
            onOpenStory={onOpenStory}
            assignees={assignedUsers}
            state={mState}
            metaData={mMetaData}
            destination={account.accountTitle}
            platform={platform}
            publishingAt={publishingtime}
            isOpenStoryDisabled={isOpenStoryDisabled}
            onClose={handleClose}
            instance={{ mRelatedMembers }}
          />
        </PreviewWrapper>
      </Popover>
    </>
  );
};

export default DraggableGridItem;
