import React, { memo, useCallback } from 'react';
import { Backdrop } from '@material-ui/core';
import Button from 'components/buttons/button';
import {
  AnimatedModalContent,
  Modal,
  ContentWrapper,
  HeaderWrapper,
  HeaderContent,
  Title,
  Filename,
  IconButton,
  CloseIcon,
  BodyWrapper,
  FooterWrapper,
} from './styled';

const createMarkup = (html) => {
  const input = html?.replaceAll('\\', '' ?? '');
  return {
    __html: `${input}`,
  };
};

const HtmlViewer = ({ handleClose, open, html }) => {
  const closeModal = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Modal
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <AnimatedModalContent open={open}>
        {open && (
          <ContentWrapper>
            <HeaderWrapper>
              <HeaderContent>
                <Title>HTML Preview</Title>
                <Filename>{html}</Filename>
              </HeaderContent>
              <IconButton size="small" onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </HeaderWrapper>
            <BodyWrapper dangerouslySetInnerHTML={createMarkup(html)} />
            <FooterWrapper>
              <Button onClick={closeModal}>Close</Button>
            </FooterWrapper>
          </ContentWrapper>
        )}
      </AnimatedModalContent>
    </Modal>
  );
};

export default memo(HtmlViewer);
