import React from 'react';
import PropTypes from 'prop-types';
import Header from './components/header';
import Footer from './components/footer';
import SettingsMenu from './components/settingsMenu';
import SettingsDetails from './components/settingsDetails';
import {
  RootWrapper,
  BodyWrapper,
  SettingsMenuWrapper,
  SettingsDetailsWrapper,
} from './settings-styled';

const Settings = (props) => {
  const { onClose, groupPolicies } = props;
  return (
    <RootWrapper>
      <Header onClose={onClose} />
      <BodyWrapper>
        <SettingsMenuWrapper>
          <SettingsMenu groupPolicies={groupPolicies} />
        </SettingsMenuWrapper>
        <SettingsDetailsWrapper>
          <SettingsDetails groupPolicies={groupPolicies} />
        </SettingsDetailsWrapper>
      </BodyWrapper>
      <Footer onClose={onClose} groupPolicies={groupPolicies} />
    </RootWrapper>
  );
};

Settings.propTypes = {
  /** callback to close the message hub dialog */
  onClose: PropTypes.func,
  groupPolicies: PropTypes.array,
};

Settings.defaultProps = {
  onClose: () => {},
  groupPolicies: [],
};

export default Settings;
