import { createContext } from 'react';

// export const BookmarksContext = createContext([]);

const defaultAutomationTemplateConfigsValues = {
  automationTemplateConfigIndex: 0,
  templateSetIndex: 0,
};

const SavedAutomationTemplateConfigsValues = JSON.parse(window.localStorage.getItem('automation'));

const automationTemplateConfigsValues = {
  ...defaultAutomationTemplateConfigsValues,
  ...SavedAutomationTemplateConfigsValues,
};

const ConfigContext = createContext(
  {
    iptcMediaTopics: [],
    externalUrls: [],
    feeds: [],
    automationTemplateConfigs: [],
    automationTemplateConfigsValues,
    metadataForms: [],
    userRights: [],
    settings: [],
  },
  () => {},
);

export default ConfigContext;
