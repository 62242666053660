import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete_small.svg';
import CreateNew from '../../../linearEllipsisMenu/components/createNew';
import ConfirmationComponent from '../../../linearEllipsisMenu/components/confirmationComponent';
import {
  List,
  ListItem,
  ListItemWrapper,
  ListItemText,
  AddIcon,
  Divider,
  DeleteWrapper,
} from './templatesSubMenu-styled';

const anchorOrigin = {
  vertical: 'center',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const SubMenu = (props) => {
  const {
    folderId,
    templates,
    anchorEl,
    onTemplateSelect,
    onTemplateSave,
    onDeleteTemplate,
    disabled,
    canCreateNewTemplate,
    canDeleteTemplate,
  } = props;

  const defaultTemplateValue = {
    mId: null,
    mRefId: null,
    mTitle: null,
    mContentKey: null,
  };

  const [anchor, setAnchor] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState(defaultTemplateValue);
  const [openDialog, setOpenDialog] = useState(false);

  const sortedTemplates = [...templates].sort((a, b) =>
    a.mTitle.localeCompare(b.mTitle, undefined, { numeric: true }),
  );

  const hidePopover = () => {
    setAnchor(null);
  };

  const handleTemplateSelect = (data) => {
    onTemplateSelect(data);
    hidePopover();
  };

  const handleCreateNewTemplate = (title, description, overwriteData) => {
    onTemplateSave(folderId, title, overwriteData);
    hidePopover();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentTemplate(defaultTemplateValue);
  };

  const handleConfirm = () => {
    onDeleteTemplate(currentTemplate.mId, currentTemplate.mRefId, currentTemplate.mContentKey);
  };

  return (
    <>
      <List disablePadding>
        {sortedTemplates.map((template) => {
          const { mRefId, mTitle, mContentKey } = template;
          return (
            <ListItemWrapper key={mRefId}>
              <ListItem
                button
                title={mTitle}
                disabled={disabled}
                onClick={() => handleTemplateSelect({ mContentKey })}
              >
                <ListItemText primary={mTitle} />
              </ListItem>
              {canDeleteTemplate && (
                <DeleteWrapper title="Delete Template">
                  <Delete
                    onClick={() => {
                      handleOpenDialog();
                      setCurrentTemplate(template);
                    }}
                  />
                </DeleteWrapper>
              )}
            </ListItemWrapper>
          );
        })}
        {openDialog && (
          <Popover anchorEl={anchorEl} onClose={handleDialogClose} noMargin>
            <ConfirmationComponent
              label={currentTemplate.mTitle}
              onOk={handleConfirm}
              onCancel={handleDialogClose}
            />
          </Popover>
        )}
        {canCreateNewTemplate && (
          <>
            <Divider />
            <ListItem
              button
              onClick={() => {
                setAnchor(anchorEl);
              }}
            >
              <AddIcon />
              <ListItemText primary="Save As New Template..." />
            </ListItem>
          </>
        )}
      </List>
      <Popover
        anchorEl={anchor}
        onClose={hidePopover}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        noMargin
      >
        <CreateNew
          variant="template"
          onCancel={hidePopover}
          onOk={handleCreateNewTemplate}
          data={templates}
        />
      </Popover>
    </>
  );
};

SubMenu.propTypes = {
  /** List of templates to be shown */
  templates: PropTypes.arrayOf(PropTypes.any),
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** Id of the folder */
  folderId: PropTypes.string,
  /** Callback to be invoked when a template is selected */
  onTemplateSelect: PropTypes.func,
  /** Callback to be invoked when new template is saved */
  onTemplateSave: PropTypes.func,
  /** Callback to be invoked when a template is deleted */
  onDeleteTemplate: PropTypes.func,
  /** boolean to disable the item */
  disabled: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
};

SubMenu.defaultProps = {
  templates: [],
  anchorEl: null,
  folderId: null,
  onTemplateSelect: () => {},
  onTemplateSave: () => {},
  onDeleteTemplate: () => {},
  disabled: false,
  canCreateNewTemplate: false,
};

export default SubMenu;
