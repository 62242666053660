/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel } from '@material-ui/core';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/systemicons/time.svg';
import Divider from 'components/divider';
import Select from 'components/select';
import Avatar from 'components/avatar';
import Popover from 'components/popover';
import Tooltip from 'components/tooltip';
import RadioButton from 'components/buttons/radioButton';
import Checkbox from 'components/checkbox';
import { isPast, isThisMinute, isAfter, differenceInMinutes, addMinutes } from 'date-fns';
import getRundownPublishingTime from 'utils/getRundownPublishingTime';
import variants from 'utils/instance/variants';
import useGetPlatforms from 'hooks/useGetPlatforms';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { scheduleNow, setHoursIsoString, setDateIsoString } from './utils';
import Picker from './components/picker';
import Footer from './components/footer';
import useStyles from './publishSettings-styles';

const forcePublishTooltip =
  // eslint-disable-next-line max-len
  'Will publish the article on schedule time even if there is media that is not yet ready for publishing. When the media is ready, the article will automatically get updated.';

const stringToBool = (str) => str === 'true';

const PublishSettings = ({
  variant,
  instanceType,
  selectedDestination,
  publishingTime,
  expiryTime,
  onOK,
  onCancel,
  anchorEl,
  isCmsBlock,
  forcePublishMetadata,
  updateInstanceMetadata,
}) => {
  /**
   * Returns the initial timestamp to be used to set the time value
   * If the initial publishing time is in the past, the value is set to now().
   */
  const getInitialTimeISOString = (forceReset) => {
    const idatestr = publishingTime || selectedDestination.publishingTime;
    if (!idatestr || forceReset) return new Date().toISOString();
    const pdate = new Date(idatestr);
    const idate = isPast(pdate) ? new Date() : pdate;
    return idate.toISOString();
  };

  const [newPublishingTime, setNewPublishingTime] = useState(getInitialTimeISOString());
  const [showExpiryDate, setShowExpiryDate] = useState(Boolean(expiryTime));
  const [forcePublish, setForcePublish] = useState(forcePublishMetadata.value);
  const [newExpiryTime, setNewExpiryTime] = useState(expiryTime);
  const offsetInMinutes = expiryTime ? differenceInMinutes(expiryTime, publishingTime) : 0;
  const [expiryOffset, setExpiryOffset] = useState(offsetInMinutes);
  const [isPublishingTimeChanged, setIsPublishingTimeChanged] = useState(false);

  const toggleShowExpiryDate = () => setShowExpiryDate((previousState) => !previousState);

  useEffect(() => {
    if (anchorEl) setNewPublishingTime(getInitialTimeISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const [publishNow, setPublishNow] = useState(scheduleNow(newPublishingTime));

  const [selectedRadioButton, setSelectedRadioButton] = useState(
    publishingTime ? 'schedule' : 'unschedule',
  );

  const [confirmPublish, setConfirmPublish] = useState(false);

  const [, error, loading, destinations] = useGetPlatforms(
    newPublishingTime,
    instanceType,
    !anchorEl,
  );

  const items = destinations.map((item) => ({
    ...item,
    icon: <Avatar size={24} publishingPoint={instanceType} variant="Publish" disableScaling />,
  }));

  const [selectedValue, setSelectedValue] = useState(
    selectedDestination.value || (destinations.length > 0 && destinations[0].value),
  );

  useEffect(() => {
    setSelectedValue(selectedDestination.value);
  }, [selectedDestination]);

  const isLinear = variant === variants.LINEAR;

  const handleForcePublishClick = () => {
    const newForcePublishValue = stringToBool(forcePublish) ? 'false' : 'true';
    updateInstanceMetadata([
      {
        key: forcePublishMetadata.key,
        value: newForcePublishValue,
      },
    ]);
    setForcePublish(newForcePublishValue);
  };

  const onResetDateTime = () => {
    setIsPublishingTimeChanged(true);
    const calculatedTime = getInitialTimeISOString(true);
    setNewPublishingTime(calculatedTime);
    setNewExpiryTime(addMinutes(calculatedTime, expiryOffset));
  };

  const getLinearPublishingTime = (destination, date) => {
    const { startTime, timeZone } = destination;
    return getRundownPublishingTime(new Date(date), startTime, timeZone);
  };

  const handleOK = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isLinear && selectedRadioButton !== 'unschedule' && publishNow && !confirmPublish) {
      setConfirmPublish(true);
      return;
    }
    const destination = destinations.find((d) => d.value === selectedValue);

    if (isLinear && !destination.id) {
      onOK({
        selectedDestination: destination,
        publishingTime: null,
      });
      return;
    }

    const newPublishingSettings = {
      selectedDestination: destination,
      publishingTime: (() => {
        if (isLinear) return getLinearPublishingTime(destination, newPublishingTime);
        if (selectedRadioButton === 'unschedule') return null;
        if (
          !isPublishingTimeChanged &&
          showExpiryDate &&
          publishingTime &&
          expiryTime !== newExpiryTime
        )
          return publishingTime;
        if (isPast(newPublishingTime)) return new Date().toISOString();
        return newPublishingTime;
      })(),
    };

    if (!isLinear) {
      newPublishingSettings.expiryTime = (() => {
        if (selectedRadioButton === 'unschedule' || !showExpiryDate || !newExpiryTime) return null;
        if (isPast(newExpiryTime)) return new Date().toISOString();
        return newExpiryTime;
      })();
    }
    onOK(newPublishingSettings);
    setIsPublishingTimeChanged(false);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setNewPublishingTime(getInitialTimeISOString());
    setConfirmPublish(false);
    onCancel();
  };

  const handleDateChange = (value) => {
    if (!value) return;
    const calculatedDate = setDateIsoString(newPublishingTime, value);
    if (newExpiryTime) setNewExpiryTime(addMinutes(calculatedDate, expiryOffset));
    setNewPublishingTime(calculatedDate);
    setIsPublishingTimeChanged(true);
  };

  const handleTimeChange = (value) => {
    if (!value) return;
    const calculatedTime = setHoursIsoString(newPublishingTime, value);
    if (newExpiryTime) setNewExpiryTime(addMinutes(calculatedTime, expiryOffset));
    setNewPublishingTime(calculatedTime);
    setIsPublishingTimeChanged(true);
  };

  const handleExpiryDateChange = (value) => {
    if (!value) return;
    const calculatedExpiryDate = setDateIsoString(
      newExpiryTime || addMinutes(new Date(), 1),
      value,
    );
    if (
      isAfter(calculatedExpiryDate, newPublishingTime) &&
      isAfter(calculatedExpiryDate, new Date())
    ) {
      setExpiryOffset(differenceInMinutes(calculatedExpiryDate, newPublishingTime));
      setNewExpiryTime(calculatedExpiryDate);
    }
  };

  const handleExpiryTimeChange = (value) => {
    if (!value) return;
    const calculatedExpiryDate = setHoursIsoString(
      newExpiryTime || new Date().toISOString(),
      value,
    );
    if (
      isAfter(calculatedExpiryDate, newPublishingTime) &&
      isAfter(calculatedExpiryDate, new Date())
    ) {
      setExpiryOffset(differenceInMinutes(calculatedExpiryDate, newPublishingTime));
      setNewExpiryTime(calculatedExpiryDate);
    }
  };

  const onDestinationChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    setPublishNow(scheduleNow(newPublishingTime));
    !selectedValue &&
      setSelectedValue(
        selectedDestination.value || (destinations.length > 0 && destinations[0].value),
      );
  }, [newPublishingTime, selectedDestination, destinations, selectedValue]);

  const shouldDisablePublish = destinations.some(
    (destination) => destination.value === selectedValue,
  );

  const classes = useStyles();

  if (error) return <div>{error.message}</div>;
  return (
    <Popover anchorEl={anchorEl} position="publish-setting-custom" onClose={handleCancel} noMargin>
      <div className={classes.container}>
        <div>
          <div className={classes.title}>Schedule Settings</div>
          <Divider />
        </div>
        <div className={classes.topComponents}>
          <div className={classes.subTitle}>Account</div>
          {loading && <LoadingIndicator />}
          <Select
            items={items}
            hideLabel
            selectedValue={selectedValue || null}
            onChange={onDestinationChange}
          />

          {!isLinear && (
            <div className={classes.radioButtonContainer}>
              <div
                className={classes.radioButton}
                role="presentation"
                onClick={() => {
                  if (selectedRadioButton !== 'unschedule') {
                    setSelectedRadioButton('unschedule');
                    setConfirmPublish(false);
                  }
                }}
              >
                <RadioButton selected={selectedRadioButton === 'unschedule'} size={22} />
                <span className={classes.radioText}>Unschedule</span>
              </div>

              <div
                className={classes.radioButton}
                role="presentation"
                onClick={() => {
                  selectedRadioButton !== 'schedule' && setSelectedRadioButton('schedule');
                }}
              >
                <RadioButton selected={selectedRadioButton === 'schedule'} size={22} />
                <span className={classes.radioText}>Schedule or publish</span>
              </div>
            </div>
          )}

          {!isLinear && selectedRadioButton === 'schedule' && (
            <>
              <div className={classes.dateTimePicker}>
                <div className={classes.datePicker}>
                  <Picker
                    Icon={CalendarOff}
                    SecondaryIcon={CalendarOn}
                    displaySecondaryIcon={publishingTime || !isThisMinute(newPublishingTime)}
                    type="date"
                    label="Publish Date"
                    timeValue={newPublishingTime}
                    onChange={handleDateChange}
                  />
                </div>
                <div className={classes.timePicker}>
                  <Picker
                    Icon={TimeIcon}
                    type="time"
                    label="Publish Time"
                    timeValue={newPublishingTime}
                    disable={isLinear}
                    onChange={handleTimeChange}
                  />
                </div>
                <div className={classes.resetDiv}>
                  <Typography
                    classes={{ root: classes.reset }}
                    onClick={onResetDateTime}
                    disabled={publishNow}
                  >
                    Set to Current Time
                  </Typography>
                </div>
              </div>
              <FormControlLabel
                control={
                  <Checkbox selected={showExpiryDate} onClick={toggleShowExpiryDate} size={24} />
                }
                label="Add Expiry Date"
                classes={{
                  root: classes.checkBox,
                  label: classes.checkBoxText,
                }}
              />
              {showExpiryDate && (
                <div className={classes.dateTimePicker}>
                  <div className={classes.datePicker}>
                    <Picker
                      Icon={CalendarOff}
                      SecondaryIcon={CalendarOn}
                      displaySecondaryIcon={expiryTime || !isThisMinute(expiryTime)}
                      type="date"
                      label="Expiry Date"
                      timeValue={newExpiryTime}
                      onChange={handleExpiryDateChange}
                      blankDisplayValue=""
                    />
                  </div>
                  <div className={classes.timePicker}>
                    <Picker
                      Icon={TimeIcon}
                      type="time"
                      label="Expiry Time"
                      timeValue={newExpiryTime}
                      onChange={handleExpiryTimeChange}
                      blankDisplayValue=""
                    />
                  </div>
                </div>
              )}
              {isCmsBlock && (
                <>
                  <Divider />
                  <Tooltip title={forcePublishTooltip} noArrow maxWidth={386}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          selected={stringToBool(forcePublish)}
                          onClick={handleForcePublishClick}
                          size={24}
                        />
                      }
                      label="Force Publish"
                      classes={{
                        root: classes.checkBox,
                        label: classes.checkBoxText,
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          )}

          {isLinear && (
            <>
              <div className={classes.blank} />
              <div className={classes.dateTimePicker}>
                <div className={classes.linearDatePicker}>
                  <Picker
                    Icon={CalendarOff}
                    SecondaryIcon={CalendarOn}
                    displaySecondaryIcon={publishingTime || !isThisMinute(newPublishingTime)}
                    type="date"
                    label="Publish Date"
                    timeValue={newPublishingTime}
                    onChange={handleDateChange}
                  />
                </div>

                <div className={classes.resetDiv}>
                  <Typography
                    classes={{ root: classes.reset }}
                    onClick={onResetDateTime}
                    disabled={publishNow}
                  >
                    Set to Today
                  </Typography>
                </div>
              </div>
            </>
          )}
        </div>
        <Divider />
        <Footer
          {...{
            handleCancel,
            isLinear,
            selectedRadioButton,
            handleOK,
            publishNow,
            confirmPublish,
            shouldDisablePublish,
          }}
        />
      </div>
    </Popover>
  );
};

PublishSettings.propTypes = {
  /** Variant of component */
  variant: PropTypes.oneOf(variants),
  /** Publishing time of the instance */
  publishingTime: PropTypes.string,
  /** Type of the instance */
  instanceType: PropTypes.string,
  /** Selected destination of this instance */
  selectedDestination: PropTypes.shape({}),
  /** Callback to be invoked when ok button is clicked */
  onOK: PropTypes.func,
  /** Callback to be invoked when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Default DOM element to anchor */
  anchorEl: PropTypes.objectOf(PropTypes.object),
  /** Expiry time of the instance */
  expiryTime: PropTypes.string,
  /** Whether CMS block structure is being used or not */
  isCmsBlock: PropTypes.bool,
};

PublishSettings.defaultProps = {
  variant: 'General',
  publishingTime: '',
  instanceType: '',
  selectedDestination: {},
  onOK: () => {},
  onCancel: () => {},
  anchorEl: null,
  expiryTime: '',
  isCmsBlock: false,
};

export default PublishSettings;
