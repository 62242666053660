import gql from 'graphql-tag';

export default gql`
  query GetRundownByPublishingDate($input: GetMembersByPublishingDateInput) {
    getRundownsByPublishingDate(input: $input) {
      mId
      mRefId
      mTitle
      mPublishingAt
      mPlannedDuration
      mThumbnailUrl
      mThumbnailKey
      mRundownTemplateId
      mType
      mState
      version
      recurrence {
        duration
        startTime
      }
    }
  }
`;
