import styled from '@emotion/styled/macro';
import { Popover as PopoverComponent } from '@material-ui/core';

import { ReactComponent as ColorIcon } from 'assets/icons/systemicons/editor/color.svg';
import colors from 'theme/colors';
import transientOptions from 'theme/helpers';

export const Color = styled(ColorIcon, transientOptions)`
  cursor: pointer;
  &:hover {
    filter: ${colors.onHover};
    -webkit-filter: ${colors.onHover};
  }
  & .colorBox {
    fill: ${(props) => props.$markColor};
  }
`;

export const Popover = styled(PopoverComponent)`
  .MuiPopover-paper {
    background: transparent;
    margin-top: 4px;
  }
`;

export const Reset = styled('div')`
  position: absolute;
  width: 25px;
  height: 25px;
  left: 6px;
  top: 6px;
  font-size: 0;
  z-index: 2;
  cursor: pointer;
  border: 1px solid ${colors.mediumEmphasis};
  &:hover {
    outline: 2px solid ${colors.highEmphasis};
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
  }
`;
