import { useMutation } from '@apollo/client';
import UPDATE_MEMBER from 'graphql/mutations/updateMember';

const usePublishDateUpdate = () => {
  const [updateStoryPublishingTime] = useMutation(UPDATE_MEMBER);

  const update = async (mId, mPublishingAt) => {
    await updateStoryPublishingTime({
      variables: {
        input: {
          mId,
          mPublishingAt,
        },
      },
    });
  };
  return [update];
};

export default usePublishDateUpdate;
