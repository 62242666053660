import React, { useRef, memo, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import useSearchBar from 'hooks/useSearchBar';
import LoadingIndicator from 'components/loadingIndicator';

import selectionTypes from '../selectionTypes';
import useStyles from './sidebar-styles';
import ViewComponent from './viewComponent';

const Iframe = lazy(() => import('./iframe'));

const viewsForViewComponent = [
  selectionTypes.CONTACTS,
  selectionTypes.TEAMS,
  selectionTypes.DEPARTMENT,
];

const SidebarView = (props) => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const { selectionType } = props;
  const OtherProps = {};
  const {
    filterModel,
    updateFilterModel,
    history,
    updateHistory,
    currentSearch,
    updateCurrentSearch,
    searchString,
  } = useSearchBar({ historyLimit: 5 });

  const showGraphicsBox = selectionType === selectionTypes.GRAPHICSBOX;
  const showStoryBox = selectionType === selectionTypes.STORYBOX;
  const showViewBox = viewsForViewComponent.includes(selectionType);

  return (
    <>
      <div className={classes[showGraphicsBox ? 'shown' : 'hidden']}>
        <Suspense fallback={LoadingIndicator}>
          <Iframe {...props} containerRef={rootRef} otherprops={{ ExternalName: 'pilotedge' }} />
        </Suspense>
      </div>
      <div className={classes[showStoryBox ? 'shown' : 'hidden']}>
        <Suspense fallback={LoadingIndicator}>
          <Iframe {...props} containerRef={rootRef} otherprops={{ ExternalName: 'mimir' }} />
        </Suspense>
      </div>
      <div className={classes[showViewBox ? 'shown' : 'hidden']}>
        <ViewComponent
          {...props}
          ref={rootRef}
          otherprops={OtherProps}
          filterModel={filterModel}
          updateFilterModel={updateFilterModel}
          history={history}
          updateHistory={updateHistory}
          currentSearch={currentSearch}
          updateCurrentSearch={updateCurrentSearch}
          searchString={searchString}
        />
      </div>
    </>
  );
};

SidebarView.propTypes = {
  /** Currently selected view type,
   * for each type a corresponding component is rendered
   * that's specified on viewComponents */
  selectionType: PropTypes.oneOf(Object.values(selectionTypes)),
  /** Show/hide the sidebar list  */
  sidebarHidden: PropTypes.bool,
};

SidebarView.defaultProps = {
  selectionType: null,
  sidebarHidden: false,
};

export default memo(SidebarView);
