import React, { useState } from 'react';
import PropTypes from 'prop-types';

import getInitials from 'utils/getInitials';
import useImageUpload from 'hooks/useImageUpload';
import useMenuAnchor from 'hooks/useMenuAnchor';

import Avatar from 'components/avatar';
import InputField from 'components/inputField';
import PlaceHolder from 'assets/icons/systemicons/AddMorePeople.png';
import menuActionTypes from './menuActionTypes';
import ActionMenu from '../../actionMenu';
import menuItems from './menuItems';

import {
  StyledWrapper,
  StyledAvatarWrapper,
  StyledTypographyWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledTeamInfo,
} from './avatarRow-styled';

const AvatarRowView = ({
  id,
  title: initialTitle,
  subtitle,
  additionalInfo,
  avatarUrl,
  variant,
  onImageUpload,
  disableUpload,
  updateDepartmentTitle,
}) => {
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [showInputField, setShowInputField] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const { anchorEl, openMenu, closeMenu } = useMenuAnchor();

  const onImageLoad = (imageFile) => {
    const imageSrc = URL.createObjectURL(imageFile);

    setUploadedAvatar(imageSrc);
    onImageUpload(imageFile, imageSrc);
  };

  const captureImage = useImageUpload({ onImageLoad });
  const avatarImage = uploadedAvatar || avatarUrl;

  const onMenuItemClick = (action) => {
    if (action === menuActionTypes.UPLOAD) captureImage();
  };

  const onTitleClick = () => {
    setShowInputField(true);
  };

  const onTitleChange = (value) => {
    setTitle(value);
  };

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      updateDepartmentTitle(id, title);
      setShowInputField(false);
    }
    if (e.keyCode === 27) {
      setTitle(initialTitle);
      setShowInputField(false);
    }
  };

  return (
    <StyledWrapper>
      <StyledAvatarWrapper>
        <Avatar
          disableScaling
          size={90}
          {...{ variant }}
          onClick={disableUpload ? null : openMenu}
          src={avatarImage}
          defaultSrc={PlaceHolder}
          pointer={!disableUpload}
          uploadEnabled={!disableUpload}
        >
          {getInitials(title)}
        </Avatar>
      </StyledAvatarWrapper>

      <StyledTypographyWrapper>
        {showInputField && variant === 'Department' ? (
          <InputField value={title} autoFocus onChange={onTitleChange} onKeyDown={handleEnterKey} />
        ) : (
          <StyledTitle onClick={onTitleClick}>{title}</StyledTitle>
        )}
        <StyledSubtitle>{subtitle}</StyledSubtitle>
        <StyledTeamInfo>{additionalInfo}</StyledTeamInfo>
      </StyledTypographyWrapper>

      <ActionMenu {...{ menuItems, anchorEl, closeMenu, onMenuItemClick }} position="left-center" />
    </StyledWrapper>
  );
};

AvatarRowView.propTypes = {
  /** Defines the primary header text */
  title: PropTypes.string,
  /** Defines the secondary header text */
  subtitle: PropTypes.string,
  /** If true, disables the action menu for image upload */
  disableUpload: PropTypes.bool,
  /** Defines additional header components to render */
  additionalInfo: PropTypes.node,
};

AvatarRowView.defaultProps = {
  title: 'The Title',
  subtitle: 'Subtitle',
  disableUpload: false,
  additionalInfo: null,
};

export default AvatarRowView;
