/* eslint-disable import/prefer-default-export */
import { atom, useAtom } from 'jotai';

/** Changed rows */
const changedRowsAtom = atom([]);
export const useChangedRows = () => useAtom(changedRowsAtom);

/** Selected item from left menu */
const selectedLeftMenuAtom = atom('profile');
export const useSelectedLeftMenu = () => useAtom(selectedLeftMenuAtom);

/** Selected group item */
const selectedGroupAtom = atom(false);
export const useSelectedGroup = () => useAtom(selectedGroupAtom);

/** Selected group name */
const selectedGroupNameAtom = atom();
export const useSelectedGroupName = (groupName) => useAtom(selectedGroupNameAtom);
