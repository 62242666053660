import checkIsNotificationConversation from 'utils/messageHub/checkIsNotificationConversation';
import conversationTypes from 'utils/constants/conversationTypes';
import capitalize from 'utils/capitalize';
import memberTypes from 'graphql/memberTypes';

const getAssignmentInfo = (mId, mType, isTemplateInstance, mTemplateId, storyId) => {
  switch (mType) {
    case memberTypes.USER_STORY:
      return { assignmentType: memberTypes.STORY, page: memberTypes.STORY, mStoryId: mId };
    case memberTypes.USER_PITCH:
      return { assignmentType: memberTypes.PITCH, page: memberTypes.PITCH, mStoryId: mId };
    case memberTypes.USER_INSTANCE:
      return {
        assignmentType: memberTypes.INSTANCE,
        page: memberTypes.STORY,
        mStoryId: isTemplateInstance || mTemplateId ? undefined : storyId,
        instanceId: isTemplateInstance || mTemplateId ? undefined : mId,
      };
    case memberTypes.TEAM_USER:
      return { assignmentType: memberTypes.TEAM, page: memberTypes.TEAM };
    case memberTypes.DEPARTMENT_USER:
      return { assignmentType: memberTypes.DEPARTMENT, page: memberTypes.DEPARTMENT };
    default:
      return { assignmentType: memberTypes.STORY, page: memberTypes.STORY };
  }
};

const createAssignmentContent = (content, mTitle) => {
  const mContent = JSON.parse(content);
  const { crudAction, mId, mType, isTemplateInstance, mTemplateId, mStoryId: storyId } = mContent;

  const crudInsertText =
    mType === memberTypes.TEAM_USER || mType === memberTypes.DEPARTMENT_USER
      ? 'added to'
      : 'assigned to';
  const actionText = crudAction === 'REMOVE' ? 'removed from' : crudInsertText;

  const { assignmentType, page, mStoryId, instanceId } = getAssignmentInfo(
    mId,
    mType,
    isTemplateInstance,
    mTemplateId,
    storyId,
  );

  const newContent = JSON.stringify({
    document: [
      {
        type: 'heading-three',
        children: [
          {
            text: `${capitalize(assignmentType)} Assignment`,
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: `You were ${actionText} ${assignmentType} ${mTitle}`,
          },
        ],
      },
    ],
    version: '0.1.0',
  });

  return { mContent: newContent, mStoryId, page, instanceId };
};

const getInfoFromContent = (content, convoType, mTitle) => {
  if (!checkIsNotificationConversation(convoType)) return { content };
  switch (convoType) {
    case conversationTypes.ASSIGNMENT: {
      return createAssignmentContent(content, mTitle);
    }
    default:
  }
  return { mContent: null };
};

export default getInfoFromContent;
