import React from 'react';
import PropTypes from 'prop-types';

import StatusIndicator from 'components/statusIndicators/StatusIndicator';
import Container from '../container/Container';

import { StyledPeopleAvatar, StyledCheck, StyledActivityStatus } from './people-styled';

const People = ({
  title,
  children,
  src,
  activityStatus,
  checked,
  width,
  height,
  type,
  highlightOnHover,
  ...rest
}) => (
  <Container width={width} height={height} {...rest}>
    <StyledPeopleAvatar type={type} width={width} height={height} src={src} title={title}>
      {src ? null : children}
    </StyledPeopleAvatar>

    {checked && (
      <StyledCheck>
        <StatusIndicator variant="Checked" />
      </StyledCheck>
    )}

    {activityStatus && (
      <StyledActivityStatus height={height} data-testid="indicator">
        <StatusIndicator variant={activityStatus} />
      </StyledActivityStatus>
    )}
  </Container>
);

People.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  activityStatus: PropTypes.oneOf(['', 'Available', 'Away', 'Idle']),
  checked: PropTypes.bool,
  /** Type of avatar based on border */
  type: PropTypes.PropTypes.oneOf(['root', 'bordered', 'thinBordered']),
};

People.defaultProps = {
  children: null,
  src: '',
  activityStatus: '',
  checked: false,
  type: 'root',
};

export default People;
