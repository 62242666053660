/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import fieldEnums from 'utils/constants/fieldEnums';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import GalleryItemImage from '../galleryItemImage';
import DragAndDrop from '../dragAndDropForGalleryItem';
import Metadata from './metadata';
import {
  GalleryItemContainer,
  CommonButton,
  MetaContainer,
  ButtonWrapper,
  MoveButtonWrapper,
  MoveDownButton,
} from './styled';

const GalleryItem = ({
  deleteItem,
  onUpdateAssetItemMeta,
  galleryItem,
  disableDeleteButton,
  moveGalleryItemWithOffset,
  hideMoveUp,
  hideMoveDown,
  moveItem,
  isAllowed,
}) => {
  const { index, mId, mRefId, proxy, caption, source } = galleryItem;

  const [getFieldsForBlock] = useGetFieldsForBlock();

  const field = getFieldsForBlock(fieldEnums.CUSTOM_SOURCES, { options: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sourceOptions = useMemo(() => field?.options || [], []);

  const updateMetadata = useCallback(
    (newValue, metaPropName) => {
      onUpdateAssetItemMeta(index, metaPropName, newValue);
    },
    [index, onUpdateAssetItemMeta],
  );

  const onClickDelete = useCallback(() => {
    deleteItem(index);
  }, [deleteItem, index]);

  const onClickMoveUp = useCallback(() => {
    moveGalleryItemWithOffset(index, -1);
  }, [moveGalleryItemWithOffset, index]);

  const onClickMoveDown = useCallback(() => {
    moveGalleryItemWithOffset(index, 1);
  }, [moveGalleryItemWithOffset, index]);

  const preventDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const renderMetaData = useMemo(() => {
    const sourceProps = {
      value: source || [],
      placeholder: 'Start typing to find Source',
      options: sourceOptions,
      propName: 'source',
    };

    const captionProps = {
      value: caption || '',
      placeholder: 'Add Caption...',
      description: 'Describe the photo',
      propName: 'caption',
    };

    return (
      <Metadata
        key={`metadata-${captionProps.value}-${sourceProps.value}`}
        captionProps={captionProps}
        updateMetadata={updateMetadata}
        sourceProps={sourceProps}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caption, source, sourceOptions, updateMetadata]);

  const renderedGalleryItem = useMemo(
    () => (
      <GalleryItemContainer>
        <GalleryItemImage mId={mId} mRefId={mRefId} proxy={proxy} />
        <MetaContainer draggable onDragStart={preventDrag}>
          {renderMetaData}
          <ButtonWrapper>
            <MoveButtonWrapper>
              {!hideMoveUp && (
                <CommonButton type="button" onClick={onClickMoveUp}>
                  Move Up
                </CommonButton>
              )}
              {!hideMoveDown && (
                <MoveDownButton type="button" onClick={onClickMoveDown}>
                  Move Down
                </MoveDownButton>
              )}
            </MoveButtonWrapper>
            <CommonButton disabled={disableDeleteButton} type="button" onClick={onClickDelete}>
              Delete
            </CommonButton>
          </ButtonWrapper>
        </MetaContainer>
      </GalleryItemContainer>
    ),
    [
      mId,
      mRefId,
      proxy,
      preventDrag,
      renderMetaData,
      hideMoveUp,
      onClickMoveUp,
      hideMoveDown,
      onClickMoveDown,
      disableDeleteButton,
      onClickDelete,
    ],
  );

  const renderedGalleryItemWithDragDrop = useMemo(
    () => (
      <DragAndDrop element={{ ...galleryItem, index }} onDragEnd={moveItem}>
        {renderedGalleryItem}
      </DragAndDrop>
    ),
    [galleryItem, index, moveItem, renderedGalleryItem],
  );

  if (!isAllowed) return renderedGalleryItem;

  return renderedGalleryItemWithDragDrop;
};

GalleryItem.propTypes = {
  /** Callable to be called when we drag and drop for changing the gallery item order */
  moveItem: PropTypes.func.isRequired,
  /** Callable to be called when delete button is pressed */
  deleteItem: PropTypes.func.isRequired,
  /** Callable to be called when we change the gallery item's source/caption
   * and store the changes on backend and slate editor.
   */
  onUpdateAssetItemMeta: PropTypes.func.isRequired,
  /** Gallery Item to be rendered */
  galleryItem: PropTypes.shape({}).isRequired,
  /** Flag for checking if we should disable the delete button or not */
  disableDeleteButton: PropTypes.bool,
  /** Callable to be called when we click move up or down button
   *  changing the gallery item order
   * */
  moveGalleryItemWithOffset: PropTypes.func.isRequired,
  /** Flag for checking if we should hide the moveUp button or not */
  hideMoveUp: PropTypes.bool,
  /** Flag for checking if we should hide the moveDown button or not */
  hideMoveDown: PropTypes.bool,
  isAllowed: PropTypes.bool,
};

GalleryItem.defaultProps = {
  disableDeleteButton: false,
  hideMoveUp: false,
  hideMoveDown: false,
  isAllowed: false,
};

export default memo(GalleryItem);
