import styled from '@emotion/styled';

import { ListItem, Typography } from '@material-ui/core';

import theme from 'theme/theme';

export const StyledListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;

  &:hover {
    background: ${theme.palette.dina.surfaceAppBackgroundMain};
  }

  &:focus {
    background-color: ${theme.palette.dina.onFocus};
  }
`;

export const StyledTitle = styled(Typography)`
  ${theme.typography.dina.overline};
  width: 287px;
  margin-top: -3px;
`;

export const StyledContent = styled(Typography)`
  ${theme.typography.dina.rightColumnHeaderText};
  color: ${theme.palette.dina.highEmphasis};
  width: 287px;
  min-height: 30px;
`;

export const StyledDividerWrapper = styled('div')`
  padding-left: 16;
`;
