import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './footer-styles';

const Footer = ({ onCancel, onOk, isOkDisabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Button className={classes.cancelButton} onClick={onCancel}>
        Cancel
      </Button>
      <Button className={classes.okButton} disabled={isOkDisabled} onClick={onOk}>
        Apply
      </Button>
    </div>
  );
};

Footer.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  isOkDisabled: PropTypes.bool,
};

Footer.defaultProps = {
  onCancel: () => {},
  onOk: () => {},
  isOkDisabled: false,
};

export default Footer;
