import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Popover from 'components/popover';
import Tooltip from 'components/tooltip';
import { currentSearchTypes, searchbarActions, searchbarFields } from 'utils/constants/searchbar';
import transitionTypeEnum from 'utils/constants/transitionTypeEnum';
import {
  StyledButtonContainer,
  StyledChip,
  StyledSearchRoot,
  ClearFilterButton,
  SavedFilterButton,
} from './searchbar-styled';
import Chip from './components/chip';
import Input from './components/input';
import SlideTransition from './components/slideTransition';
import FadeTransition from './components/fadeTransition';
import { SavedFilterIcon, ClearIcon } from './components/icons';
import PopoverContents from './components/popoverContents';
import checkIsEmptyModel from './utils/isEmptyModel';

const Searchbar = ({
  chipTypes,
  savedFilters,
  onCreateFilter,
  onUpdateFilters,
  history,
  updateHistory,
  filterModel,
  updateFilterModel,
  currentSearch,
  updateCurrentSearch,
  hideSavedFilters,
  platforms,
  showFreeTextInput,
  showSavedFiltersButton,
  transitionType,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverType, setPopoverType] = useState('');

  const clearAllFilters = useCallback(() => {
    updateFilterModel({ type: searchbarActions.CLEAR });
    updateCurrentSearch(null);
  }, [updateCurrentSearch, updateFilterModel]);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleUpdateHistory = useCallback(
    (type, value) => {
      const newHistoryInput = { ...filterModel };
      if (type === searchbarFields.UNIT) {
        newHistoryInput.department = value.department;
        newHistoryInput.team = value.team;
      } else newHistoryInput[type] = value;
      if (!checkIsEmptyModel(newHistoryInput)) {
        updateHistory(newHistoryInput);
        updateCurrentSearch(currentSearchTypes.HISTORY, 0, newHistoryInput);
      } else updateCurrentSearch(null);
    },
    [filterModel, updateCurrentSearch, updateHistory],
  );

  const updateInput = useCallback(
    (newValue) => {
      updateFilterModel({ type: searchbarActions.UPDATE_FREE_TEXT, value: newValue });
      handleUpdateHistory(searchbarFields.FREE_TEXT, newValue);
    },
    [handleUpdateHistory, updateFilterModel],
  );

  const handleOpenPopover = useCallback(
    (ref, type) => {
      if (popoverType !== type) setPopoverType(type);
      setAnchorEl(ref);
    },
    [popoverType],
  );

  const handleSavedFilterOpen = (event) => {
    handleOpenPopover(event.currentTarget, searchbarFields.SAVED_SEARCH);
  };

  return (
    <>
      <Divider />
      <StyledSearchRoot>
        {chipTypes.length > 0 &&
          chipTypes.map((chipType) => (
            <StyledChip key={chipType}>
              <Chip
                type={chipType}
                membersCount={
                  chipType === 'unit'
                    ? [...(filterModel?.team || []), ...(filterModel?.department || [])].length
                    : (filterModel[chipType] || []).length
                }
                openPopover={handleOpenPopover}
                fullWidth
              />
            </StyledChip>
          ))}
        <StyledButtonContainer showFreeTextInput={showFreeTextInput}>
          <Tooltip title="Clear all filters">
            <ClearFilterButton onClick={clearAllFilters}>
              <ClearIcon />
            </ClearFilterButton>
          </Tooltip>
          {showFreeTextInput && <Input value={filterModel?.freeText} updateValue={updateInput} />}
          {showSavedFiltersButton && (
            <Tooltip title="Show saved filters">
              <SavedFilterButton onClick={handleSavedFilterOpen}>
                <SavedFilterIcon />
              </SavedFilterButton>
            </Tooltip>
          )}
        </StyledButtonContainer>
      </StyledSearchRoot>
      <Popover
        noMargin
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        position={transitionType === transitionTypeEnum.FADE ? 'bottom' : 'top-center'}
        TransitionComponent={
          transitionType === transitionTypeEnum.FADE ? FadeTransition : SlideTransition
        }
      >
        <PopoverContents
          {...{
            popoverType,
            filterModel,
            savedFilters,
            onCreateFilter,
            onUpdateFilters,
            history,
            currentSearch,
            updateCurrentSearch,
            updateFilterModel,
            hideSavedFilters,
            platforms,
          }}
          updateHistory={handleUpdateHistory}
          onClose={handlePopoverClose}
        />
      </Popover>
    </>
  );
};

const model = PropTypes.shape({
  label: PropTypes.string,
  model: PropTypes.shape({
    freeText: PropTypes.string,
    user: PropTypes.arrayOf(PropTypes.shape({})),
    team: PropTypes.arrayOf(PropTypes.shape({})),
    department: PropTypes.arrayOf(PropTypes.shape({})),
    platform: PropTypes.arrayOf(PropTypes.shape({})),
  }),
});

Searchbar.propTypes = {
  chipTypes: PropTypes.arrayOf(PropTypes.string),
  savedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      model,
    }),
  ),
  currentSearch: PropTypes.shape({
    type: PropTypes.string,
    identification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  history: PropTypes.arrayOf(model),
  filterModel: model,
  time: PropTypes.string,
  onCreateFilter: PropTypes.func,
  onUpdateFilters: PropTypes.func,
  updateFilterModel: PropTypes.func,
  updateCurrentSearch: PropTypes.func,
  updateHistory: PropTypes.func,
  platforms: PropTypes.arrayOf(PropTypes.shape({})),
  showFreeTextInput: PropTypes.bool,
  showSavedFiltersButton: PropTypes.bool,
  transitionType: PropTypes.string,
};

Searchbar.defaultProps = {
  chipTypes: [],
  savedFilters: [],
  currentSearch: undefined,
  history: [],
  filterModel: {},
  time: new Date().toISOString(),
  onCreateFilter: () => {},
  onUpdateFilters: () => {},
  updateFilterModel: () => {},
  updateCurrentSearch: () => {},
  updateHistory: () => {},
  platforms: [],
  showFreeTextInput: true,
  showSavedFiltersButton: true,
  transitionType: 'slide',
};

export default Searchbar;
