/**
 * Returns array that is merged with two array passed based on prop
 *
 * @param {Object[]} dstArray where the other array will be merged
 * @param {Object[]} srcArray which will be merged with array1
 * @param {String} prop based on which array2 will be merged with array1
 * @returns {Object[]} merged array of array1 and array2
 *
 */

const MergeByProp = (dstArray, srcArray, prop) => {
  // assigned to newArray so that data is not mutated
  const newArray = dstArray.map(({ name, value }) => ({ name, value }));
  srcArray.forEach((obj) => {
    const index = newArray.findIndex((item) => item[prop] === obj[prop]);
    if (index >= 0) newArray.splice(index, 1, obj);
    else newArray.push(obj);
  });
  return newArray;
};

export default MergeByProp;
