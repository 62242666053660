import React from 'react';
import Scrollbar from 'screens/main/components/scrollbar';
import { makeStyles } from '@material-ui/styles';
import Header from './header';
import Info from './tabContent/TabContent';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    height: 'calc(100% - 178px)',
    background: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    // overflow: 'auto',
  },
  details: {
    height: '100%',
  },
}));
const Details = (props) => {
  const {
    onBackButtonClick,
    teamId,
    title,
    type,
    description,
    avatarUrl,
    imageKey,
    containerRef,
    teamMembers,
    teamDepartments,
    updateTeamTitle,
    canDeleteTeam,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.details}>
      <Header
        title={title}
        onBackButtonClick={onBackButtonClick}
        avatarUrl={avatarUrl}
        imageKey={imageKey}
        type={type}
        noOfMembers={teamMembers && teamMembers.length}
        updateTeamTitle={updateTeamTitle}
        {...{ teamId, canDeleteTeam }}
      />
      <div className={classes.mainDiv}>
        <Scrollbar>
          <Info
            teamTitle={title}
            teamDescription={description}
            {...{ teamId, teamMembers, teamDepartments, containerRef }}
          />
        </Scrollbar>
      </div>
    </div>
  );
};

export default Details;
