/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useCallback, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import pipe from 'lodash/fp/pipe';
import colors from 'theme/colors';
import { createEditor, Transforms, Range, Editor as SlateEditor, Node } from 'slate';
import { Slate, withReact, Editable, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import preventDefaultEvent from 'utils/preventDefaultEvent';
import stopEventPropagation from 'utils/stopEventPropagation';
import variants from './constants/types/editorVariants';
import { initialValues, actionTypes } from './constants';
import toolbarPositions from './constants/toolbarPositions';
import Leaf from './components/leaf';
import Toolbar from './components/toolbar';
import onParagraphKeyDown from './components/paragraph/utils/onParagraphKeyDown';
import onLeafKeyDown from './components/leaf/utils/onLeafKeydown';
import onQuoteKeyDown from './components/quote/utils/onQuoteKeyDown';
import onListKeyDown from './components/list/utils/onListKeyDown';
import { withLink, onLinkKeyDown } from './components/link/utils';
import onHorizontalRuleKeyDown from './components/horizontalRule/utils/onHorizontalRuleKeyDown';
import onPrimaryKeyDown from './components/primaryAutomation/utils/onPrimaryKeyDown';
import Suggestions from './components/mention/components/suggestions';
import EditorContext from './EditorContext';
import elementComponents from './constants/elementComponents';
import HoveringTooltip from './components/hoveringTooltip';
import HoveringToolbar from './components/hoveringToolbar/view';
import {
  onAssetElementKeyDown,
  onElementKeyDown,
  onVoidKeyDown,
  onPaste,
  withInline,
  withNormalization,
  withVoid,
} from './utils';
import { ToolbarWrapper, EditableWrapper, EditorWrapper } from './styled';

const wrappedEditor = pipe(
  createEditor,
  withReact,
  withHistory,
  withNormalization,
  withVoid,
  withInline,
  withLink,
);

const Editor = ({
  background,
  height,
  hostReadSpeed,
  placeholder,
  readOnly,
  renderToolbar,
  update,
  users,
  value,
  variant,
  shouldResetSelection,
  afterResetSelection,
  width,
  thumbnail,
  isAllowed,
  onCmsEditing,
  isPublished,
  toolbarPosition,
  onDone,
  setEditor,
  showHoveringTooltip,
  padding,
  platformStructure,
  isCmsBlock,
  editorFontSize,
  getPlaceholderConfigs,
  instance,
  withSignedUrl,
}) => {
  const containerRef = useRef(null);

  const editor = useMemo(() => wrappedEditor(), []);

  const { document: initialDocument, ...rest } =
    value || initialValues(variant, isAllowed, isCmsBlock);

  const resetSelection = () => {
    const { deselect, blur } = ReactEditor;
    editor.history = {
      redos: [],
      undos: [],
    };
    deselect(editor);
    blur(editor);
  };

  const shouldClearEditorContent = () => variant === variants.MESSAGE;

  const clearEditorContent = () => {
    const { document: emptyDocument } = initialValues(variant);
    editor.children = emptyDocument;
  };

  useEffect(() => {
    if (setEditor && editor) {
      setEditor(editor);
    }
  }, [setEditor, editor]);

  useEffect(() => {
    if (shouldResetSelection || !readOnly) {
      resetSelection();
    }

    if (value) {
      editor.children = initialDocument;
      editor.onChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (shouldResetSelection || !readOnly) {
      resetSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly]);

  useEffect(() => {
    if (shouldResetSelection) {
      resetSelection();
      afterResetSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldResetSelection]);

  const handleDone = useCallback(() => {
    onDone({ document: editor.children, ...rest });
    resetSelection();
    if (shouldClearEditorContent()) clearEditorContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.children, onDone]);

  const renderElement = useCallback(
    (props) => {
      const ElementComponent = elementComponents(props, variant);

      return <ElementComponent {...props} />;
    },
    [variant],
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const getDefaultPlaceholderConfigs = useCallback(
    () => ({
      template: {},
      s3Content: null,
      variables: {},
    }),
    [],
  );

  const handleUpdate = useCallback(
    ({ type, payload }) => update({ type, payload: { ...payload, ...rest } }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update],
  );

  const handleChange = useCallback(
    (newValue) => {
      const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
      if (isAstChange)
        handleUpdate({
          type: actionTypes.CHANGE,
          payload: { document: newValue },
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor.children],
  );

  const onKeyDown = useCallback(
    (event) => {
      onLeafKeyDown(editor, event, handleDone);
      onLinkKeyDown(editor, event);
      onElementKeyDown(editor, event, variant, isAllowed, isCmsBlock);
      onParagraphKeyDown(editor, event, variant, isAllowed, isCmsBlock);
      onQuoteKeyDown(editor, event);
      onListKeyDown(editor, event);
      onHorizontalRuleKeyDown(editor, event);
      onAssetElementKeyDown(editor, event, handleUpdate);
      onPrimaryKeyDown(editor, event, handleUpdate);
      onVoidKeyDown(editor, event, variant, isAllowed, isCmsBlock);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDone],
  );

  const onCut = useCallback((event) => {
    preventDefaultEvent(event);
    ReactEditor.setFragmentData(editor, event.clipboardData, 'cut');
    const { selection } = editor;
    if (selection) {
      if (Range.isExpanded(selection)) {
        const [start, end] = Range.edges(selection);
        if (start.offset === 0 && end.offset === 0) {
          preventDefaultEvent(event);
          const limit = end.path[0] + (SlateEditor.node(editor, end)[0].text === '' ? 1 : 0);
          for (let step = start.path[0]; step < limit; step += 1) {
            Transforms.removeNodes(editor, { at: [start.path[0]] });
          }
        } else {
          SlateEditor.deleteFragment(editor);
        }
      } else {
        const node = Node.parent(editor, selection.anchor.path);
        if (SlateEditor.isVoid(editor, node)) {
          Transforms.delete(editor);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** stop event bubble for onkeyup: for rundown grid */
  const onKeyUp = useCallback((event) => {
    preventDefaultEvent(event);
    stopEventPropagation(event);
  }, []);

  const toolbar = useMemo(
    () =>
      renderToolbar({
        variant,
        readOnly,
        isAllowed,
        platformStructure,
        isCmsBlock,
        toolbarPosition,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readOnly],
  );

  const handlePaste = useCallback(
    (event) => {
      onPaste(event, editor);
    },
    [editor],
  );

  return (
    <EditorWrapper background={background} width={width} height={height} ref={containerRef}>
      <Slate onChange={handleChange} value={initialDocument} editor={editor}>
        <EditorContext.Provider
          value={{
            update: handleUpdate,
            getPlaceholderConfig: getPlaceholderConfigs || getDefaultPlaceholderConfigs,
            containerRef,
            users,
            variant,
            thumbnail,
            isAllowed,
            onCmsEditing,
            isPublished,
            isCmsBlock,
            onDone: handleDone,
            editorFontSize,
            withSignedUrl,
          }}
        >
          {toolbarPosition === toolbarPositions.TOP && toolbar}
          <HoveringToolbar />
          <EditableWrapper
            padding={padding}
            fontSize={editorFontSize}
            messageVariant={variant === variants.MESSAGE}
            role="presentation"
            onMouseDown={preventDefaultEvent}
          >
            <Editable
              autoFocus
              onMouseDown={stopEventPropagation}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              readOnly={readOnly}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onDrop={(event) => true}
              onCut={onCut}
              onPaste={handlePaste}
            />
          </EditableWrapper>
          {toolbarPosition === toolbarPositions.BOTTOM && toolbar}
          {showHoveringTooltip && <HoveringTooltip hostReadSpeed={hostReadSpeed} />}
          <Suggestions />
        </EditorContext.Provider>
      </Slate>
    </EditorWrapper>
  );
};

Editor.propTypes = {
  /** Background color for the editor text area */
  background: PropTypes.string,
  /** Height of the editor view */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Placeholder text to show on empty editable area */
  placeholder: PropTypes.string,
  /** Boolean that indicates a read only editor */
  readOnly: PropTypes.bool,
  /** Render custom toolbar */
  renderToolbar: PropTypes.func,
  /** onCmsEditing callback to show cms iframe */
  onCmsEditing: PropTypes.func,
  /** on done button click callback */
  onDone: PropTypes.func,
  /** whether the instance is published or not */
  isPublished: PropTypes.bool,
  /** If true, deselects editor  */
  shouldResetSelection: PropTypes.bool,
  afterResetSelection: PropTypes.func,
  /** Callback to invoked when text editor's value updates,
   * with the update type and relevant payload passed in
   */
  update: PropTypes.func,
  /** List of users to show as suggestions */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mTitle: PropTypes.string,
    }),
  ),
  /** JSON value for the editor content */
  value: PropTypes.shape({
    version: PropTypes.string,
    document: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        data: PropTypes.shape({}),
      }),
    ),
  }),
  /** Variant of the editor */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Width of the editor view */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Determines the position of toolbar  */
  toolbarPosition: PropTypes.oneOf(Object.values(toolbarPositions)),
  /** Boolean to show/hide hovering tooltip indicating read time */
  showHoveringTooltip: PropTypes.bool,
  /** amount of padding for editable area */
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Editor.defaultProps = {
  background: colors.surfaceCard,
  height: 670,
  placeholder: 'Type something here...',
  readOnly: true,
  isPublished: false,
  renderToolbar: ({
    variant,
    readOnly,
    isAllowed,
    platformStructure,
    isCmsBlock,
    toolbarPosition,
  }) => (
    <ToolbarWrapper>
      <Toolbar
        variant={variant}
        readOnly={readOnly}
        isAllowed={isAllowed}
        platformStructure={platformStructure}
        isCmsBlock={isCmsBlock}
        toolbarPosition={toolbarPosition}
      />
    </ToolbarWrapper>
  ),
  shouldResetSelection: false,
  afterResetSelection: () => {},
  update: ({ type, payload }) => {},
  users: [],
  onCmsEditing: () => {},
  onDone: () => {},
  value: initialValues(variants.GENERAL),
  variant: variants.GENERAL,
  width: '100%',
  toolbarPosition: 'top',
  showHoveringTooltip: true,
  padding: 16,
};

export default memo(Editor);
