import { makeStyles } from '@material-ui/styles';

const useStyles = (on) =>
  makeStyles((theme) => ({
    root: {
      margin: '5px 0',
    },
    navigator: {
      float: 'left',
      padding: '3px 0',
    },
    headerText: {
      display: 'inline',
      padding: '3px 0',
      fontWeight: '400',
      fontSize: '14px',
      letterSpacing: '0.25px',
      outline: 'none',
      cursor: 'pointer',
      color: theme.palette.dina.mediumEmphasis,
      '&:hover': {
        color: theme.palette.dina.highEmphasis,
      },
    },
    dropdown: {
      float: 'right',
      margin: 'auto',
      maxWidth: '45%',
      padding: '5px 10px',
    },
    button: {
      maxWidth: '28px',
      maxHeight: '28px',
      minWidth: '28px',
      minHeight: '28px',
      padding: '0',
      '&:hover': {
        '& path': {
          'fill-opacity': 1,
        },
      },
      '&:disabled': {
        '& path': {
          'fill-opacity': 0.25,
        },
      },
    },
  }));

export default useStyles;
