/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { differenceInSeconds } from 'date-fns';
import PropTypes from 'prop-types';
import differenceBy from 'lodash/differenceBy';
import memberTypes from 'graphql/memberTypes';
import { UsersContext } from 'globalState/users';
import Alert from 'components/alert';
import checkIsSystemMessage from 'utils/messageHub/checkIsSystemMessage';
import infoImage from 'assets/icons/systemicons/info_off.svg';
import useOpenStory from 'hooks/useOpenStory';
import useStyles from './alert-styles';

const AlertView = ({
  notifications,
  onOpenMessageHub,
  selectedConversationIdRef,
  removeNotificationMessageCount,
}) => {
  const classes = useStyles();
  const timeOut = useRef(null);
  const [notificationList, setNotificationList] = useState([]);
  const [dismissedNotifications, setDismissedNotifications] = useState([]);

  const openStory = useOpenStory();

  const { getUser } = useContext(UsersContext);

  useEffect(() => {
    const recentNotifications = notifications.filter(
      ({ time }) => differenceInSeconds(new Date(), time) < 15,
    );

    const recentDismissedNotifications = dismissedNotifications.filter(
      ({ time }) => differenceInSeconds(new Date(), time) < 15,
    );

    const alertsToShow = differenceBy(recentNotifications, recentDismissedNotifications, 'time');
    setDismissedNotifications(recentDismissedNotifications);
    setNotificationList(alertsToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
      timeOut.current = null;
    }
    timeOut.current =
      notificationList.length &&
      setTimeout(() => {
        setNotificationList([]);
      }, 15000);
    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
        timeOut.current = null;
      }
    };
  }, [notificationList, setNotificationList]);

  const handleDismiss = (notification) => {
    const filteredNotifications = notificationList.filter(
      ({ mId, time }) => notification.mId !== mId || notification.time !== time,
    );
    setDismissedNotifications([...dismissedNotifications, notification]);
    setNotificationList(filteredNotifications);
  };

  const getAlertTitle = ({ mType, updatedBy }) => {
    switch (mType) {
      case memberTypes.CONVERSATION:
        return `Added to conversation by ${updatedBy}`;
      case memberTypes.MESSAGE:
        return `Message from ${updatedBy}`;
      case memberTypes.STORY:
        return 'Story assignment';
      case memberTypes.PITCH:
        return 'Pitch assignment';
      case memberTypes.INSTANCE:
        return 'Instance assignment';
      default:
        return '';
    }
  };

  const getAlertAvatarUrl = ({ mType, mUpdatedById, avatar }) => {
    switch (mType) {
      case memberTypes.CONVERSATION:
      case memberTypes.MESSAGE: {
        const user = getUser(mUpdatedById);
        const { mAvatarUrl } = user || {};
        return mAvatarUrl;
      }
      case memberTypes.STORY:
      case memberTypes.PITCH:
        return avatar;
      default:
        return undefined;
    }
  };

  const handleOpenContent = (notificationObject) => {
    const { mId, mRefId, mStoryId, mType, itemTitle } = notificationObject;
    if (mType === memberTypes.INSTANCE) {
      openStory(itemTitle, mStoryId, memberTypes.STORY, mRefId);
    } else {
      openStory(itemTitle, mStoryId, mType);
    }
    removeNotificationMessageCount(mId, mStoryId);
    handleDismiss(notificationObject);
  };

  const handleOpenMessageHub = (notificationObject) => {
    const { mId, mType } = notificationObject;
    switch (mType) {
      case memberTypes.CONVERSATION:
      case memberTypes.TEAM:
      case memberTypes.DEPARTMENT:
      case memberTypes.MESSAGE: {
        // eslint-disable-next-line no-param-reassign
        selectedConversationIdRef.current = mId;
        onOpenMessageHub();
        break;
      }
      case memberTypes.STORY:
      case memberTypes.PITCH:
      case memberTypes.INSTANCE: {
        handleOpenContent(notificationObject);
        break;
      }
      default:
    }
  };

  return (
    <div className={classes.list}>
      {notificationList.map((notification) => {
        const isSystemMessage = checkIsSystemMessage(notification);
        const alertTitle = isSystemMessage ? 'System Message' : getAlertTitle(notification);
        const mAvatarUrl = isSystemMessage ? infoImage : getAlertAvatarUrl(notification);
        return (
          <Alert
            key={`${notification.mId}_${notification.time}`}
            mCreatedAt={notification.time}
            alertTitle={alertTitle}
            onOk={() => {
              handleOpenMessageHub(notification);
            }}
            mAvatarUrl={mAvatarUrl}
            contentValue={notification.mContent}
            onCancel={() => {
              handleDismiss(notification);
            }}
            variant={notification.mType}
            contentHighlightedText={notification.itemTitle}
          />
        );
      })}
    </div>
  );
};

AlertView.propTypes = {
  /** list of notification alerts */
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  /** callback to open the messageHub component */
  onOpenMessageHub: PropTypes.func,
  /** ref containing the conversation id */
  selectedConversationIdRef: PropTypes.shape({
    current: PropTypes.string,
  }),
};

AlertView.defaultProps = {
  notifications: [],
  onOpenMessageHub: () => {},
  selectedConversationIdRef: { current: null },
};

export default AlertView;
