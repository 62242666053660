import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import InputField from 'components/inputField/InputField';
import useStyles from './LoginFormStyle';

const ResetForgottenPassword = (props) => {
  const defaultpasswordDescription = 'Type your New Password here...';
  const defaultVerificationCodeDescription = 'Enter the Verification Code here...';
  const severityMandatory = 'mandatory';
  const severityError = 'error';
  const classes = useStyles();
  const {
    username,
    passwordError,
    passwordErrorText,
    verificationCodeError,
    verificationCodeErrorText,
    onSubmit,
  } = props;
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const [passwordDescription, setPasswordDescription] = useState(defaultpasswordDescription);

  const [verificationCodeDescription, setverificationCodeDescription] = useState(
    defaultVerificationCodeDescription,
  );

  const [passwordSeverity, setPasswordSeverity] = useState(severityMandatory);
  const [verificationCodeSeverity, setverificationCodeSeverity] = useState(severityMandatory);

  const setPasswordSeverityWithDescription = (severity, description) => {
    setPasswordSeverity(severity);
    setPasswordDescription(description);
  };

  const setVerificationCodeSeverityWithDescription = (severity, description) => {
    setverificationCodeSeverity(severity);
    setverificationCodeDescription(description);
  };

  useEffect(() => {
    if (passwordError) {
      setPasswordSeverityWithDescription(severityError, passwordErrorText);
    } else if (verificationCodeError) {
      setVerificationCodeSeverityWithDescription(severityError, verificationCodeErrorText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordError, verificationCodeError]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(username, verificationCode, password);
    }
  };

  const onPasswordChange = (value) => {
    if (passwordSeverity === severityError) {
      setPasswordSeverityWithDescription(severityMandatory, defaultpasswordDescription);
    }
    setPassword(value);
  };

  const onVerificationCodeChange = (value) => {
    if (verificationCodeSeverity === severityError) {
      setVerificationCodeSeverityWithDescription(
        severityMandatory,
        defaultVerificationCodeDescription,
      );
    }
    setVerificationCode(value.trim());
  };

  return (
    <>
      <div>
        <Typography variant="h6" className={classes.resetText}>
          Please Enter the verification Code sent in your email and a new password to continue
        </Typography>
        <form onSubmit={handleSubmit} data-testid="resetforgottenPassword">
          <div className={classes.inputPadding} />
          <InputField
            label="Verification Code"
            type="text"
            description={verificationCodeDescription}
            severity={verificationCodeSeverity}
            value={verificationCode}
            onChange={(value) => onVerificationCodeChange(value)}
          />

          <div className={classes.inputPadding} />

          <InputField
            label="New Password"
            type="password"
            description={passwordDescription}
            severity={passwordSeverity}
            value={password}
            onChange={(value) => onPasswordChange(value)}
          />

          <div className={classes.inputPadding} />

          <Button
            type="submit"
            size="large"
            data-testid="btnSubmit"
            fullWidth
            disabled={!(verificationCode && password)}
            classes={{
              root: classes.buttonRoot,
              disabled: classes.buttonDisabled,
            }}
          >
            SUBMIT
          </Button>
        </form>
      </div>
    </>
  );
};

ResetForgottenPassword.propTypes = {
  /* To pass the username to verify in aws-amplify */
  username: PropTypes.string,

  /* To set error status for password. Values: true/false */
  passwordError: PropTypes.bool,

  /* Error message for password */
  passwordErrorText: PropTypes.string,

  /* To set error status for verification code. Values: true/false */
  verificationCodeError: PropTypes.bool,

  /* Error message for verification code */
  verificationCodeErrorText: PropTypes.string,

  /*  onSubmit function */
  onSubmit: PropTypes.func,
};

ResetForgottenPassword.defaultProps = {
  username: '',
  passwordError: false,
  passwordErrorText: '',
  verificationCodeError: false,
  verificationCodeErrorText: '',
  onSubmit: () => {},
};

export default ResetForgottenPassword;
