import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: '8px 16px 16px',
  },
  text: {
    ...theme.typography.dina.captionSmall,
    marginBottom: '3px',
  },
  anchor: {
    textDecorationLine: 'none',
  },
}));

export default useStyles;
