import React, { memo, useState, useCallback, useMemo } from 'react';
import { useSlate } from 'slate-react';
import styled from '@emotion/styled';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import PropTypes from 'prop-types';
import { checkIfDragDisabled } from 'components/editor/utils';
import variants from 'utils/instance/variants';
import { elementTypes } from 'components/editor/constants/types';
import { ReactComponent as H1Icon } from 'assets/icons/systemicons/editor/h1_off.svg';
import useInputEvents from 'hooks/useInputEvents';
import updateBlock from 'components/editor/utils/updateBlock';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import InputBlock from '../inputBlock';
import DragAndDrop from '../dragAndDrop';

export const HeadingIcon = styled(H1Icon)`
  margin: 8px;
`;

const MAX_LENGTH = 100;

const getDescription = (variant, str) => {
  if (variant === variants.YOUTUBE)
    return 'Max. 100 characters. First 70 characters are always shown.';
  return `${str.length} Characters`;
};

const Title = ({ attributes, children, element, readOnly }) => {
  const { data } = element;
  const { collapsed = false } = data;
  const editor = useSlate();
  const { update, variant } = useEditorContext();
  const initialValue = data.content || '';
  const [value, setValue] = useState(initialValue);

  const [onChangeCollapse] = useChangeCollapse(element);

  const updateTitle = useCallback(
    (newTitle) => {
      const updatedData = {
        ...data,
        content: newTitle,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const onUpdate = useCallback(
    (newValue) => {
      if (newValue === initialValue) setValue(initialValue);
      else updateTitle(newValue);
    },
    [initialValue, updateTitle],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(onUpdate, value, initialValue);

  const handleChange = useCallback((val) => {
    if (variant === variants.YOUTUBE && val.length <= MAX_LENGTH) setValue(val);
    else setValue(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = useMemo(
    () => (
      <InputBlock
        label="Title"
        icon={<HeadingIcon />}
        description={getDescription(variant, value)}
        placeholder="Enter title here..."
        hideEllipsisButton
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        onKeyDown={handleKeydown}
        onBlur={handleBlur}
        collapsed={collapsed}
        collapsedContent={value}
        updateCollapsed={onChangeCollapse}
        readOnly={readOnly}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      collapsed,
      handleBlur,
      handleChange,
      handleKeydown,
      inputRef,
      readOnly,
      onChangeCollapse,
      value,
      variant,
    ],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDisabled={checkIfDragDisabled(variant)}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
};

Title.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
  /** whether title block is readonly on not */
  readOnly: PropTypes.bool,
};

Title.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.TITLE,
  },
  readOnly: false,
};

export default memo(Title);
