import { useContext } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import GET_MEMBER from 'graphql/queries/getMember';
import UserContext from 'contexts/UserContext';
import CREATE_CONVERSATION from 'graphql/mutations/createConversation';
import CREATE_CONVERSATION_USER from 'graphql/mutations/createConversationUser';
import conversationTypes from 'utils/constants/conversationTypes';
import useLogger from 'utils/useLogger';
import memberTypes from 'graphql/memberTypes';
import GET_CONVERSATIONS from 'graphql/queries/getConversationsOfUser';

const updateCache = (cache, member, user) => {
  try {
    const { getConversationsOfUser } = cache.readQuery({
      query: GET_CONVERSATIONS,
      variables: {
        input: {
          mId: user.mId,
        },
      },
    });

    const newConversation = {
      convoType: member.convoType,
      mAssignedMembers: member.mAssignedMembers,
      mCreatedAt: member.mCreatedAt || new Date().toISOString(),
      mCreatedById: member.mCreatedById || '',
      mId: member.mId,
      mRefId: member.mRefId,
      mTitle: member.mTitle,
      mType: member.mType,
      mUpdatedAt: member.mUpdatedAt || new Date().toISOString(),
      mUpdatedById: member.mUpdatedById || '',
      convoLeaveOption: member.convoLeaveOption || false,
      __typename: 'ConversationType',
    };

    const isFound = getConversationsOfUser.find(
      (conversation) => conversation?.mRefId === newConversation.mRefId,
    );
    const newConversationList = isFound
      ? getConversationsOfUser
      : [newConversation, ...getConversationsOfUser];

    cache.writeQuery({
      query: GET_CONVERSATIONS,
      variables: {
        input: {
          mId: user.mId,
        },
      },
      data: {
        getConversationsOfUser: newConversationList,
      },
    });
  } catch (e) {
    // console.log(e);
  }
};

const getConversationInput = async (
  members = [],
  onOk = () => {},
  client,
  user,
  createConversationUser,
) => {
  if (!Array.isArray(members) || members.length === 0) return undefined;

  if (members.length === 1) {
    const [member] = members;
    if (member.conversationMId && member.conversationMRefId) {
      const {
        conversationMId: mId,
        conversationMRefId: mRefId,
        mAssignedMembers,
        convoType,
      } = member;
      if (convoType === conversationTypes.TEAM || convoType === conversationTypes.DEPARTMENT) {
        const item = await client.query({
          query: GET_MEMBER,
          variables: { input: { mId: member.mId, mRefId: user.mId } },
          fetchPolicy: 'network-only',
        });
        // If user doesn't exist in team or department create con_usr
        if (!item.data.getMember) {
          await createConversationUser({
            variables: { input: { mId: member.mId, mRefId: user.mId } },
          }).then((r) => {
            if (!r.loading) {
              member.convoLeaveOption = true;
              if (r && r.data && r.data.createConversationUser)
                onOk({
                  ...r.data.createConversationUser,
                  mRefId: r.data.createConversationUser.mId,
                  mAssignedMembers,
                  convoType,
                });
            }
          });
        }
        updateCache(client, member, user);
      }
      onOk({ mId, mRefId, mAssignedMembers, convoType });
      return undefined;
    }

    return {
      convoType: member.convoType,
      mAssignedMembers: member.mAssignedMembers,
    };
  }

  if (members.find((member) => member.convoType !== conversationTypes.DIRECT)) return undefined;

  return {
    convoType: conversationTypes.GROUP,
    mAssignedMembers: members.map(({ mId }) => ({ mId, mType: memberTypes.USER })),
  };
};

const useCreateConversation = () => {
  const user = useContext(UserContext);
  const logger = useLogger('use create conversation');
  const [createConversation] = useMutation(CREATE_CONVERSATION);
  const [createConversationUser] = useMutation(CREATE_CONVERSATION_USER);

  const client = useApolloClient();
  const create = async (newConversationMembers, onOk = () => {}) => {
    try {
      const input = await getConversationInput(
        newConversationMembers,
        onOk,
        client,
        user,
        createConversationUser,
      );

      if (input) {
        const variables = {
          input,
        };
        await createConversation({
          variables,
          update: (cache, mutationResult) => {
            const { createConversation: createConversationResult } = mutationResult.data;
            const { getConversationsOfUser } = cache.readQuery({
              query: GET_CONVERSATIONS,
              variables: {
                input: {
                  mId: user.mId,
                },
              },
            });

            const filteredConversations = getConversationsOfUser.filter(
              (conversation) => conversation?.mRefId !== createConversationResult.mRefId,
            );

            const newConversationList = [
              { ...createConversationResult, mUpdatedAt: new Date().toISOString() },
              ...filteredConversations,
            ];

            cache.writeQuery({
              query: GET_CONVERSATIONS,
              variables: {
                input: {
                  mId: user.mId,
                },
              },
              data: {
                getConversationsOfUser: newConversationList,
              },
            });
          },
        }).then((r) => {
          if (!r.loading) {
            if (r && r.data && r.data.createConversation) onOk(r.data.createConversation);
          }
        });
      }
    } catch (e) {
      logger.error(e);
    }
  };

  return [create];
};

export default useCreateConversation;
