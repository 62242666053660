import React from 'react';

import useSettingsValue from 'hooks/useSettingsValue';
import Tabs from './tabs';
import UserContent from './userContent';
import CreateNew from './create';

import {
  StyledAppbar as Appbar,
  StyledToolbar as Toolbar,
  StyledTabDivider as Divider,
} from './navbar-styled';

const NavbarView = () => {
  const [getSettingsValue] = useSettingsValue();
  const rundownOnly = getSettingsValue('rundown.rundownOnly') === 'true';

  return (
    <Appbar elevation={0} position="static">
      <Toolbar>
        {!rundownOnly && <CreateNew title="Create new story" />}
        <Divider orientation="vertical" flexItem />
        <Tabs />
        <Divider orientation="vertical" flexItem />
        <UserContent />
      </Toolbar>
    </Appbar>
  );
};
export default NavbarView;
