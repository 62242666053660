/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Editor from 'components/editor';
import getInitialValue from 'components/editor/constants/initialValues';
import toolbarPositions from 'components/editor/constants/toolbarPositions';
import editorVariants from 'components/editor/constants/types/editorVariants';
import useStyles from './messageInput-styles';

const initialValue = getInitialValue();

const MessageInput = ({ messageValue, onSend }) => {
  const classes = useStyles();

  const handleSend = async (newMessage) => {
    if (!isEqual(newMessage, initialValue)) {
      await onSend(newMessage);
    }
  };

  return (
    <div className={classes.editor}>
      <Editor
        height={null}
        readOnly={false}
        toolbarPosition={toolbarPositions.BOTTOM}
        variant={editorVariants.MESSAGE}
        isAllowed
        value={messageValue}
        onDone={handleSend}
        showHoveringTooltip={false}
        padding={0}
      />
    </div>
  );
};

MessageInput.propTypes = {
  /** slate json object to be shown in editor */
  messageValue: PropTypes.shape({
    document: PropTypes.arrayOf(PropTypes.shape({})),
    version: PropTypes.string,
  }),
  /** callback for sending message */
  onSend: PropTypes.func,
};

MessageInput.defaultProps = {
  messageValue: initialValue,
  onSend: () => {},
};

export default MessageInput;
