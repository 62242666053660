import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    width: ({ variant }) => (variant === 'placeholder' ? '400px' : '506px'),
    height: '242px',
    background: theme.palette.dina.surfaceCard,
    backdropFilter: 'blur(30px)',
    borderRadius: '8px',
    margin: '-8px 0',
  },
  formContainer: {
    height: '100%',
  },
  formDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    borderRadius: '8px',
  },
  topComponents: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  heading: {
    ...theme.typography.dina.marketplaceHeader,
    margin: '24px 48px 8px 0',
  },
  subHeading: {
    ...theme.typography.dina.overline,
    margin: '10px 0px',
  },
  hint: {
    ...theme.typography.dina.caption,
    marginTop: '4px',
    position: 'relative',
    display: 'flex',
    left: '4px',
    right: '8px',
    alignItems: 'center',
    color: ({ hint }) => (hint && hint.isError ? '#FF6666' : 'rgba(255, 255, 255, 0.65)'),
  },
  inputContainer: {
    marginRight: '16px',
  },
  bottomComponents: {},
  buttonLayout: {
    display: 'flex',
    flexGrow: 1,
    margin: '5px 15px 6px 16px',
    '& > *:not(:first-child)': {
      marginLeft: '8px',
    },
  },
  checkBox: {
    margin: 0,
    height: '40px',
  },
  checkBoxText: {
    ...theme.typography.dina.listItemLabel,
  },
}));

export default useStyles;
