import styled from '@emotion/styled';
import { ListItem as ListItemComponent } from '@material-ui/core';
import PopperComponent from 'components/shared/popper';
import theme from 'theme/theme';
import transientOptions from 'theme/helpers';

export const InstancePreviewWrapper = styled('div')`
  width: 527px;
`;

export const ListItem = styled(ListItemComponent, transientOptions)`
  &.MuiListItem-root {
    padding: 0 8px 0 0;
    background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel2};
    transform: translate3d(0, 0, 0);
    flex-direction: column;
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};

    :last-child {
      border-bottom: none;
    }
    :hover {
      background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel2};
      filter: ${theme.palette.dina.onHover};
    }
  }
  &.Mui-selected,
  &.Mui-selected:hover,
  &.Mui-selected:focus {
    background-color: ${(props) =>
      theme.palette.dina[props.$isInstanceItemVariant ? 'onFocusOpacity' : 'onFocus']};
  }
`;

export const Popper = styled(PopperComponent)`
  background-color: transparent;
`;
