import React, { useMemo, useState, useContext, useRef, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import memberTypes from 'graphql/memberTypes';
import { SidebarSubTabContext } from 'globalState';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import useOpenStory from 'hooks/useOpenStory';
import { useLeftSidebarSavedSearches } from 'store/sidebar';

import { Typography, FormControlLabel } from '@material-ui/core';
import Scrollbar from 'screens/main/components/scrollbar';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Tabs from 'components/tabs';
import Divider from 'components/divider';
import Searchbar from 'components/searchbarV2';
import Switch from 'components/switch';
import DatePickerButton from './datePickerButton';
import useStyles from './stories-styles';
import ListGroup from './listGroup';

const headers = {
  [memberTypes.USER]: 'My Stories/Pitches',
  [memberTypes.RUNDOWN_USER]: 'Rundowns',
  [memberTypes.USER_BOOKMARK]: 'Bookmarks',
  [memberTypes.TEAM_USER]: 'Teams',
  [memberTypes.DEPARTMENT_USER]: 'Departments',
  [memberTypes.STORY]: 'All Stories',
  [memberTypes.PITCH]: 'All Pitches',
};

const Storybox = lazy(() => import('screens/storybox'));

const Stories = ({
  groups,
  selectionType,
  onDateChanged,
  selectedDates,
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  assignedMembers,
  searchString,
}) => {
  const classes = useStyles();
  const [showStorybox, setShowStorybox] = useState(null);
  const [{ subTabs, selectedSubTab, setSelectedSubTab, scheduleType }] =
    useContext(SidebarSubTabContext);
  const [leftSidebarSavedSearches, setLeftSidebarSavedSearches] = useLeftSidebarSavedSearches();
  const [, setCurrentClicked] = useScrollIntoView();
  const scrollbarRef = useRef(null);
  const previewAnchorRef = useRef(null);

  const onTabChange = (value, index) => {
    setSelectedSubTab(index);
  };

  const [userItemsOnly, setUserItemsOnly] = useState(false);

  const handleCreateNewFilter = (newFilter) => {
    setLeftSidebarSavedSearches([...leftSidebarSavedSearches, newFilter]);
  };

  const handleUpdateFilters = (newSavedFilters) => {
    setLeftSidebarSavedSearches(newSavedFilters);
  };

  const handleToggleSwitch = () => {
    setUserItemsOnly(!userItemsOnly);
  };

  const openStory = useOpenStory();

  const sidebarHeader = (
    <div className={classes.sidebarHeader} ref={previewAnchorRef}>
      <Typography className={classes.sidebarHeaderText}>{headers[selectionType]}</Typography>
      {selectionType !== 'user' && (
        <FormControlLabel
          control={<Switch selected={userItemsOnly} onChange={handleToggleSwitch} />}
          classes={{
            root: classes.formControlLabel,
            label: classes.label,
          }}
          label="My items only"
          labelPlacement="start"
        />
      )}
    </div>
  );

  const memoizedDateTimePicker = useMemo(
    () => <DatePickerButton onSelectedDateChange={onDateChanged} />,
    [onDateChanged],
  );

  const groupsCount = groups ? groups.length : 0;
  const groupIds = [];
  if (groups && groups.length > 0) {
    groups.forEach((group) => {
      groupIds.push(group.mId);
    });
  }

  const onBeforeRefetchStories = () => {
    const itemHeight = 56;
    const additionalArea = 102;
    const offset = additionalArea + 3 * itemHeight;
    return scrollbarRef.current.getScrollTop() + scrollbarRef.current.getClientHeight() - offset;
  };

  const onAfterRefetchStories = (scrollTopPosition) => {
    scrollbarRef.current.scrollTop(scrollTopPosition);
  };

  const tabs = subTabs.map((subTab) => ({
    label: subTab,
  }));

  const sidebarContent = (
    <div className={classes.sidebarContent}>
      <Scrollbar ref={scrollbarRef}>
        <div>
          {groups.map((group, index) =>
            group ? (
              <ListGroup
                groupIds={groupIds}
                key={group.mId}
                title={group.mTitle}
                mId={group.mId}
                type={group.mType}
                onBeforeRefetch={onBeforeRefetchStories}
                onAfterRefetch={onAfterRefetchStories}
                ref={previewAnchorRef}
                {...{
                  setShowStorybox,
                  groupsCount,
                  index,
                  selectedDates,
                  openStory,
                  subTabs,
                  selectedSubTab,
                  scheduleType,
                  userItemsOnly,
                  searchString,
                  assignedMembers,
                }}
              />
            ) : (
              <div style={{ background: 'red', color: 'white' }}>Group failure</div>
            ),
          )}
        </div>
      </Scrollbar>
    </div>
  );

  const handleOpenStory = () => {
    openStory(showStorybox.title, showStorybox.id, showStorybox.page);
  };

  const onInstanceClick = async (sId, instanceId) => {
    await openStory(showStorybox.title, showStorybox.id, showStorybox.page).then(() => {
      setCurrentClicked(instanceId);
    });
  };

  return (
    <div className={classes.root}>
      {!showStorybox ? (
        <>
          {sidebarHeader}
          {(selectionType === memberTypes.STORY || selectionType === memberTypes.PITCH) && (
            <>
              <Tabs
                variant="default"
                tabWidth="scrollable"
                tabIndex={selectedSubTab}
                {...{ tabs, onTabChange }}
              />
              <div className={classes.divider}>
                <Divider />
              </div>
            </>
          )}
          {memoizedDateTimePicker}
          {sidebarContent}
          <Searchbar
            chipTypes={['user', 'unit']}
            savedFilters={leftSidebarSavedSearches}
            onCreateFilter={handleCreateNewFilter}
            onUpdateFilters={handleUpdateFilters}
            {...{
              filterModel,
              updateFilterModel,
              history,
              updateHistory,
              currentSearch,
              updateCurrentSearch,
            }}
          />
        </>
      ) : (
        <Suspense fallback={LoadingIndicator}>
          <Storybox
            onOpenStory={handleOpenStory}
            onBackClick={() => setShowStorybox(null)}
            storySpec={showStorybox}
            onInstanceClick={onInstanceClick}
          />
        </Suspense>
      )}
    </div>
  );
};

Stories.propTypes = {
  /** list group */
  groups: PropTypes.arrayOf(PropTypes.object),
  /** selection type */
  selectionType: PropTypes.string,
  /** on date change function */
  onDateChanged: PropTypes.func,
  /** list of members to be searched by searchApi */
  assignedMembers: PropTypes.arrayOf(PropTypes.string),
  /** search term for search api */
  searchString: PropTypes.string,
};

Stories.defaultProps = {
  groups: [],
  selectionType: '',
  onDateChanged: () => {},
  assignedMembers: [],
  searchString: '',
};

export default Stories;
