import React, { useCallback, memo, useMemo } from 'react';
import { BorderHorizontal } from '@material-ui/icons';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { Range } from 'slate';
import { useSlate, useFocused } from 'slate-react';
import { isElementActive, toggleElement } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants';
import useStyles from './styles';

const isZero = (value) => value === 0;

const { edges, isCollapsed } = Range;
const { HORIZONTAL_RULE } = elementTypes;

const HorizontalRuleButton = () => {
  const editor = useSlate();
  const isFocused = useFocused();
  const isActive = isElementActive(editor, HORIZONTAL_RULE);
  const classes = useStyles({ isActive });

  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      if (isFocused && isCollapsed(editor.selection)) {
        const [start] = edges(editor.selection);
        if (isZero(start.offset)) toggleElement(editor, HORIZONTAL_RULE, isActive);
      }
    },
    [isFocused, editor, isActive],
  );

  const renderContent = useMemo(
    () => (
      <div
        className={classes.root}
        role="presentation"
        title={tooltipEnum.HORIZONTAL_RULE}
        {...{ onMouseDown }}
      >
        <BorderHorizontal className={classes.icon} />
      </div>
    ),
    [classes.icon, classes.root, onMouseDown],
  );

  return renderContent;
};

export default memo(HorizontalRuleButton);
