import { useApolloClient } from '@apollo/client';
import GET_SUMMARY from 'graphql/queries/getSummary';

const useGetSummary = () => {
  const client = useApolloClient();

  const summarize = (text) =>
    client
      .query({
        query: GET_SUMMARY,
        variables: {
          input: {
            mContent: text,
            mSummarizeLines: 1,
          },
        },
        fetchpolicy: 'cache-and-network',
      })
      .then((result) => {
        const { data } = result;

        if (data && data.getSummary && data.getSummary.mSummarize) {
          return data.getSummary.mSummarize;
        }
        return null;
      });

  return [summarize];
};

export default useGetSummary;
