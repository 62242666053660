import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { isMarkActive, toggleMark } from 'components/editor/utils';
import { markTypes } from 'components/editor/constants';
import iconComponents from './constants/iconComponents';
import ButtonBase from '../buttonBase';

const MarkButton = ({ type }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, type);

  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      toggleMark(editor, type, isActive);
    },
    [editor, isActive, type],
  );

  const IconComponent = iconComponents[type];

  return (
    <ButtonBase
      type={type}
      isActive={isActive}
      IconComponent={IconComponent}
      onMouseDown={onMouseDown}
    />
  );
};

MarkButton.propTypes = {
  /** Type for the button */
  type: PropTypes.oneOf(Object.values(markTypes)),
};

MarkButton.defaultProps = {
  type: markTypes.BOLD,
};

export default memo(MarkButton);
