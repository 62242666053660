import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    cursor: 'pointer',
    '& *': {
      fill: ({ isActive }) => isActive && palette.dina.iconActive,
      fillOpacity: ({ isActive }) => isActive && 1,
    },
    '&:hover': {
      '& *': {
        fill: palette.dina.iconActive,
        fillOpacity: 1,
      },
    },
  },
  container: {
    backgroundColor: ({ isActive }) => isActive && palette.dina.iconDisabled,
    borderRadius: '4px',
    width: '24px',
    height: '24px',
  },
}));

export default useStyles;
