import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton } from '@material-ui/core';
import useStyles from './button-styles';

export const variants = {
  outlined: 'outlined',
  contained: 'contained',
  text: 'text',
};

export const types = {
  cta: 'cta',
  significant: 'significant',
  danger: 'danger',
  warning: 'warning',
  dashedOutlined: 'dashedOutlined',
  outlinedRequired: 'outlinedRequired',
  story: 'story',
  pitch: 'pitch',
};

export const shapes = {
  square: 'square',
  rectangle: 'rectangle',
  lgRectangle: 'lg-rectangle',
  smRectangle: 'sm-rectangle',
  fullWidth: 'full-width',
  actionButton: 'action-button',
};

const Button = ({
  children,
  variant,
  type,
  disabled,
  onClick,
  shape,
  submit,
  size,
  required,
  ...rest
}) => {
  const classes = useStyles(size)();

  let iconPlacement = '';
  if (Array.isArray(children)) {
    iconPlacement =
      typeof children[0] === 'string' ? (iconPlacement = classes.iconRight) : classes.iconLeft;
  }

  let fullWidth = false;
  let className;
  switch (shape) {
    case shapes.square:
      className = classes.buttonSquare;
      break;
    case shapes.lgRectangle:
      className = classes.buttonRectanglelg;
      break;
    case shapes.smRectangle:
      className = classes.buttonRectanglesm;
      break;
    case shapes.fullWidth:
      className = classes.buttonFullWidth;
      fullWidth = true;
      break;
    case shapes.actionButton:
      className = classes.actionButton;
      break;
    default:
      className = classes.buttonRectangle;
  }

  return (
    <MaterialButton
      data-testid="button"
      variant={variant}
      disabled={disabled}
      className={className}
      onClick={onClick}
      fullWidth={fullWidth}
      classes={{
        root: classes[type],
        outlined: required ? classes.outlinedRequired : classes.outlined,
        label: iconPlacement,
        contained: classes.contained,
      }}
      type={submit ? 'submit' : 'button'}
      {...rest}
    >
      {children}
    </MaterialButton>
  );
};

Button.propTypes = {
  /** Elements that should be rendered on the button (text or icon) */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
  /**  Values: text, outlined or contained */
  variant: PropTypes.oneOf(Object.values(variants)),
  /**  Values: true/false */
  required: PropTypes.bool,
  /**  Values: true/false */
  disabled: PropTypes.bool,
  /**  Values: cta, danger, significant, story, pitch */
  type: PropTypes.oneOf(['', ...Object.values(types)]),
  /** Values: rectangle, square, lg-rectangle, full-width */
  shape: PropTypes.oneOf(Object.values(shapes)),
  /**  onClick function */
  onClick: PropTypes.func,
  /** Values: true/false */
  submit: PropTypes.bool,
  /** Defines width and height of button */
  size: PropTypes.number,
};

Button.defaultProps = {
  children: '',
  variant: 'outlined',
  required: false,
  disabled: false,
  type: '',
  onClick: () => {},
  shape: 'rectangle',
  submit: false,
  size: 64,
};

export default Button;
