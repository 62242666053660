import React from 'react';
import styled from '@emotion/styled';

import theme from 'theme/theme';
import { ReactComponent as NewStory } from 'assets/icons/systemicons/HeaderNavbar/Resting_Outline.svg';

const CreateButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    transition: all 0.3s;

    &:hover {
      transform: scale(1.1);
      fill: ${theme.palette.dina.iconActive};
      fill-opacity: 1;
    }
  }
`;

const CreateTab = (props) => (
  <CreateButtonContainer>
    <NewStory {...props} />
  </CreateButtonContainer>
);

export default CreateTab;
