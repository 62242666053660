import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  divWrapper: {
    width: '100%',
    marginTop: '15px',
  },
  compact: {
    height: '64px',
    width: '100%',
    marginTop: '5px',
    marginBottom: '25px',
  },
  standard: {},
}));

export default useStyles;
