import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: ({ isPopulated }) => (isPopulated ? '4px' : '6px'),
    flexGrow: ({ fullWidth }) => (fullWidth ? 1 : 0),
  },
  outlined: {
    backgroundColor: ({ isPopulated }) =>
      isPopulated ? 'rgba(255, 152, 0, 0.1)' : theme.palette.dina.blackHoverOverlay,
    border: ({ isPopulated }) =>
      `0.5px solid ${theme.palette.dina[isPopulated ? 'statusWarning' : 'buttonBorderOutlined']}`,
    '&&:hover': {
      backgroundColor: ({ isPopulated }) =>
        isPopulated ? 'rgba(255, 152, 0, 0.15)' : theme.palette.dina.statusOnHover,
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  label: {
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '0 6px 0 12px',
    textOverflow: 'ellipsis',
    ...theme.typography.dina.listItemLabel,
    color: ({ isPopulated }) =>
      `${theme.palette.dina[isPopulated ? 'highEmphasis' : 'mediumEmphasis']}`,
  },
  icon: {
    marginRight: '-10px',
  },
  avatarPopulated: {
    '& path': {
      fill: theme.palette.dina.statusWarning,
      fillOpacity: 1,
    },
  },
  memberCount: {
    ...theme.typography.dina.caption,
    lineHeight: '14px',
    color: ({ isPopulated }) => `${theme.palette.dina[isPopulated ? 'statusWarning' : 'disabled']}`,
  },
  arrowIcon: {
    '& path': {
      fill: theme.palette.dina.highEmphasis,
      fillOpacity: 0.12,
    },
  },
}));

export default useStyles;
