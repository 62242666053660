import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import fieldEnums from 'utils/constants/fieldEnums';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import TextAreaComponent from 'components/textArea';
import { ReactComponent as Check } from 'assets/icons/systemicons/check.svg';
import { TextAreaWrapper, InputAdornment, ArrowUp, ArrowDown, ListBox, ListItem } from './styled';

const TextArea = memo(TextAreaComponent);

const AutoComplete = ({ value, readOnly, onUpdateStream, stream }) => {
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const field = getFieldsForBlock(fieldEnums.LIVE_STREAMS, { options: [] });
  const options = field?.options || [];

  const [inputValue, setInputValue] = useState(value);

  const {
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    getListboxProps,
    getOptionProps,
    popupOpen,
    groupedOptions,
  } = useAutocomplete({
    id: 'live-stream-box',
    options,
    getOptionLabel: (option) => option?.value || '',
    autoHighlight: true,
    inputValue,
    onInputChange: (_, val) => setInputValue(val),
    value: stream,
    onChange: (_, val) => onUpdateStream(val),
    getOptionSelected: (option) => option.id === stream?.id,
  });

  return (
    <>
      <TextAreaWrapper {...getRootProps()}>
        <TextArea
          inputProps={{ ...getInputProps() }}
          disabled={readOnly}
          endAdornment={
            <InputAdornment position="end" {...getPopupIndicatorProps()}>
              {popupOpen ? <ArrowUp /> : <ArrowDown />}
            </InputAdornment>
          }
          severity="regular"
          type="SoMe"
          rows={4}
          lineHeight="20px"
          label="Type the URL here or choose from the list..."
          optionalLabel="Live Stream URL"
        />
      </TextAreaWrapper>
      {groupedOptions.length > 0 ? (
        <ListBox {...getListboxProps()}>
          {groupedOptions.map((option, index) => {
            const optionProps = getOptionProps({ option, index });
            return (
              <ListItem {...optionProps}>
                {option.title}
                {optionProps['aria-selected'] && <Check />}
              </ListItem>
            );
          })}
        </ListBox>
      ) : (
        <ListBox>{popupOpen && <ListItem disabled>No Options</ListItem>}</ListBox>
      )}
    </>
  );
};

AutoComplete.propTypes = {
  /** value of auto complete input */
  value: PropTypes.string,
  /** boolean to disable input */
  readOnly: PropTypes.bool,
  /** callback to update autocomplete */
  onUpdateStream: PropTypes.func,
  /** selected stream of autocomplete */
  stream: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    title: PropTypes.string,
  }),
};

AutoComplete.defaultProps = {
  value: '',
  readOnly: false,
  onUpdateStream: () => {},
  stream: {},
};

export default memo(AutoComplete);
