import { useMutation } from '@apollo/client';
import UPDATE_GROUP_PERMISSIONS from 'graphql/mutations/updateGroupPermissions';
import { useState } from 'react';
import { useChangedRows } from '../atoms';

// Find unique resource names
const getResourceNames = (policies) => [...new Set(policies.map((policy) => policy.resourceName))];

const getPoliciesForPayload = (policies, resourceNames) =>
  resourceNames.map((resourceName) => {
    const permissions = policies.filter((policy) => policy.resourceName === resourceName);
    return {
      resourceName,
      permissions: permissions.map((permission) => ({
        action: permission.action,
        access: permission.access === true ? 'allow' : 'deny',
      })),
    };
  });

const createPolicies = (groupPermissions) => {
  const { policies } = groupPermissions;
  const resourceNames = getResourceNames(policies);
  return getPoliciesForPayload(policies, resourceNames);
};

const getchangedPermissionsInGroups = (changedRows, groupPoliciesDb) => {
  const groupRefIds = [...new Set(changedRows.map((changedRow) => changedRow.groupRefId))];
  return groupRefIds.map((groupRefId) => {
    const policies = changedRows.filter((changedRow) => changedRow.groupRefId === groupRefId);
    // Get the group which is updated with permssions
    const group = groupPoliciesDb.find((groupPolicy) => groupPolicy.mRefId === groupRefId);
    return { group, policies };
  });
};

const getPayload = (changedPermissionsInGroups) =>
  changedPermissionsInGroups.map((groupPermissions) => {
    const { group } = groupPermissions;
    const policies = createPolicies(groupPermissions);
    return {
      mId: group?.mId,
      mRefId: group?.mRefId,
      mTitle: group?.mTitle,
      mProperties: {
        __typename: 'GroupPolicy',
        policies,
      },
    };
  });

const getGroupPolicyPayload = (changedRows, groupPoliciesDb) => {
  const changedPermissionsInGroups = getchangedPermissionsInGroups(changedRows, groupPoliciesDb);
  return getPayload(changedPermissionsInGroups);
};

const useUpdateGroup = () => {
  const [changedRows] = useChangedRows();
  const [updateGroupPermissions] = useMutation(UPDATE_GROUP_PERMISSIONS);
  const [loading, setLoading] = useState(false);
  const updatePermissions = async (groupPolicies) => {
    // set access attribute of changedRows to access attributes of groupPolices in DB
    const payload = getGroupPolicyPayload(changedRows, groupPolicies);
    setLoading(true);
    const promise = await updateGroupPermissions({
      variables: {
        input: { payload },
      },
    });
    setLoading(false);
    return promise;
  };
  return [updatePermissions, loading];
};

export default useUpdateGroup;
