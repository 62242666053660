import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button } from '@material-ui/core';
import close from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as ChatEnabled } from 'assets/icons/systemicons/HeaderNavbar/chat_enabled.svg';
import useStyles from './header-styles';

const Header = ({ onClose, onNewMessageClick }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} dragHandler`}>
      <div className={classes.leftComponents}>
        <ChatEnabled className={classes.icon} />
        <div>Messages</div>
      </div>
      <div>
        <Button
          variant="outlined"
          onClick={onNewMessageClick}
          classes={{
            root: classes.buttonRoot,
            outlined: classes.buttonOutlined,
          }}
        >
          New Message
        </Button>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <img src={close} alt="close" className={classes.closeImage} />
        </IconButton>
      </div>
    </div>
  );
};

Header.propTypes = {
  /** callback to close the message hub */
  onClose: PropTypes.func,
  /** callback to open new message dialog */
  onNewMessageClick: PropTypes.func,
};

Header.defaultProps = {
  onClose: () => {},
  onNewMessageClick: () => {},
};

export default Header;
