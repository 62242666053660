import React from 'react';
import { auditFailure } from 'utils/sendErrorLogToBackend';

const ErrorBoundaryDetails = (props) => {
  const { client, timeStamp, isDevEnv, error, errorInfo } = props;

  const dinaVersion = process.env.REACT_APP_VERSION || 'v0.0.0';
  const dinaVersionDate = process.env.REACT_APP_VERSION_DATE || new Date().toISOString();

  if (!isDevEnv && error) {
    auditFailure(
      client,
      {
        message: error.message,
        code: error.code,
        stack: error.stack,
        origin: 'ux',
      },
      timeStamp,
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', color: 'rgba(255, 255, 255, 0.65)' }}>
      <div style={{ width: 600, position: 'relative' }}>
        <h3>
          Something went wrong.{' '}
          <span
            role="presentation"
            style={{ cursor: 'pointer', color: '#0F75DF' }}
            onClick={() => window.location.reload()}
          >
            Try to reload the page
          </span>{' '}
          <br />
          Or,{' '}
          <span
            role="presentation"
            style={{ cursor: 'pointer', color: '#0F75DF' }}
            onClick={async () => {
              await client.clearStore();
              window.location.reload();
            }}
          >
            Try a hard refresh
          </span>{' '}
        </h3>
        <div style={{ whiteSpace: 'no-wrap' }}>
          <div>DiNA Version: {dinaVersion}</div>
          <div>DiNA Version Date: {dinaVersionDate}</div>
          <div>Time of incident: {timeStamp.toString()}</div>
          <div>Time of incident (ISO): {timeStamp.toISOString()}</div>
          <div>Error.message: {error.toString()}</div>
          {error && error.code && <div>Error.code: {error.code}</div>}
        </div>

        {isDevEnv && error && (
          <details style={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }}>
            <>
              {error.toString()}
              <br />
              {errorInfo.componentStack}
            </>
          </details>
        )}
      </div>
    </div>
  );
};

export default ErrorBoundaryDetails;
