import styled from '@emotion/styled';

import theme from 'theme/theme';

import MaterialAvatar from '../material-avatar';

export const StyledPeopleAvatar = styled(MaterialAvatar)`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${theme.palette.dina.chipBackground};
  color: ${theme.palette.dina.mediumEmphasis};

  ${({ type }) =>
    type === 'thinBordered' &&
    `border: 0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`}

  &:hover {
    ${({ highlightOnHover }) =>
      highlightOnHover &&
      `
      position: absolute;
      width: 36px;
      height: 36px;
      transition: all 0.18s;
    `}
  }
`;

export const StyledCheck = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(25%, -25%, 0);
`;

export const StyledActivityStatus = styled('div')`
  position: absolute;
  bottom: ${({ height }) => height / 4};
  left: 0;
  transform: translate3d(-37.5%, 50%, 0);
`;
