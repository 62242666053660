import React, { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { elementTypes } from 'components/editor/constants/types';
import { removeBlock, updateBlock } from 'components/editor/utils';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import InputGroup from '../inputGroup';
import Box from '../box';
import Thumbnail from './components/thumbnail';
import DragAndDrop from '../dragAndDrop';
import { EmbeddedVideoIcon, InputGroupWrapper, ContentWrapper } from './styled';

const EmbeddedVideo = ({ attributes, children, element }) => {
  const { data } = element;
  const editor = useSlate();
  const { update } = useEditorContext();

  const { url, caption, collapsed } = data || {};

  const [onChangeCollapse] = useChangeCollapse(element);

  const handleUrlChange = useCallback(
    (newUrl) => {
      const updatedData = {
        ...data,
        url: newUrl,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const handleCaptionChange = useCallback(
    (newCaption) => {
      const updatedData = {
        ...data,
        caption: newCaption,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const handleClearUrl = useCallback(() => {
    handleUrlChange('');
  }, [handleUrlChange]);

  const handleClearCaption = useCallback(() => {
    handleCaptionChange('');
  }, [handleCaptionChange]);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderComponent = useMemo(() => {
    const urlInputProps = {
      disableLabel: false,
      onChange: handleUrlChange,
      value: url,
      placeholder: 'Type or paste the video URL here...',
      label: 'Type or paste the video URL here...',
      optionalLabel: 'Video URL',
      onClear: handleClearUrl,
    };

    const captionInputProps = {
      disableLabel: false,
      onChange: handleCaptionChange,
      value: caption,
      placeholder: 'Type video caption here...',
      label: 'Type video caption here...',
      optionalLabel: 'Video Caption',
      onClear: handleClearCaption,
    };

    const inputProps = [urlInputProps, captionInputProps];

    return (
      <Box
        iconComponent={<EmbeddedVideoIcon />}
        type="media"
        title="Embedded Video"
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed || false}
        collapsedContent={url || ''}
        hideEllipsisButton
        onMenuSelect={onMenuSelect}
      >
        <ContentWrapper>
          <Thumbnail src={url || ''} />
          <InputGroupWrapper>
            <InputGroup inputProps={inputProps} />
          </InputGroupWrapper>
        </ContentWrapper>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caption,
    collapsed,
    handleCaptionChange,
    handleClearCaption,
    handleClearUrl,
    handleUrlChange,
    onMenuSelect,
    onChangeCollapse,
    url,
  ]);

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderComponent}
      </DragAndDrop>
    </div>
  );
};

EmbeddedVideo.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

EmbeddedVideo.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.EMBEDDED_VIDEO,
  },
};

export default memo(EmbeddedVideo);
