import React, { memo, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import { Transforms } from 'slate';
import { useSelected, useSlate, ReactEditor } from 'slate-react';

import fieldEnums from 'utils/constants/fieldEnums';
import defaultThumbnail from 'assets/images/default/defaultThumbnail.png';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import Dialog from 'components/dialog';
import CreateNew from 'components/createNew';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import SelectedElement from 'components/editor/components/selectedElement';
import { actionTypes, elementTypes } from 'components/editor/constants/types';
import {
  checkIfDragDisabled,
  generateName,
  removeBlock,
  updateBlock,
} from 'components/editor/utils';
import variants from 'utils/instance/variants';
import generatePlaceholderProps from 'utils/generatePlaceholderProps';
import notifyChange from 'components/editor/utils/notifyChange';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useStorageImage from 'hooks/useStorageImage';
import { getThumbnailKey } from 'utils/mediaKey';
import useFileUpload from 'hooks/useFileUpload';
import MediaCard from 'components/mediaCard';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';

import VideoBase from '../videoBase';
import MediaViewer from '../mediaViewer';
import MediaDropZone from './mediaDropZone';
import DragAndDrop from '../dragAndDrop';

const { select, setNodes } = Transforms;
const maxThumbnails = 4;

const mimeTypes = ['video/mp4'];

const Video = ({ attributes, children, element }) => {
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const fileRef = useRef();
  const editor = useSlate();
  const { containerRef, getPlaceholderConfig, update, isAllowed, variant, withSignedUrl } =
    useEditorContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [asset, setAsset] = useState(null);

  const field = getFieldsForBlock(fieldEnums.CUSTOM_SOURCES, { options: [] });
  const sourceOptions = field?.options || [];
  /** Video data fetch */
  const { data } = element;

  const [onChangeCollapse] = useChangeCollapse(element);

  const placeholderConfigs = (dialogOpen && getPlaceholderConfig()) || {};

  const {
    src,
    showThumbnail,
    thumbnails,
    subtitles,
    cache,
    thumbnailUrl,
    proxy,
    itemDuration,
    metadata,
    collapsed = false,
    mId,
    mRefId,
  } = data;

  const isSelected = useSelected(element);
  const showHighlight = isSelected;
  const {
    showMetadata,
    description,
    title,
    source,
    isCoverphoto = false,
    editor: editors,
  } = metadata || {};

  const [localThumbnails, setLocalThumbnails] = useState(thumbnails || []);

  const cacheRef = useRef(cache || null);

  useEffect(() => {
    if (!proxy) return;

    cacheRef.current = proxy;
  }, [proxy]);

  const forceEditorFocus = useCallback(() => {
    ReactEditor.focus(editor);
  }, [editor]);

  const key = mId && mRefId && getThumbnailKey(mId, mRefId);
  const skipVideoLoad = !!cacheRef.current || !src;
  // direct uploaded video
  const { url: signedUrl, loading } = useGetSignedUrl(src, skipVideoLoad);
  const loadThumbnail = !src || key;
  const { data: thumbnailData } = useStorageImage(key, !loadThumbnail);

  const videoSrc = (src && signedUrl) || cacheRef.current || thumbnailData;

  const removeCacheFromSlateData = useCallback(() => {
    if (cache) {
      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { data: { ...element.data, cache: undefined } }, { at: path });
    }
  }, [cache, editor, element]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(removeCacheFromSlateData, []);

  /** dialog */
  const selectionCacheRef = useRef(editor.selection);

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const addThumbnails = useCallback(
    async (newThumbnails, updatedLocalThumbnails) => {
      if (Array.isArray(newThumbnails)) {
        setLocalThumbnails(updatedLocalThumbnails);
        const thumbs = thumbnails || [];
        const updatedData = {
          ...data,
          thumbnails: [...thumbs, ...newThumbnails],
        };
        updateBlock(editor, element, updatedData, update);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, element, thumbnails],
  );

  const onFileUpload = useCallback(
    async (file, fileUrl) => {
      const fileName = generateName(file.type);
      cacheRef.current = fileUrl;
      if (withSignedUrl) fileRef.current = file;

      let updatedData = withSignedUrl
        ? {
            ...element.data,
            title: fileName,
          }
        : {};

      if (!withSignedUrl) {
        const result = await update({
          type: actionTypes.ASSET_INSERT,
          payload: { document: editor.children, file, fileName },
        });

        updatedData = {
          ...element.data,
          ...result,
          title: fileName,
        };
      }
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [withSignedUrl, element],
  );

  const updateEditorOnUpload = useCallback(
    (result) => {
      const updatedData = {
        ...element.data,
        ...result,
      };
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    },
    [element, editor],
  );

  const uploadVideo = useFileUpload(mimeTypes, onFileUpload);

  const uploadSubtitle = useCallback(
    async (file) => {
      const result = await update({
        type: actionTypes.ASSET_INSERT,
        payload: { document: editor.children, file },
      });

      const existingSubtitles = subtitles || [];
      const updatedData = {
        ...element.data,
        subtitles: [...existingSubtitles, { ...result, title: file.name }],
      };

      updateBlock(editor, element, updatedData, update);
    },
    [editor, element, subtitles, update],
  );

  const updateSubtitles = useCallback(
    (updatedSubtitles) => {
      const updatedData = {
        ...element.data,
        subtitles: updatedSubtitles,
      };
      updateBlock(editor, element, updatedData, update);
    },
    [editor, element, update],
  );

  const handleOpenDialog = useCallback(
    (event) => {
      event.preventDefault();

      selectionCacheRef.current = editor.selection;
      openDialog();
    },
    [openDialog, editor],
  );

  const handleCreatePlaceholder = useCallback(
    async (mTitle) => {
      closeDialog();

      try {
        if (selectionCacheRef.current) select(editor, selectionCacheRef.current);

        const result = await update({
          type: actionTypes.CREATE_PLACEHOLDER,
          payload: { document: editor.children, title: mTitle, itemType: 'video' },
        });

        const updatedData = {
          ...result,
          showThumbnail,
          thumbnails: thumbnails || [],
          mTitle,
          itemDuration: 0,
          itemType: 'video',
          mediaType: 'video/placeholder',
          itemId: uuidv1(),
          metadata: metadata || {},
          subtitles: subtitles || [],
        };

        const path = ReactEditor.findPath(editor, element);
        setNodes(editor, { data: updatedData, type: elementTypes.PLACEHOLDER }, { at: path });
        notifyChange(editor, update);
      } catch (error) {
        // logger.log(error);
      }
    },
    [closeDialog, editor, element, metadata, showThumbnail, subtitles, thumbnails, update],
  );

  const handleCancel = useCallback(() => {
    if (selectionCacheRef.current) select(editor, selectionCacheRef.current);
    closeDialog();
  }, [closeDialog, editor]);

  /** Remove Video function to be implemented */
  const removeVideo = useCallback(
    (event) => {
      event.preventDefault();
      const updatedData = {
        showThumbnail: data.showThumbnail,
      };
      if (data.showThumbnail) updatedData.thumbnails = thumbnails || [];

      updateBlock(editor, element, updatedData, update);
      cacheRef.current = null;
      notifyChange(editor, update);
    },
    [data.showThumbnail, editor, element, thumbnails, update],
  );

  const removeThumbnail = useCallback(
    (newThumbnails, newLocalThumbnails) => {
      const updatedData = {
        ...data,
        thumbnails: newThumbnails,
      };
      setLocalThumbnails(newLocalThumbnails);

      updateBlock(editor, element, updatedData, update);
    },
    [data, editor, element, update],
  );

  const updateMetadata = useCallback(
    (newValue, metaPropName) => {
      const oldMetaData = metadata || {};
      const updatedData = {
        ...data,
        metadata: {
          ...oldMetaData,
          [metaPropName]: newValue,
        },
      };
      const allAttributes =
        metaPropName === 'isCoverphoto' && newValue === true
          ? { key: 'isCoverphoto', value: false }
          : null;
      updateBlock(editor, element, updatedData, update, undefined, allAttributes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, element, metadata],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
      if (action === 'show-metadata') updateMetadata(!showMetadata, 'showMetadata');
    },
    [editor, element, showMetadata, update, updateMetadata],
  );

  const titleProps = useMemo(
    () => ({
      value: title || '',
      placeholder: 'Add Title',
      description: 'Add a Title to the video',
      propName: 'title',
    }),
    [title],
  );

  const sourceProps = useMemo(
    () => ({
      value: source || [],
      placeholder: 'Start typing to find Source',
      options: sourceOptions,
      propName: 'source',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source],
  );

  const inputProps = useMemo(
    () => ({
      value: editors || '',
      placeholder: 'Add Editor...',
      description: 'Who is the editor?',
      propName: 'editor',
    }),
    [editors],
  );

  const textareaProps = useMemo(
    () => ({
      value: description || '',
      placeholder: 'Add Description...',
      description: 'Describe the video',
      propName: 'description',
    }),
    [description],
  );

  const renderContentWithoutBlock = useMemo(
    () => (
      <SelectedElement {...{ element }}>
        <div {...attributes}>
          {children}
          <div style={{ padding: '8px' }} contentEditable={false}>
            <MediaViewer
              handleClose={() => setAsset(null)}
              open={!!asset}
              asset={asset}
              accessToken=""
            />
            <MediaCard
              showHighlight={showHighlight}
              key={videoSrc}
              onFocus={forceEditorFocus}
              onAssetSet={(e) => {
                e.stopPropagation();
                setAsset({
                  type: elementTypes.VIDEO,
                  filename: 'Video File',
                  previewUri: src && loading ? defaultThumbnail : videoSrc,
                  mId: !src ? mId : null,
                  mRefId: !src ? mRefId : null,
                });
              }}
              src={loading ? defaultThumbnail : videoSrc}
              type={elementTypes.VIDEO}
              filename="Video File"
              byline="Media Details"
            />
          </div>
        </div>
      </SelectedElement>
    ),
    [
      asset,
      attributes,
      children,
      element,
      forceEditorFocus,
      loading,
      mId,
      mRefId,
      showHighlight,
      src,
      videoSrc,
    ],
  );

  if (!isAllowed) return renderContentWithoutBlock;

  const isYoutubeVariant = variant === variants.YOUTUBE;
  const isCmsVariant = variant === variants.CMS;
  const isSubtitleAllowed = variant !== variants.INSTAGRAM && variant !== variants.TWITTER;
  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDisabled={checkIfDragDisabled(variant)}>
        <MediaDropZone
          {...{ element, variant }}
          canAddThumbnail={!thumbnails || thumbnails.length < maxThumbnails}
          mergeNewThumbnail={(newThumbnail) => {
            if (isYoutubeVariant) setLocalThumbnails([newThumbnail]);
            else setLocalThumbnails([...localThumbnails, newThumbnail]);
          }}
        >
          {children}
          <VideoBase
            createPlaceholder={handleOpenDialog}
            hideEllipsisButton={isYoutubeVariant}
            mediaThumbnail={thumbnailData || thumbnailUrl}
            mediaDuration={itemDuration}
            thumbnails={localThumbnails}
            collapsed={collapsed}
            collapsedContent={src ? title || 'No title available' : ''}
            updateCollapsed={onChangeCollapse}
            onMenuSelect={onMenuSelect}
            removeVideo={removeVideo}
            subtitles={subtitles}
            {...{
              videoSrc,
              uploadVideo,
              addThumbnails,
              removeThumbnail,
              showThumbnail,
              maxThumbnails,
              showMetadata,
              sourceProps,
              textareaProps,
              titleProps,
              inputProps,
              element,
              isYoutubeVariant,
              isCmsVariant,
              updateMetadata,
              updateSubtitles,
              uploadSubtitle,
              isCoverphoto,
              mId,
              mRefId,
              isSubtitleAllowed,
              cacheRef,
              fileRef,
              updateEditorOnUpload,
              withSignedUrl,
            }}
          />
        </MediaDropZone>
      </DragAndDrop>
      {dialogOpen && (
        <Dialog container={containerRef.current} open={dialogOpen} onClose={handleCancel}>
          <CreateNew
            variant="placeholder"
            onCreate={handleCreatePlaceholder}
            onCancel={handleCancel}
            placeholderNameConfigs={generatePlaceholderProps(placeholderConfigs)}
          />
        </Dialog>
      )}
    </div>
  );
};

Video.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Video.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.VIDEO,
    data: { src: '', showThumbnail: true },
    children: [],
  },
};

export default memo(Video);
