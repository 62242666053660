import React from 'react';
import AES from 'crypto-js/aes';
import QRCode from 'qrcode.react';
import { Typography } from '@material-ui/core';

import colors from 'theme/colors';
import useStyles from './mobileConfig-styles';

const MobileConfig = () => {
  const classes = useStyles();
  const data = {
    p1: process.env.TENANT_NAME || 'Dev',
    p2: process.env.REACT_APP_S3_BUCKET_NAME || '',
    p3: process.env.REACT_APP_AWS_APPSYNC_REGION || '',
    p4: process.env.REACT_APP_API_ENDPOINT || '',
    p5: process.env.REACT_APP_AWS_AUTH_REGION || '',
    p6: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID || '',
    p7: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID || '',
    p8: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || '',
    p9: process.env.REACT_APP_USE_HOSTED_UI || '',
    p10: process.env.REACT_APP_AUTHENICATION_FLOW || '',
    p11: process.env.REACT_APP_AWS_COGNITO_DOMAIN || '',
    p12: process.env.REACT_APP_SIGN_IN_OUT_URL || '',
    p13: process.env.REACT_APP_AUTH_AUDIT || '',
  };
  const EncryptedData = AES.encrypt(JSON.stringify(data), 'P6BQlB458tVhzFFbBrwxVR8v').toString();

  return (
    <div className={classes.root}>
      <Typography className={classes.qrTitle}>Scan this QR code to get started</Typography>
      <Typography className={classes.qrDescription}>
        To automatically set up configuration and log in to DiNA Mobile with your personal account,
        scan the code below with the DiNA Mobile app.
      </Typography>
      <QRCode
        value={EncryptedData}
        size={328}
        bgColor={colors.surfaceAppBackgroundNavLevel2}
        fgColor={colors.highEmphasis}
      />
    </div>
  );
};

export default MobileConfig;
