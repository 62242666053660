import { memo } from 'react';
import theme from 'theme/theme';
import { IconButton as MaterialIconButton } from '@material-ui/core';
import styled from '@emotion/styled';

const MemoisedIconButton = memo(MaterialIconButton);

export const ThumbnailWrapper = styled('div')`
  position: relative;
  margin-right: 8px;
  height: 58px;
`;

export const Image = styled('img')`
  width: 58px;
  height: 58px;
  border-radius: 5px;
  object-fit: cover;
`;

export const IconButton = styled(MemoisedIconButton)`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 4px;
  right: 4px;
  background-color: ${theme.palette.dina.blackHoverOverlay};
  :hover {
    background-color: ${theme.palette.dina.blackHoverOverlay};
  }
`;

export const CloseIcon = styled('img')`
  width: 10px;
  height: 10px;
`;
