import styled from '@emotion/styled';

import theme from 'theme/theme';

import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

export const ScratchPadIconWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;

  svg {
    width: 24px;
    height: 32px;
  }

  &:hover {
    svg {
      path {
        fill: ${theme.palette.dina.iconActive};
        fill-opacity: 1;
      }
    }
  }
`;

export const NotificationIndicatorWrapper = styled.div`
  position: absolute;
  left: 14px;
  bottom: 13px;
  pointer-events: none;
`;

export const ScratchpadWrapper = styled.div`
  position: absolute;
  top: 35px;
  right: 140px;
`;

export const ScratchpadInnerRootWrapper = styled('div')`
  width: 572px;
  height: 440px;
  border-radius: 8px;
  backdrop-filter: blur(30px);
  background-color: ${theme.palette.dina.surfaceCard};
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 12px 24px ${theme.palette.dina.boxShadowDark},
    0px 0px 1px ${theme.palette.dina.boxShadowDark};
`;

export const ScratchpadInnerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledTypography = styled(Typography)`
  ${theme.typography.dina.h5};
  line-height: 28px;
  letter-spacing: normal;
  height: 64px;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

export const StyledDivider = styled(Divider)`
  height: 1px;
  background-color: ${theme.palette.dina.dividerLight};
`;

export const StyledCardContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  margin: 8px;
  overflow: auto;
`;

export const StyledCard = styled('div')`
  margin: 8px;
  height: 133px;
`;
