import styled from '@emotion/styled';

export const InputWrapper = styled('div')`
  margin-bottom: 16px;
`;

export const TextAreaWrapper = styled('div')`
  margin-top: 8px;
  margin-bottom: 16px;
`;
