import uuidv1 from 'uuid/v1';
import { ReactEditor } from 'slate-react';

const stopDefaultPasteOperation = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const updateItemIds = (nodes) =>
  nodes.map((node) => {
    if (!node?.data?.itemId) return node;
    return { ...node, data: { ...node.data, itemId: uuidv1() } };
  });

const getUpdatedDataTransfer = (updatedNodes) => {
  const stringifiedNodes = JSON.stringify(updatedNodes);
  const encoded = window.btoa(encodeURIComponent(stringifiedNodes));

  const newEvent = {
    ...new Event('paste', { bubbles: true, cancelable: true }),
    ...{
      clipboardData: {
        getData: (type) => (type === 'application/x-slate-fragment' ? encoded : null),
        types: ['application/x-slate-fragment'],
      },
    },
  };

  return newEvent.clipboardData;
};

/**
 *
 * Paste operation is handled when itemId of automation items needs to be changed
 * Otherwise slate handles it for us
 *
 */

const onPaste = (event, editor) => {
  const clipboardData = event.clipboardData.getData('application/x-slate-fragment');
  if (!clipboardData) return;

  const decoded = decodeURIComponent(window.atob(clipboardData));
  if (decoded) {
    const parsedData = JSON.parse(decoded);
    const shouldChangeItemId = parsedData.find((node) => node?.data?.itemId);

    if (shouldChangeItemId) {
      stopDefaultPasteOperation(event);

      const updatedNodes = updateItemIds(parsedData);

      const updatedDataTransfer = getUpdatedDataTransfer(updatedNodes);

      ReactEditor.insertData(editor, updatedDataTransfer);
    }
  }
};

export default onPaste;
