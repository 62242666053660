import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import useStyles from './tabs-style';

const TabsView = ({ tabs, onTabChange, tabIndex, tabsHeight, ...rest }) => {
  const classes = useStyles({ tabLength: tabs.length, tabsHeight });
  const [index, setIndex] = useState(tabIndex);

  useEffect(() => {
    setIndex(tabIndex);
  }, [tabIndex]);

  const onChange = (event, newIndex) => {
    event.preventDefault();
    setIndex(newIndex);
    onTabChange(tabs[newIndex], newIndex);
  };
  return (
    <Tabs
      classes={{
        root: classes.root,
        indicator: classes.indicator,
        flexContainer: classes.flexContainer,
      }}
      value={index}
      {...{ onChange }}
      {...rest}
    >
      {tabs.map((tab) => (
        <Tab
          label={tab.label}
          key={tab.label}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
          disabled={tab.disabled}
        />
      ))}
    </Tabs>
  );
};

TabsView.propTypes = {
  /** Items to be shown on the tabs */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ).isRequired,
  /** Callback to be invoked on tab change,
   * with the selected tabItem passed in
   */
  onTabChange: PropTypes.func,
  /** Initial tab to be selected */
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  tabsHeight: PropTypes.number,
};

TabsView.defaultProps = {
  onTabChange: () => {},
  tabIndex: 0,
  tabsHeight: 32,
};

export default TabsView;
