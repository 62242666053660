import React from 'react';
import PropTypes from 'prop-types';
import InProgressSrc from 'assets/icons/systemicons/status/inProgress.svg';
import InReviewSrc from 'assets/icons/systemicons/status/inReview.svg';
import PublishedSrc from 'assets/icons/systemicons/status/published.svg';
import ReadySrc from 'assets/icons/systemicons/status/ready.svg';
import TodoSrc from 'assets/icons/systemicons/status/toDo.svg';
import ErrorSrc from 'assets/icons/systemicons/status/error.svg';
import PendingSrc from 'assets/icons/systemicons/status/pending.svg';
import ExpiredSrc from 'assets/icons/systemicons/status/expired.svg';

const icons = {
  inProgress: InProgressSrc,
  inReview: InReviewSrc,
  published: PublishedSrc,
  ready: ReadySrc,
  todo: TodoSrc,
  error: ErrorSrc,
  pending: PendingSrc,
  expired: ExpiredSrc,
};

const IconView = ({ status }) => <img src={icons[status]} alt="status" />;

IconView.propTypes = {
  /** Status for the icon */
  status: PropTypes.string,
};

IconView.defaultProps = {
  status: 'todo',
};

export default IconView;
