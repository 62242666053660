import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    width: '351px',
    minHeight: '79px',
    maxHeight: '50vh',
    backgroundColor: theme.palette.dina.surfaceCard,
    borderRadius: '8px',
    boxShadow: `0px 0px 4px ${theme.palette.dina.boxShadowDark},
      0px 4px 4px ${theme.palette.dina.boxShadowDark}`,
    display: 'flex',
    boxSizing: 'border-box',
    border: `0.5px solid ${theme.palette.dina.borderResting}`,
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  iconContainer: {
    width: '56px',
    maxWidth: '56px',
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    minHeight: '79px',
  },
  iconComponent: {
    position: 'absolute',
    right: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  detailContainer: {
    width: '207px',
    minHeight: '79px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  timeInfo: {
    ...theme.typography.dina.caption,
    color: theme.palette.dina.highEmphasis,
    margin: '8px 8px 0 8px',
  },
  alertTitle: {
    ...theme.typography.dina.listItemLabelMedium,
    margin: '4px 8px 0 8px',
  },
  buttonContainer: {
    flexGrow: 1,
    width: '87px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '79px',
  },
  divider: {
    width: '100%',
  },
  buttonRoot: {
    height: '100%',
    width: '100%',
    flexGrow: '1',
    ...theme.typography.dina.label,
    color: theme.palette.dina.mediumEmphasis,
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
}));

export default useStyles;
