/* eslint-disable max-len */
import React, { createContext, useState, useEffect } from 'react';
import BREAKING_FEED from 'graphql/subscriptions/notifyBreakingFeeds';
import useApolloSubscription from 'hooks/useApolloSubscription';

export const BreakingFeedContext = createContext([]);

const BreakingFeedProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(0);
  const [items, setItems] = useState([]);

  const onBreakingFeedData = ({ _client, subscriptionData }) => {
    setNotifications((oldNotifications) => oldNotifications + 1);
    setItems((oldItems) => [subscriptionData.data.notifyBreakingFeedSubscription, ...oldItems]);
  };

  const [subscribe, unsubscribe] = useApolloSubscription(BREAKING_FEED, {
    variables: {},
    onSubscriptionData: onBreakingFeedData,
  });

  const clearNotificationCount = () => {
    setNotifications(0);
  };

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BreakingFeedContext.Provider value={[notifications, clearNotificationCount, items]}>
      {children}
    </BreakingFeedContext.Provider>
  );
};
export default BreakingFeedProvider;
