import Storage from '@aws-amplify/storage';

const uploadToS3 = async (key, file) =>
  Storage.put(key, file, {
    customPrefix: {
      public: '',
    },

    contentType: file.type,
  });

const getItems = async (key) =>
  Storage.list(key, {
    customPrefix: {
      public: '',
    },
  });

const getSignedUrl = async (key, checkExistence) => {
  if (checkExistence) {
    const items = await getItems(key);
    if (!items.length) {
      return null;
    }
  }

  return Storage.get(key, {
    customPrefix: {
      public: '',
    },
  });
};

const getMediaKey = (id, file, fileName) => `${id}/media/${fileName || file.name}`;

const deleteFromS3 = async (key) =>
  Storage.remove(key, {
    customPrefix: {
      public: '',
    },
  });

export { uploadToS3, getMediaKey, getItems, getSignedUrl, deleteFromS3 };
