import React, { useContext } from 'react';
import UserCtx from 'contexts/UserContext';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { useSidebarDatePickerAtom } from 'store/sidebar';
import memberTypes from 'graphql/memberTypes';
import useGetMembersOf from 'hooks/useGetMembersOf';
import Stories from './stories-view';

const StoriesContainer = ({
  selectionType,
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  assignedMembers,
  searchString,
}) => {
  const user = useContext(UserCtx);
  const [selectedDates, setSelectedDates] = useSidebarDatePickerAtom();
  const { mId } = user;

  const onDateChanged = (e) => {
    setSelectedDates(e);
  };

  const [data, error, loading] = useGetMembersOf(mId, selectionType);

  if (loading) return <LoadingIndicator />;
  if (error) return `Error! ${error.message}`;

  // eslint-disable-next-line consistent-return
  const groupsFromSelectionType = (type) => {
    if (data?.getMembersOf && type !== memberTypes.USER_BOOKMARK) {
      return data.getMembersOf;
    }
    if (type === memberTypes.USER_BOOKMARK) {
      return [{ mTitle: 'Bookmarks', mId, mType: type }];
    }
    if (type === memberTypes.STORY) {
      return [{ mTitle: 'Stories', mId, mType: type }];
    }
    if (type === memberTypes.PITCH) {
      return [{ mTitle: 'Pitches', mId, mType: type }];
    }
  };

  const groups = groupsFromSelectionType(selectionType);

  return (
    <Stories
      onDateChanged={onDateChanged}
      groups={groups ? groups.filter((e) => e) : []}
      selectionType={selectionType}
      selectedDates={selectedDates}
      filterModel={filterModel}
      updateFilterModel={updateFilterModel}
      history={history}
      updateHistory={updateHistory}
      currentSearch={currentSearch}
      updateCurrentSearch={updateCurrentSearch}
      assignedMembers={assignedMembers}
      searchString={searchString}
    />
  );
};

export default StoriesContainer;
