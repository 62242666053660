/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';
import getInitials from 'utils/getInitials';
import { Typography } from '@material-ui/core';
import useStyles from './users-styles';

const isEqualToNum = (index) => (num) => index === num;

const Users = (props) => {
  const { data, size, onClick, numOfUsers, ...rest } = props;
  const quantity = data.length;
  const displacement = 4;
  const minNumOfUsers = numOfUsers - 1;

  const styleHelper = (number, avatarWidth, shift) =>
    number > minNumOfUsers
      ? numOfUsers * avatarWidth - 3 * shift
      : number * avatarWidth - (number - 1) * shift;

  const isEqualTo = isEqualToNum(minNumOfUsers);

  const getTitle = (index, title) => {
    if (!isEqualTo(index)) return title;
    const count = quantity - index - 1;
    const more = `${count > 1 ? `${count} others` : `1 other`}`;

    return count > 0 ? `${title} and ${more}` : title;
  };

  const containerWidth = styleHelper(quantity, size, displacement);
  const classes = useStyles(containerWidth)();
  return (
    <div className={classes.container}>
      {data.slice(0, numOfUsers).map((avatarData, index) => (
        <Avatar
          key={index}
          className={classes[`avatar${index}`]}
          src={index < minNumOfUsers ? avatarData.mAvatarUrl : null}
          type={isEqualTo(index) ? 'thinBordered' : 'bordered'}
          size={size}
          title={getTitle(index, avatarData.mTitle)}
          onClick={onClick}
          {...rest}
        >
          {avatarData.mTitle && !isEqualTo(index) && (
            <Typography classes={{ root: classes.avatarText }}>
              {getInitials(avatarData.mTitle)}
            </Typography>
          )}
          {isEqualTo(index) && (
            <Typography classes={{ root: classes.avatarText }} data-testid="overflow">
              {`+${quantity - index}`}
            </Typography>
          )}
        </Avatar>
      ))}
    </div>
  );
};

Users.propTypes = {
  /** size of the avatar */
  size: PropTypes.number,
  /** Callback function to be invoked when overflow avatar is clicked */
  onClick: PropTypes.func,
  /** Contains the information about the avatars */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** number of avatars to show on the view */
  numOfUsers: PropTypes.number,
};
Users.defaultProps = {
  size: 48,
  onClick: () => {},
  data: [],
  numOfUsers: 4,
};

export default Users;
