import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: '172px',
    borderBottom: ({ height }) =>
      height === 0 ? null : `1px solid ${theme.palette.dina.dividerLight}`,
  },
  groupHeader: {
    height: '40px',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.typography.dina.overline,
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    cursor: 'pointer',
  },
  list: {
    height: ({ height }) => height,
    transition: 'height 250ms ease-in-out',
    transform: 'translateZ(0)',
  },
  container: {
    '& > :last-child': {
      marginBottom: '8px',
    },
  },
}));

export default useStyles;
