import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import { RootWrapper, TabsWrapper, Tabs } from './group-styled';
import GroupPermissions from './groupPermissions/groupPermissions-view';
import GroupHeader from './groupHeader/groupHeader-view';
import GroupMembers from './groupMembers/groupMembers-view';
import { useSelectedLeftMenu } from '../../../atoms';

const GroupTabs = memo(Tabs);

const Group = (props) => {
  const { groupPolicies } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedLeftMenu] = useSelectedLeftMenu();

  const tabs = [{ label: 'Group permissions' }, { label: 'Group members' }];

  const handleTabChange = (_, index) => {
    setTabIndex(index);
  };

  const groupPolicy = groupPolicies.find((policy) => policy.mRefId === selectedLeftMenu);

  const viewHelper = () => {
    switch (tabIndex) {
      case 0:
        return <GroupPermissions groupPolicy={groupPolicy} />;
      case 1:
        return <GroupMembers groupPolicy={groupPolicy} />;
      default:
        return <div />;
    }
  };

  return (
    <RootWrapper>
      <GroupHeader groupPolicy={groupPolicy} />
      <TabsWrapper>
        <GroupTabs tabs={tabs} onTabChange={handleTabChange} tabIndex={tabIndex} tabsHeight={36} />
      </TabsWrapper>
      <Divider />
      {viewHelper()}
    </RootWrapper>
  );
};

Group.propTypes = {
  groupPolicies: PropTypes.array,
};

Group.defaultProps = {
  groupPolicies: [],
};

export default Group;
