import React, { memo, useCallback, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import generateName from 'components/editor/utils/generateName';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { checkIfDragDisabled, removeBlock, updateBlock } from 'components/editor/utils';
import { menuOptions } from 'components/editor/constants';
import actionTypes from 'components/editor/constants/types/actionTypes';
import useImageUpload from 'hooks/useImageUpload';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useStorageImage from 'hooks/useStorageImage';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import ActionButton from '../addMedia/components/button';
import Box from '../box';
import { AddIcon, GifIcon, ContentWrapper, GifWrapper, Image, AddButton } from './styled';
import DragAndDrop from '../dragAndDrop';

const imageTypes = ['gif'];

const { setNodes } = Transforms;

const Gif = ({ attributes, children, element, readOnly }) => {
  const [buttonType, setButtonType] = useState('default');

  const editor = useSlate();
  const { update, variant } = useEditorContext();
  const { data } = element;
  const { src, collapsed = false, title } = data;
  const cacheRef = useRef(null);
  const { data: imageData } = useStorageImage(src);
  const gifSrc = (src && imageData) || cacheRef.current;

  const [onChangeCollapse] = useChangeCollapse(element);

  const onImageLoad = useCallback(
    async (file, fileUrl) => {
      const fileName = generateName(file.type);
      cacheRef.current = fileUrl;

      const result = await update({
        type: actionTypes.ASSET_INSERT,
        payload: { document: editor.children, file, fileName },
      });

      const updatedData = {
        ...element.data,
        ...result,
        title: fileName,
      };

      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const uploadGif = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
  });

  const onAddGIFClick = useCallback((event) => {
    event.preventDefault();
    uploadGif();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const removeGif = useCallback(
    (event) => {
      event.preventDefault();
      updateBlock(editor, element, {}, update);
      cacheRef.current = null;
      setButtonType('default');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleAction = useCallback(
    (event) => {
      if (buttonType === 'default') setButtonType('confirm');
      else removeGif(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonType],
  );

  const handleClickAway = useCallback(
    (event) => {
      if (buttonType === 'confirm') setButtonType('default');
    },
    [buttonType],
  );

  const renderContent = useMemo(
    () => (
      <Box
        iconComponent={<GifIcon />}
        title="Gif"
        readOnly={readOnly}
        menuItems={menuOptions}
        onMenuSelect={onMenuSelect}
        type="media"
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed}
        collapsedContent={title}
      >
        <ContentWrapper>
          {gifSrc ? (
            <GifWrapper>
              <Image src={gifSrc} alt="gif" />
              <ActionButton
                onClickAway={handleClickAway}
                type={buttonType}
                icon={<CloseIcon />}
                label="Remove Gif"
                onClick={handleAction}
              />
            </GifWrapper>
          ) : (
            <AddButton type="dashedOutlined" onClick={onAddGIFClick} disabled={readOnly}>
              <AddIcon />
            </AddButton>
          )}
        </ContentWrapper>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      buttonType,
      collapsed,
      gifSrc,
      handleAction,
      handleClickAway,
      onAddGIFClick,
      onMenuSelect,
      readOnly,
      title,
      onChangeCollapse,
    ],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDisabled={checkIfDragDisabled(variant)}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
};

Gif.propTypes = {
  /** Boolean that stops image upload */
  readOnly: PropTypes.bool,
};

Gif.defaultProps = {
  readOnly: false,
};

export default memo(Gif);
