import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '87vh',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
  },
  header: {},
  body: {
    height: '70%',
    overflow: 'auto',
  },

  divider: {
    backgroundColor: theme.palette.dina.dividerLight,
    width: 'calc(100% - 16px)',
    marginLeft: '16px',
  },
  additionalInfo: {
    ...theme.typography.dina.caption,
    marginTop: '6px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  bottomDivider: {
    marginTop: '20px',
    backgroundColor: theme.palette.dina.dividerLight,
    width: '100%',
  },
}));

export default useStyles;
