import { Editor, Element as SlateElement } from 'slate';

/**
 * Determines if specified element type is active or not
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type for the node to check for
 * @returns {Boolean}
 */

const isElementActive = (editor, type) => {
  const { selection } = editor;

  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
    }),
  );

  return Boolean(match);
};

export default isElementActive;
