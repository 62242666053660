import { MenuItem as MaterialMenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styled from '@emotion/styled/macro';
import colors from 'theme/colors';
import transientOptions from 'theme/helpers';
import { caption } from 'theme/styledTypography';

export const OptionsWrapper = styled('div')`
  ${caption}
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
  width: 64px;
  z-index: 999;
`;

export const DeleteImage = styled('img')`
  display: none;
`;

export const IconWrapper = styled('div')`
  width: 24px;
  height: 24px;
  margin-right: 20px;
  padding: 0;
`;

export const MenuItem = styled(MaterialMenuItem, transientOptions)`
  max-width: 400px;
  height: 40px;
  opacity: ${(props) => (props.$isMuted ? 0.5 : 1)};
  :hover {
    background-color: ${colors.wh};
    ${DeleteImage} {
      display: flex;
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  primaryText: {
    ...theme.typography.dina.listItemLabel,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
