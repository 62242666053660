import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { useAuthContext } from 'contexts/AuthContext';
import GET_POLICY from 'graphql/queries/getPolicy';
import getInitials from 'utils/getInitials';
import { avatarVariants } from 'components/avatar/Avatar';

import AppMenu from 'components/appMenu';
import Popover from 'components/popover/Popover';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Avatar from 'components/avatar';
import Dialog from 'components/dialog';

import Settings from './settings-view';

import { ProfileWrapper, SettingsWrapper } from './settings-styled';
import { useChangedRows, useSelectedLeftMenu, useSelectedGroup } from './atoms';

const SettingsContainer = ({ avatar, username, userId }) => {
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [settingOpen, setSettingOpen] = useState(false);
  const [, setChangedRows] = useChangedRows();
  const [, setSelectedLeftMenu] = useSelectedLeftMenu();
  const [, setSelectedGroup] = useSelectedGroup();
  const { logout } = useAuthContext();
  const { data, error, loading } = useQuery(GET_POLICY, {
    variables: {
      input: {
        mId: 'groupPolicy',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { getMemberFromId } = data;

  const handleClose = () => {
    setUserAnchorEl(null);
  };

  const onLogout = async () => {
    await logout(userId);
  };

  const onClose = () => {
    setSettingOpen(false);
    setChangedRows([]);
    setSelectedLeftMenu('profile');
    setSelectedGroup(false);
  };

  if (error || !data) return <div>no data</div>;

  if (data)
    return (
      <>
        <Avatar
          pointer
          size={32}
          highlightOnHover
          src={avatar}
          onClick={(e) => {
            setUserAnchorEl(e.currentTarget);
          }}
          variant={avatarVariants.people}
        >
          {getInitials(username)}
        </Avatar>

        {userAnchorEl && (
          <Popover
            style={{ marginTop: '24px', marginLeft: '-16px' }}
            anchorEl={userAnchorEl}
            position="center"
            onClose={() => {
              setUserAnchorEl(null);
            }}
          >
            <ProfileWrapper>
              <AppMenu
                avatar={avatar}
                username={username}
                onClose={handleClose}
                onLogout={onLogout}
                key="app-menu"
                setSettingOpen={setSettingOpen}
              />
            </ProfileWrapper>
          </Popover>
        )}
        <Dialog open={settingOpen} onClose={onClose}>
          <SettingsWrapper>
            <Settings groupPolicies={getMemberFromId} onClose={onClose} />
          </SettingsWrapper>
        </Dialog>
      </>
    );

  if (loading) return <LoadingIndicator />;

  return null;
};

export default SettingsContainer;
