/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import { useQuery, useMutation } from '@apollo/client';
import UPDATE_CONTACT from 'graphql/mutations/updateContact';
import UPDATE_MEMBERS from 'graphql/mutations/updateMembers';
import memberTypes from 'graphql/memberTypes';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import { getMembersOfQuery } from 'graphql/queryVariables';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import UserCtx from 'contexts/UserContext';
import useLogger from 'utils/useLogger';
import InfoView from './info-view';

const InfoContainer = (props) => {
  const { id, properties, type, ...rest } = props;
  const { __typename, email, firstName, surname, phone, dateOfBirth, readSpeed } = properties;
  const user = useContext(UserCtx);
  const input = {
    mId: id,
  };
  const mProperties = {
    __typename,
    firstName,
    surname,
    email,
    phone,
    dateOfBirth,
    jobTitle: type === memberTypes.USER ? 'User' : 'Contact',
    readSpeed,
  };
  const logger = useLogger('info-container');
  const [update] = useMutation(UPDATE_CONTACT);
  const [updateMembers] = useMutation(UPDATE_MEMBERS);

  const {
    data: teamData,
    error: teamError,
    loading: teamLoading,
  } = useQuery(GET_MEMBERS_OF, {
    variables: {
      input: {
        membersOfType: memberTypes.TEAM_USER,
        mId: id,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: departmentData,
    error: departmentError,
    loading: departmentLoading,
  } = useQuery(GET_MEMBERS_OF, {
    variables: {
      input: {
        membersOfType: memberTypes.DEPARTMENT_USER,
        mId: id,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (teamLoading || departmentLoading) return <LoadingIndicator />;
  if (teamError) return <div> No Team Data. </div>;
  if (departmentError) return <div> No department data </div>;

  const { getMembersOf: getTeams } = teamData;
  const { getMembersOf: getDepartments } = departmentData;

  const updateContact = (variables) => {
    if (type === memberTypes.USER || type === memberTypes.CONTACT) {
      update(variables);
    }
  };

  const updateDescription = debounce((newDescription) => {
    input.mDescription = newDescription;

    updateContact({
      variables: {
        input,
      },
    });
  }, 300);

  const updateEmail = debounce((newEmail) => {
    mProperties.email = newEmail;
    input.mProperties = mProperties;

    updateContact({
      variables: {
        input,
      },
    });
  }, 300);

  const updatePhone = debounce((newPhone) => {
    mProperties.phone = newPhone;
    input.mProperties = mProperties;

    updateContact({
      variables: {
        input,
      },
    });
  }, 300);

  const updateDateOfBirth = debounce((newDate) => {
    mProperties.dateOfBirth = newDate;
    input.mProperties = mProperties;

    updateContact({
      variables: {
        input,
      },
    });
  }, 300);

  const updateReadSpeed = debounce((newSpeed) => {
    mProperties.readSpeed = newSpeed;
    input.mProperties = mProperties;

    updateContact({
      variables: {
        input,
      },
    });
  }, 300);

  const addMembersToContact = async (resourceType, updatedMembers) => {
    let mType;
    let currentMembers;
    if (resourceType === 'team') {
      mType = memberTypes.TEAM_USER;
      currentMembers = getTeams;
    } else if (resourceType === 'department') {
      mType = memberTypes.DEPARTMENT_USER;
      currentMembers = getDepartments;
    } else return;

    const existingMemberIds = currentMembers.map(({ mId }) => mId);
    const updatedMemberIds = updatedMembers.map(({ mId }) => mId);

    const addedMemberIds = difference(updatedMemberIds, existingMemberIds);
    const newMembers = {
      members: addedMemberIds.map((mId) => ({
        mId,
        mRefId: id,
        mType,
      })),
    };

    const removedMemberIds = difference(existingMemberIds, updatedMemberIds);
    const removedMembers = {
      members: removedMemberIds.map((mId) => ({
        mId,
        mRefId: id,
      })),
    };

    // Cache update for team and department
    try {
      await updateMembers({
        variables: {
          newMembers,
          removedMembers,
        },
        update: (proxy) => {
          const newMembersWithType = updatedMembers.map((member) => ({
            ...member,
            mType: resourceType,
          }));

          // updating the my teams or my departments cache on the left sidebar

          proxy.writeQuery({
            query: GET_MEMBERS_OF,
            variables: getMembersOfQuery(user.mId, mType),
            data: {
              getMembersOf: newMembersWithType,
            },
          });

          // updating the teams or departments cache on contact details in the right sidebar
          proxy.writeQuery({
            query: GET_MEMBERS_OF,
            variables: {
              input: {
                membersOfType: mType,
                mId: id,
              },
            },
            data: {
              getMembersOf: newMembersWithType,
            },
          });
        },
      });
    } catch (error) {
      logger.log(error);
    }
  };

  return (
    <InfoView
      {...props}
      teams={getTeams}
      departments={getDepartments}
      addTeamsToContact={(members) => addMembersToContact(memberTypes.TEAM, members)}
      addDepartmentsToContact={(members) => addMembersToContact(memberTypes.DEPARTMENT, members)}
      {...{
        updateDescription,
        updateEmail,
        updatePhone,
        updateDateOfBirth,
        updateReadSpeed,
      }}
      editDisabled={(type === memberTypes.USER && id !== user.mId) || false}
      {...rest}
    />
  );
};

export default InfoContainer;
