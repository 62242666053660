import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import theme from 'theme/theme';

import Container from '../container/Container';
import MaterialAvatar from '../material-avatar';

const StyledTeamAvatar = styled(MaterialAvatar)`
  width: 100%;
  height: 100%;
  border-radius: 25%;
  background-color: ${({ showImage }) =>
    showImage ? 'transparent' : theme.palette.dina.pallateAccentCyan};
`;

const Team = ({ children, src, title, ...rest }) => (
  <Container {...rest}>
    <StyledTeamAvatar showImage={!!src} src={src} title={title}>
      {src ? null : children}
    </StyledTeamAvatar>
  </Container>
);

Team.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  title: PropTypes.string,
};

Team.defaultProps = {
  children: null,
  src: '',
  title: '',
};

export default Team;
