import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UsersContext } from 'globalState/users';
import MessageBubble from 'components/messageBubble';
import Button from 'components/buttons/button';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import useOpenStory from 'hooks/useOpenStory';
import useStyles from './messages-styles';

const Messages = ({
  messages,
  hasMore,
  onLoadMore,
  userId,
  lastMessageRef,
  onDeleteMessage,
  onClose,
}) => {
  const classes = useStyles();
  const { getUser } = useContext(UsersContext);
  const [, setCurrentClicked] = useScrollIntoView();
  const openStory = useOpenStory();

  const handleDelete = async (mId, mRefId) => {
    try {
      await onDeleteMessage(mRefId);
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    const target = document.getElementById(lastMessageRef.current);
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      // eslint-disable-next-line no-param-reassign
      lastMessageRef.current = null;
    }
  }, [lastMessageRef]);

  const handleOpenStory = async (title, mStoryId, page, instanceId) => {
    await openStory(title, mStoryId, page).then(() => {
      instanceId && setCurrentClicked(instanceId);
    });
    onClose();
  };

  return (
    <div className={classes.messages}>
      {messages.map((message) => (
        <MessageBubble
          key={message.mRefId}
          message={message}
          userId={userId}
          showEditButton={false}
          showDeleteButton
          getUser={getUser}
          onDelete={handleDelete}
          openStory={handleOpenStory}
        />
      ))}
      {hasMore ? (
        <div className={classes.loadMoreButtonContainer}>
          <Button onClick={onLoadMore} variant="text" title="Load more messages">
            Load more
          </Button>
        </div>
      ) : null}
    </div>
  );
};

Messages.propTypes = {
  /** list of messages */
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  /** boolean that indicates more messages */
  hasMore: PropTypes.bool,
  /** callback to load more message */
  onLoadMore: PropTypes.func,
  /** id of the user */
  userId: PropTypes.string,
  /** ref containing the id of last message */
  lastMessageRef: PropTypes.shape({
    current: PropTypes.string,
  }),
  /** callback to close message hub */
  onClose: PropTypes.func,
};

Messages.defaultProps = {
  messages: [],
  hasMore: false,
  onLoadMore: () => {},
  userId: '',
  lastMessageRef: { current: null },
  onClose: () => {},
};

export default Messages;
