export const chunkArray = (array, batchSize = 40) => {
  const result = [];
  for (let i = 0, len = array.length; i < len; i += batchSize)
    result.push(array.slice(i, i + batchSize));
  return result;
};

/**
 * @param  {Object[]} array
 * @param  {number} from - index
 * @param  {number} to - index
 */
export const moveArrayItem = (array, from, to) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
  return array;
};

/**
 * @param  {Object[]} arr
 * @param  {} index - index where to insert
 * @param  {} newItem - new item
 */
export const insertIntoArray = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];
