import React, { memo, useState, useCallback, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor, useReadOnly } from 'slate-react';
import { Transforms } from 'slate';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import uuidv4 from 'uuid/v4';
import fieldEnums from 'utils/constants/fieldEnums';
import { removeBlock, generateName, updateBlock } from 'components/editor/utils';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import useImageUpload from 'hooks/useImageUpload';
import useStorageImage from 'hooks/useStorageImage';
import imageTypes from 'utils/constants/imageTypes';
import notifyChange from 'components/editor/utils/notifyChange';
import ThumbnailComponent from './components/thumbnail';
import MediaDropZone from './components/mediaDropZone';
import AutoComplete from './components/autoComplete';
import Title from './components/title';
import Description from './components/description';
import Metadata from './components/metadata';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';
import { LiveStreamIcon, BoxChildren, AutocompleteWrapper, Label } from './styled';

const { setNodes } = Transforms;

const LiveStream = ({ attributes, children, element }) => {
  const editor = useSlate();
  const [getFieldsForBlock] = useGetFieldsForBlock();

  const field = getFieldsForBlock(fieldEnums.CUSTOM_SOURCES, { options: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sourceOptions = useMemo(() => field?.options || [], []);

  const { update } = useEditorContext();
  const readOnlyEditor = useReadOnly();
  const [readOnly, setReadOnly] = useState(readOnlyEditor);
  const thumbnailRef = useRef(null);
  const [onChangeCollapse] = useChangeCollapse(element);

  useEffect(() => {
    setReadOnly(readOnlyEditor);
  }, [readOnlyEditor]);

  const { data } = element;
  const {
    mRefId,
    showThumbnail = true,
    url,
    thumbnails = [],
    stream,
    mTitle,
    mDescription,
    dvrHours,
    endTime,
    startTime,
    collapsed = false,
    isCoverphoto = false,
    source,
  } = data;
  const [title, setTitle] = useState(mTitle);
  const [boxType, setBoxType] = useState('media');

  useEffect(() => {
    if (isCoverphoto) setBoxType('iscoverphoto');
    else setBoxType('media');
  }, [isCoverphoto]);

  const { src, proxy } = thumbnails.length > 0 ? thumbnails[0] : {};
  const { data: imageData } = useStorageImage(src);
  const thumbnailSrc = imageData || proxy || thumbnailRef.current;

  const updateTitle = useCallback(
    async (newTitle) => {
      if (mRefId) {
        await update({
          type: actionTypes.ASSET_UPDATE,
          payload: {
            document: editor.children,
            asset: { title: newTitle, id: mRefId },
          },
        });
      }

      setTitle(newTitle);
      const updatedData = {
        ...data,
        mTitle: newTitle,
      };
      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, mRefId],
  );

  const updateDescription = useCallback(
    (newDescription) => {
      const updatedData = {
        ...data,
        mDescription: newDescription,
      };

      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );
  const updateMetadata = useCallback(
    (newMetadataObject) => {
      const updatedData = {
        ...data,
        ...newMetadataObject,
      };

      const { isCoverphoto: mIscoverPhoto } = newMetadataObject;
      const allAttributes = mIscoverPhoto ? { key: 'isCoverphoto', value: false } : null;
      updateBlock(editor, element, updatedData, update, false, allAttributes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const onImageLoad = useCallback(
    async (file, fileUrl) => {
      thumbnailRef.current = fileUrl;
      if (file && fileUrl) {
        const fileName = generateName(file.type);
        const response = await update({
          type: actionTypes.ASSET_INSERT,
          payload: { document: editor.children, file, fileName },
        });

        const updatedData = {
          ...data,
          thumbnails: [response],
        };
        const path = ReactEditor.findPath(editor, element);
        setNodes(editor, { data: updatedData }, { at: path });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const uploadImage = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
  });

  const onAddThumbnailClick = useCallback(
    (event) => {
      event.preventDefault();
      uploadImage();
    },
    [uploadImage],
  );

  const handleRemoveThumbnail = useCallback(() => {
    thumbnailRef.current = null;
    const updatedData = {
      ...data,
      thumbnails: [],
    };

    updateBlock(editor, element, updatedData, update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, element, update]);

  const createStream = useCallback(
    async (newStream) => {
      const { title: newTitle, value } = newStream;

      setReadOnly(true);
      const response = await update({
        type: actionTypes.CREATE_ASSET,
        payload: {
          document: editor.children,
          asset: {
            id: uuidv4(),
            title: newTitle,
            itemType: 'video/stream',
            mediaType: 'video/stream',
            url: value,
            state: 'stream',
          },
        },
      });
      setReadOnly(false);

      const assets = response?.data?.createAssets;

      const { mId, mRefId: refId } = assets.length > 0 ? assets[0] : {};

      setTitle(newTitle);

      const updatedData = {
        ...data,
        url: value,
        mTitle: newTitle,
        stream: newStream,
        mId,
        mRefId: refId,
      };

      updateBlock(editor, element, updatedData, update);
      notifyChange(editor, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, update],
  );

  const updateStream = useCallback(
    async (newStream) => {
      const { title: newTitle, value } = newStream;

      setReadOnly(true);
      await update({
        type: actionTypes.ASSET_UPDATE,
        payload: {
          document: editor.children,
          asset: { title: newTitle, id: mRefId, url: value },
        },
      });
      setReadOnly(false);

      setTitle(newTitle);

      const updatedData = {
        ...data,
        url: value,
        mTitle: newTitle,
        stream: newStream,
      };

      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, update],
  );

  const onUpdateStream = useCallback(
    (newStream) => {
      if (!stream?.id) createStream(newStream);
      else if (newStream.id !== stream?.id) updateStream(newStream);
    },
    [stream, createStream, updateStream],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderContent = useMemo(
    () => (
      <MediaDropZone {...{ element }}>
        <Box
          iconComponent={<LiveStreamIcon />}
          title="Live Stream"
          readOnly={readOnly}
          type={boxType}
          updateCollapsed={onChangeCollapse}
          collapsed={collapsed}
          collapsedContent={title}
          hideEllipsisButton
          onMenuSelect={onMenuSelect}
        >
          <BoxChildren>
            <AutocompleteWrapper>
              {showThumbnail && (
                <ThumbnailComponent
                  src={thumbnailSrc}
                  readOnly={readOnly}
                  onAdd={onAddThumbnailClick}
                  onRemove={handleRemoveThumbnail}
                />
              )}
              <AutoComplete
                readOnly={readOnly}
                onUpdateStream={onUpdateStream}
                stream={stream}
                value={url}
              />
            </AutocompleteWrapper>
            <Label>Stream Title and description</Label>
            <Title
              title={title}
              setTitle={setTitle}
              readOnly={readOnly}
              updateTitle={updateTitle}
              initialTitle={mTitle}
            />
            <Description
              initialDescription={mDescription}
              readOnly={readOnly}
              updateDescription={updateDescription}
            />
            <Metadata
              source={source || []}
              dvrHours={dvrHours}
              endTime={endTime}
              startTime={startTime}
              readOnly={readOnly}
              updateMetadata={updateMetadata}
              isCoverphoto={isCoverphoto}
              sourceOptions={sourceOptions}
            />
          </BoxChildren>
        </Box>
      </MediaDropZone>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      boxType,
      collapsed,
      dvrHours,
      element,
      endTime,
      handleRemoveThumbnail,
      isCoverphoto,
      mDescription,
      mTitle,
      onAddThumbnailClick,
      onMenuSelect,
      onUpdateStream,
      readOnly,
      showThumbnail,
      source,
      sourceOptions,
      startTime,
      stream,
      thumbnailSrc,
      title,
      onChangeCollapse,
      updateDescription,
      updateMetadata,
      updateTitle,
      url,
    ],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
};

LiveStream.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

LiveStream.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.LIVE_STREAM,
  },
};

export default memo(LiveStream);
