/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import Chip from 'components/chip';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useStyles from './chipInput-styles';

const ChipInput = (props) => {
  const {
    chipInputItems,
    setItems,
    onDelete,
    helperText,
    variant,
    placeholder,
    inputRef,
    onKeyDown,
    onValueChange,
    disabled,
    disableEdit,
    minHeight,
    rootPadding,
    ...rest
  } = props;

  const classes = useStyles({ minHeight, rootPadding });

  const renderTags = (value) =>
    value.map((chip, index) => (
      <div className={classes.chip} key={chip.mId}>
        <Chip
          variant={chip?.mType === 'user' ? 'People' : capitalize(chip?.mType) || variant}
          key={chip.mId}
          onDelete={() => {
            onDelete(chip, index);
          }}
          label={chip.mTitle}
          avatarUrl={chip.mAvatarUrl}
          disabled={disabled || disableEdit}
        />
      </div>
    ));

  const renderInput = (params) => (
    <TextField
      {...params}
      variant="filled"
      placeholder={placeholder}
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      helperText={helperText}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
      InputProps={{
        ...params.InputProps,
        className: classes.noPadding,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          underline: classes.underline,
          disabled: classes.disabled,
          focused: classes.focused,
          error: classes.error,
        },
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        ...params.inputProps,
        className: classes.inputProp,
      }}
      {...rest}
    />
  );

  const onChange = (_event, newValue, reason) => {
    if (reason === 'remove-option' && setItems) {
      setItems(newValue);
    }
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      id="add-members-dialog"
      options={[]}
      open={false}
      clearOnBlur={false}
      disableClearable
      forcePopupIcon={false}
      value={chipInputItems}
      disabled={disabled || disableEdit}
      onInputChange={onValueChange}
      onChange={onChange}
      renderTags={renderTags}
      renderInput={renderInput}
    />
  );
};

ChipInput.propTypes = {
  chipInputItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdateInput: PropTypes.func,
  onValueChange: PropTypes.func,
  setItems: PropTypes.func,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  disableEdit: PropTypes.bool,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rootPadding: PropTypes.string,
};

ChipInput.defaultProps = {
  chipInputItems: [],
  onAdd: () => {},
  onDelete: () => {},
  onUpdateInput: () => {},
  onValueChange: () => {},
  setItems: () => {},
  helperText: '',
  placeholder: 'Type to find items',
  disableEdit: false,
  minHeight: '48px',
  rootPadding: '6px',
};

export default ChipInput;
