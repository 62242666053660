import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ListItem as MuiListItem, ListItemText as MuiListItemText } from '@material-ui/core';
import styled from '@emotion/styled';
import theme from 'theme/theme';
import { listItemLabel } from 'theme/styledTypography';

const ListItem = styled(MuiListItem)`
  height: 40px;
  :hover {
    background-color: ${theme.palette.dina.statusOnHover};
  }
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${theme.palette.dina.onSelected};
  }
`;

const ListItemText = styled(MuiListItemText)`
  flex: 0 1 auto;
  .MuiListItemText-primary {
    ${listItemLabel};
    font-weight: normal;
    margin-left: 16px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const ListItemView = ({ label, selected, onClick }) => (
  <ListItem button disableGutters selected={selected} onClick={onClick}>
    <ListItemText primary={label} />
  </ListItem>
);

ListItemView.propTypes = {
  /** Label of List Item */
  label: PropTypes.string,
  /** Whether the list item is selected or not */
  selected: PropTypes.bool,
  /** onClick callback when list item used as button */
  onClick: PropTypes.func,
};

ListItemView.defaultProps = {
  label: '',
  selected: false,
  onClick: () => {},
};

export default memo(ListItemView);
