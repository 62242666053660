import styled from '@emotion/styled';

export const ToolbarWrapper = styled.div`
  flex: 0 0 auto;
`;

export const EditorWrapper = styled.div`
  ${(props) => ({ background: props.background })}
  ${(props) => ({ width: props.width })}
  ${(props) => ({ height: props.height })}
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const EditableWrapper = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  ${(props) => ({ padding: props.padding })}
  color: white;
  ${({ messageVariant }) =>
    messageVariant &&
    `
    min-height: 40px;
    max-height: 160px;
    `}
  ${({ fontSize }) => {
    if (fontSize === 'medium')
      return `
      font-size: 24px;
    `;
    if (fontSize === 'large')
      return `
      font-size: 36px;
    `;
    if (fontSize === 'xLarge')
      return `
      font-size: 48px;
    `;
    return `font-size: 16px`;
  }}
`;
