import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getRelativeDate from 'utils/getRelativeDate';
import CalendarOff from 'assets/icons/systemicons/calendar_off.svg';
import CalendarOn from 'assets/icons/systemicons/calendar_on.svg';
import Calendar from 'components/calendar';
import capitalize from 'utils/capitalize';
import { ClickAwayListener, Typography } from '@material-ui/core';
import Popper from 'components/shared/popper';
import useStyles from './publishingDatePickerButton-styles';

const DatePickerButton = ({ publishingTime, onPublishingTimeChange, disableUpdate }) => {
  const [publishTime, setPublishTime] = useState(publishingTime);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setPublishTime(publishingTime);
  }, [publishingTime]);

  const setPopoverAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newTime) => {
    setPublishTime(newTime);
    onPublishingTimeChange(newTime.toISOString());
    onClose();
  };

  const handleUnschedule = () => {
    setPublishTime();
    onPublishingTimeChange();
    onClose();
  };

  const label = publishTime
    ? capitalize(getRelativeDate(publishTime, 'DD MMM. YYYY'))
    : 'Schedule date...';
  const iconSrc = publishTime ? CalendarOn : CalendarOff;

  return (
    <div>
      <div role="presentation" onClick={setPopoverAnchor} className={classes.button}>
        <img src={iconSrc} alt="calendar" className={classes.icon} width="24" height="24" />
        <Typography className={publishTime ? classes.textOn : classes.textOff}>{label}</Typography>
      </div>

      {!disableUpdate && (
        <Popper {...{ anchorEl }}>
          <ClickAwayListener onClickAway={onClose}>
            <div className={classes.calendarContainer}>
              <Calendar
                selectedDate={publishTime}
                changeSelectedDate={handleChange}
                onUnschedule={handleUnschedule}
              />
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
};

DatePickerButton.propTypes = {
  publishingTime: PropTypes.string,
  onPublishingTimeChange: PropTypes.func,
};

DatePickerButton.defaultProps = {
  publishingTime: '',
  onPublishingTimeChange: () => {},
};

export default DatePickerButton;
