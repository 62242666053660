import React from 'react';
import Checkbox from 'components/checkbox';
import {
  PermissionCell,
  ResourceTypesCell,
  GroupPermissionTableRow,
  CheckboxCell,
} from './row-styled';
import { useChangedRows } from '../../../../../atoms';

const GroupPermissionsRow = (props) => {
  const { row, selectedGroupRefId } = props;
  const [changedRows, setChangedRows] = useChangedRows();
  const isChanged = (id) => changedRows.find((changed) => changed.id === id);

  const setRows = (rows) => {
    setChangedRows(rows);
  };

  const onCheck = () => {
    // If rows is already in changed rows then remove it
    if (isChanged(row.id)) {
      setRows(changedRows.filter((changedRow) => changedRow.id !== row.id));
    } else {
      setRows([
        ...changedRows,
        {
          ...row,
          access: !row.access,
          groupRefId: selectedGroupRefId,
        },
      ]);
    }
  };

  return (
    <GroupPermissionTableRow hover onClick={onCheck} key={row.permission}>
      <CheckboxCell>
        <Checkbox selected={isChanged(row.id) ? !row.access : row.access} size={24} />
      </CheckboxCell>
      <PermissionCell>{row.permission}</PermissionCell>
      <ResourceTypesCell>{row.resourceName}</ResourceTypesCell>
    </GroupPermissionTableRow>
  );
};

export default GroupPermissionsRow;
