import styled from '@emotion/styled';

import theme from 'theme/theme';
import memberTypes from 'graphql/memberTypes';

import AppBar from '@material-ui/core/AppBar';

import pitchToolbarSrc from 'assets/images/PitchBackgroundOverlay.png';
import rndToolbarSrc from 'assets/images/Tile_DiagonalPattern.png';

export const StyledAppbar = styled(AppBar)`
  height: 48px;
  box-shadow: 0px 4px 4px ${theme.palette.dina.boxShadowDark},
    0px 0px 1px ${theme.palette.dina.boxShadowDark};
  ${({ mType, isStory, isToday }) => {
    if (mType === memberTypes.RUNDOWN) {
      return `
        background-color: ${theme.palette.dina.paletteAccentPurple};
        background-image: ${isToday ? null : `url(${rndToolbarSrc})`};
      `;
    }
    if (mType === memberTypes.RUNDOWN_TEMPLATE) {
      return `background-color: ${theme.palette.dina.paletteAccentOrange};`;
    }
    if (mType === 'create') return `background-color: ${theme.palette.dina.onSelected};`;
    if (isStory) return `background-color: ${theme.palette.dina.onSelected};`;
    if (mType === memberTypes.PITCH) {
      return `background-image: url(${pitchToolbarSrc});`;
    }
    return `background-color: ${theme.palette.dina.primary700};`;
  }}
`;

export const StyledToolbar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 0 12px;
`;
