/* eslint-disable react/react-in-jsx-scope */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import DragAndDrop from '../dragAndDrop';
import headingComponents from './constants/headingComponents';

const headingStyle = css`
  margin: 0;
  padding: 8px;
`;

const Heading = ({ attributes, children, element }) => {
  const MemoizedHeading = useMemo(() => headingComponents[element.type], [element.type]);

  return (
    <DragAndDrop element={element} isDisabled>
      <MemoizedHeading css={headingStyle} {...attributes}>
        {children}
      </MemoizedHeading>
    </DragAndDrop>
  );
};

Heading.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Heading.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.HEADING_ONE,
    children: [],
  },
};

export default memo(Heading);
