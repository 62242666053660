/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import Drawer from '@material-ui/core/Drawer';

import transientOptions from 'theme/helpers';

import { calcContentHeight } from 'screens/main/utils';

export const StyledDrawer = styled(Drawer, transientOptions)`
  max-width: ${({ width }) => width};
  z-index: 50;

  & .MuiDrawer-paper {
    min-width: ${({ width }) => `${width}px`};
    max-width: ${({ width }) => `${width}px`};
    top: ${({ hasTitle }) => (hasTitle ? '120px' : '96px')};
    height: ${({ hasTitle, $isTickerVisible }) => calcContentHeight(hasTitle, $isTickerVisible)};
  }

  & .MuiDrawer-paperAnchorRight {
    right: 48px;
    box-shadow: -8px 0px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25);
  }
`;
