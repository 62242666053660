import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

/**
 * Updates editor -> element's data attribute.
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element
 * @param {Object} data Data properties of the SlateJS element
 * @param {Object} newAssetData - getQuery Asset Data
 *
 *
 * @returns {Object} SlateJS editor instance
 *
 */

const syncAssetWithSlate = (editor, element, data, newAssetData = {}) => {
  const asset = {};

  newAssetData.mMetaData?.forEach(({ key, value }) => {
    if (['mediaSize', 'mediaDuration', 'provider'].includes(key)) asset[key] = value;
  });

  const [FirstAsset, ...rest] = data.assets;

  const updatedData = {
    ...data,
    assets: [
      {
        ...FirstAsset,
        itemDuration: asset.mediaDuration,
        mediaType: newAssetData.mediaType,
        title: newAssetData.mTitle,
        thumbnailUrl: newAssetData.mThumbnailUrl,
        proxy: newAssetData.mContentUrl,
        mAssetId: newAssetData.mAssetId,
      },
      ...rest,
    ],
  };

  const path = ReactEditor.findPath(editor, element);
  Transforms.setNodes(editor, { data: updatedData }, { at: path });
};

export default syncAssetWithSlate;
