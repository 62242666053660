import styled from '@emotion/styled';
import { IconButton as MaterialIconButton } from '@material-ui/core';
import { listItemLabel } from 'theme/styledTypography';
import theme from 'theme/theme';

export const ButtonsWrapper = styled('div')`
  display: flex;
`;

export const IconButton = styled(MaterialIconButton)`
  :hover {
    > :first-child {
      > :first-child {
        > :first-child {
          fill: white;
          fill-opacity: 1;
        }
      }
    }
  }
`;

export const Title = styled('div')`
  ${listItemLabel}
  font-weight: 500;
  letter-spacing: 0.25px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  align-self: center;
  &:hover {
    filter: ${theme.palette.dina.onHover};
    text-decoration: ${(props) => (props.disableEdit ? 'none' : 'underline')};
    cursor: ${(props) => (props.disableEdit ? 'default' : 'pointer')};
  }
`;

export const TitleWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;
