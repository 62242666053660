import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UsersContext } from 'globalState/users';
import UserContext from 'contexts/UserContext';
import useConversationContext from '../../../hooks/useConversationContext';
import getAvatarVariant from './utils/getAvatarVariant';
import getAssignedMemberInfo from './utils/getAssignedMemberInfo';
import Header from './header-view';

const HeaderDataContainer = (props) => {
  const { currentConversation, teams, departments } = useConversationContext();
  const { getUser } = useContext(UsersContext);
  const user = useContext(UserContext);

  const { mAssignedMembers: conversationMembers, convoType } = currentConversation;
  const mAssignedMembers = getAssignedMemberInfo(
    getUser,
    conversationMembers,
    user.mId,
    convoType,
    teams,
    departments,
  );
  const avatarVariant = getAvatarVariant(convoType);

  return <Header {...props} mAssignedMembers={mAssignedMembers} avatarVariant={avatarVariant} />;
};

HeaderDataContainer.propTypes = {
  /** callback to open new message by clicking add user icon */
  openNewMessage: PropTypes.func,
  /** callback to remove user & initiate different conversation */
  removeMember: PropTypes.func,
  /** boolean to show add user button */
  showAddUser: PropTypes.bool,
  /** boolean to show delete button on chip */
  showDeleteButton: PropTypes.bool,
  /** callback to select a direct message with a member by clicking a chip */
  selectMember: PropTypes.func,
};

HeaderDataContainer.defaultProps = {
  openNewMessage: () => {},
  removeMember: () => {},
  showAddUser: true,
  showDeleteButton: true,
  selectMember: () => {},
};

export default HeaderDataContainer;
