import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import IconTab from './components/iconTab';
import ContentTab from './components/contentTab';

import { StyledTabs as Tabs } from './tabs-styled';

const TabsView = forwardRef(
  (
    {
      tabIndex,
      breakingFeedNotifications,
      clearNotificationCount,
      tabs,
      handleChange,
      contentTabWidth,
      maxVisibleTabs,
    },
    ref,
  ) => (
    <Tabs value={tabIndex} onChange={handleChange} ref={ref}>
      {tabs.slice(0, maxVisibleTabs).map((tab, index) => {
        const { tabType, type, id } = tab;
        if (tabType === 'icon') {
          return (
            <IconTab
              key={type}
              tab={tab}
              breakingFeedNotifications={breakingFeedNotifications}
              clearNotificationCount={clearNotificationCount}
            />
          );
        }
        return (
          <ContentTab
            key={id}
            index={index}
            tab={tab}
            width={contentTabWidth}
            data-testid="story-tab"
          />
        );
      })}
    </Tabs>
  ),
);

TabsView.propTypes = {
  /** selected tab index */
  tabIndex: PropTypes.number.isRequired,
  /** feed notifications */
  breakingFeedNotifications: PropTypes.number.isRequired,
  /** fn to clear notification count */
  clearNotificationCount: PropTypes.func.isRequired,
  /** tabs array */
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** max number of visible tabs */
  maxVisibleTabs: PropTypes.number.isRequired,
};

export default TabsView;
