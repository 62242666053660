import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '../box';
import { LocationIcon, MapIcon, ChildrenWrapper, ContentWrapper } from './styled';

const LocationBase = ({ menuItems, readOnly, children, title, ...rest }) => (
  <Box
    iconComponent={<LocationIcon />}
    title={title}
    height={144}
    readOnly={readOnly}
    menuItems={menuItems}
    {...rest}
  >
    <ContentWrapper>
      <MapIcon />
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </ContentWrapper>
  </Box>
);

LocationBase.propTypes = {
  /** A list of menuItems */
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  /** Boolean that stops the component from editing */
  readOnly: PropTypes.bool,
  /** Contents of box component */
  children: PropTypes.node,
  /** Title of the box component */
  title: PropTypes.string,
};

LocationBase.defaultProps = {
  menuItems: [],
  readOnly: false,
  children: null,
  title: '',
};

export default memo(LocationBase);
