import React, { useState } from 'react';
import { ListSubheader, List } from '@material-ui/core';

import Popover from 'components/popover';

import FolderMore from 'assets/icons/systemicons/folder_more.svg';
import FolderAdd from 'assets/icons/systemicons/folder_add.svg';

import FoldersSubMenu from './components/foldersSubMenu';
import TemplateSubMenu from './components/templatesSubMenu';
import CreateNew from '../linearEllipsisMenu/components/createNew';

import MenuItem from '../menuItem';
import useStyles from './foldersMenuList-styles';

const FoldersMenuList = ({
  anchorEl,
  folders,
  canCreateNewTemplate,
  canDeleteTemplateFolder,
  canDeleteTemplate,
  writeLock,
  onDeleteFolder,
  closeMenu,
  onSaveTemplate,
  onSelectTemplate,
  onDeleteTemplate,
  onCreateFolder,
}) => {
  const classes = useStyles();

  const [anchor, setAnchor] = useState(null);
  const [popoverComponent, setPopoverComponent] = useState(null);

  const handleClosePopover = () => {
    setAnchor(null);
    setPopoverComponent(null);
  };

  const handleSaveTemplate = (folderId, saveTitle, overwriteData) => {
    onSaveTemplate(folderId, saveTitle, overwriteData);
    handleClosePopover();
    closeMenu();
  };

  const handleTemplateSelect = (template) => {
    writeLock && onSelectTemplate(template);
    handleClosePopover();
    closeMenu();
  };

  const handleDeleteTemplate = (mId, mRefId, mContentKey) => {
    onDeleteTemplate(mId, mRefId, mContentKey);
    handleClosePopover();
  };

  const handleCreateFolder = (folderTitle) => {
    onCreateFolder(folderTitle);
    handleClosePopover();
  };

  const handleDeleteFolder = (mId, mRefId) => {
    onDeleteFolder(mId, mRefId);
    handleClosePopover();
  };

  const sortedFolders = [...folders].sort((a, b) =>
    a.mTitle.localeCompare(b.mTitle, undefined, { numeric: true }),
  );
  const firstThreeFolders = sortedFolders.slice(0, 3);
  const remainingFolders = sortedFolders.slice(3);

  return (
    <>
      <List disablePadding classes={{ root: classes.menuItem }}>
        <ListSubheader classes={{ root: classes.listSubheader }}>INSTANCE TEMPLATES</ListSubheader>
        {firstThreeFolders.length > 0 &&
          firstThreeFolders.map(({ mId, mRefId, mTitle, items }) => (
            <MenuItem
              key={mRefId}
              label={mTitle}
              data={items}
              showDeleteButton={canDeleteTemplateFolder}
              anchorEl={anchorEl}
              isMuted={!writeLock}
              onDeleteButtonClick={() => {
                onDeleteFolder(mId, mRefId);
              }}
              onClick={() => {
                setAnchor(anchorEl);
                setPopoverComponent(
                  <TemplateSubMenu
                    folderId={mRefId}
                    templates={items}
                    anchorEl={anchorEl}
                    onTemplateSelect={handleTemplateSelect}
                    onTemplateSave={handleSaveTemplate}
                    onDeleteTemplate={handleDeleteTemplate}
                    disabled={!writeLock}
                    canCreateNewTemplate={canCreateNewTemplate}
                    canDeleteTemplate={canDeleteTemplate}
                  />,
                );
              }}
            />
          ))}
        {remainingFolders.length > 0 && (
          <MenuItem
            data={remainingFolders}
            label="More Template Folders"
            image={FolderMore}
            onClick={() => {
              setAnchor(anchorEl);
              setPopoverComponent(
                <FoldersSubMenu
                  folders={remainingFolders}
                  anchorEl={anchorEl}
                  onTemplateSelect={handleTemplateSelect}
                  onTemplateSave={handleSaveTemplate}
                  onDeleteTemplate={handleDeleteTemplate}
                  onDeleteFolder={handleDeleteFolder}
                  disableChildren={!writeLock}
                  canCreateNewTemplate={canCreateNewTemplate}
                  canDeleteTemplate={canDeleteTemplate}
                  canDeleteTemplateFolder={canDeleteTemplateFolder}
                />,
              );
            }}
          />
        )}
        {canCreateNewTemplate && (
          <MenuItem
            label="Create New Template Folder"
            image={FolderAdd}
            onClick={() => {
              setAnchor(anchorEl);
              setPopoverComponent(
                <CreateNew
                  onCancel={handleClosePopover}
                  onOk={handleCreateFolder}
                  data={folders}
                />,
              );
            }}
            showSecondaryItem={false}
          />
        )}
        <Popover
          noMargin
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClosePopover}
        >
          {popoverComponent}
        </Popover>
      </List>
    </>
  );
};

FoldersMenuList.propTypes = {};
FoldersMenuList.defaultProps = {};

export default FoldersMenuList;
