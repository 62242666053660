import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import GET_MEMBERS from 'graphql/queries/getUsers';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import AddMember from 'components/addMemberDialog/dialog-view';
import { avatarVariants } from 'components/avatar/Avatar';

const tabs = [
  { label: 'Teams', variant: avatarVariants.team },
  { label: 'Departments', variant: avatarVariants.department },
];

const DialogContainer = (props) => {
  const {
    data: teamData,
    error: teamError,
    loading: teamLoading,
  } = useQuery(GET_MEMBERS, {
    variables: getMembersOfTypeQuery(memberTypes.TEAM),
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: departmentData,
    error: departmentError,
    loading: departmentLoading,
  } = useQuery(GET_MEMBERS, {
    variables: getMembersOfTypeQuery(memberTypes.DEPARTMENT),
    fetchPolicy: 'cache-and-network',
  });

  const [tabIndex, setTabIndex] = useState(0);
  const [variant, setVariant] = useState(avatarVariants.team);

  const handleTabChange = useCallback((_, index) => {
    setTabIndex(index);
    setVariant(tabs[index].variant);
  }, []);

  if (teamError) return `Error! ${teamError.message}`;
  if (departmentError) return `Error! ${departmentError.message}`;

  return (
    <AddMember
      {...props}
      variant={variant}
      onTabChange={handleTabChange}
      tabs={tabs}
      tabIndex={tabIndex}
      data={tabIndex === 0 ? teamData?.getMembersOftype : departmentData?.getMembersOftype}
      loading={teamLoading || departmentLoading}
    />
  );
};

export default DialogContainer;
