import { useCallback, useState } from 'react';
import axios from 'axios';
import useLogger from 'utils/useLogger';

const useUploadBySignedUrl = () => {
  const logger = useLogger('use upload by Signed url');
  const [progress, setProgress] = useState(0);
  const UploadBySignedUrl = useCallback(async (uploadUrl = '', fileRef) => {
    try {
      axios.put(uploadUrl, fileRef.current, {
        onUploadProgress: (ProgressEvent) => {
          const currentProgress = (ProgressEvent.loaded / ProgressEvent.total) * 100;
          Math.trunc(currentProgress) % 10 === 0 && setProgress(currentProgress);
        },
      });
    } catch (err) {
      logger.log(`$$ useUploadBySignedUrl -> From put file via axios :::${err}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [UploadBySignedUrl, progress];
};

export default useUploadBySignedUrl;
