import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, startOfDay, endOfDay } from 'date-fns';

import useDidMount from 'hooks/useDidMount';
import { useQuery } from '@apollo/client';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';
import GET_RUNDOWNS from 'graphql/queries/getRundowns';
import useCreateRundown from 'hooks/useCreateRundown';
import { formatDuration } from 'utils/formatDuration';
import memberTypes from 'graphql/memberTypes';
import useLogger from 'utils/useLogger';
import { formatDateToLocaleString } from 'utils/formatDate';
import { sortRundownsByStartTime } from 'utils/rundown/sortRundowns';
import { useAddContentTab } from 'store/tabs';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Group from 'components/listGroup';
import Divider from 'components/divider';

import Scrollbar from 'screens/main/components/scrollbar';
import image from 'assets/images/default/defaultThumbnail.png';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Popover from 'components/popover/Popover';
import CreateRundown from 'components/createNew';
import RundownListItem from '../listItem';
import useStyles from './group-styles';

const GroupContainer = ({ title, selectedDates, setPreviewRundown, canCreateNewRundown }) => {
  const classes = useStyles();
  const logger = useLogger('rundown-groupContainer');
  const addContentTab = useAddContentTab();

  const didMount = useDidMount();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [input, setInput] = useState(null);
  const [createRundown] = useCreateRundown();

  const { 0: sDate, length: l, [l - 1]: eDate } = selectedDates;
  const startDate = startOfDay(sDate).toISOString();
  const endDate = endOfDay(eDate).toISOString();

  const query = GET_RUNDOWNS_BY_PUBLISHING_DATE;
  const queryMasters = GET_RUNDOWNS;

  const variables = {
    input: {
      mType: 'rundown',
      startDate,
      endDate,
    },
  };

  const { data, error, loading } = useQuery(query, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: masterRundowns,
    error: error1,
    loading: loading1,
  } = useQuery(queryMasters, {
    variables: { mId: '' },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });
  const onInitializeRundown = (selectedRundown, anchor) => {
    setInput(selectedRundown);
    setAnchorEl(null);
    setAnchorE2(anchor);
  };

  const onCreate = async (newName) => {
    if (!input || !canCreateNewRundown) return;
    const newInput = input;
    newInput.mTitle = newName;
    const newRundown = await createRundown(newInput);
    setAnchorE2(null);
    setInput(null);

    if (!newRundown) return;
    const { mId: id, mTitle, mType, mPublishingAt } = newRundown;
    addContentTab({
      type: memberTypes.RUNDOWN,
      title: mTitle,
      id,
      mid: id,
      selectedDate: mPublishingAt,
      mType,
    });
  };

  useEffect(() => {
    if (didMount) {
      const elm = document.getElementById('scrollbar');
      if (elm) {
        setTimeout(() => {
          elm.scrollTop = 2;
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (error || error1)
    return (
      <div>
        {error && error.message}
        {error1 && (
          <>
            <br /> {error1.message}
          </>
        )}
      </div>
    );

  if (data && masterRundowns) {
    const { getRundownsByPublishingDate } = data;

    const getSortedRundowns = (rundowns) => {
      if (!Array.isArray(rundowns)) return [];
      if (rundowns.some((rd) => !rd.mTitle)) {
        logger.error(`Invalid rundowns: ${JSON.stringify(rundowns)}`);
        return [];
      }

      return rundowns.sort((a, b) => {
        if (a.mPublishingAt > b.mPublishingAt) return 1;
        if (a.mPublishingAt < b.mPublishingAt) return -1;

        const aTitle = a.mTitle.toLowerCase();
        const bTitle = b.mTitle.toLowerCase();
        if (aTitle > bTitle) return 1;
        if (aTitle < bTitle) return -1;
        return 0;
      });
    };

    const sortedRundowns = getSortedRundowns([...getRundownsByPublishingDate]);

    const getPublishingAt = (rundown) => {
      if (!rundown.mPublishingAt) return '00:00:00';
      return format(rundown.mPublishingAt, 'HH:mm:ss');
    };

    const getPlannedDuration = (rundown) => {
      const ret = rundown.mPlannedDuration ? formatDuration(rundown.mPlannedDuration) : 0;
      return ret;
    };

    const getUnInitializedRundowns = (masters, iRundows) => {
      let ret = [];
      if (!masters) return ret;
      const sortedMasterRundowns = sortRundownsByStartTime([...masters]);
      ret = [].concat(
        sortedMasterRundowns.filter(
          (obj1) =>
            iRundows.every((obj2) => obj1.mId !== obj2.mRundownTemplateId) ||
            !obj1.recurrence.dailyExclusive,
        ),
        iRundows.filter((obj2) =>
          sortedMasterRundowns.every((obj1) => obj2.mRundownTemplateId !== obj1.mId),
        ),
      );

      return ret;
    };

    const getGroupTitle = (groupTitle) => {
      let ftDate = formatDateToLocaleString(sDate.toISOString(), true);
      if (sDate !== eDate) ftDate += `  -  ${formatDateToLocaleString(eDate.toISOString(), true)}`;
      return `${groupTitle}   ${ftDate}`;
    };

    const unInitializedRundowns = getUnInitializedRundowns(
      masterRundowns ? masterRundowns.getRundowns : undefined,
      sortedRundowns,
    );

    return (
      <>
        <Group
          id="rundown"
          title={getGroupTitle(title)}
          type="left"
          handleCreateClicked={(e) => setAnchorEl(e.currentTarget.parentNode)}
          toolTipTitle="Create Rundown"
          hideCreateNewButton={!canCreateNewRundown}
        >
          {data ? (
            <List disablePadding>
              {sortedRundowns.map(
                (rundown) =>
                  rundown && (
                    <RundownListItem
                      key={`${rundown.mId}${rundown.mRefId}`}
                      title={rundown.mTitle}
                      image={rundown.mThumbnailUrl || image}
                      startTime={getPublishingAt(rundown)}
                      timeZone={rundown.recurrence && rundown.recurrence.timeZone}
                      plan={getPlannedDuration(rundown)}
                      id={rundown.mId}
                      rundowntype={rundown.mType}
                      state={rundown.mState}
                      selectedDates={selectedDates}
                      startDate={rundown.mPublishingAt}
                      setPreviewRundown={setPreviewRundown}
                    />
                  ),
              )}
            </List>
          ) : null}
        </Group>

        <Popover
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorEl={anchorEl}
          position="right"
        >
          <div className={classes.listContainer}>
            <Typography className={classes.heading}>Select master rundown</Typography>
            <Divider />
            <Divider />
            <List disablePadding className={classes.list}>
              <Scrollbar>
                <div>
                  {unInitializedRundowns.map(
                    (rundown) =>
                      rundown && (
                        <RundownListItem
                          key={rundown.mId}
                          title={rundown.mTitle}
                          image={rundown.mThumbnailUrl || image}
                          startTime={
                            (rundown.recurrence && rundown.recurrence.startTime) || '00:00:00'
                          }
                          timeZone={rundown.recurrence && rundown.recurrence.timeZone}
                          plan={rundown.recurrence.duration}
                          difference={rundown.difference}
                          id={rundown.mId}
                          selectedDates={selectedDates}
                          initializeRundown={(e) => onInitializeRundown(e, anchorEl)}
                        />
                      ),
                  )}
                </div>
              </Scrollbar>
            </List>
          </div>
        </Popover>

        <Popover onClose={() => setAnchorE2(null)} anchorEl={anchorE2} position="right">
          <CreateRundown
            variant={memberTypes.RUNDOWN}
            onCancel={() => setAnchorE2(null)}
            onCreate={onCreate}
            defaultTitle={input ? input.mTitle : 'rundown title'}
          />
        </Popover>
      </>
    );
  }

  if (loading || loading1) return <LoadingIndicator />;

  return null;
};

GroupContainer.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** selected dates */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** set the preview rundown */
  setPreviewRundown: PropTypes.func,
  /** Boolean that allows creation of new rundown */
  canCreateNewRundown: PropTypes.bool,
};

GroupContainer.defaultProps = {
  title: 'Group',
  selectedDates: [],
  setPreviewRundown: () => {},
  canCreateNewRundown: true,
};

export default GroupContainer;
