import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import Divider from 'components/divider';
import Popover from 'components/popover';
import CreateNew from 'components/createNew';
import Tooltip from 'components/tooltip';
import useHover from 'hooks/useHover';
import { ReactComponent as Checked } from 'assets/icons/systemicons/statusIndicators/isSelected.svg';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/plus_small.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as PinOnIcon } from 'assets/icons/systemicons/pin_on.svg';
import { ReactComponent as PinOffIcon } from 'assets/icons/systemicons/pin_off.svg';
import { currentSearchTypes } from 'utils/constants/searchbar';
import { BookmarkIcon } from '../icons';
import getTooltipTitleFromModel from '../../utils/getTooltipTitleFromModel';
import useStyles from './savedSearches-styles';

const PinIcon = ({ isPinned = false, onDelete = () => {}, onTogglePin = () => {} }) => {
  const classes = useStyles();

  const [hoverRef, isHovered] = useHover();

  const [PinnedIcon, HoverPinnedIcon, title, className] = isPinned
    ? [PinOnIcon, PinOffIcon, 'Unpin filter', classes.iconChild]
    : [PinOffIcon, PinOnIcon, 'Pin filter', classes.closeIcon];

  const handleDelete = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onDelete();
  };

  const handleTogglePin = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onTogglePin();
  };

  return (
    <>
      <Tooltip title="Remove saved search">
        <DeleteIcon
          className={`${classes.closeIcon} ${classes.deleteIcon}`}
          onClick={handleDelete}
        />
      </Tooltip>
      <Tooltip title={title}>
        <div ref={hoverRef} className={classes.pinOnOff}>
          {isHovered ? (
            <HoverPinnedIcon className={className} onClick={handleTogglePin} />
          ) : (
            <PinnedIcon className={className} onClick={handleTogglePin} />
          )}
        </div>
      </Tooltip>
    </>
  );
};

const SavedSearches = ({
  history,
  savedFilters,
  currentSearch,
  updateCurrentSearch,
  onSelectSearch,
  onCreateFilter,
  onUpdateFilters,
  isEmptyModel,
  hideSavedFilters,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const closePopover = () => {
    setAnchorEl(null);
  };

  const pinnedFilters = [];
  const unpinnedFilters = [];

  savedFilters.forEach((savedFilter) => {
    if (savedFilter.isPinned) pinnedFilters.push(savedFilter);
    else unpinnedFilters.push(savedFilter);
  });

  const deleteFilter = (filter) => {
    onUpdateFilters(savedFilters.filter((savedFilter) => savedFilter !== filter));
  };

  const handleCreateNewFilter = (label) => {
    onCreateFilter(label);
    setAnchorEl(null);
  };
  const handleTogglePin = (filter) => {
    const newFilter = { label: filter?.label, model: filter?.model, isPinned: !filter?.isPinned };
    const newSavedFilters = [
      ...savedFilters.filter((savedFilter) => savedFilter !== filter),
      newFilter,
    ];
    onUpdateFilters(newSavedFilters);
  };

  return (
    <div className={classes.savedSearchRoot}>
      <div className={classes.scrollDiv}>
        <div className={classes.header}>RECENT SEARCHES</div>
        <Divider />
        {history.length > 0 ? (
          history.map((search, index) => {
            const isSelected =
              currentSearch?.type === currentSearchTypes.HISTORY &&
              currentSearch?.identification === index;
            return (
              <ListItem
                excludeDivider
                maxHeight="40px"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                text={getTooltipTitleFromModel(search)}
                iconChild={isSelected ? <Checked className={classes.iconChild} /> : undefined}
                selected={isSelected}
                tooltipTitle={getTooltipTitleFromModel(search)}
                onClick={() => {
                  updateCurrentSearch(currentSearchTypes.HISTORY, index, search);
                  onSelectSearch(search);
                }}
                whiteSpace="nowrap"
              />
            );
          })
        ) : (
          <ListItem text="No recent searches" disabled excludeDivider maxHeight="40px" />
        )}
        {!hideSavedFilters && (
          <>
            <Divider />
            {pinnedFilters.length > 0 && (
              <>
                <div className={classes.header}>PINNED FILTER SHORTCUTS</div>
                {pinnedFilters.map((filter) => (
                  <ListItem
                    excludeDivider
                    maxHeight="40px"
                    key={filter?.label}
                    text={filter?.label}
                    tooltipTitle={getTooltipTitleFromModel(filter?.model)}
                    firstChild={
                      <div className={classes.leadingIcon}>
                        <BookmarkIcon />
                      </div>
                    }
                    iconChild={
                      <PinIcon
                        isPinned
                        onDelete={() => {
                          deleteFilter(filter);
                        }}
                        onTogglePin={() => {
                          handleTogglePin(filter);
                        }}
                      />
                    }
                    onClick={() => {
                      updateCurrentSearch(
                        currentSearchTypes.SAVED_FILTERS,
                        filter?.label,
                        filter?.model,
                      );
                      onSelectSearch(filter?.model);
                    }}
                    className={classes.savedListItem}
                    whiteSpace="nowrap"
                    selected={
                      currentSearch?.type === currentSearchTypes.SAVED_FILTERS &&
                      currentSearch?.identification === filter?.label
                    }
                  />
                ))}
              </>
            )}
            <div className={classes.header}>FILTER SHORTCUTS</div>
            {unpinnedFilters.length > 0 ? (
              unpinnedFilters.map((filter) => (
                <ListItem
                  excludeDivider
                  maxHeight={40}
                  key={filter?.label}
                  text={filter?.label}
                  tooltipTitle={getTooltipTitleFromModel(filter?.model)}
                  firstChild={
                    <div className={classes.leadingIcon}>
                      <BookmarkIcon />
                    </div>
                  }
                  iconChild={
                    <PinIcon
                      onDelete={() => {
                        deleteFilter(filter);
                      }}
                      onTogglePin={() => {
                        handleTogglePin(filter);
                      }}
                    />
                  }
                  onClick={() => {
                    updateCurrentSearch(
                      currentSearchTypes.SAVED_FILTERS,
                      filter?.label,
                      filter?.model,
                    );
                    onSelectSearch(filter?.model);
                  }}
                  className={classes.savedListItem}
                  whiteSpace="nowrap"
                  selected={
                    currentSearch?.type === currentSearchTypes.SAVED_FILTERS &&
                    currentSearch?.identification === filter?.label
                  }
                />
              ))
            ) : (
              <ListItem text="No saved filters" disabled excludeDivider maxHeight="40px" />
            )}
          </>
        )}
      </div>
      {!hideSavedFilters && (
        <>
          <Divider />
          <div className={classes.header}>
            Save as new Search Bookmark
            <span className={classes.link}>What’s saved?</span>
          </div>
          <ListItem
            excludeDivider
            maxHeight={40}
            text="Save current search as Bookmark"
            firstChild={
              <div className={classes.leadingIcon}>
                <AddIcon />
              </div>
            }
            onClick={(event) => setAnchorEl(event.currentTarget)}
            disabled={isEmptyModel}
          />
        </>
      )}
      <Popover onClose={closePopover} anchorEl={anchorEl}>
        <CreateNew onCancel={closePopover} onCreate={handleCreateNewFilter} variant="feedFilter" />
      </Popover>
    </div>
  );
};

SavedSearches.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape()),
  savedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  currentSearch: PropTypes.shape({
    type: PropTypes.string,
    identification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    model: PropTypes.PropTypes.shape(),
  }),
  onSelectSearch: PropTypes.func,
  onCreateFilter: PropTypes.func,
  onUpdateFilters: PropTypes.func,
  isEmptyModel: PropTypes.bool,
  updateCurrentSearch: PropTypes.func,
  hideSavedFilters: PropTypes.bool,
};

SavedSearches.defaultProps = {
  history: [],
  savedFilters: [],
  currentSearch: undefined,
  onSelectSearch: () => {},
  onCreateFilter: () => {},
  onUpdateFilters: () => {},
  isEmptyModel: false,
  updateCurrentSearch: () => {},
  hideSavedFilters: false,
};

export default SavedSearches;
