import React, { createContext, useCallback, useEffect, useState } from 'react';

export const EditorContext = createContext();

const key = 'contentLockInfo';
const EditorProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify({ mId: null, mType: null, locked: null }));
  }, []);

  const setEditorLockedData = useCallback((data) => {
    window.localStorage.getItem(key, JSON.stringify(data));
  }, []);

  const getEditorLockedData = useCallback(() => {
    const data = window.localStorage.getItem(key);
    return data ? JSON.parse(data) : {};
  }, []);

  return (
    <EditorContext.Provider
      value={[
        isModalOpen,
        setIsModalOpen,
        // editorLockedData,
        getEditorLockedData,
        setEditorLockedData,
      ]}
    >
      {children}
    </EditorContext.Provider>
  );
};

export { EditorProvider };
