import React, { useState, memo } from 'react';
import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double.svg';
import { ReactComponent as CloseSmallIcon } from 'assets/icons/systemicons/close_small.svg';
import { IconButton } from '@material-ui/core';
import { Autocomplete, Paper, Input, EndAdornment } from './autocomplete-styled';

const CustomAutocomplete = ({ options, initialValue, onChange }) => {
  const current = options.find((option) => option.name === initialValue);
  const [value, setValue] = useState(current || null);

  const defaultProps = {
    options,
    getOptionLabel: (option) => option.name,
    getOptionSelected: (option, val) => option.name === val.name,
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    if (newValue === null) {
      onChange(null);
      setValue(null);
    } else {
      onChange(newValue.name);
      setValue(newValue);
    }
  };
  return (
    <Autocomplete
      {...defaultProps}
      id="custom-autocomplete"
      options={options}
      value={value}
      autoHighlight
      onChange={handleChange}
      PaperComponent={Paper}
      renderInput={(params) => {
        const endAdornmentProp = params.InputProps.endAdornment.props.children;
        const handleClose = endAdornmentProp[0].props.onClick;
        const handlePopperOpen = endAdornmentProp[1].props.onClick;
        return (
          <Input
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            fullWidth
            endAdornment={
              <EndAdornment>
                {value !== null && (
                  <IconButton tabIndex="-1" size="small" onClick={handleClose}>
                    <CloseSmallIcon />
                  </IconButton>
                )}
                {
                  <IconButton tabIndex="-1" size="small" onClick={handlePopperOpen}>
                    <ArrowDoubleIcon />
                  </IconButton>
                }
              </EndAdornment>
            }
          />
        );
      }}
    />
  );
};

export default memo(CustomAutocomplete);
