import voidTypes from 'components/editor/constants/types/voidTypes';
import { Editor } from 'slate';
import insertParagraph from 'components/editor/components/paragraph/utils/insertParagraph';
import matchRestriction from './matchRestriction';

const voidTypeValues = Object.values(voidTypes);

/**
 * Handles onKeyDown event on void elements
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const onVoidKeyDown = (editor, event, variant, isAllowed, isCmsBlock) => {
  try {
    const [match] = Editor.nodes(editor, {
      match: ({ type }) => voidTypeValues.includes(type),
    });

    if (match) {
      const { key } = event;
      const isEnter = key === 'Enter';
      const isBackspace = key === 'Backspace';
      const shouldPrevent = !isCmsBlock && isAllowed && matchRestriction(variant);

      if (isBackspace && shouldPrevent) event.preventDefault();

      if (isEnter && !shouldPrevent) {
        event.preventDefault();
        insertParagraph(editor);
      }
    }
  } catch (err) {
    // console.log(err);
  }

  return editor;
};

export default onVoidKeyDown;
