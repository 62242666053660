import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const LeafWrapper = styled('span')`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-decoration: ${({ underline, strikeThrough }) => {
    if (underline) return 'underline';
    if (strikeThrough) return 'line-through';
    return 'none';
  }};
  color: ${({ color }) => color || 'inherit'};
`;

const Leaf = ({ attributes, children, leaf }) => (
  <LeafWrapper {...leaf} {...attributes}>
    {children}
  </LeafWrapper>
);

Leaf.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
};

Leaf.defaultProps = {
  attributes: {},
  children: null,
};

export default memo(Leaf);
