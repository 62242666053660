const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const fillRange = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((item, index) => start + index);

const generateMonths = (currentYear) => {
  const years = fillRange(currentYear - 5, currentYear + 5);

  const months = years.flatMap((year) => {
    const monthsInYear = monthNames.map((monthName, index) => ({
      monthIndex: index,
      monthName,
      year,
    }));

    return monthsInYear;
  });

  return months;
};

export default generateMonths;
