import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import TitleInput from '../titleInput';
import { ButtonsWrapper, IconButton, Title, TitleWrapper } from './titleBox-styled';

const TitleBox = ({
  initialTitle,
  instanceId,
  onTitleUpdate,
  onDock,
  onClose,
  disableEdit,
  showCloseButton,
  showOpenStoryButton,
  onOpenStory,
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [showTitleInput, setShowTitleInput] = useState(false);

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);

  useEffect(() => {
    setShowTitleInput(false);
  }, [instanceId]);

  const handleShowInput = (event) => {
    event.preventDefault();
    event.stopPropagation();
    !disableEdit && setShowTitleInput(!showTitleInput);
  };

  const handleCloseInput = (event) => {
    setShowTitleInput(!showTitleInput);
  };

  const onClickAway = () => {
    setTitle(initialTitle);
    setShowTitleInput(!showTitleInput);
  };

  if (showTitleInput)
    return (
      <TitleInput
        title={title}
        setTitle={setTitle}
        initialTitle={initialTitle}
        onClickAway={onClickAway}
        onClose={handleCloseInput}
        onUpdate={onTitleUpdate}
      />
    );

  return (
    <>
      <TitleWrapper>
        <Title onClick={handleShowInput} disableEdit={disableEdit}>
          {title}
        </Title>
        <ButtonsWrapper>
          {showOpenStoryButton && (
            <IconButton onClick={onOpenStory} size="small">
              <Open />
            </IconButton>
          )}
          {showCloseButton && (
            <IconButton onClick={onClose} size="small">
              <Close />
            </IconButton>
          )}
        </ButtonsWrapper>
      </TitleWrapper>
      <Divider />
    </>
  );
};

TitleBox.propTypes = {
  /** Initial title for the instance */
  initialTitle: PropTypes.string,
  /** mId of the instance */
  instanceId: PropTypes.string,
  /** onTitleUpdate function */
  onTitleUpdate: PropTypes.func,
  /** onDock callback: if not defined won't show */
  onDock: PropTypes.func,
  /** onClose callback: will always show */
  onClose: PropTypes.func,
};

TitleBox.defaultProps = {
  initialTitle: '',
  instanceId: '',
  onTitleUpdate: () => {},
  onDock: undefined,
  onClose: () => {},
};

export default TitleBox;
