import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItemText } from '@material-ui/core';
import Popover from 'components/popover';
import Delete from 'assets/icons/systemicons/delete_small.svg';
import Folder from 'assets/icons/systemicons/folder.svg';
import Arrow from 'assets/icons/systemicons/arrows/disclosurearrow_right_opaque.svg';
import ConfirmationComponent from '../linearEllipsisMenu/components/confirmationComponent';
import { OptionsWrapper, DeleteImage, MenuItem, IconWrapper, useStyles } from './menu-item-styled';

const MenuItemFolder = ({
  anchorEl,
  label,
  data,
  onClick,
  image,
  secondaryItem,
  showSecondaryItem,
  disabled,
  showDeleteButton,
  onDeleteButtonClick,
  isMuted,
  selected,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const handleDeleteButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDialog(true);
  };

  const handleDialogClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDialog(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onDeleteButtonClick();
    handleDialogClose(event);
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(event);
  };

  return (
    <>
      <MenuItem
        onClick={handleClick}
        $isMuted={isMuted}
        title={label}
        disabled={disabled}
        selected={selected}
      >
        <IconWrapper>{image ? <img src={image} alt="" /> : <></>}</IconWrapper>
        <ListItemText
          primary={label}
          title={disabled ? 'Not implemented yet' : label}
          classes={{ primary: classes.primaryText }}
        />
        {(showSecondaryItem && secondaryItem) ||
          (data && (
            <OptionsWrapper>
              {data.length > 0 && data.length}
              {showDeleteButton && (
                <DeleteImage
                  role="presentation"
                  src={Delete}
                  alt="delete"
                  title="Delete Folder"
                  onClick={handleDeleteButtonClick}
                />
              )}
              <img src={Arrow} alt="" />
            </OptionsWrapper>
          ))}
      </MenuItem>
      {openDialog && (
        <Popover anchorEl={anchorEl} onClose={handleDialogClose} noMargin>
          <ConfirmationComponent label={label} onOk={handleConfirm} onCancel={handleDialogClose} />
        </Popover>
      )}
    </>
  );
};

MenuItemFolder.propTypes = {
  /** name of menuItem */
  label: PropTypes.string,
  /** contents of menuItem */
  data: PropTypes.arrayOf(PropTypes.any),
  /** callback to open menuItem folder */
  onClick: PropTypes.func,
  /** image of menuItem */
  image: PropTypes.string,
  /** Secondary Action item */
  secondaryItem: PropTypes.node,
  /** Boolean to show secondary action item */
  showSecondaryItem: PropTypes.bool,
  /** Boolean to disable the MenuItem */
  disabled: PropTypes.bool,
  /** Boolean to show the delete button */
  showDeleteButton: PropTypes.bool,
  /** Callback to be invoked when delete is clicked */
  onDeleteButtonClick: () => {},
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** boolean to reduce the opacity to half */
  isMuted: PropTypes.bool,
  /** boolean to indicate a selected item */
  selected: PropTypes.bool,
};

MenuItemFolder.defaultProps = {
  label: '',
  data: [],
  onClick: () => {},
  image: Folder,
  secondaryItem: null,
  showSecondaryItem: true,
  disabled: false,
  showDeleteButton: false,
  onDeleteButtonClick: () => {},
  anchorEl: null,
  isMuted: false,
  selected: false,
};

export default MenuItemFolder;
