import styled from '@emotion/styled';
import theme from 'theme/theme';
import { IconButton } from '@material-ui/core';
import { caption } from 'theme/styledTypography';
import { ReactComponent as DescriptionIconComponent } from 'assets/icons/systemicons/editor/description.svg';

export const DescriptionIcon = styled(DescriptionIconComponent)`
  margin: 8px 6px;
`;

export const TextAreaWrapper = styled('div')`
  margin-bottom: ${(props) => !props.isTwitter && '16px'};
`;

export const SubDescription = styled('span')`
  color: ${theme.palette.dina.statusWarning};
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const CheckboxWrapper = styled('div')`
  display: flex;
  height: 26px;
  margin-top: 4px;
  margin-right: 8px;
  opacity: ${({ isExceeded }) => (!isExceeded ? 0.35 : 1)};
  p {
    ${caption}
    width:122px;
    text-align: right;
    margin: 0;
  }

  svg {
    margin-left: 4px;
  }
`;

export const PlusButton = styled(IconButton)`
  width: 56px;
  height: 32px;
  border-radius: 4px;
  border: 0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis};
  background: ${theme.palette.dina.blackHoverOverlay};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 8px;
  :disabled {
    opacity: 0.35;
  }
`;
