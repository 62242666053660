import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
  },
  messageInput: {
    margin: '8px',
  },
  messageContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  mask: {
    position: 'absolute',
    background: 'linear-gradient(180deg, #323E4D 0%, rgba(50, 62, 77, 0) 100%)',
    left: 0,
    top: 0,
    height: '24px',
    right: 0,
    zIndex: 1,
  },
}));

export default useStyles;
