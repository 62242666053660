import styled from '@emotion/styled/macro';
import { caption, captionMedium } from 'theme/styledTypography';
import theme from 'theme/theme';

export const UnorderedList = styled('ul')`
  margin: 0;
  height: auto;
  padding: 16px 0 10px 0;
`;

export const ListItem = styled('li')`
  display: flex;
  justify-content: row;
  margin-bottom: 6px;
`;

export const Key = styled('p')`
  ${captionMedium}
  margin: 0;
  color: ${theme.palette.dina.highEmphasis};
  width: 112px;
  margin-right: 8px;
  overflow-wrap: break-word;
  text-align: right;
`;

export const Value = styled('p')`
  ${caption}
  margin: 0;
  color: ${theme.palette.dina.highEmphasis};
  max-width: 240px;
  overflow-wrap: break-word;
`;
