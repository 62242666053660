import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  addDays,
  addWeeks,
  addMonths,
  addYears,
  subDays,
  subWeeks,
  subMonths,
  subYears,
} from 'date-fns';

import { timeVariants } from 'utils/planningViews';
import ArrowNavigator from 'components/arrowNavigator';

const FUNCTION_ENUM = {
  [timeVariants.DAY]: {
    increment: addDays,
    decrement: subDays,
  },
  [timeVariants.WEEK]: {
    increment: addWeeks,
    decrement: subWeeks,
  },
  [timeVariants.MONTH]: {
    increment: addMonths,
    decrement: subMonths,
  },
  [timeVariants.YEAR]: {
    increment: addYears,
    decrement: subYears,
  },
};

const TimeNavigator = (props) => {
  const { timeVariant, time, onChange, buttonHeight, buttonWidth, resetTitle } = props;

  const handleChange = (changedTime) => {
    onChange(changedTime.toISOString());
  };

  const handleIncrement = () => {
    handleChange(FUNCTION_ENUM[timeVariant].increment(time, 1));
  };

  const handleReset = () => {
    onChange(new Date().toISOString());
  };

  const handleDecrement = () => {
    handleChange(FUNCTION_ENUM[timeVariant].decrement(time, 1));
  };

  return (
    <ArrowNavigator
      handleDecrement={handleDecrement}
      handleIncrement={handleIncrement}
      handleReset={handleReset}
      resetTitle={resetTitle}
      buttonHeight={buttonHeight}
      buttonWidth={buttonWidth}
    />
  );
};

TimeNavigator.propTypes = {
  /** Variant of time navigator,
   *  defines time resolution
   */
  timeVariant: PropTypes.oneOf(['date', 'week', 'month', 'year']).isRequired,
  /** Initial time assigned to the components */
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Callback to be invoked when navigator's button clicked */
  onChange: PropTypes.func,
  /** Height of button */
  buttonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Width of button */
  buttonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Title of reset button */
  resetTitle: PropTypes.string,
};

TimeNavigator.defaultProps = {
  time: new Date().toISOString(),
  onChange: () => {},
  buttonHeight: 32,
  buttonWidth: 32,
  resetTitle: 'Today',
};

export default memo(TimeNavigator);
