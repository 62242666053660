import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import Checkbox from 'components/checkbox';
import Divider from 'components/divider';
import Footer from '../footer';
import useStyles from './platform-styles';

const Platforms = ({ filters, updateFilters, onClose, platforms }) => {
  const classes = useStyles();

  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    setSelectedFilters(filters);
  }, [filters]);

  const togglePlatform = (platform) => {
    if (selectedFilters.find((selectedFilter) => selectedFilter.platform === platform.platform)) {
      setSelectedFilters(
        selectedFilters.filter((selectedFilter) => selectedFilter.platform !== platform.platform),
      );
    } else {
      setSelectedFilters([...selectedFilters, platform]);
    }
  };

  const clearSelectedFilters = () => {
    setSelectedFilters([]);
  };

  const handleUpdateFilters = () => {
    updateFilters(selectedFilters);
    onClose();
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>Choose Platforms to show</div>
      <Divider />
      {platforms.map((platform) => (
        <FormControlLabel
          key={platform.mProperties.platform}
          control={
            <Checkbox
              selected={
                !!selectedFilters.find(
                  (selectedFilter) => selectedFilter.platform === platform.mProperties.platform,
                )
              }
              onClick={() => togglePlatform(platform.mProperties)}
              size={24}
            />
          }
          label={platform.mProperties.platform}
          classes={{
            root: classes.formControlRoot,
            label: classes.formControlLabel,
          }}
        />
      ))}
      <Divider />
      <ListItem
        excludeDivider
        maxHeight="40px"
        text="Clear selected items"
        onClick={clearSelectedFilters}
        disabled={selectedFilters.length === 0}
      />
      <Divider />
      <Footer
        onCancel={onClose}
        onOk={handleUpdateFilters}
        isOkDisabled={
          filters.length === selectedFilters.length &&
          filters.every((filter) =>
            selectedFilters.find((selectedFilter) => selectedFilter.platform === filter.platform),
          )
        }
      />
    </div>
  );
};

Platforms.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      platform: PropTypes.string,
      accounts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  updateFilters: PropTypes.func,
  onClose: PropTypes.func,
  platforms: PropTypes.arrayOf(PropTypes.shape({})),
};

Platforms.defaultProps = {
  filters: [],
  updateFilters: () => {},
  onClose: () => {},
  platforms: [],
};

export default Platforms;
