import styled from '@emotion/styled';
import { h7 } from 'theme/styledTypography';
import {
  DialogActions,
  DialogContent as MaterialDialogContent,
  Typography,
} from '@material-ui/core';

export const InputWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 8px;
`;

export const MaterialDialogWrapper = styled(MaterialDialogContent)`
  padding: 0px;
  width: 100%;

  &:first-child {
    padding-top: 15px;
  }
`;

export const Heading = styled(Typography)`
  ${h7}
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
`;

export const StyledInputFieldWrapper = styled('div')`
  width: 100%;
  flex-grow: 100;
`;

export const IconWrapper = styled('div')`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const StyledDialogActions = styled(DialogActions)`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
