import React from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import colors from 'theme/colors';
import { listItemLabelMedium } from 'theme/styledTypography';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 24px;
  background-color: ${colors.buttonBackgroundHighEmphasis};
  position: relative;
  z-index: 1201;
`;

const Title = styled(Typography)`
  ${listItemLabelMedium};
  height: 16px;
`;

const AppTitle = ({ appTitle }) => {
  if (!appTitle) return null;

  return (
    <Wrapper>
      <Title display="inline">{appTitle}</Title>
    </Wrapper>
  );
};

export default AppTitle;
