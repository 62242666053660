import styled from '@emotion/styled';
import theme from 'theme/theme';
import { overline } from 'theme/styledTypography';
import colors from 'theme/colors';

export const RootWrapper = styled('div')`
  width: 100%;
  height: calc(100% - 135px);
`;

export const TableHead = styled('div')`
  display: flex;
  height: 40px;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  background: ${colors.backgroundResting};
  align-items: center;
  justify-content: center;
  ${overline};
`;

export const PermissionHeader = styled('div')`
  flex: 1;
  margin-left: 10px;
`;

export const ResourceTypeHeader = styled('div')`
  width: 160px;
  flex-shrink: 0;
`;

export const TableBody = styled('div')`
  align-items: center;
  justify-content: center;
`;
