import { isToday, differenceInCalendarYears, isYesterday, format, isTomorrow } from 'date-fns';

const getFormattedPublishedDate = (publishedDate) => {
  if (differenceInCalendarYears(new Date(), publishedDate) > 0)
    return `${format(publishedDate, 'ddd D MMM YY')}`;

  if (isToday(new Date(publishedDate))) return `Today / ${format(publishedDate, 'HH:mm')}`;

  if (isTomorrow(new Date(publishedDate))) return `Tomorrow / ${format(publishedDate, 'HH:mm')}`;

  if (isYesterday(new Date(publishedDate))) return `Yesterday / ${format(publishedDate, 'HH:mm')}`;

  return `${format(publishedDate, 'D MMM / HH:mm')}`;
};

export default getFormattedPublishedDate;
