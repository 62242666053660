import { useCallback } from 'react';
import insertParagraph from 'components/editor/components/paragraph/utils/insertParagraph';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';

const { findPath, focus } = ReactEditor;
const { select, removeNodes, insertNodes } = Transforms;

const useDragEnd = () => {
  const editor = useSlate();

  const onDragEnd = useCallback(
    (source, destination) => {
      const sourcePath = findPath(editor, source);
      const destinationPath = findPath(editor, destination);
      const { children: editorChildren } = editor;

      if (destinationPath) {
        const [sourceIndex] = sourcePath;
        const [destinationIndex] = destinationPath;

        const dropIndex =
          sourceIndex < destinationIndex + 1 ? destinationIndex : destinationIndex + 1;

        if (sourceIndex === dropIndex) return;

        removeNodes(editor, { at: sourcePath });
        const dropElement = editorChildren[dropIndex];
        const dropPath = findPath(editor, dropElement);
        if (dropIndex === editorChildren.length - 1) {
          insertParagraph(editor, { at: dropPath, mode: 'highest' });
        }
        insertNodes(editor, source, { at: dropPath });
        select(editor, dropPath);
        focus(editor);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [onDragEnd];
};

export default useDragEnd;
