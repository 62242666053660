const notifyConfig = {
  usr_str: { notify: true, currentUserOnly: true },
  tea_str: { notify: false, currentUserOnly: false },
  dep_str: { notify: false, currentUserOnly: false },
  dep_tea: { notify: false, currentUserOnly: false },
  str_ins: { notify: false, currentUserOnly: false },
  usr_ins: { notify: true, currentUserOnly: true },
  tea_usr: { notify: true, currentUserOnly: true },
  dep_usr: { notify: true, currentUserOnly: true },
  usr_pitch: { notify: true, currentUserOnly: true },
  con_usr: { notify: true, currentUserOnly: false },
  message: { notify: true, currentUserOnly: false },
};

export default notifyConfig;
