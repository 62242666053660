import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import { avatarVariants } from 'components/avatar/Avatar';
import theme from 'theme/theme';
import { css } from '@emotion/react';
import transientOptions from 'theme/helpers';
import { chipLabel, durationLabel, listItemLabel } from 'theme/styledTypography';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close_small.svg';

const autoWidthStyle = css`
  margin-right: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
`;
const fixedWidthStyle = css`
  width: ${({ $showAvatar }) => `calc(100% - ${$showAvatar ? 64 : 32}px)`};
`;

export const AvatarText = styled('div')`
  ${durationLabel}
  color: ${({ variant }) => {
    if (variant === avatarVariants.team) return theme.palette.dina.blackHighEmphasis;
    if (variant === avatarVariants.department) return theme.palette.dina.highEmphasis;
    return theme.palette.dina.mediumEmphasis;
  }};
`;

export const AvatarWrapper = styled('div')`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

export const ChipLabel = styled('p', transientOptions)`
  ${({ $labelType }) => {
    if ($labelType === 'listItemLabel') return listItemLabel;
    return chipLabel;
  }}
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 8px;
  padding: 0;
  ${({ $width }) => {
    if ($width === 'auto') return autoWidthStyle;
    return fixedWidthStyle;
  }};
`;

export const ChipWrapper = styled('div')`
  border-radius: 4px;
  border: 1px solid ${theme.palette.dina.buttonBorderMediumEmphasis};
  background-color: ${theme.palette.dina.blackHoverOverlay};
  display: flex;
  align-items: center;
  width: ${(props) => {
    if (props.width === 'auto') return `auto`;
    return `${props.width}px`;
  }};
  height: ${(props) => `${props.height}px`};
  :hover,
  :focus {
    background-color: ${theme.palette.dina.whiteHoverOverlay};
    .deleteButton {
      opacity: 1;
    }
  }
`;

export const DeleteButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 6px;
    margin-right: 6px;
    opacity: 0;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const Icon = styled(CloseIcon)`
  &:hover {
    & path {
      fill-opacity: 1;
    }
  }
`;
