import React from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'components/scrollbar/scrollbar';
import {
  TableHead,
  PermissionHeader,
  ResourceTypeHeader,
  RootWrapper,
  TableBody,
} from './groupPermissions-styled';
import Row from './Row/row-view';
import { useSelectedLeftMenu } from '../../../../atoms';
import getGroupPermissions from './utils';

const GroupPermissions = (props) => {
  const { groupPolicy } = props;
  const [selectedLeftMenu] = useSelectedLeftMenu();

  const permissionRowsFromConfig = getGroupPermissions(groupPolicy, selectedLeftMenu);

  return (
    <RootWrapper>
      <TableHead>
        <PermissionHeader>Permission</PermissionHeader>
        <ResourceTypeHeader>Resource Type</ResourceTypeHeader>
      </TableHead>
      <Scrollbar>
        <TableBody>
          {permissionRowsFromConfig.map((row) => (
            <Row key={row.id} row={row} selectedGroupRefId={selectedLeftMenu} />
          ))}
        </TableBody>
      </Scrollbar>
    </RootWrapper>
  );
};

GroupPermissions.propTypes = {
  groupPolicy: PropTypes.object,
};

GroupPermissions.defaultProps = {
  groupPolicy: [],
};

export default GroupPermissions;
