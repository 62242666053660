import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { InputBase, Paper as MuiPaper } from '@material-ui/core';
import theme from 'theme/theme';
import styled from '@emotion/styled';
import { instanceHeaderTitle, listItemLabel } from 'theme/styledTypography';

export const Autocomplete = styled(MuiAutocomplete)`
  .MuiAutocomplete-inputFocused {
    padding: 0 0 0 4px;
  }
`;

export const Paper = styled(MuiPaper)`
  background-color: ${theme.palette.dina.surfaceDialogs};
  .MuiAutocomplete-option {
    ${instanceHeaderTitle};
    font-weight: normal;
    color: ${theme.palette.dina.mediumEmphasis};
    &[aria-selected='true'] {
      background-color: ${theme.palette.dina.onSelected};
    }
    &[data-focus='true']:not([aria-selected='true']) {
      background-color: ${theme.palette.dina.statusOnHover};
    }
  }
  .MuiAutocomplete-noOptions {
    ${listItemLabel};
    font-weight: normal;
    color: ${theme.palette.dina.disabled};
  }
`;

export const Input = styled(InputBase)`
  ${listItemLabel};
  font-weight: normal;
  height: 31px;
  background: transparent;
  border: 1px solid ${theme.palette.dina.inputBorderResting};
  outline: none;
  :hover {
    transition: 0.2s ease-in;
    border: 1px solid ${theme.palette.dina.inputBorderResting};
    background-color: ${theme.palette.dina.whiteHoverOverlay};
  }
`;

export const EndAdornment = styled('div')`
  display: flex;
`;
