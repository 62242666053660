import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@material-ui/core';
import OpenSrc from 'assets/icons/systemicons/open.svg';
import useStyles from './header-base-styles';
import EllipsisMenu from '../ellipsisMenu';

const HeaderBaseView = ({
  children,
  menuItems,
  onMenuItemClick,
  hideMenu,
  onLinkClick,
  isSummary,
  headerTitle,
}) => {
  const classes = useStyles();

  const sidebarHeader = (
    <div className={classes.sidebarHeader}>
      <Typography className={classes.sidebarHeaderText}>{headerTitle}</Typography>
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children || sidebarHeader}</div>
      {!hideMenu && !isSummary && <EllipsisMenu {...{ menuItems, onMenuItemClick }} />}
      {isSummary && (
        <IconButton onClick={onLinkClick}>
          <img src={OpenSrc} alt="open link" />
        </IconButton>
      )}
    </div>
  );
};

HeaderBaseView.propTypes = {
  /** children items that should be displayed before the ellipsis, i.e back button */
  children: PropTypes.node,
  /** text that should be shown left of the ellipsis */
  headerTitle: PropTypes.string,
  /** Items to be shown as popover options on ellipsis click */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  /** Callback to be invoked on menu item select,
   * with the action type passed in as argument
   */
  onMenuItemClick: PropTypes.func,
  /** If true the ellipsis menu is not shown */
  hideMenu: PropTypes.bool,
};

HeaderBaseView.defaultProps = {
  headerTitle: '',
  menuItems: [],
  onMenuItemClick: () => {},
  hideMenu: false,
  children: null,
};

export default HeaderBaseView;
