import { markTypes, elementTypes } from 'components/editor/constants';

const {
  ACCESSORY,
  ADLIB,
  AUDIO,
  BREAK,
  CAMERA,
  DVE,
  FULLSCREEN_GRAPHICS,
  HEADING_FOUR,
  HEADING_ONE,
  HEADING_THREE,
  HEADING_TWO,
  JINGLE,
  LINK,
  LIVE,
  ORDERED_LIST,
  PACKAGE,
  TELEPHONE,
  UNORDERED_LIST,
  VOICE_OVER,
  OVERLAY_GRAPHICS,
  GIF,
  IMAGE,
  VIDEO,
  SECTION,
  TITLE,
  QUOTE_BOX,
  FACT,
  FEED,
  EMBEDDED_TWEET,
  HTML,
  EMBEDDED_VIDEO,
  CONTENT,
  ABSTRACT,
  OVERLINE,
  TAGS,
  AUTHOR,
  SOURCE,
  LIVE_STREAM,
  NOTIFICATION,
  PHOTO_GALLERY,
} = elementTypes;

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const tooltipEnum = {
  [ACCESSORY]: 'Accessory',
  [ADLIB]: 'Adlib',
  [AUDIO]: 'Audio',
  [BOLD]: 'Bold',
  [BREAK]: 'Break',
  [CAMERA]: 'Camera',
  [DVE]: 'DVE',
  [FULLSCREEN_GRAPHICS]: 'Fullscreen Graphics',
  [HEADING_FOUR]: 'Heading 4',
  [HEADING_ONE]: 'Heading 1',
  [HEADING_THREE]: 'Heading 3',
  [HEADING_TWO]: 'Heading 2',
  [ITALIC]: 'Italic',
  [JINGLE]: 'Jingle',
  [LINK]: 'Link',
  [LIVE]: 'Live',
  [ORDERED_LIST]: 'Numbered list',
  [PACKAGE]: 'Clip',
  [STRIKE_THROUGH]: 'Strikethrough',
  [TELEPHONE]: 'Telephone',
  [UNDERLINE]: 'Underline',
  [UNORDERED_LIST]: 'Bulleted list',
  [GIF]: 'GIF',
  [IMAGE]: 'Image',
  [VIDEO]: 'Video',
  [VOICE_OVER]: 'Voiceover',
  [OVERLAY_GRAPHICS]: '*CG',
  [SECTION]: 'Section',
  [TITLE]: 'Title',
  [QUOTE_BOX]: 'Quote',
  [FACT]: 'Fact',
  [FEED]: 'Feed',
  [EMBEDDED_TWEET]: 'Embedded Tweet',
  [HTML]: 'Html',
  [EMBEDDED_VIDEO]: 'Embedded Video',
  [CONTENT]: 'Content',
  [ABSTRACT]: 'Description',
  [OVERLINE]: 'Overline',
  [TAGS]: 'tag',
  [AUTHOR]: 'author',
  [SOURCE]: 'source',
  [LIVE_STREAM]: 'Live stream',
  [PHOTO_GALLERY]: 'Photo Gallery',
  [NOTIFICATION]: 'Notification',
  COLOR: 'Color',
  GIF: 'GIF',
  HORIZONTAL_RULE: 'Horizontal rule',
  IMAGE: 'Image',
  PLACEHOLDER: 'Placeholder',
  QUOTE: 'Quote',
  VIDEO: 'Video',
};

export default tooltipEnum;
