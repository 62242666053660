import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LeftArrowSrc from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import BookmarkOnSrc from 'assets/icons/systemicons/bookmarks_on.svg';
import BookmarkOffSrc from 'assets/icons/systemicons/bookmark_off.svg';
import AlertOnSrc from 'assets/icons/systemicons/set_alerts_on.svg';
import AlertOffSrc from 'assets/icons/systemicons/set_alert_off.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/systemicons/more_vertical.svg';
import HeaderBase from '../headerBase';
import useStyles from './detail-header-styles';

const DetailHeader = ({
  backButtonLabel,
  onBackButtonClick,
  favorited,
  onFavoriteClick,
  subscribed,
  onSubscribeClick,
  hideIcons,
  isSummary,
  showMenu,
  onMenuButtonClick,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <HeaderBase isSummary={isSummary} {...rest}>
      <div className={classes.container}>
        {!isSummary && (
          <Button
            variant="text"
            classes={{ root: classes.buttonRoot }}
            onClick={onBackButtonClick}
            disableRipple
          >
            <img src={LeftArrowSrc} alt="Go Back" className={classes.icon} />
            {backButtonLabel}
          </Button>
        )}

        {!hideIcons && (
          <div className={classes.iconButton}>
            <IconButton onClick={() => onFavoriteClick(favorited)} data-testid="favorite">
              {favorited ? (
                <img src={BookmarkOnSrc} alt="Unmark Favorite" />
              ) : (
                <img src={BookmarkOffSrc} alt="Mark Favorite" />
              )}
            </IconButton>

            <IconButton onClick={() => onSubscribeClick(subscribed)} data-testid="subscribe">
              {subscribed ? (
                <img src={AlertOnSrc} alt="Unsubscribe" />
              ) : (
                <img src={AlertOffSrc} alt="Subscribe" />
              )}
            </IconButton>
          </div>
        )}
        {showMenu && (
          <IconButton onClick={onMenuButtonClick}>
            <MenuIcon />
          </IconButton>
        )}
      </div>
    </HeaderBase>
  );
};

DetailHeader.propTypes = {
  /** Defines the text to shown beside the back arrow  */
  backButtonLabel: PropTypes.string,
  /** Callback to be invoked on back button click  */
  onBackButtonClick: PropTypes.func,
  /** When true hides the secondary icons */
  hideIcons: PropTypes.bool,
  /** Defines if the item is favorited or not */
  favorited: PropTypes.bool,
  /** Callback to be invoked on favorite button click  */
  onFavoriteClick: PropTypes.func,
  /** Defines if subscription is on or not */
  subscribed: PropTypes.bool,
  /** Callback to be invoked on subscribe button click  */
  onSubscribeClick: PropTypes.func,
  /** Defines if menu button should be visible or not */
  showMenu: PropTypes.bool,
  /** Callback to be invoked on menu button click  */
  onMenuButtonClick: PropTypes.func,
};

DetailHeader.defaultProps = {
  backButtonLabel: 'Back',
  onBackButtonClick: () => {},
  hideIcons: false,
  favorited: false,
  onFavoriteClick: () => {},
  subscribed: false,
  onSubscribeClick: () => {},
  showMenu: false,
  onMenuButtonClick: () => {},
};

export default DetailHeader;
