import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { useReadOnly, useSelected } from 'slate-react';
import useDragEnd from 'components/editor/hooks/useDragEnd';
import styled from '@emotion/styled';
import dndTypes from 'utils/dndTypes';
import theme from 'theme/theme';

const DropZone = styled('div')`
  position: relative;
  opacity: ${(props) => (props.isDragging ? 0.4 : 1)};
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
  border-radius: 4px;
  background: ${(props) => props.showHighlight && theme.palette.dina.blackHoverOverlay};
  ::before {
    content: '';
    position: absolute;
    background-color: ${({ hovered, isDragging }) =>
      hovered && !isDragging ? theme.palette.dina.onFocus : 'transparent'};
    width: calc(100% - 16px);
    left: 8px;
    bottom: 0;
    height: 3px;
  }
`;

const DragAndDrop = ({ element, children, isDisabled, hideHighlight }) => {
  const readOnly = useReadOnly();
  const isSelected = useSelected(element);
  const showHighlight = !readOnly && !hideHighlight && isSelected;

  const [onDragEnd] = useDragEnd();

  const [{ isDragging }, drag] = useDrag({
    type: dndTypes.EDITOR_BLOCK,
    item: () => ({ type: dndTypes.EDITOR_BLOCK, payload: element }),
    canDrag: () => !readOnly,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [{ hovered }, drop] = useDrop({
    accept: dndTypes.EDITOR_BLOCK,
    canDrop: () => !readOnly && !isDragging,
    drop: (item) => onDragEnd(item.payload, element),
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const attachRef = useCallback((elm) => {
    if (!isDisabled) drag(elm);
    drop(elm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropZone
      hovered={hovered}
      isDragging={isDragging}
      readOnly={readOnly}
      showHighlight={showHighlight}
      ref={attachRef}
    >
      {children}
    </DropZone>
  );
};

DragAndDrop.propTypes = {
  /** Drag and drop wrapped children */
  children: PropTypes.node.isRequired,
  /** SlateJS element */
  element: PropTypes.shape({}).isRequired,
  /** Whether block is disabled or not */
  isDisabled: PropTypes.bool,
  /** Whether to show highlight around the wrapper */
  hideHighlight: PropTypes.bool,
};

DragAndDrop.defaultProps = {
  isDisabled: false,
  hideHighlight: false,
};

export default memo(DragAndDrop);
