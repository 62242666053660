import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Editor from 'components/editor';
import Scrollbar from 'screens/main/components/scrollbar';
import useStyles from './content-styles';

const Content = ({ headerTitle, content, loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>{headerTitle}</Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.contentContainer}>
        <div className={classes.contentOutline}>
          {loading && <LoadingIndicator />}
          {content ? (
            <Scrollbar top={5} bottom={5}>
              <Editor value={content} readOnly renderToolbar={() => {}} />
            </Scrollbar>
          ) : (
            <div className={classes.noContent}>No version yet!</div>
          )}
        </div>
      </div>
    </div>
  );
};

Content.propTypes = {
  /** Title of the dialog */
  headerTitle: PropTypes.string,
  /** content */
  content: PropTypes.shape({}),
  /** If the content is loading */
  loading: PropTypes.bool,
};

Content.defaultProps = {
  headerTitle: '—',
  loading: false,
  content: undefined,
};

export default Content;
