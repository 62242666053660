import gql from 'graphql-tag';

export default gql`
  query GetUsers($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mAvatarUrl
      mAvatarKey
      mDescription
      mProperties {
        __typename
        ... on ContactType {
          firstName
          surname
          email
          phone
          dateOfBirth
          jobTitle
          readSpeed
        }
      }
      mMetaData {
        key
        value
      }
    }
  }
`;
