import { avatarVariants } from 'components/avatar/Avatar';
import conversationTypes from 'utils/constants/conversationTypes';

const getAvatarVariant = (convoType) => {
  switch (convoType) {
    case conversationTypes.TEAM:
      return avatarVariants.team;
    case conversationTypes.DEPARTMENT:
      return avatarVariants.department;
    default:
      return avatarVariants.people;
  }
};

export default getAvatarVariant;
