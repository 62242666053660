import React, { useState, useContext, createContext, useEffect } from 'react';

const SAVED_STATE = JSON.parse(window.localStorage.getItem('screens'));

const contentWidth = {
  NORMAL: 645,
  WIDE: 1058,
  FULLWIDTH: '100%',
};

const StoryToolbarCtx = createContext();

const StoryToolbarCtxProvider = (props) => {
  const { story } = SAVED_STATE || {};

  const currentWidth = story && story.extended;
  const timelineState = story && story.timelineHidden;

  const [maximized, setMaximized] = useState(
    Object.values(contentWidth).includes(currentWidth) ? currentWidth : contentWidth.NORMAL,
  );

  const [isTimelineHidden, setIsTimelineHidden] = useState(timelineState);

  useEffect(() => {
    window.localStorage.setItem(
      'screens',
      JSON.stringify({
        story: {
          extended: maximized,
          timelineHidden: isTimelineHidden,
        },
      }),
    );
  }, [maximized, isTimelineHidden]);

  return (
    <StoryToolbarCtx.Provider
      value={{ maximized, setMaximized, isTimelineHidden, setIsTimelineHidden, contentWidth }}
      {...props}
    />
  );
};

export const useStoryToolbarCtx = () => useContext(StoryToolbarCtx);

export default StoryToolbarCtxProvider;
