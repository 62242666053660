import React, { useRef, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import Scrollbar from 'components/scrollbar';
import InstanceListContainer from 'screens/main/components/leftArea/sidebar/instances/list';
import { InstanceItemVariant } from 'screens/main/components/leftArea/sidebar/instances/list/listItem/listItem-view';
import styled from '@emotion/styled';
import theme from 'theme/theme';

const FIXED_HEIGHT_FOR_THE_INSTANCE_LIST = '419px';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
  width: 327px;
`;

const InstanceList = ({ onSelectInstance, searchString, platforms, assignedMembers }) => {
  const scrollbarRef = useRef(null);

  // const [selection] = useState(new Set());
  const selection = useRef(new Set());

  const handleClick = useCallback(
    (_, index, instanceId) => {
      // only the clicked element is selected
      selection.current.clear();
      selection.current.add(index);

      onSelectInstance(instanceId);
    },
    [onSelectInstance, selection],
  );

  return (
    <Container>
      <Scrollbar
        ref={scrollbarRef}
        autoHeight
        autoHeightMin={FIXED_HEIGHT_FOR_THE_INSTANCE_LIST}
        autoHeightMax={FIXED_HEIGHT_FOR_THE_INSTANCE_LIST}
      >
        <InstanceListContainer
          searchString={searchString}
          assignedMembers={assignedMembers}
          platforms={platforms}
          variant={InstanceItemVariant.CREATE_LINK_LIST_ITEM}
          onSelectInstance={handleClick}
          linkedInstances={[]}
          selectedIndices={selection.current}
        />
      </Scrollbar>
    </Container>
  );
};

InstanceList.propTypes = {
  onSelectInstance: PropTypes.func.isRequired,
  searchString: PropTypes.string,
  platforms: PropTypes.arrayOf(PropTypes.shape({})),
  assignedMembers: PropTypes.arrayOf(PropTypes.string),
};

InstanceList.defaultProps = {
  searchString: '',
  platforms: [],
  assignedMembers: [],
};

export default InstanceList;
