import styled from '@emotion/styled';
import { css } from '@emotion/react';
import theme from 'theme/theme';
import ToolbarPositions from 'components/editor/constants/toolbarPositions';

const { blackHoverOverlay, dividerDark, dividerLight } = theme.palette.dina;

const commonStyle = css`
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${dividerLight};
`;

const readOnlyStyles = css`
  background: ${dividerDark};
  pointer-events: none;
`;

const generalStyles = css`
  background: ${blackHoverOverlay};
  pointer-events: all;
`;

const notesStyles = css`
  height: auto;
  padding: 0;
  border: none;
`;

export const Container = styled('div')`
  ${commonStyle}
  ${({ readOnly, disableGeneralToolbar }) =>
    readOnly || disableGeneralToolbar ? readOnlyStyles : generalStyles}
  ${({ toolbarPosition }) => {
    if (toolbarPosition === ToolbarPositions.TOP) {
      return { borderBottom: dividerLight };
    }
    if (toolbarPosition === ToolbarPositions.BOTTOM) {
      return { borderTop: dividerLight };
    }
    return {};
  }}
  ${({ isNotes }) => isNotes && notesStyles}
`;

export const CMSBlockContainer = styled('div')`
  height: 80px;
  ${({ readOnly }) => (readOnly ? readOnlyStyles : generalStyles)}
`;

export const CMSToolbarWrapper = styled('div')`
  ${commonStyle}
  ${({ readOnly }) => (readOnly ? readOnlyStyles : generalStyles)}
`;

export const GeneralToolbarWrapper = styled('div')`
  ${commonStyle}
  ${({ readOnly, disableGeneralToolbar }) =>
    readOnly || disableGeneralToolbar ? readOnlyStyles : generalStyles}
`;
