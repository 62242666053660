import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { AvatarGroup as MaterialAvatarGroup } from '@material-ui/lab';
import { Avatar as MaterialAvatar } from '@material-ui/core';
import theme from 'theme/theme';
import { durationLabel } from 'theme/styledTypography';
import getInitials from 'utils/getInitials';

const AvatarGroup = memo(MaterialAvatarGroup);
const Avatar = memo(MaterialAvatar);

const Group = styled(AvatarGroup)`
  .MuiAvatarGroup-avatar {
    border: none;
    margin-left: -4px;
    &:first-child {
      margin-left: 0px;
    }
    ${(props) => ({ width: props.size })}
    ${(props) => ({ height: props.size })}
    background-color: ${theme.palette.dina.chipBackground};
    border-radius: 50%;
    ${durationLabel};
    color: ${theme.palette.dina.mediumEmphasis};
    font-size: ${(props) => `${props.size / 2 - 2}px`};
  }
`;

const AvatarGroupView = ({ members, size, max }) => (
  <Group max={max} size={size}>
    {members.map((member) => (
      <Avatar src={member?.mAvatarUrl} key={member?.mId} title={member?.mTitle}>
        {getInitials(member?.mTitle)}
      </Avatar>
    ))}
  </Group>
);

AvatarGroupView.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
    }),
  ),
  size: PropTypes.number,
  max: PropTypes.number,
};

AvatarGroupView.defaultProps = {
  members: [],
  size: 24,
  max: 4,
};

export default memo(AvatarGroupView);
