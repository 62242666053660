import styled from '@emotion/styled';
import DividerComponent from 'components/divider';
import theme from 'theme/theme';
import transientOptions from 'theme/helpers';
import { KANBAN_CARD_SIZE, KANBAN_CARD_WITHOUT_STORY_TITLE_SIZE } from 'utils/constants/kanbanCard';
import { caption, captionSmall, listItemLabelMedium, overline } from 'theme/styledTypography';

export const KanbanCardWrapper = styled('div', transientOptions)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  height: ${({ $autoHeight, $storyTitle }) => {
    if ($autoHeight) return 'auto';
    if ($storyTitle) return `${KANBAN_CARD_SIZE}px`;
    return `${KANBAN_CARD_WITHOUT_STORY_TITLE_SIZE}px`;
  }};
  gap: 4px;
`;

export const TopRowWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  svg {
    height: 20px;
    width: 20px;
  }
  div {
    display: flex;
    align-items: center;
    ${caption}
    line-height: 14px;
  }
  .destination {
    margin-left: 4px;
    color: ${theme.palette.dina.highEmphasis};
  }
  .relationNumber {
    margin-right: 2px;
    ${captionSmall}
  }
`;

export const BottomRowWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 20px;
  ${caption}
  div {
    display: flex;
    align-items: center;
    & > :last-child {
      white-space: nowrap;
    }
  }
  .publishedText {
    color: ${theme.palette.dina.highEmphasis};
  }
`;

export const PublishingInfoWrapper = styled('div', transientOptions)`
  border-radius: 4px;
  height: 20px;
  padding-right: 4px;
  margin-right: 8px;
  & > :last-child {
    margin-left: 4px;
    white-space: nowrap;
  }
`;

export const TitleRowWrapper = styled('div')`
  width: 100%;
`;

export const StoryTitle = styled('div')`
  width: 100%;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${overline}
`;

export const Title = styled('div')`
  width: 100%;
  ${listItemLabelMedium}
  ${({ $autoHeight }) => {
    if (!$autoHeight) return { whiteSpace: 'nowrap' };
    return {
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
    };
  }}
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemsWrapper = styled('div')`
  display: flex;
  gap: 2px;
  margin-right: 8px;
`;

export const Divider = styled(DividerComponent)`
  margin: 0 4px;
`;
