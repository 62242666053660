import React from 'react';
import PropTypes from 'prop-types';

import { useSetTabIndex, useTabIndexValue, useSetCloseContentTab } from 'store/tabs';

import { ReactComponent as CheckIcon } from 'assets/icons/systemicons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close_small.svg';

import {
  StyledDropdownListItem as ListItem,
  StyledDropdownListItemLabel as ListItemLabel,
  StyledDropdownListItemIcons as ListItemIcons,
  StyledIconButton as IconButton,
  StyledTabIconWrapper,
} from './tabItems-styled';

import { tabIcons } from '../../../contentTab/contentTab-view';

const TabItemsView = ({ tab, visible, index, handleDropDownClose }) => {
  const currentTabIndex = useTabIndexValue();
  const setTabIndex = useSetTabIndex();
  const closeContentTab = useSetCloseContentTab();
  const selected = index === currentTabIndex;
  const TabIcon = tabIcons[tab.type];

  const handleClick = () => {
    if (!selected) {
      setTabIndex(index);
      handleDropDownClose();
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    closeContentTab({ id: tab.id });
  };

  return (
    <ListItem key={tab.id} button selected={selected} onClick={handleClick}>
      <StyledTabIconWrapper>
        <TabIcon />
      </StyledTabIconWrapper>
      <ListItemLabel>{tab.title}</ListItemLabel>
      <ListItemIcons>
        {visible && <CheckIcon />}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </ListItemIcons>
    </ListItem>
  );
};

TabItemsView.propTypes = {
  /** tab item */
  tab: PropTypes.object.isRequired,
  /** is visible in the view */
  visible: PropTypes.bool.isRequired,
  /** index of tab item */
  index: PropTypes.number.isRequired,
  /** callback when dropdown closes */
  handleDropDownClose: PropTypes.func.isRequired,
};

export default TabItemsView;
