import { elementTypes } from 'components/editor/constants';
import { isKeyHotkey } from 'is-hotkey';
import { Editor, Transforms, Range } from 'slate';

const { nodes } = Editor;

/**
 * Handles onKeyDown event on link element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @returns {Object} SlateJS editor instance
 */

const onLinkKeyDown = (editor, event) => {
  const { selection } = editor;
  const [match] = nodes(editor, {
    match: ({ type }) => type === elementTypes.LINK,
  });

  if (match && event.key === 'Backspace') {
    const [element, path] = match;
    const { children } = element;
    const [{ text }] = children;

    if (text.length === 1) Transforms.removeNodes(editor, { at: path });
  }

  // Default left/right behavior is unit:'character'.
  // This fails to distinguish between two cursor positions, such as
  // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
  // Here we modify the behavior to unit:'offset'.
  // This lets the user step into and out of the inline without stepping over characters.
  // We can customize this further to only use unit:'offset' in specific cases(if needed).
  if (selection && match && Range.isCollapsed(selection)) {
    const { nativeEvent } = event;
    if (isKeyHotkey('left', nativeEvent)) {
      event.preventDefault();
      Transforms.move(editor, { unit: 'offset', reverse: true });
      return editor;
    }
    if (isKeyHotkey('right', nativeEvent)) {
      event.preventDefault();
      Transforms.move(editor, { unit: 'offset' });
      return editor;
    }
  }

  return editor;
};

export default onLinkKeyDown;
