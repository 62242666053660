import { searchbarFields } from 'utils/constants/searchbar';

const getSearchFilters = (filterModel, isInstance = false) => {
  const assignedMembers = [];
  const platforms = [];
  let searchString = '';

  Object.keys(filterModel).forEach((key) => {
    switch (key) {
      case searchbarFields.FREE_TEXT:
        searchString = filterModel[key];
        break;
      case searchbarFields.PLATFORM:
        if (isInstance) {
          filterModel[key].forEach((platform) => {
            platforms.push(platform.platform);
          });
        }
        break;
      case searchbarFields.USER: {
        filterModel[key].forEach((user) => {
          assignedMembers.push(user.mId);
        });
        break;
      }
      case searchbarFields.TEAM: {
        if (!isInstance) {
          filterModel[key].forEach((member) => {
            assignedMembers.push(member.mId);
          });
        }
        break;
      }
      case searchbarFields.DEPARTMENT: {
        if (!isInstance) {
          filterModel[key].forEach((member) => {
            assignedMembers.push(member.mId);
          });
        }
        break;
      }
      default:
    }
  });

  return { searchString, assignedMembers, platforms };
};

export default getSearchFilters;
