import styled from '@emotion/styled/macro';
import { ReactComponent as LiveStream } from 'assets/icons/systemicons/editor/live_stream.svg';
import { overline } from 'theme/styledTypography';
import theme from 'theme/theme';

export const LiveStreamIcon = styled(LiveStream)`
  width: 24px;
  height: 24px;
  margin: 8px;
  path {
    fill: ${theme.palette.dina.blackInactive};
    fill-opacity: 1;
  }
`;

export const BoxChildren = styled('div')`
  > * {
    margin-bottom: 8px;
  }
`;

export const AutocompleteWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const Label = styled('div')`
  ${overline}
  font-weight: 500;
  padding: 9px 0px 1px 0px;
`;
