import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  instancesContainer: {
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    height: '100%',
  },

  loadMoreButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
  },
}));

export default useStyles;
