import React, { memo } from 'react';
import { markTypes, elementTypes } from 'components/editor/constants';
import MarkButton from '../markButton';
import ColorButton from '../colorButton';
import ElementButton from '../elementButton';
import ListButton from '../listButton';
import LinkButton from '../linkButton';
import VideoButton from '../videoButton';
import ImageButton from '../imageButton';
import { Container, OuterWrapper, InnerWrapper, Empty } from './styled';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, HEADING_FOUR, HEADING_THREE, HEADING_TWO, ORDERED_LIST } = elementTypes;

const Wrapper = ({ children }) => (
  <OuterWrapper>
    <InnerWrapper>{children}</InnerWrapper>
  </OuterWrapper>
);

const GeneralToolbar = () => (
  <Container>
    <Wrapper>
      <ElementButton type={HEADING_TWO} />
      <ElementButton type={HEADING_THREE} />
      <ElementButton type={HEADING_FOUR} />
    </Wrapper>
    <Wrapper>
      <MarkButton type={BOLD} />
      <MarkButton type={ITALIC} />
      <MarkButton type={UNDERLINE} />
      <MarkButton type={STRIKE_THROUGH} />
    </Wrapper>
    <Wrapper>
      <ListButton type={UNORDERED_LIST} />
      <ListButton type={ORDERED_LIST} />
    </Wrapper>
    <Wrapper>
      <ColorButton />
    </Wrapper>
    <Wrapper>
      <VideoButton />
      <ImageButton />
      <ImageButton isGif />
    </Wrapper>
    <Wrapper>
      <LinkButton />
    </Wrapper>
    {Array(10)
      .fill(0)
      .map(() => (
        <Empty />
      ))}
  </Container>
);

export default memo(GeneralToolbar);
