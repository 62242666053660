const findFieldValue = (mMetaData, fieldId) => {
  let obj;
  if (mMetaData) obj = mMetaData.find((data) => data.key === fieldId);
  return obj ? obj.value : null;
};

const returnFieldPairs = (mMetaData, fields) =>
  fields &&
  fields.map((field) => {
    const fieldValue = findFieldValue(mMetaData, field.id);
    return {
      key: field.id,
      value: fieldValue === null ? field.value : fieldValue,
    };
  });

export default returnFieldPairs;
