/**
 *  Hook for sending a story to archive *
 */

import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import memberTypes from 'graphql/memberTypes';

const ARCHIVE_STORY = gql`
  mutation ArchiveStory($input: ArchiveStoryInput) {
    archiveStory(input: $input) {
      mId
    }
  }
`;

const useArchiveStory = () => {
  const [mutate] = useMutation(ARCHIVE_STORY);
  const [update] = useUpdateLeftSidebarCache();
  const archive = async (mId, memberType, story) => {
    const getMemberType = () => {
      if (memberType === memberTypes.STORY) return memberTypes.USER_STORY;
      if (memberType === memberTypes.PITCH) return memberTypes.USER_PITCH;
      return memberType;
    };

    await mutate({
      variables: {
        input: {
          mId,
        },
      },
      update: () => {
        update(story, getMemberType(), false);
      },
    });
  };

  return [archive];
};

export default useArchiveStory;
