import React from 'react';
import styled from '@emotion/styled';

import AddMorePeopleSrc from 'assets/icons/systemicons/AddMorePeople.svg';
import Container from '../container/Container';

const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
`;

const Placeholder = (props) => (
  <Container {...props}>
    <StyledImage src={AddMorePeopleSrc} alt="placeholder" />
  </Container>
);

export default Placeholder;
