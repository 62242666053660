import React, { useState, useEffect } from 'react';
import AuthAudit from './authAudit-view';

const AuthAuditContainer = ({ props: auditingProps }) => {
  const { user, groups, urlParams } = auditingProps;

  const [props, setProps] = useState({});

  useEffect(() => {
    setProps({ user, groups, urlParams });
  }, [user, groups, urlParams]);

  return <AuthAudit props={props} />;
};
export default AuthAuditContainer;
