import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import InputField from 'components/inputField/InputField';
import { Typography, FormControlLabel } from '@material-ui/core';
import Button from 'components/buttons/button';
import Checkbox from 'components/checkbox';
import useDidMount from 'hooks/useDidMount';
import legends from './legends';
import useStyles from './createNew-styles';

const getLegends = (variant) => {
  if (!variant) return legends.story;
  return legends[variant] || legends.story;
};

const matchPattern = (value, regexString) => {
  const regexObject = new RegExp(`^[${regexString}]+$`);

  const result = regexObject.test(value);
  regexObject.lastIndex = 0;
  return !result;
};

const makeDefaultTitleCompliant = (title, variant, placeholderNameConfigs) => {
  if (variant !== 'placeholder') return title;

  const placeholderTitle = placeholderNameConfigs.defaultTitle || title;
  if (placeholderNameConfigs && placeholderNameConfigs.characters) {
    const regexString = placeholderNameConfigs.characters;
    const regexObject = new RegExp(`[^${regexString}]+`, 'g');

    /* eslint-disable no-nested-ternary */
    const conjunctiveCharacter = placeholderNameConfigs.conjunctiveCharacter
      ? placeholderNameConfigs.conjunctiveCharacter
      : regexString.includes('_')
      ? '_'
      : '';
    return placeholderTitle.replace(regexObject, conjunctiveCharacter);
  }
  return placeholderTitle;
};

const CreateForm = (props) => {
  const { onCreate, onCancel, variant, defaultTitle, placeholderNameConfigs, storyCreateOption } =
    props;
  const isPlaceholder = variant === 'placeholder';

  const [title, setTitle] = useState(
    makeDefaultTitleCompliant(defaultTitle, variant, placeholderNameConfigs),
  );

  const [createInstancesStory, setCreateInstancesStory] = useState(false);

  const [hint, setHint] = useState({
    message:
      isPlaceholder && placeholderNameConfigs && placeholderNameConfigs.defaultHint
        ? placeholderNameConfigs.defaultHint
        : null,
    isError: false,
  });
  const classes = useStyles({ variant, hint });
  const didMount = useDidMount();

  useEffect(() => {
    if (!didMount && isPlaceholder && placeholderNameConfigs) checkValidity(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const submitStory = (event) => {
    event.preventDefault();
    onCreate(title.trim(), createInstancesStory);
    setTitle('');
    setCreateInstancesStory(false);
  };

  const checkValidity = (value) => {
    if (placeholderNameConfigs.maxLength && value.length > placeholderNameConfigs.maxLength) {
      setHint({
        message: placeholderNameConfigs.maxLengthMessage,
        isError: true,
      });
      return false;
    }

    if (
      placeholderNameConfigs.characters &&
      matchPattern(value, placeholderNameConfigs.characters)
    ) {
      setHint({
        message: placeholderNameConfigs.charactersMessage,
        isError: true,
      });
      return false;
    }

    if (placeholderNameConfigs.hasPlaceholder && placeholderNameConfigs.hasPlaceholder(value)) {
      setHint({
        message: placeholderNameConfigs.hasDuplicateMessage,
        isError: true,
      });
      return false;
    }

    setHint({
      message: null,
      isError: false,
    });
    return true;
  };
  const handleChange = (value) => {
    if (isPlaceholder && placeholderNameConfigs) {
      if (value) checkValidity(value);
    }
    setTitle(value);
  };

  const { popoverTitle, placeholder, title: titleText } = getLegends(variant);

  return (
    <div className={classes.dialogRoot}>
      <form onSubmit={submitStory} className={classes.formContainer}>
        <div className={classes.formDiv}>
          <div className={classes.topComponents}>
            <Typography className={classes.heading}>{popoverTitle}</Typography>
            <Divider />
            <Typography className={classes.subHeading}>{titleText}</Typography>
            <div className={classes.inputContainer}>
              <InputField
                label={placeholder}
                value={title}
                onChange={handleChange}
                autoFocus
                severity={hint && hint.isError === true ? 'error' : 'normal'}
              />
              {hint && hint.message && (
                <Typography className={classes.hint}>{hint.message}</Typography>
              )}
            </div>
            {variant === 'rundownInstance' && storyCreateOption && (
              <FormControlLabel
                control={
                  <Checkbox
                    selected={createInstancesStory}
                    onClick={() => {
                      setCreateInstancesStory((previousState) => !previousState);
                    }}
                    size={24}
                  />
                }
                label="Create New Story"
                classes={{
                  root: classes.checkBox,
                  label: classes.checkBoxText,
                }}
              />
            )}
          </div>
          <div className={classes.bottomComponents}>
            <Divider />
            <div className={classes.buttonLayout}>
              <Button variant="text" onClick={onCancel} shape="full-width">
                Cancel
              </Button>
              <Button
                variant="contained"
                type="significant"
                disabled={!title || (hint && hint.isError)}
                shape="full-width"
                submit
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

CreateForm.propTypes = {
  /** Cancel button */
  onCancel: PropTypes.func,
  /** Create button */
  onCreate: PropTypes.func,
  /** Variant of dialog */
  variant: PropTypes.oneOf(Object.keys(legends)),
  /** Default title of the dialog */
  defaultTitle: PropTypes.string,
};

CreateForm.defaultProps = {
  onCancel: () => {},
  onCreate: () => {},
  variant: 'placeholder',
  defaultTitle: '',
};

export default CreateForm;
