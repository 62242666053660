import React, { useState } from 'react';

import { useRightSidebarSavedSearches } from 'store/sidebar';

import Divider from 'components/divider';
import Dialog from 'components/dialog';
import Searchbar from 'components/searchbarV2';
import Scrollbar from 'screens/main/components/scrollbar';
import RelevantHeader from '../../relevantHeader';
import menuItems from './menuItems';
import actionTypes from './actionTypes';
import CreateTeam from '../createTeam';
import useStyles from './master-styles';
import TeamListContainer from './team-list-container';

const TeamsView = ({
  showDetail,
  containerRef,
  filterModel,
  updateFilterModel,
  history,
  updateHistory,
  currentSearch,
  updateCurrentSearch,
  searchString,
  canCreateTeam,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(<div />);
  const [rightSidebarSavedSearches, setRightSidebarSavedSearches] = useRightSidebarSavedSearches();

  const handleCreateNewFilter = (newFilter) => {
    setRightSidebarSavedSearches([...rightSidebarSavedSearches, newFilter]);
  };

  const handleUpdateFilters = (newSavedFilters) => {
    setRightSidebarSavedSearches(newSavedFilters);
  };

  const onClick = ({ id, name, image, imageKey, description }) =>
    showDetail({
      teamId: id,
      title: name,
      avatarUrl: image,
      imageKey,
      description,
    });

  const onMenuItemClick = (action) => {
    switch (action) {
      case actionTypes.CREATE_TEAM:
        setDialogContent(
          <CreateTeam
            onCancel={() => setDialogOpen(false)}
            onSubmit={(team) => {
              showDetail({
                teamId: team.mId,
                title: team.mTitle,
                imageKey: team.mAvatarKey,
                avatarUrl: team.mAvatarUrl,
                description: team.mDescription,
              });
              setDialogOpen(false);
            }}
          />,
        );
        setDialogOpen(true);
        break;
      default:
        setDialogContent(<div />);
        setDialogOpen(false);
        break;
    }
  };

  return (
    <>
      <RelevantHeader
        headerTitle="Teams"
        hideMenu={!canCreateTeam}
        {...{
          menuItems,
          onMenuItemClick,
        }}
      />
      <Dialog
        open={dialogOpen}
        container={containerRef.current}
        BackdropProps={{ classes: { root: classes.backdropRoot } }}
        onClose={() => setDialogOpen(false)}
      >
        {dialogContent}
      </Dialog>
      <Divider />
      <div className={classes.teams}>
        <Scrollbar>
          <TeamListContainer searchString={searchString} {...{ onClick }} />
        </Scrollbar>
      </div>
      <Searchbar
        savedFilters={rightSidebarSavedSearches}
        onCreateFilter={handleCreateNewFilter}
        onUpdateFilters={handleUpdateFilters}
        {...{
          filterModel,
          updateFilterModel,
          history,
          updateHistory,
          currentSearch,
          updateCurrentSearch,
        }}
      />
    </>
  );
};

export default TeamsView;
