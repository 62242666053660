/* eslint-disable react/no-danger */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'theme/theme';

export const HtmlContent = styled('div')`
  white-space: normal;
  user-select: text !important;
  color: ${theme.palette.dina.blackHighEmphasis};
  background-color: ${theme.palette.dina.highEmphasis};
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px;
`;

const createMarkup = (document) => ({
  __html: `${document && document !== 'null' ? document : ''}`,
});

const HtmlViewer = ({ document }) => (
  <HtmlContent dangerouslySetInnerHTML={createMarkup(document)} />
);

HtmlViewer.propTypes = {
  /** content document, can be a plain string or html */
  document: PropTypes.string,
};
HtmlViewer.defaultProps = {
  document: '',
};

export default memo(HtmlViewer);
