import styled from '@emotion/styled';

import theme from 'theme/theme';

import Typography from '@material-ui/core/Typography';

export const StyledWrapper = styled('div')`
  display: flex;
  position: relative;
`;

export const StyledAvatarWrapper = styled('div')`
  padding: 8px 16px 0;
`;

export const StyledTypographyWrapper = styled('div')`
  margin-top: 20px;
`;

export const StyledTitle = styled(Typography)`
  ${theme.typography.dina.avatarHeaderTitle};
`;

export const StyledSubtitle = styled('div')`
  ${theme.typography.dina.avatarHeaderSubtitle};
  margin: 4px 0;
`;

export const StyledTeamInfo = styled('div')`
  ${theme.typography.dina.captionMedium};
  font-weight: normal;
  font-size: 13px;
  color: ${theme.palette.dina.mediumEmphasis};
`;
