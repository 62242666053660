import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import InputField from 'components/inputField';
import CheckBox from 'components/checkbox';
import useKeyPress from 'hooks/useKeyPress';
import useMetaKeyPress from 'hooks/useMetaKeyPress';
import DatePickerButton from 'screens/story/components/toolbar/publishingDatePickerButton/publishingDatePickerButton-view';
import { ReactComponent as PadlockOn } from 'assets/icons/systemicons/padlock_on.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as StoryIcon } from 'assets/icons/systemicons/story_off.svg';
import { ReactComponent as PitchIcon } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';
import Button from 'components/buttons/button';
import useStyles from './createNew-styles';

export const CheckboxWithLabel = ({ selected, onClick, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.checkboxWithLabel} onClick={onClick} role="presentation">
      <CheckBox selected={selected} disableRipple disableHoverEffect size={24} />
      <Typography className={classes.checkboxLabel}>{label}</Typography>
    </div>
  );
};

const CreateNew = ({
  canCreateStory,
  canCreatePitch,
  onCreateStory,
  onCreatePitch,
  onCancel,
  hideRestrictedOption,
  hideScheduleOption,
  scheduledDate,
  storyTitle,
}) => {
  const classes = useStyles();
  const [title, setTitle] = useState(storyTitle || '');
  const [restricted, setRestricted] = useState(false);
  const [scheduled, setScheduled] = useState(scheduledDate);
  const [submitting, setSubmitting] = useState(false);
  const enterPressed = useKeyPress('Enter');
  const ctrlPressed = useMetaKeyPress('ctrl');

  const handleCreateStory = () => {
    if (submitting) return;
    setSubmitting(true);
    if (title) onCreateStory(title.trim(), restricted, scheduled);
  };

  const handleCreatePitch = () => {
    if (submitting) return;
    setSubmitting(true);
    if (title) onCreatePitch(title.trim(), restricted, scheduled);
  };

  useEffect(() => {
    if (enterPressed) {
      if (onCreateStory && onCreatePitch) {
        if (ctrlPressed) {
          handleCreatePitch();
        } else {
          handleCreateStory();
        }
      } else if (onCreateStory) {
        handleCreateStory();
      } else if (onCreatePitch) {
        handleCreatePitch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPressed]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography classes={{ root: classes.headerLabel }}>Create New</Typography>
        <CloseIcon className={classes.closeButton} onClick={onCancel} />
      </div>
      <div className={classes.content}>
        <InputField
          autoFocus
          usage="editor"
          disableLabel
          label="Type Title here..."
          placeholder="Type Title here..."
          value={title}
          onChange={setTitle}
        />
        <div className={classes.checkboxContainer}>
          {!hideScheduleOption && (
            <CheckboxWithLabel
              selected={!!scheduled}
              onClick={() => setScheduled(scheduled ? false : new Date().toISOString())}
              label="Schedule"
            />
          )}
          {scheduled && (
            <div className={classes.restrictedInfo}>
              <DatePickerButton publishingTime={scheduled} onPublishingTimeChange={setScheduled} />
            </div>
          )}
        </div>
        <div className={classes.checkboxContainer}>
          {!hideRestrictedOption && (
            <CheckboxWithLabel
              selected={restricted}
              onClick={() => setRestricted(!restricted)}
              label="Restricted Access"
            />
          )}
          {restricted && (
            <div className={classes.restrictedInfo}>
              <PadlockOn className={classes.warning} />
              <Typography className={classes.restrictedText}>
                Only you can access upon creation.
                <br />
                You can add other People, Teams or Departments after it’s created.
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className={classes.footer}>
        <div role="presentation" className={classes.cancelButton} onClick={onCancel}>
          Cancel
        </div>
        <div className={classes.btnContainer}>
          {canCreatePitch && (
            <Button
              shape="full-width"
              variant="contained"
              type="pitch"
              onClick={handleCreatePitch}
              disabled={!title?.trim() || submitting}
            >
              <PitchIcon className={classes.pitchIcon} />
              <div className={classes.pitchLabel}>Create Pitch</div>
            </Button>
          )}
          {canCreateStory && (
            <Button
              shape="full-width"
              variant="contained"
              type="story"
              onClick={handleCreateStory}
              disabled={!title?.trim() || submitting}
            >
              <StoryIcon className={classes.storyIcon} />
              <div className={classes.storyLabel}>Create Story</div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

CreateNew.propTypes = {
  /** whether can create story */
  canCreateStory: PropTypes.bool,
  /** whether can create pitch */
  canCreatePitch: PropTypes.bool,
  /** onCreate callback */
  onCreateStory: PropTypes.func,
  /** onCreatePitch callback */
  onCreatePitch: PropTypes.func,
  /** onCancel callback */
  onCancel: PropTypes.func,
  /** whether to hide restricted option */
  hideRestrictedOption: PropTypes.bool,
  /** whether to hide schedule option */
  hideScheduleOption: PropTypes.bool,
  /** scheduled date of publishing */
  scheduledDate: PropTypes.string,
  /** optional pre filled storyTitle */
  storyTitle: PropTypes.string,
};

CreateNew.defaultProps = {
  canCreateStory: false,
  canCreatePitch: false,
  onCreateStory: undefined,
  onCreatePitch: undefined,
  onCancel: () => {},
  hideRestrictedOption: false,
  hideScheduleOption: false,
  scheduledDate: new Date().toISOString(),
  storyTitle: '',
};

export default CreateNew;
