import React from 'react';
import memberTypes from 'graphql/memberTypes';
import { useMutation } from '@apollo/client';
import useLogger from 'utils/useLogger';
import CREATE_CONTACT from 'graphql/mutations/createContact';
import GET_CONTACTS from 'graphql/queries/getUsers';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import CreateContact from './create-contact';

const CreateContactContainer = ({ onSubmit, onCancel }) => {
  const contactType = memberTypes.CONTACT;
  const logger = useLogger('create contact container');
  const [createContact] = useMutation(CREATE_CONTACT, {
    update: (proxy, mutationResult) => {
      const { createContact: newContact } = mutationResult.data;
      // Read contacts from the cache
      try {
        const contactList = proxy.readQuery({
          query: GET_CONTACTS,
          variables: getMembersOfTypeQuery(contactType),
        });

        const list = contactList.getMembersOftype.filter(
          (contact) => contact !== null && contact.mId === newContact.mId,
        );

        const contacts = [...contactList.getMembersOftype];

        // Add new contact to the contact-list if it is not the cache
        if (list.length === 0) {
          contacts.push(newContact);
        }
        // Write updated contact-list in the cache.
        proxy.writeQuery({
          query: GET_CONTACTS,
          variables: getMembersOfTypeQuery(contactType),
          data: {
            getMembersOftype: contacts,
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        logger.log(error);
      }
    },
  });

  const createNewContact = async ({ firstName, surname, email, phone, description, readSpeed }) => {
    if (!(firstName && surname)) {
      return;
    }

    const mProperties = {
      __typename: 'ContactType',
      firstName,
      surname,
      jobTitle: 'Contact',
    };

    if (email) {
      mProperties.email = email;
    }

    if (phone) {
      mProperties.phone = phone;
    }

    if (readSpeed) {
      mProperties.readSpeed = readSpeed;
    }

    const input = {
      mTitle: `${firstName} ${surname}`,
      mProperties,
    };

    if (description) {
      input.mDescription = description;
    }

    createContact({
      variables: {
        input,
      },
    })
      .then((result) => {
        if (result.data.createContact) {
          onSubmit(result.data.createContact);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        logger.log(e);
        onSubmit(null);
      });
  };

  return (
    <>
      <CreateContact onCancel={onCancel} onSubmit={createNewContact} />
    </>
  );
};

export default CreateContactContainer;
