/**
 *  Hook for bookmarking a story
 *
 */

import { useMutation } from '@apollo/client';
import updateBookmarks from 'graphql/mutations/updateBookmarks';

const useBookmarkStory = () => {
  const [mutate] = useMutation(updateBookmarks);

  const bookmark = async (userId, bookmarks) => {
    await mutate({
      variables: {
        input: {
          mId: userId,
          mBookmarks: Object.values(bookmarks),
        },
      },
    });
  };

  return [bookmark];
};

export default useBookmarkStory;
