import styled from '@emotion/styled';
import theme from 'theme/theme';
import colors from 'theme/colors';
import transientOptions from 'theme/helpers';
import { Typography } from '@material-ui/core';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled('div', transientOptions)`
  border-top: 1px solid ${colors.dividerLight};
  padding: 5.5px 16px 16px 16px;
`;

export const Header = styled(Typography)`
  ${theme.typography.dina.overline};
  color: ${colors.mediumEmphasis};
  margin-bottom: 5.5px;
`;

export const TextArea = styled('div')`
  ${theme.typography.dina.body2};
`;

export const ChipContainer = styled('div')`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
