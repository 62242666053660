/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import { Scrollbars } from 'react-custom-scrollbars';

const VerticalTrack = styled.div`
  ${({ top, bottom }) => ({
    top: `${top || 0}px`,
    bottom: `${bottom || 0}px`,
  })}
  right: 0;
  transition: opacity 200ms ease;
  z-index: 99;
  position: absolute;
  display: flex;
  justify-content: center;
`;

const VerticalThumb = styled.div`
  background: transparent;
  justify-content: center;
  width: 100%;
  &:hover {
    display: block;
  }
  &:hover {
    & > :first-of-type {
      background: ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)')};
      border-left: ${({ dark }) =>
        `0.5px solid rgba${dark ? '(0, 0, 0, 0.4)' : '(255, 255, 255, 0.4)'}`};
      border-right: ${({ dark }) =>
        `0.5px solid rgba${dark ? '(0, 0, 0, 0.4)' : '(255, 255, 255, 0.4)'}`};
    }
  }
  &:active {
    & > :first-of-type {
      background: ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)')};
      border-left: ${({ dark }) =>
        `0.5px solid rgba${dark ? 'rgba(0, 0, 0, 0.4)' : '(255, 255, 255, 0.4)'}`};
      border-right: ${({ dark }) =>
        `0.5px solid rgba${dark ? 'rgba(0, 0, 0, 0.4)' : '(255, 255, 255, 0.4)'}`};
    }
  }
`;

const VerticalThumbInner = styled.div`
  width: 4px;
  height: 100%;
  background: ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.15)')};
  border-left: ${({ dark }) =>
    `0.5px solid rgba${dark ? '(0, 0, 0, 0.07)' : '(255, 255, 255, 0.07)'}`};
  border-right: ${({ dark }) =>
    `0.5px solid rgba${dark ? '(0, 0, 0, 0.07)' : '(255, 255, 255, 0.07)'}`};
  border-radius: 10px;
`;

const Scrollbar = ({ children, valueChanged, closeToBottom, top, bottom, dark, ...rest }) => {
  const scrollbar = useRef(null);

  useEffect(() => {
    if (scrollbar && valueChanged) {
      scrollbar.current.scrollToTop();
    }
  }, [valueChanged]);

  const closeToBottomHandler = debounce(() => {
    closeToBottom();
  }, 300);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const buffer = 50; // 50px from scroll bottom

    // the value will be greater than one just before the scroll reaches the bottom
    const val = (scrollTop + buffer) / (scrollHeight - clientHeight);
    if (val > 1) closeToBottomHandler();
  };

  return (
    <Scrollbars
      onUpdate={handleUpdate}
      ref={scrollbar}
      style={{ height: '100%' }}
      renderTrackHorizontal={(props) => (
        <div {...props} className="track-horizontal" style={{ display: 'none' }} />
      )}
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
      )}
      renderThumbVertical={(scrollProps) => (
        <VerticalThumb dark={dark} {...scrollProps} style={{ display: 'flex' }}>
          <VerticalThumbInner dark={dark} />
        </VerticalThumb>
      )}
      renderTrackVertical={(scrollProps) => (
        <VerticalTrack {...scrollProps} style={{ width: '16px' }} top={top} bottom={bottom} />
      )}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  valueChanged: PropTypes.string,
  closeToBottom: PropTypes.func,
  /* defines top of the vertical scrollbar */
  top: PropTypes.number,
  /* defines bottom of the vertical scrollbar */
  bottom: PropTypes.number,
  dark: PropTypes.bool,
};

Scrollbar.defaultProps = {
  valueChanged: null,
  closeToBottom: () => {},
  top: 0,
  bottom: 0,
  dark: false,
};

export default Scrollbar;
