import styled from '@emotion/styled';
import { ReactComponent as EmbeddedTweetIcon } from 'assets/icons/systemicons/editor/embeddedTweet.svg';

export const TweetIcon = styled(EmbeddedTweetIcon)`
  margin: 8px;
`;

export const Link = styled('span')`
  text-decoration: underline;
  cursor: pointer;
`;

export const TweetWrapper = styled('div')`
  margin: 16px 0px;
`;
