import postMessage from 'utils/postMessage';

const openAssetInMimirLeftSidePanel = (element) => {
  const assetItem = getMimirAssetItem(element);

  if (!assetItem) return;
  const { mAssetId } = assetItem;
  const message = {
    action: 'open_item',
    payload: mAssetId,
    destination: 'MIMIR',
  };

  if (message) {
    postMessage(message, 'mimir');
  }
};

export const isMimirAssetItem = (element) => !!getMimirAssetItem(element);

const getMimirAssetItem = (element) => {
  if (!element) return null;
  const { data } = element;
  if (!data) return null;
  // fix for element structure is not consistent.
  if (data.mProvider === 'Mimir') return data;
  const { assets } = data;
  if (!assets || assets.length === 0) return null;
  const assetItem = assets[0];
  if (!assetItem) return null;
  if (assetItem.mProvider !== 'Mimir') return null;
  return assetItem;
};

export default openAssetInMimirLeftSidePanel;
