/**
 *  Hook for getting members of
 */

import { useQuery } from '@apollo/client';
import GET_INSTANCE from 'graphql/queries/getSingleInstance';
import { getStoryInstancesQuery } from 'graphql/queryVariables';

const useGetInstances = (mId) => {
  const { data, error, loading } = useQuery(GET_INSTANCE, {
    variables: getStoryInstancesQuery(mId),
  });

  return [data, error, loading];
};

export default useGetInstances;
