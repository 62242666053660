import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',

    position: 'static',
    width: '100%',
    height: '100%',
    left: '0px',
    top: '0px',

    flex: 'none',
    order: '0',
    alignSelf: 'flex-start',
    margin: '0px',
    background: theme.palette.dina.blackHoverOverlay,
  },
  header: {
    position: 'relative',
    height: '40px',
    width: '100%',
    left: '0px',
    top: '0px',

    flex: 'none',
    order: '0',
    alignSelf: 'flex-start',
  },
  headerTitle: {
    ...theme.typography.dina.subtitle1,
    position: 'absolute',
    left: '16px',
    right: '8px',
    top: 'calc(50% - 18px/2)',
    bottom: '0px',
    color: theme.palette.dina.highEmphasis,
  },
  menuContainer: {
    height: '100%',
    width: '100%',
  },
}));

export default useStyles;
