import styled from '@emotion/styled';
import theme from 'theme/theme';

// eslint-disable-next-line import/prefer-default-export
export const StyledMenu = styled('div')`
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 2;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: ${theme.palette.dina.surfaceCard};
  border: 1px solid ${theme.palette.dina.buttonBackgroundOutlined};
  box-shadow: 0px 0px 4px ${theme.palette.dina.boxShadowDark},
    0px 6px 6px ${theme.palette.dina.boxShadowDark};
  border-radius: 6px;
  transition: opacity 0.75s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 32px;

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > * + *:not(:last-child) {
    margin-left: 12px;
  }

  & > * + *:last-child {
    margin-left: 4px;
  }
`;
