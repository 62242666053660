import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  header: {
    width: ({ openDrawer }) => (openDrawer ? '628px' : '384px'),
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'width 200ms ease-in-out',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    transform: 'translateZ(0)',
  },
  leftComponents: {
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.mediumEmphasis,
    lineHeight: '28px',
    marginLeft: '8px',
  },
  timePickerWrapper: {
    height: '28px',
    width: '65px',
    margin: '1px 0px 0px 2px',
  },
  formControlLabel: {
    margin: '0 32px 0 0',
  },
  label: {
    ...theme.typography.dina.captionRegular,
    letterSpacing: '0.25px',
    flexShrink: 1,
  },
  unscheduleButton: {
    maxHeight: '24px',
    minHeight: '24px',
    borderRadius: '4px',
    marginRight: '8px',
    width: '80px',
    backgroundColor: theme.palette.dina.statusWarning,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
      backgroundColor: theme.palette.dina.statusWarning,
    },
  },
  unscheduleText: {
    ...theme.typography.dina.captionRegular,
    letterSpacing: '0.25px',
    textTransform: 'none',
    fontWeight: 500,
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
